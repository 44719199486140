import { useDispatch } from 'react-redux';
import { useQueryClient } from 'react-query';
import { clearUserState } from 'features/auth/actions';

export const useUserLogout = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const logoutUser = async () => {
        await dispatch(clearUserState());
        await queryClient.invalidateQueries();
    };

    return { logoutUser };
};
