import { useEffect } from 'react';
import { isToday } from 'utils/dates';
import { api } from 'medrefer-web-sdk/api';
import { CalendarState } from 'components/Calendar/CalendarView/useCalendarState';

interface Props {
    state: CalendarState;
    handleDateChange: (date: Date) => void;
}

export const useNearestEventFocus = ({ state, handleDateChange }: Props) => {
    useEffect(() => {
        if (isToday(state.date)) {
            focusNearestEvent();
        }
        // eslint-disable-next-line
    }, []);

    const focusNearestEvent = async () => {
        try {
            const config = await api.getCalendarConfig();
            if (config.nearest_event_timestamp) {
                handleDateChange(new Date(config.nearest_event_timestamp));
            }
        } catch (err) {}
    };
};
