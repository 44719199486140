import React from 'react';
import {
    Button,
    DatePicker,
    FormErrorMessage,
    FormSelect,
    InformationContainer,
    Input,
    Label,
    RadioTile,
    UnderlineH2 as Title,
} from 'medrefer-web-sdk/web-kit';
import { DaySelector } from './DaySelector';
import { Field, Form, FormikProps, withFormik } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import { CustomRepeatFormProps, EndsOnOption, FormValues } from './CustomRepeat.types';
import { Frequency } from 'medrefer-web-sdk/api/models';
import { mapPropsToValues, validationSchema } from './CustomRepeat.schema';
import { useTranslation, withTranslation } from 'react-i18next';
import i18n from 'i18n/i18n';
import { mapValuesToScheduleRule } from './helpers/mapValuesToScheduleRule';
import { useFrequencyOptions } from './CustomRepeat.hooks';

export const InnerForm = (props: CustomRepeatFormProps & FormikProps<FormValues>) => {
    const { t } = useTranslation();
    const frequencyOptions = useFrequencyOptions(props.values.interval);

    return (
        <div>
            <Title>{t('calendar:schedule.custom_repeat_label')}</Title>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.handleSubmit();
                }}
            >
                <Row className="mb-5">
                    <Col xs={12} md={4}>
                        <Label>{t('calendar:schedule.repeat_every')}</Label>
                    </Col>
                    <Col xs={12} md={4} className="pl-0 pr-2">
                        <Input
                            isError={false}
                            id="interval"
                            name="interval"
                            value={props.values.interval}
                            type="number"
                            min={1}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                    </Col>
                    <Col xs={12} md={4} className="pl-0 pr-0">
                        <Field
                            name="freq"
                            options={frequencyOptions}
                            component={FormSelect}
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.values.freq === Frequency.WEEKLY && (
                    <>
                        <Row className="mb-5">
                            <Col xs={12} md={12} className="mb-2">
                                <Label>{t('calendar:schedule.repeat_on')}</Label>
                            </Col>
                            <Col xs={12} md={12}>
                                <DaySelector {...props} />
                            </Col>
                        </Row>
                    </>
                )}
                <Row className="mb-2">
                    <Col xs={12} md={12} className="mb-2">
                        <Label>{t('calendar:schedule.ends_on')}</Label>
                    </Col>
                    <Col xs={12} md={6}>
                        <RadioTile
                            name="endsOn"
                            value={EndsOnOption.never}
                            checked={props.values.endsOn === EndsOnOption.never}
                            title={t('calendar:schedule.repeat_never')}
                            onChange={props.handleChange}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12} md={6}>
                        <RadioTile
                            name="endsOn"
                            value={EndsOnOption.onDay}
                            checked={props.values.endsOn === EndsOnOption.onDay}
                            title={t('calendar:schedule.repeat_on_day')}
                            onChange={props.handleChange}
                        />
                    </Col>
                    <Col xs={12} md={6} style={props.values.endsOn !== EndsOnOption.onDay ? { opacity: '0.5' } : {}}>
                        <DatePicker
                            name="until"
                            fullWidth
                            value={props.values.until}
                            onChange={props.handleChange}
                            disabled={props.values.endsOn !== EndsOnOption.onDay}
                            autoComplete="off"
                            showYearDropdown
                            scrollableYearDropdown
                            dropdownMode="select"
                            minDate={new Date()}
                            i18n={i18n}
                            dateFormat="yyyy/MM/dd"
                        />
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Col xs={12} md={6}>
                        <RadioTile
                            name="endsOn"
                            value={EndsOnOption.afterX}
                            checked={props.values.endsOn === EndsOnOption.afterX}
                            title={t('calendar:schedule.repeat_after')}
                            onChange={props.handleChange}
                        />
                    </Col>
                    <Col
                        xs={12}
                        md={2}
                        className="pr-0"
                        style={props.values.endsOn !== EndsOnOption.afterX ? { opacity: '0.5' } : {}}
                    >
                        <Input
                            isError={false}
                            disabled={props.values.endsOn !== EndsOnOption.afterX}
                            id="count"
                            name="count"
                            value={props.values.count}
                            type="number"
                            min={1}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                    </Col>
                    <Col
                        xs={12}
                        md={4}
                        className="pl-0"
                        style={props.values.endsOn !== EndsOnOption.afterX ? { opacity: '0.5' } : {}}
                    >
                        <Input disabled value={t('calendar:schedule.occurrences').toString()} type="text" />
                    </Col>
                </Row>

                <Container>
                    <Row>
                        <Col className="m-auto" xs={12} md={12}>
                            <Button>{t('editAppointmentForm:save_button_text')}</Button>
                            <InformationContainer>
                                <FormErrorMessage onlyTouched={false} />
                            </InformationContainer>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </div>
    );
};

const CustomRepeat = withFormik<CustomRepeatFormProps, FormValues>({
    mapPropsToValues: mapPropsToValues,
    validationSchema: validationSchema,
    handleSubmit: (values, { props }) => {
        const rrule = mapValuesToScheduleRule(values);
        props.onSubmit(rrule);
    },
})(InnerForm);

export default withTranslation()(CustomRepeat);
