import React, { FC, useEffect, useState } from 'react';
import { Select } from './Select';
import { LoadOptions, useAsyncPaginate, useComponents } from 'react-select-async-paginate';
import { GroupBase, PropsValue } from 'react-select';
import { getAsyncValue, getFormFieldValue } from './utils';
import { AsyncSelectProps, Option } from './Select.types';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const FormAsyncSelect: FC<AsyncSelectProps<Option, boolean, GroupBase<Option>, any>> = ({
    field,
    form, // eslint-disable-line @typescript-eslint/no-unused-vars
    options,
    defaultOptions,
    additional,
    loadOptions,
    loadOptionsOnMenuOpen,
    debounceTimeout,
    filterOption,
    reduceOptions,
    shouldLoadMore,
    components: defaultComponents,
    isMulti,
    initialOptions,
    recoverOptions,
    afterChange,
    noOptionsMessage,
    minSearchLength = 0,
    ...rest
}) => {
    const innerLoadOptions: LoadOptions<Option, GroupBase<Option>, unknown> = async (
        search,
        loadedOptions,
        additional,
    ) => {
        if (search.length < minSearchLength) {
            return {
                hasMore: false,
                options: [],
            };
        }
        return loadOptions(search, loadedOptions, additional);
    };

    const asyncPaginateProps = useAsyncPaginate({
        options,
        defaultOptions,
        additional,
        loadOptions: innerLoadOptions,
        loadOptionsOnMenuOpen,
        debounceTimeout,
        filterOption,
        reduceOptions,
        shouldLoadMore,
    });
    const components = useComponents(defaultComponents || {});
    const [value, setValue] = useState<Option[]>([]);

    useEffect(() => {
        setInitialValue();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChange = (option: PropsValue<Option>) => {
        form.setFieldValue(field.name, getFormFieldValue(option));

        if (Array.isArray(option)) {
            setValue(option);
        } else if (option) {
            setValue([option as Option]);
        } else {
            setValue([]);
        }

        if (afterChange != null) {
            afterChange(option);
        }
    };

    const setInitialValue = async () => {
        const initialValue = await getAsyncValue(field.value, initialOptions, isMulti, recoverOptions);
        setValue(initialValue);
    };

    return (
        <Select
            components={components}
            isMulti={isMulti}
            {...asyncPaginateProps}
            {...rest}
            value={value}
            onChange={onChange}
            noOptionsMessage={(e) => {
                if (e.inputValue.length < minSearchLength) {
                    return null;
                } else if (noOptionsMessage) {
                    return noOptionsMessage(e);
                }
            }}
        />
    );
};
