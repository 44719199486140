import styled from 'styled-components';

interface DropDownMenuProps {
    isOpen?: boolean;
}

export const DropDownMenu = styled.div<DropDownMenuProps>`
    position: absolute;
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 1px 5px rgba(44, 51, 71, 0.12);
    font-size: 13px;
    line-height: 25px;
    color: #000000;
    border-radius: 4px;
    align-items: flex-end;
    padding: 12px 18px 16px;
    z-index: 10;
    span {
        display: block;
        width: 100%;
        white-space: nowrap;
        cursor: pointer;
        padding-bottom: 4px;
        &:hover {
            color: ${({ theme }) => theme.accent};
        }
    }
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        overflow-y: scroll;
        max-height: 320px;
    } ;
`;

export const DropDownMenuEdit = styled(DropDownMenu)`
    right: 0px;
    top: 46px;

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        left: 0px;
        right: auto;
    }
`;
export const DropDownMenuFilter = styled(DropDownMenu)`
    align-items: flex-start;
    padding: 17px 20px 25px;
    left: 0;
    top: 44px;
    width: 220px;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        left: auto;
        right: 0px;
    } ;
`;
