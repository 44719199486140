import styled from 'styled-components';
import React from 'react';

export const ExternalLink = ({ children, ...rest }: React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
    return (
        <ExternalLinkStyled rel="noreferrer" target="_blank" {...rest}>
            {children}
        </ExternalLinkStyled>
    );
};

const ExternalLinkStyled = styled.a`
    text-decoration: none;
    cursor: pointer;
    color: ${({ theme }) => theme.black};

    &:hover {
        text-decoration: underline;
        color: ${({ theme }) => theme.black};
    }
`;
