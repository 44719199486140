import styled from 'styled-components';

export const DateNavigatorStyled = styled.div`
    margin-top: 62px;
    padding: 0 10px 10px 10px;
    .month-picker {
        span {
            font-size: 14px;
        }
        font-weight: 600;
        padding: 10px 6px;
        div {
            display: inline-block;
            width: 24px;
            height: 24px;
            float: right;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            cursor: pointer;
            transition: 0.2s;
            border-radius: 100%;

            &:hover {
                background: #dadada;
            }
        }
        .right {
            user-select: none;
            margin-left: 3px;
            margin-bottom: 4px;
        }

        .left {
            user-select: none;
            margin-left: -1px;
            margin-bottom: 3px;
        }

        img {
            display: inline-block;
            width: 6px;
            height: 12px;
        }
    }
    .days-picker {
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        column-gap: 5px;
        row-gap: 5px;
        .day {
            user-select: none;
            border-radius: 100%;
            text-align: center;
            font-size: 11px;
            color: #70757a;
            cursor: pointer;
            height: 28px;
            width: 28px;
            padding-top: 5px;
            &.today {
                background-color: #1a73e8;
                color: #fff !important;
                &:hover {
                    background-color: #1967d2;
                }
            }
            &.active {
                color: #185abc;
                background-color: #d2e3fc;
                &:hover {
                    background-color: #aecbfa;
                }
            }
            &.dayName {
                &:hover {
                    background: none;
                    cursor: inherit;
                }
            }
            &.current {
                color: black;
            }
            &:hover {
                background: #dadada;
            }
        }
    }
`;
