import React from 'react';
import { Field, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation, withTranslation } from 'react-i18next';
import { Col, Form, Row } from 'react-bootstrap';
import { AcceptInvitationFormData, FormProps } from './AcceptInvitationForm.types';

import {
    Button,
    CheckBox,
    CheckBoxErrorIcon,
    CheckBoxLabel,
    ErrorMessage,
    FormAsyncSelect,
    FormErrorMessage,
    FormGroup,
    FormSelect,
    FormSeparator,
    InformationContainer,
    Input,
    Label,
    Option,
} from 'medrefer-web-sdk/web-kit';
import { useInsuranceStatusOptions } from 'hooks/options/useInsuranceStatusOptions';
import { PrivacyPolicyNote } from 'components/PrivacyPolicy';
import { UserRole } from 'medrefer-web-sdk/api/models';
import { PhoneInput } from 'components/shared/PhoneInput';
import i18n from 'i18next';
import { DatePickerField } from '../../../../medrefer-web-sdk/web-kit/components/formComponents/DatePicker/DatePicker';
import { OptionalField } from '../../../shared/ServiceSlots/RequestWaitingRoomModal/RequestWaitingRoomDataComponents/OptionalField';
import useLocationAutocompleteOptions from '../../../../hooks/options/useLocationAutocompleteOptions';
import {
    EOptionalFields,
    useOptionalFields,
} from '../../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';
import { useSelector } from 'react-redux';
import { getBrand } from '../../../../features/auth/selectors';

const InnerForm = (props: FormProps & FormikProps<AcceptInvitationFormData>) => {
    const { t } = useTranslation();
    const insuranceStatusOptions = useInsuranceStatusOptions();
    const { loadLocationAutocompleteOptions, setAddressProps } = useLocationAutocompleteOptions();
    const organization = useSelector(getBrand);
    const { isVisible } = useOptionalFields(organization);

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <FormGroup className="mb-0">
                <Form.Label>{t('registerForm:emailLabel')}</Form.Label>
                <Input
                    data-testid="email"
                    id="email"
                    name="email"
                    type="email"
                    value={props.invitee.email}
                    disabled={true}
                />
            </FormGroup>
            <FormGroup>
                <Label isRequired>{t('registerForm:passwordLabel')}</Label>
                <Input
                    data-testid="password"
                    id="password"
                    name="password"
                    type="password"
                    onChange={props.handleChange}
                    isError={!!(props.errors.password && props.touched.password)}
                    value={props.values.password}
                />
                {props.errors.password && props.touched.password && (
                    <ErrorMessage>{props.errors.firstName}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Label>{t('registerForm:personalTitleLabel')}</Label>
                <Input
                    data-testid="personalTitle"
                    id="personalTitle"
                    name="personalTitle"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.personalTitle}
                    autoComplete="off"
                />
            </FormGroup>
            <FormGroup>
                <Label isRequired>{t('registerForm:firstNameLabel')}</Label>
                <Input
                    data-testid="firstName"
                    id="firstName"
                    name="firstName"
                    type="text"
                    isError={!!(props.errors.firstName && props.touched.firstName)}
                    onChange={props.handleChange}
                    value={props.values.firstName}
                    autoComplete="off"
                />
                {props.errors.firstName && props.touched.firstName && (
                    <ErrorMessage>{props.errors.firstName}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Label isRequired>{t('registerForm:lastNameLabel')}</Label>
                <Input
                    data-testid="lastName"
                    isError={!!(props.errors.lastName && props.touched.lastName)}
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.lastName}
                    autoComplete="off"
                />
                {props.errors.lastName && props.touched.lastName && (
                    <ErrorMessage>{props.errors.lastName}</ErrorMessage>
                )}
            </FormGroup>

            {props.values.role === UserRole.patient && (
                <>
                    <OptionalField
                        isError={!!(props.touched.phone && props.errors.phone)}
                        errorMessage={props.errors.phone}
                        fieldName={'phone'}
                        fieldLabel={t('newAppointmentForm:title_phone_number')}
                        fields={props.values.fields}
                        mandatory_fields={props.values.mandatory_fields}
                    >
                        <PhoneInput
                            isError={!!(props.touched.phone && props.errors.phone)}
                            id="phone"
                            type="text"
                            value={props.values.phone}
                            onBlur={props.handleBlur}
                            onChange={props.handleChange}
                        />
                    </OptionalField>
                    <OptionalField
                        isError={!!(props.touched.insuranceNumber && props.errors.insuranceNumber)}
                        errorMessage={props.errors.insuranceNumber}
                        fieldName={'insurance_number'}
                        fieldLabel={t('newAppointmentForm:title_insurance_number')}
                        fields={props.fields}
                        mandatory_fields={props.mandatory_fields}
                    >
                        <Input
                            isError={!!(props.touched.insuranceNumber && props.errors.insuranceNumber)}
                            id="insuranceNumber"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.insuranceNumber}
                            onBlur={props.handleBlur}
                        />
                    </OptionalField>
                    <OptionalField
                        isError={!!(props.touched.insuranceStatus && props.errors.insuranceStatus)}
                        errorMessage={props.errors.insuranceStatus}
                        fieldName={'insurance_status'}
                        fieldLabel={t('newAppointmentForm:title_insurance_status')}
                        fields={props.fields}
                        mandatory_fields={props.mandatory_fields}
                    >
                        <Field
                            name="insuranceStatus"
                            options={insuranceStatusOptions}
                            id="insuranceStatus"
                            component={FormSelect}
                            placeholder={t('newAppointmentForm:placeholder_insurance_status')}
                            isMulti={false}
                            type={'text'}
                            isError={props.touched.insuranceStatus && props.errors.insuranceStatus}
                            onBlur={props.handleBlur}
                        />
                    </OptionalField>
                    <FormGroup>
                        <Label isRequired>{t('registerForm:titleDateOfBirth')}</Label>
                        <DatePickerField
                            isError={props.touched.dateOfBirth && props.errors.dateOfBirth}
                            name="dateOfBirth"
                            fullWidth
                            autoComplete="off"
                            showYearDropdown
                            scrollableYearDropdown
                            maxDate={new Date()}
                            dropdownMode="select"
                            i18n={i18n}
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                        />
                        {props.touched.insuranceStatus && props.errors.insuranceStatus && (
                            <ErrorMessage className="text-left mt-1">{props.errors.dateOfBirth}</ErrorMessage>
                        )}
                    </FormGroup>
                    <OptionalField
                        isError={!!(props.errors.street && props.touched.street)}
                        errorMessage={props.errors.street}
                        fieldName={'address'}
                        fieldLabel={t('newAppointmentForm:title_address')}
                        fields={props.fields}
                        mandatory_fields={props.mandatory_fields}
                    >
                        <Field
                            name="address"
                            isError={!!(props.errors.street && props.touched.street)}
                            loadOptions={loadLocationAutocompleteOptions}
                            component={FormAsyncSelect}
                            placeholder={t('adminPanel:manageLocations.locationsForm.address_placeholder')}
                            debounceTimeout={400}
                            minSearchLength={3}
                            onBlur={props.handleBlur}
                            afterChange={(option: Option) => setAddressProps(props.setFieldValue, option)}
                        />
                    </OptionalField>
                </>
            )}

            <FormGroup>
                <FormSeparator className="mb-4 mt-0" />
                {props.values.role === UserRole.patient && (
                    <>
                        {isVisible(EOptionalFields.phone) && (
                            <Row>
                                <Col className="d-flex align-items-start" xs={12}>
                                    <CheckBox
                                        id="smsAgreement"
                                        checked={props.values.smsAgreement}
                                        onChange={props.handleChange}
                                        className="mr-4"
                                    />
                                    <CheckBoxLabel>{t('newAppointmentForm:checkbox_text1')}</CheckBoxLabel>
                                </Col>
                            </Row>
                        )}
                        <Row>
                            <Col className="d-flex align-items-start" xs={12}>
                                <CheckBox
                                    id="emailAgreement"
                                    checked={props.values.emailAgreement}
                                    onChange={props.handleChange}
                                    className="mr-4"
                                />
                                <CheckBoxLabel>{t('newAppointmentForm:checkbox_text2')}</CheckBoxLabel>
                            </Col>
                        </Row>
                    </>
                )}
                <Row>
                    <Col className="d-flex align-items-start" xs={12}>
                        <CheckBox
                            isError={props.touched.termsAgreement && props.errors.termsAgreement}
                            id="termsAgreement"
                            disable
                            checked={props.values.termsAgreement}
                            onChange={props.handleChange}
                            className="mr-4"
                        />
                        <CheckBoxLabel isRequired>
                            <PrivacyPolicyNote />
                        </CheckBoxLabel>
                        {props.touched.termsAgreement && props.errors.termsAgreement && <CheckBoxErrorIcon />}
                    </Col>
                </Row>
                {props.touched.termsAgreement && props.errors.termsAgreement && (
                    <ErrorMessage className="text-left mr-5 pr-5 mt-1">{props.errors.termsAgreement}</ErrorMessage>
                )}
                <FormSeparator className="mb-4 mt-4" />
            </FormGroup>

            <InformationContainer>
                <FormErrorMessage apiErrors={props.request.errors} />
            </InformationContainer>
            <div style={{ maxWidth: 200 }}>
                <Button isLoading={props.request.isLoading}>{t('registerForm:button')}</Button>
            </div>
        </Form>
    );
};

const AcceptInvitationForm = withFormik<FormProps, AcceptInvitationFormData>({
    mapPropsToValues: (props) => ({
        role: props.invitee.role,
        personalTitle: props.invitee.personal_title,
        firstName: props.invitee.first_name,
        lastName: props.invitee.last_name,
        password: '',
        invitationToken: props.invitationToken,
        phone: '',
        smsAgreement: false,
        emailAgreement: false,
        termsAgreement: false,
        dateOfBirth: '',
        insuranceStatus: '',
        insuranceNumber: '',
        fields: props.fields,
        mandatory_fields: props.mandatory_fields,
        street: '',
        city: '',
    }),
    validationSchema: ({ t }: FormProps) =>
        Yup.object().shape({
            firstName: Yup.string()
                .matchesName(t('newAppointmentForm:warning_first_name_matches'))
                .required(t('newAppointmentForm:warning_first_name_required')),
            lastName: Yup.string()
                .matchesName(t('newAppointmentForm:warning_last_name_matches'))
                .required(t('newAppointmentForm:warning_last_name_required')),
            password: Yup.string()
                .max(50, t('loginForm:warning_password_max'))
                .required(t('loginForm:warning_password_required')),
            phone: Yup.string().when(['role', 'mandatory_fields', 'fields', 'sms_agreement'], {
                is: (role) => [UserRole.patient].includes(role),
                then: Yup.string().when(['fields', 'mandatory_fields', 'sms_agreement'], {
                    is: (fields, mandatory_fields) => fields.includes('phone') || mandatory_fields.includes('phone'),
                    then: Yup.string()
                        .matchesPhone(t('newAppointmentForm:warning_phone_matches'))
                        .when(['smsAgreement', 'mandatory_fields'], {
                            is: true,
                            then: Yup.string().required(t('newAppointmentForm:warning_phone_required')),
                        })
                        .when('mandatory_fields', {
                            is: (mandatory_fields) => mandatory_fields.includes('phone'),
                            then: Yup.string().required(t('newAppointmentForm:warning_phone_matches')),
                        }),
                }),
            }),
            termsAgreement: Yup.bool().oneOf([true], t('newAppointmentForm:warning_terms_agreement')),
            dateOfBirth: Yup.string().when(['role'], {
                is: (role) => [UserRole.patient].includes(role),
                then: Yup.string().required(t('newAppointmentForm:warning_date_of_birth_required')),
            }),
            insuranceStatus: Yup.string().when(['role', 'mandatory_fields'], {
                is: (role) => [UserRole.patient].includes(role),
                then: Yup.string().when('mandatory_fields', {
                    is: (mandatory_fields) => mandatory_fields.includes('insurance_status'),
                    then: Yup.string().required(t('newAppointmentForm:warning_insurance_status_required')),
                }),
            }),
            insuranceNumber: Yup.string().when(['role', 'mandatory_fields'], {
                is: (role) => [UserRole.patient].includes(role),
                then: Yup.string().when('mandatory_fields', {
                    is: (mandatory_fields) => mandatory_fields.includes('insurance_number'),
                    then: Yup.string().required(t('newAppointmentForm:warning_insurance_number_required')),
                }),
            }),
            city: Yup.string().when(['role', 'mandatory_fields'], {
                is: (role) => [UserRole.patient].includes(role),
                then: Yup.string().when('mandatory_fields', {
                    is: (mandatory_fields) => mandatory_fields.includes('address'),
                    then: Yup.string().required(t('newAppointmentForm:warning_address_required')),
                }),
            }),
            street: Yup.string().when(['mandatory_fields', 'role'], {
                is: (role) => [UserRole.patient].includes(role),
                then: Yup.string().when('mandatory_fields', {
                    is: (mandatory_fields) => mandatory_fields.includes('address'),
                    then: Yup.string().required(t('newAppointmentForm:warning_address_required')),
                }),
            }),
        }),
    handleSubmit: (values, { props }) => {
        props.onSubmit(values);
    },
})(InnerForm);

export default withTranslation()(AcceptInvitationForm);
