import styled from 'styled-components';
import { H2 } from './typography';

export const UnderlineH2 = styled(H2)`
    padding: 12px 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #eeeef2;
`;

export const UnderlineFullH2 = styled(UnderlineH2)`
    width: 100%;
`;
