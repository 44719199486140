import React from 'react';
import { InformationBubble, InformationBubbleTypes } from 'medrefer-web-sdk/web-kit';
import styled from 'styled-components';
import CloseIcon from '../assets/icons/CloseIcon.png';

export enum ToastTypes {
    warning,
    info,
    success,
}

interface Props {
    text: string;
    type: ToastTypes;
    toggleToast: () => void;
}

export const Toast = ({ text, type, toggleToast }: Props) => {
    return (
        <ToastContainer>
            <CloseButton alt="Close icon" onClick={toggleToast} src={CloseIcon} />
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any*/}
            <InformationBubble type={type as any as InformationBubbleTypes}>{text}</InformationBubble>
        </ToastContainer>
    );
};

const CloseButton = styled.img`
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
`;

const ToastContainer = styled.div`
    position: fixed;
    z-index: 9999999999999999;
    bottom: 10px;
    left: 10px;
    right: 10px;
`;
