import { Column } from 'react-table';
import { SlotPermission } from '../../../medrefer-web-sdk/api/models';
import { Schedule } from './CalendarTable.types';
import publicIcon from '../../../assets/Web.svg';
import privateIcon from '../../../assets/Lock.svg';
import internalIcon from '../../../assets/account-group.svg';
import React from 'react';
import { getSlotLocalization } from './Utils';
import { CheckBox } from '../../../medrefer-web-sdk/web-kit';
import moment from 'moment/moment';
import { getDateTimeIntervalString } from '../../../utils/dates';

export const columns: (t: (key: string) => string, checkSchedule: (key: string) => void) => Column<Schedule>[] = (
    t,
    checkSchedule,
) => [
    {
        Header: ' ',
        accessor: (schedule) => {
            return (
                <>
                    <CheckBox
                        style={{ marginBottom: '15px' }}
                        checked={schedule.isChecked}
                        onChange={() => {
                            if (schedule.key) {
                                checkSchedule(schedule.key);
                            }
                        }}
                    ></CheckBox>
                </>
            );
        },
        width: 50,
    },
    {
        Header: t('adminPanel:table.columns.time'),
        accessor: (schedule) => {
            const startMoment = moment(new Date(schedule.date_from));
            const endMoment = moment(new Date(schedule.date_to));

            return <span>{getDateTimeIntervalString(startMoment, endMoment)}</span>;
        },
        sortParam: 'date_from',
    },
    {
        Header: t('adminPanel:table.columns.service_name'),
        accessor: (schedule) => {
            return <span>{schedule.healthcare_service.name}</span>;
        },
        sortParam: 'healthcare_service__name',
    },
    {
        Header: t('newSlotForm:slot_visibility'),
        accessor: (schedule) => {
            if (schedule.slot_permission) {
                let icon = publicIcon;
                if (schedule.slot_permission == SlotPermission.PRIVATE) icon = privateIcon;
                if (schedule.slot_permission == SlotPermission.INTERNAL) icon = internalIcon;
                return (
                    <span>
                        <img src={icon} />
                        {' ' + t(getSlotLocalization(schedule.slot_permission))}
                    </span>
                );
            }
        },
        width: 100,
        sortParam: 'slot_permission',
    },
    {
        Header: t('calendar:calendarTable:criteria'),
        accessor: (schedule) => {
            if (schedule.criteria && schedule.criteria.length > 0) {
                let to_show = '';
                schedule.criteria.map((criteria) => {
                    criteria.values.map((spec) => {
                        to_show += spec.name + ' ';
                    });
                });
                return to_show;
            }
        },
    },
];
