import React, { ReactElement } from 'react';
import { GreyOutlineButton } from 'medrefer-web-sdk/web-kit';

import { Container } from 'react-bootstrap';
import { Attachment } from 'medrefer-web-sdk/api/models';
import { api } from 'medrefer-web-sdk/api';
import { InformationBubble, InformationBubbleTypes } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';

interface Props {
    attachments?: Attachment[] | undefined;
    files?: File[] | undefined;
    useRequestTypeFile?: boolean;
}

const AppointmentFiles = ({ attachments, files, useRequestTypeFile = false }: Props): ReactElement => {
    const { t } = useTranslation();

    const openAttachmentLink = (id: number): void => {
        // Safari blocks window.open() if used inside async function. This workaround fixes the issue.
        const windowReference = window.open();

        if (useRequestTypeFile) {
            api.getRequestAttachmentLink(id).then((url) => {
                if (windowReference) {
                    windowReference.location.href = url;
                }
            });
            return;
        }

        api.getAttachmentLink(id).then((url) => {
            if (windowReference) {
                windowReference.location.href = url;
            }
        });
    };

    const openFileLink = (index: number): void => {
        // Safari blocks window.open() if used inside async function. This workaround fixes the issue.
        const windowReference = window.open();

        if (windowReference && files) {
            const url = URL.createObjectURL(files[index]);
            windowReference.location.href = url;
        }
    };

    if ((attachments === undefined || attachments.length === 0) && (files === undefined || files.length === 0))
        return <></>;

    return (
        <Container>
            <InformationBubble className="pt-4 mb-5 d-inline-block" type={InformationBubbleTypes.info}>
                <ReactMarkdown>{t('appointmentDetails:attachmentWarningMessage') as string}</ReactMarkdown>
            </InformationBubble>
            {attachments?.map((attachment: Attachment) => {
                return (
                    <div key={attachment.filename} className="mb-2">
                        <GreyOutlineButton onClick={() => openAttachmentLink(attachment.id)}>
                            {attachment.filename}
                        </GreyOutlineButton>
                    </div>
                );
            })}
            {files?.map((file: File, index: number) => {
                return (
                    <div key={file.name} className="mb-2">
                        <GreyOutlineButton onClick={() => openFileLink(index)}>{file.name}</GreyOutlineButton>
                    </div>
                );
            })}
        </Container>
    );
};

export default AppointmentFiles;
