import React from 'react';
import {
    ErrorMessage,
    FormGroup,
    Input,
    Label,
    RadioTile,
    TextArea,
    TextGrey,
    UnderlineH2 as Title,
} from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import { NoSlotsAction, NoSlotsDisplay, NoSlotsSubFormValues } from './NoSlotsBehaviourSubForm.types';
import { FormikErrors, FormikHandlers, FormikTouched } from 'formik';
import { useTranslation } from 'react-i18next';
import { NoSlotsMessagePreview } from 'components/shared/NoSlotsBehaviourSubForm/NoSlotsBehaviourSubForm.styled';
import { parseFormToNoSlotsBehaviour } from 'components/shared/NoSlotsBehaviourSubForm/NoSlotsBehaviourSubForm.helpers';

interface Props extends Pick<FormikHandlers, 'handleChange'> {
    namespace?: string;
    values: NoSlotsSubFormValues;
    errors?: FormikErrors<NoSlotsSubFormValues>;
    touched?: FormikTouched<NoSlotsSubFormValues>;
}

export const NoSlotsBehaviourSubForm = ({ values, errors = {}, touched = {}, ...props }: Props) => {
    const { t } = useTranslation();
    const resultValues = parseFormToNoSlotsBehaviour(values);

    const withNamespace = (fieldName: string) => {
        return props.namespace ? `${props.namespace}.${fieldName}` : fieldName;
    };

    const actionTextName = {
        [NoSlotsAction.link]: 'actionLinkText',
        [NoSlotsAction.email]: 'actionEmailText',
        [NoSlotsAction.phone]: 'actionPhoneText',
        [NoSlotsAction.none]: null,
    }[values.actionType];

    const actionTextValue = {
        [NoSlotsAction.link]: values.actionLinkText,
        [NoSlotsAction.email]: values.actionEmailText,
        [NoSlotsAction.phone]: values.actionPhoneText,
        [NoSlotsAction.none]: '',
    }[values.actionType];

    return (
        <>
            <Title>{t('noSlotsForm:title')}</Title>
            <TextGrey>{t('noSlotsForm:description')}</TextGrey>
            <FormGroup>
                <Row>
                    <Col>
                        <Label>{t('noSlotsForm:behaviour_label')}</Label>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3} className="mt-2">
                        <RadioTile
                            name={withNamespace('displayCalendar')}
                            value={NoSlotsDisplay.default}
                            title={t('noSlotsForm:behaviour_default_title')}
                            description={t('noSlotsForm:behaviour_default_description')}
                            checked={values.displayCalendar === NoSlotsDisplay.default}
                            onChange={props.handleChange}
                        />
                    </Col>
                    <Col xs={12} md={3} className="mt-2">
                        <RadioTile
                            name={withNamespace('displayCalendar')}
                            value={NoSlotsDisplay.display}
                            title={t('noSlotsForm:behaviour_display_title')}
                            description={t('noSlotsForm:behaviour_display_description')}
                            checked={values.displayCalendar === NoSlotsDisplay.display}
                            onChange={props.handleChange}
                        />
                    </Col>
                    <Col xs={12} md={3} className="mt-2">
                        <RadioTile
                            name={withNamespace('displayCalendar')}
                            value={NoSlotsDisplay.hidden}
                            title={t('noSlotsForm:behaviour_hide_title')}
                            description={t('noSlotsForm:behaviour_hide_description')}
                            checked={values.displayCalendar === NoSlotsDisplay.hidden}
                            onChange={props.handleChange}
                        />
                    </Col>
                    <Col xs={12} md={3} className="mt-2">
                        <RadioTile
                            name={withNamespace('displayCalendar')}
                            value={NoSlotsDisplay.waiting}
                            title={t('noSlotsForm:behaviour_waiting_title')}
                            description={t('noSlotsForm:behaviour_waiting_description')}
                            checked={values.displayCalendar === NoSlotsDisplay.waiting}
                            onChange={props.handleChange}
                        />
                    </Col>
                </Row>
            </FormGroup>
            {values.displayCalendar === NoSlotsDisplay.display && (
                <>
                    <FormGroup>
                        <Row>
                            <Col>
                                <Label isRequired>{t('noSlotsForm:message_label')}</Label>
                                <TextArea
                                    isError={!!(touched.message && errors.message)}
                                    id={withNamespace('message')}
                                    placeholder={t('noSlotsForm:message_placeholder')}
                                    value={values.message}
                                    onChange={props.handleChange}
                                />
                            </Col>
                        </Row>
                        {touched.message && errors.message && (
                            <ErrorMessage className="text-right mt-1">{errors.message}</ErrorMessage>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col>
                                <Label>{t('noSlotsForm:action_type_label')}</Label>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={3} className="mt-2">
                                <RadioTile
                                    id={withNamespace('actionType')}
                                    value={NoSlotsAction.none}
                                    checked={values.actionType === NoSlotsAction.none}
                                    onChange={props.handleChange}
                                    title={t('noSlotsForm:action_option_none')}
                                />
                            </Col>
                            <Col xs={12} md={3} className="mt-2">
                                <RadioTile
                                    id={withNamespace('actionType')}
                                    value={NoSlotsAction.link}
                                    checked={values.actionType === NoSlotsAction.link}
                                    onChange={props.handleChange}
                                    title={t('noSlotsForm:action_option_link')}
                                />
                            </Col>
                            <Col xs={12} md={3} className="mt-2">
                                <RadioTile
                                    id={withNamespace('actionType')}
                                    value={NoSlotsAction.phone}
                                    checked={values.actionType === NoSlotsAction.phone}
                                    onChange={props.handleChange}
                                    title={t('noSlotsForm:action_option_phone')}
                                />
                            </Col>
                            <Col xs={12} md={3} className="mt-2">
                                <RadioTile
                                    id={withNamespace('actionType')}
                                    value={NoSlotsAction.email}
                                    checked={values.actionType === NoSlotsAction.email}
                                    onChange={props.handleChange}
                                    title={t('noSlotsForm:action_option_email')}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    {values.actionType === NoSlotsAction.link && (
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label isRequired>{t('noSlotsForm:action_link_label')}</Label>
                                    <Input
                                        isError={!!(touched.actionLink && errors.actionLink)}
                                        id={withNamespace('actionLink')}
                                        type="text"
                                        placeholder={t('noSlotsForm:action_link_placeholder')}
                                        value={values.actionLink}
                                        onChange={props.handleChange}
                                    />
                                </Col>
                            </Row>
                            {touched.actionLink && errors.actionLink && (
                                <ErrorMessage className="text-right mt-1">{errors.actionLink}</ErrorMessage>
                            )}
                        </FormGroup>
                    )}
                    {values.actionType === NoSlotsAction.phone && (
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label isRequired>{t('noSlotsForm:action_phone_label')}</Label>
                                    <Input
                                        isError={!!(touched.actionPhone && errors.actionPhone)}
                                        id={withNamespace('actionPhone')}
                                        type="text"
                                        value={values.actionPhone}
                                        onChange={props.handleChange}
                                    />
                                </Col>
                            </Row>
                            {touched.actionPhone && errors.actionPhone && (
                                <ErrorMessage className="text-right mt-1">{errors.actionPhone}</ErrorMessage>
                            )}
                        </FormGroup>
                    )}
                    {values.actionType === NoSlotsAction.email && (
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label isRequired>{t('noSlotsForm:action_email_label')}</Label>
                                    <Input
                                        isError={!!(touched.actionEmail && errors.actionEmail)}
                                        id={withNamespace('actionEmail')}
                                        type="text"
                                        value={values.actionEmail}
                                        onChange={props.handleChange}
                                    />
                                </Col>
                            </Row>
                            {touched.actionEmail && errors.actionEmail && (
                                <ErrorMessage className="text-right mt-1">{errors.actionEmail}</ErrorMessage>
                            )}
                        </FormGroup>
                    )}
                    {actionTextName && (
                        <FormGroup>
                            <Row>
                                <Col>
                                    <Label>{t('noSlotsForm:action_text_label')}</Label>
                                    <Input
                                        id={withNamespace(actionTextName)}
                                        type="text"
                                        value={actionTextValue}
                                        onChange={props.handleChange}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    )}
                </>
            )}
            {[NoSlotsDisplay.default, NoSlotsDisplay.display].includes(values.displayCalendar) && (
                <FormGroup>
                    <Row>
                        <Col>
                            <Label>{t('noSlotsForm:message_preview_label')}</Label>
                            <TextGrey>{t('noSlotsForm:message_preview_description')}</TextGrey>
                            <NoSlotsMessagePreview
                                message={
                                    resultValues == null ? t('publicSearch:no_free_slots_found') : resultValues.message
                                }
                            />
                        </Col>
                    </Row>
                </FormGroup>
            )}
        </>
    );
};
