import React from 'react';
import { ModalBody, ModalTitle, ModalMessage } from './Modal.styled';
import { Button, GreyOutlineButton } from '../buttons';
import { Modal, ModalState } from './Modal';

type Props = {
    state: ModalState;
    title?: string;
    message?: string;
    confirmButton: string;
    declineButton: string;
    onConfirm: () => void;
    onDecline: () => void;
    isConfirmLoading?: boolean;
    confirmButtonTestId?: string;
    declineButtonTestId?: string;
};

type ModalPropKeys = 'state';

export const ConfirmModal = ({ state, ...rest }: Props) => {
    return <Modal state={state} isCentered={true} body={<ConfirmModalBody {...rest} />} />;
};

export const ConfirmModalBody = ({
    title,
    message,
    confirmButton,
    declineButton,
    onConfirm,
    onDecline,
    isConfirmLoading,
    confirmButtonTestId,
    declineButtonTestId,
}: Omit<Props, ModalPropKeys>) => {
    return (
        <ModalBody>
            <ModalTitle>{title}</ModalTitle>
            <ModalMessage>{message}</ModalMessage>
            <div style={{ maxWidth: 215 }}>
                <div className="mb-2">
                    <Button onClick={() => onConfirm()} isLoading={isConfirmLoading} data-testid={confirmButtonTestId}>
                        {confirmButton}
                    </Button>
                </div>
                <div>
                    <GreyOutlineButton isLarge onClick={() => onDecline()} data-testid={declineButtonTestId}>
                        {declineButton}
                    </GreyOutlineButton>
                </div>
            </div>
        </ModalBody>
    );
};
