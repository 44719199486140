import React from 'react';
import { HealthcareServiceSlots, NoSlotsBehaviour, SlotSimplified } from 'medrefer-web-sdk/api/models';
import { Container, ArrowControl, Arrow } from './SlotsCalendar.styled';
import { CalendarTimeline } from '../CalendarTimeline';
import BackArrow from 'medrefer-web-sdk/web-kit/assets/icons/svg/BackArrow.svg';
import { CalendarHook } from 'components/shared/ServiceSlots/SlotsCalendar/SlotsCalendar.hooks';
import { useTranslation } from 'react-i18next';
import { NoSlotsMessage } from 'components/shared/NoSlotsMessage';
import { CalendarTimelineProps } from 'components/shared/ServiceSlots/CalendarTimeline/CalendarTimeline';
import { NoSlotsWaitingRoomBehaviour } from '../RequestWaitingRoomModal/NoSlotsWaitingRoomBehaviour';
export interface SlotsCalendarProps extends CalendarTimelineProps {
    calendar: CalendarHook;
    noSlotsBehaviour: NoSlotsBehaviour;
    healthcareService: HealthcareServiceSlots;
}

export interface LoadMoreData {
    items: SlotSimplified[];
    nearestDateFrom: Date | null;
}

export const SlotsCalendar = ({
    calendar,
    noSlotsBehaviour,
    healthcareService,
    ...timelineProps
}: SlotsCalendarProps) => {
    const { t } = useTranslation();

    return (
        <Container>
            {calendar.noSlotsInFuture ? (
                noSlotsBehaviour.waiting_room ? (
                    <NoSlotsWaitingRoomBehaviour healthcareService={healthcareService} />
                ) : (
                    <NoSlotsMessage message={noSlotsBehaviour.message} />
                )
            ) : (
                <>
                    <ArrowControl>
                        <Arrow
                            src={BackArrow}
                            alt={t('publicSearch:calendar_previous_dates')}
                            title={t('publicSearch:calendar_previous_dates')}
                            isActive={calendar.hasPrev}
                            onClick={calendar.goPrev}
                        />
                    </ArrowControl>
                    <CalendarTimeline calendar={calendar} {...timelineProps} />
                    <ArrowControl>
                        <Arrow
                            src={BackArrow}
                            alt={t('publicSearch:calendar_next_dates')}
                            title={t('publicSearch:calendar_next_dates')}
                            isActive={calendar.hasNext}
                            onClick={() => calendar.goNext()}
                            isReversed
                        />
                    </ArrowControl>
                </>
            )}
        </Container>
    );
};
