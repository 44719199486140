import styled from 'styled-components';
import { TextGrey } from 'medrefer-web-sdk/web-kit';

export const Container = styled.div`
    width: 100%;
    max-width: 800px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background-color: #fff;
    margin: 15px auto;
    padding: 15px;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        max-width: 100vw;
        margin: 0;
    }
`;

export const PriceLabel = styled.div`
    padding: 6px 16px;
    text-align: center;
    font-weight: 600;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.accent}0f;
    color: ${({ theme }) => theme.accent};
    pointer-events: none;
    cursor: default;
    letter-spacing: 1px;
`;

export const ServiceComment = styled(TextGrey)`
    display: block;
    border-radius: 6px;
    background-color: ${({ theme }) => theme.accent}0f;
    color: ${({ theme }) => theme.accent};
`;
