import React from 'react';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import { PrimaryButton } from 'medrefer-web-sdk/web-kit';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useBrand from 'hooks/useBrand';

interface Props {
    activeListItem: AppointmentModel;
}

export const AppointmentVideoCall = ({ activeListItem }: Props) => {
    const { t } = useTranslation();
    const { getPathBranded } = useBrand();

    return (
        <Link to={getPathBranded('appointmentVideoCall', activeListItem.id.toString())}>
            <PrimaryButton>{t('patientPortal:myAppointments.join_call_button')}</PrimaryButton>
        </Link>
    );
};
