import React from 'react';
import {
    ProgressStepsContainer,
    ProgressLine,
    ProgressLineFilled,
    StepsList,
    Step,
    StepDot,
    StepText,
} from './ProgressSteps.styled';

export interface ProgressStep {
    name: string;
    title: string;
}

interface Props {
    steps: ProgressStep[];
    activeStep: string;
}

export const ProgressSteps = ({ steps, activeStep }: Props) => {
    const activeStepIdx = steps.findIndex((s) => activeStep === s.name);

    return (
        <ProgressStepsContainer>
            <ProgressLine />
            <ProgressLineFilled activeStep={activeStepIdx + 1} stepCount={steps.length} />
            <StepsList>
                {steps.map((step, index) => (
                    <Step key={step.name} stepCount={steps.length}>
                        <StepDot stepCount={steps.length} isActive={index <= activeStepIdx}>
                            {index + 1}
                        </StepDot>
                        <StepText>{step.title}</StepText>
                    </Step>
                ))}
            </StepsList>
        </ProgressStepsContainer>
    );
};
