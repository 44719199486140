import React from 'react';
import { SidebarListMenu } from '../SidebarListMenu';
import { SidebarListWrap, ScrollContainer } from './SidebarList.styled';
import { useDispatch } from 'react-redux';
import { InfiniteScrollList } from 'medrefer-web-sdk/web-kit';
import { AppointmentCard } from 'components/Dashboard/Sidebar/AppointmentCard';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import useBrand from 'hooks/useBrand';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import { AppointmentCardPlaceholder } from 'components/Dashboard/Sidebar/AppointmentCardPlaceholder';
import { routerActions } from 'connected-react-router';
import { useDashboardFilters } from 'components/Dashboard/hooks/useDashboardFilters';
import { useDashboardData } from 'components/Dashboard/hooks/useDashboardData';

export interface SidebarListProps {
    toggleShowAppointmentDetails: () => void;
}

export const SidebarList = ({ toggleShowAppointmentDetails }: SidebarListProps) => {
    const filters = useDashboardFilters();
    const { appointments, countData, hasMore, isLoading, loadMore, version, activeListItem } =
        useDashboardData(filters);
    const dispatch = useDispatch();

    const { t } = useTranslation();
    const location = useLocation();
    const { getPathBranded } = useBrand();

    const onAppointmentClick = (appointment: AppointmentModel) => {
        dispatch(
            routerActions.push(getPathBranded('dashboardActiveItem', appointment.id.toString()) + location.search),
        );
        toggleShowAppointmentDetails();
    };

    return (
        <SidebarListWrap>
            <SidebarListMenu filters={filters} countData={countData} />
            <ScrollContainer>
                <InfiniteScrollList
                    data={appointments}
                    version={version}
                    renderItem={({ item }) => (
                        <AppointmentCard
                            key={item.id}
                            appointment={item}
                            isActive={item.id === activeListItem?.id}
                            onClick={() => onAppointmentClick(item)}
                        />
                    )}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    ListEmptyComponent={
                        !hasMore && (
                            <div style={{ color: '#9D9EB2' }} className="w-100 d-flex justify-content-center mt-4">
                                {t('sidebarList:no_records')}
                            </div>
                        )
                    }
                    ListFooterComponent={
                        isLoading &&
                        (appointments.length === 0 ? (
                            <>
                                <AppointmentCardPlaceholder />
                                <AppointmentCardPlaceholder />
                                <AppointmentCardPlaceholder />
                                <AppointmentCardPlaceholder />
                                <AppointmentCardPlaceholder />
                                <AppointmentCardPlaceholder />
                            </>
                        ) : (
                            <AppointmentCardPlaceholder />
                        ))
                    }
                    useWindow={false}
                />
            </ScrollContainer>
        </SidebarListWrap>
    );
};
