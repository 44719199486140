import { SlotMode } from 'medrefer-web-sdk/api/models/Slot';
import { WithTranslation } from 'react-i18next';
import { Slot, SlotCriteriaIds, SlotData, SlotEditScope } from 'medrefer-web-sdk/api/models';
import { SchedulePopoverType, ScheduleType } from 'components/Calendar/Calendar.types';
import { ApiRequest } from 'hooks/useApiRequest';
import RRule from 'rrule';
import { HourRange } from 'components/Calendar/CalendarView/CalendarView.types';

export interface ActiveSchedule {
    id: string;
    dateFrom: Date;
    dateTo: Date;
    duration: number;
    type: ScheduleType;
}

export enum RecurringSlotOption {
    dontRepeat = 'dontRepeat',
    daily = 'daily',
    weekly = 'weekly',
    monthly = 'monthly',
    yearly = 'yearly',
    everyWeekday = 'everyWeekday',
    custom = 'custom',
}

export interface FormValues {
    healthcare_service: number;
    appointment_reasons: string[];
    repeat: string;
    status: string;
    date: Date;
    date_hour: Date;
    dateTo_hour: Date;
    duration: number;
    comment: string;
    scope: SlotEditScope;
    recurring_option: RecurringSlotOption;
    recurring_rule?: RRule;
    criteriaIds: SlotCriteriaIds[];
    mode: SlotMode | null;
    duration_type: number;
    slot_visibility: number;
}

export interface SchedulePopoverProps extends WithTranslation {
    type: SchedulePopoverType;
    activeSchedule: ActiveSchedule;
    request: ApiRequest;
    activeSlot: Slot | null;
    handleSubmit: (values: SlotData, slotKey: string, scope: SlotEditScope) => void;
    deleteSlot: () => void;
    hourRange: HourRange;
    closePopoverAndReloadAppointments: () => void;
}
