import { UserRole } from 'medrefer-web-sdk/api/models';

interface UserWithRole {
    role: UserRole;
}

export const canManageAnyService = (user: UserWithRole) => {
    return user.role === UserRole.organization;
};

export const canManageSlots = (user: UserWithRole) => {
    return [UserRole.organization, UserRole.organizationMember].includes(user.role);
};
