import React, { ChangeEvent, useState } from 'react';
import { RadioTile, UnderlineH2 as Title } from 'medrefer-web-sdk/web-kit';
import { GreyOutlineButton, Button } from 'medrefer-web-sdk/web-kit';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { SlotEditScope } from 'medrefer-web-sdk/api/models';
import { FormValues, SchedulePopoverProps } from 'components/Calendar/SchedulePopover/SchedulePopover.types';
import { FormikProps } from 'formik';

interface Props {
    title: string;
    acceptButton: string;
    declineButton: string;
    toggleModal: () => void;
    handleSubmit: (selectedOption: SlotEditScope) => void;
    baseProps: SchedulePopoverProps & FormikProps<FormValues>;
    isLoading?: boolean;
}

export const RecurringSlotActionConfirmation = ({
    title,
    acceptButton,
    declineButton,
    toggleModal,
    handleSubmit,
    isLoading,
}: Props) => {
    const [editOption, setEditOption] = useState(SlotEditScope.SINGLE);
    const { t } = useTranslation();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const option =
            value === SlotEditScope.SINGLE
                ? SlotEditScope.SINGLE
                : value === SlotEditScope.FOLLOWING
                ? SlotEditScope.FOLLOWING
                : SlotEditScope.ALL;

        setEditOption(option);
    };

    return (
        <ConfirmationContainer>
            <Title>{title}</Title>
            <div style={{ maxWidth: 215 }}>
                <RadioTile
                    name="editOption"
                    value={SlotEditScope.SINGLE}
                    checked={editOption === SlotEditScope.SINGLE}
                    title={t('calendar:recurringSlotActionConfirmation.thisSlotOnly')}
                    onChange={handleChange}
                    className="mt-2"
                />
                <RadioTile
                    name="editOption"
                    value={SlotEditScope.FOLLOWING}
                    checked={editOption === SlotEditScope.FOLLOWING}
                    title={t('calendar:recurringSlotActionConfirmation.thisAndUpcoming')}
                    onChange={handleChange}
                    className="mt-2"
                />
                <RadioTile
                    name="editOption"
                    value={SlotEditScope.ALL}
                    checked={editOption === SlotEditScope.ALL}
                    title={t('calendar:recurringSlotActionConfirmation.all')}
                    onChange={handleChange}
                    className="mt-2"
                />
                <div className="mt-4">
                    <Button onClick={() => handleSubmit(editOption)} isLoading={isLoading}>
                        {acceptButton}
                    </Button>
                </div>
                <div>
                    <GreyOutlineButton isLarge onClick={toggleModal}>
                        {declineButton}
                    </GreyOutlineButton>
                </div>
            </div>
        </ConfirmationContainer>
    );
};

const ConfirmationContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    label {
        margin-left: 0;
    }
`;
