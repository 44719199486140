import styled, { css } from 'styled-components';

export enum InformationBubbleTypes {
    warning,
    info,
    success,
}

interface InformationBubbleProps {
    type: InformationBubbleTypes;
}

const infoStyles = css`
    color: ${({ theme }) => theme.accent};
    background: ${({ theme }) => theme.accent}0f;

    blockquote {
        padding-left: 10px;
        border-left: 1.5px solid ${({ theme }) => theme.accent};
        margin-bottom: 0;

        > :last-child {
            margin-bottom: 0;
        }
    }

    a {
        color: ${({ theme }) => theme.accent};
        text-decoration: underline;
    }
`;
const warningStyles = css`
    color: #f92763;
    background: #ffeef3;
    min-height: 41px;
    margin-top: 0;
`;
const successStyles = css`
    background: #eaf9ec;
    color: #4cc13a;
    min-height: 41px;
    margin-top: 0;

    p {
        margin-bottom: 0;
    }
`;

export const InformationBubble = styled.div<InformationBubbleProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24px;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.15px;
    border-radius: 8px;
    width: 100%;
    min-height: 56px;
    padding: 11px 34px 13px 14px;
    ${(props) => props.type === InformationBubbleTypes.info && infoStyles};
    ${(props) => props.type === InformationBubbleTypes.warning && warningStyles};
    ${(props) => props.type === InformationBubbleTypes.success && successStyles};
`;

export const InformationContainer = styled.div`
    transform: translateY(-20px);
    margin-top: 25px;
`;
