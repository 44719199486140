import React from 'react';

import { SearchSubtitle, SearchTitle } from 'components/PublicSearch/LandingSearch/LandingSearch.styled';
import ServiceSlotsList from 'components/PublicSearch/SlotBooking/ServiceSlotsList';
import { useTranslation } from 'react-i18next';
import { ServiceSearchValues } from 'components/PublicSearch/SlotBooking/ServiceSearchForm';
import { SearchContainer } from './LandingSearch.styled';
import { SlotBookingProgress } from 'components/PublicSearch/SlotBookingProgress';

type Props = {
    filterParams: ServiceSearchValues;
};

export const LandingService = ({ filterParams }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <SlotBookingProgress activeStep="pickSlot" />
            <SearchContainer>
                <SearchTitle>{t('publicSearch:landing_search_brand_title')}</SearchTitle>
                <SearchSubtitle>{t('publicSearch:landing_search_service_subtitle')}</SearchSubtitle>
                <ServiceSlotsList filterParams={filterParams} />
            </SearchContainer>
        </>
    );
};
