import { useTranslation } from 'react-i18next';
import { Option } from 'medrefer-web-sdk/web-kit';
import { Frequency } from 'medrefer-web-sdk/api/models';

export const useFrequencyOptions = (intervalNumber: number): Option[] => {
    const { t } = useTranslation();

    if (intervalNumber === 1) {
        return [
            { label: t('calendar:frequency.day'), value: Frequency.DAILY },
            { label: t('calendar:frequency.week'), value: Frequency.WEEKLY },
            { label: t('calendar:frequency.month'), value: Frequency.MONTHLY },
            { label: t('calendar:frequency.year'), value: Frequency.YEARLY },
        ];
    }

    return [
        { label: t('calendar:frequency.days'), value: Frequency.DAILY },
        { label: t('calendar:frequency.weeks'), value: Frequency.WEEKLY },
        { label: t('calendar:frequency.months'), value: Frequency.MONTHLY },
        { label: t('calendar:frequency.years'), value: Frequency.YEARLY },
    ];
};
