import React from 'react';
import { SearchSubtitle, SearchTitle } from 'components/PublicSearch/LandingSearch/LandingSearch.styled';
import { ServiceSearchForm, ServiceSearchValues } from 'components/PublicSearch/SlotBooking/ServiceSearchForm';
import { useTranslation } from 'react-i18next';
import { SearchContainer } from './LandingSearch.styled';

type Props = {
    onSubmit: (values: ServiceSearchValues) => void;
    defaultValues: ServiceSearchValues;
};

export const LandingSearchForm = ({ onSubmit, defaultValues }: Props) => {
    const { t } = useTranslation();
    return (
        <SearchContainer style={{ paddingTop: '10vh' }}>
            <SearchTitle>{t('publicSearch:landing_search_brand_title')}</SearchTitle>
            <SearchSubtitle>{t('publicSearch:landing_search_brand_subtitle')}</SearchSubtitle>
            <ServiceSearchForm onSubmit={onSubmit} {...defaultValues} />
        </SearchContainer>
    );
};
