import React from 'react';
import { HealthcareService } from 'medrefer-web-sdk/api/models';
import { Cell, Column } from 'react-table';
import { getFullName } from 'medrefer-web-sdk/api/utils';

export const columns: (t: (key: string) => string) => Column<HealthcareService>[] = (t) => [
    {
        Header: t('adminPanel:table.columns.id'),
        accessor: 'id',
        sortParam: 'id',
        width: 80,
    },
    {
        Header: t('adminPanel:table.columns.service_name'),
        accessor: 'name',
        sortParam: 'name',
    },
    {
        Header: t('adminPanel:table.columns.category'),
        accessor: 'categories',
        Cell: (cell: Cell) => <p>{cell.value[0]?.name}</p>,
    },
    {
        Header: t('adminPanel:table.columns.locations'),
        accessor: 'location',
        sortParam: 'location__name',
        Cell: (cell: Cell) => <p>{cell.value?.name}</p>,
    },
    {
        Header: t('adminPanel:table.columns.comment'),
        sortParam: 'comment',
        accessor: 'comment',
    },
    {
        Header: t('adminPanel:table.columns.manager'),
        accessor: 'managing_user',
        Cell: (cell: Cell) => <p>{cell.value ? getFullName(cell.value) : '-'}</p>,
    },
];
