import { idNameToOption, Option } from 'medrefer-web-sdk/web-kit';
import { api } from 'medrefer-web-sdk/api';
import { ServiceCategory } from 'medrefer-web-sdk/api/models';

const useServiceCategoryOptions = () => {
    const toServiceCategoryOption = (category: ServiceCategory) => idNameToOption(category);

    const loadServiceCategoryOptions = async (search: string, loadedOptions: Option[]) => {
        const res = await api.getServiceCategories({
            limit: 10,
            offset: loadedOptions.length,
            search,
        });

        return {
            options: res.results.map(toServiceCategoryOption),
            hasMore: res.next != null,
        };
    };

    const recoverServiceCategoryOptions = async (missingKeys: string[]) => {
        const res = await api.getServiceCategories({
            id__in: missingKeys.join(','),
        });
        return res.results.map(toServiceCategoryOption);
    };

    return {
        toServiceCategoryOption,
        loadServiceCategoryOptions,
        recoverServiceCategoryOptions,
    };
};

export default useServiceCategoryOptions;
