import styled from 'styled-components';

export const FormGroup = styled.div`
    padding: 12px 0;
    position: relative;
    margin-bottom: 12px;

    .services-multiselect {
        .Select__indicator:nth-child(1) {
            display: none;
        }
    }
`;
