import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Button, Label, LabelSubtitle, Thumb, ErrorMessage } from 'medrefer-web-sdk/web-kit';
import { CustomRow } from 'components/Dashboard/Appointment/AppointmentDetails/AppointmentDetails.styled';
import { CustomCol } from './AttachDocuments.styled';
import useApiRequest from 'hooks/useApiRequest';
import { useAttachDocuments } from 'hooks/useAttachDocuments';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';
import ScreenCapture from '@uppy/screen-capture';
import Compressor from '@uppy/compressor';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/audio/dist/style.css';
import '@uppy/screen-capture/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import '@uppy/webcam/dist/style.css';
import { getActiveLanguage } from 'utils/i18n';
import German from '@uppy/locales/lib/de_DE';
import Polish from '@uppy/locales/lib/pl_PL';
import English from '@uppy/locales/lib/en_US';
interface Props {
    appointment: AppointmentModel;
    toggleModal: () => void;
    afterUpload?: () => void;
}

export const AttachDocuments = ({ appointment, toggleModal, afterUpload }: Props) => {
    const { t } = useTranslation();
    const request = useApiRequest();

    const {
        attachments,
        uploadInput,
        setAttachments,
        handleFileChange,
        handleFileDelete,
        uploadAttachments,
        attachmentError,
    } = useAttachDocuments();

    const onSubmit = async () => {
        await request.dispatch(uploadAttachments(appointment));
        toggleModal();
        if (afterUpload) {
            afterUpload();
        }
    };

    const activeLocaleString = getActiveLanguage();
    const locale = activeLocaleString == 'de' ? German : activeLocaleString == 'pl' ? Polish : English;
    const uppy = React.useMemo(() => {
        return new Uppy({
            locale: locale,

            restrictions: {
                maxNumberOfFiles: 5,
                minNumberOfFiles: 0,
                maxFileSize: 5 * 1024 * 1024, //5mb
            },
        })
            .use(Webcam, {})
            .use(Compressor, {})
            .use(ScreenCapture, {});
    }, [locale]);
    React.useEffect(() => {
        return () => uppy.close({ reason: 'unmount' });
    }, [uppy]);

    uppy.on('complete', (result) => {
        const fileList = result.successful.map((file) => {
            return new File([file.data], file.name, { type: file.type });
        });

        setAttachments(fileList);
    });

    return (
        <>
            <CustomRow style={{}}>
                <CustomCol>
                    <Form.Label>
                        <Label style={{ top: '0px' }}>{t('attachDocumentsModalBody:title')}</Label>
                    </Form.Label>
                    <LabelSubtitle>{t('attachDocumentsModalBody:subtitle')}</LabelSubtitle>
                </CustomCol>
            </CustomRow>
            <input
                title="imageUpload"
                ref={uploadInput}
                id="attachments"
                name="attachments"
                type="file"
                onChange={(e) => handleFileChange(e, 5000, 5)}
                style={{ visibility: 'hidden', zIndex: 90 }}
            />
            <Dashboard
                height={'450px'}
                style={{ paddingBottom: '10px' }}
                uppy={uppy}
                plugins={['Webcam', 'ScreenCast', 'Unsplash', 'ScreenCapture']}
                metaFields={[
                    { id: 'name', name: 'Name', placeholder: 'file name' },
                    { id: 'caption', name: 'Caption', placeholder: 'describe what the file is for' },
                ]}
            />
            {attachments &&
                attachments.map(({ name }, index) => {
                    return <Thumb key={name} name={name} click={() => handleFileDelete(index)} />;
                })}

            <div style={{ margin: '0 auto', maxWidth: 215, paddingTop: '10px' }}>
                <Button onClick={onSubmit} isLoading={request.isLoading}>
                    {t('attachDocumentsModalBody:submit_button')}
                </Button>
            </div>

            {attachmentError && <ErrorMessage className="mt-1">{attachmentError}</ErrorMessage>}
        </>
    );
};
