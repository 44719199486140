import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { CalendarType, DateRange } from './CalendarView.types';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import { formatISODate } from 'medrefer-web-sdk/api/utils';
import moment from 'moment';

export interface CalendarState {
    date: Date;
    dateRange: DateRange | null;
    calendarType: CalendarType;
    syncDate: Dispatch<Date>;
    syncDateRange: Dispatch<DateRange>;
    syncCalendarType: Dispatch<SetStateAction<CalendarType>>;
}

export const useCalendarState = (): CalendarState => {
    const queryParams = useQueryParams();

    const initialDate = useMemo(() => {
        const date = queryParams.get('date');

        if (date && moment(date).isValid()) {
            return new Date(date);
        }
        return new Date();
        // eslint-disable-next-line
    }, []);

    const initialCalendarType = useMemo((): CalendarType => {
        const calendarType = queryParams.get('view');
        return (calendarType || CalendarType.week) as CalendarType;
        // eslint-disable-next-line
    }, []);

    const [calendarType, syncCalendarType] = useState(initialCalendarType);
    const [dateRange, setDateRange] = useState<DateRange | null>(null);
    const [date, setDate] = useState(initialDate);

    useEffect(() => {
        queryParams.set('view', calendarType);
        // eslint-disable-next-line
    }, [calendarType]);

    useEffect(() => {
        queryParams.set('date', formatISODate(moment(date)));
        // eslint-disable-next-line
    }, [date]);

    const syncDate = (value: Date) => {
        setImmediate(() => setDate(value));
    };

    const syncDateRange = (value: DateRange) => {
        setImmediate(() => setDateRange(value));
    };

    return {
        date,
        dateRange,
        calendarType,
        syncDate,
        syncDateRange,
        syncCalendarType,
    };
};
