import { Accordion, Col, Row } from 'react-bootstrap';
import {
    Button,
    ContextAwareToggle,
    ErrorMessage,
    FormGroup,
    Label,
    Modal,
    TextArea,
    useModalState,
} from '../../../../../medrefer-web-sdk/web-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AdminOrganizationFormProps, AdminOrganizationFormValues } from '../AdminOrganizationForm.types';
import { FormikProps } from 'formik';
import { MailPlaceholderTextModal } from './MailPlaceholderTextModal';

interface Props {
    props: AdminOrganizationFormProps & FormikProps<AdminOrganizationFormValues>;
}
export const EmailTextFooterModule = ({ props }: Props) => {
    const { t } = useTranslation();
    const modal = useModalState();

    return (
        <FormGroup>
            <Accordion defaultActiveKey="0">
                <Row>
                    <ContextAwareToggle eventKey="0">
                        {t('organizationProfileDisplay:email_text_options_label')}
                    </ContextAwareToggle>
                    <Accordion.Collapse eventKey="0" style={{ width: '95%', marginLeft: 16, marginRight: -1 }}>
                        <>
                            {props.values.supported_languages.includes('pl') && (
                                <div style={{ marginBottom: 10 }}>
                                    <Row className="primary">
                                        <Col xs={12} md={4}>
                                            <Label>{t('organizationProfileDisplay:email_text_label') + ' (pl)'}</Label>
                                        </Col>
                                        <Col xs={12} md={8}>
                                            <TextArea
                                                isError={!!(props.touched.email_text_pl && props.errors.email_text_pl)}
                                                id="email_text_pl"
                                                onChange={props.handleChange}
                                                value={props.values.email_text_pl}
                                                onBlur={props.handleBlur}
                                                style={{ height: '130px' }}
                                                placeholder={t('organizationProfileDisplay:email_text_placeholder')}
                                            />
                                        </Col>
                                    </Row>
                                    {props.touched.email_text_pl && props.errors.email_text_pl && (
                                        <ErrorMessage className="text-right mt-1">
                                            {props.errors.email_text_pl}
                                        </ErrorMessage>
                                    )}
                                </div>
                            )}
                            {props.values.supported_languages.includes('en') && (
                                <div style={{ marginBottom: 10 }}>
                                    <Row className="primary">
                                        <Col xs={12} md={4}>
                                            <Label>{t('organizationProfileDisplay:email_text_label') + ' (en)'}</Label>
                                        </Col>
                                        <Col xs={12} md={8}>
                                            <TextArea
                                                isError={!!(props.touched.email_text_en && props.errors.email_text_en)}
                                                id="email_text_en"
                                                onChange={props.handleChange}
                                                value={props.values.email_text_en}
                                                onBlur={props.handleBlur}
                                                style={{ height: '130px' }}
                                                placeholder={t('organizationProfileDisplay:email_text_placeholder')}
                                            />
                                        </Col>
                                    </Row>
                                    {props.touched.email_text_en && props.errors.email_text_en && (
                                        <ErrorMessage className="text-right mt-1">
                                            {props.errors.email_text_en}
                                        </ErrorMessage>
                                    )}
                                </div>
                            )}
                            {props.values.supported_languages.includes('de') && (
                                <div style={{ marginBottom: 10 }}>
                                    <Row className="primary">
                                        <Col xs={12} md={4}>
                                            <Label>{t('organizationProfileDisplay:email_text_label') + ' (de)'}</Label>
                                        </Col>
                                        <Col xs={12} md={8}>
                                            <TextArea
                                                isError={!!(props.touched.email_text_de && props.errors.email_text_de)}
                                                id="email_text_de"
                                                onChange={props.handleChange}
                                                value={props.values.email_text_de}
                                                onBlur={props.handleBlur}
                                                style={{ height: '130px' }}
                                                placeholder={t('organizationProfileDisplay:email_text_placeholder')}
                                            />
                                        </Col>
                                    </Row>
                                    {props.touched.email_text_de && props.errors.email_text_de && (
                                        <ErrorMessage className="text-right mt-1">
                                            {props.errors.email_text_de}
                                        </ErrorMessage>
                                    )}
                                </div>
                            )}
                            <Modal
                                state={modal}
                                isCentered={true}
                                button={
                                    <Col
                                        xs={12}
                                        md={6}
                                        className="ml-auto mr-auto"
                                        style={{ marginTop: 20, order: -99999999999999 }}
                                    >
                                        <Button inverseColors={true} type={'button'}>
                                            Show email template
                                        </Button>
                                    </Col>
                                }
                                body={<MailPlaceholderTextModal />}
                            />
                        </>
                    </Accordion.Collapse>
                </Row>
            </Accordion>
        </FormGroup>
    );
};
