import React, { ReactElement } from 'react';
import { PrimaryButton } from '..';
import { ButtonSpinner, SpinnerColorVariant } from './ButtonSpinner';
import * as Styled from './Button.styled';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    isLoading?: boolean;
    inverseColors?: boolean;
}

export const Button = ({ isLoading, children, ...rest }: Props): ReactElement => {
    return (
        <Styled.ButtonContainer>
            <PrimaryButton disabled={isLoading} {...rest}>
                {isLoading ? (
                    <ButtonSpinner spinnerColorVariant={SpinnerColorVariant.accent} />
                ) : (
                    <span>{children}</span>
                )}
            </PrimaryButton>
        </Styled.ButtonContainer>
    );
};
