import React from 'react';
import { Form, FormikProps, withFormik } from 'formik';
import { useTranslation, withTranslation } from 'react-i18next';
import { mapPropsToValues, validationSchema } from './QuestionnaireForm.schema';
import {
    FormErrorMessage,
    Button,
    TextRed,
    UnderlineH2 as Title,
    InformationContainer,
    FormGroup,
    Label,
    Input,
    TextArea,
} from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';
import { QuestionnaireFormProps } from './QuestionnaireForm.types';
import { QuestionnaireData } from 'medrefer-web-sdk/api/models';

export const InnerForm = (props: QuestionnaireFormProps & FormikProps<QuestionnaireData>) => {
    const { t } = useTranslation();
    return (
        <>
            <Title>
                {props.type === ModalType.add
                    ? t('adminPanel:manageQuestionnaires.form.title_add')
                    : t('adminPanel:manageQuestionnaires.form.title_edit')}
            </Title>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                }}
            >
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <Label isRequired>{t('adminPanel:manageQuestionnaires.form.title_label')}</Label>
                        </Col>
                        <Col xs={12}>
                            <Input
                                isError={false}
                                id="title"
                                name="title"
                                value={props.values.title}
                                type="text"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                            />
                        </Col>
                        <Col xs={12} style={{ marginTop: '20px' }}>
                            <Label isRequired>{t('adminPanel:manageQuestionnaires.form.content_label')}</Label>
                        </Col>
                        <Col xs={12}>
                            <TextArea
                                isError={false}
                                id="content"
                                name="content"
                                value={props.values.content}
                                placeholder={t('adminPanel:manageQuestionnaires.form.content_label')}
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                style={{ height: '600px' }}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <InformationContainer>
                    <FormErrorMessage apiErrors={props.request.errors} />
                </InformationContainer>
                <Row>
                    <Col className="m-auto" xs={12} md={12}>
                        <Button isLoading={props.request.isLoading}>
                            {props.type === ModalType.add
                                ? t('adminPanel:manageQuestionnaires.form.button_add')
                                : t('adminPanel:manageQuestionnaires.form.button_edit')}
                        </Button>
                        {!props.isValid && !props.isValidating && (
                            <TextRed className="text-right mr-3 mt-4">
                                {t('newAppointmentForm:warning_please_fill_all')}
                            </TextRed>
                        )}
                    </Col>
                </Row>
            </Form>
        </>
    );
};

const LocationsForm = withFormik<QuestionnaireFormProps, QuestionnaireData>({
    mapPropsToValues: mapPropsToValues,
    validationSchema: validationSchema,
    handleSubmit: (values, { props }) => {
        props.handleSubmit(values);
    },
})(InnerForm);

export default withTranslation()(LocationsForm);
