import React from 'react';
import { SlotCriteria } from 'medrefer-web-sdk/api/models';
import { Column } from 'react-table';
import { BooleanCell } from 'components/shared/DataTable/BooleanCell';

export const columns: (t: (key: string) => string) => Column<SlotCriteria>[] = (t) => [
    {
        Header: t('adminPanel:table.columns.id'),
        accessor: 'id',
        width: 80,
        sortParam: 'id',
    },
    {
        Header: t('adminPanel:table.columns.name'),
        accessor: 'name',
        sortParam: 'name',
    },
    {
        Header: t('adminPanel:table.columns.values'),
        accessor: (slotCriteria) =>
            slotCriteria.values.map((value, index) => (
                <span key={value.name}>
                    {index > 0 && ', '}
                    {value.name}
                </span>
            )),
    },
    {
        Header: t('adminPanel:table.columns.required'),
        accessor: 'required',
        Cell: BooleanCell,
        sortParam: 'required',
    },
    {
        Header: t('adminPanel:table.columns.depends_on'),
        accessor: (slotCriteria) => {
            if (slotCriteria.enable_when?.criteria && slotCriteria.enable_when?.criteria_value) {
                return (
                    <span>
                        {slotCriteria.enable_when.criteria.name} | {slotCriteria.enable_when.criteria_value.name}
                    </span>
                );
            } else if (slotCriteria.enable_when?.healthcare_service) {
                return <span>{slotCriteria.enable_when.healthcare_service.name}</span>;
            } else {
                return <span>-</span>;
            }
        },
        sortParam: 'enable_when__criteria_value__name',
    },
];
