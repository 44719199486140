import React, { ChangeEvent, useState } from 'react';
import {
    Pagination,
    PaginationButton,
    PaginationNavigation,
    PaginationSettings,
    TableControlsContainer,
    TableFilters,
    TableSearchInput,
} from './TableControls.styled';

import { Select, Option, SingleValue } from '../../../';
import { TableControlsProps } from './TableControls.types';
import { useNonInitialEffect, useDelayedState } from '../../../hooks';

export const TableControls = ({
    searchPhrase,
    setSearchPhrase,
    tableFilters,
    pageSize,
    pageSizeOptions,
    setPageSize,
    pageIndex,
    previousPage,
    canPreviousPage,
    nextPage,
    canNextPage,
    pageOptions,
    previousPageText,
    nextPageText,
    gotoPage,
}: TableControlsProps) => {
    const [search, setSearch, delayedSearch] = useDelayedState<string>(searchPhrase);
    const [pageOption, setPageOption] = useState(
        pageSizeOptions.find((option) => option.value === pageSize.toString()),
    );

    const handlePageSideChange = (option: SingleValue<Option>) => {
        if (option) {
            const pageSize = option.value;
            setPageSize(parseInt(pageSize));
            setPageOption(option);
        }
    };

    const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
    };

    useNonInitialEffect(() => {
        setSearchPhrase(delayedSearch);
    }, [delayedSearch]);

    return (
        <TableControlsContainer>
            <div>
                <TableSearchInput value={search} onChange={handleSearchChange} />
            </div>

            <TableFilters>
                {tableFilters?.map(({ name, placeholder, options, handleOptionChange }) => (
                    <Select
                        key={name}
                        name={name}
                        onChange={handleOptionChange}
                        placeholder={placeholder}
                        options={options}
                    />
                ))}
            </TableFilters>
            {pageOptions.length > 1 && (
                <Pagination>
                    <PaginationSettings>
                        <Select
                            name={'type'}
                            value={pageOption}
                            onChange={handlePageSideChange}
                            placeholder={'Type'}
                            options={pageSizeOptions}
                        />
                    </PaginationSettings>
                    <PaginationNavigation>
                        <PaginationButton
                            className="left"
                            type="button"
                            onClick={previousPage}
                            disabled={!canPreviousPage}
                        >
                            {previousPageText}
                        </PaginationButton>
                        {pageOptions.map((pageOption: number, index: number) => {
                            if (index < 2 || Math.abs(pageIndex - index) <= 1) {
                                return (
                                    <React.Fragment key={index}>
                                        <PaginationButton
                                            key={pageOption}
                                            className={`page ${pageIndex === pageOption ? 'active' : ''}`}
                                            type="button"
                                            onClick={() => gotoPage(pageOption)}
                                        >
                                            {pageOption + 1}
                                        </PaginationButton>
                                        {pageIndex > 3 && index === 1 && (
                                            <PaginationButton
                                                key={pageOption + 'placeholder-start'}
                                                className={`page ${pageIndex === pageOption ? 'active' : ''}`}
                                                type="button"
                                                disabled
                                            >
                                                ...
                                            </PaginationButton>
                                        )}
                                    </React.Fragment>
                                );
                            }

                            if (index === pageOptions.length - 1) {
                                return (
                                    <React.Fragment key={index}>
                                        {pageIndex !== pageOptions.length - 3 && (
                                            <PaginationButton
                                                key={pageOption + 'placeholder-end'}
                                                className={`page ${pageIndex === pageOption ? 'active' : ''}`}
                                                type="button"
                                                disabled
                                            >
                                                ...
                                            </PaginationButton>
                                        )}
                                        <PaginationButton
                                            key={pageOption}
                                            className={`page ${pageIndex === pageOption ? 'active' : ''}`}
                                            type="button"
                                            onClick={() => gotoPage(pageOption)}
                                        >
                                            {pageOption + 1}
                                        </PaginationButton>
                                    </React.Fragment>
                                );
                            }
                        })}
                        <PaginationButton className="right" type="button" onClick={nextPage} disabled={!canNextPage}>
                            {nextPageText}
                        </PaginationButton>
                    </PaginationNavigation>
                </Pagination>
            )}
        </TableControlsContainer>
    );
};
