import React from 'react';
import { Field, FormikProps } from 'formik';
import { FormAsyncSelect, TextArea, Option } from 'medrefer-web-sdk/web-kit';
import { FormGroup, Input, Label } from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
    LocationFormValues,
    LocationsFormProps,
} from 'components/AdminPanel/ManageLocations/LocationsForm/LocationsForm.types';
import { api } from 'medrefer-web-sdk/api';
import useLocationAutocompleteOptions from 'hooks/options/useLocationAutocompleteOptions';

interface Props {
    baseProps: LocationsFormProps & FormikProps<LocationFormValues>;
}

export const LocationsData = ({ baseProps }: Props) => {
    const { t } = useTranslation();
    const { activeItem } = baseProps;
    const { loadLocationAutocompleteOptions } = useLocationAutocompleteOptions();

    const handleLocationChange = (option: Option) => {
        api.getLocationPlace(option.value.toString()).then((place) => {
            baseProps.setFieldValue('position', place.position);
            baseProps.setFieldValue('address', place.address);
        });
    };

    return (
        <>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label isRequired>{t('adminPanel:manageLocations.locationsForm.location_name_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Input
                            isError={false}
                            id="name"
                            name="name"
                            value={baseProps.values.name}
                            type="text"
                            onChange={baseProps.handleChange}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label>{t('adminPanel:manageLocations.locationsForm.description_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <TextArea
                            isError={false}
                            id="description"
                            name="description"
                            value={baseProps.values.description}
                            placeholder={t('adminPanel:manageLocations.locationsForm.description_placeHolder')}
                            onChange={baseProps.handleChange}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label isRequired>{t('adminPanel:manageLocations.locationsForm.address_label')}</Label>
                    </Col>

                    <Col xs={12} md={8}>
                        <Field
                            name="addressSelect"
                            loadOptions={loadLocationAutocompleteOptions}
                            initialOptions={activeItem && [{ label: activeItem.address.text, value: 'default' }]}
                            component={FormAsyncSelect}
                            placeholder={t('adminPanel:manageLocations.locationsForm.address_placeholder')}
                            debounceTimeout={400}
                            minSearchLength={3}
                            onBlur={baseProps.handleBlur}
                            afterChange={handleLocationChange}
                        />
                    </Col>
                </Row>
            </FormGroup>
        </>
    );
};
