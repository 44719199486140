import styled from 'styled-components';

export const BirthDayAppointmentHeader = styled.div`
    padding-left: 20px;
    line-height: 36px;
    color: #9d9eb2;
`;

export const InfoDashedPanel = styled.div`
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 11px;
    padding-bottom: 10px;
    width: 100%;
    border-bottom: 1px solid #eeeef2;
    > div:nth-of-type(1) {
        height: 11px;
        padding-right: 20px;
        border-right: 1px solid #eeeef2;
    }
    > div:nth-of-type(2) {
        height: 11px;
        padding-left: 20px;
        display: flex;
        span {
            margin-right: 5px;
            color: #9d9eb2;
        }
    }
`;

export const HeaderBadgesContainer = styled.div`
    display: flex;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        flex-wrap: wrap;
        gap: 5px;
    }
`;

export const ActionMenuMobile = styled.div`
    display: none;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        margin: 10px 0;
        .action-label {
            display: none;
        }
    }
`;

export const ActionMenuDesktop = styled.div`
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        display: none;
    }
`;
