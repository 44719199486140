import i18n from '../i18n/i18n';
import { Feature, GeneralSearchItemType, InsuranceStatus, UserRole } from 'medrefer-web-sdk/api/models';

export const getActiveLanguage = () => {
    return i18n.languages[0];
};

export const mapInsuranceToTranslation = (insuranceStatusString: string | undefined) => {
    switch (insuranceStatusString) {
        case InsuranceStatus.PUBLIC:
            return i18n.t('insuranceStatus:public');
        case InsuranceStatus.PRIVATE:
            return i18n.t('insuranceStatus:private');
        case InsuranceStatus.SELF_PAYMENT:
            return i18n.t('insuranceStatus:self_payment');
        case InsuranceStatus.OTHER:
            return i18n.t('insuranceStatus:other');
        default:
            return 'Unknown insurance';
    }
};

export const translateGeneralSearchItemType = (value: GeneralSearchItemType) => {
    switch (value) {
        case GeneralSearchItemType.healthcare_service_group:
            return 'group';
        case GeneralSearchItemType.healthcare_service:
            return i18n.t('generalSearchItemType:healthcare_service');
        case GeneralSearchItemType.practitioner:
            return i18n.t('generalSearchItemType:practitioner');
        case GeneralSearchItemType.appointment_reason:
            return i18n.t('generalSearchItemType:appointment_reason');
        default:
            return 'Unknown group';
    }
};

export const translateUserRole = (role: UserRole) => {
    switch (role) {
        case UserRole.patient:
            return i18n.t('userRole:patient');
        case UserRole.organization:
            return i18n.t('userRole:organization');
        case UserRole.organizationMember:
            return i18n.t('userRole:organization_member');
        case UserRole.cooperator:
            return i18n.t('userRole:cooperator');
        case UserRole.admin:
            return i18n.t('userRole:admin');
        default:
            return 'Unknown role';
    }
};

export const translateFeature = (value: Feature) => {
    switch (value) {
        case Feature.videoCalls:
            return i18n.t('features:labels.video_calls');
        case Feature.questionnaires:
            return i18n.t('features:labels.questionnaires');
        case Feature.appointmentPricing:
            return i18n.t('features:labels.appointment_pricing');
        case Feature.stripePayments:
            return i18n.t('features:labels.stripe_payments');
        default:
            return 'Unknown feature';
    }
};
