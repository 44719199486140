import useApiRequest from 'hooks/useApiRequest';
import { LoginData, LoginCredentials, UserUpdateData } from 'medrefer-web-sdk/api/models';
import { loginUser } from 'features/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams, useTemporaryState } from 'medrefer-web-sdk/web-kit/hooks';
import { api } from 'medrefer-web-sdk/api';
import { errorTimeout, messageTimeout } from 'utils/constants';
import { useEffect } from 'react';
import { authActions } from '../../features/auth';
import { getBrand } from '../../features/auth/selectors';

const useLoginFlow = () => {
    const request = useApiRequest({ errorTimeout });
    const queryParams = useQueryParams();
    const afterPasswordReset = queryParams.get('password-reset') === 'true';
    const [emailNotFound, setEmailNotFound] = useTemporaryState(false, errorTimeout);
    const [otpCodeSent, setOtpCodeSent] = useTemporaryState(false, messageTimeout);
    const [passwordReset, setPasswordReset] = useTemporaryState(false, messageTimeout);
    const dispatch = useDispatch();
    const organization = useSelector(getBrand);

    useEffect(() => {
        if (afterPasswordReset) {
            setPasswordReset(true);
        }
    }, [afterPasswordReset, setPasswordReset]);

    const updateProfile = async (data: UserUpdateData) => {
        await dispatch(authActions.updateUserData(data));
    };

    const ensureEmailExists = async (data: LoginCredentials) => {
        const exists = await request.dispatch(api.checkEmailTaken(data.email));
        setEmailNotFound(!exists);
        return exists;
    };

    const submitCredentials = async (data: LoginCredentials) => {
        await request.dispatch(
            api.requestLoginOtp({
                email: data.email,
                password: data.password,
                organization_id: organization?.id,
            }),
        );
        setOtpCodeSent(true);
    };

    const submitOtp = async (data: LoginCredentials) => {
        await request.dispatch(dispatchLogin(data));
    };

    const dispatchLogin = async (data: LoginData) => {
        await dispatch(loginUser(data));
    };

    return {
        emailNotFound,
        otpCodeSent,
        passwordReset,
        request,
        ensureEmailExists,
        submitCredentials,
        submitOtp,
        updateProfile,
    };
};

export default useLoginFlow;
