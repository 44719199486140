/* eslint-disable no-unused-vars */
import React, { FC } from 'react';
import { Input } from '../index';
import * as Styled from './SwitchInput.styled';

interface Props {
    label?: string;
    id: string;
    isChecked: boolean | undefined;
    isReadOnly?: boolean;
    isError?: boolean;
    testId?: string;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SwitchInput: FC<Props> = ({ label, id, isChecked, isError, testId, handleChange, isReadOnly }: Props) => {
    return (
        <Styled.Switch isReadOnly={isReadOnly}>
            <Input
                id={id}
                className="toggle-checkbox"
                type="checkbox"
                aria-label="switch"
                checked={isChecked}
                onChange={handleChange}
                isError={isError}
                disabled={isReadOnly}
            />
            <div className="toggle-switch"></div>
            {label && (
                <label
                    className="toggle-label"
                    style={{ visibility: isChecked ? 'visible' : label ? 'visible' : 'hidden' }}
                    data-testid={testId}
                >
                    {label}
                </label>
            )}
        </Styled.Switch>
    );
};
