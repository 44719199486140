import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from 'assets/Logo.svg';
import { ReactSVG } from 'react-svg';
import useBrand from 'hooks/useBrand';
import { BrandLogo } from './AppLogo.styled';
import { getPath } from 'router-paths';
import { Image } from 'react-bootstrap';

export const AppLogo = ({ className, isSidebarVisible }: { className?: string; isSidebarVisible?: boolean }) => {
    const { organization, getPathBranded } = useBrand();
    const location = useLocation();
    const linkTo = location.pathname.includes(getPath('admin')) ? getPath('admin') : getPathBranded('landing');
    return (
        <BrandLogo>
            <Link to={linkTo}>
                {organization ? (
                    <>
                        {organization.branding.logo ? (
                            <Image
                                src={organization.branding.logo?.file || ''}
                                style={
                                    isSidebarVisible
                                        ? { paddingLeft: '70px', paddingRight: '10px', maxHeight: '50px' }
                                        : { maxHeight: '50px', paddingRight: '10px' }
                                }
                            ></Image>
                        ) : (
                            <span style={isSidebarVisible ? { paddingLeft: '70px' } : {}}>{organization.name}</span>
                        )}
                    </>
                ) : (
                    <ReactSVG
                        src={Logo}
                        className={className}
                        style={isSidebarVisible ? { paddingLeft: '70px' } : {}}
                    />
                )}
            </Link>
        </BrandLogo>
    );
};
