import React, { FC } from 'react';
import { MyAppointmentsData } from 'components/PatientPanel/MyAppointments/MyAppointments.types';
import { AppointmentListItem } from './AppointmentListItem';
import { LoadMoreList } from 'medrefer-web-sdk/web-kit';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import { useTranslation } from 'react-i18next';

interface AppointmentsListProps {
    appointmentsData: MyAppointmentsData;
    loadMore?: (prev: AppointmentModel[]) => void;
    noItemsMessage?: string;
    showItemControls?: boolean;
    cancelAppointment?: (appointmentId: number) => void;
    rescheduleAppointment?: (appointmentId: number, organizationId: number, healthcareServiceId: number) => void;
    reloadAppointments?: () => void;
}
export const AppointmentsList: FC<AppointmentsListProps> = ({
    appointmentsData,
    loadMore,
    noItemsMessage,
    showItemControls,
    cancelAppointment,
    rescheduleAppointment,
    reloadAppointments,
}: AppointmentsListProps) => {
    const { t } = useTranslation();
    return (
        <div>
            <LoadMoreList
                data={appointmentsData.appointments}
                hasMore={!!appointmentsData.next}
                isLoading={appointmentsData.isLoading}
                loadMore={loadMore}
                renderItem={({ item }) => {
                    return (
                        <AppointmentListItem
                            appointment={item}
                            showControls={showItemControls}
                            cancelAppointment={cancelAppointment}
                            rescheduleAppointment={rescheduleAppointment}
                            reloadAppointments={reloadAppointments}
                        />
                    );
                }}
                ListEmptyComponent={!appointmentsData.isLoading && <p>{noItemsMessage}</p>}
                loadMoreText={t('pagination:load_more')}
            />
        </div>
    );
};
