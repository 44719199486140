import { useCallback, useMemo, useState } from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { Slot, SlotCriteria, SlotData, SlotEditScope, SlotPermission } from 'medrefer-web-sdk/api/models';
import { api } from 'medrefer-web-sdk/api';
import { useModalState, useTableOrdering } from 'medrefer-web-sdk/web-kit';
import { Schedule } from './CalendarTable.types';
import { getActiveLanguage } from '../../../utils/i18n';
import { useQueryParams } from '../../../medrefer-web-sdk/web-kit/hooks';
import { useTranslation } from 'react-i18next';

export const useSlotAndAppointments = () => {
    const { t } = useTranslation();
    const { ordering, sortHandler } = useTableOrdering(['date_from'], true);
    const [activeItem, setActiveItem] = useState<Slot | null>(null);
    const [data, setData] = useState<Schedule[]>([]);
    const [pageCount, setPageCount] = useState(1);
    const requestLoadAppointments = useApiRequest();
    const editModalState = useModalState({ actionOnClose: () => setActiveItem(null) });
    const editModalSlotState = useModalState({ actionOnClose: () => setActiveItem(null) });
    const completedRequestModalState = useModalState();
    const requestErrorModalState = useModalState();
    const [completedRequestCount, setCompletedRequestCount] = useState(0);
    const [checkedCount, setCheckedCount] = useState(0);
    const [tableParams, setTableParams] = useState<{ pageIndex: number; pageSize: number; searchPhrase: string }>({
        pageIndex: 0,
        pageSize: 20,
        searchPhrase: '',
    });
    const checkForSlotPermissionNames = (searchString: string) => {
        if (searchString.length >= 4) {
            if (t('newSlotForm:slot_private').toLowerCase().includes(searchString.toLowerCase())) return 'priv';
            if (t('newSlotForm:slot_public').toLowerCase().includes(searchString.toLowerCase())) return 'pub';
            if (t('newSlotForm:slot_internal').toLowerCase().includes(searchString.toLowerCase())) return 'int';
        }
        return searchString;
    };
    const queryParams = useQueryParams();
    if (requestLoadAppointments.errors) {
        if (!requestErrorModalState.isOpen) requestErrorModalState.toggleModal();
    }
    const msToMin = (dateTo: string, dateFrom: string) => {
        return (new Date(dateTo).getTime() - new Date(dateFrom).getTime()) / (60 * 1000);
    };
    const unselectAll = () => {
        setData((prevState) => {
            prevState.forEach((schedule) => {
                schedule.isChecked = false;
            });
            return [...prevState];
        });
        countChecked();
    };
    const selectAll = () => {
        setData((prevState) => {
            prevState.forEach((schedule) => {
                if (schedule.date_from > new Date()) schedule.isChecked = true;
            });
            return [...prevState];
        });
        countChecked();
    };
    const checkSchedule = (key: string) => {
        setData((prevState) => {
            const toCheck = prevState.findIndex((schedule) => {
                return schedule.key == key;
            });
            if (toCheck != -1) {
                prevState[toCheck].isChecked = !prevState[toCheck].isChecked;
                return [...prevState];
            } else {
                return prevState;
            }
        });
        countChecked();
    };
    const editSlots = async (permission: SlotPermission) => {
        const schedulesToEdit = data.filter((schedule) => {
            return schedule.isChecked;
        });
        for (const schedule of schedulesToEdit) {
            if (schedule.key) {
                const slot = await prepareSlotToEdit(schedule.key, permission);
                await dispatchEditSlot(slot, schedule.key, SlotEditScope.SINGLE);
            }
        }
        if (requestLoadAppointments.isCompleted) {
            setCompletedRequestCount(schedulesToEdit.length);
            completedRequestModalState.toggleModal();
        }
        unselectAll();
        await fetchData(tableParams.pageIndex, tableParams.pageSize, tableParams.searchPhrase);
    };
    const deleteSlots = async () => {
        const schedulesToDelete = data.filter((schedule) => {
            return schedule.isChecked;
        });
        for (const schedule of schedulesToDelete) {
            if (schedule.key) {
                await requestLoadAppointments.dispatch(api.deleteSlot(schedule.key, SlotEditScope.SINGLE));
            }
        }
        await fetchData(tableParams.pageIndex, tableParams.pageSize, tableParams.searchPhrase);
    };
    const makeSelectedPrivate = async () => {
        await editSlots(SlotPermission.PRIVATE);
        unselectAll();
        editModalSlotState.toggleModal();
    };
    const makeSelectedPublic = async () => {
        await editSlots(SlotPermission.PUBLIC);
        unselectAll();
        editModalSlotState.toggleModal();
    };
    const makeSelectedInternal = async () => {
        await editSlots(SlotPermission.INTERNAL);
        unselectAll();
        editModalSlotState.toggleModal();
    };
    const deleteSlot = async (key: string) => {
        await requestLoadAppointments.dispatch(api.deleteSlot(key, SlotEditScope.SINGLE));
        await fetchData(tableParams.pageIndex, tableParams.pageSize, tableParams.searchPhrase);
    };
    const dispatchEditSlot = async (values: SlotData, slotKey: string, scope: SlotEditScope) => {
        await requestLoadAppointments.dispatch(api.updateSlot(values, slotKey, scope));
    };
    const prepareSlotToEdit = async (key: string, permission: SlotPermission) => {
        let slot = data.find((schedule) => {
            return schedule.key == key;
        })?.raw as Slot;
        if (!slot) slot = await requestLoadAppointments.dispatch(api.getSlot(key));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const criteria_values = slot.criteria.reduce((acc: any, next) => {
            return [...acc, ...next.values];
        }, []);
        const rrule = slot.schedule_rule || null;
        const values: SlotData = {
            criteria_values: criteria_values.map((values: SlotCriteria) => {
                return values.id.toString();
            }),
            date_from: new Date(slot.date_from),
            date_to: new Date(slot.date_to),
            mode: slot.mode,
            schedule_rule: rrule,
            slot_permission: permission,
            healthcare_service: slot.healthcare_service.id,
            status: slot.status,
        };
        return values;
    };
    const editSlot = async (key: string, permission: SlotPermission) => {
        const slot = await prepareSlotToEdit(key, permission);
        await dispatchEditSlot(slot, key, SlotEditScope.SINGLE);
        await fetchData(tableParams.pageIndex, tableParams.pageSize, tableParams.searchPhrase);
    };
    const mapSlotsToSchedules = (slots: Slot[]) => {
        const schedules: Schedule[] = slots.map((slot: Slot) => {
            return {
                key: slot.key,
                date_from: new Date(slot.date_from),
                date_to: new Date(slot.date_to),
                duration: msToMin(slot.date_to, slot.date_from),
                slot_permission: slot.slot_permission,
                healthcare_service: slot.healthcare_service,
                isChecked: false,
                criteria: slot.criteria,
                raw: slot,
            };
        });
        return schedules;
    };
    const countChecked = useCallback(() => {
        setCheckedCount(
            data.filter((schedule: Schedule) => {
                return schedule.isChecked;
            }).length,
        );
    }, [data]);
    const fetchData = useCallback(
        async (pageIndex, pageSize, searchPhrase) => {
            pageIndex = tableParams.pageIndex;
            pageSize = tableParams.pageSize;
            let order: string[] = [];
            if (ordering.includes('full_name') || ordering.includes('-full_name')) {
                order = ordering.filter((obj) => {
                    return !obj.includes('full_name');
                });
            }
            searchPhrase = checkForSlotPermissionNames(searchPhrase);
            const today = new Date();
            const slots = await requestLoadAppointments.dispatch(
                api.getOrganizationSlotsWithCriteria(
                    today,
                    new Date(today.getFullYear() + 1, today.getMonth(), today.getDate()),
                    order,
                    searchPhrase,
                    pageSize,
                    pageIndex,
                ),
            );
            const slotsData = mapSlotsToSchedules(slots);
            setPageCount(1);
            setData(slotsData);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ordering, tableParams],
    );
    const handlePageSizeChange = async (option: number) => {
        setTableParams({
            pageSize: option,
            searchPhrase: tableParams.searchPhrase,
            pageIndex: tableParams.pageIndex,
        });
        queryParams.set('pageSize', option.toString());
        await fetchData(tableParams.pageIndex, option, tableParams.searchPhrase);
    };
    const handleNext = async () => {
        setTableParams({
            pageSize: tableParams.pageSize,
            searchPhrase: tableParams.searchPhrase,
            pageIndex: tableParams.pageIndex + 1,
        });
        queryParams.set('pageIndex', (tableParams.pageIndex + 1).toString());
        await fetchData(tableParams.pageIndex + 1, tableParams.pageSize, tableParams.searchPhrase);
    };
    const handlePrev = async () => {
        if (tableParams.pageIndex - 1 >= 0) {
            setTableParams({
                pageSize: tableParams.pageSize,
                searchPhrase: tableParams.searchPhrase,
                pageIndex: tableParams.pageIndex - 1,
            });
            queryParams.set('pageIndex', (tableParams.pageIndex - 1).toString());
            await fetchData(tableParams.pageIndex - 1, tableParams.pageSize, tableParams.searchPhrase);
        }
    };
    const handleToday = async () => {
        setTableParams({
            pageSize: tableParams.pageSize,
            searchPhrase: tableParams.searchPhrase,
            pageIndex: 0,
        });
        queryParams.set('pageIndex', '0');
        await fetchData(0, tableParams.pageSize, tableParams.searchPhrase);
    };
    const formattedDate = useMemo(() => {
        if (data.length == 0) return '';
        const minDate = new Date(Math.min(...data.map((slot) => slot.date_from.getTime())));
        const maxDate = new Date(Math.max(...data.map((slot) => slot.date_from.getTime())));
        const fromMonthName = minDate.toLocaleDateString(getActiveLanguage(), {
            month: 'short',
        });

        const toMonthName = maxDate.toLocaleDateString(getActiveLanguage(), {
            month: 'short',
        });
        if (minDate.getDate() == maxDate.getDate()) return `${minDate.getDate()} ${fromMonthName}`;
        return `${minDate.getDate()} ${fromMonthName} - ${maxDate.getDate()} ${toMonthName}`;
    }, [data]);
    /**
     * Location actions.
     */
    const reloadAppointments = async () => {
        editModalState.toggleModal();
        await fetchData(tableParams.pageIndex, tableParams.pageSize, tableParams.searchPhrase);
    };
    const fetchActiveItem = () => {
        return null;
    };

    return {
        isLoading: requestLoadAppointments.isLoading,
        data,
        activeItem,
        pageCount,
        fetchData,
        fetchActiveItem,
        reloadAppointments,
        ordering,
        sortHandler,
        unselectAll,
        selectAll,
        checkSchedule,
        makeSelectedPrivate,
        makeSelectedPublic,
        makeSelectedInternal,
        deleteSlot,
        deleteSlots,
        editSlot,
        editModalSlotState,
        completedRequestModalState,
        completedRequestCount,
        requestErrorModalState,
        requestLoadAppointments,
        handlePageSizeChange,
        handleNext,
        handlePrev,
        handleToday,
        formattedDate,
        tableParams,
        checkedCount,
    };
};
