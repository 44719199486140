import { useSelector } from 'react-redux';
import { getBrand } from 'features/auth/selectors';
import { getPath, PathsMap, RouteName } from 'router-paths';
import useBrandSlug from './useBrandSlug';
import { Feature } from 'medrefer-web-sdk/api/models';
import { useUserLogout } from 'hooks/useUserLogout';

const useBrand = () => {
    const organization = useSelector(getBrand);
    const { subdomainSlug, pathSlug, domain } = useBrandSlug();
    const organizationSlug = subdomainSlug || pathSlug;
    const { logoutUser } = useUserLogout();

    const getPathBranded = <TRoute extends RouteName>(route: TRoute, ...params: Parameters<PathsMap[TRoute]>) => {
        if (subdomainSlug) {
            return getPath(route, ...params);
        }

        if (pathSlug) {
            return getPath('branded', pathSlug) + getPath(route, ...params);
        }

        return getPath('notFound');
    };

    const redirectToBrand = async (organizationSlug: string) => {
        if (subdomainSlug) {
            // Since local storage is not shared across subdomains we need to delete user session
            // otherwise user will not be able to go to this subdomain again
            // because he will be instantly redirected the the main domain and will not be able
            // to logout from the subdomain he is currently in
            await logoutUser();
        }
        // Not all organizations have their subdomains so we should specify a brand in the path
        window.location.href = window.location.protocol + '//' + domain + getPath('branded', organizationSlug);
    };

    const featureEnabled = (name: keyof typeof Feature) => {
        return organization && organization.features.includes(Feature[name]);
    };

    return {
        organization,
        organizationSlug,
        getPathBranded,
        redirectToBrand,
        featureEnabled,
    };
};

export default useBrand;
