import React, { ReactElement } from 'react';
import { IconButton, TextGrey, DropDownMenuEdit, useModalState, Modal } from 'medrefer-web-sdk/web-kit';
import EditIcon from 'assets/EditIcon.svg';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import CancelAppointmentModalBody from './CancelAppointmentModalBody/CancelAppointmentModalBody';
import { AttachDocuments } from './AttachDocuments';
import { AppointmentStatus, AppointmentModel } from 'medrefer-web-sdk/api/models';
import { useMyAppointmentsActions } from 'hooks/useMyAppointmentsActions';
import { RescheduleAppointment } from 'components/shared/RescheduleAppointment';
import usePopoverMenu from 'hooks/usePopoverMenu';
import { AttachQuestionnaire } from './AttachQuestionnaire';
import useBrand from 'hooks/useBrand';

interface Props {
    activeListItem: AppointmentModel;
}

export const EditMenu = (props: Props): ReactElement => {
    const { activeListItem } = props;
    const { t } = useTranslation();
    const documentsModal = useModalState();
    const cancelModal = useModalState();
    const questionnaireModal = useModalState();
    const { isOpen, handleToggle, menuRef } = usePopoverMenu();
    const { rescheduleAppointmentModalState, rescheduleAppointment, isAppointmentRescheduleLoading } =
        useMyAppointmentsActions();
    const { featureEnabled } = useBrand();

    if (activeListItem.status === AppointmentStatus.CANCELLED) {
        return <div />;
    }

    return (
        <div ref={menuRef} className="d-flex align-items-center" style={{ position: 'relative' }}>
            <TextGrey className="pr-3 action-label">{t('editMenu:message')}</TextGrey>
            <IconButton onClick={handleToggle}>
                <ReactSVG src={EditIcon} />
            </IconButton>
            <DropDownMenuEdit isOpen={isOpen}>
                {activeListItem.status === AppointmentStatus.BOOKED &&
                    new Date(activeListItem.date_from) > new Date() && (
                        <RescheduleAppointment
                            appointmentId={activeListItem.id}
                            modalState={rescheduleAppointmentModalState}
                            rescheduleAppointment={rescheduleAppointment}
                            isReschedulingAppointment={isAppointmentRescheduleLoading}
                            button={<span>{t('editMenu:reschedule')}</span>}
                        />
                    )}
                <Modal
                    state={documentsModal}
                    button={<span>{t('editMenu:attachDocuments')}</span>}
                    body={<AttachDocuments toggleModal={documentsModal.toggleModal} appointment={activeListItem} />}
                />
                {featureEnabled('questionnaires') && (
                    <Modal
                        state={questionnaireModal}
                        button={<span>{t('editMenu:attachQuestionnaire')}</span>}
                        body={
                            <AttachQuestionnaire
                                toggleModal={questionnaireModal.toggleModal}
                                appointment={activeListItem}
                            />
                        }
                    />
                )}
                <Modal
                    state={cancelModal}
                    button={<span>{t('editMenu:cancel')}</span>}
                    body={
                        <CancelAppointmentModalBody
                            activeListItem={activeListItem}
                            toggleModal={cancelModal.toggleModal}
                        />
                    }
                />
            </DropDownMenuEdit>
        </div>
    );
};

export default EditMenu;
