import React, { ReactElement, useEffect } from 'react';
import SqueresIcon from '../../../assets/icons/SqueresIcon.png';

import moment from 'moment';

import { TimePicker, PopupBackground, TimeRangeWrapper, Icon } from './TimeRangePicker.styled';

export enum TypesInput {
    start = 'START',
    end = 'END',
}

interface TimeRangePickerProps {
    nameStart: string;
    nameEnd: string;
    hourStart: Date;
    hourEnd: Date;
    defaultValueStart: Date;
    defaultValueEnd: Date;
    setFieldValue: (filedName: string, value: Date) => void;
}

export const TimeRangePicker = (props: TimeRangePickerProps): ReactElement => {
    const [isOpen, setIsOpen] = React.useState(false);

    const [selectedStart, setSelectedStart] = React.useState(false);
    const [selectedEnd, setSelectedEnd] = React.useState(false);

    useEffect(() => {
        if (selectedStart || selectedEnd) {
            setIsOpen(true);
        } else {
            setIsOpen(false);
        }
    }, [selectedStart, selectedEnd]);

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (val: any, filedName: any) => {
        setSelectedStart(true);
        setSelectedEnd(true);
        const value = new Date(val);
        props.setFieldValue(filedName, value);
        if (filedName.includes('hourStart')) {
            const hourEndName = filedName.slice(0, filedName.length - 9) + 'hourEnd';
            const autoEndDate = moment(value).add(30, 'm').toDate();
            props.setFieldValue(hourEndName, autoEndDate);
        }
    };

    const onOpen = (type: TypesInput) => {
        setSelectedStart(true);
        setSelectedEnd(true);
        if (type === TypesInput.start) {
            setSelectedStart(true);
        } else {
            setSelectedEnd(true);
        }
    };

    const onClose = (type: TypesInput) => {
        if (type === TypesInput.start) {
            setSelectedStart(false);
        } else if (type === TypesInput.end) {
            setSelectedEnd(false);
        }
    };

    const handleIconClick = () => {
        // TO_DO add click first input ref
    };

    return (
        <div>
            <TimeRangeWrapper isActive={isOpen ? true : false}>
                <TimePicker
                    value={moment(props.hourStart)}
                    onChange={handleChange}
                    isLeft={true}
                    name={props.nameStart}
                    open={isOpen}
                    onOpen={() => onOpen(TypesInput.start)}
                    onClose={() => onClose(TypesInput.start)}
                    isActive={selectedStart ? true : false}
                    defaultValue={moment(props.defaultValueStart)}
                />

                <TimePicker
                    value={moment(props.hourEnd)}
                    onChange={handleChange}
                    isLeft={false}
                    name={props.nameEnd}
                    open={isOpen}
                    onOpen={() => onOpen(TypesInput.end)}
                    onClose={() => onClose(TypesInput.end)}
                    isActive={selectedEnd ? true : false}
                    defaultValue={moment(props.defaultValueEnd)}
                />

                <PopupBackground isOpen={isOpen}>
                    <span>FROM </span> <span>TO</span>
                </PopupBackground>

                <Icon onClick={() => handleIconClick()} isVisible={!isOpen} src={SqueresIcon} alt="Squers icon" />
            </TimeRangeWrapper>
        </div>
    );
};
