import React, { ReactElement } from 'react';

import { ContextAwareToggle } from 'medrefer-web-sdk/web-kit';
import { Row, Col, Container, Accordion } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import { CustomRow } from 'components/Dashboard/Appointment/AppointmentDetails/AppointmentDetails.styled';
import { FormValue } from 'components/shared/FormValue';
import { mapInsuranceToTranslation } from 'utils/i18n';
import { formatDate } from 'utils/dates';
import moment from 'moment';

export interface Props {
    activeListItem: AppointmentModel;
}

const AppointmentOverview = ({ activeListItem }: Props): ReactElement => {
    const { t } = useTranslation();
    const { healthcare_service, patient_metadata, comment } = activeListItem;
    const { full_name, email, phone, insurance_number, insurance_status, date_of_birth, post_code, city, street } =
        patient_metadata;

    const formatAddress = () => {
        let address = '';
        if (city) {
            address += city;
        }
        if (street) {
            address += ', ' + street;
        }
        if (post_code) {
            address += ', ' + post_code;
        }
        return address.trim();
    };
    const formattedAddress = formatAddress();

    return (
        <Container>
            <Accordion defaultActiveKey="0">
                <Row>
                    <ContextAwareToggle eventKey="0">{t('appointmentOverview:patient')}</ContextAwareToggle>
                </Row>
                <Accordion.Collapse eventKey="0">
                    <CustomRow className="mb-0 mr-0 ml-0">
                        <Col>
                            <FormValue title={t('appointmentOverview:name_and_surname')} value={full_name} />
                            <FormValue title={t('appointmentOverview:email')} value={email} />
                            {insurance_status && (
                                <FormValue
                                    title={t('appointmentOverview:insurance_status')}
                                    value={mapInsuranceToTranslation(insurance_status)}
                                />
                            )}
                            {formattedAddress && (
                                <FormValue title={t('appointmentOverview:address')} value={formattedAddress} />
                            )}
                        </Col>
                        <Col>
                            <FormValue title={t('appointmentOverview:phone_number')} value={phone} />
                            <FormValue
                                title={t('appointmentOverview:date_of_birth')}
                                value={date_of_birth && formatDate(moment(date_of_birth))}
                            />
                            {insurance_number && (
                                <FormValue title={t('appointmentOverview:insurance_number')} value={insurance_number} />
                            )}
                        </Col>
                    </CustomRow>
                </Accordion.Collapse>
            </Accordion>
            <Accordion defaultActiveKey="0">
                <Row>
                    <ContextAwareToggle eventKey="0">{t('appointmentOverview:medical_information')}</ContextAwareToggle>
                </Row>
                <Accordion.Collapse eventKey="0">
                    <CustomRow className="mb-5 mr-0 ml-0">
                        <Col>
                            <FormValue
                                title={t('appointmentOverview:healthcare_service')}
                                value={healthcare_service.name}
                            />
                            <FormValue title={t('appointmentOverview:reason')} value={activeListItem.reason.name} />
                        </Col>
                        <Col>
                            <FormValue title={t('appointmentOverview:message')} value={comment} />
                        </Col>
                    </CustomRow>
                </Accordion.Collapse>
            </Accordion>
        </Container>
    );
};

export default AppointmentOverview;
