import React from 'react';
import { H2, ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import { useOrganizationCriteriaOptions } from 'hooks/options/useOrganizationCriteriaOptions';
import SlotCriteriaForm from './SlotCriteriaForm';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import useBrand from 'hooks/useBrand';
import { SearchContainer } from '../LandingSearch/LandingSearch.styled';
import useSlotCriteriaForm from './hooks/useSlotCriteriaForm';
import { useCriteriaSearchContext } from './hooks/useCriteriaSearchContext';
import { SlotBookingProgress } from 'components/PublicSearch/SlotBookingProgress';
import { useQueryParams } from '../../../medrefer-web-sdk/web-kit/hooks';

export const CriteriaSearch = () => {
    const { t } = useTranslation();
    const { getPathBranded } = useBrand();
    const context = useCriteriaSearchContext();
    const { slotsCriteria, isLoading } = useOrganizationCriteriaOptions();
    const { onValuesSubmit } = useSlotCriteriaForm();
    const queryParams = useQueryParams();

    if (slotsCriteria && slotsCriteria.length === 0) {
        const params = queryParams.copy();
        return <Redirect to={getPathBranded('searchServiceSlots') + params.toString()} />;
    }

    return (
        <>
            <SlotBookingProgress activeStep="criteria" />
            <SearchContainer>
                <H2 className="mr-2 ml-2 text-center">{t('publicSearch:criteria_search_description')}</H2>
                {!isLoading && slotsCriteria && (
                    <SlotCriteriaForm slotsCriteria={slotsCriteria} onSubmit={onValuesSubmit} context={context} />
                )}
                {isLoading && <ActivityIndicator />}
            </SearchContainer>
        </>
    );
};
