import * as React from 'react';
import EditMenu from './EditMenu';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';

interface Props {
    activeListItem: AppointmentModel;
}

export const ActionMenu = ({ activeListItem }: Props) => {
    return (
        <div className="d-flex justify-content-center">
            <EditMenu activeListItem={activeListItem} />
        </div>
    );
};

export default ActionMenu;
