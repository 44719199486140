import React, { FC, useState } from 'react';
import { PrimaryButton, useModalState, DropDownMenuEdit, IconButton } from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, ListItemContainer, AppointmentTime, AppointmentMode } from './AppointmentList.styled';
import { Link } from 'react-router-dom';
import useBrand from 'hooks/useBrand';
import { ManagingUser } from 'components/shared/ServiceSlots/ManagingUser';
import { ServiceLocation } from 'components/shared/ServiceSlots/ServiceLocation';
import { AppointmentModel, FhirQuestionnaireResponseStatus } from 'medrefer-web-sdk/api/models';
import { AppointmentDetailsModal } from 'components/shared/AppointmentDetailsModal';
import { Separator, Modal } from 'medrefer-web-sdk/web-kit';
import { AttachDocuments } from 'components/Dashboard/Appointment/AppointmentHeader/ActionMenu/AttachDocuments';
import VideoCamIcon from 'assets/VideocamIcon.svg';
import EditIcon from 'assets/EditIcon.svg';
import { ReactSVG } from 'react-svg';

interface AppointmentListItemProps {
    appointment: AppointmentModel;
    showControls?: boolean;
    cancelAppointment?: (appointmentId: number) => void;
    rescheduleAppointment?: (appointmentId: number, organizationId: number, healthcareServiceId: number) => void;
    reloadAppointments?: () => void;
}

export const AppointmentListItem: FC<AppointmentListItemProps> = ({
    appointment,
    showControls,
    cancelAppointment,
    rescheduleAppointment,
    reloadAppointments,
}: AppointmentListItemProps) => {
    const { t } = useTranslation();
    const attatchDocumentsModalState = useModalState();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const handleToggle = () => {
        setIsOpen(!isOpen);
    };
    const dateFromMoment = moment(appointment.date_from);
    const { getPathBranded, featureEnabled } = useBrand();

    let options: Array<JSX.Element> = [];

    const inProgressQuestionnaires = appointment.questionnaire_responses.filter(
        (questionnaire) => questionnaire.status === FhirQuestionnaireResponseStatus.IN_PROGRESS,
    );

    if (showControls) {
        if (cancelAppointment) {
            options = [
                ...options,
                // eslint-disable-next-line react/jsx-key
                <span onClick={() => cancelAppointment(appointment.id)}>
                    {t('patientPortal:myAppointments.cancel_button')}
                </span>,
            ];
        }

        if (rescheduleAppointment) {
            options = [
                ...options,
                // eslint-disable-next-line react/jsx-key
                <Modal
                    isCentered={true}
                    state={attatchDocumentsModalState}
                    body={
                        <AttachDocuments
                            toggleModal={attatchDocumentsModalState.toggleModal}
                            appointment={appointment}
                            afterUpload={reloadAppointments}
                        />
                    }
                    button={
                        <span onClick={attatchDocumentsModalState.toggleModal}>{t('editMenu:attachDocuments')}</span>
                    }
                />,
                // eslint-disable-next-line react/jsx-key
                <span
                    onClick={() =>
                        rescheduleAppointment(
                            appointment.id,
                            appointment.organization.id,
                            appointment.healthcare_service.id,
                        )
                    }
                >
                    {t('patientPortal:myAppointments.reschedule_button')}
                </span>,
            ];
        }
    }

    return (
        <ListItemContainer className="mb-5 p-0">
            <Row className="mb-3">
                <Col md={8} className="d-flex flex-column position-relative">
                    <ManagingUser
                        user={appointment.managing_user ? appointment.managing_user : null}
                        alternativeName={`${appointment.healthcare_service.name} ${t('sidebarList:at')} ${
                            appointment.organization.name
                        }`}
                    />
                    <ServiceLocation
                        location={
                            appointment.healthcare_service.location ? appointment.healthcare_service.location : null
                        }
                    />
                </Col>

                <AppointmentTime md={4}>
                    <AppointmentMode className="text-right relative">
                        {appointment.video_call_available && (
                            <>
                                <ReactSVG src={VideoCamIcon} className="icon" />
                                <span>{t('calendar:mode.online')}</span>
                            </>
                        )}
                    </AppointmentMode>
                    <span>{dateFromMoment.format('DD MMMM YYYY')}</span>
                    <span>{dateFromMoment.format('HH:mm')}</span>
                    <span>{dateFromMoment.format('dddd')}</span>
                </AppointmentTime>
            </Row>
            {showControls && <Separator />}
            {appointment.comment && (
                <Row className="mb-3">
                    <Col xs={6}>
                        <p style={{ wordBreak: 'break-word' }}>{appointment.comment}</p>
                    </Col>
                    <Col xs={6}>
                        {appointment.comment && (
                            <Col className="ml-auto" xs={12} md={6}>
                                <Link to={getPathBranded('appointmentVideoCall', String(appointment.id))} />
                            </Col>
                        )}
                    </Col>
                </Row>
            )}
            <Row className="mb-3">
                <Col xs={12} md={3} className="mb-3 mb-md-0">
                    <AppointmentDetailsModal
                        appointment={appointment}
                        button={
                            <PrimaryButton
                                inverseColors
                                badgeContent={featureEnabled('questionnaires') ? inProgressQuestionnaires.length : 0}
                                tooltipContent={
                                    featureEnabled('questionnaires') ? t('sidebar:tooltip_questionnaires') : ''
                                }
                            >
                                {t('appointmentDetails:btn')}
                            </PrimaryButton>
                        }
                    />
                </Col>
                <Col xs={12} md={9}>
                    <ButtonsContainer>
                        {appointment.video_call_available && (
                            <Col md={{ span: 4, offset: options.length ? 7 : 8 }}>
                                <Link
                                    to={getPathBranded('appointmentVideoCall', String(appointment.id))}
                                    style={{ textDecoration: 'none' }}
                                >
                                    <PrimaryButton inverseColors>
                                        <ReactSVG src={VideoCamIcon} />{' '}
                                        {t('patientPortal:myAppointments.join_call_button')}
                                    </PrimaryButton>
                                </Link>
                            </Col>
                        )}
                        {options.length ? (
                            <>
                                <Col
                                    xs={12}
                                    md={{ span: 1, offset: appointment.video_call_available ? 0 : 11 }}
                                    style={{ paddingLeft: 0 }}
                                >
                                    <IconButton onClick={handleToggle} className="icon-button">
                                        <ReactSVG src={EditIcon} />
                                    </IconButton>
                                </Col>
                                <DropDownMenuEdit isOpen={isOpen} style={{ top: '54px' }}>
                                    {options.map((option) => option)}
                                </DropDownMenuEdit>
                            </>
                        ) : (
                            ''
                        )}
                    </ButtonsContainer>
                </Col>
            </Row>
        </ListItemContainer>
    );
};
