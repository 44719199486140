import { useState, useEffect, MutableRefObject } from 'react';
import { theme } from 'medrefer-web-sdk/web-kit';
import TUICalendar from './TUICalendar';
import { useWindowDimensions } from 'medrefer-web-sdk/web-kit/hooks';

export const useCalendarHeight = (calendarType: string | undefined, cal: MutableRefObject<TUICalendar | null>) => {
    const { height } = useWindowDimensions();
    const [calendarHeight, setCalendarHeight] = useState<number>(height);
    const topOffset = theme.parsePx(theme.navbarHeight.desktop) + 62;
    const [calendarWrapperHeight, setCalendarWrapperHeight] = useState<number>(height - topOffset);

    useEffect(() => {
        switch (calendarType) {
            case 'week':
                setCalendarHeight(height);
                setCalendarWrapperHeight(height - topOffset);
                break;
            case 'month':
                setCalendarHeight(calendarWrapperHeight);
                setCalendarWrapperHeight(height - topOffset);
                break;
            case 'day':
                setCalendarHeight(calendarWrapperHeight - 42);
                setCalendarWrapperHeight(height - topOffset - 5);
                break;
            default:
                setCalendarHeight(height);
        }
        // eslint-disable-next-line
        cal.current?.forceUpdate();
        // eslint-disable-next-line
    }, [calendarType, height]);

    return {
        calendarWrapperHeight,
        calendarHeight,
    };
};
