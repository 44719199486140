import React, { useRef } from 'react';
import { ErrorMessage, H1, Input, Label, UnderlineH2 } from 'medrefer-web-sdk/web-kit';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormProps } from './BookingForm';
import { FormikProps } from 'formik';
import { SlotBookingFormData, SlotBookingStep } from 'components/PublicSearch/SlotBooking/SlotBooking.types';
import { FormColumn, UserFormContainer } from './BookingForm.styled';
import { EditFieldButton } from 'components/shared/EditFieldButton';

type Props = {
    props: FormProps & FormikProps<SlotBookingFormData>;
};

export const UserLoginForm = ({ props }: Props) => {
    const { t } = useTranslation();
    const passwordInput = useRef<HTMLInputElement>(null);
    const { step } = props.values;

    return (
        <UserFormContainer>
            <H1 className="mb-3">{t('slotBooking:login_title')}</H1>
            <Row className="mb-4">
                <Col>
                    <UnderlineH2>{t('slotBooking:login_description')}</UnderlineH2>
                </Col>
            </Row>
            <FormGroup>
                <Row>
                    <Col xs={12} md={6}>
                        <Label isRequired labelTop>
                            {t('newAppointmentForm:title_password')}
                        </Label>
                        <EditFieldButton
                            onClick={() => {
                                props.setFieldValue('step', SlotBookingStep.login);
                                setImmediate(() => passwordInput.current!.focus());
                            }}
                            isVisible={step != SlotBookingStep.login}
                        >
                            <Input
                                ref={passwordInput}
                                isError={!!(props.touched.password && props.errors.password)}
                                id="password"
                                placeholder={t('newAppointmentForm:placeholder_password')}
                                type="password"
                                onChange={props.handleChange}
                                value={props.values.password}
                                onBlur={props.handleBlur}
                                disabled={step != SlotBookingStep.login}
                                autoFocus
                            />
                        </EditFieldButton>
                        {props.touched.password && props.errors.password && (
                            <ErrorMessage className="text-left mt-1 mr-3">{props.errors.password}</ErrorMessage>
                        )}
                    </Col>
                    {step === SlotBookingStep.loginOtpCode && (
                        <FormColumn xs={12} md={6}>
                            <Label isRequired labelTop>
                                {t('loginForm:otp_code')}
                            </Label>
                            <Input
                                isError={!!(props.touched.token && props.errors.token)}
                                id="token"
                                placeholder={t('loginForm:otp_code')}
                                type="text"
                                onChange={props.handleChange}
                                value={props.values.token}
                                onBlur={props.handleBlur}
                                autoFocus
                            />
                            {props.touched.token && props.errors.token && (
                                <ErrorMessage className="text-left mt-1 mr-3">{props.errors.token}</ErrorMessage>
                            )}
                        </FormColumn>
                    )}
                </Row>
            </FormGroup>
        </UserFormContainer>
    );
};
