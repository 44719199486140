import styled, { css } from 'styled-components';
import { ReactSVG } from 'react-svg';

export const Container = styled.div`
    width: 100%;
    display: flex;
`;

export const ArrowControl = styled.div`
    margin: 0 15px;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        margin: 0;
    }
`;

interface ArrowProps {
    isReversed?: boolean;
    isActive?: boolean;
}

export const Arrow = styled(ReactSVG)<ArrowProps>`
    transform: ${(props) => (props.isReversed ? 'rotate(180deg)' : 'rotate(0)')};
    cursor: not-allowed;
    opacity: 0.2;
    svg {
        height: 24px;
        width: 24px;
        transform: translateX(-1px);
        fill: ${({ theme }) => theme.accent};
    }

    div {
        background: ${({ theme }) => theme.accent}4a;
        border-radius: 50%;
        padding: 6px;
    }

    ${(props) =>
        props.isActive &&
        css`
            cursor: pointer;
            opacity: 1;
        `}
`;
