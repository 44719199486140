import { DateRange } from 'components/Calendar/CalendarView/CalendarView.types';
import { useQuery, useQueryClient } from 'react-query';
import { api } from 'medrefer-web-sdk/api';
import { AppointmentModel, Slot } from 'medrefer-web-sdk/api/models';
import { useEffect } from 'react';
import { BookingStatus } from 'components/Dashboard/StatusBadge/StatusBadge';

export const useCalendarSlotsAndAppointments = (dateRange: DateRange | null) => {
    const queryClient = useQueryClient();

    useEffect(() => {
        reloadSchedules();
        // eslint-disable-next-line
    }, []);

    const getSlotsAndAppointments = async (dateRange: DateRange) => {
        const requestAppointments = api.getAppointments({
            dateFrom: dateRange.dateFrom,
            dateTo: dateRange.dateTo,
            statuses: [BookingStatus.upcoming, BookingStatus.past],
            limit: 1000,
        });
        const requestSlots = api.getOrganizationSlots(dateRange.dateFrom, dateRange.dateTo);

        return await Promise.all([requestAppointments, requestSlots]);
    };

    const reloadSchedules = async () => {
        queryClient.invalidateQueries('calendarSchedules');
    };

    const query = useQuery(
        ['calendarSchedules', dateRange],
        async () => {
            if (dateRange) {
                const data = await getSlotsAndAppointments(dateRange);
                return {
                    appointments: data[0].appointments,
                    slots: data[1],
                };
            }
        },
        {
            staleTime: 10 * 60 * 1000,
            keepPreviousData: true,
        },
    );

    let appointments: AppointmentModel[] = [];
    let slots: Slot[] = [];

    if (query.data) {
        appointments = query.data.appointments;
        slots = query.data.slots;
    }

    return {
        appointments,
        slots,
        isLoading: query.isLoading,
        reloadSchedules,
    };
};
