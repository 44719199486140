import { useEffect, useState } from 'react';

/**
 * Temporary state that returns the initial value after the designated time.
 */
export const useTemporaryState = <T = unknown>(initialValue: T, timeToLive?: number): [T, (nextValue: T) => void] => {
    const [value, setValue] = useState<T>(initialValue);

    useEffect(() => {
        if (value !== initialValue && timeToLive) {
            const timeoutId = setTimeout(() => setValue(initialValue), timeToLive);

            return () => clearTimeout(timeoutId);
        }
    }, [value, timeToLive, initialValue]);

    return [value, setValue];
};
