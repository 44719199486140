import { SET_USER, CLEAR_STATE } from './constants';
import { api } from 'medrefer-web-sdk/api';
import { AppThunk } from 'MyTypes';

import {
    AcceptInvitationData,
    LoginData,
    PatientRegistrationData,
    TokenPair,
    Language,
    UserUpdateData,
} from 'medrefer-web-sdk/api/models';
import { clearAuthTokens, getAuthTokens, isTokenValid, setAuthTokens } from 'utils/tokens';
import { action } from 'typesafe-actions';

export const checkAuthentication = (): AppThunk => async (dispatch) => {
    const { token, refresh } = getAuthTokens();

    if (token && isTokenValid(token)) {
        api.setAuthToken(token);
        await dispatch(getUserData());
    } else if (refresh && isTokenValid(refresh)) {
        const data = await api.refreshToken(refresh);
        await dispatch(finalizeLogin(data));
    }
};

export const finalizeLogin =
    (data: TokenPair): AppThunk =>
    async (dispatch) => {
        setAuthTokens(data);
        await dispatch(getUserData());
    };

export const loginUser = (data: LoginData) => {
    return createLoginAction(() => {
        return api.login(data);
    });
};

export const registerPatientUser = (data: PatientRegistrationData) => {
    return createLoginAction(() => {
        return api.registerPatient(data);
    });
};

export const acceptInvitation = (data: AcceptInvitationData) => {
    return createLoginAction(() => {
        return api.acceptInvitation(data);
    });
};

const createLoginAction = (performLogin: () => Promise<TokenPair>): AppThunk => {
    return async (dispatch): Promise<void> => {
        const response = await performLogin();
        await dispatch(finalizeLogin(response));
    };
};

export const clearUserState = (): AppThunk => async (dispatch) => {
    clearAuthTokens();
    dispatch(action(CLEAR_STATE));
};

export const getUserData = (): AppThunk => async (dispatch) => {
    const userProfile = await api.getUserProfile();
    dispatch(action(SET_USER, userProfile));
};

export const updateUserData =
    (userData: UserUpdateData): AppThunk =>
    async (dispatch) => {
        const userProfile = await api.updateUserProfile(userData);
        dispatch(action(SET_USER, userProfile));
    };

export const updateUserLanguage =
    (language: Language): AppThunk =>
    async (dispatch) => {
        const userProfile = await api.updateUserLanguage(language);
        dispatch(action(SET_USER, userProfile));
    };
