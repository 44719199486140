import styled from 'styled-components';

export const ModalBody = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

export const ModalTitle = styled.span`
    font-weight: 800;
    font-size: 23px;
    line-height: 36px;
    color: ${({ theme }) => theme.black};
    margin-bottom: 10px;
`;

export const ModalMessage = styled.p`
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: ${({ theme }) => theme.black};
    margin-bottom: 30px;
`;
