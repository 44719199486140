import { useEffect, useState } from 'react';
import { CalendarState } from 'components/Calendar/CalendarView/useCalendarState';

export const useCurrentMonth = (state: CalendarState) => {
    const [currentMonth, setCurrentMonth] = useState<Date>(state.date);

    useEffect(() => {
        setCurrentMonth(new Date(state.date));
    }, [state.date]);

    const handleNextMonth = () => {
        setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() + 1)));
    };

    const handlePrevMonth = () => {
        setCurrentMonth(new Date(currentMonth.setMonth(currentMonth.getMonth() - 1)));
    };

    return {
        currentMonth,
        handleNextMonth,
        handlePrevMonth,
    };
};
