import React from 'react';
import { Redirect, RedirectProps, useLocation } from 'react-router-dom';
import useBrand from 'hooks/useBrand';
import { RouteRole, routeRoleSelector, UserRouteRole } from 'components/shared/routes/RouteRole';
import { UserRole } from 'medrefer-web-sdk/api/models';
import { getPath } from 'router-paths';
import Types from 'MyTypes';
import { connect } from 'react-redux';

interface RedirectHomeProps extends Omit<RedirectProps, 'to'> {
    /*
    Overrides default home location
     */
    redirectTo?: string;
    routeRole: UserRouteRole;
}

const RedirectHome = ({ routeRole, redirectTo, ...rest }: RedirectHomeProps) => {
    const { getPathBranded } = useBrand();
    const location = useLocation();

    const getRedirectPathname = () => {
        if (redirectTo) {
            return redirectTo;
        }
        if (routeRole == RouteRole.guest) {
            return getPathBranded('login');
        }
        if (routeRole == UserRole.patient) {
            return getPathBranded('patientPanel');
        }
        if (routeRole == UserRole.admin) {
            return getPath('admin');
        }
        return getPathBranded('appointments');
    };

    return (
        <Redirect
            to={{
                pathname: getRedirectPathname(),
                state: {
                    from: location.pathname,
                },
            }}
            {...rest}
        />
    );
};

const mapStateToProps = (state: Types.RootState) => ({
    routeRole: routeRoleSelector(state),
});

export default connect(mapStateToProps)(RedirectHome);
