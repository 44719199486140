import { QuestionnaireResponseFormData, QuestionnaireResponseFormProps } from './QuestionnaireResponseForm.types';
import {
    FhirQuestionnaireItemType,
    FhirQuestionnaireResponseItem,
    FhirQuestionnaireResponse,
    FhirQuestionnaire,
} from 'medrefer-web-sdk/api/models';
import { questionnaireResponseMapper } from './helpers/questionnaireResponseMapper';
import { FormikBag } from 'formik';

export const mapPropsToValues = ({
    config,
    content,
}: QuestionnaireResponseFormProps): QuestionnaireResponseFormData => {
    const fhirQuestionnaireConfig = JSON.parse(config) as FhirQuestionnaire;
    const fhirQuestionnaireResponse = content && (JSON.parse(content) as FhirQuestionnaireResponse);
    /**
     * Map config to base form values
     */
    const items = fhirQuestionnaireConfig.item.map((item) => {
        let value;
        const draftItem =
            fhirQuestionnaireResponse &&
            fhirQuestionnaireResponse.item.find(
                (draftItem: FhirQuestionnaireResponseItem) => draftItem.linkId === item.linkId,
            );

        if (item.type === FhirQuestionnaireItemType.boolean) {
            value = draftItem ? !!draftItem.answer[0].valueBoolean : false;
        } else if (item.type === FhirQuestionnaireItemType.integer || item.type === FhirQuestionnaireItemType.decimal) {
            value = draftItem ? (draftItem.answer[0].valueDecimal as number) : false;
        } else if (
            item.type === FhirQuestionnaireItemType.date ||
            item.type === FhirQuestionnaireItemType.dateTime ||
            item.type === FhirQuestionnaireItemType.time
        ) {
            value = draftItem ? !!draftItem.answer[0].valueDateTime : false;
        } else {
            value = draftItem ? (draftItem.answer[0].valueString as string) : '';
        }
        return {
            linkId: item.linkId,
            text: item.text,
            value,
        };
    });

    return {
        items,
    };
};

export const handleSubmit = (
    values: QuestionnaireResponseFormData,
    { props }: FormikBag<QuestionnaireResponseFormProps, QuestionnaireResponseFormData>,
) => {
    props.onSubmit(JSON.stringify(questionnaireResponseMapper(values)));
};
