import { Col } from 'react-bootstrap';
import { Button, Modal, useModalState } from '../../../../medrefer-web-sdk/web-kit';
import RequestWaitingRoomForm from './RequestWaitingRoomForm';
import AgreementsWRForm from './AgreementsModal/AgreementsWRForm';
import React, { useState } from 'react';
import useApiRequest from '../../../../hooks/useApiRequest';
import useLoginFlow from '../../../Auth/useLoginFlow';
import useRegisterFlow from '../../../Auth/useRegisterFlow';
import { useWaitingRoomRequest } from './useWaitingRoomRequest';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser, getBrand } from '../../../../features/auth/selectors';
import useBrand from '../../../../hooks/useBrand';
import { SubmitWaitingRoomProps } from './RequestWaitingRoomForm.types';
import { AgreementsWRFields } from './AgreementsModal/AgreementsWRForm.types';
import { routerActions } from 'connected-react-router';
import { HealthcareServiceSlots } from '../../../../medrefer-web-sdk/api/models';
import { useTranslation } from 'react-i18next';
import { useOptionalFields } from './useOptionalFields';
import CompleteUserInfoForm from '../../../Auth/CompleteUserInformation/CompleteUserInfoForm';
interface Props {
    healthcareService: HealthcareServiceSlots;
}
export const NoSlotsWaitingRoomBehaviour = ({ healthcareService }: Props) => {
    const { submitCredentials, submitOtp, updateProfile } = useLoginFlow();
    const authUser = useSelector(getAuthUser);
    const { t } = useTranslation();
    const addWaitingRoomRequest = useApiRequest();
    const { addAppointmentRequest } = useWaitingRoomRequest();
    const dispatch = useDispatch();
    const modal = useModalState();
    const { getPathBranded } = useBrand();
    const { submitCredentials: submitCredentials_register, submitOtp: submitOtp_register } = useRegisterFlow();
    const [submitedValues, SetSubmitedValues] = useState<SubmitWaitingRoomProps | undefined>(undefined);
    const [isSubmiting, SetIsSubmiting] = useState(false);
    const organization = useSelector(getBrand);
    const onFormSubmit = (submitObject: SubmitWaitingRoomProps) => {
        addWaitingRoomRequest.clearErrors();
        SetSubmitedValues(submitObject);
    };
    const { getFields, getMandatoryFields, hasMissingInfo } = useOptionalFields(organization);

    const formFinalSubmit = (agreementsValues: AgreementsWRFields) => {
        if (agreementsValues.user === undefined || submitedValues === undefined || healthcareService.id === undefined)
            return;

        SetIsSubmiting(true);
        addAppointmentRequest(
            healthcareService.id,
            healthcareService.location === null,
            agreementsValues.user.id,
            submitedValues.pickedDays,
            submitedValues.message,
        ).then((res) => {
            submitedValues.attachDocumentHandler?.uploadRequestAttachments(res).finally(() => {
                modal.toggleModal();
                const path = getPathBranded('patientPanel');
                dispatch(routerActions.push(path));
                SetSubmitedValues(undefined);
                SetIsSubmiting(false);
            });
        });
    };

    return (
        <Col xs={12} md={12} className="d-flex justify-content-center align-items-center">
            <div style={{ width: 100 }}>
                <Modal
                    state={modal}
                    isCentered={false}
                    button={
                        <Col xs={12} md={12} className="d-flex justify-content-center align-items-center">
                            <Button
                                onClick={() => SetSubmitedValues(undefined)}
                                style={{
                                    width: 'fit-content',
                                    height: 'fit-content',
                                    minWidth: '150px',
                                    minHeight: '70px',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {t('publicSearch:waiting_room_send_request_button')}
                            </Button>
                        </Col>
                    }
                    body={
                        submitedValues === undefined ? (
                            <RequestWaitingRoomForm
                                user={authUser}
                                request={addWaitingRoomRequest}
                                onFormSubmit={onFormSubmit}
                                submitCredentials={submitCredentials}
                                submitOtp={submitOtp}
                                closeModal={() => modal.toggleModal()}
                                submitOtp_register={submitOtp_register}
                                submitCredentials_register={submitCredentials_register}
                                organization={organization}
                                fields={getFields()}
                                mandatory_fields={getMandatoryFields()}
                            />
                        ) : hasMissingInfo(authUser) ? (
                            <CompleteUserInfoForm
                                user={authUser}
                                onFormSubmit={() => {
                                    return;
                                }}
                                fields={getFields()}
                                mandatory_fields={getMandatoryFields()}
                                updateProfile={updateProfile}
                            />
                        ) : (
                            <AgreementsWRForm
                                isLoading={isSubmiting}
                                requestWaitingRoomFields={submitedValues}
                                onFormSubmit={formFinalSubmit}
                                updateProfile={updateProfile}
                                healthService={healthcareService}
                                fields={getFields()}
                                mandatory_fields={getMandatoryFields()}
                            />
                        )
                    }
                />
            </div>
        </Col>
    );
};
