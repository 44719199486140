import React from 'react';
import {
    AppointmentRequestsModel,
    AppointmentRequestTime,
} from '../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';
import { AppointmentTime, ListItemContainer } from '../MyAppointments/AppointmentsList/AppointmentList.styled';
import { Col, Row } from 'react-bootstrap';
import { ManagingUser } from '../../shared/ServiceSlots/ManagingUser';
import { ServiceLocation } from '../../shared/ServiceSlots/ServiceLocation';
import { useTranslation } from 'react-i18next';
import { IconButton, PrimaryButton, Separator } from '../../../medrefer-web-sdk/web-kit';
import { WaitingRoomAppointmentDetailsModal } from './DetailsModal/WaitingRoomAppointmentDetailsModal';
import useBrand from '../../../hooks/useBrand';
import { Link } from 'react-router-dom';
import { DayCircle, DaySelectorContainer } from '../../RequestsList/DayCircles/DayCircles.styled';
import { ReactSVG } from 'react-svg';
import DeleteForeverIcon from '../../../assets/DeleteForeverIcon.svg';
import { DeleteRequestModal } from './DeleteRequestModal/DeleteRequestModal';
interface Props {
    item: AppointmentRequestsModel;
    reloadAppointments: () => void;
}
export const WaitingRoomAppointmentListItem = ({ item, reloadAppointments }: Props) => {
    const { t } = useTranslation();
    const { getPathBranded } = useBrand();

    const name = item.healthcare_service?.name || item.healthcare_service_group?.name;
    return (
        <ListItemContainer className="mb-5 p-0">
            <Row className="mb-3">
                <Col md={8} className="d-flex flex-column position-relative">
                    <ManagingUser
                        user={item.managing_user ? item.managing_user : null}
                        alternativeName={`${name} ${t('sidebarList:at')} ${item.organization?.name}`}
                    />
                    <ServiceLocation
                        location={item.healthcare_service?.location ? item.healthcare_service.location : null}
                    />
                </Col>
                <AppointmentTime md={4}>
                    {t('requestWaitingRoom:picked_days_confirmation_label')}
                    <DaySelectorContainer>
                        {item.available_time.slice(0, 5).map((option, key) => (
                            <DayCircle
                                key={key}
                                isAllDay={item.available_time[key] == AppointmentRequestTime.ALL_DAY}
                                isMorning={item.available_time[key] == AppointmentRequestTime.MORNING}
                                isAfternoon={item.available_time[key] == AppointmentRequestTime.AFTERNOON}
                                small={false}
                            >
                                {t('global:daynamesShort.' + (key + 1))}
                            </DayCircle>
                        ))}
                    </DaySelectorContainer>
                </AppointmentTime>
            </Row>
            <Separator />
            {item.comment && (
                <Row className="mb-3">
                    <Col xs={6}>
                        <p style={{ wordBreak: 'break-word' }}>{item.comment}</p>
                    </Col>
                    <Col xs={6}>
                        {item.comment && (
                            <Col className="ml-auto" xs={12} md={6}>
                                <Link to={getPathBranded('appointmentVideoCall', String(item.id))} />
                            </Col>
                        )}
                    </Col>
                </Row>
            )}
            <Row className="mb-3">
                <Col xs={12} md={3} className="mb-3 mb-md-0">
                    <WaitingRoomAppointmentDetailsModal
                        waitingRoomAppointment={item}
                        button={<PrimaryButton inverseColors={true}>{t('appointmentDetails:btn')}</PrimaryButton>}
                    />
                </Col>
                <Col xs={12} md={{ span: 1, offset: 8 }} style={{ paddingLeft: 0 }}>
                    <DeleteRequestModal
                        reloadAppointments={reloadAppointments}
                        id={item.id}
                        button={
                            <IconButton
                                className="icon-button"
                                tooltipContent={t('patientPortal:myAppointments.delete_request_tooltip')}
                            >
                                <ReactSVG
                                    style={{ fill: 'red' }}
                                    className="nav-icon"
                                    alt="Patient panel icon"
                                    src={DeleteForeverIcon}
                                />
                            </IconButton>
                        }
                    />
                </Col>
            </Row>
        </ListItemContainer>
    );
};
