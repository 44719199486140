import React from 'react';
import { RadioTileStyled } from './RadioTile.styled';
import { TextGrey } from '../../typography';

interface Props extends React.HTMLProps<HTMLInputElement> {
    title: string;
    description?: string;
}

export const RadioTile = ({ title, description, checked, className, ...rest }: Props) => {
    return (
        <RadioTileStyled isActive={checked} className={className}>
            <input {...rest} checked={checked} type="radio" style={{ display: 'none' }} />
            <div>
                <div>{title}</div>
                {description && <TextGrey>{description}</TextGrey>}
            </div>
        </RadioTileStyled>
    );
};
