import { useCallback, useRef, useState } from 'react';
import useApiRequest from 'hooks/useApiRequest';
import {
    HealthcareServicesParams,
    HealthcareServiceGroup,
    ServiceGroupManagementData,
} from 'medrefer-web-sdk/api/models';
import { api } from 'medrefer-web-sdk/api';
import { useModalState, useTableOrdering } from 'medrefer-web-sdk/web-kit';
import { errorTimeout } from 'utils/constants';

export const useManageServiceGroups = () => {
    const { ordering, sortHandler } = useTableOrdering(['-id']);
    const [activeItem, setActiveItem] = useState<HealthcareServiceGroup | null>(null);
    const [tableParams, setTableParams] = useState<{ pageIndex: number; pageSize: number; searchPhrase: string }>({
        pageIndex: 0,
        pageSize: 0,
        searchPhrase: '',
    });
    const [data, setData] = useState<HealthcareServiceGroup[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const requestLoadServices = useApiRequest();
    const request = useApiRequest({ errorTimeout });
    const addModalState = useModalState();
    const editModalState = useModalState({ actionOnClose: () => setActiveItem(null) });
    const deleteModalState = useModalState();

    const fetchIdRef = useRef(0);

    const fetchData = useCallback(
        (pageIndex, pageSize, searchPhrase) => {
            const fetchId = ++fetchIdRef.current;
            setTableParams({ pageIndex, pageSize, searchPhrase });
            if (fetchId === fetchIdRef.current) {
                const params: HealthcareServicesParams = {
                    offset: pageSize * pageIndex,
                    limit: pageSize,
                    search: searchPhrase,
                    ordering: ordering.join(','),
                };

                requestLoadServices.dispatch(api.getHealthcareServiceGroups(params)).then((res) => {
                    setPageCount(Math.ceil(res.count / pageSize));
                    setData(res.results);
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ordering],
    );

    /**
     * Service actions
     */
    const reloadData = () => {
        fetchData(tableParams.pageIndex, tableParams.pageSize, tableParams.searchPhrase);
    };

    const fetchActiveItem = (item: HealthcareServiceGroup) => {
        if (activeItem && item.id === activeItem.id) {
            return;
        }
        if (activeItem && item.id !== activeItem.id) {
            setActiveItem(null);
        }
        request.dispatch(api.getHealthcareServiceGroup(item.id)).then((service) => {
            setActiveItem(service);
        });
    };

    const createHealthcareServiceGroup = (values: ServiceGroupManagementData) => {
        request.dispatch(api.createHealthcareServiceGroup(values)).then(() => {
            addModalState.toggleModal();
            reloadData();
        });
    };

    const updateHealthcareServiceGroup = (values: ServiceGroupManagementData) => {
        if (!activeItem) return;
        request.dispatch(api.updateHealthcareServiceGroup(activeItem.id, values)).then(() => {
            editModalState.toggleModal();
            reloadData();
        });
    };

    const deleteHealthcareServiceGroup = () => {
        if (!activeItem) return;
        request.dispatch(api.deleteHealthcareServiceGroup(activeItem.id)).then(() => {
            deleteModalState.toggleModal();
            reloadData();
        });
    };

    return {
        createHealthcareServiceGroup,
        updateHealthcareServiceGroup,
        isLoading: requestLoadServices.isLoading,
        request,
        activeItem,
        fetchActiveItem,
        data,
        pageCount,
        fetchData,
        addModalState,
        editModalState,
        ordering,
        sortHandler,
        deleteModalState,
        deleteHealthcareServiceGroup,
    };
};
