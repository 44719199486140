import { useMemo } from 'react';
import { UserRole } from 'medrefer-web-sdk/api/models';
import { Option } from 'medrefer-web-sdk/web-kit';
import { translateUserRole } from 'utils/i18n';

export const useRoleOptions = (filterRoles?: UserRole[]) => {
    const roleOptions: Option[] = useMemo(() => {
        let roles;
        if (filterRoles == null) {
            roles = [
                UserRole.patient,
                UserRole.organizationMember,
                UserRole.cooperator,
                UserRole.organization,
                UserRole.admin,
            ];
        } else {
            roles = filterRoles;
        }

        return roles.map((role) => ({
            value: role,
            label: translateUserRole(role),
        }));
    }, [filterRoles]);

    return roleOptions;
};
