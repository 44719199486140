import styled from 'styled-components';

export const CalendarFiltersContainer = styled.div`
    max-height: calc(var(--maxvh) - ${({ theme }) => theme.navbarHeight.desktop});
    margin-top: -5px;
`;

export const CalendarFiltersWrapper = styled.div`
    max-height: calc(var(--maxvh) - ${({ theme }) => theme.navbarHeight.desktop});
    overflow-y: scroll;

    &::-webkit-scrollbar {
        display: none;
    }
`;
