import { Column } from 'react-table';
import { OrganizationProfile } from 'medrefer-web-sdk/api/models';

export const organizationAdminColumns: (t: (key: string) => string) => Column<OrganizationProfile>[] = (t) => [
    {
        Header: t('adminPanel:table.columns.id'),
        accessor: 'id',
        sortParam: 'id',
        width: 40,
    },
    {
        Header: t('adminPanel:table.columns.name'),
        accessor: 'name',
        sortParam: 'name',
        width: 200,
    },
    {
        Header: t('adminPanel:table.columns.slug'),
        accessor: 'slug',
        sortParam: 'slug',
        width: 200,
    },
];
