import React, { FC, ReactNode } from 'react';
import { Modal as ModalBootstrap } from 'react-bootstrap';
import './modal.css';
import CloseIcon from '../../assets/icons/CloseIcon.png';

export interface ModalState {
    toggleModal: () => void;
    isOpen: boolean;
}

interface Props {
    state: ModalState;
    body?: ReactNode;
    button?: ReactNode;
    isCentered?: boolean;
    className?: string;
    onlyBody?: boolean;
}

export const Modal: FC<Props> = ({ body, button, state, isCentered = true, className, onlyBody }: Props) => {
    return (
        <div className={`modal-container w-100 ${className ? className : ''}`}>
            {button && (
                <div className="modal-button-container" onClick={state.toggleModal}>
                    {button}
                </div>
            )}
            <ModalBootstrap
                show={state.isOpen}
                onHide={state.toggleModal}
                keyboard={false}
                className={`modal fade ${isCentered ? 'modal-center' : ''} ${onlyBody ? 'onlyBody' : ''}`}
                id="modal"
                tabIndex="-1"
                data-backdrop="static"
            >
                <div className="d-flex">
                    {!onlyBody && (
                        <img
                            style={{ cursor: 'pointer' }}
                            alt="Close icon"
                            onClick={state.toggleModal}
                            src={CloseIcon}
                            className="ml-auto mr-4 mt-4 "
                        />
                    )}
                </div>
                <ModalBootstrap.Body className={`${!onlyBody ? 'pl-md-5 pr-md-5 pb-5 pt-3' : 'p-0'}`}>
                    {body}
                </ModalBootstrap.Body>
            </ModalBootstrap>
        </div>
    );
};
