import React, { FC } from 'react';
import { MyAppointments } from 'components/PatientPanel/MyAppointments';
import { Container } from 'react-bootstrap';

export const PatientPanel: FC = () => {
    return (
        <Container fluid>
            <MyAppointments />
        </Container>
    );
};
