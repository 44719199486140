import React from 'react';
import { BasePhoneInput } from './BasePhoneInput';
import useBrand from 'hooks/useBrand';
import { useTranslation } from 'react-i18next';
import { getActiveLanguage } from 'utils/i18n';
import { Input } from 'medrefer-web-sdk/web-kit';
import { Language } from 'medrefer-web-sdk/api/models';
import de from 'react-phone-input-2/lang/de.json';
import pl from 'react-phone-input-2/lang/pl.json';

type InputProps = React.ComponentProps<typeof Input>;

export const PhoneInput = ({ isError, value, ...rest }: InputProps) => {
    const { organization } = useBrand();
    const { t } = useTranslation();
    const currentLanguage = getActiveLanguage() as Language;
    const localization = {
        en: undefined,
        de,
        pl,
    }[currentLanguage];

    let countries = ['de'];
    if (organization && organization.operating_countries.length > 0) {
        countries = organization.operating_countries.map((c) => c.toLowerCase());
    }

    return (
        <BasePhoneInput
            localization={localization}
            country={countries[0]}
            preferredCountries={countries}
            placeholder={t('newAppointmentForm:placeholder_phone_number')}
            isError={isError}
            value={value}
            inputProps={rest}
        />
    );
};
