import styled from 'styled-components';
import { Container } from 'react-bootstrap';
import { IconButton } from 'medrefer-web-sdk/web-kit';

export const ProfileDisplayContainer = styled(Container)`
    position: relative;
    margin: 0 80px 0 80px;
    background-color: #fff;
    padding: 40px;
    overflow-y: auto;
    max-width: calc(100vw - 240px);

    .modal-container {
        width: 42px !important;
        right: 40px;

        .modal-button-container {
            width: 42px !important;
            right: 0;

            button {
                top: 0;
                right: 0;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        max-width: 100vw;
        margin: auto;
        padding: 15px;
        height: calc(var(--vh, 1vh) * 100 - ${({ theme }) => theme.navbarHeight.mobile});

        .modal-container {
            right: 15px;
            top: 20px;
        }
    }
`;

export const CustomIconButton = styled(IconButton)`
    position: absolute;
    top: 20px;
    right: 20px;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        top: 0;
    }
`;
