import React from 'react';
import { Avatar, AvatarProps } from 'medrefer-web-sdk/web-kit';
import { getFullName } from 'medrefer-web-sdk/api/utils';
import { User, UserPractitioner, UserProfile } from 'medrefer-web-sdk/api/models';

type Props = Omit<AvatarProps, 'src' | 'name'> & {
    user?: User | UserPractitioner | UserProfile | null;
};

export const UserAvatar = ({ user, ...rest }: Props) => {
    const avatarUrl = getUserAvatarUrl(user);
    const userName = user ? getFullName(user, true) : '';
    return <Avatar src={avatarUrl || undefined} name={userName} {...rest} />;
};

export const getUserAvatarUrl = (user?: User | UserPractitioner | UserProfile | null) => {
    if (user == null) return undefined;

    if ('practitioner' in user) {
        return user.practitioner?.image?.file;
    }

    return undefined;
};
