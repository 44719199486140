import React, { useEffect } from 'react';
import { FormikProps, withFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import { defaultSlotMode, Slot, UserProfile } from 'medrefer-web-sdk/api/models';
import { SlotBookingFormData, SlotBookingStep } from 'components/PublicSearch/SlotBooking/SlotBooking.types';
import { AttachDocumentsHanlerType } from 'hooks/useAttachDocuments';
import { ApiRequest } from 'hooks/useApiRequest';
import { BaseInfoForm } from './BaseInfoForm';
import { UserLoginForm } from './UserLoginForm';
import { UserRegisterForm } from './UserRegisterForm';
import { SubmitAppointmentForm } from './SubmitAppointmentForm';
import { handleSubmit, mapPropsToValues, validationSchema } from './BookingForm.schema';
import { ConfirmBookingModal } from 'components/shared/ConfirmBookingModal';
import { Agreements } from './Agreements';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';

export interface FormProps extends WithTranslation {
    user?: UserProfile;
    request: ApiRequest;
    slot: Slot;
    otpCodeSent: boolean;
    checkEmailTaken: (data: SlotBookingFormData) => Promise<boolean>;
    submitLoginData: (data: SlotBookingFormData) => Promise<void>;
    submitRegisterData: (data: SlotBookingFormData) => Promise<void>;
    submitLoginOtpCode: (data: SlotBookingFormData) => Promise<void>;
    requestConfirmation: (data: SlotBookingFormData) => Promise<void>;
    attachDocumentsHandler: AttachDocumentsHanlerType;
    children?: JSX.Element | null;
    onDiscard: () => void;
    onConfirm: () => void;
    submittedValues: SlotBookingFormData | null;
    fields: string[];
    mandatory_fields: string[];
}

const InnerForm = (props: FormProps & FormikProps<SlotBookingFormData>) => {
    const { step } = props.values;
    const { user, setFieldValue, submittedValues, slot } = props;
    const queryParams = useQueryParams();

    useEffect(() => {
        if (user && user.phone) {
            setFieldValue('phone', user.phone);
        }
        // eslint-disable-next-line
    }, [user]);

    useEffect(() => {
        if (slot.mode === null) {
            const mode = queryParams.get('mode');
            setFieldValue('mode', mode ? mode : defaultSlotMode);
        }
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (user) {
            setFieldValue('step', submittedValues ? SlotBookingStep.confirmation : SlotBookingStep.base);
            return;
        }

        const newStep = step === SlotBookingStep.registerConfirmation ? SlotBookingStep.registerOtpCode : step;
        setFieldValue('step', submittedValues ? SlotBookingStep.registerConfirmation : newStep);
    }, [step, user, setFieldValue, submittedValues]);
    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
            className="w-100"
        >
            <BaseInfoForm props={props} />
            {[SlotBookingStep.login, SlotBookingStep.loginOtpCode].includes(step) && <UserLoginForm props={props} />}
            {[SlotBookingStep.register, SlotBookingStep.registerOtpCode, SlotBookingStep.registerConfirmation].includes(
                step,
            ) && <UserRegisterForm props={props} />}
            <SubmitAppointmentForm baseProps={props} />
            {props.submittedValues && (
                <ConfirmBookingModal
                    values={props.submittedValues}
                    attachments={props.attachDocumentsHandler.attachments}
                    onDiscard={props.onDiscard}
                    onConfirm={props.onConfirm}
                    isLoading={props.request.isLoading}
                    errors={props.errors}
                    useUserInfoModal={[
                        SlotBookingStep.login,
                        SlotBookingStep.loginOtpCode,
                        SlotBookingStep.confirmation,
                    ].includes(step)}
                    agreements={<Agreements baseProps={props} requestConfirmation={props.requestConfirmation} />}
                />
            )}
        </Form>
    );
};

const BookingForm = withFormik<FormProps, SlotBookingFormData>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(InnerForm);

export default withTranslation()(BookingForm);
