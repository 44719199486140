import { FormikProps, withFormik } from 'formik';
import React, { ChangeEvent } from 'react';
import * as Yup from 'yup';

import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Title, RadioSort } from './SortForm.styled';

import { SortTypes } from 'medrefer-web-sdk/api/models';
import useSortTypes from '../useSortTypes';
interface FormValues {
    sort: SortTypes;
}
interface FormProps {
    sort: SortTypes;
    setSort: (sort: string) => void;
}

const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props: FormProps & FormikProps<FormValues>) => {
    const { t } = useTranslation();
    const sortTypes = useSortTypes();

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.handleChange(e);
        props.handleSubmit();
    };

    return (
        <Form>
            <Form.Group>
                <Title>{t('sortMenu:title')}</Title>
                {sortTypes.map(({ value, key, name }) => {
                    return (
                        <RadioSort key={value} isActive={props.values.sort === value}>
                            <input
                                title="sortInput"
                                value={value}
                                checked={props.values.sort === value}
                                name="sort"
                                type="radio"
                                onChange={handleChange}
                            />
                            <div className="d-flex">
                                <span className="mr-1 w-auto">{key}:</span> <div>{name}</div>
                            </div>
                        </RadioSort>
                    );
                })}
            </Form.Group>
        </Form>
    );
};

const SortForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({ sort: props.sort }),
    validationSchema: Yup.object().shape({}),
    enableReinitialize: true,
    handleSubmit: (values, { props }) => {
        const { sort } = values;
        const { setSort } = props;
        setSort(sort.toLowerCase());
    },
})(InnerForm);

export default SortForm;
