import React from 'react';
import { DisplayArrayDataContainer, ItemContainer } from './DisplayArrayData.styled';

type Props = {
    labels: string[];
};

export const DisplayLabelsArray = ({ labels }: Props) => {
    return (
        <DisplayArrayDataContainer>
            {labels.map((label, key) => (
                <ItemContainer key={key}>
                    <p>{label}</p>
                </ItemContainer>
            ))}
        </DisplayArrayDataContainer>
    );
};
