import { WithTranslation } from 'react-i18next';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';
import {
    SlotCriteriaConditionData,
    SlotCriteriaManagementData,
    SlotCriteriaTranslation,
    SlotCriteriaValueData,
} from 'medrefer-web-sdk/api/models';
import { ApiRequest } from 'hooks/useApiRequest';

export interface SlotsCriteriaFormProps extends WithTranslation {
    activeItem?: SlotCriteriaTranslation;
    type: ModalType;
    request: ApiRequest;
    handleSubmit: (values: SlotCriteriaManagementData) => void;
}

export interface SlotCriteriaFormValues extends SlotCriteriaManagementData {
    enable_condition: boolean;
    condition_type: SlotCriteriaConditionType | null;
    enable_when: SlotCriteriaConditionData;
    values: SlotCriteriaValueData[];
}

export enum SlotCriteriaConditionType {
    anotherCriteria = 'anotherCriteria',
    healthcareService = 'healthcareService',
}
