import { useCallback, useEffect, useState } from 'react';
import { api } from 'medrefer-web-sdk/api';
import { SlotCriteria, SlotCriteriaValue } from 'medrefer-web-sdk/api/models';
import { idNameToOption, Option } from 'medrefer-web-sdk/web-kit';
import { useQuery } from 'react-query';
import { useQueryParams } from '../../medrefer-web-sdk/web-kit/hooks';
import { HealthcareServicesCriteriaParams } from '../../medrefer-web-sdk/api/models/forms/HealthcareServiceCriteriaFinderParams';

export const useOrganizationCriteriaOptions = () => {
    const queryParams = useQueryParams();
    const idHealthcareService = queryParams.get('id');
    const type = queryParams.get('type');
    const params: HealthcareServicesCriteriaParams = {
        healthcare_service: idHealthcareService,
        type: type,
    };
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const query = useQuery('slotsCriteria', () => api.getSlotsHSCriteria(params), {
        staleTime: Infinity,
    });
    const slotsCriteria = query.data || [];
    useEffect(() => {
        setIsLoading(true);
        const getData = async () => {
            await query.refetch();
            setIsLoading(false);
        };
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idHealthcareService]);

    const toOption = useCallback((criteriaOrValues: SlotCriteria[] | SlotCriteriaValue[]): Option[] => {
        return criteriaOrValues.map(idNameToOption);
    }, []);

    const getSingleCriteria = (criteriaId: string) => {
        return slotsCriteria?.find((criteria) => criteria.id.toString() === criteriaId) || null;
    };

    const getCriteriaValueOptions = (criteriaId: string) => {
        if (slotsCriteria) {
            const criteriaValues = getSingleCriteria(criteriaId)?.values;
            return (criteriaValues && toOption(criteriaValues)) || [];
        }
        return [];
    };

    return {
        isLoading, //isLoading: query.isLoading,
        slotsCriteria,
        getSingleCriteria,
        slotsCriteriaOptions: toOption(slotsCriteria),
        getCriteriaValueOptions,
    };
};
