import { FormSeparator, GreyTitle, H1, useModalState } from '../../../../medrefer-web-sdk/web-kit';
import { Modal } from 'medrefer-web-sdk/web-kit';
import React, { ReactElement } from 'react';
import {
    AppointmentRequestsModel,
    AppointmentRequestTime,
} from '../../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';
import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'react-bootstrap';
import { CustomRow } from '../../../shared/ConfirmBookingModal/ConfirmBookingModal.styled';
import { FormValue } from '../../../shared/FormValue';
import { formatISODate, getFullName } from '../../../../medrefer-web-sdk/api/utils';
import moment from 'moment';
import { DayCircle, DaySelectorContainer } from '../../../RequestsList/DayCircles/DayCircles.styled';
import { useSelector } from 'react-redux';
import { getBrand } from '../../../../features/auth/selectors';
import {
    EOptionalFields,
    useOptionalFields,
} from '../../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';
import { mapInsuranceToTranslation } from '../../../../utils/i18n';

interface Props {
    waitingRoomAppointment: AppointmentRequestsModel;
    button?: ReactElement;
}
export const WaitingRoomAppointmentDetailsModal = (props: Props) => {
    const modalState = useModalState();

    return <Modal isCentered={true} state={modalState} body={<ModalBody {...props} />} button={props.button} />;
};

const ModalBody = ({ waitingRoomAppointment }: Props) => {
    const { t } = useTranslation();
    const organization = useSelector(getBrand);
    const { isVisible } = useOptionalFields(organization);

    const getAddress = (): undefined | string => {
        if (
            waitingRoomAppointment.patient_metadata?.city === '' ||
            waitingRoomAppointment.patient_metadata?.street === ''
        )
            return undefined;

        return waitingRoomAppointment.patient_metadata?.city + ', ' + waitingRoomAppointment.patient_metadata?.street;
    };

    return (
        <Form>
            <H1 className="mb-4">{t('appointmentDetails:request_title')}</H1>
            <CustomRow className="mb-0 mr-0 ml-0">
                <Col>
                    <FormValue
                        title={t('slotOverview:practitioner')}
                        value={
                            waitingRoomAppointment.managing_user && waitingRoomAppointment.managing_user.first_name
                                ? getFullName(waitingRoomAppointment.managing_user)
                                : '-'
                        }
                    />
                    <FormValue
                        title={t('slotOverview:healthcare_service')}
                        value={
                            waitingRoomAppointment.healthcare_service?.name ||
                            waitingRoomAppointment.healthcare_service_group?.name
                        }
                    />
                </Col>
                <Col>
                    <Row>
                        <GreyTitle> {t('requestWaitingRoom:picked_days_confirmation_label')} </GreyTitle>
                    </Row>
                    <Row>
                        <DaySelectorContainer>
                            {waitingRoomAppointment.available_time.slice(0, 5).map((option, key) => (
                                <DayCircle
                                    key={key}
                                    isAllDay={
                                        waitingRoomAppointment.available_time[key] == AppointmentRequestTime.ALL_DAY
                                    }
                                    isMorning={
                                        waitingRoomAppointment.available_time[key] == AppointmentRequestTime.MORNING
                                    }
                                    isAfternoon={
                                        waitingRoomAppointment.available_time[key] == AppointmentRequestTime.AFTERNOON
                                    }
                                    small={false}
                                >
                                    {t('global:daynamesShort.' + (key + 1))}
                                </DayCircle>
                            ))}
                        </DaySelectorContainer>
                    </Row>
                </Col>
                <FormSeparator />
                <Col>
                    <FormValue
                        title={t('newAppointmentForm:title_personal_title')}
                        value={waitingRoomAppointment.patient?.personal_title}
                    />
                    <FormValue
                        title={t('newAppointmentForm:title_first_name')}
                        value={waitingRoomAppointment.patient?.first_name}
                    />
                    <FormValue
                        title={t('newAppointmentForm:title_last_name')}
                        value={waitingRoomAppointment.patient?.last_name}
                    />
                    <FormValue
                        title={t('newAppointmentForm:title_date_of_birth')}
                        value={
                            waitingRoomAppointment.patient_metadata?.date_of_birth &&
                            formatISODate(moment(waitingRoomAppointment.patient_metadata.date_of_birth))
                        }
                    />
                    <FormValue
                        title={t('newAppointmentForm:title_email')}
                        value={waitingRoomAppointment.patient?.email}
                    />
                </Col>
                <Col>
                    {isVisible(EOptionalFields.phone) && (
                        <FormValue
                            title={t('newAppointmentForm:title_phone_number')}
                            value={waitingRoomAppointment.patient?.phone}
                        />
                    )}
                    {isVisible(EOptionalFields.insurance_status) && (
                        <FormValue
                            title={t('newAppointmentForm:title_insurance_status')}
                            value={
                                waitingRoomAppointment.patient_metadata?.insurance_status &&
                                mapInsuranceToTranslation(waitingRoomAppointment.patient_metadata?.insurance_status)
                            }
                        />
                    )}
                    {isVisible(EOptionalFields.insurance_number) && (
                        <FormValue
                            title={t('newAppointmentForm:title_insurance_number')}
                            value={waitingRoomAppointment.patient_metadata?.insurance_number}
                        />
                    )}
                    {isVisible(EOptionalFields.address) && (
                        <FormValue title={t('newAppointmentForm:title_address')} value={getAddress()} />
                    )}
                    <FormValue
                        title={t('newAppointmentForm:title_confirmation_message')}
                        value={waitingRoomAppointment.comment}
                    />
                    {waitingRoomAppointment.attachments.length !== 0 && (
                        <FormValue
                            title={t('newAppointmentForm:title_attachments')}
                            value={waitingRoomAppointment.attachments.map((file) => file.filename)}
                        />
                    )}
                </Col>
            </CustomRow>
        </Form>
    );
};
