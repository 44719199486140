import { useCallback, useRef, useState } from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { PaginatedDataParams, SlotCriteriaManagementData, SlotCriteriaTranslation } from 'medrefer-web-sdk/api/models';
import { api } from 'medrefer-web-sdk/api';
import { useModalState, useTableOrdering } from 'medrefer-web-sdk/web-kit';
import { errorTimeout } from 'utils/constants';

export const useManageSlotsCriteria = () => {
    const { ordering, sortHandler } = useTableOrdering(['-id']);
    const [activeItem, setActiveItem] = useState<SlotCriteriaTranslation | null>(null);
    const [tableParams, setTableParams] = useState<{ pageIndex: number; pageSize: number; searchPhrase: string }>({
        pageIndex: 0,
        pageSize: 0,
        searchPhrase: '',
    });

    const [data, setData] = useState<SlotCriteriaTranslation[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const requestLoadSlotsCriteria = useApiRequest();
    const request = useApiRequest({ errorTimeout });
    const addModalState = useModalState();
    const deleteModalState = useModalState({ actionOnClose: () => setActiveItem(null) });
    const editModalState = useModalState({ actionOnClose: () => setActiveItem(null) });

    const fetchIdRef = useRef(0);

    const fetchData = useCallback(
        (pageIndex, pageSize, searchPhrase) => {
            const fetchId = ++fetchIdRef.current;
            setTableParams({ pageIndex, pageSize, searchPhrase });

            if (fetchId === fetchIdRef.current) {
                const params: PaginatedDataParams = {
                    offset: pageSize * pageIndex,
                    limit: pageSize,
                    search: searchPhrase,
                    ordering: ordering.join(','),
                };

                requestLoadSlotsCriteria.dispatch(api.getOrganizationSlotsCriteria(params)).then((res) => {
                    setPageCount(Math.ceil(res.count / pageSize));
                    setData(res.results);
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ordering],
    );

    /**
     * Location actions.
     */
    const reloadData = () => {
        fetchData(tableParams.pageIndex, tableParams.pageSize, tableParams.searchPhrase);
    };

    const fetchActiveItem = (item: SlotCriteriaTranslation) => {
        if (activeItem || !item.id) return;

        request.dispatch(api.getSingleOrganizationSlotsCriteria(item.id)).then((slotsCriteria) => {
            setActiveItem(slotsCriteria);
        });
    };

    const createSlotsCriteria = (values: SlotCriteriaManagementData) => {
        request.dispatch(api.createSlotCriteria(values)).then(() => {
            addModalState.toggleModal();
            reloadData();
        });
    };

    const updateSlotsCriteria = (values: SlotCriteriaManagementData) => {
        if (!activeItem || !activeItem.id) return;

        request.dispatch(api.updateSlotCriteria(activeItem.id, values)).then(() => {
            editModalState.toggleModal();
            reloadData();
        });
    };

    const deleteSlotsCriteria = () => {
        if (!activeItem || !activeItem.id) return;
        request.dispatch(api.deleteSlotCriteria(activeItem.id)).then(() => {
            deleteModalState.toggleModal();
            reloadData();
        });
    };

    return {
        createSlotsCriteria,
        updateSlotsCriteria,
        deleteSlotsCriteria,
        isLoading: requestLoadSlotsCriteria.isLoading,
        request,
        activeItem,
        fetchActiveItem,
        data,
        pageCount,
        fetchData,
        addModalState,
        editModalState,
        deleteModalState,
        ordering,
        sortHandler,
    };
};
