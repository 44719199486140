import { ActivityIndicator } from '../../ActivityIndicator';
import { PrimaryButton } from '../../buttons';
import React from 'react';

interface LoadMoreButtonProps {
    hasMore?: boolean;
    isLoading?: boolean;
    loadMore?: () => void;
    text?: string;
}

export const LoadMoreButton = ({ hasMore, isLoading, loadMore, text }: LoadMoreButtonProps) => {
    if (!isLoading && !hasMore) {
        return null;
    }

    return (
        <div className="d-flex justify-content-center pb-3 pt-3">
            {isLoading ? (
                <ActivityIndicator />
            ) : (
                <PrimaryButton onClick={loadMore} style={{ width: 'fit-content', padding: '0 25px' }}>
                    {text}
                </PrimaryButton>
            )}
        </div>
    );
};
