import { useCallback, useEffect, useState } from 'react';
import { PaymentIntent } from '@stripe/stripe-js';
import { getStripeClient } from 'components/plugins/StripePayments/utils';

export const usePaymentIntent = (intentClientSecret: string, startReloadingSteps: () => void) => {
    const [paymentIntent, setPaymentIntent] = useState<PaymentIntent | undefined>();
    const [isPaymentIntentLoading, setIsPaymentIntentLoading] = useState(true);
    const isPaymentProcessing = paymentIntent && paymentIntent.status !== 'requires_payment_method';

    const fetchPaymentIntent = useCallback(async (intentClientSecret: string) => {
        const stripe = await getStripeClient();
        const result = await stripe.retrievePaymentIntent(intentClientSecret);
        return result.paymentIntent;
    }, []);

    const loadPaymentIntent = useCallback(
        async (intentClientSecret: string) => {
            try {
                setPaymentIntent(await fetchPaymentIntent(intentClientSecret));
            } catch {}
            setIsPaymentIntentLoading(false);
        },
        [fetchPaymentIntent],
    );

    useEffect(() => {
        loadPaymentIntent(intentClientSecret);
    }, [loadPaymentIntent, intentClientSecret]);

    useEffect(() => {
        if (isPaymentProcessing) {
            startReloadingSteps();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPaymentProcessing]);

    return {
        paymentIntent,
        isPaymentProcessing,
        isPaymentIntentLoading,
    };
};
