import React from 'react';
import {
    Button,
    ConfirmModalBody,
    FormErrorMessage,
    InformationContainer,
    Modal,
    RedButton,
    useModalState,
    PrimaryButton,
} from 'medrefer-web-sdk/web-kit';
import { FormValues, SchedulePopoverProps } from 'components/Calendar/SchedulePopover/SchedulePopover.types';

import { FormikProps } from 'formik';
import { Container, Col, Row } from 'react-bootstrap';
import { SchedulePopoverType } from 'components/Calendar/Calendar.types';
import { useTranslation } from 'react-i18next';
import { SlotEditScope } from 'medrefer-web-sdk/api/models';
import { RecurringSlotActionConfirmation } from 'components/Calendar/SchedulePopover/RecurringSlotActionConfirmation';
import { BookSlotForPatientModal } from 'components/shared/BookSlotForPatient';

interface Props {
    baseProps: SchedulePopoverProps & FormikProps<FormValues>;
}

export const FormButtons = ({ baseProps }: Props) => {
    const { t } = useTranslation();
    const submitSlotModalState = useModalState();
    const deleteSlotModalState = useModalState();
    const bookSlotModalState = useModalState();

    const handleSlotConfirmation = (selectedScope: SlotEditScope) => {
        baseProps.setFieldValue('scope', selectedScope);
        baseProps.submitForm();
    };

    const handleSaveRecurringSlot = (e: React.MouseEvent<HTMLElement>) => {
        e.preventDefault();
        if (baseProps.values.date < new Date()) {
            baseProps.submitForm();
            return;
        }
        submitSlotModalState.toggleModal();
    };

    const onSlotBooked = () => {
        bookSlotModalState.toggleModal();
        baseProps.closePopoverAndReloadAppointments();
    };

    const errorMessage = <FormErrorMessage apiErrors={baseProps.request.errors} />;

    return (
        <Container>
            <Row>
                {baseProps.type === SchedulePopoverType.edit && baseProps.activeSlot ? (
                    <>
                        <Col className="ml-auto" xs={12} md={4}>
                            <Modal
                                state={deleteSlotModalState}
                                button={
                                    <RedButton type="button">{t('editAppointmentForm:delete_button_text')}</RedButton>
                                }
                                body={
                                    baseProps.activeSlot.is_recurring ? (
                                        <RecurringSlotActionConfirmation
                                            title={t('calendar:deleteRecurringSlot.title')}
                                            acceptButton={t('calendar:deleteRecurringSlot.accept_btn_message')}
                                            declineButton={t('calendar:deleteRecurringSlot.decline_btn_message')}
                                            toggleModal={deleteSlotModalState.toggleModal}
                                            handleSubmit={baseProps.deleteSlot}
                                            baseProps={baseProps}
                                        />
                                    ) : (
                                        <ConfirmModalBody
                                            title={t('calendar:deleteSlotModalBody.title')}
                                            message={t('calendar:deleteSlotModalBody.message')}
                                            confirmButton={t('calendar:deleteSlotModalBody.accept_btn_message')}
                                            declineButton={t('calendar:deleteSlotModalBody.decline_btn_message')}
                                            onDecline={deleteSlotModalState.toggleModal}
                                            onConfirm={baseProps.deleteSlot}
                                        />
                                    )
                                }
                            />
                        </Col>
                        <Col className="ml-auto" xs={12} md={4}>
                            <BookSlotForPatientModal
                                state={bookSlotModalState}
                                button={
                                    <PrimaryButton type="button">
                                        {t('editAppointmentForm:book_button_text')}
                                    </PrimaryButton>
                                }
                                slot={baseProps.activeSlot}
                                onSlotBooked={onSlotBooked}
                            />
                        </Col>
                        <Col className="ml-auto" xs={12} md={4}>
                            {baseProps.activeSlot.is_recurring ? (
                                <>
                                    <Modal
                                        state={submitSlotModalState}
                                        body={
                                            <RecurringSlotActionConfirmation
                                                title={t('calendar:editRecurringSlot.title')}
                                                acceptButton={t('calendar:editRecurringSlot.accept_btn_message')}
                                                declineButton={t('calendar:editRecurringSlot.decline_btn_message')}
                                                toggleModal={submitSlotModalState.toggleModal}
                                                handleSubmit={handleSlotConfirmation}
                                                baseProps={baseProps}
                                            />
                                        }
                                    />
                                    <Button onClick={handleSaveRecurringSlot}>
                                        {t('editAppointmentForm:save_button_text')}
                                    </Button>
                                </>
                            ) : (
                                <Button>{t('editAppointmentForm:save_button_text')}</Button>
                            )}
                        </Col>
                    </>
                ) : (
                    <Col className="m-auto" xs={12} md={12}>
                        <Button isLoading={baseProps.request.isLoading}>{t('newSlotForm:button_label')}</Button>
                    </Col>
                )}
            </Row>
            {errorMessage && <InformationContainer>{errorMessage}</InformationContainer>}
        </Container>
    );
};
