import React, { FC, ReactNode } from 'react';
import { ReactSVG } from 'react-svg';
import { Container, Row } from 'react-bootstrap';
import { pageStyles, PageTitle } from '../Auth.styled';

import LoginIllustration from 'assets/LoginIllustration.svg';
import * as Styled from '../Auth.styled';
import { AppLogo } from 'components/shared/AppLogo/AppLogo';
interface Props {
    pageTitle: string;
    children?: ReactNode;
}

export const AuthPage: FC<Props> = ({ pageTitle, children }: Props) => {
    return (
        <section>
            <Container fluid style={pageStyles.container}>
                <Row>
                    <Styled.LeftCol lg={5}>
                        <ReactSVG src={LoginIllustration} />
                    </Styled.LeftCol>
                    <Styled.RightCol
                        xs={12}
                        lg={7}
                        style={{ height: '100vh', overflowY: 'scroll', paddingBottom: '50px' }}
                    >
                        <Styled.FormBox>
                            <AppLogo className="mb-4" />
                            <PageTitle>{pageTitle}</PageTitle>
                            {children}
                        </Styled.FormBox>
                    </Styled.RightCol>
                </Row>
            </Container>
        </section>
    );
};
