import styled from 'styled-components';

export const NotFoundContainer = styled.div`
    align-items: center;
    color: #1f1f1f;
    display: flex;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 100;
    background: white;
`;

export const Message = styled.div`
    display: flex;
    flex-wrap: wrap;
    height: auto;
    justify-content: center;
    width: 100%;
    min-height: unset;
`;

export const MessageBox = styled.div`
    margin: 0 auto;
    max-width: 700px;
    padding: 1.875rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;

    h1 {
        color: ${({ theme }) => theme.accent};
        font-size: 10em;
        font-weight: 700;
    }

    .icon {
        display: inline-block;
        transform: translateY(-5px);
        margin-right: 10px;
    }

    button {
        width: 200px;
        margin-top: 20px;
    }
`;
