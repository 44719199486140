import React, { FC, ReactNode } from 'react';
import { PopoverState } from './hooks/usePopoverState';
import ReactDOM from 'react-dom';
import CloseIcon from '../../assets/icons/CloseIcon.png';
import { ActivityIndicator } from '../ActivityIndicator';
import {
    PopoverLoaderContainer,
    PopoverOverlay,
    PopoverWrapper,
    CloseButton,
    PopoverContent,
    PopoverContentWrapper,
} from './Popover.styled';
import { PopoverPosition, usePopoverPosition } from './hooks/usePopoverPosition';

interface Props {
    state: PopoverState;
    body?: ReactNode;
    className?: string;
    isLoading?: boolean;
    onCloseAction?: () => void;
}

const PopoverLoader = ({ position }: { position: PopoverPosition }) => (
    <PopoverLoaderContainer position={position}>
        <ActivityIndicator />
    </PopoverLoaderContainer>
);

export const Popover: FC<Props> = ({ body, state, isLoading, onCloseAction }: Props) => {
    const position = usePopoverPosition(state);

    const handleClick = () => {
        state.togglePopover();
        onCloseAction && onCloseAction();
    };

    return ReactDOM.createPortal(
        <PopoverWrapper isOpen={state.isOpen}>
            <PopoverOverlay onClick={handleClick} />
            <PopoverContentWrapper position={position}>
                <PopoverContent>
                    <CloseButton className="d-flex" onClick={handleClick}>
                        <img style={{ cursor: 'pointer' }} alt="Close icon" src={CloseIcon} />
                    </CloseButton>

                    {isLoading ? <PopoverLoader position={position} /> : body}
                </PopoverContent>
            </PopoverContentWrapper>
        </PopoverWrapper>,
        document.body,
    );
};
