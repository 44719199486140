import useApiRequest from 'hooks/useApiRequest';
import { api } from 'medrefer-web-sdk/api';
import {
    FhirQuestionnaireResponseStatus,
    QuestionnaireResponseData,
    QuestionnaireResponseExtended,
    UserRole,
} from 'medrefer-web-sdk/api/models';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'features/auth/selectors';

interface PatientQuestionnaireParams {
    appointmentId: string | undefined;
    questionnaireId: string | undefined;
}

export const useQuestionnaireResponseData = () => {
    const queryParams = useQueryParams();
    const accessToken = queryParams.get('token');
    const user = useSelector(getAuthUser);
    const [questionnaireResponse, setQuestionnaireResponse] = useState<QuestionnaireResponseExtended | null>(null);
    const [linkInvalid, setLinkInvalid] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState<boolean>(false);
    const request = useApiRequest();
    const params: PatientQuestionnaireParams = useParams();
    const appointmentId = params.appointmentId ? parseInt(params.appointmentId) : undefined;
    const questionnaireId = params.questionnaireId ? parseInt(params.questionnaireId) : undefined;
    const isError = linkInvalid || request.errors != null;
    const readOnly = user?.role !== UserRole.patient && !accessToken;

    useEffect(() => {
        if (!appointmentId || !questionnaireId) {
            setLinkInvalid(true);
        } else {
            request
                .dispatch(api.getQuestionnaireResponse(appointmentId, questionnaireId, accessToken))
                .then(setQuestionnaireResponse);
        }
        // eslint-disable-next-line
    }, []);

    const onSubmit = (content: string) => {
        if (questionnaireResponse && appointmentId && !readOnly) {
            const dto: QuestionnaireResponseData = {
                content,
                questionnaire: questionnaireResponse.questionnaire.id,
                status: FhirQuestionnaireResponseStatus.COMPLETED,
            };

            api.updateQuestionnaireResponse(questionnaireResponse.id, appointmentId, dto, accessToken).then(() => {
                setSubmitSuccess(true);
            });
        } else {
            setSubmitSuccess(true);
        }
    };

    return {
        questionnaireResponse,
        isError,
        readOnly,
        onSubmit,
        submitSuccess,
        isLoading: request.isLoading,
    };
};
