import styled, { css } from 'styled-components';
import CheckBoxIcon from '../../assets/icons/svg/CheckBoxIcon.svg';
import AsteriksIcon from '../../assets/icons/AsteriksIcon.png';
import ExclamationCircleIcon from '../../assets/icons/ExclamationCircleIcon.png';

//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CheckBox = styled.input.attrs({ type: 'checkbox' })<any>`
    appearance: initial;
    visibility: hidden;
    box-sizing: border-box;
    color: red;
    position: relative;
    cursor: pointer;
    &:before {
        box-sizing: border-box;
        content: '';
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        width: 22px;
        height: 22px;
        border: 1px solid ${({ theme }) => theme.purple100};
    }
    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 22px;
        height: 22px;
        visibility: visible;
        opacity: 0;
        content: '';
        background-size: 80%;
        background-color: ${({ theme }) => theme.accent};
        mask: url(${CheckBoxIcon}) no-repeat 50% 50%;
    }
    &:checked {
        &:after {
            opacity: 1;
            background-position: center;
        }
    }
    &[disabled] {
        cursor: default;
        &:after {
            background-color: ${({ theme }) => theme.grey200};
        }
    }
    ${(props) =>
        props.isError &&
        css`
            &:before {
                border: 1px solid ${({ theme }) => theme.purple100};
                background: rgba(255, 238, 243, 0.5);
            }
        `}
`;

interface CheckBoxLabelProps {
    isRequired?: boolean;
}

export const CheckBoxLabel = styled.label<CheckBoxLabelProps>`
    font-size: 14px;
    font-weight: normal !important;
    line-height: 19px;
    color: #767785 !important;
    max-width: 390px;
    overflow-wrap: anywhere;
    margin-left: 5px;
    @-moz-document url-prefix() {
        padding-right: 10px;
        @media (max-width: ${({ theme }) => theme.devices.desktop}) {
            padding-right: 0px;
        }
    }

    a {
        margin: 0 4px;
        cursor: pointer;
        text-decoration: none;
        font-weight: 700;
        color: ${({ theme }) => theme.black};
    }
    position: relative;
    ${(props) =>
        props.isRequired &&
        css`
            &:after {
                margin-left: 8px;
                top: 0px;
                right: 0;
                position: absolute;
                content: '';
                background-image: url(${AsteriksIcon});
                width: 6px;
                height: 6px;
                background-size: 6px 6px;
            }
        `}

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        min-width: 0;

        input {
            padding-right: 10px;
        }
    }
`;

export const CheckBoxErrorIcon = styled.div`
    width: 24px;
    height: 24px;
    background-image: url(${ExclamationCircleIcon});
    background-size: 100%;
    background-repeat: no-repeat;
    transform: translate(5px, 5px);
`;
