import { Card } from 'react-bootstrap';
import styled from 'styled-components';

export const TableViewHeader = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
`;

export const TableViewContainer = styled.div`
    padding: 40px 20px;
`;
export const TableContainer = styled(Card)`
    margin-top: 20px;
    padding: 10px 20px;
`;

export const AddButtonContainer = styled.div`
    button {
        padding: 0 20px;
    }
`;
