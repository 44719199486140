import { Questionnaire } from 'medrefer-web-sdk/api/models';
import { Option } from 'medrefer-web-sdk/web-kit';
import { api } from 'medrefer-web-sdk/api';

export const useQuestionnaireOptions = () => {
    const toQuestionnaireOption = (questionnaire: Questionnaire) => {
        return {
            label: questionnaire.title,
            value: questionnaire.id.toString(),
        };
    };

    const loadQuestionnaireOptions = async (search: string, loadedOptions: Option[]) => {
        const res = await api.getQuestionnaires({
            limit: 20,
            offset: loadedOptions.length,
            search,
        });

        return {
            options: res.results.map(toQuestionnaireOption),
            hasMore: res.next != null,
        };
    };

    return {
        loadQuestionnaireOptions,
        toQuestionnaireOption,
    };
};
