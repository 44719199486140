import React, { useState, useMemo } from 'react';
import { FormGroup, FormSelect, H1, Label, Select, ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import { useOrganizationCriteriaOptions } from 'hooks/options/useOrganizationCriteriaOptions';
import { Row, Col } from 'react-bootstrap';
import { IconButtonAlt } from 'medrefer-web-sdk/web-kit';
import { ScheduleFormBaseProps } from './ScheduleFormData';
import { Field, FieldArray } from 'formik';
import TrashIcon from 'medrefer-web-sdk/web-kit/assets/icons/TrashIcon.png';
import PlusIcon from 'assets/PlusIcon.svg';
import { Option } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { getFormFieldValue } from 'medrefer-web-sdk/web-kit/components/formComponents/Select/utils';

export const CriteriaPicker = ({ baseProps }: ScheduleFormBaseProps) => {
    const { t } = useTranslation();
    const [selectedCriteria, setSelectedCriteria] = useState<Option | null>(null);
    const { slotsCriteriaOptions, getSingleCriteria, getCriteriaValueOptions, isLoading } =
        useOrganizationCriteriaOptions();

    const currentSlotsCriteriaOptions = useMemo(() => {
        return slotsCriteriaOptions?.filter(
            (criteriaOption) => !baseProps.values.criteriaIds.some((criteria) => criteria.id === criteriaOption.value),
        );
    }, [baseProps.values.criteriaIds, slotsCriteriaOptions]);

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center">
                <ActivityIndicator />
            </div>
        );
    }

    if (!slotsCriteriaOptions || slotsCriteriaOptions.length == 0) {
        return <p>{t('newSlotForm:no_criteria_options_message')}</p>;
    }

    return (
        <>
            <H1 className="mb-3">{t('newSlotForm:criteria_title')}</H1>

            <FormGroup>
                <FieldArray
                    name="criteriaIds"
                    render={(arrayHelpers) => (
                        <>
                            {currentSlotsCriteriaOptions && currentSlotsCriteriaOptions.length > 0 && (
                                <Row>
                                    <Col sm={3}>
                                        <Label isRequired labelTop>
                                            {t('newSlotForm:add_criteria_label')}
                                        </Label>
                                    </Col>
                                    <Col sm={7}>
                                        <Select
                                            name="criteriaPicker"
                                            value={selectedCriteria}
                                            onChange={setSelectedCriteria}
                                            options={currentSlotsCriteriaOptions}
                                            placeholder={t('newSlotForm:criteria_placeholder')}
                                        />
                                    </Col>
                                    <Col sm={2}>
                                        <IconButtonAlt
                                            type="button"
                                            onClick={() => {
                                                if (selectedCriteria) {
                                                    const singleCriteria = getSingleCriteria(selectedCriteria.value);
                                                    arrayHelpers.push({
                                                        id: selectedCriteria.value,
                                                        multiple_selection: singleCriteria?.multiple_selection,
                                                        values: [],
                                                    });
                                                    setSelectedCriteria(null);
                                                }
                                            }}
                                        >
                                            <img src={PlusIcon} alt="Add" />
                                        </IconButtonAlt>
                                    </Col>
                                </Row>
                            )}
                            {baseProps.values.criteriaIds.map((criteria, index) => {
                                const fieldName = `criteriaIds[${index}].values`;
                                const singleCriteria = getSingleCriteria(criteria.id);
                                const isMulti = singleCriteria?.multiple_selection;

                                return (
                                    <FormGroup key={index}>
                                        <Row>
                                            <Col sm={10}>
                                                <Label isRequired labelTop>
                                                    {singleCriteria?.name}
                                                </Label>
                                                <Field
                                                    name={fieldName}
                                                    options={getCriteriaValueOptions(criteria.id)}
                                                    component={FormSelect}
                                                    placeholder={t('newSlotForm:criteria_value_placeholder')}
                                                    isMulti={isMulti}
                                                    onBlur={baseProps.handleBlur}
                                                    onChange={(option: Option): void => {
                                                        if (isMulti) {
                                                            baseProps.setFieldValue(
                                                                fieldName,
                                                                getFormFieldValue(option),
                                                            );
                                                        } else {
                                                            baseProps.setFieldValue(fieldName, [option.value]);
                                                        }
                                                    }}
                                                />
                                            </Col>
                                            <Col sm={2} className="d-flex align-items-end">
                                                <IconButtonAlt onClick={() => arrayHelpers.remove(index)} type="button">
                                                    <img src={TrashIcon} alt="Delete" />
                                                </IconButtonAlt>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                );
                            })}
                        </>
                    )}
                />
            </FormGroup>
        </>
    );
};
