import { Theme } from './theme.types';

export const theme: Theme = {
    accent: '#6a6ef9',
    background: '#fafafc',
    purple50: '#f4f4ff',
    purple60: '#edeffe',
    purple75: '#eaeaf4',
    purple100: '#dbdcee',
    purple200: '#c5c5d2',
    purple300: '#6a6ef9',
    red100: '#eec0cd',
    red200: '#ee5755',
    red300: '#f82863',
    red400: '#d34f4d',
    white: '#ffffff',
    semiWhite: '#f9f9f9',
    grey50: '#f5f5f5',
    grey100: '#eeeef2',
    grey150: '#747478',
    grey200: '#9d9eb2',
    grey300: '#555',
    grey400: '#24252f',
    green100: '#eaf9ec',
    green200: '#4cc13a',
    yellow: '#d99f00',
    orange: '#f97c27',
    black: '#000',
    lavender: '#dcbeff',
    cyan: '#42d4f4',

    darkslategray: '#2f4f4f',
    saddlebrown: '#8b4513',
    darkgreen: '#006400',
    darkkhaki: '#bdb76b',
    indigo: '#4b0082',
    red: '#ff0000',
    darkturquoise: '#00ced1',
    lime: '#00ff00',
    mediumspringgreen: '#00fa9a',
    blue: '#0000ff',
    fuchsia: '#ff00ff',
    cornflower: '#6495ed',
    deeppink: '#ff1493',
    lightpink: '#ffb6c1',

    light: 300,
    semiBold: 600,
    bold: 800,
    fontSize: {
        xxs: '1rem',
        xs: '1.2rem',
        sm: '1.6rem',
        md: '2.1rem',
        lg: '2.4rem',
        xl: '4rem',
    },
    devices: {
        tablet: '768px',
        desktop: '992px',
    },
    navbarHeight: {
        desktop: '75px',
        mobile: '60px',
    },
    footerHeight: '75px',
    parsePx: (number: string) => {
        return parseInt(number.replace('px', ''));
    },
};
