import React from 'react';
import { GreyTitle as Title, ValueTypography as Value } from 'medrefer-web-sdk/web-kit';
import { Row } from 'react-bootstrap';

export const ArrayValue = ({ values }: { values: Array<string> }) => {
    return (
        <ul style={{ paddingLeft: '15px', listStyle: 'decimal' }}>
            {values.map((value) => (
                <li key={value}>
                    <Value>{value}</Value>
                </li>
            ))}
        </ul>
    );
};

export const FormValue = ({ title, value }: { title: string; value?: string | Array<string> | null }) => {
    return (
        <>
            <Row>
                <Title> {title}</Title>
            </Row>
            <Row>
                {value ? typeof value === 'object' ? <ArrayValue values={value} /> : <Value>{value}</Value> : '-'}
            </Row>
        </>
    );
};
