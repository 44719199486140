import React, { useEffect } from 'react';
import { api } from 'medrefer-web-sdk/api';
import usePaginatedData from 'hooks/usePaginatedData';
import { defaultSlotMode, HealthcareServiceSlots } from 'medrefer-web-sdk/api/models';
import { ServiceSlots } from 'components/shared/ServiceSlots';
import { InformationBubble, InformationBubbleTypes, LoadMoreList } from 'medrefer-web-sdk/web-kit';
import { Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useServiceSlotsConfig } from 'components/shared/ServiceSlots/useServiceSlotsConfig';
import { ServiceSearchValues } from './ServiceSearchForm';
import { getHealthcareServiceNoSlotsBehaviour } from 'components/shared/ServiceSlots/utils';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import { routerActions } from 'connected-react-router';
import useBrand from 'hooks/useBrand';
import { useDispatch } from 'react-redux';

type Props = {
    filterParams: ServiceSearchValues;
};

const ServiceSlotsList: React.FC<Props> = ({ filterParams }: Props) => {
    const { t } = useTranslation();
    const data = usePaginatedData<HealthcareServiceSlots>();
    const { maxDaysRangeSize, initDateFrom, initFetchedDateTo } = useServiceSlotsConfig();
    const queryParams = useQueryParams();
    const { getPathBranded } = useBrand();
    const dispatch = useDispatch();

    useEffect(() => {
        data.clear();
        loadMore([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterParams]);

    const loadMore = (prev: HealthcareServiceSlots[]) => {
        const promise = api.getHealthcareServiceSlots({
            offset: prev.length,
            generalSearch: filterParams.generalSearchOption?.value,
            generalSearchType: filterParams.generalSearchOption?.type,
            criteriaValues: filterParams.criteriaValues,
            location: filterParams.location,
            healthcareServiceIds: filterParams.healthcareServiceIds,
            mode: filterParams.mode,
            slotsAfter: initDateFrom,
            slotsBefore: initFetchedDateTo,
        });

        data.appendFromRequest(promise);
    };

    const handleSlotClick = (slotKey: string) => {
        const params = queryParams.copy();
        if (params.get('mode') == null) {
            params.set('mode', defaultSlotMode);
        }
        const path = getPathBranded('slotBooking', slotKey);
        dispatch(routerActions.push(path + params.toString()));
    };

    return (
        <Container style={{ maxWidth: '100vw' }}>
            <LoadMoreList
                data={data.items}
                hasMore={data.hasMore}
                isLoading={data.isLoading}
                loadMore={loadMore}
                renderItem={({ item }) => (
                    <ServiceSlots
                        healthcareService={item}
                        initDateFrom={initDateFrom}
                        initFetchedDateFrom={initDateFrom}
                        initFetchedDateTo={initFetchedDateTo}
                        maxDaysRangeSize={maxDaysRangeSize}
                        slotFilterParams={{
                            generalSearch: filterParams.generalSearchOption?.value,
                            generalSearchType: filterParams.generalSearchOption?.type,
                            criteriaValues: filterParams.criteriaValues,
                        }}
                        noSlotsBehaviour={getHealthcareServiceNoSlotsBehaviour(item)}
                        handleSlotClick={handleSlotClick}
                    />
                )}
                ListEmptyComponent={
                    !data.isLoading && (
                        <Col md="3" className="ml-auto mr-auto">
                            <InformationBubble type={InformationBubbleTypes.info}>
                                {t('publicSearch:no_services_found')}
                            </InformationBubble>
                        </Col>
                    )
                }
                loadMoreText={t('pagination:load_more')}
            />
        </Container>
    );
};

export default ServiceSlotsList;
