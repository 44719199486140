import React from 'react';
import {
    ActivityIndicator,
    H1,
    InformationBubble,
    InformationBubbleTypes,
    ValueTypography,
} from 'medrefer-web-sdk/web-kit';
import { AppointmentModel, AppointmentStep, AppointmentStepType } from 'medrefer-web-sdk/api/models';
import { useTranslation } from 'react-i18next';
import { usePaymentIntent } from 'components/plugins/StripePayments/usePaymentIntent';
import { PaymentMethodForm } from 'components/plugins/StripePayments/PaymentMethodForm';

export interface AppointmentPaymentStepProps {
    appointment: AppointmentModel;
    step: AppointmentStep<AppointmentStepType.stripePayment>;
    startReloadingSteps: () => void;
}

export const AppointmentPaymentStep = ({ appointment, step, startReloadingSteps }: AppointmentPaymentStepProps) => {
    const { t } = useTranslation('plugins');
    const { paymentIntent, isPaymentIntentLoading, isPaymentProcessing } = usePaymentIntent(
        step.step_details.intent_client_secret,
        startReloadingSteps,
    );

    if (isPaymentIntentLoading) {
        return <ActivityIndicator absoluteCenter />;
    }

    return (
        <>
            <H1 className="text-center">{t('stripePayment.step_title')}</H1>
            <ValueTypography className="d-block text-center">{t('stripePayment.step_description')}</ValueTypography>
            {paymentIntent && !isPaymentProcessing && <PaymentMethodForm appointment={appointment} step={step} />}
            {paymentIntent && isPaymentProcessing && (
                <>
                    <p>{t('stripePayment.payment_processing')}</p>
                    <ActivityIndicator />
                </>
            )}
            {!paymentIntent && (
                <InformationBubble type={InformationBubbleTypes.warning}>
                    {t('stripePayment.loading_payment_error')}
                </InformationBubble>
            )}
        </>
    );
};
