import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const SearchTitle = styled.h1`
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
    max-width: 800px;
`;

export const SearchSubtitle = styled.p`
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
    text-align: center;
    max-width: 800px;
`;

export const BookingContainer = styled(Row)`
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        flex-direction: column-reverse;
    }
`;
