import { useState } from 'react';
import { ApiErrorResponse } from 'medrefer-web-sdk/api/ApiTypes';
import { useTemporaryState } from 'medrefer-web-sdk/web-kit/hooks/useTemporaryState';
import { useTranslation } from 'react-i18next';

export interface ApiRequestOptions {
    errorTimeout?: number;
}

export interface ApiRequest {
    errors?: ApiErrorResponse;
    isLoading: boolean;
    isCompleted: boolean;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    dispatch: <T = any>(promise: Promise<T>) => Promise<T>;
    clearErrors: () => void;
}

/**
 * A hook that wraps an API promise to get a basic information about the request.
 */
const useApiRequest = (options?: ApiRequestOptions): ApiRequest => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCompleted, setIsCompleted] = useState(false);
    const [errors, setErrors] = useTemporaryState<ApiErrorResponse | undefined>(undefined, options?.errorTimeout);
    const { t } = useTranslation();

    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const dispatch = <T = any>(promise: Promise<T>): Promise<T> => {
        setErrors(undefined);
        setIsCompleted(false);
        setIsLoading(true);

        promise
            .then(() => {
                setIsCompleted(true);
                setIsLoading(false);
            })
            .catch((error) => {
                try {
                    setErrors(error.response.data);
                } catch (e) {
                    setErrors({
                        non_field_errors: [
                            {
                                message: t('global:unknown_error_from_api'),
                                code: 'error',
                            },
                        ],
                    });
                }
                setIsLoading(false);
            });

        return promise;
    };

    const clearErrors = () => {
        setErrors(undefined);
    };

    return {
        errors,
        isLoading,
        isCompleted,
        dispatch,
        clearErrors,
    };
};

export default useApiRequest;
