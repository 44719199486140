import React from 'react';

import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'react-bootstrap';

import { FormProps, FormRowProps } from './FilterForm.types';
import { Amount, CheckBox, Label, Title } from './FilterForm.styled';
import { BookingStatus } from 'components/Dashboard/StatusBadge/StatusBadge';

const FormRowCheckbox = ({ checked, id, count, onClick }: FormRowProps) => {
    const { t } = useTranslation();
    const label = {
        UPCOMING: t('filterForm:upcoming'),
        PAST: t('filterForm:past'),
        CANCELLED: t('filterForm:cancelled'),
    }[id];

    return (
        <Row className="mb-1">
            <Col className="d-flex align-items-start" xs={9}>
                <Label>
                    <CheckBox id={id} checked={checked} onChange={onClick} className="mr-4" />
                    <div style={{ lineHeight: '13px' }}>{label}</div>
                </Label>
            </Col>
            <Col xs={1}>
                <Amount>{count}</Amount>
            </Col>
        </Row>
    );
};

export const FilterForm = ({ countData, filters, setStatuses }: FormProps) => {
    const { t } = useTranslation();

    const handleChangeStatus = (status: BookingStatus) => {
        if (filters.statuses.includes(status)) {
            setStatuses(filters.statuses.filter((current) => current !== status));
        } else {
            setStatuses(filters.statuses.concat(status));
        }
    };

    const values = [BookingStatus.upcoming, BookingStatus.past, BookingStatus.cancelled];

    return (
        <Form className="w-100">
            <Form.Group className="mb-0">
                <Title>{t('filterForm:filter_status')}</Title>
                {values.map((status) => (
                    <FormRowCheckbox
                        id={status}
                        key={status}
                        checked={filters.statuses.includes(status)}
                        count={countData.countByStatus[status]}
                        onClick={() => handleChangeStatus(status)}
                    />
                ))}
            </Form.Group>
        </Form>
    );
};
