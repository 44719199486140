import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import { api } from 'medrefer-web-sdk/api';
import { InsuranceStatus, User, UserRole } from 'medrefer-web-sdk/api/models';

import useApiRequest from 'hooks/useApiRequest';
import { errorTimeout } from 'utils/constants';
import { AcceptInvitationFormData } from './AcceptInvitationForm/AcceptInvitationForm.types';
import { acceptInvitation, updateUserData } from 'features/auth/actions';
import { toNullableDate } from '../../../utils/dates';
import { findEnumValueByStringKey } from '../../shared/ServiceSlots/utils';
import { getBrand } from '../../../features/auth/selectors';

export const useAcceptInvitation = () => {
    const dispatch = useDispatch();
    const queryParams = useQueryParams();
    const request = useApiRequest({ errorTimeout });
    const invitationToken = queryParams.get('token') || '';
    const [invitee, setInvitee] = useState<User | null>(null);
    const [isTokenValid, setIsTokenValid] = useState(true);
    const organization = useSelector(getBrand);

    const onSubmit = (data: AcceptInvitationFormData) => {
        request.dispatch(dispatchAcceptInvitation(data));
    };

    const dispatchAcceptInvitation = async (data: AcceptInvitationFormData) => {
        await dispatch(acceptInvitation(data));
        try {
            await dispatch(
                updateUserData({
                    role: data.role,
                    insuranceStatus: findEnumValueByStringKey(InsuranceStatus, data.insuranceStatus),
                    insuranceNumber: data.insuranceNumber,
                    phone: data.phone,
                    smsAgreement: data.smsAgreement,
                    emailAgreement: data.emailAgreement,
                    street: data.street,
                    city: data.city,
                    dateOfBirth: toNullableDate(data.dateOfBirth),
                }),
            );
        } catch (err) {}
    };

    useEffect(() => {
        if (invitationToken) {
            api.validateInvitation(invitationToken)
                .then((res) => {
                    if (organization?.slug == 'agata' && res?.role == UserRole.organizationMember) {
                        window.location.href = `https://dev-doctordashboar.qsystems.pro/invitationRegister?invitationToken=${invitationToken}`; //replace('https://dev-doctordashboar.qsystems.pro/');
                    } else if (
                        organization?.slug == 'sec' &&
                        organization.id == 14 &&
                        res?.role == UserRole.organizationMember
                    ) {
                        window.location.href = `https://staging.dashboard.zweitmeinung-arzt.online/invitationRegister?invitationToken=${invitationToken}`; //replace('https://dev-doctordashboar.qsystems.pro/');
                    } else if (
                        organization?.slug == 'zweitmeinung' &&
                        organization.id == 13 &&
                        res?.role == UserRole.organizationMember
                    ) {
                        window.location.href = `https://dashboard.zweitmeinung-arzt.online/invitationRegister?invitationToken=${invitationToken}`; //replace('https://dev-doctordashboar.qsystems.pro/');
                    } else {
                        setInvitee(res);
                    }
                })
                .catch(() => setIsTokenValid(false));
        } else {
            setIsTokenValid(false);
        }
    }, [invitationToken, setIsTokenValid, setInvitee, organization?.slug, organization?.id]);

    return {
        invitee,
        invitationToken,
        isTokenValid,
        request,
        onSubmit,
    };
};
