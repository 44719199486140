import { InsuranceStatus } from 'medrefer-web-sdk/api/models';
import { mapInsuranceToTranslation } from 'utils/i18n';

export const useInsuranceStatusOptions = () => {
    const insuranceStatuses = Object.values(InsuranceStatus);
    return insuranceStatuses.map((value) => ({
        value: value,
        label: mapInsuranceToTranslation(value),
    }));
};
