import { HealthcareServiceSlots, NoSlotsBehaviour } from 'medrefer-web-sdk/api/models';
import i18n from 'i18n/i18n';

export const getDefaultNoSlotsBehaviour = (): NoSlotsBehaviour => {
    return {
        waiting_room: false,
        display_calendar: true,
        message: i18n.t('publicSearch:no_free_slots_found'),
    };
};

export const getHealthcareServiceNoSlotsBehaviour = (
    service: HealthcareServiceSlots,
    fallbackToDefaultIfHidden = false,
): NoSlotsBehaviour => {
    if (
        service.no_slots_behaviour == null ||
        (fallbackToDefaultIfHidden && !service.no_slots_behaviour.display_calendar)
    ) {
        return getDefaultNoSlotsBehaviour();
    }
    return service.no_slots_behaviour;
};

export function findEnumValueByStringKey<T>(enumObject: T, key: string): T[keyof T] | undefined {
    const enumEntries = Object.entries(enumObject) as [keyof T, string][];

    const foundEntry = enumEntries.find(([, value]) => value === key);

    return foundEntry?.[0] as T[keyof T] | undefined;
}
