import { AppointmentModel, BaseHealthcareService, OrganizationLocation, Slot } from 'medrefer-web-sdk/api/models';
import { ICalendarInfo } from 'tui-calendar';

/**
 * returns variables used in mapping appointments/sclots to schedule objects
 * @param element current element in mapping function
 * @param activeServices
 * @param calendars list of available calendars
 * @param organizationLocations
 * @returns object containing variables for mapping
 */
export const getMappingData = (
    element: AppointmentModel | Slot,
    activeServices: BaseHealthcareService[],
    calendars: ICalendarInfo[],
    organizationLocations: OrganizationLocation[],
) => {
    const serviceSet = organizationLocations.map((location) => {
        return {
            ...location.service_set.map((serviceSet) => {
                return { ...serviceSet, location_id: location.id };
            }),
        };
    });

    const serviceLocationMap = serviceSet.reduce((prev: BaseHealthcareService[], next: BaseHealthcareService[]) => {
        return [...prev, ...Object.values(next)];
    }, []);

    const serviceLocation = serviceLocationMap.find((service) => service.id === element.healthcare_service.id);

    const calendarId = calendars.find(
        (calendar) => serviceLocation && calendar.id === serviceLocation.id.toString(),
    )?.id;

    const scheduleWidth = 100 / activeServices.length;

    const healthCareService = activeServices.find((x) => x.id === element.healthcare_service.id);

    const scheduleLeft = healthCareService ? scheduleWidth * activeServices.indexOf(healthCareService) : 0;

    return {
        serviceSet,
        serviceLocationMap,
        serviceLocation,
        calendarId,
        scheduleWidth,
        healthCareService,
        scheduleLeft,
    };
};
