export type DateRange = {
    dateFrom: Date;
    dateTo: Date;
};

export interface ClickScheduleEvent {
    domRect: DOMRect;
    calendarType: CalendarType;
    dateRange: DateRange;
    data: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export interface CreateScheduleEvent {
    domRect: DOMRect;
    calendarType: CalendarType;
    dateRange: DateRange;
}

export interface UpdateScheduleEvent {
    calendarType: CalendarType;
    dateRange: DateRange;
    data: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

export enum CalendarType {
    day = 'day',
    week = 'week',
    month = 'month',
}

export interface HourRange {
    min: number;
    max: number;
}
