import React from 'react';
import { TabAppointment, TabAppointmentBadge, TabContainer } from './AppointmentTabs.styled';
import { useTranslation } from 'react-i18next';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import useBrand from 'hooks/useBrand';

interface Props {
    activeTab: AppointmentTab;
    setActiveTab: (tab: AppointmentTab) => void;
    activeListItem: AppointmentModel;
}

export enum AppointmentTab {
    Overview = 'OVERVIEW',
    Attachments = 'ATTACHMENTS',
    VideoCall = 'VIDEO_CALL',
    Questionnaires = 'QUESTIONNAIRES',
}

export const AppointmentTabs = ({ activeTab, setActiveTab, activeListItem }: Props) => {
    const { t } = useTranslation();
    const { featureEnabled } = useBrand();

    if (!activeListItem) {
        return null;
    }

    return (
        <TabContainer className="w-100">
            <TabAppointment
                className="mr-3"
                inverseColors={activeTab !== AppointmentTab.Overview}
                onClick={() => setActiveTab(AppointmentTab.Overview)}
            >
                {t('appointmentTabs:tab_btn_1')}
            </TabAppointment>
            {activeListItem.attachments.length > 0 && (
                <TabAppointmentBadge
                    className="mr-3"
                    inverseColors={activeTab !== AppointmentTab.Attachments}
                    onClick={() => setActiveTab(AppointmentTab.Attachments)}
                    data-badge={activeListItem.attachments.length}
                >
                    {t('appointmentTabs:tab_btn_3')}
                </TabAppointmentBadge>
            )}
            {featureEnabled('questionnaires') && activeListItem.questionnaire_responses.length > 0 && (
                <TabAppointmentBadge
                    className="mr-3"
                    inverseColors={activeTab !== AppointmentTab.Questionnaires}
                    onClick={() => setActiveTab(AppointmentTab.Questionnaires)}
                    data-badge={activeListItem.questionnaire_responses.length}
                >
                    {t('appointmentTabs:tab_questionnaires')}
                </TabAppointmentBadge>
            )}
            {featureEnabled('videoCalls') && activeListItem.video_call_available && (
                <TabAppointment
                    className="mr-3"
                    inverseColors={activeTab !== AppointmentTab.VideoCall}
                    onClick={() => setActiveTab(AppointmentTab.VideoCall)}
                >
                    {t('appointmentTabs:tab_video_call')}
                </TabAppointment>
            )}
        </TabContainer>
    );
};
