import { useCallback, useRef, useState } from 'react';
import useApiRequest from 'hooks/useApiRequest';
import {
    MemberInvitationData,
    MembersManagementData,
    OrganizationMember,
    PaginatedDataParams,
} from 'medrefer-web-sdk/api/models';
import { api } from 'medrefer-web-sdk/api';
import { useModalState, useTableOrdering } from 'medrefer-web-sdk/web-kit';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'medrefer-web-sdk/api/utils';
import { errorTimeout } from 'utils/constants';
import { canManageAnyService } from 'features/auth/rules';

export interface ManageMembersTableData extends OrganizationMember {
    nameDisplay: string;
}

export const useManageMembers = () => {
    const { t } = useTranslation();
    const { ordering, sortHandler } = useTableOrdering(['-id']);
    const [isInvitationSent, setIsInvitationSent] = useState(false);
    const [activeItem, setActiveItem] = useState<OrganizationMember | null>(null);
    const [tableParams, setTableParams] = useState<{ pageIndex: number; pageSize: number; searchPhrase: string }>({
        pageIndex: 0,
        pageSize: 0,
        searchPhrase: '',
    });
    const [data, setData] = useState<ManageMembersTableData[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const requestLoadMembers = useApiRequest();
    const request = useApiRequest({ errorTimeout });
    const requestInvitation = useApiRequest();
    const addModalState = useModalState();
    const editModalState = useModalState({
        actionOnClose: () => {
            setActiveItem(null);
            setIsInvitationSent(false);
        },
    });

    const fetchIdRef = useRef(0);

    const fetchData = useCallback(
        (pageIndex, pageSize, searchPhrase) => {
            const fetchId = ++fetchIdRef.current;
            setTableParams({ pageIndex, pageSize, searchPhrase });
            if (fetchId === fetchIdRef.current) {
                const params: PaginatedDataParams = {
                    offset: pageSize * pageIndex,
                    limit: pageSize,
                    search: searchPhrase,
                    ordering: ordering.join(','),
                };

                requestLoadMembers.dispatch(api.getOrganizationMembers(params)).then((res) => {
                    setPageCount(Math.ceil(res.count / pageSize));
                    setData(
                        res.results.map((member) => {
                            const memberData = {
                                ...member,
                                nameDisplay: getFullName(member),
                                date_joined: moment(member.date_joined).format('DD/MM/YYYY'),
                            };

                            if (canManageAnyService(member)) {
                                memberData.assigned_healthcare_services = [
                                    {
                                        id: -1,
                                        name: t('filterForm:all'),
                                        comment: t('filterForm:all'),
                                        is_active: false,
                                        categories: [],
                                        price: null,
                                        is_video_call_restricted: true,
                                        requires_assignment_to_access: true,
                                    },
                                ];
                            }

                            return memberData;
                        }),
                    );
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ordering],
    );

    /**
     * Members actions.
     */

    const reloadData = () => {
        fetchData(tableParams.pageIndex, tableParams.pageSize, tableParams.searchPhrase);
    };

    const fetchActiveItem = (item: OrganizationMember) => {
        if (activeItem && item.id === activeItem.id) {
            return;
        }
        if (activeItem && item.id !== activeItem.id) {
            setActiveItem(null);
        }
        request.dispatch(api.getOrganizationMember(item.id)).then((service) => {
            setActiveItem(service);
        });
    };

    const addMember = (values: MemberInvitationData) => {
        request.dispatch(api.inviteOrganizationMember(values)).then(() => {
            addModalState.toggleModal();
            reloadData();
        });
    };

    const editMember = (formValues: MembersManagementData) => {
        if (!activeItem) return;

        let values = formValues;

        if (!activeItem.is_active) {
            values = {
                email: activeItem.email,
                personal_title: activeItem.personal_title,
                first_name: activeItem.first_name,
                last_name: activeItem.last_name,
                is_active: formValues.is_active,
            };
        }

        request.dispatch(api.editOrganizationMember(activeItem.id, values)).then(() => {
            editModalState.toggleModal();
            reloadData();
        });
    };

    const resendEmailInvitation = () => {
        if (!activeItem) return;
        requestInvitation
            .dispatch(api.resendOrganizationMemberInvitation({ email: activeItem.email, role: activeItem.role }))
            .then(() => {
                setIsInvitationSent(true);
            });
    };

    return {
        addMember,
        editMember,
        isLoading: requestLoadMembers.isLoading,
        request,
        activeItem,
        fetchActiveItem,
        data,
        pageCount,
        fetchData,
        addModalState,
        editModalState,
        isInvitationSent,
        resendEmailInvitation,
        requestInvitation,
        ordering,
        sortHandler,
    };
};
