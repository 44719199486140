import { PrimaryButton } from 'medrefer-web-sdk/web-kit';
import styled from 'styled-components';

export const LinksContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const RegisterButton = styled(PrimaryButton)`
    width: 75px;
    height: 40px;
    line-height: unset;
    margin-right: 10px;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        width: 120px;
        margin-left: 20px;
    }
`;
