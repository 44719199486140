import { api } from 'medrefer-web-sdk/api';
import { Slot } from 'medrefer-web-sdk/api/models';
import { useEffect, useState } from 'react';

const useSlot = (slotKey: string) => {
    const [slot, setSlot] = useState<Slot | null>(null);

    useEffect(() => {
        api.getSlot(slotKey).then(setSlot);
    }, [slotKey]);

    return slot;
};

export default useSlot;
