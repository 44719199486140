import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';
import { mapPropsToValues, validationSchema, handleSubmit } from './OrganizationProfileForm.schema';
import OrganizationProfileData from './OrganizationProfileData';
import { OrganizationProfileFormProps, OrganizationProfileFormValues } from './OrganizationProfileForm.types';

const OrganizationProfileForm = withFormik<OrganizationProfileFormProps, OrganizationProfileFormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(OrganizationProfileData);

export default withTranslation()(OrganizationProfileForm);
