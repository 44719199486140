import React, { ReactElement, useRef, useState } from 'react';
import {
    AddAttachmentButton,
    ClearAttachmentButton,
} from 'components/Dashboard/Appointment/AppointmentHeader/ActionMenu/AttachDocuments/AttachDocuments.styled';
import { api } from 'medrefer-web-sdk/api';
import { useTranslation } from 'react-i18next';

interface Props {
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    value?: number | null;
    name: string;
    acceptFileTypes?: string;
}

export default function AttachmentInput({ setFieldValue, value, name, acceptFileTypes }: Props): ReactElement {
    const uploadInput = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const [image, setImage] = useState<File | null>(null);
    const [initialValue] = useState(value);

    const handleInputChange = () => {
        const uploadImage = uploadInput.current?.files?.item(0);
        setImage(uploadImage ? uploadImage : null);

        if (uploadImage) {
            api.uploadAttachment(uploadImage).then((data) => {
                setFieldValue(name, data.id);
            });
        }
    };

    return (
        <>
            <input
                title="imageUpload"
                ref={uploadInput}
                id={name}
                name={name}
                type="file"
                accept={acceptFileTypes ? acceptFileTypes : 'image/*'}
                onChange={handleInputChange}
                style={{ visibility: 'hidden', zIndex: 90 }}
            />
            <AddAttachmentButton
                onClick={(e) => {
                    e.preventDefault();
                    uploadInput?.current && uploadInput.current.click();
                }}
                style={{ transform: 'translateY(-15px)' }}
            >
                {image ? image.name : t('organizationProfileDisplay:change_image_button')}
            </AddAttachmentButton>
            {image && (
                <ClearAttachmentButton
                    onClick={() => {
                        setImage(null);
                        setFieldValue(name, initialValue);
                    }}
                >
                    X
                </ClearAttachmentButton>
            )}
        </>
    );
}
