import React, { useMemo } from 'react';
import { ApiErrorResponse } from '../../../api/ApiTypes';
import { InformationBubble, InformationBubbleTypes } from '.';
import { useFormikContext } from 'formik';
import { ApiErrorMessage } from './ApiErrorMessage';

type Props = {
    apiErrors?: ApiErrorResponse;
    skipFormikErrors?: boolean;
    onlyTouched?: boolean;
};

export const FormErrorMessage = ({ apiErrors, skipFormikErrors, onlyTouched = true }: Props) => {
    const { touched, errors } = useFormikContext();
    const message = useMemo(() => {
        let retValue: string | null = null;
        const formErrors = Object.keys(errors) as Array<keyof typeof errors>;
        if (!skipFormikErrors) {
            formErrors.forEach((key) => {
                const touchedKey =
                    typeof touched[key] === 'object' ? touched[key][Object.keys(touched[key])[-1]] : touched[key];

                if (!onlyTouched || (touchedKey === true && !retValue)) {
                    retValue =
                        typeof errors[key] === 'object' ? errors[key][Object.keys(errors[key])[-1]] : errors[key];
                }
            });
        }
        return retValue;
    }, [touched, errors, skipFormikErrors, onlyTouched]);

    if (!message) {
        return <ApiErrorMessage apiErrors={apiErrors} />;
    }

    return <InformationBubble type={InformationBubbleTypes.warning}>{message}</InformationBubble>;
};
