import { withFormik, FormikProps } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { FormProps } from './PatientInvitationForm.types';
import { PatientInvitationData } from 'medrefer-web-sdk/api/models';
import {
    Input,
    FormGroup,
    Button,
    FormErrorMessage,
    InformationContainer,
    Label,
    ErrorMessage,
} from 'medrefer-web-sdk/web-kit';
import i18n from 'i18next';
import { DatePickerField } from 'medrefer-web-sdk/web-kit/components/formComponents/DatePicker/DatePicker';
import { mapPropsToValues, validationSchema } from './PatientInvitationForm.scheme';
import { PhoneInput } from 'components/shared/PhoneInput';

const InnerForm = (props: FormProps & FormikProps<PatientInvitationData>) => {
    const { t } = useTranslation();

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.handleSubmit();
            }}
        >
            <FormGroup>
                <Label isRequired>{t('registerForm:emailLabel')}</Label>
                <Input
                    isError={!!(props.touched.email && props.errors.email)}
                    data-testid="email"
                    id="email"
                    name="email"
                    type="email"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.email}
                />
                {props.touched.email && props.errors.email && (
                    <ErrorMessage className="text-left mt-1">{props.errors.email}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Label>{t('registerForm:personalTitleLabel')}</Label>
                <Input
                    data-testid="personalTitle"
                    id="personalTitle"
                    name="personalTitle"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.personalTitle}
                />
                {props.touched.personalTitle && props.errors.personalTitle && (
                    <ErrorMessage className="text-left mt-1">{props.errors.personalTitle}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Label isRequired>{t('registerForm:firstNameLabel')}</Label>
                <Input
                    isError={!!(props.touched.firstName && props.errors.firstName)}
                    data-testid="firstName"
                    id="firstName"
                    name="firstName"
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.firstName}
                />
                {props.touched.firstName && props.errors.firstName && (
                    <ErrorMessage className="text-left mt-1">{props.errors.firstName}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Label isRequired>{t('registerForm:lastNameLabel')}</Label>
                <Input
                    isError={!!(props.touched.lastName && props.errors.lastName)}
                    data-testid="lastName"
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.lastName}
                />
                {props.touched.lastName && props.errors.lastName && (
                    <ErrorMessage className="text-left mt-1">{props.errors.lastName}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Label isRequired>{t('registerForm:titleDateOfBirth')}</Label>
                <DatePickerField
                    isError={props.touched.patient?.date_of_birth && props.errors.patient?.date_of_birth}
                    name="patient.date_of_birth"
                    fullWidth
                    autoComplete="off"
                    showYearDropdown
                    scrollableYearDropdown
                    maxDate={new Date()}
                    dropdownMode="select"
                    i18n={i18n}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                />
                {props.touched.patient?.date_of_birth && props.errors.patient?.date_of_birth && (
                    <ErrorMessage className="text-left mt-1">{props.errors.patient?.date_of_birth}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Label>{t('registerForm:phoneLabel')}</Label>
                <PhoneInput
                    isError={!!(props.touched.phone && props.errors.phone)}
                    id="phone"
                    name="phone"
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.phone}
                />
                {props.touched.phone && props.errors.phone && (
                    <ErrorMessage className="text-left mt-1">{props.errors.phone}</ErrorMessage>
                )}
            </FormGroup>
            <InformationContainer>
                <FormErrorMessage apiErrors={props.request.errors} />
            </InformationContainer>
            <div style={{ maxWidth: 200, margin: 'auto' }}>
                <Button isLoading={props.request.isLoading}>{t('patientInvitation:button_label')}</Button>
            </div>
        </Form>
    );
};

const PatientInvitationForm = withFormik<FormProps, PatientInvitationData>({
    mapPropsToValues,
    validationSchema,
    handleSubmit: (values, { props }) => {
        props.handleSubmit(values);
    },
})(InnerForm);

export default withTranslation()(PatientInvitationForm);
