import { Field, FormikProps, withFormik } from 'formik';
import React, { useRef } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Col, Form, Row } from 'react-bootstrap';
import { FormProps, RegisterFormValues } from './RegisterForm.types';
import { RegistrationStep } from 'medrefer-web-sdk/api/models';
import { EditFieldButton } from 'components/shared/EditFieldButton';
import {
    Button,
    CheckBox,
    CheckBoxErrorIcon,
    CheckBoxLabel,
    ErrorMessage,
    FormAsyncSelect,
    FormErrorMessage,
    FormGroup,
    FormSelect,
    FormSeparator,
    InformationBubble,
    InformationBubbleTypes,
    InformationContainer,
    Input,
    Label,
    Option,
    TextGrey,
} from 'medrefer-web-sdk/web-kit';
import i18n from 'i18next';
import { DatePickerField } from 'medrefer-web-sdk/web-kit/components/formComponents/DatePicker/DatePicker';
import { handleSubmit, mapPropsToValues, validationSchema } from './RegisterForm.schema';
import { PrivacyPolicyNote } from 'components/PrivacyPolicy';
import { useInsuranceStatusOptions } from 'hooks/options/useInsuranceStatusOptions';
// import ReactMarkdown from 'react-markdown';
import { PhoneInput } from 'components/shared/PhoneInput';
import { OptionalField } from '../../../shared/ServiceSlots/RequestWaitingRoomModal/RequestWaitingRoomDataComponents/OptionalField';
import useLocationAutocompleteOptions from '../../../../hooks/options/useLocationAutocompleteOptions';
import {
    EOptionalFields,
    useOptionalFields,
} from '../../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';
import { useSelector } from 'react-redux';
import { getBrand } from '../../../../features/auth/selectors';

const InnerForm = (props: FormProps & FormikProps<RegisterFormValues>) => {
    const { t } = useTranslation();
    const emailInput = useRef<HTMLInputElement>(null);
    const { step } = props.values;
    const insuranceStatusOptions = useInsuranceStatusOptions();
    const { loadLocationAutocompleteOptions, setAddressProps } = useLocationAutocompleteOptions();
    const organization = useSelector(getBrand);
    const { isVisible } = useOptionalFields(organization);
    const setStep = (newStep: RegistrationStep) => {
        props.setFieldValue('step', newStep);
    };

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <FormGroup>
                <Label isRequired>{t('registerForm:emailLabel')}</Label>
                <EditFieldButton
                    onClick={() => {
                        setStep(RegistrationStep.base);
                        setImmediate(() => emailInput.current!.focus());
                    }}
                    isVisible={step > RegistrationStep.base}
                >
                    <Input
                        ref={emailInput}
                        isError={!!(props.touched.email && props.errors.email)}
                        data-testid="email"
                        id="email"
                        name="email"
                        type="email"
                        disabled={[RegistrationStep.otpCode].includes(step)}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                        value={props.values.email}
                    />
                </EditFieldButton>
                {props.touched.email && props.errors.email && (
                    <ErrorMessage className="text-left mt-1">{props.errors.email}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Label isRequired>{t('registerForm:passwordLabel')}</Label>
                <Input
                    isError={!!(props.touched.password && props.errors.password)}
                    data-testid="password"
                    id="password"
                    name="password"
                    type="password"
                    onChange={props.handleChange}
                    disabled={[RegistrationStep.otpCode].includes(step)}
                    onBlur={props.handleBlur}
                    value={props.values.password}
                />
                {props.touched.password && props.errors.password && (
                    <ErrorMessage className="text-left mt-1">{props.errors.password}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Label isRequired>{t('registerForm:confirmPasswordLabel')}</Label>
                <Input
                    isError={!!(props.touched.confirm_password && props.errors.confirm_password)}
                    id="confirm_password"
                    type="password"
                    onChange={props.handleChange}
                    disabled={[RegistrationStep.otpCode].includes(step)}
                    value={props.values.confirm_password}
                    onBlur={props.handleBlur}
                />
                {props.touched.confirm_password && props.errors.confirm_password && (
                    <ErrorMessage className="text-left mt-1">{props.errors.confirm_password}</ErrorMessage>
                )}
            </FormGroup>
            <FormSeparator />
            <FormGroup>
                <Label>{t('registerForm:personalTitleLabel')}</Label>
                <Input
                    data-testid="personalTitle"
                    id="personalTitle"
                    name="personalTitle"
                    type="text"
                    disabled={[RegistrationStep.otpCode].includes(step)}
                    onChange={props.handleChange}
                    value={props.values.personalTitle}
                />
                {props.touched.personalTitle && props.errors.personalTitle && (
                    <ErrorMessage className="text-left mt-1">{props.errors.personalTitle}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Label isRequired>{t('registerForm:firstNameLabel')}</Label>
                <Input
                    isError={!!(props.touched.firstName && props.errors.firstName)}
                    data-testid="firstName"
                    id="firstName"
                    disabled={[RegistrationStep.otpCode].includes(step)}
                    name="firstName"
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.firstName}
                />
                {props.touched.firstName && props.errors.firstName && (
                    <ErrorMessage className="text-left mt-1">{props.errors.firstName}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Label isRequired>{t('registerForm:lastNameLabel')}</Label>
                <Input
                    isError={!!(props.touched.lastName && props.errors.lastName)}
                    data-testid="lastName"
                    disabled={[RegistrationStep.otpCode].includes(step)}
                    id="lastName"
                    name="lastName"
                    type="text"
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    value={props.values.lastName}
                />
                {props.touched.lastName && props.errors.lastName && (
                    <ErrorMessage className="text-left mt-1">{props.errors.lastName}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Label isRequired>{t('registerForm:titleDateOfBirth')}</Label>
                <DatePickerField
                    isError={props.touched.date_of_birth && props.errors.date_of_birth}
                    name="date_of_birth"
                    disabled={[RegistrationStep.otpCode].includes(step)}
                    fullWidth
                    autoComplete="off"
                    showYearDropdown
                    scrollableYearDropdown
                    maxDate={new Date()}
                    dropdownMode="select"
                    i18n={i18n}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                />
                {props.touched.date_of_birth && props.errors.date_of_birth && (
                    <ErrorMessage className="text-left mt-1">{props.errors.date_of_birth}</ErrorMessage>
                )}
            </FormGroup>
            <OptionalField
                isError={!!(props.touched.phone && props.errors.phone)}
                errorMessage={props.errors.phone}
                fieldName={'phone'}
                fieldLabel={t('newAppointmentForm:title_phone_number')}
                fields={props.values.fields}
                mandatory_fields={props.values.mandatory_fields}
            >
                <PhoneInput
                    isError={!!(props.touched.phone && props.errors.phone)}
                    id="phone"
                    type="text"
                    disabled={[RegistrationStep.otpCode].includes(step)}
                    value={props.values.phone}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                />
            </OptionalField>
            <OptionalField
                isError={!!(props.touched.insurance_number && props.errors.insurance_number)}
                errorMessage={props.errors.insurance_number}
                fieldName={'insurance_number'}
                fieldLabel={t('newAppointmentForm:title_insurance_number')}
                fields={props.fields}
                mandatory_fields={props.mandatory_fields}
            >
                <Input
                    isError={!!(props.touched.insurance_number && props.errors.insurance_number)}
                    id="insurance_number"
                    name="insurance_number"
                    disabled={[RegistrationStep.otpCode].includes(step)}
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.insurance_number}
                    onBlur={() => props.setFieldTouched('insurance_number', true)}
                />
            </OptionalField>
            <OptionalField
                isError={!!(props.touched.insurance_status && props.errors.insurance_status)}
                errorMessage={props.errors.insurance_status}
                fieldName={'insurance_status'}
                fieldLabel={t('newAppointmentForm:title_insurance_status')}
                fields={props.fields}
                mandatory_fields={props.mandatory_fields}
            >
                <Field
                    name="insurance_status"
                    options={insuranceStatusOptions}
                    isDisabled={[RegistrationStep.otpCode].includes(step)}
                    id="insurance_status"
                    component={FormSelect}
                    placeholder={t('newAppointmentForm:placeholder_insurance_status')}
                    isMulti={false}
                    type={'text'}
                    isError={!!(props.touched.insurance_status && props.errors.insurance_status)}
                    onBlur={() => props.setFieldTouched('insurance_status', true)}
                />
            </OptionalField>
            <OptionalField
                isError={!!(props.errors.street && props.touched.street)}
                errorMessage={props.errors.street}
                fieldName={'address'}
                fieldLabel={t('newAppointmentForm:title_address')}
                fields={props.fields}
                mandatory_fields={props.mandatory_fields}
            >
                <Field
                    name="street"
                    id="street"
                    isDisabled={[RegistrationStep.otpCode].includes(step)}
                    isError={!!(props.errors.street && props.touched.street)}
                    loadOptions={loadLocationAutocompleteOptions}
                    component={FormAsyncSelect}
                    placeholder={t('adminPanel:manageLocations.locationsForm.address_placeholder')}
                    debounceTimeout={400}
                    minSearchLength={3}
                    onBlur={() => props.setFieldTouched('street', true)}
                    afterChange={(option: Option) => setAddressProps(props.setFieldValue, option)}
                />
            </OptionalField>
            {step >= RegistrationStep.otpCode && (
                <>
                    <FormSeparator className="mb-4 mt-0" />
                    <FormGroup>
                        <Row className="mb-4">
                            <Col>
                                <TextGrey>
                                    {/*<ReactMarkdown*/}
                                    {/*    renderers={{*/}
                                    {/*        link: (props) => (*/}
                                    {/*            <a href={props.href} rel="nofollow noreferrer noopener" target="_blank">*/}
                                    {/*                {props.children}*/}
                                    {/*            </a>*/}
                                    {/*        ),*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {t('otp:code_sent') as string}
                                    {/*</ReactMarkdown>*/}
                                </TextGrey>
                            </Col>
                        </Row>
                        <Label>{t('loginForm:otp_code')}</Label>
                        <Input
                            data-testid="token"
                            id="token"
                            name="token"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.token}
                            autoFocus
                        />
                    </FormGroup>
                </>
            )}
            <InformationContainer>
                {props.otpCodeSent ? (
                    <InformationBubble type={InformationBubbleTypes.success}>{t('otp:code_sent')}</InformationBubble>
                ) : props.emailTaken ? (
                    <InformationBubble type={InformationBubbleTypes.warning}>
                        {t('registerForm:warning_email_taken')}
                    </InformationBubble>
                ) : (
                    <FormErrorMessage apiErrors={props.request.errors} />
                )}
            </InformationContainer>
            <FormGroup>
                <FormSeparator className="mb-4 mt-0" />
                {/*<OptionalField
                    isError={!!(props.touched.sms_agreement && props.errors.sms_agreement)}
                    errorMessage={props.errors.sms_agreement}
                    fieldName={'phone'}
                    fieldLabel={t('newAppointmentForm:checkbox_text1')}
                    fields={props.fields}
                    mandatory_fields={props.mandatory_fields}
                    isCheckMark={true}
                    colClassName={'d-flex align-items-start'}
                    rowClassName={''}
                >
                    <CheckBox
                        id="sms_agreement"
                        checked={props.values.sms_agreement}
                        onChange={props.handleChange}
                        className="mr-4"
                        isError={props.touched.sms_agreement && props.errors.sms_agreement}
                    />
                </OptionalField>*/}
                {isVisible(EOptionalFields.phone) && (
                    <Row>
                        <Col className="d-flex align-items-start" xs={12}>
                            <CheckBox
                                id="sms_agreement"
                                checked={props.values.sms_agreement}
                                onChange={props.handleChange}
                                className="mr-4"
                                isError={props.touched.sms_agreement && props.errors.sms_agreement}
                            />
                            <CheckBoxLabel>{t('newAppointmentForm:checkbox_text1')}</CheckBoxLabel>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className="d-flex align-items-start" xs={12}>
                        <CheckBox
                            id="email_agreement"
                            checked={props.values.email_agreement}
                            onChange={props.handleChange}
                            className="mr-4"
                        />
                        <CheckBoxLabel>{t('newAppointmentForm:checkbox_text2')}</CheckBoxLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex align-items-start" xs={12}>
                        <CheckBox
                            isError={props.touched.terms_agreement && props.errors.terms_agreement}
                            id="terms_agreement"
                            disable
                            checked={props.values.terms_agreement}
                            onChange={props.handleChange}
                            className="mr-4"
                        />
                        <CheckBoxLabel isRequired>
                            <PrivacyPolicyNote />
                        </CheckBoxLabel>
                        {props.touched.terms_agreement && props.errors.terms_agreement && <CheckBoxErrorIcon />}
                    </Col>
                </Row>
                {props.touched.terms_agreement && props.errors.terms_agreement && (
                    <ErrorMessage className="text-left mr-5 pr-5 mt-1">{props.errors.terms_agreement}</ErrorMessage>
                )}
                <FormSeparator className="mb-4 mt-4" />
            </FormGroup>
            <div style={{ maxWidth: 200, margin: 'auto' }}>
                <Button isLoading={props.request.isLoading}>
                    {step < RegistrationStep.otpCode ? t('registerForm:button_next') : t('registerForm:button')}
                </Button>
            </div>
        </Form>
    );
};

const RegisterForm = withFormik<FormProps, RegisterFormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(InnerForm);

export default withTranslation()(RegisterForm);
