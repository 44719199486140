import React from 'react';
import { ButtonLabel, ErrorMessage, Label, Thumb } from '../../../../medrefer-web-sdk/web-kit';
import { Dashboard } from '@uppy/react';
import Uppy from '@uppy/core';
import German from '@uppy/locales/lib/de_DE';
import Polish from '@uppy/locales/lib/pl_PL';
import English from '@uppy/locales/lib/en_US';
import { getActiveLanguage } from '../../../../utils/i18n';
import useSlotBooking from '../../../PublicSearch/SlotBooking/useSlotBooking';
import { useTranslation } from 'react-i18next';
import Webcam from '@uppy/webcam';
import Compressor from '@uppy/compressor';
import ScreenCapture from '@uppy/screen-capture';
import { AttachDocumentsHanlerType } from '../../../../hooks/useAttachDocuments';

interface Props {
    title?: string;
    subtitle?: string;
    maxFiles?: number;
    minFiles?: number;
    height?: number;
    note?: string;
    disable?: boolean;
    allowedFileTypes?: string[];
    onFilePicked: (documentHandler: AttachDocumentsHanlerType) => void;
}
export default function FileAttachment({
    title,
    subtitle,
    onFilePicked,
    maxFiles = 5,
    minFiles = 0,
    height = 300,
    disable = false,
    note = undefined,
    allowedFileTypes = ['.txt', '.docx', '.doc', '.pdf', '.odt', '.jpg', '.png', '.jpeg'],
}: Props) {
    const { attachDocumentsHandler } = useSlotBooking();
    const activeLocaleString = getActiveLanguage();
    const { t } = useTranslation();
    const locale = activeLocaleString == 'de' ? German : activeLocaleString == 'pl' ? Polish : English;
    const uppy = React.useMemo(() => {
        return new Uppy({
            locale: locale,
            restrictions: {
                allowedFileTypes: allowedFileTypes,
                maxNumberOfFiles: maxFiles,
                minNumberOfFiles: minFiles,
                maxFileSize: 5 * 1024 * 1024, //5mb
            },
        })
            .use(Webcam, {})
            .use(Compressor, {})
            .use(ScreenCapture, {});
    }, [allowedFileTypes, locale, maxFiles, minFiles]);

    React.useEffect(() => {
        return () => uppy.close({ reason: 'unmount' });
    }, [uppy]);

    React.useEffect(() => {
        onFilePicked(attachDocumentsHandler);
    }, [attachDocumentsHandler, onFilePicked]);

    uppy.on('complete', (result) => {
        const fileList = result.successful.map((file) => {
            return new File([file.data], file.name, { type: file.type });
        });
        attachDocumentsHandler.setAttachments(fileList);
    });

    return (
        <>
            <Label labelTop> {title ?? t('fileAttachment:attachment_title')} </Label>
            {!disable && <ButtonLabel> {subtitle ?? t('fileAttachment:attachment_subtitle')} </ButtonLabel>}
            <input
                title="documentUpload"
                ref={attachDocumentsHandler.uploadInput}
                id="attachments"
                name="attachments"
                type="file"
                onChange={(e) => attachDocumentsHandler.handleFileChange(e, 5000, 5)}
                style={{ display: 'none' }}
            />
            {!disable && (
                <Dashboard
                    style={{ zIndex: 1 }}
                    uppy={uppy}
                    plugins={['Webcam', 'ScreenCast', 'Unsplash', 'ScreenCapture']}
                    height={height}
                    metaFields={[
                        { id: 'name', name: 'Name', placeholder: 'file name' },
                        { id: 'caption', name: 'Caption', placeholder: 'describe what the file is for' },
                    ]}
                />
            )}
            {!disable && (
                <ButtonLabel style={{ paddingTop: 10 }}>
                    {note !== undefined ? note : t('attachDocumentsModalBody:max_files_title')}
                </ButtonLabel>
            )}
            {attachDocumentsHandler.attachments && (
                <div>
                    {attachDocumentsHandler.attachments.map(({ name }, index) => {
                        return (
                            <Thumb
                                key={name}
                                name={name}
                                click={() => {
                                    if (disable) return;

                                    attachDocumentsHandler.handleFileDelete(index);
                                }}
                                listIndex={index + 1}
                            />
                        );
                    })}
                </div>
            )}
            {attachDocumentsHandler.attachments.length == 0 && (
                <div>{t('fileAttachment:attachment_no_files_attached')}</div>
            )}
            {attachDocumentsHandler.attachmentError && (
                <ErrorMessage className="mt-1">{attachDocumentsHandler.attachmentError}</ErrorMessage>
            )}
        </>
    );
}
