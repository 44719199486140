import { useState } from 'react';
import { PaginatedData } from 'medrefer-web-sdk/api/ApiTypes';

const usePaginatedData = <Item = unknown>() => {
    const [items, setItems] = useState<Item[]>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [version, setVersion] = useState(1);

    const append = (data: PaginatedData<Item[]>): void => {
        setItems((prevItems) => {
            prevItems = data.previous != null ? prevItems : [];
            return prevItems.concat(data.results);
        });
        setHasMore(data.next != null);
        setVersion((prevVersion) => prevVersion + 1);
        setTotalCount(data.count);
    };

    const appendFromRequest = (promise: Promise<PaginatedData<Item[]>>): void => {
        setIsLoading(true);
        promise
            .then((data) => {
                append(data);
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const clear = () => {
        setItems([]);
    };

    return {
        items,
        setItems,
        isLoading,
        hasMore,
        version,
        totalCount,
        append,
        appendFromRequest,
        clear,
    };
};

export default usePaginatedData;
