import React, { FC } from 'react';
import TimePicker from 'rc-time-picker';
import './rc-time-picker.css';
import { Moment } from 'moment';

export interface Props {
    className?: string;
    onChange: (val: Moment, name: string) => void;
    value: Moment;
    open: boolean;
    onOpen: () => void;
    onClose: () => void;
    name: string;
    defaultValue: Moment;
}

function disabledHours() {
    return [0, 1, 2, 3, 4, 5, 6, 20, 21, 22, 23];
}

export const CustomTimePicker: FC<Props> = ({
    className,
    onChange,
    value,
    open,
    onOpen,
    onClose,
    name,
    defaultValue,
    ...rest
}: Props) => {
    return (
        <TimePicker
            {...rest}
            className={className}
            popupClassName={className}
            showSecond={false}
            onChange={(val) => onChange(val, name)}
            hideDisabledOptions
            minuteStep={5}
            value={value}
            onOpen={onOpen}
            onClose={onClose}
            open={open}
            disabledHours={disabledHours}
            defaultValue={defaultValue}
            allowEmpty={false}
        />
    );
};
