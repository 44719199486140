import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';
import { RequestWaitingRoomProps, RequestWaitingRoomValues } from './RequestWaitingRoomForm.types';
import { handleSubmit, mapPropsToValues, validationSchema } from './RequestWaitingRoomForm.schema';
import { RequestWaitingRoomData } from './RequestWaitingRoomData';

const RequestWaitingRoomForm = withFormik<RequestWaitingRoomProps, RequestWaitingRoomValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(RequestWaitingRoomData);
export default withTranslation()(RequestWaitingRoomForm);
