import { removePrefix } from 'medrefer-web-sdk/web-kit/utils/stringHelpers';

const sortNameEqual = (param: string, paramName: string) => {
    return removePrefix(param, '-') === paramName;
};

export const sortHandlerToggle = (stateVariable: string[], sortParam: string, allowMultiple: boolean): string[] => {
    if (stateVariable.length === 0) {
        return [sortParam];
    }

    const currentParam = stateVariable.find((param) => sortNameEqual(param, sortParam));
    let stateWithoutCurrent = [...stateVariable.filter((param) => !sortNameEqual(param, sortParam))];

    if (!allowMultiple) {
        stateWithoutCurrent = [];
    }

    if (!currentParam) {
        return [sortParam, ...stateWithoutCurrent];
    }

    if (currentParam.charAt(0) === '-') {
        return stateWithoutCurrent;
    }

    return ['-' + sortParam, ...stateWithoutCurrent];
};

export const getSortOrder = (ordering: string[], sortParam: string): string | null => {
    const currentParam = ordering.find((param) => sortNameEqual(param, sortParam));
    if (!currentParam) {
        return null;
    }
    return currentParam.charAt(0) === '-' ? 'desc' : 'asc';
};
