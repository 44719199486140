import styled from 'styled-components';

export const Title = styled.div`
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    color: ${({ theme }) => theme.black};
    border-bottom: #eaeaf4 1px solid;
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 14px;
`;

interface RadioProps {
    isActive?: boolean;
}

export const RadioSort = styled.label<RadioProps>`
    margin-top: -5px;
    margin-bottom: -5px;
    margin-left: -10px;
    border-radius: 8px;
    width: 215px;
    min-width: 150px;
    height: 42px;
    display: flex;
    align-items: center;
    padding-left: 24px;
    position: relative;
    font-size: 14px !important;
    line-height: 19px !important;
    box-sizing: border-box;
    &:after {
        transition: 0.1s cubic-bezier(0.01, 1.06, 0.85, 0.51);
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 12px;
        left: 10px;
        width: ${(props) => (props.isActive ? '18px !important' : '18px')};
        height: ${(props) => (props.isActive ? '18px !important' : '18px')};
        border: ${(props) => (props.isActive ? `1px solid ${props.theme.accent} !important` : '1px solid #DBDCEE')};
        border-radius: 50%;
    }
    &:before {
        transition: 0.1s cubic-bezier(0.01, 1.06, 0.85, 0.51);
        box-sizing: border-box;
        transform: ${(props) => (props.isActive ? 'scale(1)' : 'scale(0)')};
        content: '';
        position: absolute;
        left: 15.5px;
        top: 17.5px;
        width: 7px;
        height: 7px;
        background: ${({ theme }) => theme.accent};
        border-radius: 50%;
    }
    input {
        opacity: 0;
        display: hidden;
        border: 1px solid red;
    }
    &:hover {
        &:after {
            padding: 2px;
            border: 1px solid ${({ theme }) => theme.accent};
        }
        &:before {
            box-sizing: border-box;
            display: block;
            content: '';
            position: absolute;
            left: 15.5px;
            top: 17.5px;
            width: 7px;
            height: 7px;
            background: ${({ theme }) => theme.accent};
            border-radius: 50%;
        }
    }
    div {
        font-size: 13px;
        line-height: 25px;
        color: #000000;
        font-weight: normal;
        span {
            cursor: default;
            font-size: 13px;
            line-height: 25px;
            color: #9d9eb2 !important;
        }
    }
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        width: auto;
        min-width: 0px;
    }
`;
