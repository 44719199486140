import useBrand from 'hooks/useBrand';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import { useDispatch } from 'react-redux';
import { routerActions } from 'connected-react-router';

const useSlotCriteriaForm = () => {
    const { getPathBranded } = useBrand();
    const queryParams = useQueryParams();
    const dispatch = useDispatch();

    const onValuesSubmit = (valueIds: number[]) => {
        const params = queryParams.copy();
        params.set('criteria', valueIds.join(','));
        const path = getPathBranded('searchServiceSlots');
        dispatch(routerActions.push(path + params.toString()));
    };

    return {
        onValuesSubmit,
    };
};

export default useSlotCriteriaForm;
