import styled from 'styled-components';

export const QuestionnaireResponseContainer = styled.div`
    padding: 0;
`;

export const QuestionnaireResponse = styled.div`
    padding: 20px 10px;
    cursor: pointer;
    color: black !important;
    text-decoration: none !important;

    &:hover {
        text-decoration: none !important;
    }

    &:nth-child(2n) {
        background: ${({ theme }) => theme.grey50};
    }
`;
