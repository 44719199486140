import React from 'react';
import { Field, FormikProps } from 'formik';
import { MembersManagementData, UserRole } from 'medrefer-web-sdk/api/models';
import {
    Button,
    ErrorMessage,
    FormAsyncSelect,
    FormGroup,
    InformationBubble,
    InformationBubbleTypes,
    Input,
    Label,
    SwitchInput,
} from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import { MembersFormProps } from 'components/AdminPanel/ManageMembers/MembersForm/MembersForm.types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'features/auth/selectors';
import useOrganizationServiceOptions from 'hooks/options/useOrganizationServiceOptions';

interface MembersDataProps {
    baseProps: MembersFormProps & FormikProps<MembersManagementData>;
}

export const MembersData = ({ baseProps }: MembersDataProps) => {
    const { t } = useTranslation();
    const authUser = useSelector(getAuthUser);
    const { activeItem } = baseProps;
    const { loadOrganizationServiceOptions, toOrganizationServiceOption } = useOrganizationServiceOptions();

    return (
        <>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label>{t('adminPanel:manageMembers.editForm.email_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <Input
                            id="email"
                            type="email"
                            onChange={baseProps.handleChange}
                            value={baseProps.values.email}
                            onBlur={baseProps.handleBlur}
                            disabled
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label>{t('adminPanel:manageMembers.editForm.personal_title_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <Input
                            isError={!!(baseProps.touched.personal_title && baseProps.errors.personal_title)}
                            id="personal_title"
                            type="text"
                            onChange={baseProps.handleChange}
                            value={baseProps.values.personal_title}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
                {baseProps.touched.personal_title && baseProps.errors.personal_title && (
                    <ErrorMessage className="text-right mt-1">{baseProps.errors.personal_title}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label>{t('adminPanel:manageMembers.editForm.first_name_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <Input
                            isError={!!(baseProps.touched.first_name && baseProps.errors.first_name)}
                            id="first_name"
                            type="text"
                            onChange={baseProps.handleChange}
                            value={baseProps.values.first_name}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
                {baseProps.touched.first_name && baseProps.errors.first_name && (
                    <ErrorMessage className="text-right mt-1">{baseProps.errors.first_name}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label>{t('adminPanel:manageMembers.editForm.last_name_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <Input
                            isError={!!(baseProps.touched.last_name && baseProps.errors.last_name)}
                            id="last_name"
                            type="text"
                            onChange={baseProps.handleChange}
                            value={baseProps.values.last_name}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
                {baseProps.touched.last_name && baseProps.errors.last_name && (
                    <ErrorMessage className="text-right mt-1">{baseProps.errors.last_name}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={6} md={4}>
                        <Label style={{ marginTop: 0 }}>{t('adminPanel:manageMembers.editForm.is_active_label')}</Label>
                    </Col>
                    {baseProps.activeItem?.id !== authUser?.id ? (
                        <Col xs={6} md={8}>
                            <SwitchInput
                                id="is_active"
                                isChecked={baseProps.values.is_active}
                                handleChange={baseProps.handleChange}
                            />
                        </Col>
                    ) : (
                        <Col xs={6} md={8}>
                            <p style={{ margin: '0' }}>{t('adminPanel:manageMembers.editForm.cant_edit_yourself')}</p>
                        </Col>
                    )}
                </Row>
            </FormGroup>
            {baseProps.activeItem?.is_invitation_pending && baseProps.values.is_active && (
                <FormGroup>
                    <Row>
                        <Col xs={6} md={4}>
                            <Label style={{ marginTop: 0 }}>
                                {t('adminPanel:manageMembers.editForm.verification_email')}
                            </Label>
                        </Col>
                        <Col xs={6} md={8}>
                            {!baseProps.isInvitationSent ? (
                                <Button
                                    onClick={baseProps.resendEmailInvitation}
                                    isLoading={baseProps.requestInvitation.isLoading}
                                >
                                    {t('adminPanel:manageMembers.editForm.resend_verification_email')}
                                </Button>
                            ) : (
                                <InformationBubble type={InformationBubbleTypes.success}>
                                    {t('adminPanel:manageMembers.editForm.resend_success')}
                                </InformationBubble>
                            )}
                        </Col>
                    </Row>
                </FormGroup>
            )}
            {baseProps.activeItem?.role != UserRole.organization && baseProps.values.is_active && (
                <FormGroup>
                    <Row>
                        <Col xs={6} md={4}>
                            <Label>{t('adminPanel:manageMembers.editForm.services_label')}</Label>
                        </Col>
                        <Col xs={6} md={8}>
                            <Field
                                name="assigned_healthcare_services"
                                loadOptions={loadOrganizationServiceOptions}
                                initialOptions={
                                    activeItem &&
                                    activeItem.assigned_healthcare_services.map(toOrganizationServiceOption)
                                }
                                component={FormAsyncSelect}
                                placeholder="Select services"
                                isMulti={true}
                                isError={
                                    baseProps.touched.assigned_healthcare_services &&
                                    !!baseProps.errors.assigned_healthcare_services
                                }
                                onBlur={baseProps.handleBlur}
                            />
                        </Col>
                    </Row>
                </FormGroup>
            )}
        </>
    );
};
