import {
    ActivityIndicator,
    Button,
    FormSeparator,
    GreyTitle,
    Modal,
    ModalState,
    UnderlineH2,
    UnderlineH2 as Title,
} from '../../../medrefer-web-sdk/web-kit';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    AppointmentRequestsModel,
    AppointmentRequestStatus,
    AppointmentRequestTime,
} from '../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';
import { Col, Container, Row } from 'react-bootstrap';
import { useCalendarSize } from '../../shared/ServiceSlots/SlotsCalendar/SlotsCalendar.hooks';
import { useSlotsFinderCalendar } from './SlotFinderHook';
import { CalendarTimelineRequest } from './CalendarTimelineRequest';
import { DayCircle, DaySelectorContainer } from '../DayCircles/DayCircles.styled';
import { HealthcareService, Weekday } from '../../../medrefer-web-sdk/api/models';
import { FormValue } from '../../shared/FormValue';
import { getFullName } from '../../../medrefer-web-sdk/api/utils';
import { StatusBadge } from '../StatusBadge/StatusBadge';
import { Arrow, ArrowControl } from '../../shared/ServiceSlots/SlotsCalendar/SlotsCalendar.styled';
import BackArrow from 'medrefer-web-sdk/web-kit/assets/icons/svg/BackArrow.svg';
import { ConfirmBookingModal } from '../../shared/ConfirmBookingModal';
import AppointmentOverviewSimplified from './AppointmentOverviewSimplified';
import AppointmentButtons from './AppointmentButtons';
import { NavLink } from '../../shared/NavLink';
import useBrand from '../../../hooks/useBrand';
import EditMenuRequest from './EditMenuRequest';
import AppointmentFiles from '../../Dashboard/Appointment/AppointmentDetails/AppointmentFiles';
import { useSelector } from 'react-redux';
import { getBrand } from '../../../features/auth/selectors';
import {
    EOptionalFields,
    useOptionalFields,
} from '../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';

type Props = {
    state: ModalState;
    activeItem: AppointmentRequestsModel;
    reloadAppointmentsRequests: () => void;
};

type PropsForList = {
    state: ModalState;
    activeItem: AppointmentRequestsModel;
    reloadAppointmentsRequests: () => void;
    healthcareService: HealthcareService;
    t: (key: string) => string;
};
export const SlotsFinderModal = ({ state, activeItem, reloadAppointmentsRequests }: Props) => {
    return (
        <Modal
            state={state}
            isCentered={false}
            body={
                <SlotsFinderModalBody
                    state={state}
                    activeItem={activeItem}
                    reloadAppointmentsRequests={reloadAppointmentsRequests}
                />
            }
        />
    );
};

const SlotList = ({ state, activeItem, reloadAppointmentsRequests, healthcareService, t }: PropsForList) => {
    const [calendarRef, daysRangeSize] = useCalendarSize(5);
    const date = new Date();
    const calendar = useSlotsFinderCalendar(
        [],
        date,
        null,
        daysRangeSize,
        date,
        new Date(date.getFullYear() + 1, date.getMonth(), date.getDate()),
        healthcareService.id,
        activeItem,
        state,
        reloadAppointmentsRequests,
    );
    const { getPathBranded } = useBrand();
    return (
        <>
            {calendar.isLoading && (
                <Container style={{ paddingLeft: '45%' }}>
                    <ActivityIndicator background={'#000'} />
                </Container>
            )}
            {!calendar.isLoading && calendar.hasSlots && (
                <>
                    <Title>{healthcareService.name}</Title>
                    <Container style={{ maxWidth: '100vw', display: 'flex' }}>
                        <ArrowControl>
                            <Arrow
                                src={BackArrow}
                                alt={t('publicSearch:calendar_previous_dates')}
                                title={t('publicSearch:calendar_previous_dates')}
                                isActive={calendar.hasPrev}
                                onClick={calendar.goPrev}
                            />
                        </ArrowControl>
                        <CalendarTimelineRequest
                            calendar={calendar}
                            calendarRef={calendarRef}
                            handleSlotClick={calendar.slotClickHandle}
                            daysRangeSize={daysRangeSize}
                        />
                        <ArrowControl>
                            <Arrow
                                src={BackArrow}
                                alt={t('publicSearch:calendar_next_dates')}
                                title={t('publicSearch:calendar_next_dates')}
                                isActive={calendar.hasNext}
                                onClick={() => calendar.goNext()}
                                isReversed
                            />
                        </ArrowControl>
                    </Container>
                </>
            )}
            {!calendar.isLoading && !calendar.hasSlots && (
                <>
                    <Title>{healthcareService.name}</Title>
                    <NavLink to={getPathBranded('calendar')} activeClassName="active">
                        <Container>
                            <Button>{t('waitingRoom:got_to_calendar')}</Button>
                        </Container>
                    </NavLink>
                </>
            )}
            {calendar.bookingData && (
                <ConfirmBookingModal
                    values={calendar.bookingData}
                    isLoading={calendar.isLoading}
                    onDiscard={calendar.onDiscard}
                    onConfirm={calendar.onConfirmBooking}
                />
            )}
        </>
    );
};

export const SlotsFinderModalBody = ({ state, activeItem, reloadAppointmentsRequests }: Props) => {
    const { t } = useTranslation();
    const organization = useSelector(getBrand);
    const { isVisible } = useOptionalFields(organization);
    const dayOptions = [
        { label: t('global:daynamesOne.monday'), value: Weekday.MO },
        { label: t('global:daynamesOne.tuesday'), value: Weekday.TU },
        { label: t('global:daynamesOne.wednesday'), value: Weekday.WE },
        { label: t('global:daynamesOne.thursday'), value: Weekday.TH },
        { label: t('global:daynamesOne.friday'), value: Weekday.FR },
    ];

    const getCriteria = () => {
        if (activeItem.criteria && activeItem.criteria.length > 0) {
            let to_show = '';
            activeItem.criteria.map((appointment) => {
                appointment.values.map((spec) => {
                    to_show += spec.name + ' ';
                });
            });
            return to_show;
        }
        return '';
    };
    return (
        <>
            <h2 style={{ padding: '12px 0', marginBottom: '20px' }}>{t('waitingRoom:request_modal_info_title')}</h2>
            <Row>
                <StatusBadge appointment={activeItem} />
                <div className="d-flex justify-content-right" style={{ marginLeft: '80%' }}>
                    <EditMenuRequest
                        activeListItem={activeItem}
                        state={state}
                        reloadRequests={reloadAppointmentsRequests}
                    />
                </div>
            </Row>
            <UnderlineH2></UnderlineH2>
            <Row>
                <Col>
                    <FormValue
                        title={t('slotOverview:healthcare_service')}
                        value={activeItem.healthcare_service?.name || ''}
                    />
                </Col>
                <Col>
                    <FormValue title={t('calendar:calendarTable:criteria')} value={getCriteria()} />
                </Col>
                <Col>
                    <Row>
                        <GreyTitle> {t('requestWaitingRoom:picked_days_confirmation_label')}</GreyTitle>
                    </Row>
                    <Row>
                        <DaySelectorContainer>
                            {dayOptions.map((option, key) => (
                                <DayCircle
                                    key={key}
                                    isAllDay={activeItem?.available_time[key] == AppointmentRequestTime.ALL_DAY}
                                    isMorning={activeItem?.available_time[key] == AppointmentRequestTime.MORNING}
                                    isAfternoon={activeItem?.available_time[key] == AppointmentRequestTime.AFTERNOON}
                                    small={true}
                                >
                                    {option.label}
                                </DayCircle>
                            ))}
                        </DaySelectorContainer>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col>
                    <FormValue
                        title={t('adminPanel:table.columns.full_name')}
                        value={getFullName({
                            personal_title: activeItem.patient?.personal_title || '',
                            first_name: activeItem.patient?.first_name || '',
                            last_name: activeItem.patient?.last_name || '',
                        })}
                    ></FormValue>
                </Col>
                <Col>
                    {isVisible(EOptionalFields.phone) && (
                        <FormValue
                            title={t('newAppointmentForm:title_phone_number')}
                            value={activeItem.patient?.phone}
                        ></FormValue>
                    )}
                </Col>
                <Col>
                    <FormValue title={t('newAppointmentForm:title_email')} value={activeItem.patient?.email || ''} />
                </Col>
            </Row>
            <FormSeparator />
            <Row>
                <Col>
                    <FormValue title={t('newAppointmentForm:title_confirmation_message')} value={activeItem.comment} />
                </Col>
                <Col>
                    {activeItem.attachments.length > 0 && (
                        <AppointmentFiles attachments={activeItem.attachments} useRequestTypeFile />
                    )}
                </Col>
            </Row>
            {activeItem.status == AppointmentRequestStatus.PENDING && (
                <>
                    <Title>{t('waitingRoom:slot_finder_modal_title')}</Title>
                    {activeItem.healthcare_service && (
                        <SlotList
                            state={state}
                            activeItem={activeItem}
                            reloadAppointmentsRequests={reloadAppointmentsRequests}
                            healthcareService={activeItem.healthcare_service}
                            t={t}
                        />
                    )}
                    {activeItem.healthcare_service_group &&
                        activeItem.healthcare_service_group.services.map((hs) => {
                            return (
                                <>
                                    <SlotList
                                        state={state}
                                        activeItem={activeItem}
                                        reloadAppointmentsRequests={reloadAppointmentsRequests}
                                        healthcareService={hs as unknown as HealthcareService}
                                        t={t}
                                    />
                                </>
                            );
                        })}
                </>
            )}
            {activeItem.status == AppointmentRequestStatus.APPROVED && (
                <>
                    <Title>{t('waitingRoom:SlotModalApprovedTitle')}</Title>
                    {activeItem.appointment && (
                        <>
                            <AppointmentOverviewSimplified activeListItem={activeItem} />
                            <AppointmentButtons
                                appointment={activeItem.appointment}
                                reloadAppointmentsRequests={reloadAppointmentsRequests}
                                toggleModal={state.toggleModal}
                            />
                        </>
                    )}
                </>
            )}
        </>
    );
};
