export class ApiRoutes {
    //auth
    static userProfileRoute = '/auth/me';
    static userProfilesRoute = '/auth/me/profiles';
    static loginRoute = '/auth/login';
    static loginCodeRoute = '/auth/login/code';
    static requestLoginOtpRoute = '/auth/login/otp';
    static patientRegistrationRoute = '/auth/registration/patients';
    static checkFieldTakenRoute = '/auth/registration/check-field-taken';
    static requestPasswordResetRoute = '/auth/password-reset/';
    static passwordResetCheckRecoveryToken = '/auth/password-reset/validate_token/';
    static passwordResetSetNewPassword = '/auth/password-reset/confirm/';
    static refreshTokenRoute = '/auth/refresh-token';
    static phoneValidation = '/auth/registration/phone-validation';

    // otp
    static otpRequestsRoute = '/otp/requests';

    // appointments
    static appointmentsRoute = '/appointments/';
    static appointmentsWithSlotRoute = '/appointmentsWithSlots/';
    static appointmentIdRoute = (id: number): string => `/appointments/${id}`;
    static appointmentStepsRoute = (id: number): string => `/appointments/${id}/steps`;
    static appointmentVideoCallRoute = (id: number): string => `/appointments/${id}/video-call`;
    static appointmentPatientAccessRoute = (id: number) => `/appointments/patient-access/${id}`;
    static appointmentVideoCallPatientAccessRoute = (id: number) => `/appointments/patient-access/${id}/video-call`;
    static appointmentReasonsRoute = '/appointment/reasons/';
    static appointmentReasonsIdRoute = (id: number) => `/appointment/reasons/${id}`;
    static uploadAppointmentAttachmentRoute = (id: number): string => `/appointments/${id}/send_attachments/`;
    static uploadAppointmentRequestAttachmentRoute = (id: number): string =>
        `/appointment-requests/${id}/send_attachments/`;
    static appointmentAttachmentIdRoute = (id: number): string => `/appointments/attachments/${id}`;
    static slotBookingRoute = '/appointments/slot-booking';
    static cancelAppointmentRoute = (id: number): string => `/appointments/${id}/cancel`;
    static rescheduleAppointmentRoute = (id: number): string => `/appointments/${id}/reschedule`;

    // appointment_requests
    static appointmentRequestsRoute = '/appointment-requests';
    static appointmentRequestRoute = (id: number) => `/appointment-requests/${id}`;
    static appointmentRequestAttachmentIdRoute = (id: number) => `/appointment-requests/attachments/${id}`;
    static appointmentRequestMarkRespondedRoute = (id: number) => `/appointment-requests/${id}/mark_responded`;
    static appointmentRequestMarkPendingRoute = (id: number) => `/appointment-requests/${id}/mark_pending`;
    static appointmentRequestMarkCanceledRoute = (id: number) => `/appointment-requests/${id}/mark_canceled`;
    static appointmentRequestMarkRejectedRoute = (id: number) => `/appointment-requests/${id}/mark_rejected`;
    static appointmentRequestMarkApprovedRoute = (id: number, idAppointment: number) =>
        `/appointment-requests/${id}/mark_approved/${idAppointment}`;
    // Organizations
    static organizationsSlugRoute = (slug: string) => `/organizations/slug/${slug}`;
    static healthcareServicesRoute = `/organizations/healthcare-services`;
    static healthcareServiceSlotsRoute = '/organizations/healthcare-services/slots';

    static organizationMembersRoute = '/organizations/management/members/';
    static organizationMembersIdRoute = (id: number) => `/organizations/management/members/${id}`;

    static assignedHealthcareServicesRoute = '/organizations/assigned-healthcare-services';
    static manageHealthcareServicesRoute = '/organizations/management/healthcare-services/';
    static manageHealthcareServicesIdRoute = (id: number) => `/organizations/management/healthcare-services/${id}`;

    static manageHealthcareServiceGroupsRoute = '/organizations/management/healthcare-services/groups';
    static manageHealthcareServiceGroupsIdRoute = (id: number) =>
        `/organizations/management/healthcare-services/groups/${id}`;

    static locationsAutocompleteRoute = '/locations/autocomplete/address';
    static locationPlacesIdRoute = (placeId: string) => `/locations/places/${placeId}`;

    static manageLocationsRoute = '/organizations/management/locations/';
    static manageLocationsIdRoute = (id: number): string => `/organizations/management/locations/${id}`;
    static serviceCategoriesRoute = '/organizations/service-categories';

    static manageOrganizationProfileRoute = '/organizations/management/profile';

    static getCalendarOrganizationLocationsRoute = '/organizations/calendar/locations';
    static getCalendarConfigRoute = '/organizations/calendar/config';

    static getOrganizationLocationsRoute = '/organizations/locations';
    static getOrganizationPatientsRoute = '/organizations/patients';
    static getOrganizationPatientRoute = (id: number) => `/organizations/patients/${id}`;

    // Slots
    static slotsOrganizationsRoute = '/slots/organization-slots/';
    static slotsOrganizationsCriteriaRoute = '/slots/organization-slots-criteria/';
    static slotsOrganizationsRequestsRoute = '/slots/organization-slots-filtered';
    static slotOrganizationsRoute = (id: string): string => `/slots/organization-slots/${id}`;
    static slotPatientsRoute = (id: string): string => `/slots/patient-slots/${id}`;

    static generalSearch = '/organizations/general-search';

    // Slots criteria
    static slotsCriteriaRoute = '/slots/criteria';
    static slotsCriteriaHSRoute = '/slots/criteriaHS';
    static slotsCriteriaIdRoute = (id: number): string => `/slots/criteria/${id}`;

    static slotsCriteriaManagementRoute = '/slots/criteria/management';
    static slotsCriteriaManagementIdRoute = (id: number): string => `/slots/criteria/management/${id}`;

    // Insurances
    static insuranceRoute = '/insurances/';

    // Invitations
    static inviteOrganizationMemberRoute = '/invitations/organization-members';
    static resendOrganizationMemberInvitationRoute = '/invitations/organization-members/resend';
    static invitePatientRoute = '/invitations/patients';
    static validateInvitationRoute = '/invitations/validate-token';
    static acceptInvitationRoute = '/invitations/accept';

    // Attachments
    static uploadAttachmentRoute = '/attachments/';

    // All organization admin
    static organizationAdminRoot = '/organizations/admin/';
    static organizationAdminEdit = (id: number): string => `/organizations/admin/${id}`;

    // Questionnaires
    static questionnaires = '/questionnaires/';
    static questionnairesId = (id: number): string => `/questionnaires/${id}`;
    static questionnaireResponses = (appointmentId: number) =>
        `/appointments/${appointmentId}/questionnaire-responses/`;
    static questionnaireResponsesId = (appointmentId: number, id: number) =>
        `/appointments/${appointmentId}/questionnaire-responses/${id}/`;
}
