import {
    Button,
    GreyOutlineButton,
    Modal,
    ModalBody,
    ModalMessage,
    ModalTitle,
    useModalState,
} from '../../../../medrefer-web-sdk/web-kit';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../../medrefer-web-sdk/api';
import { AppointmentRequestStatus } from '../../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';
import useApiRequest from '../../../../hooks/useApiRequest';

interface Props {
    id: number;
    reloadAppointments: () => void;
    button?: ReactElement;
}

export const DeleteRequestModal = ({ button, id, reloadAppointments }: Props) => {
    const modal = useModalState();

    return (
        <Modal
            state={modal}
            button={button}
            body={<Body toggleModal={() => modal.toggleModal()} id={id} reloadAppointments={reloadAppointments} />}
        />
    );
};

interface BodyProps {
    id: number;
    toggleModal: () => void;
    reloadAppointments: () => void;
}
const Body = ({ toggleModal, id, reloadAppointments }: BodyProps) => {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const request = useApiRequest();

    const deleteRequest = () => {
        setIsLoading(true);
        request
            .dispatch(api.changeAppointmentRequestStatus(id, AppointmentRequestStatus.CANCELED))
            .then(reloadAppointments)
            .finally(() => {
                toggleModal();
                setIsLoading(false);
            });
    };

    return (
        <ModalBody>
            <ModalTitle>{t('patientPortal:myAppointments.cancelAppointmentModalBody.title')}</ModalTitle>
            <ModalMessage>{t('patientPortal:myAppointments.cancelAppointmentModalBody.message_request')}</ModalMessage>
            <div style={{ maxWidth: 215 }}>
                <div className="mb-2">
                    <Button onClick={deleteRequest} isLoading={isLoading}>
                        {t('patientPortal:myAppointments.cancelAppointmentModalBody.accept_btn_message')}
                    </Button>
                </div>
                <div>
                    <GreyOutlineButton isLarge onClick={toggleModal}>
                        {t('patientPortal:myAppointments.cancelAppointmentModalBody.decline_btn_message')}
                    </GreyOutlineButton>
                </div>
            </div>
        </ModalBody>
    );
};
