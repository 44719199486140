import { ReactSVG } from 'react-svg';
import styled, { css } from 'styled-components';

export const TimelineWrapper = styled.div`
    width: 100%;
    overflow: hidden;
`;

interface TimelineProps {
    fullWidth: number;
}

export const Timeline = styled.div<TimelineProps>`
    display: flex;
    width: calc(${(props) => props.fullWidth}%);
`;

interface CalendarColProps {
    widthPercent: number;
}

export const CalendarCol = styled.div<CalendarColProps>`
    height: 100%;
    margin: 0 5px;
    width: calc(${(props) => props.widthPercent}%);
`;

export const ColumnHeader = styled.div`
    text-align: center;
    font-size: 15px;
`;

export const FreeSlot = styled.div<{ disabled?: boolean }>`
    padding: 3px 6px;
    margin-top: 8px;
    text-align: center;
    font-weight: 600;
    border-radius: 2px;
    background-color: ${({ theme }) => theme.accent}0f;
    color: ${({ theme }) => theme.accent};
    cursor: pointer;

    ${({ disabled, theme }) =>
        disabled &&
        css`
            pointer-events: none;
            cursor: default;
            background-color: ${theme.grey200}0f;
            color: ${theme.grey200};
        `}

    :hover {
        text-decoration: underline;
    }
`;

export const BusySlot = styled.div`
    padding: 3px 6px;
    margin-top: 8px;
    text-align: center;
    color: ${({ theme }) => theme.grey200};
    text-decoration: line-through;
`;

export const EmptySlot = styled.div`
    padding: 3px 6px;
    margin-top: 8px;
    text-align: center;
    color: ${({ theme }) => theme.grey200};
`;

export const EmptyPlaceholder = styled.div`
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: ${({ theme }) => theme.grey400};
`;

export const ClickableText = styled.span`
    text-decoration: underline;
    cursor: pointer;
    color: ${({ theme }) => theme.accent};
    font-weight: 600;

    &:hover {
        text-decoration: none;
    }
`;

export const ShowMoreContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    button {
        width: auto;
        height: 40px;
    }
`;

export const ShowMoreArrow = styled(ReactSVG)`
    transform: ${(props) => (props.isReversed ? 'rotate(90deg)' : 'rotate(-90deg)')};
    margin-left: 15px;
    svg {
        fill: ${({ theme }) => theme.accent};
        height: 30px;
        width: 30px;
    }
`;
