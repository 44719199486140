import { CLEAR_STATE, SET_USER } from './constants';
import { OrganizationProfile, UserProfile } from 'medrefer-web-sdk/api/models';
import { SET_BRAND } from 'features/appointment/constants';

export type UserState = {
    user?: UserProfile;
    brand?: OrganizationProfile;
};

export const initialState: UserState = {
    user: undefined,
    brand: undefined,
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export default function (state = initialState, action: any): UserState {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                user: action.payload,
            };
        case SET_BRAND:
            return {
                ...state,
                brand: action.payload,
            };
        case CLEAR_STATE:
            return {
                ...initialState,
                brand: state.brand,
            };
        default:
            return state;
    }
}
