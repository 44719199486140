import { useEffect } from 'react';

export const useNotification = () => {
    useEffect(() => {
        if ('Notification' in window) {
            requestNotificationPermission();
        }
    }, []);
};

const requestNotificationPermission = () => {
    return new Promise((resolve) => {
        try {
            Notification.requestPermission().then((permission) => resolve(permission));
        } catch (error) {
            // Safari doesn't return a promise for requestPermissions and it
            // throws a TypeError. It takes a callback as the first argument
            // instead.
            if (error instanceof TypeError) {
                Notification.requestPermission((permission) => resolve(permission));
            } else {
                throw error;
            }
        }
    });
};

export const notification = (title: string, description: string) => {
    const options = {
        body: description,
    };
    new Notification(title, options);
};
