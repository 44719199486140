import React from 'react';
import { withFormik, FormikProps } from 'formik';
import { Form, Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { withTranslation } from 'react-i18next';
import {
    Input,
    Button,
    Label,
    ErrorMessage,
    FormGroup,
    UnderlineH2 as Title,
    FormErrorMessage,
    InformationContainer,
} from 'medrefer-web-sdk/web-kit';
import { FormProps, mapPropsToValues, validationSchema } from './EditMemberProfileForm.schema';
import { AttachmentInput } from 'components/shared/AttachmentInput';
import { EditMemberProfileData } from 'medrefer-web-sdk/api/models';

const InnerForm = (props: FormProps & FormikProps<EditMemberProfileData>) => {
    const { t } = useTranslation();

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <Title>{t('editMemberProfileForm:member_form_label')}</Title>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('editMemberProfileForm:member_description_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-left">
                        <Input
                            isError={!!(props.touched.description && props.errors.description)}
                            id="description"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.description}
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.description && props.errors.description && (
                    <ErrorMessage className="text-left mt-1">{props.errors.description}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('editMemberProfileForm:member_image_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-left">
                        <AttachmentInput
                            setFieldValue={props.setFieldValue}
                            value={props.values.image}
                            name={'image'}
                        />
                    </Col>
                </Row>
                {props.touched.image && props.errors.image && (
                    <ErrorMessage className="text-left mt-1">{props.errors.image}</ErrorMessage>
                )}
            </FormGroup>
            <InformationContainer>
                <FormErrorMessage apiErrors={props.request.errors} skipFormikErrors />
            </InformationContainer>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" xs={12} md={6}>
                        <Button isLoading={props.request.isLoading}>{t('editProfileForm:submit_button_label')}</Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
};

const EditMemberProfileForm = withFormik<FormProps, EditMemberProfileData>({
    mapPropsToValues,
    validationSchema,
    handleSubmit: (values, { props }) => {
        props.updateMemberProfile({
            description: values.description,
            image: values.image,
        });
    },
})(InnerForm);

export default withTranslation()(EditMemberProfileForm);
