import { EndsOnOption, FormValues } from '../CustomRepeat.types';
import { RRule } from 'rrule';
import { freqToRRule, weekdayToRRule } from 'components/Calendar/SchedulePopover/RecurringOptionField/helpers/rrule';
import { Frequency } from 'medrefer-web-sdk/api/models';

export const mapValuesToScheduleRule = (values: FormValues): RRule => {
    let rrule: RRule = new RRule({
        freq: freqToRRule(values.freq),
        interval: values.interval,
        byweekday: values.freq === Frequency.WEEKLY ? values.days.map(weekdayToRRule) : [],
    });

    if (values.endsOn === EndsOnOption.onDay) {
        rrule = new RRule({
            ...rrule.origOptions,
            until: values.until,
        });
    }

    if (values.endsOn === EndsOnOption.afterX) {
        rrule = new RRule({
            ...rrule.origOptions,
            count: values.count,
        });
    }

    return rrule;
};
