import { GET_APPOINTMENTS, UPDATE_APPOINTMENT, CLEAR_APPOINTMENTS, CHANGE_ACTIVE, CLEAR_STATE } from './constants';
import { AppointmentModel, AppointmentStatusesCount } from 'medrefer-web-sdk/api/models';

export interface CountData {
    countByStatus: AppointmentStatusesCount;
}

export interface AppointmentState {
    countData: CountData;
    appointments: AppointmentModel[];
    countTotal: number | null;
    activeListItem: AppointmentModel | null;
}

const initialState: AppointmentState = {
    countData: {
        countByStatus: {
            UPCOMING: 0,
            PAST: 0,
            CANCELLED: 0,
        },
    },
    appointments: [],
    countTotal: null,
    activeListItem: null,
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export default function (state = initialState, action: any): AppointmentState {
    switch (action.type) {
        case GET_APPOINTMENTS:
            return {
                ...state,
                appointments: action.appointments,
                countTotal: action.countTotal,
                countData: action.countData,
            };
        case UPDATE_APPOINTMENT:
            return {
                ...state,
                appointments: state.appointments.map((appointment) => {
                    if (appointment.id === action.payload.id) {
                        return action.payload;
                    }
                    return appointment;
                }),
            };
        case CLEAR_APPOINTMENTS:
            return {
                ...state,
                appointments: [],
                countTotal: state.countTotal,
            };
        case CHANGE_ACTIVE:
            return {
                ...state,
                activeListItem: action.payload,
            };
        case CLEAR_STATE:
            return initialState;
        default:
            return state;
    }
}
