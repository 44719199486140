import { withFormik, FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import { Field } from 'formik';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { FormSelect } from 'medrefer-web-sdk/web-kit';
import { Language } from 'medrefer-web-sdk/api/models';
import { useNonInitialEffect } from 'medrefer-web-sdk/web-kit/hooks';
import { getActiveLanguage } from 'utils/i18n';

interface FormValues {
    currentLanguage: Language;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FormProps {}

const InnerForm = (props: FormProps & FormikProps<FormValues>) => {
    const { currentLanguage } = props.values;
    const { t } = useTranslation();

    const languageOptions = [
        { value: 'en', label: 'English' },
        { value: 'de', label: 'Deutsch' },
        { value: 'pl', label: 'Polski' },
    ];

    useNonInitialEffect(() => {
        i18n.changeLanguage(currentLanguage);
        // eslint-disable-next-line
    }, [currentLanguage]);

    return (
        <Form onSubmit={() => props.handleSubmit} className="mt-3" style={{ maxWidth: 200 }}>
            <Form.Group>
                <Field
                    name="currentLanguage"
                    options={languageOptions}
                    component={FormSelect}
                    placeholder={t('newAppointmentForm:placeholder_insurance_company')}
                    isMulti={false}
                    onBlur={props.handleBlur}
                />
            </Form.Group>
        </Form>
    );
};

const SearchForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({
        currentLanguage: getActiveLanguage() as Language,
    }),
    validationSchema: Yup.object().shape({
        currentLanguage: Yup.string().max(100, 'Please input 100 characters or less'),
    }),
    handleSubmit: (values, { setSubmitting }) => {
        setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
        }, 1000);
    },
})(InnerForm);

export default SearchForm;
