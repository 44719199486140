import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999;
    width: 100%;
    max-width: 1180px;
`;

export const Content = styled.div`
    display: flex;
    align-items: center;
    border-radius: 12px;
    padding: 18px 22px;
    margin: 0 20px;
    box-shadow: 0 0 45px 0 rgba(0, 0, 0, 0.25);
    background-color: #fff;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        flex-direction: column;
        gap: 20px;
    }
`;
