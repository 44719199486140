import React from 'react';
import { LocationFormValues, LocationsFormProps } from './LocationsForm.types';
import { Form, FormikProps, withFormik } from 'formik';
import { useTranslation, withTranslation } from 'react-i18next';
import { mapPropsToValues, validationSchema } from './LocationsForm.schema';
import {
    FormErrorMessage,
    Button,
    TextRed,
    UnderlineH2 as Title,
    InformationContainer,
} from 'medrefer-web-sdk/web-kit';
import { Col, Container, Row } from 'react-bootstrap';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';
import { LocationsData } from 'components/AdminPanel/ManageLocations/LocationsForm/LocationsData';

export const InnerForm = (props: LocationsFormProps & FormikProps<LocationFormValues>) => {
    const { t } = useTranslation();
    return (
        <>
            <Title>
                {props.type === ModalType.add
                    ? t('adminPanel:manageLocations.locationsForm.title_add')
                    : t('adminPanel:manageLocations.locationsForm.title_edit')}
            </Title>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                }}
            >
                <LocationsData baseProps={props} />
                <InformationContainer>
                    <FormErrorMessage apiErrors={props.request.errors} />
                </InformationContainer>
                <Container>
                    <Row>
                        <Col className="m-auto" xs={12} md={12}>
                            <Button isLoading={props.request.isLoading}>
                                {props.type === ModalType.add
                                    ? t('adminPanel:manageLocations.locationsForm.button_add')
                                    : t('adminPanel:manageLocations.locationsForm.button_edit')}
                            </Button>
                            {!props.isValid && !props.isValidating && (
                                <TextRed className="text-right mr-3 mt-4">
                                    {t('newAppointmentForm:warning_please_fill_all')}
                                </TextRed>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Form>
        </>
    );
};

const LocationsForm = withFormik<LocationsFormProps, LocationFormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit: (values, { props }) => {
        const { addressSelect, ...data } = values; // eslint-disable-line @typescript-eslint/no-unused-vars
        props.handleSubmit(data);
    },
})(InnerForm);

export default withTranslation()(LocationsForm);
