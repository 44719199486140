import { withFormik, FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    Button,
    Input,
    FormGroup,
    InformationBubble,
    InformationContainer,
    InformationBubbleTypes,
    FormErrorMessage,
} from 'medrefer-web-sdk/web-kit';
import { ApiRequest } from 'hooks/useApiRequest';

interface FormValues {
    email: string;
}

interface FormProps extends WithTranslation {
    onSubmit: (email: string) => void;
    request: ApiRequest;
}

const InnerForm = (props: FormProps & FormikProps<FormValues>) => {
    const { t } = useTranslation();

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <FormGroup className="mb-0">
                <Form.Label>{t('passwordRecoveryForm:label')}</Form.Label>
                <Input
                    data-testid="email"
                    id="email"
                    name="email"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.email}
                />
            </FormGroup>
            <InformationContainer className="mb-4 mb-md-0">
                <FormErrorMessage apiErrors={props.request.errors} />
                {props.request.isCompleted && props.isValid && (
                    <InformationBubble type={InformationBubbleTypes.success}>
                        {t('passwordRecoveryForm:info_email_send')}
                    </InformationBubble>
                )}
            </InformationContainer>
            <div style={{ maxWidth: 200, margin: 'auto' }}>
                <Button isLoading={props.request.isLoading}>{t('passwordRecoveryForm:button')}</Button>
            </div>
        </Form>
    );
};

const LoginForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({ email: '' }),
    validationSchema: ({ t }: FormProps) =>
        Yup.object().shape({
            email: Yup.string()
                .max(50, t('passwordRecoveryForm:warning_email_max'))
                .email(t('passwordRecoveryForm:warning_email_matches'))
                .required(t('passwordRecoveryForm:warning_email_required')),
        }),
    handleSubmit: (values, { props }) => {
        props.onSubmit(values.email);
    },
})(InnerForm);

export default withTranslation()(LoginForm);
