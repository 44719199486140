import { supportedLanguages, Language, FlatFieldTranslations, OrganizationProfile } from 'medrefer-web-sdk/api/models';
import { Schema } from 'yup';

const translationFieldName = <Name extends string>(fieldName: Name, language: Language): `${Name}_${Language}` => {
    return `${fieldName}_${language}`;
};

export const extractFieldTranslations = <Name extends string, Value>(
    object: { [key: string]: any } | undefined, // eslint-disable-line @typescript-eslint/no-explicit-any
    fieldName: Name,
    defaultValue: Value,
) => {
    /**
     * Given the object with arbitrary fields, extract fields that are the translation of the passed field name
     * for every supported language. Use default value if no value is found the object.
     */
    return supportedLanguages.reduce((result, language) => {
        const value = object == null ? defaultValue : object[translationFieldName(fieldName, language)];
        result[translationFieldName(fieldName, language)] = value == null ? defaultValue : value;
        return result;
    }, {} as FlatFieldTranslations<Name, Value>);
};

export const getDefaultFieldTranslations = <Name extends string, Value>(fieldName: Name, defaultValue: Value) => {
    return extractFieldTranslations(undefined, fieldName, defaultValue);
};

export const getTranslationValidationForField = <V extends Schema<unknown>>(fieldName: string, validation: V) => {
    /**
     * Create an object for validation scheme with all supported language versions for the given field name.
     * Use the same validation for each field.
     */
    return extractFieldTranslations(undefined, fieldName, validation);
};

export const getTranslationConfigForField = <Name extends string>(
    baseFieldName: Name,
    baseLabel: string,
    organization?: OrganizationProfile,
) => {
    /**
     * Create an array of objects that contain configuration like label and field name
     * for every supported language of the organization.
     */
    return getTranslationConfigForSelectedLanguages(baseFieldName, baseLabel, organization?.supported_languages || []);
};

export const getTranslationConfigForSelectedLanguages = <Name extends string>(
    baseFieldName: Name,
    baseLabel: string,
    selectedLanguages: Language[],
) => {
    /**
     * Create an array of objects that contain configuration like label and field name
     * for every passed language.
     */
    if (selectedLanguages.length === 1) {
        return [
            {
                label: baseLabel,
                fieldName: translationFieldName(baseFieldName, selectedLanguages[0]),
            },
        ];
    } else {
        return selectedLanguages.map((language) => {
            const suffix = `(${language.toUpperCase()})`;
            const label = `${baseLabel} ${suffix}`;
            return {
                label,
                fieldName: translationFieldName(baseFieldName, language),
            };
        });
    }
};
