import styled from 'styled-components';

export const SidebarMenu = styled.div`
    padding: 24px 24px 8px 30px;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        position: fixed;
        top: 60px;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0 10px;
        z-index: 2;
    }
`;

export const FiltersContainer = styled.div`
    display: flex;
    margin-bottom: 1.5rem;
    gap: 5px;

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 0;
        margin-bottom: 1rem;
        > div {
            margin-right: 0 !important;
            margin-left: 2px !important;
        }
    }
`;
