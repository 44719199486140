import useApiRequest from 'hooks/useApiRequest';
import { ConfirmBookingModal, DisplayAgreements } from 'components/shared/ConfirmBookingModal';
import { api } from 'medrefer-web-sdk/api';
import { AppointmentModel, SlotBookingData } from 'medrefer-web-sdk/api/models';
import React, { FC, useEffect, useState } from 'react';
import { toNullableDate } from 'utils/dates';

interface Props {
    appointment: AppointmentModel;
    slotKey: string;
    clearSlotKey: () => void;
    rescheduleAppointment: (appointmentId: number, slotKey: string) => void;
    isReschedulingAppointment: boolean;
}

export const RescheduleConfirmation: FC<Props> = ({
    slotKey,
    clearSlotKey,
    appointment,
    rescheduleAppointment,
    isReschedulingAppointment,
}: Props) => {
    const [newAppointmentData, setNewAppointmentData] = useState<SlotBookingData | null>(null);
    const request = useApiRequest();

    const loadAppointmentData = async () => {
        const slot = await request.dispatch(api.getSlot(slotKey));
        if (!appointment || !slot) return;

        const data: SlotBookingData = {
            slot: slot,
            mode: slot.mode,
            street: appointment.patient?.patient?.street || '',
            city: appointment.patient?.patient?.city || '',
            personal_title: appointment.patient_metadata.personal_title,
            first_name: appointment.patient_metadata.first_name,
            last_name: appointment.patient_metadata.last_name,
            phone: appointment.patient_metadata.phone,
            email: appointment.patient_metadata.email,
            insurance_number: appointment.patient_metadata.insurance_number,
            insurance_status: appointment.patient_metadata.insurance_status || '',
            date_of_birth: toNullableDate(appointment.patient_metadata.date_of_birth),
            reason: appointment.reason.id.toString(),
            comment: appointment.comment,
            sms_agreement: appointment.patient_metadata.sms_agreement,
            email_agreement: appointment.patient_metadata.email_agreement,
            terms_agreement: true,
        };
        setNewAppointmentData(data);
    };

    const onDiscardConfirmation = () => {
        setNewAppointmentData(null);
        clearSlotKey();
    };

    const onConfirm = async () => {
        rescheduleAppointment(appointment.id, slotKey);
    };

    useEffect(() => {
        loadAppointmentData();
        // eslint-disable-next-line
    }, [slotKey]);

    if (newAppointmentData) {
        return (
            <ConfirmBookingModal
                values={newAppointmentData}
                onDiscard={onDiscardConfirmation}
                onConfirm={onConfirm}
                isLoading={isReschedulingAppointment}
                agreements={<DisplayAgreements values={newAppointmentData} />}
            />
        );
    }
    return null;
};
