import { useEffect, useState } from 'react';
import { AppointmentModel, AppointmentParams } from 'medrefer-web-sdk/api/models';
import { useParams } from 'react-router-dom';
import Types from 'MyTypes';
import { useDispatch, useSelector } from 'react-redux';
import { appointmentSelectors } from 'features/appointment';
import { routerActions } from 'connected-react-router';
import useBrand from 'hooks/useBrand';
import { clearAppointments, getAppointments, getSingleAppointment } from 'features/appointment/actions';
import { getActiveListItem } from 'features/appointment/selectors';
import { changeActiveListItem } from 'features/appointment/actions';
import { DashboardFilters } from 'components/Dashboard/Dashboard.types';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import useApiRequest from 'hooks/useApiRequest';
import { getShouldLoad } from 'features/appointment/selectors';

export const useDashboardData = (filters: DashboardFilters) => {
    const appointments = useSelector((state: Types.RootState) => appointmentSelectors.getList(state.appointment));
    const countData = useSelector((state: Types.RootState) => state.appointment.countData);
    const activeListItem = useSelector((state: Types.RootState) => getActiveListItem(state.appointment));
    const hasMore = useSelector((state: Types.RootState) => getShouldLoad(state.appointment));
    const [version, setVersion] = useState(1);

    const { appointmentId } = useParams<{ appointmentId?: string }>();
    const queryParams = useQueryParams();
    const request = useApiRequest();

    const { getPathBranded } = useBrand();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(clearAppointments());
        loadMore([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const loadMore = (prev: AppointmentModel[]) => {
        request
            .dispatch(
                dispatchGetAppointments({
                    limit: 20,
                    offset: prev.length,
                    ...filters,
                }),
            )
            .then(() => {
                setVersion((prevVersion) => prevVersion + 1);
            });
    };

    const dispatchGetAppointments = async (params: AppointmentParams) => {
        await dispatch(getAppointments(params));
    };

    // Redirect activeAppointment
    useEffect(() => {
        const isFilter = filters.statuses.length > 0;
        const isList = appointments.length > 0;
        const firstListItem = appointments[0];
        const firstListItemId = firstListItem && firstListItem.id ? firstListItem.id : '';
        const isRedirect = (activeListItem === null || String(activeListItem.id) !== appointmentId) && isList;
        let currAppointment;

        if (appointmentId) {
            currAppointment = appointments.find(
                (appointment: AppointmentModel) => appointment.id === parseInt(appointmentId),
            );
            if (!currAppointment && !activeListItem && !isFilter) {
                dispatch(getSingleAppointment(parseInt(appointmentId)));
            }
        }

        if (currAppointment) {
            dispatch(changeActiveListItem(currAppointment));
        } else if (isFilter && !isList) {
            dispatch(changeActiveListItem(null));
        } else if (isRedirect) {
            dispatch(changeActiveListItem(firstListItem));
            const search = queryParams.toString();
            dispatch(routerActions.push(getPathBranded('dashboardActiveItem', firstListItemId.toString()) + search));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentId, appointments, activeListItem]);

    return {
        appointments,
        countData,
        loadMore,
        hasMore,
        isLoading: request.isLoading,
        version,
        activeListItem,
    };
};
