import React, { ReactNode } from 'react';
import useBrand from 'hooks/useBrand';
import { NavLink } from 'components/shared/NavLink';
import { RouteName } from 'router-paths';

interface SideMenuLinkProps {
    title: string;
    link: RouteName;
    icon?: ReactNode;
    notExact?: boolean;
    onClick?: () => void;
}

export const SideMenuLink = ({ title, icon, link, notExact, onClick }: SideMenuLinkProps) => {
    const { getPathBranded } = useBrand();
    return (
        <li
            onClick={() => {
                onClick && onClick();
            }}
        >
            <NavLink to={getPathBranded(link)} activeClassName="active" notExact={notExact}>
                {icon}
                <span>{title}</span>
            </NavLink>
        </li>
    );
};
