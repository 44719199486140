import { useMemo } from 'react';
import { getActiveLanguage } from 'utils/i18n';

export const useDateNavigator = (currentMonth: Date) => {
    const days = useMemo(() => {
        let startDate = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), 1);
        startDate = new Date(startDate.setDate(startDate.getDate() - startDate.getDay()));
        const newDays = [];
        let dateToAdd = startDate;
        for (let i = 1; i <= 6 * 7; i++) {
            newDays.push(dateToAdd);
            dateToAdd = new Date(dateToAdd.setDate(dateToAdd.getDate() + 1));
        }
        return newDays;
    }, [currentMonth]);

    const dayNames = useMemo(() => {
        return [1, 2, 3, 4, 5, 6, 0].map((daytoset) => {
            const date = new Date(currentMonth.getFullYear(), currentMonth.getMonth(), currentMonth.getDate());
            const distance = daytoset - date.getDay();
            return new Date(date.setDate(date.getDate() + distance)).toLocaleString(getActiveLanguage(), {
                weekday: 'narrow',
            });
        });
    }, [currentMonth]);

    return {
        days,
        dayNames,
    };
};
