export enum Currency {
    USD = 'USD',
    EUR = 'EUR',
    PLN = 'PLN',
}

export enum PaymentMethod {
    CARD = 'CARD',
    KLARNA = 'KLARNA',
}

export interface Price {
    amount: string;
    amount_currency: Currency;
    amount_formatted: string;
    payment_methods: PaymentMethod[];
}
