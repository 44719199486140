import React from 'react';
import { FallbackProps } from 'react-error-boundary';

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
    return (
        <div
            role="alert"
            style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}
        >
            <h2>Something went wrong.</h2>
            <p>The application has encountered an error:</p>
            <pre>{error.message}</pre>
            {error.stack && (
                <div>
                    <strong>Stack Trace:</strong>
                    <pre>{error.stack}</pre>
                </div>
            )}
            <button
                style={{
                    height: '48px',
                    width: '180px',
                    backgroundColor: 'white',

                    color: 'black',
                    border: '1px solid ',
                    borderRadius: '7.5px',
                }}
                onClick={resetErrorBoundary}
            >
                Try Again
            </button>
        </div>
    );
};
