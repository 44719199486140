import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const AppointmentDetailsContainer = styled.div`
    padding: 20px 15px;
    background: #ffff;
    width: 100%;
    max-height: 100%;
    overflow-y: hidden;
    border-radius: 8px;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        padding: 0;
        max-width: 100%;
    }
`;

export const CustomRow = styled(Row)`
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        flex-direction: column;
    }
`;
