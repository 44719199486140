import styled from 'styled-components';

export const AccentSquare = styled.div<{ color: string }>`
    background-color: ${({ color }) => color};
    width: 40px;
    height: 40px;
    display: inline-block;
    border-radius: 8px;
    box-shadow: 0 3px 8px rgb(0 0 0 / 40%);
`;
