import { RecurringSlotOption } from '../../SchedulePopover.types';
import { RRule } from 'rrule';
import { getByMonth, getByMonthDay, getByWeekDay } from './rrule';

/**
 * @param recurringOption Option selected in form
 * @param dateFrom Date from selected in form
 * @returns formatted scheduleRule for API to consume
 */
export const scheduleRuleMapper = (recurringOption: RecurringSlotOption, dateFrom: Date) => {
    switch (recurringOption) {
        case RecurringSlotOption.daily:
            return new RRule({
                freq: RRule.DAILY,
                interval: 1,
            });
        case RecurringSlotOption.weekly:
            return new RRule({
                freq: RRule.WEEKLY,
                interval: 1,
                byweekday: [getByWeekDay(dateFrom)],
            });
        case RecurringSlotOption.everyWeekday:
            return new RRule({
                freq: RRule.WEEKLY,
                interval: 1,
                byweekday: [RRule.MO, RRule.TU, RRule.WE, RRule.TH, RRule.FR],
            });
        case RecurringSlotOption.monthly:
            return new RRule({
                freq: RRule.MONTHLY,
                interval: 1,
                bymonthday: getByMonthDay(dateFrom),
            });
        case RecurringSlotOption.yearly:
            return new RRule({
                freq: RRule.YEARLY,
                interval: 1,
                bymonthday: getByMonthDay(dateFrom),
                bymonth: getByMonth(dateFrom),
            });
        default:
            return undefined;
    }
};
