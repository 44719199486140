import React, { Ref, useImperativeHandle } from 'react';
import TUICalendar from './TUICalendar';
import 'tui-calendar/dist/tui-calendar.css';
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';

import {
    CalendarContainer,
    CalendarLoading,
    CalendarWrapper,
    HorizontalScrollWrapper,
    VerticalScrollWrapper,
} from './CalendarView.styled';

import VideoCallIcon from 'assets/VideocamIcon.svg';
import { CalendarMenu } from './CalendarMenu/CalendarMenu';
import { ServiceColumns } from './ServiceColumns';
import { ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import { useCalendarHeight } from './useCalendarHeight';
import { useTranslation } from 'react-i18next';
import { getCalendarTemplates } from './TUICalendar/helpers';
import { useCalendarControls } from './useCalendarControls';
import { useCalendarFields } from './useCalendarFields';
import { CalendarState } from './useCalendarState';
import { ICalendarInfo, ISchedule } from 'tui-calendar';
import { ClickScheduleEvent, CreateScheduleEvent, HourRange, UpdateScheduleEvent } from './CalendarView.types';
import { ControlRefObject } from 'components/Calendar/CalendarView/useControlRef';

interface Props {
    state: CalendarState;
    hourRange: HourRange;
    isLoading: boolean;
    canCreateSchedule: boolean;
    calendars?: ICalendarInfo[];
    activeCalendars?: ICalendarInfo[];
    schedules?: ISchedule[];
    onClickSchedule: (e: ClickScheduleEvent) => void;
    onCreateSchedule: (e: CreateScheduleEvent) => void;
    onUpdateSchedule: (e: UpdateScheduleEvent) => void;
    controlRef: Ref<ControlRefObject>;
}

export const CalendarView = ({
    state,
    hourRange,
    calendars,
    activeCalendars,
    isLoading,
    canCreateSchedule,
    schedules,
    onClickSchedule,
    onCreateSchedule,
    onUpdateSchedule,
    controlRef,
}: Props) => {
    const { t } = useTranslation();
    const {
        ref,
        handleClickSchedule,
        handleCreateSchedule,
        handleDeleteSchedule,
        handleUpdateSchedule,
        handleToday,
        handlePrev,
        handleNext,
        handleDateChange,
        handleCalendarTypeChange,
    } = useCalendarControls({
        state,
        onClickSchedule,
        onCreateSchedule,
        onUpdateSchedule,
    });
    useImperativeHandle(controlRef, () => ({
        handleDateChange,
        handleCalendarTypeChange,
    }));
    const { calendarHeight, calendarWrapperHeight } = useCalendarHeight(state.calendarType, ref);
    const { isCurrent, isPast, pastRowsLength, dateFormatted } = useCalendarFields(state);

    return (
        <CalendarContainer>
            <CalendarMenu
                handleToday={handleToday}
                handlePrev={handlePrev}
                handleNext={handleNext}
                calendarType={state.calendarType}
                dateFormatted={dateFormatted}
                handleCalendarTypeChange={handleCalendarTypeChange}
            />
            <HorizontalScrollWrapper>
                <CalendarWrapper
                    height={calendarWrapperHeight}
                    calendarType={state.calendarType}
                    hourRangeCount={hourRange.max - hourRange.min}
                    pastRowsLength={pastRowsLength}
                    isPast={isPast}
                    isCurrent={isCurrent}
                    videoCallIcon={VideoCallIcon}
                >
                    <ServiceColumns
                        activeCalendars={activeCalendars || calendars || []}
                        calendarType={state.calendarType}
                    />
                    <CalendarLoading isLoading={isLoading}>
                        <ActivityIndicator background={'#000'} />
                    </CalendarLoading>
                    <VerticalScrollWrapper calendarType={state.calendarType}>
                        <TUICalendar
                            ref={ref}
                            height={`${calendarHeight}px`}
                            view={state.calendarType}
                            useCreationPopup={false}
                            useDetailPopup={false}
                            template={getCalendarTemplates()}
                            calendars={calendars}
                            schedules={schedules}
                            onClickSchedule={handleClickSchedule}
                            onBeforeCreateSchedule={handleCreateSchedule}
                            onBeforeUpdateSchedule={handleUpdateSchedule}
                            onBeforeDeleteSchedule={handleDeleteSchedule}
                            isReadOnly={!canCreateSchedule}
                            timezones={[
                                {
                                    timezoneName: 'Europe/Berlin',
                                    displayLabel: 'GMT+02:00',
                                    tooltip: 'Berlin',
                                },
                            ]}
                            taskView={[]}
                            scheduleView={['time']}
                            week={{
                                hourStart: hourRange.min,
                                hourEnd: hourRange.max,
                                startDayOfWeek: 1,
                                daynames: t('global:daynamesShort', { returnObjects: true }),
                            }}
                            month={{ startDayOfWeek: 1 }}
                            theme={{
                                'week.today.backgroundColor': 'none',
                                'day.today.backgroundColor': 'none',
                                'week.pastDay.color': '#bbb',
                            }}
                        />
                    </VerticalScrollWrapper>
                </CalendarWrapper>
            </HorizontalScrollWrapper>
        </CalendarContainer>
    );
};
