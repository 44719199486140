import styled from 'styled-components';

export const InfoPill = styled.span`
    font-weight: 600;
    font-size: 10px;
    line-height: 14px;
    color: #9d9eb2;
    padding: 6px 11px 7px;
    background: #ffffff;
    border: 1px solid #eaeaf4;
    box-sizing: border-box;
    border-radius: 13px;
`;
