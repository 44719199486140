import React from 'react';
import { Field, Form, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import {
    Button,
    FormErrorMessage,
    FormGroup,
    InformationContainer,
    Input,
    Label,
    UnderlineH2 as Title,
    FormSelect,
} from 'medrefer-web-sdk/web-kit';
import { Col, Container, Row } from 'react-bootstrap';
import { MemberInvitationData, UserRole } from 'medrefer-web-sdk/api/models';
import { ApiRequest } from 'hooks/useApiRequest';
import { useRoleOptions } from 'hooks/options/useRoleOptions';

interface InviteFormProps extends WithTranslation {
    request: ApiRequest;
    handleSubmit: (values: MemberInvitationData) => void;
}

export const InnerForm = (props: InviteFormProps & FormikProps<MemberInvitationData>) => {
    const { t } = useTranslation();
    const roleOptions = useRoleOptions([UserRole.organizationMember, UserRole.cooperator, UserRole.organization]);

    return (
        <>
            <Title>{t('adminPanel:manageMembers.inviteForm.invite_button')}</Title>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                }}
            >
                <FormGroup>
                    <Row>
                        <Col xs={12} md={4}>
                            <Label isRequired>{t('adminPanel:manageMembers.editForm.email_label')}</Label>
                        </Col>
                        <Col xs={12} md={8}>
                            <Input
                                isError={false}
                                id="email"
                                name="email"
                                value={props.values.email}
                                type="text"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <FormGroup>
                    <Row>
                        <Col xs={12} md={4}>
                            <Label isRequired>{t('adminPanel:manageMembers.editForm.role_label')}</Label>
                        </Col>
                        <Col xs={12} md={8}>
                            <Field
                                name="role"
                                options={roleOptions}
                                component={FormSelect}
                                isError={props.touched.role && !!props.errors.role}
                                onBlur={props.handleBlur}
                            />
                        </Col>
                    </Row>
                </FormGroup>
                <InformationContainer>
                    <FormErrorMessage apiErrors={props.request.errors} />
                </InformationContainer>
                <Container>
                    <Row>
                        <Col className="m-auto" xs={12} md={12}>
                            <Button isLoading={props.request.isLoading}>
                                {t('adminPanel:manageMembers.inviteForm.invite_button')}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </>
    );
};

const InviteMember = withFormik<InviteFormProps, MemberInvitationData>({
    mapPropsToValues: () => {
        return {
            email: '',
            role: UserRole.organizationMember,
        };
    },
    validationSchema: ({ t }: InviteFormProps) =>
        Yup.object().shape({
            email: Yup.string().email().required(t('form:error.required')),
        }),
    handleSubmit: (values, { props }) => {
        props.handleSubmit(values);
    },
})(InnerForm);

export default withTranslation()(InviteMember);
