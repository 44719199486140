import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import { EditProfileForm } from './EditProfileForm';
import ProfileAttribute from 'components/shared/ProfileAttribute';
import PatientAttributes from './PatientAttributes';

import EditIcon from 'assets/EditIcon.svg';

import { ExternalLink, Modal, UnderlineH2 as TitleUnderline, useModalState } from 'medrefer-web-sdk/web-kit';
import { CustomIconButton, ProfileDisplayContainer } from 'components/Profile/Profile.styled';
import { useDispatch, useSelector } from 'react-redux';
import { authActions, userSelectors } from 'features/auth';
import { UserRole, UserUpdateData } from 'medrefer-web-sdk/api/models';
import { getFullName } from 'medrefer-web-sdk/api/utils';
import useApiRequest from 'hooks/useApiRequest';
import { errorTimeout } from 'utils/constants';
import { translateUserRole } from 'utils/i18n';
import { getPath } from 'router-paths';
import { getBrand } from '../../features/auth/selectors';
import { EOptionalFields, useOptionalFields } from '../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';

const ModalButton = function (): JSX.Element {
    return (
        <CustomIconButton>
            <ReactSVG src={EditIcon} />
        </CustomIconButton>
    );
};

export const Profile: React.FC = () => {
    const authUser = useSelector(userSelectors.getAuthUser);
    const dispatch = useDispatch();
    const request = useApiRequest({ errorTimeout });
    const { t } = useTranslation();
    const modal = useModalState();
    const organization = useSelector(getBrand);
    const { isVisible, getMandatoryFields, getFields } = useOptionalFields(organization);

    if (!authUser) {
        return null;
    }

    const onUserDataSubmit = (data: UserUpdateData) => {
        request.dispatch(updateUserData(data));
    };

    const updateUserData = async (data: UserUpdateData) => {
        await dispatch(authActions.updateUserData(data));
        modal.toggleModal();
    };

    const dateJoined = authUser.date_joined ? authUser.date_joined.split('T')[0] : '-';

    return (
        <div className="pl-md-5 pr-md-5 pb-md-5 pt-md-3 pt-0">
            <div className="w-full d-flex justify-content-center mt-md-5">
                <ProfileDisplayContainer>
                    <Modal
                        state={modal}
                        isCentered={false}
                        button={<ModalButton />}
                        className="position-absolute"
                        body={
                            <EditProfileForm
                                authUser={authUser}
                                updateUserData={onUserDataSubmit}
                                request={request}
                                fields={getFields()}
                                mandatory_fields={getMandatoryFields()}
                            />
                        }
                    />
                    <TitleUnderline style={{ padding: '20px 0' }}>{t('profileDisplay:profile_label')}</TitleUnderline>
                    <Row className="mb-5 mr-0 ml-0">
                        <Col xs={12} md={6}>
                            {authUser.role === UserRole.organization && (
                                <ProfileAttribute label={t('profileDisplay:user_id_label')} value={authUser.id} />
                            )}
                            <ProfileAttribute
                                label={t('profileDisplay:full_name_label')}
                                value={getFullName(authUser)}
                            />
                            <ProfileAttribute label={t('profileDisplay:email_label')} value={authUser.email} />
                        </Col>
                        <Col xs={12} md={6}>
                            {isVisible(EOptionalFields.phone) && (
                                <ProfileAttribute label={t('profileDisplay:phone_label')} value={authUser.phone} />
                            )}
                            {authUser.role === UserRole.organization && (
                                <ProfileAttribute
                                    label={t('profileDisplay:role_label')}
                                    value={translateUserRole(authUser.role)}
                                />
                            )}

                            <ProfileAttribute label={t('profileDisplay:date_joined_label')} value={dateJoined} />
                        </Col>
                        {authUser.patient && <PatientAttributes patient={authUser.patient} isVisible={isVisible} />}
                    </Row>
                    <Row>
                        <Col xs={12} md={3} className="mr-auto">
                            <div>
                                <ExternalLink href={getPath('privacyPolicy')}>
                                    {t('profileDisplay:privacy_policy_link')}
                                </ExternalLink>
                            </div>
                        </Col>
                    </Row>
                </ProfileDisplayContainer>
            </div>
        </div>
    );
};
