import React from 'react';
import { useTranslation } from 'react-i18next';
import { FooterContainer, FooterTitle, FooterLinks } from './Footer.styled';
import { getPath } from 'router-paths';
import { ExternalLink } from 'medrefer-web-sdk/web-kit';

interface Props {
    isSideMenuOpen: boolean;
    isSideMenuVisible: boolean;
}

export const Footer = ({ isSideMenuOpen, isSideMenuVisible }: Props) => {
    const { t } = useTranslation();
    return (
        <FooterContainer isSideMenuOpen={isSideMenuOpen} isSideMenuVisible={isSideMenuVisible}>
            <FooterTitle>{t('footer:address')}</FooterTitle>
            <FooterLinks>
                <ExternalLink href={getPath('termsOfUse')}>{t('footer:terms_of_use')}</ExternalLink>
                <ExternalLink href={getPath('privacyPolicy')}>{t('footer:data_privacy')}</ExternalLink>
                <ExternalLink href={getPath('imprint')}>{t('footer:imprint')}</ExternalLink>
            </FooterLinks>
        </FooterContainer>
    );
};
