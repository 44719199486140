import React, { useEffect, ReactNode, useState } from 'react';
import { Column, usePagination, useTable, useFlexLayout, useRowSelect, Row } from 'react-table';
import { TableDisplay } from './TableDisplay';
import { TableControls } from './TableControls';
import { TableContainer, TableViewContainer, TableViewHeader, AddButtonContainer } from './DataTable.styled';
import { H1, Option, PrimaryButton } from '../../';
import { Modal, ModalState } from '../Modal/Modal';
import { TableFilter } from './TableControls/TableControls.types';
import { useQueryParams, useNonInitialEffect } from '../../hooks';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export interface DataTableProps<Item = any> {
    title: string;
    noItemsMessage: string;
    actionColumnTitle: string;
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    columns: Column<any>[];
    data: Array<Item>;
    fetchData: (pageIndex: number, pageSize: number, searchPhrase: string) => void;
    isLoading: boolean;
    pageCount: number;
    pageSizeOptions: Option[];
    addItemForm?: ReactNode;
    addModalState?: ModalState;
    editItemForm?: ReactNode;
    addButtonText?: string;
    previousPageText: string;
    nextPageText: string;
    editModalState?: ModalState;
    deleteItemForm?: ReactNode;
    deleteModalState?: ModalState;
    setActiveItem: (item: Item) => void;
    tableFilters?: TableFilter[];
    ordering?: string[];
    sortHandler?: (sortParam: string) => void;
    breadcrumbs?: ReactNode;
    // eslint-disable-next-line @typescript-eslint/ban-types
    getExtraActions?: (row: Row<object>) => undefined | Array<ReactNode>;
}

export const DataTable = ({
    title,
    actionColumnTitle,
    columns,
    data,
    fetchData,
    isLoading,
    pageCount,
    pageSizeOptions,
    addItemForm,
    addModalState,
    setActiveItem,
    editItemForm,
    editModalState,
    deleteItemForm,
    deleteModalState,
    tableFilters,
    ordering,
    sortHandler,
    addButtonText,
    previousPageText,
    nextPageText,
    breadcrumbs,
    getExtraActions,
    noItemsMessage,
}: DataTableProps) => {
    const defaultColumn = React.useMemo(
        () => ({
            // When using the useFlexLayout:
            minWidth: 30, // minWidth is only used as a limit for resizing
            width: 150, // width is used for both the flex-basis and flex-grow
            maxWidth: 200, // maxWidth is only used as a limit for resizing
        }),
        [],
    );
    const queryParams = useQueryParams();
    const pageSizeParam = queryParams.get('pageSize');
    const pageIndexParam = queryParams.get('pageIndex');
    const searchPhraseParam = queryParams.get('searchPhrase');
    const [searchPhrase, setSearchPhrase] = useState(searchPhraseParam ? searchPhraseParam : '');

    const {
        pageOptions,
        state: { pageIndex, pageSize },
        gotoPage,
        previousPage,
        nextPage,
        setPageSize,
        canPreviousPage,
        canNextPage,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: {
                pageIndex: pageIndexParam ? parseInt(pageIndexParam) : 0,
                pageSize: pageSizeParam ? parseInt(pageSizeParam) : 10,
            },
            manualPagination: true,
            pageCount: pageCount,
        },
        usePagination,
        useFlexLayout,
        useRowSelect,
    );

    useEffect(() => {
        fetchData(pageIndex, pageSize, searchPhrase);
    }, [fetchData, pageIndex, pageSize, searchPhrase]);

    useNonInitialEffect(() => {
        queryParams.set('pageIndex', pageIndex.toString());
        queryParams.set('pageSize', pageSize.toString());
        queryParams.set('searchPhrase', searchPhrase.toString());
    }, [fetchData, pageIndex, pageSize, searchPhrase]);

    return (
        <TableViewContainer>
            <TableViewHeader>
                <H1>{title}</H1>
                {addModalState && addItemForm && addButtonText && (
                    <AddButtonContainer>
                        <PrimaryButton type="button" onClick={addModalState.toggleModal}>
                            {addButtonText}
                        </PrimaryButton>
                        <Modal isCentered={false} state={addModalState} body={addItemForm} />
                    </AddButtonContainer>
                )}
            </TableViewHeader>
            {breadcrumbs && breadcrumbs}
            <TableContainer>
                <TableControls
                    searchPhrase={searchPhrase}
                    setSearchPhrase={setSearchPhrase}
                    tableFilters={tableFilters}
                    pageIndex={pageIndex}
                    pageCount={pageCount}
                    pageOptions={pageOptions}
                    pageSize={pageSize}
                    pageSizeOptions={pageSizeOptions}
                    setPageSize={setPageSize}
                    nextPage={nextPage}
                    previousPage={previousPage}
                    gotoPage={gotoPage}
                    canPreviousPage={canPreviousPage}
                    canNextPage={canNextPage}
                    previousPageText={previousPageText}
                    nextPageText={nextPageText}
                />
                <TableDisplay
                    actionColumnTitle={actionColumnTitle}
                    columnCount={columns.length}
                    getTableProps={getTableProps}
                    getTableBodyProps={getTableBodyProps}
                    headerGroups={headerGroups}
                    rows={rows}
                    prepareRow={prepareRow}
                    isLoading={isLoading}
                    setActiveItem={setActiveItem}
                    editItemForm={editItemForm}
                    editModalState={editModalState}
                    deleteItemForm={deleteItemForm}
                    deleteModalState={deleteModalState}
                    ordering={ordering}
                    sortHandler={sortHandler}
                    getExtraActions={getExtraActions}
                    noItemsMessage={noItemsMessage}
                />
            </TableContainer>
        </TableViewContainer>
    );
};
