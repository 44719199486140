import React, { useEffect, useMemo } from 'react';
import { Modal, Option, useModalState } from 'medrefer-web-sdk/web-kit';
import { FormValues, RecurringSlotOption, SchedulePopoverProps } from '../SchedulePopover.types';
import RRule from 'rrule';
import { CustomRepeat } from '../CustomRepeat';
import { Field, FormikProps } from 'formik';
import { toTitleCase } from 'medrefer-web-sdk/web-kit/utils/stringHelpers';
import { Language } from 'medrefer-web-sdk/api/models';
import { rruleToText } from 'i18n/rrule-i18n';
import { recurringOptionText } from './helpers/recurringOptionText';
import { RepeatSelect } from './RecurringOptionField.styled';
import useOptionsPreset from './useOptionsPreset';
import { replaceRRuleDate } from './helpers/rrule';
import { scheduleRuleMapper } from './helpers/scheduleRuleMapper';
import { getActiveLanguage } from 'utils/i18n';

type Props = {
    props: SchedulePopoverProps & FormikProps<FormValues>;
};

export const RecurringOptionField = ({ props }: Props) => {
    const scheduleModal = useModalState();
    const { activeSlot } = props;
    const { recurring_option: recurringOption, recurring_rule: recurringRule, date } = props.values;
    const optionsPreset = useOptionsPreset(date);

    useEffect(() => {
        const preset = optionsPreset.find((o) => o.value?.toString() === recurringRule?.toString());
        if (preset) {
            props.setFieldValue('recurring_option', preset.label);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (recurringRule) {
            const newRecurringRule = replaceRRuleDate(recurringRule, date, recurringOption);
            if (recurringRule.toString() !== newRecurringRule.toString()) {
                props.setFieldValue('recurring_rule', newRecurringRule);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date, recurringRule, recurringOption]);

    const recurringSlotOptions = useMemo(() => {
        const recurringOption = (value: RecurringSlotOption) => ({ label: recurringOptionText(value), value });

        const options = [
            recurringOption(RecurringSlotOption.daily),
            recurringOption(RecurringSlotOption.weekly),
            recurringOption(RecurringSlotOption.monthly),
            recurringOption(RecurringSlotOption.yearly),
            recurringOption(RecurringSlotOption.everyWeekday),
            recurringOption(RecurringSlotOption.custom),
        ];

        if (!activeSlot?.is_recurring) {
            options.unshift(recurringOption(RecurringSlotOption.dontRepeat));
        }

        return options;
    }, [activeSlot]);

    const onRecurringOptionChange = (option: Option<RecurringSlotOption>) => {
        if (option.value === RecurringSlotOption.custom) {
            scheduleModal.toggleModal();
        } else {
            props.setFieldValue('recurring_option', option.value);
            props.setFieldValue('recurring_rule', scheduleRuleMapper(option.value, date));
        }
    };

    const onCustomRepeatSubmit = (rrule: RRule) => {
        scheduleModal.toggleModal();
        props.setFieldValue('recurring_option', RecurringSlotOption.custom);
        props.setFieldValue('recurring_rule', rrule);
    };

    let repeatText = '';
    if (recurringOption !== RecurringSlotOption.custom) {
        repeatText = recurringOptionText(recurringOption);
    } else if (recurringRule != null) {
        const currentLanguage = getActiveLanguage() as Language;
        repeatText = rruleToText(recurringRule, currentLanguage);
    }

    return (
        <>
            <Field
                name="recurring_option"
                options={recurringSlotOptions}
                placeholder={toTitleCase(repeatText)}
                value={null}
                component={RepeatSelect}
                isError={props.touched.repeat && props.errors.repeat}
                onBlur={props.handleBlur}
                onChange={onRecurringOptionChange}
            />
            <Modal
                isCentered={false}
                state={scheduleModal}
                body={<CustomRepeat recurringSchema={props.values.recurring_rule} onSubmit={onCustomRepeatSubmit} />}
            />
        </>
    );
};
