import { AppointmentModel, PatientMetadata } from '../models';
import { getFullName } from '../utils';

export const appointmentFactory = (data: AppointmentModel): AppointmentModel => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const commonData: any = {
        ...(data.patient || {}),
        ...(data.patient?.patient || {}),
        ...(data.proposal || {}),
    };

    const patientMetadata: PatientMetadata = {
        personal_title: commonData.personal_title || '',
        first_name: commonData.first_name || '',
        last_name: commonData.last_name || '',
        full_name: '',
        email: commonData.email || '',
        phone: commonData.phone || '',
        date_of_birth: commonData.date_of_birth || '',
        post_code: commonData.post_code || '',
        city: commonData.city || '',
        street: commonData.street || '',
        insurance_number: commonData.insurance_number || '',
        insurance_company: commonData.insurance_company,
        insurance_status: commonData.insurance_status,
        sms_agreement: commonData.sms_agreement || false,
        email_agreement: commonData.email_agreement || false,
    };
    patientMetadata.full_name = getFullName(patientMetadata);

    return {
        ...data,
        patient_metadata: patientMetadata,
    };
};
