import React from 'react';

import { useTranslation } from 'react-i18next';
import { Col, Form, Row } from 'react-bootstrap';

import {
    Amount,
    CheckBox,
    Label,
    Title,
} from '../Dashboard/Sidebar/SidebarListMenuFilter/FilterForm/FilterForm.styled';
import { RequestsFilters } from './RequestListFilter';
import { AppointmentRequestStatus, RequestCountData } from '../../medrefer-web-sdk/api/models/AppointmentRequestsModel';

export interface FormProps {
    countData: RequestCountData;
    filters: RequestsFilters;
    setStatuses: (items: AppointmentRequestStatus[]) => void;
}
export interface FormRowProps {
    id: AppointmentRequestStatus;
    checked: boolean;
    count: number;
    onClick: () => void;
    isLoading?: boolean;
}
const FormRowCheckbox = ({ checked, id, count, onClick }: FormRowProps) => {
    const { t } = useTranslation();
    const label = {
        Apr: t('statusBadge:approved'),
        Can: t('statusBadge:cancelled'),
        Pnd: t('statusBadge:pending'),
        Rej: t('statusBadge:rejected'),
        Rsp: t('statusBadge:responded'),
    }[id];

    return (
        <Row className="mb-1">
            <Col className="d-flex align-items-start" xs={9}>
                <Label>
                    <CheckBox id={id} checked={checked} onChange={onClick} className="mr-4" />
                    <div style={{ lineHeight: '13px' }}>{label}</div>
                </Label>
            </Col>
            <Col xs={1}>
                <Amount>{count}</Amount>
            </Col>
        </Row>
    );
};

export const RequestFilterForm = ({ countData, filters, setStatuses }: FormProps) => {
    const { t } = useTranslation();
    const handleChangeStatus = (status: AppointmentRequestStatus) => {
        if (filters.statuses.includes(status)) {
            filters.setStatuses(filters.statuses.filter((current) => current !== status));
            setStatuses(filters.statuses.filter((current) => current !== status));
        } else {
            filters.setStatuses(filters.statuses.concat(status));
            setStatuses(filters.statuses.concat(status));
        }
    };

    const values = [
        AppointmentRequestStatus.APPROVED,
        AppointmentRequestStatus.CANCELED,
        AppointmentRequestStatus.PENDING,
        AppointmentRequestStatus.REJECTED,
        AppointmentRequestStatus.RESPONDED,
    ];
    return (
        <Form className="w-100">
            <Form.Group className="mb-0">
                <Title>{t('filterForm:filter_status')}</Title>
                {values.map((status) => (
                    <FormRowCheckbox
                        id={status}
                        key={status}
                        checked={filters.statuses.includes(status)}
                        count={countData[status]}
                        onClick={() => {
                            handleChangeStatus(status);
                        }}
                    />
                ))}
            </Form.Group>
        </Form>
    );
};
