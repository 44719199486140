import { Questionnaire } from 'medrefer-web-sdk/api/models';
import { Column } from 'react-table';

export const columns: (t: (key: string) => string) => Column<Questionnaire>[] = (t) => [
    {
        Header: t('adminPanel:table.columns.id'),
        accessor: 'id',
        sortParam: 'id',
        width: 80,
    },
    {
        Header: t('adminPanel:table.columns.title'),
        accessor: 'title',
        sortParam: 'title',
    },
];
