import React, { FC } from 'react';
import styled, { css } from 'styled-components';

interface HamburgerButtonProps {
    isActive?: boolean;
    handleOnClick?: () => void;
    className?: string;
}

export const Hamburger: FC<HamburgerButtonProps> = ({ isActive, handleOnClick, className }: HamburgerButtonProps) => (
    <HamburgerButton onClick={handleOnClick} className={className}>
        <HamburgerBox>
            <HamburgerInner isActive={isActive} />
        </HamburgerBox>
    </HamburgerButton>
);

const HamburgerButton = styled.button`
    padding: 10px;
    display: inline-block;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: 0;
    margin: 0 0 0 5px;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        padding: 25px 30px;
    }
`;

const HamburgerBox = styled.span`
    width: 25px;
    height: 24px;
    display: inline-block;
    position: relative;
`;

const hamburgerLine = css`
    width: 100%;
    height: 2.5px;
    position: absolute;
    left: 0;
`;

const HamburgerInner = styled.span<{ isActive?: boolean }>`
    ${hamburgerLine}

    background-color: ${({ theme, isActive }) => (isActive ? 'transparent' : theme.black)};
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.1s ease-in-out;

    &::before,
    &::after {
        ${hamburgerLine}
        background-color: ${({ theme }) => theme.black};
        content: '';
        transition: transform 0.1s ease-in-out;
    }

    &::before {
        top: -9px;
        transform: ${({ isActive }) => (isActive ? 'translateY(9px) rotate(45deg)' : '')};
    }

    &::after {
        top: 9px;
        transform: ${({ isActive }) => (isActive ? 'translateY(-9px) rotate(-45deg)' : '')};
    }
`;
