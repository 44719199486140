import styled from 'styled-components';
import { UnderlineFullH2 } from '../Underline';
import ChevronDown from '../../assets/icons/ChevronDown.png';
import ChevronUp from '../../assets/icons/ChevronUp.png';

interface UnderlineChevronProps {
    isActive: boolean;
}

export const StyledUnderlineChevron = styled(UnderlineFullH2)<UnderlineChevronProps>`
    background-image: ${(props) => (props.isActive ? `url(${ChevronUp})` : `url(${ChevronDown})`)};
    background-repeat: no-repeat;
    background-position: 100% calc(50% + 4px);
    text-align: start;
    margin: 0;
`;
