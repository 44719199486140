import styled from 'styled-components';

export const LinkItem = styled.span`
    font-weight: 800;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 1.375px;
    color: ${({ theme }) => theme.black};
    margin-top: 5px;
    cursor: pointer;
    &:hover {
        opacity: 0.8;
    }
`;
