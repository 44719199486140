import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';
import { AgreementsWRProps, AgreementsWRValues } from './AgreementsWRForm.types';
import { handleSubmit, mapPropsToValues, validationSchema } from './AgreementsWRForm.schema';
import { AgreementsWRData } from './AgreementsWRData';

const AgreementsWRForm = withFormik<AgreementsWRProps, AgreementsWRValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(AgreementsWRData);
export default withTranslation()(AgreementsWRForm);
