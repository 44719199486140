import moment from 'moment';
import React, { ReactElement } from 'react';

import { Col, Row } from 'react-bootstrap';
import {
    BirthDayAppointmentHeader,
    InfoDashedPanel,
    HeaderBadgesContainer,
    ActionMenuMobile,
    ActionMenuDesktop,
} from './AppointmentHeader.styled';
import { mapInsuranceToTranslation } from 'utils/i18n';
import { useTranslation } from 'react-i18next';
import ActionMenu from './ActionMenu/ActionMenu';
import { formatDate } from 'utils/dates';
import { InfoPill, H1 } from 'medrefer-web-sdk/web-kit';
import { StatusBadge } from 'components/Dashboard/StatusBadge';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';

interface Props {
    activeListItem: AppointmentModel;
}

export const AppointmentHeader = ({ activeListItem }: Props): ReactElement => {
    const { t } = useTranslation();
    const { created_at, id, healthcare_service } = activeListItem;
    const { full_name, date_of_birth, insurance_status } = activeListItem.patient_metadata;

    return (
        <Col>
            <ActionMenuMobile>
                <ActionMenu activeListItem={activeListItem} />
            </ActionMenuMobile>
            <Row className="mb-2 d-flex justify-content-between align-items-center">
                <HeaderBadgesContainer>
                    <div className="mr-2">
                        <StatusBadge appointment={activeListItem} />
                    </div>
                    <InfoPill className="mr-2">
                        {t('appointmentHeader:reported_on')} {formatDate(moment(created_at))}
                    </InfoPill>
                    <InfoPill className="mr-2">
                        {t('appointmentHeader:id')} {id}
                    </InfoPill>
                    {insurance_status && (
                        <InfoPill>
                            {t('appointmentHeader:insurance')}
                            <span style={{ color: '#000000', marginLeft: '2px' }}>
                                {mapInsuranceToTranslation(insurance_status)}
                            </span>
                        </InfoPill>
                    )}
                </HeaderBadgesContainer>
                <ActionMenuDesktop className="mt-2">
                    <ActionMenu activeListItem={activeListItem} />
                </ActionMenuDesktop>
            </Row>
            <Row>
                <H1 className="mb-4">{full_name}</H1>
                {date_of_birth && (
                    <BirthDayAppointmentHeader>{formatDate(moment(date_of_birth))}</BirthDayAppointmentHeader>
                )}
            </Row>
            <Row className="d-flex align-items-center">
                <InfoDashedPanel>
                    <div>{healthcare_service.name}</div>
                </InfoDashedPanel>
            </Row>
        </Col>
    );
};
