import styled from 'styled-components';

export const TextGrey = styled.span`
    font-weight: 600;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 1px;
    color: #9d9eb2;
`;

export const GreyTitle = styled(TextGrey)`
    letter-spacing: 1.25px;
    margin-bottom: 5px;
`;
