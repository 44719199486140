import React, { ReactNode, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminPanelContainer } from '../AdminPanel/AdminPanel.styled';
import { DataTable } from '../shared/DataTable';
import { columns } from './RequestsList.consts';
import { useManageAppointmentRequests } from './hooks/RequestsList.hook';
import { Row } from 'react-table';
import { IconButtonAlt, Modal, useModalState } from '../../medrefer-web-sdk/web-kit';
import { ReactSVG } from 'react-svg';
import EditIcon from '../../assets/EditIcon.svg';
import { AppointmentRequestsModel } from '../../medrefer-web-sdk/api/models/AppointmentRequestsModel';
import { SlotsFinderModal } from './SlotFinderModal/SlotsFinderModal';
import { ButtonsContainer, GreyOutlineButton } from './RequestsListButtonCointerner.styled';
import { RequestListFilter } from './RequestListFilter';
import { useRequestListFilters } from './hooks/useRequestListFilters';
import { AutoFillInfoModal } from './AutoFillInfoModal/AutoFillInfoModal';

export const RequestsList = () => {
    const { t } = useTranslation();
    const filters = useRequestListFilters();
    const { fetchActiveItem, ...rest } = useManageAppointmentRequests(filters);
    const slotsFinderModalState = useModalState({ actionOnClose: () => setActiveItem(null) });

    const setActiveItem = useCallback(
        (item: AppointmentRequestsModel | null) => {
            if (item) {
                fetchActiveItem(item);
                slotsFinderModalState.toggleModal();
            }
        },
        [slotsFinderModalState, fetchActiveItem],
    );

    const getExtraActions = useCallback(
        // eslint-disable-next-line @typescript-eslint/ban-types
        (row: Row<object>): undefined | Array<ReactNode> => {
            const rowObject = row.original as AppointmentRequestsModel;

            return [
                <IconButtonAlt key={rowObject.id} onClick={() => setActiveItem(rowObject)}>
                    <ReactSVG src={EditIcon} />
                </IconButtonAlt>,
            ];
        },
        [setActiveItem],
    );
    return (
        <>
            <AdminPanelContainer>
                <DataTable
                    title={t('waitingRoom:waiting_room_table_title')}
                    noItemsMessage={t('adminPanel:table.no_items_message')}
                    actionColumnTitle={t('adminPanel:table.columns.actions')}
                    columns={columns(t, rest.checkSchedule)}
                    previousPageText={''}
                    nextPageText={'next'}
                    setActiveItem={() => {
                        return null;
                    }}
                    breadcrumbs={
                        <>
                            <ButtonsContainer>
                                <RequestListFilter countData={rest.countData} filters={filters} />
                                <GreyOutlineButton style={{ width: 'fit-content' }} onClick={rest.selectAll}>
                                    {t('calendar:selectAllButton')}
                                </GreyOutlineButton>
                                <GreyOutlineButton style={{ width: 'fit-content' }} onClick={rest.unselectAll}>
                                    {t('calendar:unselectAllButton')}
                                </GreyOutlineButton>
                                <GreyOutlineButton
                                    disabled={rest.checkedCount == 0}
                                    style={{ width: 'fit-content' }}
                                    onClick={rest.autoFillHandler}
                                >
                                    {t('waitingRoom:find_slots_for_requests')}
                                </GreyOutlineButton>
                            </ButtonsContainer>
                        </>
                    }
                    getExtraActions={getExtraActions}
                    {...rest}
                ></DataTable>
            </AdminPanelContainer>
            {rest.activeItem && (
                <SlotsFinderModal
                    state={slotsFinderModalState}
                    activeItem={rest.activeItem}
                    reloadAppointmentsRequests={rest.reloadAppointments}
                />
            )}
            <Modal
                state={rest.autofillFinishedModalState}
                body={
                    <AutoFillInfoModal
                        isLoading={rest.autoFillLoading}
                        title={t('waitingRoom:auto_fill_modal_title')}
                        countNonChanged={rest.noChangedStatusCnt}
                        messageNonChanged={t('waitingRoom:no_changed_msg')}
                        messageChanged={t('waitingRoom:changed_msg')}
                        confirmButton={t('calendar:calendarTable:finishedInfoModalButton')}
                        onConfirmHandler={rest.onConfirmAutoFillModal}
                        countChanged={rest.changedStatusCnt}
                    />
                }
            />
        </>
    );
};
