import { useCallback } from 'react';
import { LoadedOptionsPage } from 'medrefer-web-sdk/web-kit';
import { GeneralSearchOption } from 'components/PublicSearch/SlotBooking/ServiceSearchForm/ServiceSearchForm';
import { GeneralSearchItem, GeneralSearchItemType } from 'medrefer-web-sdk/api/models';
import { api } from 'medrefer-web-sdk/api';
import { getFullName } from 'medrefer-web-sdk/api/utils';
import { translateGeneralSearchItemType } from 'utils/i18n';

const useGeneralSearchOptions = () => {
    const loadGeneralSearchOptions = useCallback(
        async (search: string): Promise<LoadedOptionsPage<GeneralSearchOption>> => {
            const res = await api.generalSearch({
                search: search,
            });

            const formatLabel = (item: GeneralSearchItem) => {
                switch (item.type) {
                    case GeneralSearchItemType.healthcare_service_group:
                        return (item as GeneralSearchItem<GeneralSearchItemType.healthcare_service_group>).object.name;
                    case GeneralSearchItemType.healthcare_service:
                        return (item as GeneralSearchItem<GeneralSearchItemType.healthcare_service>).object.name;
                    case GeneralSearchItemType.practitioner:
                        return getFullName((item as GeneralSearchItem<GeneralSearchItemType.practitioner>).object);
                    case GeneralSearchItemType.appointment_reason:
                        return (item as GeneralSearchItem<GeneralSearchItemType.appointment_reason>).object.name;
                }
            };

            const getValue = (item: GeneralSearchItem) => {
                switch (item.type) {
                    case GeneralSearchItemType.healthcare_service:
                        const service = item as GeneralSearchItem<GeneralSearchItemType.healthcare_service>;
                        return service.object.key;
                    case GeneralSearchItemType.healthcare_service_group:
                        const group = item as GeneralSearchItem<GeneralSearchItemType.healthcare_service>;
                        return group.object.key;
                }
                return item.object.id.toString();
            };
            //show only hs and hs groups
            res.results = res.results.filter((x) => x[0].type !== GeneralSearchItemType.practitioner);
            const groupedOptions = res.results.map((optionsGroup: GeneralSearchItem[]) => {
                return {
                    label: translateGeneralSearchItemType(optionsGroup[0].type),
                    options: optionsGroup.map((optionObject) => ({
                        value: getValue(optionObject),
                        label: formatLabel(optionObject),
                        type: optionObject.type,
                        id: optionObject.object.id,
                    })),
                };
            });
            const groupedOptionsFiltered: {
                label: string;
                options: { value: string; label: string; type: GeneralSearchItemType; id: number }[];
            }[] = [];
            const items = groupedOptions.find((grp) => grp.label == 'group')?.options;
            if (items) {
                const hsOption = groupedOptions.find(
                    (grp) => grp.label == translateGeneralSearchItemType(GeneralSearchItemType.healthcare_service),
                );
                if (hsOption) {
                    hsOption.options.push(...items);
                } else {
                    groupedOptions.unshift({
                        label: translateGeneralSearchItemType(GeneralSearchItemType.healthcare_service),
                        options: items,
                    });
                }
            }
            for (let i = 0; i < groupedOptions.length; i++) {
                if (groupedOptions[i].label != 'group') {
                    groupedOptionsFiltered.push(groupedOptions[i]);
                }
            }
            return {
                options: groupedOptionsFiltered,
                hasMore: false,
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return {
        loadGeneralSearchOptions,
    };
};

export default useGeneralSearchOptions;
