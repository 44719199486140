import { UserPatientData } from './UserPatientData';

export interface PatientRegistrationData {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    personalTitle: string;
    phone: string;
    token: string;
    patient: UserPatientData;
}

export enum RegistrationStep {
    base,
    otpCode,
}
