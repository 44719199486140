import { NoSlotsBehaviour, ProfileImage, Language } from './index';
import { EmailText } from '../../../components/SuperAdminPanel/ManageOrganizations/useEmailText';

export interface Organization {
    id: number;
    name: string;
}

export interface OrganizationProfile extends Organization {
    description: string;
    slug: string;
    branding: Branding;
    locations_count: number | undefined;
    features: Feature[];
    no_slots_behaviour: NoSlotsBehaviour | null;
    operating_countries: string[];
    supported_languages: Language[];
    fields: string[];
    mandatory_fields: string[];
    insurance_number_field_mandatory: boolean;
    insurance_number_field_visibility: boolean;
    phone_number_field_mandatory: boolean;
    phone_number_field_visibility: boolean;
    insurance_type_field_visibility: boolean;
    insurance_type_field_mandatory: boolean;
    address_field_visibility: boolean;
    address_field_mandatory: boolean;
}

export interface AdminOrganization extends Organization {
    description: string;
    slug: string;
    branding: Branding;
    features: Feature[];
    no_slots_behaviour: NoSlotsBehaviour | null;
    operating_countries: string[];
    supported_languages: Language[];
    uses_custom_auth: boolean;
    uses_custom_notifications: boolean;
    appointment_email_subscriptions: string[];
    fields: string[];
    mandatory_fields: string[];
    insurance_number_field_mandatory: boolean;
    insurance_number_field_visibility: boolean;
    phone_number_field_mandatory: boolean;
    phone_number_field_visibility: boolean;
    insurance_type_field_visibility: boolean;
    insurance_type_field_mandatory: boolean;
    address_field_visibility: boolean;
    address_field_mandatory: boolean;
    email_text: EmailText[];
}

export interface Branding {
    logo: ProfileImage | null;
    accent_color: string;
}

export enum Feature {
    videoCalls = 'VIDEO_CALLS',
    questionnaires = 'QUESTIONNAIRES',
    appointmentPricing = 'APPOINTMENT_PRICING',
    stripePayments = 'STRIPE_PAYMENTS',
}
