import { useCallback, useRef, useState } from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { LocationData, LocationManagementData, LocationParams } from 'medrefer-web-sdk/api/models';
import { api } from 'medrefer-web-sdk/api';
import { useModalState, useTableOrdering } from 'medrefer-web-sdk/web-kit';
import { errorTimeout } from 'utils/constants';

export interface TableLocationData extends Omit<LocationData, 'address'> {
    address: string;
}

const formatData = (data: LocationData[]): TableLocationData[] => {
    return data.map((location) => {
        return {
            id: location.id,
            name: location.name,
            description: location.description,
            address: `${location.address.text}, ${location.address.city}, ${location.address.district}, ${location.address.state}`,
            position: location.position,
        };
    });
};

export const useManageLocations = () => {
    const { ordering, sortHandler } = useTableOrdering(['-id']);
    const [activeItem, setActiveItem] = useState<LocationData | null>(null);
    const [tableParams, setTableParams] = useState<{ pageIndex: number; pageSize: number; searchPhrase: string }>({
        pageIndex: 0,
        pageSize: 0,
        searchPhrase: '',
    });

    const [data, setData] = useState<TableLocationData[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const requestLoadMembers = useApiRequest();
    const request = useApiRequest({ errorTimeout });
    const addModalState = useModalState();
    const deleteModalState = useModalState({ actionOnClose: () => setActiveItem(null) });
    const editModalState = useModalState({ actionOnClose: () => setActiveItem(null) });

    const fetchIdRef = useRef(0);

    const fetchData = useCallback(
        (pageIndex, pageSize, searchPhrase) => {
            const fetchId = ++fetchIdRef.current;
            setTableParams({ pageIndex, pageSize, searchPhrase });
            if (fetchId === fetchIdRef.current) {
                const params: LocationParams = {
                    offset: pageSize * pageIndex,
                    limit: pageSize,
                    search: searchPhrase,
                    ordering: ordering.join(','),
                };

                requestLoadMembers.dispatch(api.getServiceLocations(params)).then((res) => {
                    setPageCount(Math.ceil(res.count / pageSize));
                    setData(formatData(res.results));
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ordering],
    );

    /**
     * Location actions.
     */
    const reloadData = () => {
        fetchData(tableParams.pageIndex, tableParams.pageSize, tableParams.searchPhrase);
    };

    const fetchActiveItem = (item: TableLocationData) => {
        if (activeItem && item.id === activeItem.id) {
            return;
        }
        if (activeItem && item.id !== activeItem.id) {
            setActiveItem(null);
        }
        request.dispatch(api.getLocation(item.id)).then((location) => {
            setActiveItem(location);
        });
    };

    const createLocation = (values: LocationManagementData) => {
        request.dispatch(api.createLocation(values)).then(() => {
            addModalState.toggleModal();
            reloadData();
        });
    };
    const updateLocation = (values: LocationManagementData) => {
        if (!activeItem) return;

        const editLocationData = {
            ...values,
            address: {
                ...values.address,
                id: activeItem.address.id,
            },
        };

        request.dispatch(api.updateLocation(activeItem.id, editLocationData)).then(() => {
            editModalState.toggleModal();
            reloadData();
        });
    };

    const deleteLocation = () => {
        if (!activeItem) return;
        request.dispatch(api.deleteLocation(activeItem.id)).then(() => {
            deleteModalState.toggleModal();
            reloadData();
        });
    };

    return {
        createLocation,
        updateLocation,
        deleteLocation,
        isLoading: requestLoadMembers.isLoading,
        request,
        activeItem,
        fetchActiveItem,
        data,
        pageCount,
        fetchData,
        addModalState,
        editModalState,
        deleteModalState,
        ordering,
        sortHandler,
    };
};
