import React from 'react';
import { CalendarHook } from 'components/shared/ServiceSlots/SlotsCalendar/SlotsCalendar.hooks';
import { useTranslation } from 'react-i18next';
import { EmptyPlaceholder } from 'components/shared/ServiceSlots/CalendarTimeline/CalendarTimeline.styled';
import { NearestDateNavigator } from 'components/shared/ServiceSlots/CalendarTimeline/NearestDateNavigator';

export const EmptyCalendar = ({ calendar }: { calendar: CalendarHook }) => {
    const { t } = useTranslation();

    if (calendar.nearestSlotDate) {
        return <NearestDateNavigator date={calendar.nearestSlotDate} onClick={calendar.goNearest} />;
    }

    return <EmptyPlaceholder>{t('publicSearch:calendar_empty')}</EmptyPlaceholder>;
};
