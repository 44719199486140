import { CalendarType } from 'components/Calendar/CalendarView/CalendarView.types';
import { useRef } from 'react';

export type ControlRefObject = {
    handleDateChange: (date: Date) => void;
    handleCalendarTypeChange: (calendarType: CalendarType) => void;
};

export const useControlRef = () => {
    return useRef<ControlRefObject>({
        handleDateChange: () => {
            return undefined;
        },
        handleCalendarTypeChange: () => {
            return undefined;
        },
    });
};
