import React from 'react';
import { Button, Collapse } from 'react-bootstrap';
import ArrowUpIcon from 'medrefer-web-sdk/web-kit/assets/icons/ArrowUpIcon.png';
import ArrowDownIcon from 'medrefer-web-sdk/web-kit/assets/icons/ArrowDownIcon.png';
import { ScheduleType } from 'components/Calendar/Calendar.types';
import { useCalendarFilters } from 'components/Calendar/hooks/useCalendarFilters';
import { useTranslation } from 'react-i18next';
import { BaseHealthcareService } from 'medrefer-web-sdk/api/models';
import { ICalendarInfo } from 'tui-calendar';
import { useOrganizationLocations } from 'components/Calendar/hooks/useOrganizationLocations';

interface Props {
    activeServices: BaseHealthcareService[];
    setActiveServices: (services: BaseHealthcareService[]) => void;
    setActiveScheduleTypes: (types: string[]) => void;
    activeScheduleTypes: string[];
    calendars: ICalendarInfo[];
}

export const CalendarServiceFilters = ({
    activeServices,
    setActiveServices,
    setActiveScheduleTypes,
    activeScheduleTypes,
    calendars,
}: Props) => {
    const { t } = useTranslation();
    const organizationLocations = useOrganizationLocations();
    const {
        locationFilters,
        isScheduleTypeFilterOpen,
        setIsScheduleTypeFilterOpen,
        handleLocationDrawerClick,
        handleServiceCheckboxClick,
        handleScheduleTypeCheckboxClick,
    } = useCalendarFilters({
        activeServices,
        setActiveServices,
        setActiveScheduleTypes,
        activeScheduleTypes,
    });
    const scheduleTypeLabels = {
        [ScheduleType.slot]: t('calendar:servicesFilters:slot_type'),
        [ScheduleType.appointment]: t('calendar:servicesFilters:appointment_type'),
    };

    return (
        <>
            <div className="services">
                <Button
                    onClick={() => setIsScheduleTypeFilterOpen(!isScheduleTypeFilterOpen)}
                    aria-expanded={isScheduleTypeFilterOpen}
                >
                    {t('calendar:servicesFilters.my_schedule_type')}
                    <img src={isScheduleTypeFilterOpen ? ArrowUpIcon : ArrowDownIcon} alt="arrow up/down icon" />
                </Button>
                <Collapse in={isScheduleTypeFilterOpen}>
                    <div>
                        {Object.keys(ScheduleType).map((type) => (
                            <div
                                key={type}
                                className="service"
                                onClick={() => {
                                    handleScheduleTypeCheckboxClick(type);
                                }}
                            >
                                <input
                                    type="checkbox"
                                    style={{ backgroundColor: '#333' }}
                                    name={type}
                                    checked={activeScheduleTypes.indexOf(type) !== -1}
                                    readOnly
                                />
                                <label>{scheduleTypeLabels[type as ScheduleType]}</label>
                            </div>
                        ))}
                    </div>
                </Collapse>
            </div>
            {locationFilters.map((locationFilter) => {
                const location = organizationLocations.find((x) => x.id === locationFilter.locationId);

                return (
                    <div className="services" key={'locationFilters' + location?.id}>
                        <input
                            type="checkbox"
                            style={{ backgroundColor: '#333' }}
                            className={'location-checkbox'}
                            name={location?.name}
                            onClick={() => handleLocationDrawerClick(location?.id, 'isActive')}
                            checked={locationFilter.isActive}
                            readOnly
                        />
                        <Button
                            onClick={() => handleLocationDrawerClick(location?.id, 'isOpen')}
                            className="location"
                            aria-expanded={locationFilter.isOpen}
                        >
                            {location?.name}
                            <img src={locationFilter.isOpen ? ArrowUpIcon : ArrowDownIcon} alt="arrow up/down icon" />
                        </Button>
                        <Collapse in={locationFilter.isOpen}>
                            <div>
                                {location?.service_set.map((service) => {
                                    const currentCalendar = calendars.find((x) => x.id === service.id.toString());
                                    return (
                                        <div
                                            key={service.name}
                                            className="service location"
                                            onClick={() => {
                                                handleServiceCheckboxClick(service);
                                            }}
                                        >
                                            <input
                                                type="checkbox"
                                                style={{
                                                    backgroundColor: currentCalendar?.bgColor,
                                                }}
                                                name={service.id.toString()}
                                                checked={activeServices.map((x) => x.id).indexOf(service.id) !== -1}
                                                readOnly
                                            />
                                            <label>{service.name}</label>
                                        </div>
                                    );
                                })}
                            </div>
                        </Collapse>
                    </div>
                );
            })}
        </>
    );
};
