import React, { FC } from 'react';
import { ConfirmModal, ModalState } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';

interface CancelAppointmentProps {
    modalState: ModalState;
    cancelAppointment: () => void;
    isLoading: boolean;
    onBeforeClose?: () => void;
}

export const CancelAppointment: FC<CancelAppointmentProps> = ({
    modalState,
    cancelAppointment,
    isLoading,
    onBeforeClose,
}: CancelAppointmentProps) => {
    const { t } = useTranslation();

    const toggleModal = () => {
        onBeforeClose && onBeforeClose();
        modalState.toggleModal();
    };

    return (
        <ConfirmModal
            state={{
                ...modalState,
                toggleModal,
            }}
            title={t('patientPortal:myAppointments.cancelAppointmentModalBody.title')}
            message={t('patientPortal:myAppointments.cancelAppointmentModalBody.message')}
            confirmButton={t('patientPortal:myAppointments.cancelAppointmentModalBody.accept_btn_message')}
            declineButton={t('patientPortal:myAppointments.cancelAppointmentModalBody.decline_btn_message')}
            onConfirm={cancelAppointment}
            onDecline={toggleModal}
            isConfirmLoading={isLoading}
        />
    );
};
