import { useCallback, useRef, useState } from 'react';
import { api } from 'medrefer-web-sdk/api';
import useApiRequest from 'hooks/useApiRequest';
import { AdminOrganization, AdminOrganizationFormData, PaginatedDataParams } from 'medrefer-web-sdk/api/models';
import { useModalState, useTableOrdering } from 'medrefer-web-sdk/web-kit';
import { errorTimeout } from 'utils/constants';

export const useManageOrganizations = () => {
    const { ordering, sortHandler } = useTableOrdering(['name']);
    const [tableParams, setTableParams] = useState<{ pageIndex: number; pageSize: number; searchPhrase: string }>({
        pageIndex: 0,
        pageSize: 0,
        searchPhrase: '',
    });
    const [pageCount, setPageCount] = useState(0);
    const requestLoadOrganizations = useApiRequest();
    const request = useApiRequest({ errorTimeout });
    const [data, setData] = useState<AdminOrganization[]>([]);
    const fetchIdRef = useRef(0);
    const addModalState = useModalState();
    const editModalState = useModalState();
    const [activeItem, setActiveItem] = useState<AdminOrganization | null>(null);

    const fetchData = useCallback(
        (pageIndex, pageSize, searchPhrase) => {
            const fetchId = ++fetchIdRef.current;
            setTableParams({ pageIndex, pageSize, searchPhrase });
            if (fetchId === fetchIdRef.current) {
                const params: PaginatedDataParams = {
                    offset: pageSize * pageIndex,
                    limit: pageSize,
                    search: searchPhrase,
                    ordering: ordering.join(','),
                };

                requestLoadOrganizations.dispatch(api.adminListOrganizations(params)).then((res) => {
                    setPageCount(Math.ceil(res.count / pageSize));
                    setData(res.results);
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ordering],
    );

    const reloadData = () => {
        fetchData(tableParams.pageIndex, tableParams.pageSize, tableParams.searchPhrase);
    };

    const editOrganization = (formValues: AdminOrganizationFormData) => {
        if (!activeItem) return;

        request.dispatch(api.adminEditOrganization(activeItem.id, formValues)).then(() => {
            reloadData();
            editModalState.toggleModal();
        });
    };

    const createOrganization = (formValues: AdminOrganizationFormData) => {
        request.dispatch(api.adminCreateOrganization(formValues)).then(() => {
            reloadData();
            addModalState.toggleModal();
        });
    };

    return {
        isLoading: requestLoadOrganizations.isLoading,
        tableParams,
        pageCount,
        ordering,
        sortHandler,
        data,
        fetchData,
        addModalState,
        editModalState,
        activeItem,
        setActiveItem,
        request,
        editOrganization,
        createOrganization,
    };
};
