import { useCallback, useEffect, useRef, useState } from 'react';

const usePopoverMenu = () => {
    const [isOpen, setOpen] = useState(false);
    // eslint-disable-next-line  @typescript-eslint/no-explicit-any
    const menuRef: any = useRef(null);

    const handleToggle = useCallback(() => {
        setOpen((isOpen) => !isOpen);
    }, [setOpen]);

    const handleClick = useCallback(
        (e: MouseEvent) => {
            if (
                isOpen &&
                e.target instanceof HTMLElement &&
                !(menuRef.current && (menuRef.current as HTMLElement)).contains(e.target)
            ) {
                handleToggle();
            }
        },
        [isOpen, menuRef, handleToggle],
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false);
        return () => {
            document.removeEventListener('mousedown', handleClick, false);
        };
    }, [handleClick]);

    return {
        isOpen,
        handleToggle,
        menuRef,
    };
};

export default usePopoverMenu;
