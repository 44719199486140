import i18n, { Resource, ResourceLanguage } from 'i18next';
import * as path from 'path';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { api } from 'medrefer-web-sdk/api';
import { settings } from 'utils/settings';
import { registerLocale } from 'react-datepicker';
import moment from 'moment';

import 'moment/locale/de';
import 'moment/locale/pl';
import fnsEn from 'date-fns/locale/en-GB';
import fnsDe from 'date-fns/locale/de';
import fnsPl from 'date-fns/locale/pl';

const fnsLocales = {
    en: fnsEn,
    de: fnsDe,
    pl: fnsPl,
};

const enContext = require.context('./locales/en', false);
const deContext = require.context('./locales/de', false);
const plContext = require.context('./locales/pl', false);

const languageContextMapper = (
    fileName: string,
    langResource: ResourceLanguage,
    langContext: __WebpackModuleApi.RequireContext,
) => {
    if (fileName.endsWith('.json')) {
        const locale: string = path.basename(fileName, '.json');
        langResource[locale] = langContext(fileName);
    }
};

const getResource = (): Resource => {
    const en: ResourceLanguage = {};
    const de: ResourceLanguage = {};
    const pl: ResourceLanguage = {};

    enContext.keys().forEach((filename: string) => languageContextMapper(filename, en, enContext));
    deContext.keys().forEach((filename: string) => languageContextMapper(filename, de, deContext));
    plContext.keys().forEach((filename: string) => languageContextMapper(filename, pl, plContext));

    return {
        en,
        de,
        pl,
    };
};

i18n.on('languageChanged', (locale) => {
    registerLocale(locale, fnsLocales[locale as keyof typeof fnsLocales]);
    moment.locale(locale);
    api.setLanguage(locale);
});

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        detection: {
            caches: ['localStorage', 'cookie'],
        },
        fallbackLng: settings.i18n.fallbackLng,
        supportedLngs: settings.i18n.supportedLngs,
        load: 'languageOnly',
        nonExplicitSupportedLngs: true,
        interpolation: {
            escapeValue: false,
        },
        resources: getResource(),
        defaultNS: 'global',
        returnEmptyString: true,
    });

export default i18n;
