import React, { ReactElement } from 'react';
import { Row, Accordion } from 'react-bootstrap';
import { ContextAwareToggle } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { LanguageSwitch } from './LanguageSwitch/LanguageSwitch';
import { SettingsContainter, SettingsContentContainer } from './Settings.styled';

export function Settings(): ReactElement {
    const { t } = useTranslation();

    return (
        <div>
            <SettingsContainter className="pl-md-5 pr-md-5">
                <div className="w-full d-flex justify-content-center mt-md-5">
                    <SettingsContentContainer>
                        <Accordion defaultActiveKey="0">
                            <Row>
                                <ContextAwareToggle eventKey="0">
                                    {t('settingsDisplay:title_language_switch')}
                                </ContextAwareToggle>
                            </Row>
                            <Accordion.Collapse eventKey="0">
                                <LanguageSwitch />
                            </Accordion.Collapse>
                        </Accordion>
                    </SettingsContentContainer>
                </div>
            </SettingsContainter>
        </div>
    );
}
