import { idNameToOption, Option } from 'medrefer-web-sdk/web-kit';
import { api } from 'medrefer-web-sdk/api';
import { HealthcareService, BaseHealthcareService } from 'medrefer-web-sdk/api/models';

const useOrganizationServiceOptions = () => {
    const toOrganizationServiceOption = (category: HealthcareService | BaseHealthcareService) =>
        idNameToOption(category);

    const loadOrganizationServiceOptions = async (search: string, loadedOptions: Option[]) => {
        const res = await api.getHealthcareServices({
            limit: 10,
            offset: loadedOptions.length,
            search,
            is_active: true,
        });

        return {
            options: res.results.map(toOrganizationServiceOption),
            hasMore: res.next != null,
        };
    };

    const recoverOrganizationServiceOptions = async (missingKeys: string[]) => {
        const res = await api.getHealthcareServices({
            id__in: missingKeys.join(','),
        });
        return res.results.map(toOrganizationServiceOption);
    };

    return {
        toOrganizationServiceOption,
        loadOrganizationServiceOptions,
        recoverOrganizationServiceOptions,
    };
};

export default useOrganizationServiceOptions;
