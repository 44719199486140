import React, { FC, useMemo } from 'react';
import { StyledBadge } from 'components/Dashboard/StatusBadge/StatusBadge.styled';
import { AppointmentModel, AppointmentStatus } from 'medrefer-web-sdk/api/models';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

export enum BookingStatus {
    upcoming = 'UPCOMING',
    past = 'PAST',
    cancelled = 'CANCELLED',
}

export interface Props {
    appointment: AppointmentModel;
}

export const StatusBadge: FC<Props> = ({ appointment }: Props) => {
    const { t } = useTranslation();

    const state = useMemo(() => {
        const status = appointment.status;
        const dateFrom = new Date(appointment.date_from);

        if (status === AppointmentStatus.CANCELLED) {
            return BookingStatus.cancelled;
        }

        if (moment(dateFrom).isAfter(new Date())) {
            return BookingStatus.upcoming;
        }

        return BookingStatus.past;
    }, [appointment]);

    let badgeText = 'Old value in database';

    switch (state) {
        case BookingStatus.upcoming:
            badgeText = t('statusBadge:upcoming');
            break;
        case BookingStatus.past:
            badgeText = t('statusBadge:past');
            break;
        case BookingStatus.cancelled:
            badgeText = t('statusBadge:cancelled');
            break;
    }

    return <StyledBadge type={state}>{badgeText}</StyledBadge>;
};
