import styled from 'styled-components';

interface RadioTileProps {
    isActive?: boolean;
}

export const RadioTileStyled = styled.label<RadioTileProps>`
    border-radius: 8px;
    width: 100%;
    height: 100%;
    padding: 15px 0 15px 36px;
    position: relative;
    font-size: 14px !important;
    font-weight: 400 !important;
    background: ${(props) => (props.isActive ? props.theme.purple50 : props.theme.semiWhite)};
    border: ${(props) => (props.isActive ? `${props.theme.accent}` : 'transparent')} 1px solid;
    cursor: default;
    transition: all 0.1s ease;
    z-index: 100000;
    margin-bottom: 0;

    &:after {
        box-sizing: border-box;
        content: '';
        position: absolute;
        top: 16px;
        left: 12px;
        width: 16px;
        height: 16px;
        border: ${(props) => (props.isActive ? `6px solid ${props.theme.accent} !important` : '1px solid #DBDCEE')};
        border-radius: 50%;
    }

    &:hover {
        &:after {
            padding: 2px;
            width: 6px;
            height: 6px;
            border: 6px solid ${({ theme }) => theme.accent};
        }

        &.disabled {
            &:after {
                width: 16px;
                height: 16px;
                border: 1px solid #dbdcee !important;
            }
        }
    }
`;
