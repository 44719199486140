import { idNameToOption, LoadedOptionsPage, Option } from 'medrefer-web-sdk/web-kit';
import { api } from 'medrefer-web-sdk/api';
import { LocationData } from 'medrefer-web-sdk/api/models';

const useServiceLocationOptions = () => {
    const toServiceLocationOption = (location: LocationData) => idNameToOption(location);

    const loadServiceLocationOptions = async (search: string, loadedOptions: Option[]): Promise<LoadedOptionsPage> => {
        const res = await api.getServiceLocations({
            limit: 10,
            offset: loadedOptions.length,
            search,
        });

        return {
            options: res.results.map(toServiceLocationOption),
            hasMore: res.next != null,
        };
    };

    const recoverServiceLocationOptions = async (missingKeys: string[]) => {
        const res = await api.getServiceLocations({
            id__in: missingKeys.join(','),
        });
        return res.results.map(toServiceLocationOption);
    };

    return {
        toServiceLocationOption,
        loadServiceLocationOptions,
        recoverServiceLocationOptions,
    };
};

export default useServiceLocationOptions;
