import * as Yup from 'yup';
import { UserProfile, UserUpdateData } from 'medrefer-web-sdk/api/models';
import { WithTranslation } from 'react-i18next';
import { ApiRequest } from 'hooks/useApiRequest';

export interface UserProfileEditFormData extends UserUpdateData {
    fields: string[];
    mandatory_fields: string[];
}

export interface FormProps extends WithTranslation {
    authUser: UserProfile;
    updateUserData: (userData: UserUpdateData) => void;
    request: ApiRequest;
    fields: string[];
    mandatory_fields: string[];
}

export const mapPropsToValues = ({ authUser, fields, mandatory_fields }: FormProps): UserProfileEditFormData => {
    const { patient } = authUser;

    return {
        firstName: authUser.first_name,
        lastName: authUser.last_name,
        phone: authUser.phone,
        personalTitle: authUser.personal_title,
        role: authUser.role,
        dateOfBirth: patient?.date_of_birth ? new Date(patient?.date_of_birth) : null,
        postCode: patient ? patient.post_code : '',
        city: patient ? patient.city : '',
        street: patient ? patient.street : '',
        insuranceNumber: patient ? patient.insurance_number : '',
        insuranceCompany: patient ? patient.insurance_company : undefined,
        insuranceStatus: patient ? patient.insurance_status : undefined,
        smsAgreement: patient ? patient.sms_agreement : false,
        emailAgreement: patient ? patient.email_agreement : false,
        fields: fields,
        mandatory_fields: mandatory_fields,
    };
};

export const validationSchema = ({ t }: FormProps) =>
    Yup.object().shape({
        firstName: Yup.string()
            .matchesName(t('editProfileForm:warning_first_name_matches'))
            .required(t('editProfileForm:warning_first_name_required')),
        lastName: Yup.string()
            .matchesName(t('editProfileForm:warning_last_name_matches'))
            .required(t('editProfileForm:warning_last_name_required')),
        phone: Yup.string().when(['mandatory_fields', 'fields'], {
            is: (mandatory_fields, fields) => mandatory_fields.includes('phone') || fields.includes('phone'),
            then: Yup.string()
                .matchesPhone(t('newAppointmentForm:warning_phone_matches'))
                .when(['mandatory_fields'], {
                    is: (mandatory_fields) => mandatory_fields.includes('phone'),
                    then: Yup.string().required(t('newAppointmentForm:warning_phone_required')),
                }),
        }),
        insuranceStatus: Yup.string().when('mandatory_fields', {
            is: (mandatory_fields) => mandatory_fields.includes('insurance_status'),
            then: Yup.string().required(t('newAppointmentForm:warning_insurance_status_required')),
        }),
        insuranceNumber: Yup.string().when('mandatory_fields', {
            is: (mandatory_fields) => mandatory_fields.includes('insurance_number'),
            then: Yup.string().required(t('newAppointmentForm:warning_insurance_number_required')),
        }),
        city: Yup.string().when('mandatory_fields', {
            is: (mandatory_fields) => mandatory_fields.includes('address'),
            then: Yup.string().required(t('newAppointmentForm:warning_address_required')),
        }),
        street: Yup.string().when('mandatory_fields', {
            is: (mandatory_fields) => mandatory_fields.includes('address'),
            then: Yup.string().required(t('newAppointmentForm:warning_address_required')),
        }),
    });
