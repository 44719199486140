import React, { FC } from 'react';
import { DataTable } from 'components/shared/DataTable';
import { AdminPanelContainer, FormActivityIndicatorContainer } from 'components/AdminPanel/AdminPanel.styled';

import { columns } from './ManageMembers.consts';
import { useManageMembers } from './hooks/useManageMembers';
import { MembersForm } from './MembersForm';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';
import { ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import InviteMember from 'components/AdminPanel/ManageMembers/InviteMember';
import { useTranslation } from 'react-i18next';
import { RouteBreadcrumbs } from 'components/shared/RouteBreadcrumbs';

export const ManageMembers: FC = () => {
    const { t } = useTranslation();

    const {
        addMember,
        editMember,
        request,
        activeItem,
        fetchActiveItem,
        isInvitationSent,
        resendEmailInvitation,
        requestInvitation,
        ...rest
    } = useManageMembers();
    return (
        <>
            <AdminPanelContainer>
                <DataTable
                    title={t('adminPanel:manageMembers.title')}
                    actionColumnTitle={t('adminPanel:table.columns.actions')}
                    noItemsMessage={t('adminPanel:table.no_items_message')}
                    columns={columns(t)}
                    addItemForm={<InviteMember request={request} handleSubmit={addMember} />}
                    editItemForm={
                        activeItem ? (
                            <MembersForm
                                activeItem={activeItem}
                                type={ModalType.edit}
                                request={request}
                                handleSubmit={editMember}
                                isInvitationSent={isInvitationSent}
                                resendEmailInvitation={resendEmailInvitation}
                                requestInvitation={requestInvitation}
                            />
                        ) : (
                            <FormActivityIndicatorContainer>
                                <ActivityIndicator />
                            </FormActivityIndicatorContainer>
                        )
                    }
                    setActiveItem={fetchActiveItem}
                    addButtonText={t('adminPanel:table.controls.addButtonText')}
                    previousPageText={t('adminPanel:table.controls.previousPageText')}
                    nextPageText={t('adminPanel:table.controls.nextPageText')}
                    breadcrumbs={<RouteBreadcrumbs />}
                    {...rest}
                />
            </AdminPanelContainer>
        </>
    );
};
