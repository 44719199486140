import { useEffect, useRef, DependencyList } from 'react';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const useNonInitialEffect = (effect: () => any, deps: DependencyList) => {
    const initialRender = useRef(true);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        let effectReturns = () => {};

        if (initialRender.current) {
            initialRender.current = false;
        } else {
            effectReturns = effect();
        }

        if (effectReturns && typeof effectReturns === 'function') {
            return effectReturns;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
