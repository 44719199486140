import React from 'react';
import { Column } from 'react-table';
import { StatusBadge } from './StatusBadge/StatusBadge';
import { getFullName } from '../../medrefer-web-sdk/api/utils';
import {
    AppointmentRequestsModel,
    AppointmentRequestTime,
} from '../../medrefer-web-sdk/api/models/AppointmentRequestsModel';
import { DayCircle, DaySelectorContainer } from './DayCircles/DayCircles.styled';
import { Weekday } from '../../medrefer-web-sdk/api/models';
import { CheckBox } from '../../medrefer-web-sdk/web-kit';

export const columns: (
    t: (key: string) => string,
    checkSchedule: (id: number) => void,
) => Column<AppointmentRequestsModel>[] = (t, checkSchedule) => [
    {
        Header: ' ',
        accessor: (appointment) => {
            return (
                <>
                    <CheckBox
                        style={{ marginBottom: '15px' }}
                        checked={appointment.isChecked}
                        onChange={() => {
                            if (appointment.id) {
                                checkSchedule(appointment.id);
                            }
                        }}
                    ></CheckBox>
                </>
            );
        },
        width: 50,
    },
    {
        Header: t('adminPanel:table.columns.id'),
        accessor: 'id',
        sortParam: 'id',
        width: 50,
    },
    {
        Header: t('adminPanel:table.columns.status'),
        accessor: (appointment) => <StatusBadge appointment={appointment} />,
        width: 70,
        sortParam: 'status',
    },
    {
        Header: t('adminPanel:table.columns.full_name'),
        accessor: (appointment) => <span>{getFullName(appointment.patient_metadata)}</span>,
        sortParam: 'full_name',
        width: 100,
    },
    {
        Header: t('waitingRoom:available_time_column'),
        accessor: (appointment) => {
            const dayOptions = [
                { label: t('global:daynamesOne.monday'), value: Weekday.MO },
                { label: t('global:daynamesOne.tuesday'), value: Weekday.TU },
                { label: t('global:daynamesOne.wednesday'), value: Weekday.WE },
                { label: t('global:daynamesOne.thursday'), value: Weekday.TH },
                { label: t('global:daynamesOne.friday'), value: Weekday.FR },
            ];
            return (
                <DaySelectorContainer>
                    {dayOptions.map((option, key) => (
                        <DayCircle
                            key={key}
                            isAllDay={appointment.available_time[key] == AppointmentRequestTime.ALL_DAY}
                            isMorning={appointment.available_time[key] == AppointmentRequestTime.MORNING}
                            isAfternoon={appointment.available_time[key] == AppointmentRequestTime.AFTERNOON}
                            small={false}
                        >
                            {option.label}
                        </DayCircle>
                    ))}
                </DaySelectorContainer>
            );
        },
    },
    {
        Header: t('adminPanel:table.columns.service_name'),
        accessor: (schedule) => {
            return <span>{schedule.healthcare_service?.name || schedule.healthcare_service_group?.name}</span>;
        },
        sortParam: 'healthcare_service__name',
    },
    {
        Header: t('calendar:calendarTable:criteria'),
        accessor: (appointment) => {
            if (appointment.criteria && appointment.criteria.length > 0) {
                let to_show = '';
                appointment.criteria.map((appointment) => {
                    appointment.values.map((spec) => {
                        to_show += spec.name + ' ';
                    });
                });
                return to_show;
            }
        },
    },
];
