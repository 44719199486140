import { useMemo } from 'react';
import { getMedianDate } from 'medrefer-web-sdk/api/utils';
import { CalendarState } from './useCalendarState';
import { CalendarType, DateRange } from './CalendarView.types';
import { getActiveLanguage } from 'utils/i18n';

export const useCalendarFields = ({ dateRange, calendarType }: CalendarState) => {
    const getCurrentMonthName = (dateRange: DateRange, calendarType: CalendarType) => {
        if (dateRange.dateFrom.getMonth() === dateRange.dateTo.getMonth() || calendarType === CalendarType.month) {
            return getMedianDate(dateRange.dateFrom, dateRange.dateTo).toLocaleString(getActiveLanguage(), {
                month: 'long',
            });
        } else {
            const fromMonthName = dateRange.dateFrom.toLocaleString(getActiveLanguage(), {
                month: 'short',
            });

            const toMonthName = dateRange.dateTo.toLocaleString(getActiveLanguage(), {
                month: 'short',
            });

            return `${fromMonthName} - ${toMonthName}`;
        }
    };

    const getCurrentYear = (dateRange: DateRange) => {
        return getMedianDate(dateRange.dateFrom, dateRange.dateTo).getFullYear();
    };

    const getCurrentDay = (dateRange: DateRange) => {
        return getMedianDate(dateRange.dateFrom, dateRange.dateTo).getDate();
    };

    const dateFormatted = useMemo(() => {
        if (dateRange == null) {
            return '';
        }

        let result = getCurrentMonthName(dateRange, calendarType);

        if (calendarType === CalendarType.day) {
            result += ' ' + getCurrentDay(dateRange).toString() + ',';
        }

        result += ' ' + getCurrentYear(dateRange).toString();

        return result;
    }, [calendarType, dateRange]);

    // get number of past week rows on monthly calendar
    const now = new Date();
    const isCurrent = dateRange
        ? calendarType === CalendarType.day
            ? dateRange.dateFrom.getDate() === now.getDate()
            : dateRange.dateFrom.getTime() < now.getTime() && now.getTime() < dateRange.dateTo.getTime()
        : false;

    const isPast = dateRange
        ? calendarType === CalendarType.day
            ? dateRange.dateFrom.getDate() < now.getDate()
            : now.getTime() > dateRange.dateTo.getTime()
        : false;

    let pastRowsLength = 0;
    if (dateRange) {
        const daysDiff = (now.getTime() - dateRange.dateFrom.getTime()) / (1000 * 60 * 60 * 24);
        pastRowsLength = Math.floor((daysDiff - (now.getDay() - 1)) / 7);
    }

    return {
        isCurrent,
        isPast,
        pastRowsLength,
        dateFormatted,
    };
};
