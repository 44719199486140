import * as Yup from 'yup';
import { QuestionnaireFormProps } from './QuestionnaireForm.types';
import { QuestionnaireData } from 'medrefer-web-sdk/api/models';

export const mapPropsToValues = ({ activeItem }: QuestionnaireFormProps): QuestionnaireData => {
    return {
        title: activeItem ? activeItem.title : '',
        content: activeItem ? activeItem.content : '',
    };
};

export const validationSchema = ({ t }: QuestionnaireFormProps) =>
    Yup.object().shape({
        title: Yup.string().required(t('form:error.required')),
        content: Yup.string().required(t('form:error.required')),
    });
