import { AttachmentInput } from 'components/shared/AttachmentInput';
import { FormikProps } from 'formik';
import {
    Button,
    ErrorMessage,
    FormErrorMessage,
    FormGroup,
    InformationContainer,
    Input,
    Label,
    UnderlineH2 as Title,
} from 'medrefer-web-sdk/web-kit';
import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { OrganizationProfileFormProps, OrganizationProfileFormValues } from './OrganizationProfileForm.types';
import { NoSlotsBehaviourSubForm } from '../../shared/NoSlotsBehaviourSubForm';
import { AccentSquare } from 'components/shared/AccentSquare';

export const OrganizationProfileData = (
    props: OrganizationProfileFormProps & FormikProps<OrganizationProfileFormValues>,
) => {
    const { t } = useTranslation();

    const formTitle = props.activeItem
        ? t('organizationProfileForm:organization_form_update_label')
        : t('organizationProfileForm:organization_form_create_label');

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <Title>{formTitle}</Title>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:organization_name_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <Input
                            isError={!!(props.touched.name && props.errors.name)}
                            id="name"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.name}
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.name && props.errors.name && (
                    <ErrorMessage className="text-right mt-1">{props.errors.name}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:organization_description_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <Input
                            isError={!!(props.touched.description && props.errors.description)}
                            id="description"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.description}
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.description && props.errors.description && (
                    <ErrorMessage className="text-right mt-1">{props.errors.description}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:organization_image_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <AttachmentInput
                            setFieldValue={props.setFieldValue}
                            value={props.values.branding.logo}
                            name={'branding.logo'}
                        />
                    </Col>
                </Row>
                {props.touched.branding?.logo && props.errors.branding?.logo && (
                    <ErrorMessage className="text-right mt-1">{props.errors.branding?.logo}</ErrorMessage>
                )}
            </FormGroup>

            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:organization_accent_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <Row className="primary">
                            <Col xs={1} className="text-right mr-3">
                                <AccentSquare color={props.values.branding.accent_color} />
                            </Col>
                            <Col xs={10} className="text-right">
                                <Input
                                    isError={
                                        !!(props.touched.branding?.accent_color && props.errors.branding?.accent_color)
                                    }
                                    id="branding.accent_color"
                                    type="text"
                                    onChange={(e) => {
                                        props.handleChange(e);
                                    }}
                                    value={props.values.branding.accent_color}
                                    onBlur={props.handleBlur}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {props.touched.branding?.accent_color && props.errors.branding?.accent_color && (
                    <ErrorMessage className="text-right mt-1">{props.errors.branding?.accent_color}</ErrorMessage>
                )}
            </FormGroup>
            <NoSlotsBehaviourSubForm
                namespace={'no_slots_behaviour'}
                values={props.values.no_slots_behaviour}
                errors={props.errors.no_slots_behaviour}
                touched={props.touched.no_slots_behaviour}
                handleChange={props.handleChange}
            />
            <InformationContainer>
                <FormErrorMessage apiErrors={props.request.errors} skipFormikErrors />
            </InformationContainer>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" xs={12} md={6}>
                        <Button isLoading={props.request.isLoading}>{t('editProfileForm:submit_button_label')}</Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
};

export default OrganizationProfileData;
