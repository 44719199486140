import React from 'react';
import { ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import { useDispatch } from 'react-redux';
import {
    AppointmentModel,
    FhirQuestionnaireResponseStatus,
    QuestionnaireResponseData,
} from 'medrefer-web-sdk/api/models';
import { appointmentActions } from 'features/appointment';
import AttachQuestionnaireForm, { FormValues } from './AttachQuestionnaireForm';
import { useQuestionnaires } from 'features/questionnaires/hooks';

interface Props {
    appointment: AppointmentModel;
    toggleModal: () => void;
}

export const AttachQuestionnaire = ({ appointment, toggleModal }: Props) => {
    const dispatch = useDispatch();
    const questionnaires = useQuestionnaires();

    const handleSubmit = async (values: FormValues) => {
        if (values.questionnaire) {
            const dto: QuestionnaireResponseData = {
                status: FhirQuestionnaireResponseStatus.IN_PROGRESS,
                content: '',
                questionnaire: parseInt(values.questionnaire),
                notify_source: values.notifySource,
            };

            await dispatch(appointmentActions.attachQuestionnaire(appointment, dto));
            toggleModal();
        }
    };

    if (questionnaires.isLoading) {
        return <ActivityIndicator absoluteCenter />;
    }

    return <AttachQuestionnaireForm handleSubmit={handleSubmit} initialQuestionnaire={questionnaires.data[0]} />;
};
