import React, { useState, useEffect } from 'react';
import { InfiniteScroll } from '../InfiniteScroll';
import { FlatList, FlatListProps } from '../FlatList';
import { ActivityIndicator } from '../../ActivityIndicator';

interface InfiniteScrollListProps<Item = unknown> extends FlatListProps<Item> {
    version?: number;
    hasMore?: boolean;
    loadMore?: (prev: Array<Item>) => void;
    initialLoad?: boolean;
    threshold?: number;
    isReverse?: boolean;
    useWindow?: boolean;
    getScrollParent?: () => HTMLElement | null;
}

export const InfiniteScrollList = <Item,>({
    data,
    version,
    hasMore = false,
    loadMore,
    initialLoad = true,
    threshold = 250,
    isReverse,
    useWindow,
    getScrollParent,
    ...rest
}: InfiniteScrollListProps<Item>) => {
    const [lastVersion, setLastVersion] = useState<number | undefined>(undefined);

    useEffect(() => {
        if (initialLoad === true) {
            loadMoreIfNeeded();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadMoreIfNeeded = () => {
        if (loadMore && hasMore) {
            loadMore(data);
        }
    };

    const onEndReached = () => {
        if (version !== lastVersion) {
            setLastVersion(version);
            loadMoreIfNeeded();
        }
    };

    return (
        <InfiniteScroll
            loadMore={onEndReached}
            threshold={threshold}
            hasMore={hasMore}
            initialLoad={initialLoad}
            isReverse={isReverse}
            useWindow={useWindow}
            getScrollParent={getScrollParent}
        >
            <FlatList
                data={data}
                ListFooterComponent={
                    hasMore && (
                        <div className="d-flex justify-content-center pb-3 pt-3">
                            <ActivityIndicator />
                        </div>
                    )
                }
                {...rest}
            />
        </InfiniteScroll>
    );
};
