import { useDispatch, useSelector } from 'react-redux';
import { clearBrand, setBrand, setBrandData } from 'features/appointment/actions';
import { useEffect } from 'react';
import useApiRequest from './useApiRequest';
import { getAuthUser } from 'features/auth/selectors';
import { useFirstRender } from 'medrefer-web-sdk/web-kit/hooks/useFirstRender';
import useBrand from 'hooks/useBrand';

const useBrandData = () => {
    const { organization, organizationSlug } = useBrand();
    const dispatch = useDispatch();
    const request = useApiRequest();
    const authUser = useSelector(getAuthUser);
    const firstRender = useFirstRender();
    const isOrganizationLoading = firstRender || request.isLoading || organization?.slug !== organizationSlug;

    useEffect(() => {
        getBrandData(organizationSlug);
        // eslint-disable-next-line
    }, [organizationSlug]);

    const getBrandData = (organizationSlug?: string) => {
        request.dispatch(getBrandDataRequest(organizationSlug));
    };

    const getBrandDataRequest = async (organizationSlug?: string) => {
        if (!organizationSlug) {
            await dispatch(clearBrand());
        } else if (authUser?.organization && authUser.organization.slug === organizationSlug) {
            await dispatch(setBrandData(authUser.organization));
        } else {
            await dispatch(setBrand(organizationSlug));
        }
    };

    return {
        isOrganizationLoading,
    };
};

export default useBrandData;
