import { getTimeFormatted } from 'components/Calendar/helpers';
import { getMappingData } from 'components/Calendar/helpers/utils';
import {
    AppointmentModel,
    AppointmentStatus,
    BaseHealthcareService,
    OrganizationLocation,
    SlotMode,
} from 'medrefer-web-sdk/api/models';
import { ICalendarInfo, ISchedule } from 'tui-calendar';
import { CalendarType } from 'components/Calendar/CalendarView/CalendarView.types';

export const mapAppointmentsToSchedules = (
    appointments: AppointmentModel[],
    activeServices: BaseHealthcareService[],
    calendarType: string | undefined,
    calendars: ICalendarInfo[],
    organizationLocations: OrganizationLocation[],
): ISchedule[] => {
    return appointments
        .filter((appointment) => appointment.status === AppointmentStatus.BOOKED)
        .map((appointment) => {
            const { calendarId, scheduleWidth, healthCareService, scheduleLeft } = getMappingData(
                appointment,
                activeServices,
                calendars,
                organizationLocations,
            );

            return {
                calendarId: calendarId,
                category: 'time',
                isVisible: true,
                title: `${appointment.mode === SlotMode.ONLINE ? '<b class="video-call-icon"></b>' : ''} ${
                    calendarType === CalendarType.month ? getTimeFormatted(new Date(appointment.date_from)) + ' ' : ''
                }${appointment.patient_metadata.first_name[0]}. ${appointment.patient_metadata.last_name}${
                    appointment.reason.name ? ` - ${appointment.reason.name}` : appointment.reason.name
                }`.trim(),
                id: `${appointment.id}`,
                body: `${appointment.healthcare_service.name} for ${appointment.patient_metadata.full_name} at ${appointment.organization.name} Date from: ${appointment.date_from} Date to: ${appointment.date_to}`,
                start: new Date(appointment.date_from),
                end: new Date(appointment.date_to),
                isReadOnly: true,
                raw: { type: 'appointment', id: `${appointment.id}`, service_id: appointment.healthcare_service.id },
                customStyle: `${
                    calendarType === CalendarType.day && healthCareService
                        ? `width: ${scheduleWidth}%; position: absolute; left: ${scheduleLeft}% !important`
                        : ''
                }`,
                //this is done because we want to use AllDay styles on monthly calendar schedules
                isAllDay: calendarType === CalendarType.month,
            };
        });
};
