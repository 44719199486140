import React from 'react';
import styled from 'styled-components';
import { ReactNode } from 'react';
interface Props {
    href: string;
    activeClassName?: string;
    children?: ReactNode;
    target?: string;
}

export const Link = ({ children, ...rest }: Props) => {
    return <StyledNavLink {...rest}>{children}</StyledNavLink>;
};

const StyledNavLink = styled.a`
    align-items: center;
    border-radius: 3px;
    display: flex;
    justify-content: flex-start;
    padding: 8px 15px;
    position: relative;
    transition: 0.2s;
    color: #000;

    &.active {
        color: ${({ theme }) => theme.accent};

        svg {
            fill: ${({ theme }) => theme.accent};
        }
    }

    &:hover {
        color: ${({ theme }) => theme.accent};
        text-decoration: none;

        svg {
            fill: ${({ theme }) => theme.accent};
        }
    }

    span {
        display: inline-block;
        margin-left: 10px;
        white-space: nowrap;
    }
`;
