import { UserPractitioner, HealthcareServiceSearch, AppointmentReason } from 'medrefer-web-sdk/api/models';

export enum GeneralSearchItemType {
    healthcare_service_group = 'healthcare_service_group',
    healthcare_service = 'healthcare_service',
    practitioner = 'practitioner',
    appointment_reason = 'appointment_reason',
}

type GeneralSearchObjectMap = {
    [GeneralSearchItemType.healthcare_service_group]: HealthcareServiceSearch;
    [GeneralSearchItemType.healthcare_service]: HealthcareServiceSearch;
    [GeneralSearchItemType.practitioner]: UserPractitioner;
    [GeneralSearchItemType.appointment_reason]: AppointmentReason;
};

export interface GeneralSearchItem<T extends GeneralSearchItemType = GeneralSearchItemType> {
    type: T;
    object: GeneralSearchObjectMap[T];
}

export interface GeneralSearch {
    results: GeneralSearchItem[][];
}
