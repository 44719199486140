import styled from 'styled-components';

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 30px;
    margin: 10px 0;
    position: relative;
    align-items: center;
    z-index: 2;
    button {
        width: 100px;
    }
    > div {
        width: 200px;
    }
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        gap: 0;
    }
    span {
        font-size: 20px;
        font-weight: 600;
        padding-top: 4px;
    }
`;
