import React from 'react';
import { SettingsMenu } from './SettingsMenu';
import { useDispatch, useSelector } from 'react-redux';
import { routerActions } from 'connected-react-router';
import { LinksContainer, RegisterButton } from './Navigation.styled';
import { getAuthUser } from 'features/auth/selectors';
import { SideMenu } from './SideMenu';
import { Navbar } from './Navbar';
import useBrand from 'hooks/useBrand';
import { NavLink } from 'components/shared/NavLink';
import { useTranslation } from 'react-i18next';

interface NavigationProps {
    isSideMenuOpen: boolean;
    setIsSideMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    showSideMenu: boolean;
}

export const Navigation = ({ isSideMenuOpen, setIsSideMenuOpen, showSideMenu }: NavigationProps) => {
    const dispatch = useDispatch();
    const brand = useBrand();
    const authUser = useSelector(getAuthUser);
    const { t } = useTranslation();

    const handleToggleSidebar = () => {
        setIsSideMenuOpen(!isSideMenuOpen);
    };

    const onClickLogin = () => {
        dispatch(routerActions.push(brand.getPathBranded('login')));
    };

    return (
        <>
            <Navbar handleToggle={handleToggleSidebar} isSideMenuVisible={showSideMenu}>
                <LinksContainer>
                    {authUser && authUser.patient && !showSideMenu && (
                        <NavLink to={brand.getPathBranded('patientPanel')}>
                            {t('patientPortal:myAppointments.title')}
                        </NavLink>
                    )}
                    {authUser ? <SettingsMenu /> : <RegisterButton onClick={onClickLogin}>Login</RegisterButton>}
                </LinksContainer>
            </Navbar>
            {showSideMenu && <SideMenu isOpen={isSideMenuOpen} setIsOpen={setIsSideMenuOpen} />}
        </>
    );
};
