import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { PrimaryButton, H1, ValueTypography, ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useBrand from 'hooks/useBrand';
import { api } from 'medrefer-web-sdk/api';
import useApiRequest from 'hooks/useApiRequest';
import { AppointmentDetails } from 'components/shared/AppointmentDetailsModal/AppointmentDetailsModal';
import useObjectFetch from 'hooks/useObjectFetch';
import { SlotBookingProgress } from 'components/PublicSearch/SlotBookingProgress';

export const SlotBookingSuccess = () => {
    const { t } = useTranslation();
    const { getPathBranded, featureEnabled } = useBrand();
    const { appointmentId } = useParams<{ appointmentId?: string }>();
    const request = useApiRequest();
    const appointment = useObjectFetch(appointmentId, (appointmentId) =>
        request.dispatch(api.getAppointment(parseInt(appointmentId))),
    );

    return (
        <>
            {appointment && (
                <SlotBookingProgress
                    activeStep="done"
                    includePaymentStep={featureEnabled('stripePayments') && appointment.price != null}
                />
            )}
            <Wrapper>
                <H1>{t('slotBooking:success_title')}</H1>
                <ValueTypography style={{ textAlign: 'center' }}>{t('slotBooking:success_message')}</ValueTypography>
                {request.isLoading ? (
                    <ActivityIndicator />
                ) : (
                    appointment && (
                        <DetailsContainer>
                            <AppointmentDetails appointment={appointment} />
                        </DetailsContainer>
                    )
                )}
                <Col md={2} className="mt-3 mb-4">
                    <Link to={getPathBranded('patientPanel')}>
                        <PrimaryButton>{t('slotBooking:success_close_button')}</PrimaryButton>
                    </Link>
                </Col>
            </Wrapper>
        </>
    );
};

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const DetailsContainer = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
`;
