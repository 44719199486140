import React from 'react';
import { ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import styled from 'styled-components';

export const IndicatorWrapper = styled.div`
    height: calc(100vh - ${({ theme }) => theme.navbarHeight.desktop});
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const PageActivityIndicator = () => {
    return (
        <IndicatorWrapper>
            <ActivityIndicator size={150} />
        </IndicatorWrapper>
    );
};
