import styled, { css } from 'styled-components';
import { Col } from 'react-bootstrap';

export const FormColumn = styled(Col)<{ inputBottom?: boolean }>`
    ${({ inputBottom }) => (inputBottom ? `${inputBottomStyles}` : '')};

    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        margin-top: 30px;
        flex-wrap: wrap;
    }
`;

const inputBottomStyles = css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`;

export const UserFormContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
    padding: 20px;
`;
