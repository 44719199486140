import { useQuery } from 'react-query';
import { api } from 'medrefer-web-sdk/api';
import { PaginatedDataParams } from 'medrefer-web-sdk/api/models';

export const useQuestionnaires = (params: PaginatedDataParams = {}) => {
    const query = useQuery(['questionnaires', params], () => api.getQuestionnaires(params), {
        staleTime: 10 * 60 * 1000,
    });

    return {
        data: query.data?.results || [],
        isLoading: query.isLoading,
    };
};
