import React from 'react';
import { NavbarContainer, MenuToggle, NavbarLogo } from './Navbar.styled';
import { AppLogo } from 'components/shared/AppLogo/AppLogo';

interface NavbarProps {
    handleToggle?: () => void;
    children?: JSX.Element;
    isSideMenuVisible: boolean;
}

export const Navbar = ({ handleToggle, children, isSideMenuVisible }: NavbarProps) => {
    return (
        <NavbarContainer>
            {isSideMenuVisible && <MenuToggle handleOnClick={handleToggle} isActive={false} />}
            <NavbarLogo isHamburgerVisible={isSideMenuVisible}>
                <AppLogo isSidebarVisible={isSideMenuVisible} />
            </NavbarLogo>
            {children}
        </NavbarContainer>
    );
};
