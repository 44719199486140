import styled from 'styled-components';

export const ButtonContainer = styled.div`
    min-height: 70px;
`;

export const ButtonLabel = styled.div`
    font-size: 11px;
    color: ${({ theme }) => theme.grey150};
    margin-top: -5px;
    margin-left: 0;
    margin-bottom: 10px;
`;
