import React, { ReactElement, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';

import PasswordResetForm from './ResetPasswordForm';
import { pageStyles } from '../Auth.styled';

import { api } from 'medrefer-web-sdk/api';
import useApiRequest from 'hooks/useApiRequest';
import { AuthPage } from 'components/Auth/AuthPage';
import useBrand from 'hooks/useBrand';

export const ResetPassword = (): ReactElement => {
    const location = useLocation();
    const { t } = useTranslation();
    const request = useApiRequest();
    const [token, setToken] = useState('');
    const [isTokenInvalid, setIsTokenInvalid] = useState(false);
    const brand = useBrand();

    useEffect(() => {
        setToken(location.search.split('=')[1]);
    }, [location]);

    useEffect(() => {
        if (token) {
            api.checkRecoveryToken(token).catch(() => {
                setIsTokenInvalid(true);
            });
        }
    }, [token]);

    const setNewPassword = (password: string) => {
        request.dispatch(api.setUserPassword({ password, token }));
    };

    if (request.isCompleted) {
        return <Redirect to={brand.getPathBranded('login') + '?password-reset=true'} />;
    }

    return (
        <AuthPage pageTitle={t('resetPasswordPage:h1')}>
            <PasswordResetForm setNewPassword={setNewPassword} isTokenInvalid={isTokenInvalid} request={request} />
            <div className="mt-5">
                <span style={pageStyles.message}>{t('resetPasswordPage:message')}</span>
                <Link to={brand.getPathBranded('login')} style={pageStyles.message_cta}>
                    {t('resetPasswordPage:message_cta')}
                </Link>
            </div>
        </AuthPage>
    );
};
