import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Modal, ModalState } from 'medrefer-web-sdk/web-kit/components/Modal/Modal';
import { useTranslation } from 'react-i18next';
import { ServiceSlots } from 'components/shared/ServiceSlots';
import { AppointmentModel, HealthcareServiceSlots, AppointmentStatus } from 'medrefer-web-sdk/api/models';
import { api } from 'medrefer-web-sdk/api';
import useApiRequest from 'hooks/useApiRequest';
import { ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import { CenterContainer } from './RescheduleAppointment.styled';
import { H2 } from 'medrefer-web-sdk/web-kit';
import { useServiceSlotsConfig } from 'components/shared/ServiceSlots/useServiceSlotsConfig';
import { RescheduleConfirmation } from 'components/shared/RescheduleAppointment/RescheduleConfirmation';
import { PaginatedData } from 'medrefer-web-sdk/api/ApiTypes';
import useObjectFetch from 'hooks/useObjectFetch';
import { getHealthcareServiceNoSlotsBehaviour } from 'components/shared/ServiceSlots/utils';

interface RescheduleAppointmentBodyProps {
    appointmentId: number | undefined;
    rescheduleAppointment: (appointmentId: number, slotKey: string) => void;
    isReschedulingAppointment: boolean;
}

interface RescheduleAppointmentProps extends RescheduleAppointmentBodyProps {
    modalState: ModalState;
    onBeforeClose?: () => void;
    button?: ReactElement;
}

export const RescheduleAppointment: FC<RescheduleAppointmentProps> = ({
    appointmentId,
    modalState,
    onBeforeClose,
    rescheduleAppointment,
    isReschedulingAppointment,
    button,
}: RescheduleAppointmentProps) => {
    return (
        <Modal
            state={{
                ...modalState,
                toggleModal: () => {
                    onBeforeClose && onBeforeClose();
                    modalState.toggleModal();
                },
            }}
            button={button && button}
            isCentered={false}
            body={
                modalState.isOpen && (
                    <RescheduleAppointmentBody
                        appointmentId={appointmentId}
                        rescheduleAppointment={rescheduleAppointment}
                        isReschedulingAppointment={isReschedulingAppointment}
                    />
                )
            }
        />
    );
};

const RescheduleAppointmentBody = ({
    appointmentId,
    rescheduleAppointment,
    isReschedulingAppointment,
}: RescheduleAppointmentBodyProps) => {
    const { t } = useTranslation();
    const [healthcareService, setHealthcareService] = useState<HealthcareServiceSlots | null>(null);
    const appointment = useObjectFetch(appointmentId, (appointmentId: number) => {
        return request.dispatch(api.getAppointment(appointmentId));
    });
    const [slotKey, setSlotKey] = useState<string | null>(null);
    const { maxDaysRangeSize, initDateFrom, initFetchedDateTo } = useServiceSlotsConfig();
    const request = useApiRequest();

    useEffect(() => {
        if (appointment) {
            loadService(appointment).then((data) => {
                setHealthcareService(data.results[0]);
            });
        }
        setSlotKey(null);
        // eslint-disable-next-line
    }, [appointment]);

    const loadService = async (appointmentData: AppointmentModel): Promise<PaginatedData<HealthcareServiceSlots[]>> => {
        return request.dispatch(
            api.getHealthcareServiceSlots({
                healthcareServiceIds: [appointmentData.healthcare_service.id],
                slotsAfter: initDateFrom,
                slotsBefore: initFetchedDateTo,
                reschedulingAppointment: appointmentData.id,
            }),
        );
    };

    const handleSlotClick = (slotKey: string) => {
        setSlotKey(slotKey);
    };

    const clearSlotKey = () => {
        setSlotKey(null);
    };

    return (
        <>
            <H2>{t('patientPortal:myAppointments.rescheduleAppoinmtnetModalBody.title')}</H2>
            {request.isLoading && (
                <CenterContainer>
                    <ActivityIndicator />
                </CenterContainer>
            )}
            {appointment && appointment?.slot && appointment.status === AppointmentStatus.BOOKED && healthcareService && (
                <>
                    <p>{t('patientPortal:myAppointments.rescheduleAppoinmtnetModalBody.message')}</p>
                    <ServiceSlots
                        healthcareService={healthcareService}
                        initDateFrom={initDateFrom}
                        initFetchedDateFrom={initDateFrom}
                        initFetchedDateTo={initFetchedDateTo}
                        maxDaysRangeSize={maxDaysRangeSize}
                        handleSlotClick={handleSlotClick}
                        startFromNearest
                        appointment={appointment}
                        slotFilterParams={{
                            reschedulingAppointment: appointment.id,
                        }}
                        noSlotsBehaviour={getHealthcareServiceNoSlotsBehaviour(healthcareService, true)}
                    />
                </>
            )}
            {!request.isLoading && !healthcareService && (
                <CenterContainer>
                    <p>{t('patientPortal:myAppointments.rescheduleAppoinmtnetModalBody.failed_to_load_message')}</p>
                </CenterContainer>
            )}
            {appointment && !appointment.slot && (
                <p>
                    {t('patientPortal:myAppointments.rescheduleAppoinmtnetModalBody.appointment_not_booked_with_slot')}
                </p>
            )}
            {appointment && appointment.status !== AppointmentStatus.BOOKED && (
                <p>{t('patientPortal.myAppointments.rescheduleAppoinmtnetModalBody.appointment_status_not_booked')}</p>
            )}
            {slotKey && appointment && (
                <RescheduleConfirmation
                    appointment={appointment}
                    slotKey={slotKey}
                    clearSlotKey={clearSlotKey}
                    rescheduleAppointment={rescheduleAppointment}
                    isReschedulingAppointment={isReschedulingAppointment}
                />
            )}
        </>
    );
};
