import * as Yup from 'yup';
import { NoSlotsDisplay, NoSlotsAction, NoSlotsSubFormValues } from './NoSlotsBehaviourSubForm.types';
import { AdminOrganization, OrganizationProfile } from 'medrefer-web-sdk/api/models';
import { parseNoSlotsBehaviourToForm } from './NoSlotsBehaviourSubForm.helpers';
import { WithTranslation } from 'react-i18next';

export const mapPropsValuesForNoSlots = ({
    activeItem,
}: {
    activeItem?: OrganizationProfile | AdminOrganization;
}): NoSlotsSubFormValues => {
    return parseNoSlotsBehaviourToForm(activeItem?.no_slots_behaviour);
};

export const validationSchemaForNoSlots = ({ t }: WithTranslation) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return Yup.lazy((value: any) => {
        const values = value as NoSlotsSubFormValues;
        if (values.displayCalendar === NoSlotsDisplay.display) {
            return Yup.object().shape({
                message: Yup.string().required(t('form:error.required')),
                actionLink: Yup.string().when('actionType', {
                    is: NoSlotsAction.link,
                    then: Yup.string().url(t('form:error.url_invalid')).required(t('form:error.required')),
                }),
                actionEmail: Yup.string().when('actionType', {
                    is: NoSlotsAction.email,
                    then: Yup.string().email(t('form:error.email_invalid')).required(t('form:error.required')),
                }),
                actionPhone: Yup.string().when('actionType', {
                    is: NoSlotsAction.phone,
                    then: Yup.string().matchesPhone(t('form:error.phone_invalid')).required(t('form:error.required')),
                }),
            });
        } else {
            return Yup.object();
        }
    });
};
