import styled, { css } from 'styled-components';

export const FooterContainer = styled.div<{ isSideMenuOpen: boolean; isSideMenuVisible: boolean }>`
    height: ${({ theme }) => theme.footerHeight}}
    width: fit-content;
    padding-top: 15px;
    font-size: 12px;
    z-index: 30;
    margin: auto;
    transition: 0.2s ease-in-out;

    

    ${({ isSideMenuVisible, isSideMenuOpen }) =>
        isSideMenuVisible &&
        css`
            padding-left: ${isSideMenuOpen ? '240px' : '80px'};
        `}
        
        @media (max-width: ${({ theme }) => theme.devices.desktop}) {
            padding-left: 0;
        }
        
`;

export const FooterTitle = styled.p`
    text-align: center;
    margin-bottom: 8px;
`;

export const FooterLinks = styled.div`
    display: flex;
    justify-content: center;
    gap: 25px;

    a {
        color: black;
        position: relative;

        &:nth-last-child(1) {
            &:after {
                display: none;
            }
        }

        &:after {
            content: '';
            position: absolute;
            height: 5px;
            width: 5px;
            border-radius: 10px;
            background: black;
            right: -14px;
            top: 7px;
        }
    }
`;
