import { useEffect, useState } from 'react';
import { BaseHealthcareService } from 'medrefer-web-sdk/api/models';
import { useOrganizationLocations } from 'components/Calendar/hooks/useOrganizationLocations';

interface Props {
    activeServices: BaseHealthcareService[];
    setActiveServices: (services: BaseHealthcareService[]) => void;
    setActiveScheduleTypes: (types: string[]) => void;
    activeScheduleTypes: string[];
}

interface LocationFilter {
    locationId: number;
    isOpen: boolean;
    isActive: boolean;
}

export const useCalendarFilters = ({
    activeServices,
    setActiveServices,
    activeScheduleTypes,
    setActiveScheduleTypes,
}: Props) => {
    const [locationFilters, setLocationFilters] = useState<LocationFilter[]>([]);
    const [isScheduleTypeFilterOpen, setIsScheduleTypeFilterOpen] = useState<boolean>(true);
    const organizationLocations = useOrganizationLocations();

    useEffect(() => {
        setLocationFilters(
            organizationLocations.map((x) => {
                return {
                    locationId: x.id,
                    isOpen: true,
                    isActive: true,
                };
            }),
        );
    }, [organizationLocations]);

    const handleLocationDrawerClick = (locationId: number | undefined, type: string) => {
        const newLocationFilters = [...locationFilters];
        const location = locationFilters.find((x) => x.locationId === locationId);
        if (!location) return;
        if (type === 'isActive') {
            newLocationFilters[locationFilters.indexOf(location)].isActive = !location.isActive;
            const filtersToApply = organizationLocations.find((x) => x.id === locationId)?.service_set;
            if (!location.isActive) {
                setActiveServices(activeServices.filter((x) => filtersToApply?.map((x) => x.id).indexOf(x.id) === -1));
            } else if (filtersToApply) {
                setActiveServices([...activeServices].concat(filtersToApply));
            }
        } else {
            newLocationFilters[locationFilters.indexOf(location)].isOpen = !location.isOpen;
        }
        setLocationFilters(newLocationFilters);
    };

    const handleServiceCheckboxClick = (service: BaseHealthcareService) => {
        const isAdding = activeServices.map((x) => x.id).indexOf(service.id) === -1;

        setActiveServices(
            isAdding ? activeServices.concat([service]) : activeServices.filter((x) => x.id !== service.id),
        );

        const organizationLocation = organizationLocations.find(
            (location) => location.service_set.indexOf(service) !== -1,
        );

        const locationFilter = locationFilters.find((x) => x.locationId === organizationLocation?.id);

        const servicesIntersection = activeServices.filter((x) => organizationLocation?.service_set.includes(x));

        if (organizationLocation && locationFilter) {
            const isActive = !(servicesIntersection.length === 1 && !isAdding);
            const newLocationFilters = [...locationFilters];
            newLocationFilters[newLocationFilters.indexOf(locationFilter)].isActive = isActive;
            setLocationFilters(newLocationFilters);
        }
    };
    const handleScheduleTypeCheckboxClick = (type: string) => {
        const isAdding = activeScheduleTypes.indexOf(type) === -1;
        setActiveScheduleTypes(
            isAdding ? activeScheduleTypes.concat([type]) : activeScheduleTypes.filter((x) => x !== type),
        );
    };

    return {
        locationFilters,
        setLocationFilters,
        isScheduleTypeFilterOpen,
        setIsScheduleTypeFilterOpen,
        handleLocationDrawerClick,
        handleServiceCheckboxClick,
        handleScheduleTypeCheckboxClick,
    };
};
