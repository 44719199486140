import React, { useCallback, useEffect, useRef, useState } from 'react';
import { userSelectors } from 'features/auth';
import { appointmentsSocket } from 'medrefer-web-sdk/api';
import { UserRole, WebSocketEvent, WebSocketPayload } from 'medrefer-web-sdk/api/models';
import { useInterval } from 'medrefer-web-sdk/web-kit/hooks';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { notification, useNotification } from 'services/notifications';
import { getAuthTokens } from 'utils/tokens';
import { Toast } from 'medrefer-web-sdk/web-kit';
import { ToastTypes } from 'medrefer-web-sdk/web-kit/components/Toast';
import useBrand from 'hooks/useBrand';

const maxReconnectionTries = 5;
const reconnectionInterval = 1000;

const UpdateListener = () => {
    const { organization } = useBrand();
    const [reconnectAttempts, setReconnectAttempts] = useState(0);
    const [isTryingToReconnect, setIsTryingToReconnect] = useState(false);
    const [showConnectionErrorMessage, setShowConnectionErrorMessage] = useState(false);
    const authUser = useSelector(userSelectors.getAuthUser);

    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const statusUpdateWs = useRef<any>(null);
    const { t } = useTranslation();
    useNotification();

    const handleNewAppointment = useCallback(
        (payload: WebSocketPayload) => {
            notification(`ID: ${payload.id}`, t('notification:newAppointment'));
        },
        [t],
    );

    const handleStatusUpdate = useCallback(
        (payload: WebSocketPayload) => {
            notification(`ID: ${payload.id}`, t('notification:statusUpdate'));
        },
        [t],
    );

    const handleNewAttachments = useCallback(
        (payload: WebSocketPayload) => {
            notification(`ID: ${payload.id}`, t('notification:newAttachments'));
        },
        [t],
    );

    const notifyUpdate = useCallback(
        (message: WebSocketEvent) => {
            if (message.type === 'appointment.created') {
                handleNewAppointment(message.payload);
            } else if (message.type === 'appointment.updated') {
                handleStatusUpdate(message.payload);
            } else if (message.type === 'appointment.attachments.updated') {
                handleNewAttachments(message.payload);
            }
        },
        [handleNewAppointment, handleNewAttachments, handleStatusUpdate],
    );

    const handleUpdate = useCallback(
        (message: WebSocketEvent) => {
            if (message.payload.triggered_by_user !== authUser?.id && !message.payload.is_silent) {
                notifyUpdate(message);
            }
        },
        [authUser?.id, notifyUpdate],
    );

    const connect = useCallback(() => {
        const { token } = getAuthTokens();

        if (token && organization?.slug) {
            statusUpdateWs.current = new WebSocket(appointmentsSocket(token, organization.slug));
            //eslint-disable-next-line @typescript-eslint/no-explicit-any
            statusUpdateWs.current.onmessage = (e: any) => {
                handleUpdate(JSON.parse(e.data));
            };

            statusUpdateWs.current.onopen = () => {
                // stop reconnecting if connected
                setIsTryingToReconnect(false);
                setReconnectAttempts(0);

                // eslint-disable-next-line no-console
                console.log('Socket connection successfully opened.');
            };

            // try reconnecting on close
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            statusUpdateWs.current.onclose = (e: any) => {
                // eslint-disable-next-line no-console
                console.log('Socket is closed. Trying to reconnect.', e.reason);

                setIsTryingToReconnect(true);
            };

            // websocket onerror event listener
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            statusUpdateWs.current.onerror = (err: any) => {
                // eslint-disable-next-line no-console
                console.error('Socket encountered error: ', err.message, 'Closing socket');

                if (statusUpdateWs.current) {
                    statusUpdateWs.current.close();
                }
            };
        }
    }, [handleUpdate, organization?.slug]);

    useEffect(() => {
        if (authUser?.role === UserRole.organization) {
            connect();
        }
        return () => {
            if (statusUpdateWs.current) {
                statusUpdateWs.current.close();
            }
        };
    }, [authUser?.role, connect]);

    const checkAndReconnect = useCallback(() => {
        if (authUser?.role !== UserRole.organization) {
            setIsTryingToReconnect(false);
            return;
        }

        if (reconnectAttempts >= maxReconnectionTries) {
            setIsTryingToReconnect(false);
            setShowConnectionErrorMessage(true);
            return;
        }

        if (!statusUpdateWs.current || statusUpdateWs.current.readyState == WebSocket.CLOSED) connect();
        setReconnectAttempts((prev) => prev + 1);
    }, [connect, reconnectAttempts, setReconnectAttempts, authUser?.role]);

    useInterval(
        checkAndReconnect,
        // Delay in milliseconds or null to stop it
        isTryingToReconnect ? reconnectionInterval : null,
    );

    if (showConnectionErrorMessage) {
        return (
            <Toast
                text={t('notification:cantConnect')}
                type={ToastTypes.warning}
                toggleToast={() => setShowConnectionErrorMessage(false)}
            />
        );
    }

    return null;
};

export default UpdateListener;
