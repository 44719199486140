import React, { ReactNode, useState } from 'react';
import { AppointmentModel, Slot, SlotBookingData, SlotMode } from 'medrefer-web-sdk/api/models';
import { Modal, ModalState, UnderlineH2 } from 'medrefer-web-sdk/web-kit';
import BookSlotForPatientForm, { FormValues } from './BookSlotForPatientForm';
import useApiRequest from 'hooks/useApiRequest';
import { useTranslation } from 'react-i18next';
import { api } from 'medrefer-web-sdk/api';
import { ConfirmBookingModal } from '../ConfirmBookingModal';
import { toNullableDate } from 'utils/dates';
import { errorTimeout } from 'utils/constants';
import { parseEnumProperty } from 'medrefer-web-sdk/web-kit/utils/stringHelpers';

export interface BookSlotForPatientModalProps {
    state: ModalState;
    button?: ReactNode;
    slot: Slot;
    onSlotBooked: (appointment: AppointmentModel) => void;
}

export const BookSlotForPatientModal = ({ state, button, slot, onSlotBooked }: BookSlotForPatientModalProps) => {
    const { t } = useTranslation();
    const request = useApiRequest({ errorTimeout });
    const [bookingData, setBookingData] = useState<SlotBookingData | null>(null);

    const handleSubmit = async (values: FormValues) => {
        if (!values.patient) {
            return;
        }

        const patientProfile = await request.dispatch(api.getOrganizationPatient(parseInt(values.patient)));

        if (!patientProfile.patient) {
            return;
        }

        const mode = parseEnumProperty<SlotMode>(SlotMode, values.mode);

        setBookingData({
            slot: slot,
            city: patientProfile.patient.city,
            street: patientProfile.patient.street,
            patient: patientProfile.id,
            personal_title: patientProfile.personal_title,
            first_name: patientProfile.first_name,
            last_name: patientProfile.last_name,
            phone: patientProfile.phone,
            email: patientProfile.email,
            insurance_number: patientProfile.patient.insurance_number,
            insurance_status: patientProfile.patient.insurance_status,
            date_of_birth: toNullableDate(patientProfile.patient.date_of_birth),
            reason: values.reason,
            comment: values.comment,
            sms_agreement: patientProfile.patient.sms_agreement,
            email_agreement: patientProfile.patient.email_agreement,
            terms_agreement: true,
            mode: mode ? mode : null,
        });
    };

    const onDiscardConfirmation = () => {
        setBookingData(null);
    };

    const onConfirm = () => {
        if (!bookingData) {
            return;
        }
        request.dispatch(api.bookSlot(bookingData)).then(onSlotBooked);
        setBookingData(null);
    };

    return (
        <>
            <Modal
                state={state}
                button={button}
                body={
                    <>
                        <UnderlineH2>{t('slotBooking:form_title')}</UnderlineH2>
                        <BookSlotForPatientForm slot={slot} handleSubmit={handleSubmit} request={request} />
                    </>
                }
            />
            {bookingData && (
                <ConfirmBookingModal
                    values={bookingData}
                    isLoading={request.isLoading}
                    onDiscard={onDiscardConfirmation}
                    onConfirm={onConfirm}
                />
            )}
        </>
    );
};
