import {
    Button,
    ErrorMessage,
    ModalBody,
    ModalMessage,
    ModalState,
    ModalTitle,
} from '../../../../medrefer-web-sdk/web-kit';
import React from 'react';
import { ApiRequest } from '../../../../hooks/useApiRequest';

type Props = {
    state: ModalState;
    title: string;
    message?: string;
    confirmButton?: string;
    count: number;
    errors?: ApiRequest;
};

export const CalendarTableInfoModal = ({ title, message, confirmButton, count, state, errors }: Props) => {
    const messageWithCnt = message?.replace('{}', count.toString());

    let errorMessage = '';
    if (errors?.errors) {
        errorMessage = errors.errors.date_from[0].message.toString();
    }
    return (
        <ModalBody>
            <ModalTitle>{title}</ModalTitle>
            {message && <ModalMessage>{messageWithCnt}</ModalMessage>}
            {errors && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <div style={{ maxWidth: 215 }}>
                <div className="mb-2">
                    <Button
                        style={{ minWidth: '150px' }}
                        onClick={() => {
                            if (errors?.errors) {
                                errors.clearErrors();
                            }
                            state.toggleModal();
                        }}
                    >
                        {confirmButton}
                    </Button>
                </div>
            </div>
        </ModalBody>
    );
};
