import { Moment } from 'moment';

export const constants = {
    dateFormat: 'DD.MM.YYYY',
    timeFormat: 'HH:mm',
};

export const toNullableDate = (input: number | string | Date | null | undefined) => {
    return input != null ? new Date(input) : null;
};

export const addDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

export const subtractDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() - days);
    return result;
};

export const getDaysDiff = (firstDate: Date, secondDate: Date) => {
    return Math.floor(getDaysDiffPrecise(firstDate, secondDate));
};

export const getDaysDiffPrecise = (firstDate: Date, secondDate: Date) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
    const oneMin = 60 * 1000; // seconds * milliseconds
    return Math.abs(
        (firstDate.getTime() -
            firstDate.getTimezoneOffset() * oneMin -
            (secondDate.getTime() - secondDate.getTimezoneOffset() * oneMin)) /
            oneDay,
    ); //normalize time to GMT + 0
};

export const minDate = (...dates: Date[]): Date => {
    const timestamps = dates.map((date) => date.getTime());
    const minTimestamp = Math.min(...timestamps);
    return new Date(minTimestamp);
};

export const maxDate = (...dates: Date[]): Date => {
    const timestamps = dates.map((date) => date.getTime());
    const minTimestamp = Math.max(...timestamps);
    return new Date(minTimestamp);
};

export const maxNullableDate = (...nullableDates: Array<Date | null>): Date | null => {
    return findNullableDate(maxDate, nullableDates);
};

export const minNullableDate = (...nullableDates: Array<Date | null>): Date | null => {
    return findNullableDate(minDate, nullableDates);
};

const findNullableDate = (action: (...dates: Date[]) => Date, nullableDates: Array<Date | null>): Date | null => {
    const dates = nullableDates.filter((d) => d != null);
    if (dates.length) {
        return action(...(dates as Date[]));
    }
    return null;
};

/**
 * Rounds a date up to the next largest date without hours
 */
export const ceilDate = (date: Date): Date => {
    const start = floorDate(date);
    return start < date ? addDays(start, 1) : start;
};

/**
 * Returns the largest date without hours less than or equal to a given date
 */
export const floorDate = (date: Date): Date => {
    return new Date(new Date(date).setHours(0, 0, 0, 0));
};

export const formatDate = (moment: Moment): string => {
    return moment.format(constants.dateFormat);
};

export const formatTime = (moment: Moment): string => {
    return moment.format(constants.timeFormat);
};

export const formatDateTime = (moment: Moment): string => {
    return `${moment.format(constants.dateFormat)} ${moment.format(constants.timeFormat)}`;
};

export const getTimeIntervalString = (start: Moment, end: Moment): string => {
    return `${start.format('H:mm')} - ${end.format('H:mm')}`;
};

/**
 * Returns time interval of the same day
 * Example: 19.11.2021 18:30 - 19:30
 */
export const getDateTimeIntervalString = (start: Moment, end: Moment): string => {
    const date = formatDate(start);
    const timeInterval = getTimeIntervalString(start, end);
    return `${date} ${timeInterval}`;
};

export const isSameDay = (dateToCheck: Date, actualDate: Date) => {
    return (
        dateToCheck.getDate() === actualDate.getDate() &&
        dateToCheck.getMonth() === actualDate.getMonth() &&
        dateToCheck.getFullYear() === actualDate.getFullYear()
    );
};

export const isToday = (date: Date) => {
    return isSameDay(date, new Date());
};
