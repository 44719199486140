import { getTimeFormatted } from 'components/Calendar/helpers';
import { ScheduleType } from '../../Calendar.types';
import { SlotPermission } from '../../../../medrefer-web-sdk/api/models';
function getTimeTemplate(
    schedule: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        start: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        isPrivate: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        isReadOnly: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        recurrenceRule: any;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        attendees: string | any[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        location: any;
        title: string;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        raw: any;
    },
    isAllDay: boolean,
) {
    const publicIcon =
        '<svg  xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 24 24">' +
        '<path fill="#000000" d="M16.36,14C16.44,13.34 16.5,12.68 16.5,12C16.5,11.32 16.44,10.66 16.36,10H19.74C19.9,10.64 20,11.31 20,12C20,12.69 19.9,13.36 19.74,14M14.59,19.56C15.19,18.45 15.65,17.25 15.97,16H18.92C17.96,17.65 16.43,18.93 14.59,19.56M14.34,14H9.66C9.56,13.34 9.5,12.68 9.5,12C9.5,11.32 9.56,10.65 9.66,10H14.34C14.43,10.65 14.5,11.32 14.5,12C14.5,12.68 14.43,13.34 14.34,14M12,19.96C11.17,18.76 10.5,17.43 10.09,16H13.91C13.5,17.43 12.83,18.76 12,19.96M8,8H5.08C6.03,6.34 7.57,5.06 9.4,4.44C8.8,5.55 8.35,6.75 8,8M5.08,16H8C8.35,17.25 8.8,18.45 9.4,19.56C7.57,18.93 6.03,17.65 5.08,16M4.26,14C4.1,13.36 4,12.69 4,12C4,11.31 4.1,10.64 4.26,10H7.64C7.56,10.66 7.5,11.32 7.5,12C7.5,12.68 7.56,13.34 7.64,14M12,4.03C12.83,5.23 13.5,6.57 13.91,8H10.09C10.5,6.57 11.17,5.23 12,4.03M18.92,8H15.97C15.65,6.75 15.19,5.55 14.59,4.44C16.43,5.07 17.96,6.34 18.92,8M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />' +
        '</svg>';
    const privateIcon =
        `<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20"  viewBox="0 0 24 24">` +
        `<path d="M12,17A2,2 0 0,0 14,15C14,13.89 13.1,13 12,13A2,2 0 0,0 10,15A2,2 0 0,0 12,17M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V10C4,8.89 4.9,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" />` +
        `</svg>`;
    const internalIcon =
        '<svg xmlns="http://www.w3.org/2000/svg" width="18" height="20"  viewBox="0 0 24 24">' +
        '<path d="M12,5.5A3.5,3.5 0 0,1 15.5,9A3.5,3.5 0 0,1 12,12.5A3.5,3.5 0 0,1 8.5,9A3.5,3.5 0 0,1 12,5.5M5,8C5.56,8 6.08,8.15 6.53,8.42C6.38,9.85 6.8,11.27 7.66,12.38C7.16,13.34 6.16,14 5,14A3,3 0 0,1 2,11A3,3 0 0,1 5,8M19,8A3,3 0 0,1 22,11A3,3 0 0,1 19,14C17.84,14 16.84,13.34 16.34,12.38C17.2,11.27 17.62,9.85 17.47,8.42C17.92,8.15 18.44,8 19,8M5.5,18.25C5.5,16.18 8.41,14.5 12,14.5C15.59,14.5 18.5,16.18 18.5,18.25V20H5.5V18.25M0,20V18.5C0,17.11 1.89,15.94 4.45,15.6C3.86,16.28 3.5,17.22 3.5,18.25V20H0M24,20H20.5V18.25C20.5,17.22 20.14,16.28 19.55,15.6C22.11,15.94 24,17.11 24,18.5V20Z" />' +
        '</svg>';
    const html = [];

    if (!isAllDay) {
        html.push('<strong>' + getTimeFormatted(schedule.start) + '</strong> ');
    }
    if (schedule.isPrivate) {
        html.push('<span class="calendar-font-icon ic-lock-b"></span>');
        html.push(' Private');
    } else {
        if (schedule.isReadOnly) {
            html.push('<span class="calendar-font-icon ic-readonly-b"></span>');
        } else if (schedule.recurrenceRule) {
            html.push('<span class="calendar-font-icon ic-repeat-b"></span>');
        } else if (schedule.attendees.length) {
            html.push('<span class="calendar-font-icon ic-user-b"></span>');
        } else if (schedule.location) {
            html.push('<span class="calendar-font-icon ic-location-b"></span>');
        }
        if (schedule.raw.type == ScheduleType.slot) {
            if (schedule.raw.slot_permission == SlotPermission.PUBLIC) {
                html.push(' ' + publicIcon);
            }
            if (schedule.raw.slot_permission == SlotPermission.PRIVATE) {
                html.push(' ' + privateIcon);
            }
            if (schedule.raw.slot_permission == SlotPermission.INTERNAL) {
                html.push(' ' + internalIcon);
            }
        }
        html.push(' ' + schedule.title);
    }
    return html.join('');
}

export const getCalendarTemplates = () => {
    return {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        time: (schedule: any) => {
            return getTimeTemplate(schedule, false);
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        timegridDisplayPrimaryTime: (time: any) => {
            return `${time.hour}:${time.minutes < 10 ? '0' + time.minutes : time.minutes}`;
        },
    };
};
