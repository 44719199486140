import React, { FC } from 'react';

import { useCalendarData } from './hooks';
import { canManageSlots } from 'features/auth/rules';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'features/auth/selectors';
import { CalendarView } from './CalendarView';
import { useCalendarCallbacks } from './hooks/useCalendarCallbacks';
import { CalendarServiceFilters } from './CalendarServiceFilters';
import { DateNavigator } from './DateNavigator';
import { MobileNotAvailable } from 'components/MobileNotAvailable';
import { CalendarViewStyled } from './CalendarView/CalendarView.styled';
import { CalendarFiltersContainer, CalendarFiltersWrapper } from './Calendar.styled';
import { useCalendarState } from './CalendarView/useCalendarState';
import { HourRange } from './CalendarView/CalendarView.types';
import { useNearestEventFocus } from './hooks/useNearestEventFocus';
import { CalendarActiveSchedule } from './CalendarActiveSchedule/CalendarActiveSchedule';
import { useControlRef } from 'components/Calendar/CalendarView/useControlRef';

export const Calendar: FC = () => {
    const authUser = useSelector(getAuthUser);
    const state = useCalendarState();
    const controlRef = useControlRef();
    const { handleDateChange, handleCalendarTypeChange } = controlRef.current;
    useNearestEventFocus({ state, handleDateChange });
    const {
        schedulePopoverType,
        schedulePopoverState,
        activeSchedule,
        setActiveSchedule,
        onClickSchedule,
        onCreateSchedule,
        onUpdateSchedule,
    } = useCalendarCallbacks();
    const {
        calendars,
        activeCalendars,
        schedules,
        activeServices,
        setActiveServices,
        setActiveScheduleTypes,
        activeScheduleTypes,
        isLoading,
        reloadSchedules,
    } = useCalendarData(state);
    const hourRange: HourRange = { min: 6, max: 21 };

    if (!authUser) return null;

    return (
        <>
            <MobileNotAvailable />
            <CalendarViewStyled>
                <CalendarFiltersContainer>
                    <CalendarFiltersWrapper>
                        <DateNavigator
                            state={state}
                            handleDateChange={handleDateChange}
                            handleCalendarTypeChange={handleCalendarTypeChange}
                        />
                        <CalendarServiceFilters
                            activeServices={activeServices}
                            setActiveServices={setActiveServices}
                            setActiveScheduleTypes={setActiveScheduleTypes}
                            activeScheduleTypes={activeScheduleTypes}
                            calendars={calendars}
                        />
                    </CalendarFiltersWrapper>
                </CalendarFiltersContainer>
                <CalendarView
                    state={state}
                    calendars={calendars}
                    activeCalendars={activeCalendars}
                    isLoading={isLoading}
                    canCreateSchedule={canManageSlots(authUser)}
                    hourRange={hourRange}
                    schedules={schedules}
                    onClickSchedule={onClickSchedule}
                    onCreateSchedule={onCreateSchedule}
                    onUpdateSchedule={onUpdateSchedule}
                    controlRef={controlRef}
                />
            </CalendarViewStyled>
            <CalendarActiveSchedule
                activeSchedule={activeSchedule}
                setActiveSchedule={setActiveSchedule}
                schedulePopoverType={schedulePopoverType}
                schedulePopoverState={schedulePopoverState}
                reloadSchedules={reloadSchedules}
                hourRange={hourRange}
            />
        </>
    );
};
