import React, { FC } from 'react';
import { ConfirmModalBody } from 'medrefer-web-sdk/web-kit';
import { DataTable } from 'components/shared/DataTable';
import { AdminPanelContainer, FormActivityIndicatorContainer } from 'components/AdminPanel/AdminPanel.styled';
import { useManageServiceGroups } from './hooks/useManageServiceGroups';
import { columns } from './ManageServiceGroups.consts';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';
import { ServiceGroupForm } from './ServiceGroupForm';
import { ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { RouteBreadcrumbs } from 'components/shared/RouteBreadcrumbs';

export const ManageServicesGroups: FC = () => {
    const { t } = useTranslation();
    const {
        createHealthcareServiceGroup,
        updateHealthcareServiceGroup,
        request,
        activeItem,
        fetchActiveItem,
        deleteModalState,
        deleteHealthcareServiceGroup,
        ...rest
    } = useManageServiceGroups();
    return (
        <>
            <AdminPanelContainer>
                <DataTable
                    title={t('adminPanel:manageServiceGroups.title')}
                    actionColumnTitle={t('adminPanel:table.columns.actions')}
                    noItemsMessage={t('adminPanel:table.no_items_message')}
                    columns={columns(t)}
                    addItemForm={
                        <ServiceGroupForm
                            type={ModalType.add}
                            request={request}
                            handleSubmit={createHealthcareServiceGroup}
                        />
                    }
                    editItemForm={
                        activeItem ? (
                            <ServiceGroupForm
                                activeItem={activeItem}
                                type={ModalType.edit}
                                request={request}
                                handleSubmit={updateHealthcareServiceGroup}
                            />
                        ) : (
                            <FormActivityIndicatorContainer>
                                <ActivityIndicator />
                            </FormActivityIndicatorContainer>
                        )
                    }
                    deleteModalState={deleteModalState}
                    deleteItemForm={
                        activeItem ? (
                            <ConfirmModalBody
                                title={t('adminPanel:manageServiceGroups.deleteForm.title')}
                                message={t('adminPanel:manageServiceGroups.deleteForm.message')}
                                confirmButton={t('adminPanel:manageServiceGroups.deleteForm.accept_button')}
                                declineButton={t('adminPanel:manageServiceGroups.deleteForm.decline_button')}
                                onConfirm={deleteHealthcareServiceGroup}
                                onDecline={deleteModalState.toggleModal}
                            />
                        ) : (
                            <ActivityIndicator />
                        )
                    }
                    setActiveItem={fetchActiveItem}
                    addButtonText={t('adminPanel:table.controls.addButtonText')}
                    previousPageText={t('adminPanel:table.controls.previousPageText')}
                    nextPageText={t('adminPanel:table.controls.nextPageText')}
                    breadcrumbs={<RouteBreadcrumbs />}
                    {...rest}
                />
            </AdminPanelContainer>
        </>
    );
};
