import React, { useMemo, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    ErrorMessage,
    FormGroup,
    FormSelect,
    Input,
    Label,
    TextArea,
    Thumb,
    ButtonLabel,
    idNameToOption,
} from 'medrefer-web-sdk/web-kit';
import { Field, FormikProps } from 'formik';
import { SlotBookingFormData, SlotBookingStep } from 'components/PublicSearch/SlotBooking/SlotBooking.types';
import { FormProps } from './BookingForm';
import { useTranslation } from 'react-i18next';
import { FormColumn } from './BookingForm.styled';
import { EditFieldButton } from 'components/shared/EditFieldButton';
import { PhoneInput } from 'components/shared/PhoneInput';
import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';
import ScreenCapture from '@uppy/screen-capture';
import Compressor from '@uppy/compressor';
import { Dashboard } from '@uppy/react';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/audio/dist/style.css';
import '@uppy/screen-capture/dist/style.css';
import '@uppy/image-editor/dist/style.css';
import '@uppy/webcam/dist/style.css';
import { getActiveLanguage } from 'utils/i18n';
import German from '@uppy/locales/lib/de_DE';
import Polish from '@uppy/locales/lib/pl_PL';
import English from '@uppy/locales/lib/en_US';
type Props = {
    props: FormProps & FormikProps<SlotBookingFormData>;
};

export const BaseInfoForm = ({ props }: Props) => {
    const { t } = useTranslation();
    const emailInput = useRef<HTMLInputElement>(null);
    const { step } = props.values;
    const isAuthenticated = !!props.user;
    const { attachDocumentsHandler } = props;
    const appointmentReasonOptions = useMemo(() => {
        return props.slot.available_for_reasons.map(idNameToOption);
    }, [props.slot]);
    const reasonId = appointmentReasonOptions.find((reason) => reason.value === props.values.reason);
    const isPhoneInputVisible = isAuthenticated && props.values.sms_agreement && props.user?.phone === '';
    const activeLocaleString = getActiveLanguage();
    const locale = activeLocaleString == 'de' ? German : activeLocaleString == 'pl' ? Polish : English;
    const uppy = React.useMemo(() => {
        return new Uppy({
            locale: locale,
            restrictions: {
                maxNumberOfFiles: 5,
                minNumberOfFiles: 0,
                maxFileSize: 5 * 1024 * 1024, //5mb
            },
        })
            .use(Webcam, {})
            .use(Compressor, {})
            .use(ScreenCapture, {});
    }, [locale]);
    React.useEffect(() => {
        return () => uppy.close({ reason: 'unmount' });
    }, [uppy]);

    uppy.on('complete', (result) => {
        const fileList = result.successful.map((file) => {
            return new File([file.data], file.name, { type: file.type });
        });
        attachDocumentsHandler.setAttachments(fileList);
    });
    return (
        <>
            <FormGroup style={{ paddingTop: 0 }}>
                <Row>
                    <FormColumn xs={12} md={6} inputBottom>
                        <Label isRequired labelTop>
                            {t('newAppointmentForm:title_email')}
                        </Label>
                        <EditFieldButton
                            onClick={() => {
                                props.setFieldValue('step', SlotBookingStep.base);
                                setImmediate(() => emailInput.current!.focus());
                            }}
                            isVisible={step != SlotBookingStep.base && !isAuthenticated}
                        >
                            <Input
                                ref={emailInput}
                                isError={!!(props.touched.email && props.errors.email)}
                                id="email"
                                type="text"
                                onChange={props.handleChange}
                                value={props.values.email}
                                onBlur={props.handleBlur}
                                disabled={isAuthenticated || step != SlotBookingStep.base}
                            />
                        </EditFieldButton>
                    </FormColumn>
                    <FormColumn xs={12} md={6} hidden={appointmentReasonOptions.length > 1 ? false : true}>
                        <Label isRequired labelTop>
                            {t('newAppointmentForm:title_reason_for_appointment')}
                        </Label>
                        <Field
                            name="reason"
                            options={appointmentReasonOptions}
                            component={FormSelect}
                            value={reasonId}
                            placeholder={t('newAppointmentForm:placeholder_reason_for_appointment')}
                            noOptionsMessage={() => t('newAppointmentForm:no_options_reason_for_appointment')}
                        />
                    </FormColumn>
                </Row>
                <Row>
                    {props.touched.email && props.errors.email && (
                        <ErrorMessage className="text-left mt-1" style={{ paddingLeft: '15px' }}>
                            {props.errors.email}
                        </ErrorMessage>
                    )}
                </Row>
            </FormGroup>
            {isPhoneInputVisible && (
                <FormGroup style={{ paddingTop: 0 }}>
                    <Row>
                        <FormColumn xs={12} md={6}>
                            <Label isRequired={props.values.sms_agreement} labelTop>
                                {t('newAppointmentForm:title_phone_number')}
                            </Label>
                            <PhoneInput
                                isError={!!(props.touched.phone && props.errors.phone)}
                                id="phone"
                                type="text"
                                onChange={props.handleChange}
                                value={props.values.phone}
                                onBlur={props.handleBlur}
                            />
                            {props.touched.phone && props.errors.phone && (
                                <ErrorMessage className="text-left mt-1 mr-3">{props.errors.phone}</ErrorMessage>
                            )}
                        </FormColumn>
                    </Row>
                </FormGroup>
            )}
            <FormGroup>
                <Row>
                    <Col xs={12}>
                        <Label labelTop>{t('newAppointmentForm:title_message')}</Label>
                        <TextArea
                            style={{ height: '130px' }}
                            isError={!!(props.touched.comment && props.errors.comment)}
                            id="comment"
                            placeholder={t('newAppointmentForm:placeholder_message')}
                            onChange={props.handleChange}
                            value={props.values.comment}
                            onBlur={props.handleBlur}
                        />
                        {props.touched.comment && props.errors.comment && (
                            <ErrorMessage className="text-left mt-1">{props.errors.comment}</ErrorMessage>
                        )}
                    </Col>
                    <Col xs={12} className="mt-4" style={{ zIndex: 2 }}>
                        <Label labelTop>{t('newAppointmentForm:title_attachments')}</Label>
                        <ButtonLabel>{t('newAppointmentForm:subtitle_attachment')}</ButtonLabel>
                        <input
                            title="imageUpload"
                            ref={attachDocumentsHandler.uploadInput}
                            id="attachments"
                            name="attachments"
                            type="file"
                            onChange={(e) => attachDocumentsHandler.handleFileChange(e, 5000, 5)}
                            style={{ display: 'none' }}
                        />
                        <Dashboard
                            style={{ zIndex: 1 }}
                            uppy={uppy}
                            plugins={['Webcam', 'ScreenCast', 'Unsplash', 'ScreenCapture']}
                            metaFields={[
                                { id: 'name', name: 'Name', placeholder: 'file name' },
                                { id: 'caption', name: 'Caption', placeholder: 'describe what the file is for' },
                            ]}
                        />
                        <ButtonLabel style={{ paddingTop: 10 }}>
                            {t('attachDocumentsModalBody:max_files_title')}
                        </ButtonLabel>
                        {attachDocumentsHandler.attachments &&
                            attachDocumentsHandler.attachments.map(({ name }, index) => {
                                return (
                                    <Thumb
                                        key={name}
                                        name={name}
                                        click={() => attachDocumentsHandler.handleFileDelete(index)}
                                        listIndex={index + 1}
                                    />
                                );
                            })}
                        {attachDocumentsHandler.attachmentError && (
                            <ErrorMessage className="mt-1">{attachDocumentsHandler.attachmentError}</ErrorMessage>
                        )}
                    </Col>
                </Row>
            </FormGroup>
        </>
    );
};
