export enum Frequency {
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY',
    YEARLY = 'YEARLY',
}

export enum Weekday {
    MO = 'MO',
    TU = 'TU',
    WE = 'WE',
    TH = 'TH',
    FR = 'FR',
    SA = 'SA',
    SU = 'SU',
}

export const ALL_WEEKDAYS = [Weekday.MO, Weekday.TU, Weekday.WE, Weekday.TH, Weekday.FR, Weekday.SA, Weekday.SU];

export interface ScheduleRule {
    freq: Frequency;
    interval: number;
    count: number | null;
    until: string | null;
    byweekdays: Weekday[];
    bymonthday?: number;
    bymonth?: number;
}
