import styled from 'styled-components';

interface AppointmentContainerProps {
    $isShow: boolean;
}

export const AppointmentContainer = styled.div<AppointmentContainerProps>`
    background-color: #fafafc;
    max-width: calc(100% - 400px);
    transition: transform 0.2s ease-in;
    flex: 1;
    padding: 20px;
    max-height: calc(100vh - ${({ theme }) => theme.navbarHeight.desktop});
    overflow-y: scroll;

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        z-index: 99 !important;
        margin: 0;
        min-width: 100vw;
        max-width: 100vw;
        position: fixed;
        top: 45px;
        transform: ${({ $isShow }) => ($isShow ? 'translateX(0)' : 'translateX(100vw)')};
        background-color: white;
        height: var(--maxvh);
        overflow-y: scroll;
        padding-top: 0;
    }
`;
