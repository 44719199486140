import React from 'react';
import { PolicyLinkLink } from './PrivacyPolicyNote.styled';
import { useTranslation } from 'react-i18next';
import ReactMarkdown from 'react-markdown';
import { getPath } from 'router-paths';

export const PrivacyPolicyNote = () => {
    const { t } = useTranslation();
    return (
        <>
            <ReactMarkdown
                renderers={{
                    link: (props) => (
                        <a href={props.href} rel="nofollow noreferrer noopener" target="_blank">
                            {props.children}
                        </a>
                    ),
                }}
                allowedTypes={['text', 'strong', 'delete', 'emphasis', 'link']}
                unwrapDisallowed
            >
                {t('privacyPolicyNote:message_start') as string}
            </ReactMarkdown>
            <PolicyLinkLink href={getPath('privacyPolicy')} rel="noreferrer" target="_blank">
                {t('privacyPolicyNote:message_cta')}
            </PolicyLinkLink>
            {t('privacyPolicyNote:message_end')}
        </>
    );
};
