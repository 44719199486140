import styled from 'styled-components';
import { Hamburger } from 'medrefer-web-sdk/web-kit/components/buttons/Hamburger';

export interface NavbarLinksContainerProps {
    isOpen: boolean;
}

export const NavbarContainer = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background-color: ${({ theme }) => theme.white};
    z-index: 3;
    display: flex;
    align-items: center;
    border-bottom: solid 1px #eeeef2;
    justify-content: space-between;
    height: ${({ theme }) => theme.navbarHeight.mobile};
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: flex;
        z-index: 30;
        justify-content: space-between;
        height: ${({ theme }) => theme.navbarHeight.desktop};
    }
`;

export const MenuToggle = styled(Hamburger)`
    position: fixed;
    top: 7px;
    left: 0;
    z-index: 5;

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        top: 0;
    }
`;

export const NavbarLogo = styled.span<{ isHamburgerVisible?: boolean }>`
    span {
        padding-left: ${({ isHamburgerVisible }) => (isHamburgerVisible ? '70px' : '0')} !important;

        @media (max-width: ${({ theme }) => theme.devices.desktop}) {
            padding-left: ${({ isHamburgerVisible }) => (isHamburgerVisible ? '45px' : '20px')} !important;
            text-align: center;
            display: block;
            text-overflow: ellipsis;
            max-width: 250px;
            white-space: nowrap;
            overflow: hidden;
        }
    }
`;
