import { HealthcareServiceOption } from 'medrefer-web-sdk/api/models';
import { useEffect, useState } from 'react';
import { api } from 'medrefer-web-sdk/api';
import { healthcareServiceFactory } from './factories';

export const useHealthcareServiceOptions = (): HealthcareServiceOption[] | undefined => {
    const [options, setOptions] = useState<HealthcareServiceOption[] | undefined>();

    useEffect(() => {
        api.getOrganizationHealthcareServices().then((data) => {
            setOptions(data.map(healthcareServiceFactory));
        });
    }, []);

    return options;
};
