import React, { useMemo } from 'react';
import { ApiErrorResponse } from '../../../api/ApiTypes';
import { InformationBubble, InformationBubbleTypes } from '.';

type Props = {
    apiErrors?: ApiErrorResponse;
};

/**
 * Display only errors from API
 */
export const ApiErrorMessage = ({ apiErrors }: Props) => {
    const message = useMemo(() => {
        let retValue = '';

        if (apiErrors?.non_field_errors != null) {
            retValue = apiErrors.non_field_errors[0].message;
        } else if (apiErrors) {
            retValue = Object.values(apiErrors)[0][0].message;
        }

        return retValue;
    }, [apiErrors]);

    if (!message) {
        return null;
    }

    return <InformationBubble type={InformationBubbleTypes.warning}>{message}</InformationBubble>;
};
