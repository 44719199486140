import React, { ReactNode } from 'react';
import { Button, H1, Modal } from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { InsuranceStatus, SlotBookingData, UserProfile, UserRole } from 'medrefer-web-sdk/api/models';
import { api } from 'medrefer-web-sdk/api';
import { FormikErrors } from 'formik';
import useObjectFetch from 'hooks/useObjectFetch';
import { AgreementsData } from '../AgreementsInfo/AgreementsData';
import { useOptionalFields } from '../ServiceSlots/RequestWaitingRoomModal/useOptionalFields';
import { useSelector } from 'react-redux';
import { getAuthUser, getBrand } from '../../../features/auth/selectors';
import CompleteUserInfoForm from '../../Auth/CompleteUserInformation/CompleteUserInfoForm';
import useLoginFlow from '../../Auth/useLoginFlow';
import { findEnumValueByStringKey } from '../ServiceSlots/utils';

type Props = {
    values: SlotBookingData;
    isLoading: boolean;
    onDiscard: () => void;
    onConfirm: () => void;
    attachments?: File[];
    agreements?: ReactNode;
    errors?: FormikErrors<SlotBookingData>;
    useUserInfoModal?: boolean;
};

export const ConfirmBookingModal = (props: Props) => {
    const organization = useSelector(getBrand);
    const { hasMissingInfo, getFields, getMandatoryFields } = useOptionalFields(organization);
    const authUser = useSelector(getAuthUser);
    const { updateProfile } = useLoginFlow();

    return (
        <Modal
            isCentered={false}
            state={{
                isOpen: true,
                toggleModal: props.onDiscard,
            }}
            body={
                props.useUserInfoModal && hasMissingInfo(authUser) ? (
                    <CompleteUserInfoForm
                        user={authUser}
                        updateProfile={updateProfile}
                        onFormSubmit={() => {
                            return;
                        }}
                        fields={getFields()}
                        mandatory_fields={getMandatoryFields()}
                    />
                ) : (
                    <ConfirmBookingBody {...props} />
                )
            }
        />
    );
};

const ConfirmBookingBody = ({
    values,
    attachments,
    onConfirm,
    isLoading,
    agreements,
    errors,
    useUserInfoModal,
}: Props) => {
    const { t } = useTranslation();
    const appointmentReason = useObjectFetch(values.reason, (key) => api.getAppointmentReason(parseInt(key)));
    const authUser = useSelector(getAuthUser);

    const getUser = (): UserProfile => {
        return {
            date_joined: '',
            language: 'pl',
            profiles_count: 0,
            role: UserRole.patient,
            id: 0,
            email: values.email,
            first_name: values.first_name,
            last_name: values.last_name,
            patient: {
                insurance_status: findEnumValueByStringKey(
                    InsuranceStatus,
                    (useUserInfoModal && authUser?.patient?.insurance_status) || values.insurance_status || '',
                ),
                insurance_number:
                    (useUserInfoModal && authUser?.patient?.insurance_number) || values.insurance_number || '',
                date_of_birth: values.date_of_birth?.toDateString() || '',
                city: (useUserInfoModal && authUser?.patient?.city) || values.city || '',
                street: (useUserInfoModal && authUser?.patient?.street) || values.street || '',
                email_agreement: values.email_agreement,
                sms_agreement: values.sms_agreement,
                post_code: '',
            },
            phone: values.phone || '',
            personal_title: values.personal_title || '',
        };
    };

    return (
        <>
            <H1 className="mb-4">{t('slotBooking:confirmation')}</H1>
            <AgreementsData
                managing_user={values.slot.managing_user}
                health_service={values.slot.healthcare_service}
                duration={[values.slot.date_from, values.slot.date_to]}
                message={values.comment}
                attachments={attachments}
                appointment_reason={appointmentReason?.name}
                date_from={values.slot.date_from}
                user={getUser()}
            />
            {agreements}
            <Row>
                <Col className="m-auto mt-2" xs={12} md={8}>
                    <Button
                        disabled={errors?.terms_agreement !== undefined || !values.terms_agreement}
                        style={
                            errors?.terms_agreement === undefined && values.terms_agreement
                                ? {}
                                : {
                                      backgroundColor: 'lightgray',
                                      borderColor: 'lightgray',
                                      boxShadow: '0px 20px 25px lightgray',
                                  }
                        }
                        onClick={() => {
                            if (typeof errors === 'undefined' || (errors && !Object.keys(errors).length)) {
                                onConfirm();
                            }
                        }}
                        isLoading={isLoading}
                    >
                        {t('newAppointmentForm:button_confirm_message')}
                    </Button>
                </Col>
            </Row>
        </>
    );
};
