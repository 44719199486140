import React, { ReactElement } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import LanguageSwitchForm from './LanguageSwitchForm';

import { Label, FormGroup } from 'medrefer-web-sdk/web-kit';

export function LanguageSwitch(): ReactElement {
    const { t } = useTranslation();

    return (
        <div className="mt-2">
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('languageSwitch:change_lang_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-left">
                        <LanguageSwitchForm />
                    </Col>
                </Row>
            </FormGroup>
        </div>
    );
}
