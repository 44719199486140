import { Dispatch, SetStateAction, useState } from 'react';

export interface PopoverState {
    togglePopover: () => void;
    isOpen: boolean;
    target: DOMRect | undefined;
    setTarget: Dispatch<SetStateAction<DOMRect | undefined>>;
    height?: number;
    width?: number;
}

export const usePopoverState = ({
    target: initialTarget,
    actionOnClose,
    height,
    width,
}: {
    target?: DOMRect;
    // Optional action fired when closing popover
    actionOnClose?: () => void;
    height?: number;
    width?: number;
}): PopoverState => {
    const [isOpen, setIsOpen] = useState(false);
    const [target, setTarget] = useState<DOMRect | undefined>(initialTarget);

    const togglePopover = () => {
        if (isOpen && actionOnClose) {
            actionOnClose();
        }
        setIsOpen(!isOpen);
    };

    return { isOpen, togglePopover, target, setTarget, height, width };
};
