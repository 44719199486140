import styled from 'styled-components';

export const ArrowButtonContainer = styled.button<{ direction: 'right' | 'left' }>`
    width: 32px !important;
    height: 32px;
    border: none;
    border-radius: 100%;
    background: none;
    transition: 0.2s;

    &:hover {
        background: ${({ theme }) => theme.grey100};
    }

    img {
        width: 7px;
        height: auto;
        transform: translateY(-1px)
            ${({ direction }) => (direction === 'right' ? 'translateX(1px)' : 'translateX(-2px)')};
    }
`;
