import React, { FC, useState } from 'react';
import {
    CalendarCol,
    TimelineWrapper,
    Timeline,
} from 'components/shared/ServiceSlots/CalendarTimeline/CalendarTimeline.styled';
import { addDays } from 'utils/dates';
import { CalendarHook } from 'components/shared/ServiceSlots/SlotsCalendar/SlotsCalendar.hooks';
import { NodeCallback } from 'medrefer-web-sdk/web-kit/hooks';
import Expand from 'components/shared/Expand';
import { DayTitle } from 'components/shared/ServiceSlots/CalendarTimeline/DayTitle';
import { SlotTime } from 'components/shared/ServiceSlots/CalendarTimeline/SlotTime';
import { EmptyCalendar } from 'components/shared/ServiceSlots/CalendarTimeline/EmptyCalendar';
import { ShowMoreHours } from 'components/shared/ServiceSlots/CalendarTimeline/ShowMoreHours';

export interface CalendarTimelineProps {
    calendar: CalendarHook;
    daysRangeSize: number;
    calendarRef: NodeCallback;
    handleSlotClick: (slotKey: string) => void;
}

export const CalendarTimeline: FC<CalendarTimelineProps> = ({
    calendar,
    daysRangeSize,
    calendarRef,
    handleSlotClick,
}: CalendarTimelineProps) => {
    const [showMoreHours, setShowMoreHours] = useState(false);
    const visibleDays = calendar.daysSlots;
    const maxDaySize = Math.max(...visibleDays.map((c) => c.length));
    const fullWidth = 100;
    const columnWidth = 100 / daysRangeSize;

    const toggleShowMore = () => {
        setShowMoreHours((prev) => !prev);
    };

    return (
        <TimelineWrapper ref={calendarRef}>
            <Timeline fullWidth={fullWidth}>
                {visibleDays.map((slots, colIdx) => (
                    <CalendarCol key={colIdx} widthPercent={columnWidth}>
                        <DayTitle date={addDays(calendar.dateFrom, colIdx)} />

                        {maxDaySize > 0 && (
                            <>
                                {Array(Math.max(4))
                                    .fill(0)
                                    .map((_, i) => (
                                        <SlotTime handleSlotClick={handleSlotClick} key={i} slot={slots[i]} />
                                    ))}
                                <Expand open={showMoreHours}>
                                    {Array(maxDaySize)
                                        .slice(4)
                                        .fill(0)
                                        .map((_, i) => (
                                            <SlotTime handleSlotClick={handleSlotClick} key={i} slot={slots[i + 4]} />
                                        ))}
                                </Expand>
                            </>
                        )}
                    </CalendarCol>
                ))}
            </Timeline>
            {maxDaySize === 0 && <EmptyCalendar calendar={calendar} />}
            {maxDaySize > 4 && <ShowMoreHours isActive={showMoreHours} toggle={toggleShowMore} />}
        </TimelineWrapper>
    );
};
