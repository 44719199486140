import React, { ReactNode, useState } from 'react';
import { SideMenuSubDrop } from './SideMenu.styled';
import ArrowDownIcon from 'medrefer-web-sdk/web-kit/assets/icons/ArrowRightIcon.png';
import { SideMenuLink } from './SideMenuLink';
import { useLocation } from 'react-router-dom';
import { RouteName } from 'router-paths';
import useBrand from 'hooks/useBrand';

export interface ExpandableMenuLink {
    path: RouteName;
    title: string;
}

interface ExpandableMenuProps {
    title: string;
    isSideMenuOpen: boolean;
    setIsSideMenuOpen: (value: boolean) => void;
    icon: ReactNode;
    links: ExpandableMenuLink[];
    onClick: () => void;
}

export const ExpandableMenu = ({
    title,
    icon,
    isSideMenuOpen,
    setIsSideMenuOpen,
    links,
    onClick,
}: ExpandableMenuProps) => {
    const [isSubDropOpen, setIsSubDropOpen] = useState<boolean>(false);
    const location = useLocation();
    const { getPathBranded } = useBrand();
    const handleOpenSubdrop = () => {
        if (!isSideMenuOpen && !isSubDropOpen) {
            setIsSideMenuOpen(true);
            setIsSubDropOpen(true);
        }

        setIsSubDropOpen(!isSubDropOpen);
    };

    const isActive = links.map((link) => getPathBranded(link.path)).indexOf(location.pathname) !== -1;

    return (
        <li>
            <div className={`subdrop-title ${isActive ? 'active' : ''}`} onClick={handleOpenSubdrop}>
                <div style={{ padding: '0', display: 'flex' }}>
                    {icon}
                    <span>{title}</span>
                    <img
                        src={ArrowDownIcon}
                        className={`arrow-icon ${isSubDropOpen && isSideMenuOpen ? 'open' : ''}`}
                        alt="Location icon"
                    />
                </div>
            </div>
            <SideMenuSubDrop isOpen={isSubDropOpen && isSideMenuOpen}>
                {links.map((link: ExpandableMenuLink) => (
                    <SideMenuLink key={link.path} title={link.title} link={link.path} onClick={onClick} />
                ))}
            </SideMenuSubDrop>
        </li>
    );
};
