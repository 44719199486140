import React from 'react';
import { FhirQuestionnaireItem, FhirQuestionnaireItemType } from 'medrefer-web-sdk/api/models';
import {
    FormGroup,
    Input,
    Label,
    RadioTile,
    SwitchInput,
    TextArea,
    UnderlineH2 as Title,
} from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import i18n from 'i18next';
import { BaseQuestionnaireResponseFormProps } from '../QuestionnaireResponseForm.types';
import { DatePickerField } from 'medrefer-web-sdk/web-kit/components/formComponents/DatePicker/DatePicker';
import { FieldArray } from 'formik';
import { useTranslation } from 'react-i18next';

interface Props {
    items: FhirQuestionnaireItem[];
    baseProps: BaseQuestionnaireResponseFormProps;
    readOnly?: boolean;
}

interface ItemProps {
    item: FhirQuestionnaireItem;
    baseProps: BaseQuestionnaireResponseFormProps;
    index: number;
    readOnly?: boolean;
    t: (key: string) => string;
}

export const QuestionnaireItems = ({ items, baseProps, readOnly }: Props) => {
    const { t } = useTranslation();
    return (
        <FieldArray
            name="items"
            render={() => (
                <div>
                    {items.map((item, index) => {
                        let Component = null;

                        if (item.type === FhirQuestionnaireItemType.boolean) {
                            Component = QuestionnaireItemBoolean;
                        }

                        if (item.type === FhirQuestionnaireItemType.display) {
                            Component = QuestionnaireItemDisplay;
                        }

                        if (item.type === FhirQuestionnaireItemType.text) {
                            Component = QuestionnaireItemText;
                        }

                        if (item.type === FhirQuestionnaireItemType.string) {
                            Component = QuestionnaireItemString;
                        }

                        if (item.type === FhirQuestionnaireItemType.decimal) {
                            Component = QuestionnaireItemDecimal;
                        }

                        if (item.type === FhirQuestionnaireItemType.choice) {
                            Component = QuestionnaireItemChoice;
                        }

                        if (item.type === FhirQuestionnaireItemType.date) {
                            Component = QuestionnaireItemDate;
                        }
                        if (Component) {
                            return (
                                <FormGroup key={item.linkId}>
                                    <Component
                                        item={item}
                                        baseProps={baseProps}
                                        index={index}
                                        readOnly={readOnly}
                                        t={t}
                                    ></Component>
                                </FormGroup>
                            );
                        }
                        return <p key={item.linkId}>No component for this field type</p>;
                    })}
                </div>
            )}
        />
    );
};

const QuestionnaireItemDisplay = ({ item }: ItemProps) => (
    <Row>
        <Title>{item.text}</Title>
    </Row>
);
const QuestionnaireItemText = ({ item, baseProps, index, readOnly, t }: ItemProps) => (
    <Row>
        <Col xs={12} md={4}>
            <Label>{item.text}</Label>
        </Col>
        <Col xs={12} md={8}>
            <TextArea
                isError={false}
                id={`items[${index}].value`}
                name={`items[${index}].value`}
                value={baseProps.values.items[index].value as string}
                onChange={baseProps.handleChange}
                placeholder={t('questionnaire:fields.text_area_placeholder')}
                onBlur={baseProps.handleBlur}
                disabled={readOnly}
            />
        </Col>
    </Row>
);

const QuestionnaireItemString = ({ item, baseProps, index, readOnly, t }: ItemProps) => (
    <Row>
        <Col xs={12} md={4}>
            <Label>{item.text}</Label>
        </Col>
        <Col xs={12} md={8}>
            <Input
                isError={false}
                id={`items[${index}].value`}
                name={`items[${index}].value`}
                value={baseProps.values.items[index].value as string}
                placeholder={t('questionnaire:fields.input_placeholder')}
                type="text"
                onChange={baseProps.handleChange}
                onBlur={baseProps.handleBlur}
                disabled={readOnly}
            />
        </Col>
    </Row>
);

const QuestionnaireItemDecimal = ({ item, baseProps, index, readOnly, t }: ItemProps) => (
    <Row>
        <Col xs={12} md={4}>
            <Label>{item.text}</Label>
        </Col>
        <Col xs={12} md={8}>
            <Input
                isError={false}
                id={`items[${index}].value`}
                name={`items[${index}].value`}
                value={baseProps.values.items[index].value as number}
                placeholder={t('questionnaire:fields.input_placeholder')}
                type="number"
                onChange={baseProps.handleChange}
                onBlur={baseProps.handleBlur}
                step="0.01"
                disabled={readOnly}
            />
        </Col>
    </Row>
);

const QuestionnaireItemDate = ({ item, baseProps, index, readOnly }: ItemProps) => (
    <Row>
        <Col xs={12} md={4}>
            <Label>{item.text}</Label>
        </Col>
        <Col xs={12} md={8}>
            <DatePickerField
                id={`items[${index}].value`}
                name={`items[${index}].value`}
                fullWidth
                autoComplete="off"
                showYearDropdown
                scrollableYearDropdown
                maxDate={new Date()}
                dropdownMode="select"
                i18n={i18n}
                onChange={baseProps.handleChange}
                onBlur={baseProps.handleBlur}
                disabled={readOnly}
            />
        </Col>
    </Row>
);

const QuestionnaireItemBoolean = ({ item, baseProps, index, readOnly }: ItemProps) => {
    return (
        <Row>
            <Col xs={12} md={4}>
                <Label>{item.text}</Label>
            </Col>
            <Col xs={12} md={8}>
                <SwitchInput
                    id={`items[${index}].value`}
                    isChecked={!!baseProps.values.items[index].value}
                    handleChange={baseProps.handleChange}
                    isReadOnly={readOnly}
                />
            </Col>
        </Row>
    );
};

const QuestionnaireItemChoice = ({ item, baseProps, index, readOnly }: ItemProps) => {
    if (!item.answerOption) {
        return <p>There is no items</p>;
    }

    return (
        <>
            <Row>
                <Label>{item.text}</Label>
            </Row>

            {item.answerOption.map((option) => (
                <Row key={option.valueString}>
                    <RadioTile
                        name={`items[${index}].value`}
                        value={option.valueString}
                        checked={baseProps.values.items[index].value === option.valueString}
                        title={option.valueString || ''}
                        onChange={baseProps.handleChange}
                        style={{ width: 'auto', paddingRight: 10, marginLeft: 0 }}
                        className={`${readOnly && 'disabled'}`}
                        disabled={readOnly}
                    />
                </Row>
            ))}
        </>
    );
};
