import { useMemo } from 'react';
import { scheduleRuleMapper } from './helpers/scheduleRuleMapper';
import { RecurringSlotOption } from '../SchedulePopover.types';

const useOptionsPreset = (date: Date) => {
    return useMemo(() => {
        const predefinedOption = (label: RecurringSlotOption) => ({
            label,
            value: scheduleRuleMapper(label, date),
        });

        return [
            predefinedOption(RecurringSlotOption.daily),
            predefinedOption(RecurringSlotOption.weekly),
            predefinedOption(RecurringSlotOption.monthly),
            predefinedOption(RecurringSlotOption.yearly),
            predefinedOption(RecurringSlotOption.everyWeekday),
        ];
    }, [date]);
};

export default useOptionsPreset;
