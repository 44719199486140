import * as Yup from 'yup';
import { CustomRepeatFormProps, EndsOnOption, FormValues } from './CustomRepeat.types';
import { Frequency } from 'medrefer-web-sdk/api/models';
import { rruleToFreq, numberToWeekday } from '../RecurringOptionField/helpers/rrule';

export const mapPropsToValues = ({ recurringSchema }: CustomRepeatFormProps): FormValues => {
    const ruleSchema = recurringSchema?.options;

    const endsOnValue = ruleSchema?.until
        ? EndsOnOption.onDay
        : ruleSchema?.count
        ? EndsOnOption.afterX
        : EndsOnOption.never;

    return {
        interval: ruleSchema?.interval !== undefined ? ruleSchema.interval : 1,
        freq: ruleSchema?.freq !== undefined ? rruleToFreq(ruleSchema.freq) : Frequency.WEEKLY,
        days: (ruleSchema?.byweekday || []).map(numberToWeekday),
        endsOn: endsOnValue,
        until: ruleSchema?.until !== undefined && ruleSchema?.until !== null ? new Date(ruleSchema.until) : undefined,
        count: ruleSchema?.count !== undefined && ruleSchema?.count !== null ? ruleSchema.count : undefined,
    };
};

export const validationSchema = ({ t }: CustomRepeatFormProps) =>
    Yup.object().shape({
        interval: Yup.number(),
        freq: Yup.string(),
        days: Yup.array().when('freq', {
            is: Frequency.WEEKLY,
            then: Yup.array().min(1, t('calendar:schedule.day_error_message')),
        }),
    });
