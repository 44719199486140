import { appointmentActions } from 'features/appointment';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import { useState, useRef, ChangeEvent, Dispatch, SetStateAction, RefObject } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppointmentRequestsModel } from '../medrefer-web-sdk/api/models/AppointmentRequestsModel';

export interface AttachDocumentsHanlerType {
    attachments: File[];
    setAttachments: Dispatch<SetStateAction<File[]>>;
    uploadInput: RefObject<HTMLInputElement>;
    handleFileChange: (e: ChangeEvent<HTMLInputElement>, maxSizeKb?: number, maxFiles?: number) => void;
    handleFileDelete: (index: number) => void;
    uploadAttachments: (appointment: AppointmentModel) => Promise<void>;
    attachmentError: string | null;
    uploadRequestAttachments: (appointmentRequest: AppointmentRequestsModel) => Promise<void>;
}

export const useAttachDocuments = (): AttachDocumentsHanlerType => {
    const [attachments, setAttachments] = useState<File[]>([]);
    const [attachmentError, setAttachmentError] = useState<string | null>(null);
    const uploadInput = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>, maxSizeKb?: number, maxFiles?: number) => {
        const sizeLimit = maxSizeKb ? maxSizeKb : 5000;
        const fileList = uploadInput.current && uploadInput.current.files;
        const isSizeValid = fileList && sizeLimit ? fileList[0].size / 1024 <= sizeLimit : true;
        const isMaxFiles = maxFiles ? attachments.length + 1 <= maxFiles : true;
        if (fileList && attachments && isSizeValid && isMaxFiles) {
            const updatedList = [...attachments, fileList[0]];
            setAttachments(updatedList);
            setAttachmentError(null);
        } else if (!isSizeValid) {
            setAttachmentError(t('newAppointmentForm:warning_attachment_size', { size: `${sizeLimit} kb` }));
        } else if (!isMaxFiles) {
            setAttachmentError(t('newAppointmentForm:warning_attachment_max_file', { size: maxFiles }));
        }
        e.target.value = '';
    };

    const handleFileDelete = (index: number) => {
        const updatedList = [...attachments];
        if (updatedList) {
            updatedList.splice(index, 1);
            setAttachments(updatedList);
        }
    };

    const uploadAttachments = async (appointment: AppointmentModel) => {
        await dispatch(appointmentActions.uploadAttachments(appointment, attachments));
    };

    const uploadRequestAttachments = async (requestAppointment: AppointmentRequestsModel) => {
        await dispatch(appointmentActions.uploadRequestAttachments(requestAppointment, attachments));
    };

    return {
        attachments,
        setAttachments,
        uploadInput,
        handleFileChange,
        handleFileDelete,
        uploadAttachments,
        attachmentError,
        uploadRequestAttachments,
    };
};
