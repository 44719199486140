import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModalBody } from 'medrefer-web-sdk/web-kit';
import { useHistory } from 'react-router-dom';
import useBrand from 'hooks/useBrand';
import { useUserLogout } from 'hooks/useUserLogout';

interface ConfirmSignOutProps {
    toggleModal: () => void;
}

const ConfirmSignOut: React.FC<ConfirmSignOutProps> = ({ toggleModal }: ConfirmSignOutProps) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { getPathBranded } = useBrand();
    const { logoutUser } = useUserLogout();

    const handleClickAccept = async () => {
        toggleModal();
        await logoutUser();
        history.replace(getPathBranded('landing'));
    };

    const handleClickReject = () => {
        toggleModal();
    };

    return (
        <ConfirmModalBody
            title={t('confirmSignout:title')}
            message={t('confirmSignout:message')}
            confirmButton={t('confirmSignout:accept_btn_message')}
            confirmButtonTestId="confirm-signout-btn"
            declineButton={t('confirmSignout:decline_btn_message')}
            onConfirm={handleClickAccept}
            onDecline={handleClickReject}
        />
    );
};

export default ConfirmSignOut;
