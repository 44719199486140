import { useTranslation } from 'react-i18next';
import { SortTypes } from 'medrefer-web-sdk/api/models';

const useSortTypes = () => {
    const { t } = useTranslation();

    return [
        { value: SortTypes.dateAsc, key: t('sortMenu:DATE'), name: t('sortMenu:DATE_FROM_ASC') },
        { value: SortTypes.dateDesc, key: t('sortMenu:DATE'), name: t('sortMenu:DATE_FROM_DESC') },
        { value: SortTypes.nameAsc, key: t('sortMenu:NAME'), name: t('sortMenu:NAME_ASC') },
        { value: SortTypes.nameDesc, key: t('sortMenu:NAME'), name: t('sortMenu:NAME_DESC') },
    ];
};

export default useSortTypes;
