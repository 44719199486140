import { ServiceSearchValues } from 'components/PublicSearch/SlotBooking/ServiceSearchForm/ServiceSearchForm';
import { GeneralSearchItemType, SlotMode } from 'medrefer-web-sdk/api/models';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import { parseEnumProperty, parseToNumberList } from 'medrefer-web-sdk/web-kit/utils/stringHelpers';

export const useDefaultSearchValues = (): ServiceSearchValues => {
    const queryParams = useQueryParams();

    const value = queryParams.get('search');
    const type = parseEnumProperty<GeneralSearchItemType>(GeneralSearchItemType, queryParams.get('type'));
    const label = queryParams.get('label');
    let generalSearchOption;
    if (value && type && label) {
        generalSearchOption = { value, label, type };
    }

    return {
        healthcareServiceIds: parseToNumberList(queryParams.get('services') || ''),
        location: queryParams.get('location'),
        generalSearch: generalSearchOption?.value,
        generalSearchOption: generalSearchOption,
        criteriaValues: queryParams.get('criteria'),
        mode: parseEnumProperty<SlotMode>(SlotMode, queryParams.get('mode')),
    };
};
