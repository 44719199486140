import { combineReducers } from 'redux';
import { History } from 'history';

import { connectRouter } from 'connected-react-router';
import authUserReducer from 'features/auth/reducer';
import appointmentReducer from 'features/appointment/reducer';

const rootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        auth: authUserReducer,
        appointment: appointmentReducer,
    });

export default rootReducer;
