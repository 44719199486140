import { getActiveLanguage } from 'utils/i18n';

const pathsMap = {
    notFound: () => `/404`,
    branded: (organizationIdOrSlug: number | string) => `/b/${organizationIdOrSlug}`,
    admin: () => '/admin',
    landing: () => `/`,
    searchCriteria: () => `/criteria`,
    searchServiceSlots: () => `/book`,
    slotBooking: (slotId: string) => `/book/${slotId}`,
    slotBookingSuccess: (appointmentId: string) => `/book/${appointmentId}/success`,
    completeAppointmentSteps: (appointmentId: string) => `/book/${appointmentId}/steps`,
    login: () => `/login`,
    register: () => `/register`,
    forgotPassword: () => `/forgot_password`,
    resetPassword: () => `/reset_password`,

    patientPanel: () => '/patient_panel/',
    appointments: () => '/appointments/',
    appointmentsTable: () => '/appointments_table/',
    dashboardActiveItem: (appointmentId?: string) => `/appointments/${appointmentId}`,
    acceptInvitation: () => '/accept_invitation',
    settings: () => `/settings`,
    profile: () => `/profile`,
    memberProfile: () => `/member_profile`,
    organizationProfile: () => `/organization_profile`,
    newAppointment: () => `/new_appointment`,
    calendar: () => `/calendar`,
    calendarTable: () => '/calendar_table/',
    requestList: () => '/request_list/',
    manageMembers: () => '/manage_members',
    manageServices: () => '/manage_services',
    manageServiceGroups: () => '/manage_services_groups',
    manageLocations: () => '/manage_locations',
    manageSlotsCriteria: () => '/manage_slots_criteria',
    manageQuestionnaires: () => '/manage_questionnaire_templates',
    appointmentVideoCall: (appointmentId?: string) => `/appointments/${appointmentId}/call`,
    questionnaire: (appointmentId: string, questionnaireId: string) =>
        `/questionnaire/${appointmentId}/${questionnaireId}`,

    termsOfUse: () => `/terms-of-use-de.pdf`,
    privacyPolicy: () => `https://www.medrefer.de/${getActiveLanguage()}/privacy-policy`,
    imprint: () => `https://www.medrefer.de/${getActiveLanguage()}/imprint`,
};
export type PathsMap = typeof pathsMap;
export type RouteName = keyof PathsMap;

export const getPath = <TRoute extends RouteName>(route: TRoute, ...params: Parameters<PathsMap[TRoute]>) => {
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    const pathCb: (...args: any[]) => string = pathsMap[route];

    return pathCb(...params);
};

export const getPathAdmin = <TRoute extends RouteName>(route: TRoute, ...params: Parameters<PathsMap[TRoute]>) => {
    return getPath('admin') + getPath(route, ...params);
};
