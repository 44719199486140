import * as Yup from 'yup';
import { ServicesFormProps } from './ServicesForm.types';
import { Currency, PaymentMethod } from 'medrefer-web-sdk/api/models';
import { ServiceFormValues } from './ServicesForm.types';

export const mapPropsToValues = ({ activeItem }: ServicesFormProps): ServiceFormValues => {
    if (activeItem) {
        return {
            name: activeItem.name,
            comment: activeItem.comment,
            is_active: activeItem.is_active,
            categories: activeItem.categories.map((category) => category.id.toString()),
            location: activeItem.location ? activeItem.location.id.toString() : '',
            charge_applies: activeItem.price != null,
            amount: activeItem.price?.amount || '0',
            amount_currency: activeItem.price?.amount_currency || Currency.EUR,
            payment_methods: [PaymentMethod.CARD], // will be used in the future
            is_video_call_restricted: activeItem.is_video_call_restricted,
            requires_assignment_to_access: activeItem.requires_assignment_to_access,
        };
    } else {
        return {
            name: '',
            comment: '',
            is_active: true,
            categories: [],
            location: '',
            charge_applies: false,
            amount: '0',
            amount_currency: Currency.EUR,
            payment_methods: [PaymentMethod.CARD], // will be used in the future
            is_video_call_restricted: true,
            requires_assignment_to_access: true,
        };
    }
};

export const validationSchema = ({ t }: ServicesFormProps) =>
    Yup.object().shape({
        name: Yup.string().matchesName(t('form:error.wrong_format')).required(t('form:error.required')),
        is_active: Yup.boolean(),
        categories: Yup.array().of(Yup.string()).required(),
        location: Yup.string().required(t('form:error.required')),
        amount: Yup.string().when('charge_applies', {
            is: true,
            then: Yup.string().required(t('form:error.required')),
        }),
        amount_currency: Yup.string().when('charge_applies', {
            is: true,
            then: Yup.string().required(t('form:error.required')),
        }),
    });
