import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { PrimaryButton, RedButton } from 'medrefer-web-sdk/web-kit';
import { Link } from 'react-router-dom';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useBrand from 'hooks/useBrand';

interface Props {
    activeAppointment: AppointmentModel;
    openCancelAppointment: () => void;
    openRescheduleAppointment: () => void;
}

export const AppointmentView = ({ activeAppointment, openCancelAppointment, openRescheduleAppointment }: Props) => {
    const { t } = useTranslation();
    const appointmentDateFrom = moment(activeAppointment.date_from);
    const appointmentDateTo = moment(activeAppointment.date_to);
    const { getPathBranded } = useBrand();

    return (
        <div style={{ width: '100%' }}>
            <Row className="mb-1">
                <Col xs={12} className="d-flex align-items-center">
                    <p>
                        <b>{activeAppointment.patient_metadata.full_name}</b>
                        {activeAppointment.patient_metadata.date_of_birth &&
                            `, ${activeAppointment.patient_metadata.date_of_birth}`}
                    </p>
                </Col>
            </Row>
            <Row className="mb-1">
                <Col xs={12} className="d-flex align-items-center">
                    {activeAppointment.healthcare_service.name}
                </Col>
            </Row>
            <Row className="mb-4">
                <Col xs={12} className="d-flex flex-column">
                    <span>{appointmentDateFrom.format('DD MMMM YYYY')}</span>
                    <span>
                        {appointmentDateFrom.format('HH:mm')} -{appointmentDateTo.format('HH:mm')}
                    </span>
                    <span>{appointmentDateFrom.format('dddd')}</span>
                </Col>
            </Row>
            {activeAppointment && new Date(activeAppointment.date_from) > new Date() && (
                <Row className="mt-3 mb-3">
                    <Col xs={12} className="d-flex" style={{ gap: '10px' }}>
                        <PrimaryButton onClick={() => openRescheduleAppointment()}>
                            {t('patientPortal:myAppointments.reschedule_button')}
                        </PrimaryButton>
                        <RedButton onClick={() => openCancelAppointment()}>
                            {t('patientPortal:myAppointments.cancel_button')}
                        </RedButton>
                    </Col>
                </Row>
            )}
            {activeAppointment.video_call_available && (
                <Row className="mt-2 mb-3">
                    <Link
                        to={getPathBranded('appointmentVideoCall', activeAppointment.id.toString())}
                        className="d-flex col-12"
                        style={{ gap: '10px' }}
                    >
                        <PrimaryButton>{t('patientPortal:myAppointments.join_call_button')}</PrimaryButton>
                    </Link>
                </Row>
            )}
        </div>
    );
};
