import React from 'react';
import { Route, Switch } from 'react-router';
import useBrandData from 'hooks/useBrandData';
import {
    PatientGuestRoute,
    GuestRoute,
    PatientRoute,
    DashboardRoute,
    AdminRoute,
    MemberRoute,
    AuthenticatedRoute,
} from 'components/shared/routes';
import { LandingSearch } from 'components/PublicSearch/LandingSearch';
import { CriteriaSearch } from 'components/PublicSearch/CriteriaSearch';
import { SlotBooking } from 'components/PublicSearch/SlotBooking';
import { ServiceSlotsSearch } from 'components/PublicSearch/ServiceSlotsSearch';
import { SlotBookingSuccess } from 'components/PublicSearch/SlotBookingSuccess';
import { Login } from 'components/Auth/Login';
import { ForgotPassword } from 'components/Auth/ForgotPassword';
import { ResetPassword } from 'components/Auth/ResetPassword';
import { Register } from 'components/Auth/Register';
import { AcceptInvitation } from 'components/Auth/AcceptInvitation';
import useBrand from 'hooks/useBrand';

import { Profile } from 'components/Profile';
import { OrganizationProfile } from 'components/OrganizationProfile';
import { MemberProfile } from 'components/MemberProfile';
import { PatientPanel } from 'components/PatientPanel';
import { VideoCall } from 'components/VideoCall';
import Dashboard from 'components/Dashboard/Dashboard';
import { AppointmentsTable } from 'components/Dashboard/AppointmentsTable';
import { Calendar } from 'components/Calendar';
import {
    ManageMembers,
    ManageServices,
    ManageLocations,
    ManageSlotsCriteria,
    ManageServicesGroups,
    ManageQuestionnaires,
} from 'components/AdminPanel';
import { NavigationProvider } from 'providers/NavigationProvider';
import { NotFoundPage } from 'components/NotFoundPage';
import { PageActivityIndicator } from 'components/shared/PageActivityIndicator';
import { Settings } from 'components/Settings';
import { settings } from 'utils/settings';
import { PatientQuestionnaire } from 'components/PatientQuestionnaire';
import { CompleteAppointmentSteps } from 'components/PublicSearch/CompleteAppointmentSteps';
import { CalendarTable } from '../../Calendar/CalendarTable';
import { RequestsList } from '../../RequestsList/RequestsList';

export const BrandedRouter = () => {
    const { organization, organizationSlug, getPathBranded, redirectToBrand } = useBrand();
    const { isOrganizationLoading } = useBrandData();

    if (isOrganizationLoading) {
        return <PageActivityIndicator />;
    }

    if (!organization || !organizationSlug) {
        return <NotFoundPage />;
    }

    if (organization.slug != organizationSlug) {
        redirectToBrand(organization.slug);
        return null;
    }

    return (
        <NavigationProvider>
            <Switch>
                {settings.settingsEnabled && (
                    <AuthenticatedRoute exact path={getPathBranded('settings')} component={Settings} />
                )}
                <MemberRoute exact path={getPathBranded('memberProfile')} component={MemberProfile} />
                <AuthenticatedRoute exact path={getPathBranded('profile')} component={Profile} />
                <PatientRoute exact path={getPathBranded('patientPanel')} component={PatientPanel} />
                <Route exact path={getPathBranded('appointmentVideoCall', ':appointmentId')} component={VideoCall} />
                <DashboardRoute
                    exact
                    path={getPathBranded('dashboardActiveItem', ':appointmentId')}
                    component={Dashboard}
                />
                <DashboardRoute exact path={getPathBranded('calendar')} component={Calendar} />
                <DashboardRoute exact path={getPathBranded('appointments')} component={Dashboard} />
                <DashboardRoute exact path={getPathBranded('appointmentsTable')} component={AppointmentsTable} />
                <DashboardRoute exact path={getPathBranded('calendarTable')} component={CalendarTable} />
                <DashboardRoute exact path={getPathBranded('requestList')} component={RequestsList} />

                <AdminRoute exact path={getPathBranded('manageMembers')} component={ManageMembers} />
                <AdminRoute exact path={getPathBranded('manageServices')} component={ManageServices} />
                <AdminRoute exact path={getPathBranded('manageServiceGroups')} component={ManageServicesGroups} />
                <AdminRoute exact path={getPathBranded('manageLocations')} component={ManageLocations} />
                <AdminRoute exact path={getPathBranded('manageSlotsCriteria')} component={ManageSlotsCriteria} />
                <AdminRoute exact path={getPathBranded('manageQuestionnaires')} component={ManageQuestionnaires} />
                <AdminRoute exact path={getPathBranded('organizationProfile')} component={OrganizationProfile} />

                <PatientGuestRoute exact path={getPathBranded('landing')} component={LandingSearch} />
                <PatientGuestRoute exact path={getPathBranded('slotBooking', ':slotKey')} component={SlotBooking} />
                <PatientGuestRoute exact path={getPathBranded('searchCriteria')} component={CriteriaSearch} />
                <PatientGuestRoute exact path={getPathBranded('searchServiceSlots')} component={ServiceSlotsSearch} />
                <PatientGuestRoute
                    exact
                    path={getPathBranded('slotBookingSuccess', ':appointmentId')}
                    component={SlotBookingSuccess}
                />
                <PatientRoute
                    exact
                    path={getPathBranded('completeAppointmentSteps', ':appointmentId')}
                    component={CompleteAppointmentSteps}
                />
                <Route
                    exact
                    path={getPathBranded('questionnaire', ':appointmentId', ':questionnaireId')}
                    component={PatientQuestionnaire}
                />
                <Route exact path={getPathBranded('login')} component={Login} />
                <GuestRoute exact path={getPathBranded('forgotPassword')} component={ForgotPassword} />
                <GuestRoute exact path={getPathBranded('resetPassword')} component={ResetPassword} />
                <GuestRoute exact path={getPathBranded('register')} component={Register} />
                <GuestRoute exact path={getPathBranded('acceptInvitation')} component={AcceptInvitation} />
                <Route component={NotFoundPage} />
            </Switch>
        </NavigationProvider>
    );
};
