import React from 'react';
import { InformationBubble, InformationBubbleTypes } from 'medrefer-web-sdk/web-kit';
import styled from 'styled-components';

const WarningWrapper = styled.div`
    margin: 0 auto;
    padding: 30px 15px;
    width: fit-content;
`;

export const BookingSlotWarning = ({ text }: { text: string }) => {
    return (
        <WarningWrapper>
            <InformationBubble type={InformationBubbleTypes.warning}>{text}</InformationBubble>
        </WarningWrapper>
    );
};
