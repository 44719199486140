import { RecurringSlotOption } from '../../SchedulePopover.types';
import i18n from 'i18n/i18n';

export const recurringOptionText = (option: RecurringSlotOption) => {
    switch (option) {
        case RecurringSlotOption.custom:
            return i18n.t('calendar:recurringOption.custom');
        case RecurringSlotOption.dontRepeat:
            return i18n.t('calendar:recurringOption.dontRepeat');
        case RecurringSlotOption.daily:
            return i18n.t('calendar:recurringOption.daily');
        case RecurringSlotOption.weekly:
            return i18n.t('calendar:recurringOption.weekly');
        case RecurringSlotOption.monthly:
            return i18n.t('calendar:recurringOption.monthly');
        case RecurringSlotOption.yearly:
            return i18n.t('calendar:recurringOption.yearly');
        case RecurringSlotOption.everyWeekday:
            return i18n.t('calendar:recurringOption.everyWeekday');
        default:
            return '';
    }
};
