import React from 'react';
import ServiceSearchForm, {
    ServiceSearchValues,
} from 'components/PublicSearch/SlotBooking/ServiceSearchForm/ServiceSearchForm';
import ServiceSlotsList from 'components/PublicSearch/SlotBooking/ServiceSlotsList';
import { useSlotsSearch } from 'components/PublicSearch/LandingSearch/hooks/useSlotsSearch';
import { PageActivityIndicator } from 'components/shared/PageActivityIndicator';
import { inIframe } from 'utils/inIframe';
import { SlotBookingProgress } from 'components/PublicSearch/SlotBookingProgress';

export const ServiceSlotsSearch = () => {
    const { onSubmit, filterParams, servicesLoaded, defaultValues } = useSlotsSearch();
    const submitValues = (values: ServiceSearchValues) => {
        onSubmit(values, 'searchServiceSlots');
    };

    if (!servicesLoaded) {
        return <PageActivityIndicator />;
    }

    return (
        <>
            <SlotBookingProgress activeStep="pickSlot" />
            {!inIframe() && (
                <div className="mt-4">
                    <ServiceSearchForm onSubmit={submitValues} {...defaultValues} />
                </div>
            )}
            <ServiceSlotsList filterParams={filterParams} />
        </>
    );
};
