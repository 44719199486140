import { FormProps } from './BookingForm';
import * as Yup from 'yup';
import { SlotBookingFormData, SlotBookingStep } from 'components/PublicSearch/SlotBooking/SlotBooking.types';
import { FormikBag } from 'formik';
import { toNullableDate } from 'utils/dates';

export const mapPropsToValues = ({
    user,
    slot,
    submittedValues,
    fields,
    mandatory_fields,
}: FormProps): SlotBookingFormData => {
    const reasonId = slot.available_for_reasons.length === 1 ? slot.available_for_reasons[0].id.toString() : undefined;
    return {
        slot: slot,
        mode: slot.mode,
        email: user?.email || '',
        personal_title: '',
        first_name: '',
        last_name: '',
        phone: user?.phone || '',
        insurance_number: '',
        insurance_status: '',
        date_of_birth: toNullableDate(user?.patient?.date_of_birth),
        reason: reasonId ? reasonId : submittedValues?.reason ? submittedValues?.reason : undefined,
        password: '',
        confirm_password: '',
        comment: submittedValues?.comment || '',
        sms_agreement: user?.patient?.sms_agreement || false,
        email_agreement: user?.patient?.email_agreement || false,
        terms_agreement: false,
        token: '',
        step: SlotBookingStep.base,
        fields: fields,
        mandatory_fields: mandatory_fields,
        street: user?.patient?.street || '',
        city: user?.patient?.city || '',
    };
};

export const validationSchema = ({ t }: FormProps) => {
    return Yup.object().shape({
        email: Yup.string()
            .email(t('newAppointmentForm:warning_email_matches'))
            .required(t('newAppointmentForm:warning_email_required')),
        reason: Yup.string().required(t('newAppointmentForm:warning_reason_for_appointment_required')),
        comment: Yup.string()
            .min(5, t('newAppointmentForm:warning_message_min'))
            .max(500, t('newAppointmentForm:warning_message_max')),
        terms_agreement: Yup.bool().when('step', {
            is: (step) => [SlotBookingStep.confirmation, SlotBookingStep.registerConfirmation].includes(step),
            then: Yup.bool().oneOf([true], t('newAppointmentForm:warning_terms_agreement')),
        }),
        first_name: Yup.string()
            .matchesName(t('newAppointmentForm:warning_first_name_matches'))
            .when('step', {
                is: SlotBookingStep.register,
                then: Yup.string().required(t('newAppointmentForm:warning_first_name_required')),
            }),
        last_name: Yup.string()
            .matchesName(t('newAppointmentForm:warning_last_name_matches'))
            .when('step', {
                is: SlotBookingStep.register,
                then: Yup.string().required(t('newAppointmentForm:warning_last_name_required')),
            }),
        date_of_birth: Yup.date()
            .nullable()
            .when('step', {
                is: (step) => [SlotBookingStep.register].includes(step),
                then: Yup.date()
                    .typeError(t('newAppointmentForm:warning_date_of_birth_type'))
                    .required(t('newAppointmentForm:warning_date_of_birth_required')),
            }),
        insurance_status: Yup.string().when(['step', 'fields', 'mandatory_fields'], {
            is: (step) => [SlotBookingStep.register].includes(step),
            then: Yup.string().when('mandatory_fields', {
                is: (mandatory_fields) => mandatory_fields.includes('insurance_status'),
                then: Yup.string().required(t('newAppointmentForm:warning_insurance_status_required')),
            }),
        }),
        insurance_number: Yup.string().when(['step', 'fields', 'mandatory_fields'], {
            is: (step) => [SlotBookingStep.register].includes(step),
            then: Yup.string().when('mandatory_fields', {
                is: (mandatory_fields) => mandatory_fields.includes('insurance_number'),
                then: Yup.string().required(t('newAppointmentForm:warning_insurance_number_required')),
            }),
        }),
        street: Yup.string().when(['step', 'fields', 'mandatory_fields'], {
            is: (step) => [SlotBookingStep.register].includes(step),
            then: Yup.string().when('mandatory_fields', {
                is: (mandatory_fields) => mandatory_fields.includes('address'),
                then: Yup.string().required(t('newAppointmentForm:warning_address_required')),
            }),
        }),
        phone: Yup.string().when(['step', 'fields', 'mandatory_fields'], {
            is: (step) => [SlotBookingStep.register].includes(step),
            then: Yup.string().when(['mandatory_fields', 'fields'], {
                is: (mandatory_fields, fields) => mandatory_fields.includes('phone') || fields.includes('phone'),
                then: Yup.string()
                    .matchesPhone(t('newAppointmentForm:warning_phone_matches'))
                    .when('mandatory_fields', {
                        is: (mandatory_fields) => mandatory_fields.includes('phone'),
                        then: Yup.string().required(t('newAppointmentForm:warning_phone_required')),
                    }),
            }),
        }),
        /*phone: Yup.string()
            .matchesPhone(t('newAppointmentForm:warning_phone_matches'))
            .when(['sms_agreement', 'step'], {
                is: (sms_agreement, step) =>
                    (step === SlotBookingStep.registerConfirmation || step === SlotBookingStep.confirmation) &&
                    sms_agreement,
                then: Yup.string().required(t('newAppointmentForm:warning_phone_required')),
            }),*/
        token: Yup.string().when('step', {
            is: (step) => [SlotBookingStep.loginOtpCode, SlotBookingStep.registerOtpCode].includes(step),
            then: Yup.string().required(t('otp:warning_code_required')),
        }),
        password: Yup.string()
            .when('step', {
                is: (step) => [SlotBookingStep.register].includes(step),
                then: Yup.string().matchesPassword(
                    t('registerForm:warning_password_min', { min: 8 }),
                    t('registerForm:warning_password_too_simple'),
                ),
            })
            .when('step', {
                is: (step) => [SlotBookingStep.login, SlotBookingStep.register].includes(step),
                then: Yup.string().required(t('newAppointmentForm:warning_password_required')),
            }),
        confirm_password: Yup.string().when('step', {
            is: (step) => [SlotBookingStep.register].includes(step),
            then: Yup.string()
                .oneOf([Yup.ref('password'), ''], t('newAppointmentForm:warning_password_confirmation'))
                .required(t('newAppointmentForm:warning_password_confirmation')),
        }),
    });
};

export const handleSubmit = (
    values: SlotBookingFormData,
    { props, setSubmitting, setTouched, setFieldValue }: FormikBag<FormProps, SlotBookingFormData>,
) => {
    const goStep = (step: SlotBookingStep) => {
        setSubmitting(false);
        setTouched({});
        setFieldValue('step', step);
    };
    switch (values.step) {
        case SlotBookingStep.base:
            if (props.user) {
                props.requestConfirmation(values).then(() => goStep(SlotBookingStep.confirmation));
            } else {
                props.checkEmailTaken(values).then((taken) => {
                    if (taken) {
                        goStep(SlotBookingStep.login);
                    } else {
                        goStep(SlotBookingStep.register);
                    }
                });
            }
            break;
        case SlotBookingStep.login:
            props.submitLoginData(values).then(() => goStep(SlotBookingStep.loginOtpCode));
            break;
        case SlotBookingStep.loginOtpCode:
            props.submitLoginOtpCode(values).then(() => goStep(SlotBookingStep.confirmation));
            break;
        case SlotBookingStep.register:
            props.submitRegisterData(values).then(() => goStep(SlotBookingStep.registerOtpCode));
            break;
        case SlotBookingStep.registerOtpCode:
            props.requestConfirmation(values).then(() => goStep(SlotBookingStep.registerConfirmation));
            break;
        case SlotBookingStep.confirmation:
            props.requestConfirmation(values);
            break;
        case SlotBookingStep.registerConfirmation:
            props.requestConfirmation(values);
            break;
    }
};
