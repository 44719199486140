import { SlotCriteria, SlotCriteriaOperator } from 'medrefer-web-sdk/api/models';
import { FormikBag } from 'formik';
import { flattenObjLists } from 'medrefer-web-sdk/web-kit/utils/arrayHelpers';
import { CriteriaSearchContext } from 'components/PublicSearch/CriteriaSearch/hooks/useCriteriaSearchContext';

export interface FormProps {
    slotsCriteria: SlotCriteria[];
    context: CriteriaSearchContext;
    onSubmit: (valueIds: number[]) => void;
}

export interface FormValues {
    selectedValues: SelectedValues;
}

export type SelectedValues = {
    [criteriaId: number]: number[];
};

interface SlotCriteriaVisibilityContext extends CriteriaSearchContext {
    selectedValues: SelectedValues;
}

export const mapPropsToValues = ({ slotsCriteria }: FormProps): FormValues => {
    return {
        selectedValues: slotsCriteria.reduce((result: SelectedValues, next) => {
            result[next.id] = [];
            return result;
        }, {}),
    };
};

export const validate = (values: FormValues, props: FormProps) => {
    const errors: { [key: number]: string } = {};
    let hasErrors = false;
    const selected = values.selectedValues;

    props.slotsCriteria.forEach((criteria) => {
        const visibilityContext = {
            selectedValues: selected,
            ...props.context,
        };
        if (criteria.required && selected[criteria.id].length === 0 && isCriteriaVisible(criteria, visibilityContext)) {
            errors[criteria.id] = '';
            hasErrors = true;
        }
    });

    return hasErrors ? { selectedValues: errors } : {};
};

export const handleSubmit = (values: FormValues, { props }: FormikBag<FormProps, FormValues>) => {
    props.onSubmit(flattenObjLists(values.selectedValues));
};

export const isCriteriaVisible = (criteria: SlotCriteria, visibilityContext: SlotCriteriaVisibilityContext) => {
    const condition = criteria.enable_when;

    if (condition == null) {
        return true;
    }

    if (condition.operator !== SlotCriteriaOperator.EQUALS) {
        return false;
    }

    if (condition.criteria && condition.criteria_value) {
        return visibilityContext.selectedValues[condition.criteria.id].includes(condition.criteria_value.id);
    }

    if (condition.healthcare_service) {
        return condition.healthcare_service.key === visibilityContext.healthcareServiceKey;
    }

    return false;
};
