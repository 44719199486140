import React from 'react';
import { ColumnHeader } from 'components/shared/ServiceSlots/CalendarTimeline/CalendarTimeline.styled';
import moment from 'moment';

export const DayTitle = ({ date }: { date: Date }) => {
    return (
        <ColumnHeader>
            <strong>{moment(date).format('dddd')}</strong>
            <p>{moment(date).format('D MMM')}</p>
        </ColumnHeader>
    );
};
