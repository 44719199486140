import { AdminPanelContainer, FormActivityIndicatorContainer } from 'components/AdminPanel/AdminPanel.styled';
import { RouteBreadcrumbs } from 'components/shared/RouteBreadcrumbs';
import { AdminOrganization } from 'medrefer-web-sdk/api/models';
import { ActivityIndicator, IconButtonAlt } from 'medrefer-web-sdk/web-kit';
import { DataTable } from 'components/shared/DataTable';
import React, { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import { useManageOrganizations } from './ManageOrganizations.hook';
import { organizationAdminColumns } from './ManageOrganizations.constants';
import HamburgerMenu from 'assets/HamburgerMenu.svg';
import { Row } from 'react-table';
import { getPath } from 'router-paths';
import { AdminOrganizationForm } from './AdminOrganizationForm';
import { useOptionalFields } from '../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';
import { useEmailText } from './useEmailText';

export const ManageOrganizations: FC = () => {
    const { t } = useTranslation();
    //const organization = useSelector(getBrand);
    const { activeItem, setActiveItem, request, editOrganization, createOrganization, ...rest } =
        useManageOrganizations();
    const { getMandatoryFields, getFields, fieldsOptions } = useOptionalFields(activeItem || undefined);
    const { GetEmailTextArray } = useEmailText();

    // eslint-disable-next-line @typescript-eslint/ban-types
    const getExtraActions = (row: Row<object>): undefined | Array<ReactNode> => {
        const rowObject = row.original as AdminOrganization;
        return [
            <IconButtonAlt
                key={rowObject.id}
                onClick={() => {
                    window.open(getPath('branded', rowObject.slug), '_blank');
                }}
            >
                <ReactSVG src={HamburgerMenu} />
            </IconButtonAlt>,
        ];
    };

    return (
        <AdminPanelContainer>
            <DataTable
                title={t('adminPanel:manageOrganizations.title')}
                actionColumnTitle={t('adminPanel:table.columns.actions')}
                noItemsMessage={t('adminPanel:table.no_items_message')}
                columns={organizationAdminColumns(t)}
                addItemForm={
                    <AdminOrganizationForm
                        getEmailText={GetEmailTextArray}
                        onFormSubmit={createOrganization}
                        request={request}
                        mandatory_fields={getMandatoryFields()}
                        fields={getFields()}
                        fieldOptions={fieldsOptions}
                    />
                }
                editItemForm={
                    activeItem ? (
                        <AdminOrganizationForm
                            getEmailText={GetEmailTextArray}
                            onFormSubmit={editOrganization}
                            activeItem={activeItem}
                            request={request}
                            mandatory_fields={getMandatoryFields()}
                            fields={getFields()}
                            fieldOptions={fieldsOptions}
                        />
                    ) : (
                        <FormActivityIndicatorContainer>
                            <ActivityIndicator />
                        </FormActivityIndicatorContainer>
                    )
                }
                setActiveItem={setActiveItem}
                addButtonText={t('adminPanel:table.controls.addButtonText')}
                previousPageText={t('adminPanel:table.controls.previousPageText')}
                nextPageText={t('adminPanel:table.controls.nextPageText')}
                breadcrumbs={<RouteBreadcrumbs />}
                getExtraActions={getExtraActions}
                {...rest}
            />
        </AdminPanelContainer>
    );
};
