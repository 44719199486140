import { GroupBase, OptionsOrGroups, PropsValue } from 'react-select';
import { Option, RawPropsValue } from './Select.types';

export const getSelectValue = (
    value: RawPropsValue,
    options: OptionsOrGroups<Option, GroupBase<Option>> | undefined,
    isMulti: boolean | undefined,
): PropsValue<Option> => {
    options = options || [];
    const values: Option[] = [];

    options.forEach((option) => {
        values.push(...(Array.isArray(option) ? option : [option]).filter((o) => isOptionSelected(o, value)));
    });

    return isMulti ? values : values[0] || null;
};

const isOptionSelected = (option: Option, value: string | string[] | undefined) => {
    return Array.isArray(value) ? value?.indexOf(option.value) >= 0 : value === option.value;
};

export const getAsyncValue = async (
    value: RawPropsValue,
    options: OptionsOrGroups<Option, GroupBase<Option>> | undefined,
    isMulti: boolean | undefined,
    recoverOptions?: (missingValues: string[]) => Promise<Option[]>,
): Promise<Option[]> => {
    const foundOptions = getSelectValue(value, options, isMulti);
    const missingValues = getMissingValues(value, foundOptions);
    const missingOptions = await recoverMissingOptions(missingValues, recoverOptions);
    return mergeOptions(foundOptions, missingOptions);
};

const getMissingValues = (value: RawPropsValue, foundOptions: PropsValue<Option>) => {
    let missingKeys: string[] = [];
    if (Array.isArray(foundOptions)) {
        missingKeys = (value as string[]).filter((v) => foundOptions.findIndex((k) => k.value === v) === -1);
    } else if (value && !foundOptions) {
        missingKeys = [value as string];
    }
    return missingKeys;
};

const recoverMissingOptions = async (
    missingValues: string[],
    recoverOptions?: (missingValues: string[]) => Promise<Option[]>,
) => {
    if (recoverOptions && missingValues.length > 0) {
        return await recoverOptions(missingValues);
    } else {
        return [];
    }
};

const mergeOptions = (foundOptions: PropsValue<Option>, missingOptions: Option[]) => {
    return missingOptions.concat(foundOptions || []);
};

export const getFormFieldValue = (option: PropsValue<Option>) => {
    let optionValue;
    if (option == null) {
        optionValue = null;
    } else if (Array.isArray(option)) {
        optionValue = option.map((o) => o.value);
    } else {
        optionValue = (option as Option).value;
    }
    return optionValue;
};

export const idNameToOption = ({ id, name }: { id: number | string; name: string }): Option => {
    return {
        value: id.toString(),
        label: name,
    };
};
