import React, { useEffect } from 'react';

import { FlatList, FlatListProps } from '../FlatList';
import { LoadMoreButton } from './LoadMoreButton';

interface LoadMoreListProps<Item = unknown> extends FlatListProps<Item> {
    hasMore?: boolean;
    isLoading?: boolean;
    loadMore?: (prev: Array<Item>) => void;
    initialLoad?: boolean;
    loadMoreText?: string;
}

export const LoadMoreList = <Item,>({
    data,
    hasMore,
    isLoading,
    loadMore,
    initialLoad,
    loadMoreText,
    ...rest
}: LoadMoreListProps<Item>) => {
    useEffect(() => {
        if (initialLoad === true) {
            loadMoreIfNeeded();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadMoreIfNeeded = () => {
        if (loadMore && hasMore) {
            loadMore(data);
        }
    };

    return (
        <FlatList
            data={data}
            ListFooterComponent={
                <LoadMoreButton
                    hasMore={hasMore}
                    isLoading={isLoading}
                    loadMore={loadMoreIfNeeded}
                    text={loadMoreText}
                />
            }
            {...rest}
        />
    );
};
