import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FormikProps } from 'formik';

import { FormProps, UserProfileEditFormData } from './EditProfileForm.schema';
import {
    DatePicker,
    ErrorMessage,
    FormGroup,
    FormSelect,
    Input,
    Label,
    UnderlineH2 as Title,
} from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import i18n from 'i18next';
import { useInsuranceStatusOptions } from 'hooks/options/useInsuranceStatusOptions';
import { useSelector } from 'react-redux';
import { getBrand } from '../../../features/auth/selectors';
import {
    EOptionalFields,
    useOptionalFields,
} from '../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';

interface EditPatientFormProps {
    baseProps: FormProps & FormikProps<UserProfileEditFormData>;
}

const EditPatientForm: FC<EditPatientFormProps> = ({ baseProps }: EditPatientFormProps) => {
    const { t } = useTranslation();
    // TODO: WILL BE RESTORED IN THE FUTURE
    // const insuranceCompanies = useInsuranceCompanies();
    // const insuranceCompanyOptions = insuranceCompanies.map(idNameToOption);
    const insuranceStatusOptions = useInsuranceStatusOptions();
    const organization = useSelector(getBrand);
    const { isVisible } = useOptionalFields(organization);

    return (
        <>
            <Title>{t('editProfileForm:header_patient')}</Title>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('editProfileForm:title_date_of_birth')}</Label>
                    </Col>
                    <Col md={8} className="text-left">
                        <DatePicker
                            isError={!!(baseProps.touched.dateOfBirth && baseProps.errors.dateOfBirth)}
                            name="dateOfBirth"
                            value={baseProps.values.dateOfBirth}
                            placeholder={t('editProfileForm:placeholder_date_of_birth')}
                            fullWidth
                            autoComplete="off"
                            showYearDropdown
                            scrollableYearDropdown
                            maxDate={new Date()}
                            dropdownMode="select"
                            i18n={i18n}
                        />
                    </Col>
                </Row>
                {baseProps.touched.dateOfBirth && baseProps.errors.dateOfBirth && (
                    <ErrorMessage className="text-left mt-1">{baseProps.errors.dateOfBirth}</ErrorMessage>
                )}
            </FormGroup>
            {isVisible(EOptionalFields.insurance_status) && (
                <FormGroup>
                    <Row className="primary">
                        <Col xs={12} md={4}>
                            <Label isRequired={baseProps.mandatory_fields.includes('insurance_status')}>
                                {t('editProfileForm:title_insurance_status')}
                            </Label>
                        </Col>
                        <Col md={8} className="text-left">
                            <Field
                                name="insuranceStatus"
                                options={insuranceStatusOptions}
                                component={FormSelect}
                                placeholder={t('editProfileForm:placeholder_insurance_status')}
                                isMulti={false}
                                isError={!!(baseProps.touched.insuranceStatus && baseProps.errors.insuranceStatus)}
                                onBlur={baseProps.handleBlur}
                            />
                        </Col>
                    </Row>
                    {baseProps.touched.insuranceStatus && baseProps.errors.insuranceStatus && (
                        <ErrorMessage className="text-left mt-1">{baseProps.errors.insuranceStatus}</ErrorMessage>
                    )}
                </FormGroup>
            )}
            {/* TODO: TEMPORARY: Will be restored in near future*/}
            {/* <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('editProfileForm:title_insurance_company')}</Label>
                    </Col>
                    <Col md={8} className="text-left">
                        <Field
                            name="insuranceCompany"
                            options={insuranceCompanyOptions}
                            component={FormSelect}
                            placeholder={t('editProfileForm:placeholder_insurance_company')}
                            isMulti={false}
                            isError={
                                baseProps.touched.insuranceCompany && baseProps.errors.insuranceCompany ? true : false
                            }
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
                {baseProps.touched.insuranceCompany && baseProps.errors.insuranceCompany && (
                    <ErrorMessage className="text-left mt-1">{baseProps.errors.insuranceCompany}</ErrorMessage>
                )}
            </FormGroup> */}
            {isVisible(EOptionalFields.insurance_number) && (
                <FormGroup>
                    <Row className="primary">
                        <Col xs={12} md={4}>
                            <Label isRequired={baseProps.mandatory_fields.includes('insurance_number')}>
                                {t('editProfileForm:title_insurance_number')}
                            </Label>
                        </Col>
                        <Col md={8} className="text-left">
                            <Input
                                isError={!!(baseProps.touched.insuranceNumber && baseProps.errors.insuranceNumber)}
                                id="insuranceNumber"
                                type="text"
                                onChange={baseProps.handleChange}
                                value={baseProps.values.insuranceNumber}
                                onBlur={baseProps.handleBlur}
                            />
                        </Col>
                    </Row>
                    {baseProps.touched.insuranceNumber && baseProps.errors.insuranceNumber && (
                        <ErrorMessage className="text-left mt-1">{baseProps.errors.insuranceNumber}</ErrorMessage>
                    )}
                </FormGroup>
            )}
            {isVisible(EOptionalFields.address) && (
                <>
                    <FormGroup>
                        <Row className="primary">
                            <Col xs={12} md={4}>
                                <Label isRequired={baseProps.mandatory_fields.includes('address')}>
                                    {t('editProfileForm:title_street')}
                                </Label>
                            </Col>
                            <Col md={8} className="text-left">
                                <Input
                                    isError={!!(baseProps.touched.street && baseProps.errors.street)}
                                    id="street"
                                    type="text"
                                    onChange={baseProps.handleChange}
                                    value={baseProps.values.street}
                                    onBlur={baseProps.handleBlur}
                                />
                            </Col>
                        </Row>
                        {baseProps.touched.street && baseProps.errors.street && (
                            <ErrorMessage className="text-left mt-1">{baseProps.errors.street}</ErrorMessage>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Row className="primary">
                            <Col xs={12} md={4}>
                                <Label isRequired={baseProps.mandatory_fields.includes('address')}>
                                    {t('editProfileForm:title_city')}
                                </Label>
                            </Col>
                            <Col md={8} className="text-left">
                                <Input
                                    isError={!!(baseProps.touched.city && baseProps.errors.city)}
                                    id="city"
                                    type="text"
                                    onChange={baseProps.handleChange}
                                    value={baseProps.values.city}
                                    onBlur={baseProps.handleBlur}
                                />
                            </Col>
                        </Row>
                        {baseProps.touched.city && baseProps.errors.city && (
                            <ErrorMessage className="text-left mt-1">{baseProps.errors.city}</ErrorMessage>
                        )}
                    </FormGroup>
                </>
            )}
        </>
    );
};

export default EditPatientForm;
