import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { pageStyles } from '../Auth.styled';
import { AuthPage } from 'components/Auth/AuthPage';
import { RegisterForm } from './RegisterForm';
import useBrand from 'hooks/useBrand';
import useRegisterFlow from '../useRegisterFlow';
import { useOptionalFields } from '../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';
import { useSelector } from 'react-redux';
import { getBrand } from '../../../features/auth/selectors';

export const Register: FC = () => {
    const { t } = useTranslation();
    const { request, emailTaken, otpCodeSent, submitCredentials, submitOtp } = useRegisterFlow();
    const { getPathBranded } = useBrand();
    const organization = useSelector(getBrand);
    const { getFields, getMandatoryFields } = useOptionalFields(organization);

    return (
        <AuthPage pageTitle={t('registerPage:h1')}>
            <RegisterForm
                request={request}
                emailTaken={emailTaken}
                otpCodeSent={otpCodeSent}
                submitCredentials={submitCredentials}
                submitOtp={submitOtp}
                fields={getFields()}
                mandatory_fields={getMandatoryFields()}
            />
            <div className="mt-5">
                <span style={pageStyles.message}>{t('forgotPasswordPage:message')}</span>
                <Link to={getPathBranded('login')} style={pageStyles.message_cta}>
                    {t('forgotPasswordPage:message_cta')}
                </Link>
            </div>
        </AuthPage>
    );
};
