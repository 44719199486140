import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { UnderlineH2 } from 'medrefer-web-sdk/web-kit';

export const FormColumn = styled(Col)`
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        margin-top: 30px !important;
        flex-wrap: wrap;
    }
`;

export const SchedulePopoverTitle = styled(UnderlineH2)`
    position: sticky;
    top: 0;
    width: 100%;
    background: white;
    z-index: 10;
    text-align: center;
    padding-top: 25px;
`;

export const ButtonsContainer = styled.div`
    position: sticky;
    bottom: 0;
    width: 100%;
    background: white;
    z-index: 10;
    padding-top: 15px;
`;

export const SwitchInputContainer = styled.div`
    width: fit-content;
    display: inline-block;
    position: relative;
`;
