import React from 'react';
import SortIcon from 'assets/SortIcon.svg';
import { ReactSVG } from 'react-svg';
import { IconButtonAlt2, DropDownMenuFilter } from 'medrefer-web-sdk/web-kit';

import SortForm from './SortForm/SortForm';
import { SortTypes } from 'medrefer-web-sdk/api/models';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import useSortTypes from './useSortTypes';
import styled from 'styled-components';
import usePopoverMenu from 'hooks/usePopoverMenu';

interface Props {
    sort: SortTypes;
}

export const SidebarListMenuSort = ({ sort }: Props) => {
    const queryParams = useQueryParams();
    const sortTypes = useSortTypes();
    const { isOpen, handleToggle, menuRef } = usePopoverMenu();

    const setSort = (sort: string) => {
        queryParams.set('sort', sort);
        handleToggle();
    };

    const sortType = sortTypes.find((x) => x.value === sort);

    return (
        <div className="mr-2" ref={menuRef} style={{ position: 'relative' }}>
            <IconButtonAlt2 onClick={handleToggle}>
                <ReactSVG src={SortIcon} />
                {sortType != null && (
                    <div className="filter-labels">
                        <SortLabel>{sortType.name}</SortLabel>
                    </div>
                )}
            </IconButtonAlt2>
            <DropDownMenuFilter isOpen={isOpen}>
                <SortForm sort={sort} setSort={setSort} />
            </DropDownMenuFilter>
        </div>
    );
};

const SortLabel = styled.span`
    color: ${({ theme }) => theme.black} !important;
`;
