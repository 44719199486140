import React, { ReactElement } from 'react';
import { FormSeparator, H1, H2, Modal, useModalState } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import AppointmentQuestionnaireResponses from 'components/Dashboard/Appointment/AppointmentDetails/AppointmentQuestionnaireResponses';
import { AgreementsData } from '../AgreementsInfo/AgreementsData';

type Props = {
    appointment: AppointmentModel;
    button?: ReactElement;
};

export const AppointmentDetailsModal = (props: Props) => {
    const modalState = useModalState();

    return (
        <Modal isCentered={true} state={modalState} body={<AppointmentDetails {...props} />} button={props.button} />
    );
};

export const AppointmentDetails = ({ appointment }: Props) => {
    const { t } = useTranslation();
    return (
        <>
            <H1 className="mb-4">{t('appointmentDetails:title')}</H1>
            <AgreementsData
                appointment={appointment}
                managing_user={appointment.managing_user}
                health_service={appointment.healthcare_service}
                date_from={appointment.date_from}
                duration={[appointment.date_from, appointment.date_to]}
                message={appointment.comment}
                appointment_reason={appointment.reason.name}
                show_form_separator={false}
            />
            {!!appointment.questionnaire_responses.length && (
                <>
                    <FormSeparator />
                    <H2>{t('appointmentDetails:questionnaires')}</H2>
                    <AppointmentQuestionnaireResponses appointment={appointment} />
                </>
            )}
        </>
    );
};
