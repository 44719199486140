import { api } from 'medrefer-web-sdk/api';
import { useEffect, useMemo } from 'react';

const parseDomain = () => {
    const host = window.location.host;
    const parts = host.split('.');

    if (
        parts[0] !== 'app-dev' &&
        parts[0] !== 'app-staging' &&
        parts[0] !== 'app-demo' &&
        parts[0] !== 'app' &&
        parts[0] !== 'localhost:3000'
    ) {
        return [parts[0], parts.slice(1).join('.')];
    }
    return [null, host];
};

const getPathSlug = () => {
    const path = window.location.pathname.split('/');
    return path[1] === 'b' ? path[2] : '';
};

export const getCurrentBrandSlug = () => {
    const [subdomain] = parseDomain();
    return subdomain || getPathSlug();
};

const useBrandSlug = () => {
    const pathSlug = getPathSlug();
    const [subdomain, domain] = useMemo(parseDomain, [window.location.host]);

    useEffect(() => {
        const newSlug = pathSlug || subdomain;

        if (newSlug) {
            api.setBrandSlugHeader(newSlug);
        }
    }, [pathSlug, subdomain]);

    return {
        subdomainSlug: subdomain || undefined,
        pathSlug: pathSlug || undefined,
        domain,
    };
};

export default useBrandSlug;
