import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { DataTable as BaseDataTable, DataTableProps, Option } from 'medrefer-web-sdk/web-kit';
import { getActiveLanguage } from 'utils/i18n';

export const DataTable = (props: Omit<DataTableProps, 'pageSizeOptions'>) => {
    const { t } = useTranslation();

    const pageSizeOptions: Option[] = useMemo(() => {
        return [
            { value: '10', label: t('adminPanel:table.show_x_items', { x: 10 }) },
            { value: '20', label: t('adminPanel:table.show_x_items', { x: 20 }) },
            { value: '30', label: t('adminPanel:table.show_x_items', { x: 30 }) },
            { value: '40', label: t('adminPanel:table.show_x_items', { x: 40 }) },
            { value: '50', label: t('adminPanel:table.show_x_items', { x: 50 }) },
        ];
        // eslint-disable-next-line
    }, [getActiveLanguage()]);

    return <BaseDataTable pageSizeOptions={pageSizeOptions} {...props} />;
};
