import React from 'react';
import { GroupBase } from 'react-select';
import { stylesReactSelect, ReactSelectComponent } from './Select.styled';
import { Option, SelectProps } from './Select.types';

export const Select = <
    OptionType extends Option = Option,
    IsMulti extends boolean = false,
    Group extends GroupBase<OptionType> = GroupBase<OptionType>,
>(
    props: SelectProps<OptionType, IsMulti, Group>,
) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return <ReactSelectComponent classNamePrefix={'Select'} styles={stylesReactSelect} {...(props as any)} />;
};

export default Select;
