import { Api } from './Api';

export const api = new Api();

let apiRootWS: string | undefined;
apiRootWS = process.env.REACT_APP_API_URL || '';
apiRootWS = apiRootWS.replace('http', 'ws').replace(/api\/v\d+/g, 'ws');

export const appointmentsSocket = (token: string, organizationSlug: string): string => {
    return `${apiRootWS}/appointments?token=${token}&brand=${organizationSlug}`;
};
