import styled from 'styled-components';
import LookupIcon from 'assets/LookupIcon.svg';

export const SearchInput = styled.input`
    background: #ffffff;
    border: 1px solid transparent;
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 42px;
    width: 100%;
    padding: 0 14px;
    position: relative;
    background-image: url(${LookupIcon});
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: 12px 50%;
    padding-left: 36px;
    &::placeholder {
        color: #9d9eb2;
        max-width: 80%;
        font-size: 13px;
        line-height: 15px;
    }
`;
