import { withFormik, FormikProps } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';

import {
    Input,
    FormGroup,
    InformationBubble,
    InformationContainer,
    InformationBubbleTypes,
    Button,
} from 'medrefer-web-sdk/web-kit';
import { ApiRequest } from 'hooks/useApiRequest';

interface FormValues {
    password: string;
    password2: string;
}

interface FormProps extends WithTranslation {
    setNewPassword: (password: string) => void;
    isTokenInvalid: boolean;
    request: ApiRequest;
}

const InnerForm = (props: FormProps & FormikProps<FormValues>) => {
    const { t } = useTranslation();

    const getErrorMessage = () => {
        if (props.isTokenInvalid) {
            return t('passwordResetForm:warning_token');
        }
        if (props.touched.password && props.errors.password) {
            return props.errors.password;
        }
        if (props.touched.password2 && props.errors.password2) {
            return props.errors.password2;
        }
        if (props.request.errors) {
            return t('global:unknown_error_from_api');
        }
    };

    const errorMessage = getErrorMessage();

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <FormGroup className="mb-0">
                <Form.Label>{t('passwordResetForm:new_password')}</Form.Label>
                <Input
                    data-testid="password"
                    id="password"
                    name="password"
                    type="password"
                    onChange={props.handleChange}
                    value={props.values.password}
                    disabled={props.isTokenInvalid}
                />
            </FormGroup>
            <FormGroup>
                <Form.Label>{t('passwordResetForm:new_password2')}</Form.Label>
                <Input
                    data-testid="password2"
                    id="password2"
                    name="password2"
                    type="password"
                    onChange={props.handleChange}
                    value={props.values.password2}
                    disabled={props.isTokenInvalid}
                />
            </FormGroup>
            <InformationContainer>
                {errorMessage && (
                    <InformationBubble type={InformationBubbleTypes.warning}>{errorMessage}</InformationBubble>
                )}
            </InformationContainer>
            <div style={{ maxWidth: 200 }}>
                <Button isLoading={props.request.isLoading}>{t('passwordResetForm:button')}</Button>
            </div>
        </Form>
    );
};

const PasswordResetForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({ password: '', password2: '' }),
    validationSchema: ({ t }: FormProps) =>
        Yup.object().shape({
            password: Yup.string()
                .min(8, t('passwordResetForm:warning_password_min'))
                .max(50, t('passwordResetForm:warning_password_max'))
                .required(t('passwordResetForm:warning_password_required')),
            password2: Yup.string().when('password', {
                is: (val) => val.length > 0,
                then: Yup.string()
                    .min(8, t('passwordResetForm:warning_password2_min'))
                    .max(50, t('passwordResetForm:warning_password2_max'))
                    .required(t('passwordResetForm:warning_password2_required'))
                    .oneOf([Yup.ref('password')], t('passwordResetForm:warning_password2_matches')),
            }),
        }),
    handleSubmit: (values, { props }) => {
        props.setNewPassword(values.password);
    },
})(InnerForm);

export default withTranslation()(PasswordResetForm);
