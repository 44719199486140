import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Button,
    ErrorMessage,
    FormGroup,
    H1,
    InformationBubble,
    InformationBubbleTypes,
    Input,
    Label,
} from '../../../../../medrefer-web-sdk/web-kit';
import { TFunction } from 'i18next';
import { RequestWaitingRoomProps, RequestWaitingRoomValues } from '../RequestWaitingRoomForm.types';
import { FormikProps } from 'formik';
import { SlotBookingStep } from '../../../../PublicSearch/SlotBooking/SlotBooking.types';

interface Props {
    t: TFunction;
    mainProps: RequestWaitingRoomProps & FormikProps<RequestWaitingRoomValues>;
    onCancel: () => void;
}
export default function RequestWaitingRoomLoginSubModule({ t, onCancel, mainProps }: Props) {
    return (
        <div>
            <H1 className="mb-3">{t('slotBooking:login_title')}</H1>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={12} className="ml-auto mr-auto">
                        <Input id="email" type="email" disabled={true} value={mainProps.values.email} />
                    </Col>
                </Row>
            </FormGroup>
            <Label isRequired>{t('newAppointmentForm:title_password')}</Label>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={12} className="ml-auto mr-auto">
                        <Input
                            isError={!!(mainProps.touched.password && mainProps.errors.password)}
                            id="password"
                            type="password"
                            placeholder={t('newAppointmentForm:placeholder_password')}
                            onChange={mainProps.handleChange}
                            value={mainProps.values.password}
                            disabled={mainProps.values.step === SlotBookingStep.loginOtpCode}
                            onBlur={mainProps.handleBlur}
                        />
                        {mainProps.touched.password && mainProps.errors.password && (
                            <ErrorMessage className="text-left mt-1 mr-3">{mainProps.errors.password}</ErrorMessage>
                        )}
                    </Col>
                </Row>
            </FormGroup>
            {mainProps.values.step === SlotBookingStep.loginOtpCode && (
                <>
                    <Label isRequired>{t('loginForm:otp_code')}</Label>
                    <FormGroup>
                        <Row className="primary">
                            <Col xs={12} md={12} className="ml-auto mr-auto">
                                <Input
                                    isError={!!(mainProps.touched.token && mainProps.errors.token)}
                                    id="token"
                                    placeholder={t('loginForm:otp_code')}
                                    onChange={mainProps.handleChange}
                                    value={mainProps.values.token}
                                    onBlur={mainProps.handleBlur}
                                />
                                {mainProps.touched.token && mainProps.errors.token && (
                                    <ErrorMessage className="text-left mt-1 mr-3">
                                        {mainProps.errors.token}
                                    </ErrorMessage>
                                )}
                            </Col>
                        </Row>
                    </FormGroup>
                </>
            )}
            {mainProps.values.passwordError && (
                <FormGroup>
                    <Row className="primary">
                        <Col className="ml-auto mr-auto" xs={12} md={12}>
                            <InformationBubble type={InformationBubbleTypes.warning}>
                                {t('requestWaitingRoom:error_wrong_password')}
                            </InformationBubble>
                        </Col>
                    </Row>
                </FormGroup>
            )}
            {mainProps.values.otpCodeError && (
                <FormGroup>
                    <Row className="primary">
                        <Col className="ml-auto mr-auto" xs={12} md={12}>
                            <InformationBubble type={InformationBubbleTypes.warning}>
                                {t('requestWaitingRoom:error_wrong_otp_code')}
                            </InformationBubble>
                        </Col>
                    </Row>
                </FormGroup>
            )}
            <FormGroup>
                <Row className="primary">
                    <Col className="ml-auto mr-auto" xs={12} md={6}>
                        <Button type="button" inverseColors={true} onClick={onCancel}>
                            {t('newAppointmentForm:button_cancel')}
                        </Button>
                    </Col>
                    <Col className="ml-auto mr-auto" xs={12} md={6}>
                        <Button type="submit" isLoading={mainProps.values.isLoading}>
                            {mainProps.values.step === SlotBookingStep.loginOtpCode
                                ? t('requestWaitingRoom:proceed_button')
                                : t('newAppointmentForm:button_next')}
                        </Button>
                    </Col>
                </Row>
            </FormGroup>
        </div>
    );
}
