import styled from 'styled-components';

export const DisplayArrayDataContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

export const ItemContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 10px;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.accent}0f;
    p {
        padding: 0;
        margin: 0;
    }
`;
