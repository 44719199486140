import {
    Button,
    DatePicker,
    ErrorMessage,
    FormAsyncSelect,
    FormGroup,
    FormSelect,
    H1,
    InformationBubble,
    InformationBubbleTypes,
    Input,
    Label,
    Option,
} from 'medrefer-web-sdk/web-kit';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import i18n, { TFunction } from 'i18next';
import { RequestWaitingRoomProps, RequestWaitingRoomValues } from '../RequestWaitingRoomForm.types';
import { Field, FormikProps } from 'formik';
import { SlotBookingStep } from '../../../../PublicSearch/SlotBooking/SlotBooking.types';
import { useInsuranceStatusOptions } from '../../../../../hooks/options/useInsuranceStatusOptions';
import { PhoneInput } from '../../../PhoneInput';
import { OptionalField } from '../RequestWaitingRoomDataComponents/OptionalField';
import useLocationAutocompleteOptions from '../../../../../hooks/options/useLocationAutocompleteOptions';

interface Props {
    t: TFunction;
    props: RequestWaitingRoomProps & FormikProps<RequestWaitingRoomValues>;
    onCancel: () => void;
}
export default function RequestWaitingRoomRegisterSubModule({ t, props, onCancel }: Props) {
    const insuranceStatusOptions = useInsuranceStatusOptions();
    const { loadLocationAutocompleteOptions, setAddressProps } = useLocationAutocompleteOptions();

    const isError = (): boolean => {
        return (
            !!(props.touched.firstName && props.errors.firstName) ||
            !!(props.touched.lastName && props.errors.lastName) ||
            !!(props.touched.dateOfBirth && props.errors.dateOfBirth) ||
            !!(props.touched.insuranceNumber && props.errors.insuranceNumber) ||
            !!(props.touched.confirmPassword && props.errors.confirmPassword)
        );
    };

    return (
        <>
            <H1 className="mb-3">{t('slotBooking:register_title')}</H1>
            <Label>{t('newAppointmentForm:title_email')}</Label>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={12} className="ml-auto mr-auto">
                        <Input id="email" type="email" disabled={true} value={props.values.email} />
                    </Col>
                </Row>
            </FormGroup>
            <OptionalField
                isError={!!(props.touched.phone && props.errors.phone)}
                errorMessage={props.errors.phone}
                fieldName={'phone'}
                fieldLabel={t('newAppointmentForm:title_phone_number')}
                fields={props.values.fields}
                mandatory_fields={props.values.mandatory_fields}
            >
                <PhoneInput
                    isError={!!(props.touched.phone && props.errors.phone)}
                    disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                        props.values.step,
                    )}
                    id="phone"
                    type="text"
                    value={props.values.phone}
                    onBlur={props.handleBlur}
                    onChange={props.handleChange}
                />
            </OptionalField>
            <Label>{t('newAppointmentForm:title_personal_title')}</Label>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={12} className="text-left">
                        <Input
                            id="personalTitle"
                            onChange={props.handleChange}
                            value={props.values.personalTitle}
                            onBlur={props.handleBlur}
                            disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                props.values.step,
                            )}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <Label isRequired>{t('newAppointmentForm:title_first_name')}</Label>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={12} className="text-left">
                        <Input
                            isError={!!(props.touched.firstName && props.errors.firstName)}
                            id="firstName"
                            onChange={props.handleChange}
                            value={props.values.firstName}
                            onBlur={props.handleBlur}
                            disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                props.values.step,
                            )}
                        />
                    </Col>
                </Row>
                {props.touched.firstName && props.errors.firstName && (
                    <ErrorMessage className="text-left mt-1">{props.errors.firstName}</ErrorMessage>
                )}
            </FormGroup>
            <Label isRequired>{t('newAppointmentForm:title_last_name')}</Label>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={12} className="text-left">
                        <Input
                            isError={!!(props.touched.lastName && props.errors.lastName)}
                            id="lastName"
                            onChange={props.handleChange}
                            value={props.values.lastName}
                            onBlur={props.handleBlur}
                            disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                props.values.step,
                            )}
                        />
                    </Col>
                </Row>
                {props.touched.lastName && props.errors.lastName && (
                    <ErrorMessage className="text-left mt-1">{props.errors.lastName}</ErrorMessage>
                )}
            </FormGroup>
            <Label isRequired>{t('newAppointmentForm:title_date_of_birth')}</Label>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={12} className="text-left">
                        <DatePicker
                            isError={props.touched.dateOfBirth && props.errors.dateOfBirth}
                            name="dateOfBirth"
                            value={props.values.dateOfBirth}
                            fullWidth
                            autoComplete="off"
                            showYearDropdown
                            scrollableYearDropdown
                            maxDate={new Date()}
                            dropdownMode="select"
                            i18n={i18n}
                            onChange={props.handleChange}
                            disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                props.values.step,
                            )}
                        />
                        {props.touched.dateOfBirth && props.errors.dateOfBirth && (
                            <ErrorMessage className="text-left mt-1 mr-3">{props.errors.dateOfBirth}</ErrorMessage>
                        )}
                    </Col>
                </Row>
            </FormGroup>
            <OptionalField
                isError={!!(props.touched.insuranceNumber && props.errors.insuranceNumber)}
                errorMessage={props.errors.insuranceNumber}
                fieldName={'insurance_number'}
                fieldLabel={t('newAppointmentForm:title_insurance_number')}
                fields={props.fields}
                mandatory_fields={props.mandatory_fields}
            >
                <Input
                    isError={!!(props.touched.insuranceNumber && props.errors.insuranceNumber)}
                    id="insuranceNumber"
                    type="text"
                    onChange={props.handleChange}
                    value={props.values.insuranceNumber}
                    onBlur={props.handleBlur}
                    disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                        props.values.step,
                    )}
                />
            </OptionalField>
            <OptionalField
                isError={!!(props.touched.insuranceStatus && props.errors.insuranceStatus)}
                errorMessage={props.errors.insuranceStatus}
                fieldName={'insurance_status'}
                fieldLabel={t('newAppointmentForm:title_insurance_status')}
                fields={props.fields}
                mandatory_fields={props.mandatory_fields}
            >
                <Field
                    name="insuranceStatus"
                    options={insuranceStatusOptions}
                    id="insuranceStatus"
                    component={FormSelect}
                    placeholder={t('newAppointmentForm:placeholder_insurance_status')}
                    isMulti={false}
                    type={'text'}
                    isError={props.touched.insuranceStatus && props.errors.insuranceStatus}
                    onBlur={props.handleBlur}
                    isDisabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                        props.values.step,
                    )}
                />
            </OptionalField>
            <OptionalField
                isError={!!(props.errors.street && props.touched.street)}
                errorMessage={props.errors.street}
                fieldName={'address'}
                fieldLabel={t('newAppointmentForm:title_address')}
                fields={props.fields}
                mandatory_fields={props.mandatory_fields}
            >
                <Field
                    name="address"
                    isError={!!(props.errors.street && props.touched.street)}
                    loadOptions={loadLocationAutocompleteOptions}
                    component={FormAsyncSelect}
                    placeholder={t('adminPanel:manageLocations.locationsForm.address_placeholder')}
                    debounceTimeout={400}
                    minSearchLength={3}
                    onBlur={props.handleBlur}
                    isDisabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                        props.values.step,
                    )}
                    afterChange={(option: Option) => setAddressProps(props.setFieldValue, option)}
                />
            </OptionalField>
            <Label isRequired>{t('newAppointmentForm:title_password')}</Label>
            <FormGroup>
                <Row>
                    <Col xs={12} md={12} className="text-left">
                        <Input
                            isError={!!(props.touched.password && props.errors.password)}
                            id="password"
                            placeholder={t('newAppointmentForm:placeholder_password')}
                            type="password"
                            onChange={props.handleChange}
                            value={props.values.password}
                            onBlur={props.handleBlur}
                            disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                props.values.step,
                            )}
                        />
                        {props.touched.password && props.errors.password && (
                            <ErrorMessage className="text-left mt-1 mr-3">{props.errors.password}</ErrorMessage>
                        )}
                    </Col>
                </Row>
            </FormGroup>
            <Label isRequired>{t('newAppointmentForm:title_confirm_password')}</Label>
            <FormGroup>
                <Row>
                    <Col xs={12} md={12} className="text-left">
                        <Input
                            isError={!!(props.touched.confirmPassword && props.errors.confirmPassword)}
                            id="confirmPassword"
                            placeholder={t('newAppointmentForm:placeholder_confirm_password')}
                            type="password"
                            onChange={props.handleChange}
                            value={props.values.confirmPassword}
                            onBlur={props.handleBlur}
                            disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                props.values.step,
                            )}
                        />
                        {props.touched.confirmPassword && props.errors.confirmPassword && (
                            <ErrorMessage className="text-left mt-1 mr-3">{props.errors.confirmPassword}</ErrorMessage>
                        )}
                    </Col>
                </Row>
            </FormGroup>
            {props.values.step === SlotBookingStep.registerOtpCode && (
                <>
                    <Label isRequired>{t('loginForm:otp_code')}</Label>
                    <FormGroup>
                        <Row className="primary">
                            <Col xs={12} md={12} className="ml-auto mr-auto">
                                <Input
                                    isError={!!(props.touched.token && props.errors.token)}
                                    id="token"
                                    placeholder={t('loginForm:otp_code')}
                                    onChange={props.handleChange}
                                    value={props.values.token}
                                    onBlur={props.handleBlur}
                                />
                                {props.touched.token && props.errors.token && (
                                    <ErrorMessage className="text-left mt-1 mr-3">{props.errors.token}</ErrorMessage>
                                )}
                            </Col>
                        </Row>
                    </FormGroup>
                </>
            )}
            {isError() && (
                <FormGroup>
                    <Row className="primary">
                        <Col className="ml-auto mr-auto" xs={12} md={12}>
                            <InformationBubble type={InformationBubbleTypes.warning}>
                                {t('requestWaitingRoom:register_form_error')}
                            </InformationBubble>
                        </Col>
                    </Row>
                </FormGroup>
            )}
            {props.values.otpCodeError && (
                <FormGroup>
                    <Row className="primary">
                        <Col className="ml-auto mr-auto" xs={12} md={12}>
                            <InformationBubble type={InformationBubbleTypes.warning}>
                                {t('requestWaitingRoom:error_wrong_otp_code')}
                            </InformationBubble>
                        </Col>
                    </Row>
                </FormGroup>
            )}
            <FormGroup>
                <Row className="primary">
                    <Col className="ml-auto mr-auto" xs={12} md={6}>
                        <Button type="button" inverseColors={true} onClick={onCancel}>
                            {t('newAppointmentForm:button_cancel')}
                        </Button>
                    </Col>
                    <Col className="ml-auto mr-auto" xs={12} md={6}>
                        <Button type="submit" isLoading={props.values.isLoading}>
                            {props.values.step === SlotBookingStep.registerOtpCode
                                ? t('requestWaitingRoom:proceed_button')
                                : t('newAppointmentForm:button_next')}
                        </Button>
                    </Col>
                </Row>
            </FormGroup>
        </>
    );
}
