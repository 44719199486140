import styled from 'styled-components';

export const DaySelectorContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    gap: 8px;
`;

export const DayCircle = styled.span<{ isAllDay: boolean; isMorning: boolean; isAfternoon: boolean; small: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    width: ${({ small }) => (small ? '16px;' : '24px')};
    height: ${({ small }) => (small ? '16px;' : '24px')};
    background-color: ${({ theme, isAllDay }) => (isAllDay ? theme.accent : theme.grey100)};
    background-image: ${({ theme, isMorning, isAfternoon }) =>
        isMorning
            ? `linear-gradient(${theme.accent}, ${theme.accent}, ${theme.grey100}, ${theme.grey100})`
            : isAfternoon
            ? `linear-gradient(${theme.grey100},  ${theme.grey100}, ${theme.accent}, ${theme.accent})`
            : ''};
    color: ${({ theme, isAllDay }) => (isAllDay ? theme.white : theme.black)};
    border-radius: 50%;
    transition: all 0.1s ease;
`;
