import React from 'react';
import moment from 'moment';

type Props = {
    date: Date;
};

export const DateDistanceToNow = ({ date }: Props) => {
    return <span title={date.toLocaleString()}>{moment(date).fromNow()}</span>;
};
