import { useMemo } from 'react';
import { useConditionCriteria } from 'components/AdminPanel/ManageSlotsCriteria/hooks/useConditionCriteria';
import { idNameToOption } from 'medrefer-web-sdk/web-kit';
import { SlotsCriteriaFormProps } from 'components/AdminPanel/ManageSlotsCriteria/SlotsCriteriaForm';
import { FormikProps } from 'formik';
import { SlotCriteriaFormValues } from 'components/AdminPanel/ManageSlotsCriteria/SlotsCriteriaForm/SlotsCriteriaForm.types';

export const useConditionOptions = (baseProps: SlotsCriteriaFormProps & FormikProps<SlotCriteriaFormValues>) => {
    const { activeItem } = baseProps;
    const conditionCriteriaId = baseProps.values.enable_when.criteria;
    const conditionCriteria = useConditionCriteria(activeItem);
    const conditionValueOptions = useMemo(() => {
        const criteria = conditionCriteria.find((criteria) => criteria.id.toString() === conditionCriteriaId);
        if (criteria) {
            return criteria.values.map(idNameToOption);
        }
        return [];
    }, [conditionCriteriaId, conditionCriteria]);

    return {
        conditionCriteriaOptions: conditionCriteria.map(idNameToOption),
        conditionValueOptions,
    };
};
