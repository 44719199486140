export interface NoSlotsSubFormValues {
    displayCalendar: NoSlotsDisplay;
    message: string;
    actionType: NoSlotsAction;
    actionLink: string;
    actionLinkText: string;
    actionEmail: string;
    actionEmailText: string;
    actionPhone: string;
    actionPhoneText: string;
}

export enum NoSlotsDisplay {
    default = 'default',
    display = 'display',
    hidden = 'hidden',
    waiting = 'waiting',
}

export enum NoSlotsAction {
    none = 'none',
    link = 'link',
    phone = 'phone',
    email = 'email',
}
