import * as Yup from 'yup';
import { MembersFormProps } from 'components/AdminPanel/ManageMembers/MembersForm/MembersForm.types';
import { MembersManagementData } from 'medrefer-web-sdk/api/models';

export const mapPropsToValues = ({ activeItem }: MembersFormProps): MembersManagementData => {
    return {
        email: activeItem?.email || '',
        personal_title: activeItem?.personal_title || '',
        first_name: activeItem?.first_name || '',
        last_name: activeItem?.last_name || '',
        is_active: activeItem ? activeItem.is_active : false,
        assigned_healthcare_services: activeItem
            ? activeItem.assigned_healthcare_services.map((service) => service.id.toString())
            : [],
    };
};

export const validationSchema = ({ t }: MembersFormProps) =>
    Yup.object().shape({
        email: Yup.string()
            .max(50, t('loginForm:warning_login_max'))
            .email(t('loginForm:warning_login_matches'))
            .required(t('loginForm:warning_email_required')),
        personal_title: Yup.string().max(25, t('adminPanel:manageMembers.editForm.warning_personal_title')),
        first_name: Yup.string()
            .matchesName(t('newAppointmentForm:warning_first_name_matches'))
            .required(t('newAppointmentForm:warning_first_name_required')),
        last_name: Yup.string()
            .matchesName(t('newAppointmentForm:warning_last_name_matches'))
            .required(t('newAppointmentForm:warning_last_name_required')),
        is_active: Yup.boolean(),
        assigned_healthcare_services: Yup.array().of(Yup.string()).notRequired(),
    });
