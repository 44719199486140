import React from 'react';
import FilterIcon from 'assets/FilterIcon.svg';
import { ReactSVG } from 'react-svg';
import { IconButtonAlt2, DropDownMenuFilter } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { FilterForm } from './FilterForm/FilterForm';
import { CountData } from 'features/appointment/reducer';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import usePopoverMenu from 'hooks/usePopoverMenu';
import { BookingStatus } from 'components/Dashboard/StatusBadge/StatusBadge';
import { DashboardFilters } from 'components/Dashboard/Dashboard.types';

interface Props {
    filters: DashboardFilters;
    countData: CountData;
}

export const SidebarListMenuFilter = ({ filters, countData }: Props) => {
    const queryParams = useQueryParams();
    const { t } = useTranslation();
    const { isOpen, handleToggle, menuRef } = usePopoverMenu();

    const setStatuses = (items: BookingStatus[]) => {
        queryParams.set(
            'status',
            items.map((item) => item.toLocaleLowerCase()),
        );
    };

    const filtersCount = filters.statuses.length;

    return (
        <div className="mr-2" ref={menuRef} style={{ position: 'relative' }}>
            <IconButtonAlt2 onClick={handleToggle}>
                <ReactSVG src={FilterIcon} />
                {filtersCount > 0 ? (
                    <div className="pl-1 filter-labels">
                        +{filtersCount}
                        <span>filter(s)</span>
                    </div>
                ) : (
                    <div className="pl-1 filter-labels">{t('filterMenu:all_requests')}</div>
                )}
            </IconButtonAlt2>
            <DropDownMenuFilter isOpen={isOpen}>
                <FilterForm countData={countData} filters={filters} setStatuses={setStatuses} />
            </DropDownMenuFilter>
        </div>
    );
};
