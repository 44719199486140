import { SlotBookingData } from 'medrefer-web-sdk/api/models';

export interface SlotBookingFormData extends SlotBookingData {
    password: string;
    confirm_password: string;
    token: string;
    step: SlotBookingStep;
    fields: string[];
    mandatory_fields: string[];
}

export enum SlotBookingStep {
    base,
    login,
    loginOtpCode,
    register,
    registerOtpCode,
    registerConfirmation,
    confirmation,
}
