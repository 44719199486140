import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form, FormikProps, withFormik } from 'formik';
import { FormValues, SchedulePopoverProps } from './SchedulePopover.types';
import { SchedulePopoverType } from 'components/Calendar/Calendar.types';
import { mapPropsToValues, validationSchema, handleSubmit } from './SchedulePopover.schema';
import { ScheduleFormData, FormButtons } from './ScheduleForm';
import { SchedulePopoverTitle, ButtonsContainer } from './SchedulePopover.styled';

export const InnerForm: React.FC<SchedulePopoverProps & FormikProps<FormValues>> = (props) => {
    const { t } = useTranslation();
    return (
        <>
            <SchedulePopoverTitle>
                {props.type === SchedulePopoverType.create ? t('newSlotForm:form_title') : t('editSlotForm:form_title')}
            </SchedulePopoverTitle>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                }}
            >
                <ScheduleFormData baseProps={props} />
                <ButtonsContainer>
                    <FormButtons baseProps={props} />
                </ButtonsContainer>
            </Form>
        </>
    );
};

const SchedulePopover = withFormik<SchedulePopoverProps, FormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(InnerForm);

export default withTranslation()(SchedulePopover);
