import { PrimaryButton } from 'medrefer-web-sdk/web-kit';
import styled from 'styled-components';

export interface TabAppointmentProps {
    disabled?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    badge?: any;
}

export const TabAppointment = styled(PrimaryButton)<TabAppointmentProps>`
    display: inline-block;
    width: unset;
    position: relative;
    border-radius: 22px;

    padding: 10px 20px;
    height: 40px;
    font-size: 13px;
    line-height: 18px;
    cursor: ${(props) => (props.disabled ? 'not-allowed' : undefined)};
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        margin: 5px 0;
    }
`;

export const TabAppointmentBadge = styled(TabAppointment)`
    &:after {
        content: attr(data-badge);
        line-height: 16px;
        font-size: 10px;
        width: 16px;
        height: 16px;
        background: ${({ theme }) => theme.accent};
        border-radius: 50%;
        color: white;
        position: absolute;
        top: -4px;
        right: -4px;
        z-index: 10;
        font-weight: bold;
    }
`;

export const TabContainer = styled.div`
    display: flex;
    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        gap: 5px;
    }
`;
