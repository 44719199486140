import { defaultSlotMode, SlotPermission } from 'medrefer-web-sdk/api/models/Slot';
import { FormValues, SchedulePopoverProps } from './SchedulePopover.types';
import * as Yup from 'yup';
import { FormikBag } from 'formik';
import { getInitialRecurringOption, getInitialRecurringRule } from './RecurringOptionField/helpers/values';
import { SlotData, SlotEditScope, SlotStatus } from 'medrefer-web-sdk/api/models';
import { serializeRecurringRule } from './RecurringOptionField/helpers/values';

export const mapPropsToValues = (props: SchedulePopoverProps): FormValues => {
    const healthcare_service = props.activeSlot ? props.activeSlot.healthcare_service.id : 0;
    const appointment_reasons: string[] = props.activeSlot
        ? props.activeSlot.available_for_reasons.map((reason) => reason.id.toString())
        : [];

    const criteriaIds =
        props.activeSlot?.criteria?.map((criteria) => ({
            id: criteria.id.toString(),
            multiple_selection: criteria.multiple_selection,
            values: criteria.values.map((value) => value.id.toString()),
        })) || [];

    return {
        healthcare_service,
        appointment_reasons,
        repeat: '',
        status: SlotStatus.FREE,
        date: props.activeSchedule.dateFrom,
        date_hour: props.activeSchedule.dateFrom,
        dateTo_hour: props.activeSchedule.dateTo,
        duration: props.activeSchedule.duration,
        comment: props.activeSlot?.comment || '',
        recurring_option: getInitialRecurringOption(props.activeSlot),
        recurring_rule: getInitialRecurringRule(props.activeSlot),
        scope: SlotEditScope.SINGLE,
        mode: props.activeSlot ? props.activeSlot.mode : defaultSlotMode,
        criteriaIds: criteriaIds,
        duration_type: 1,
        slot_visibility: props.activeSlot?.slot_permission
            ? Object.values(SlotPermission).indexOf(props.activeSlot.slot_permission)
            : 1,
    };
};

export const validationSchema = ({ t }: SchedulePopoverProps) =>
    Yup.object().shape({
        appointment_reasons: Yup.array().min(1, t('newAppointmentForm:warning_reason_for_appointment_required')),
        healthcare_service: Yup.string()
            .matches(/[^0]/, t('newAppointmentForm:warning_healthcare_service_matches'))
            .required(t('newAppointmentForm:warning_healthcare_service_required')),
        date: Yup.date().required(t('newAppointmentForm:warning_invalid_date')).nullable(),
        date_hour: Yup.string().required(t('newAppointmentForm:warning_invalid_date_hour')).nullable(),
        dateTo_hour: Yup.string().required(t('newAppointmentForm:warning_invalid_date_hour')).nullable(),
        duration: Yup.number()
            .required(t('newAppointmentForm:warning_duration_required'))
            .min(5, t('newAppointmentForm:warning_duration_range', { min: 5, max: 360 }))
            .max(360, t('newAppointmentForm:warning_duration_range', { min: 5, max: 360 })),
        comment: Yup.string(),
    });

export const handleSubmit = (values: FormValues, { props }: FormikBag<SchedulePopoverProps, FormValues>) => {
    const dateFrom = new Date(
        values.date.getFullYear(),
        values.date.getMonth(),
        values.date.getDate(),
        values.date_hour.getHours(),
        values.date_hour.getMinutes(),
        0,
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const criteria_values = values.criteriaIds.reduce((acc: any, next) => {
        return [...acc, ...next.values];
    }, []);
    const slot_permission = Object.values(SlotPermission)[values.slot_visibility];
    const dateTo = new Date(dateFrom.getTime());
    dateTo.setMinutes(dateTo.getMinutes() + values.duration);

    const data: SlotData = {
        healthcare_service: values.healthcare_service,
        available_for_reasons: values.appointment_reasons,
        status: values.status,
        date_from: dateFrom,
        date_to: dateTo,
        comment: values.comment,
        schedule_rule: serializeRecurringRule(values),
        mode: values.mode,
        criteria_values,
        slot_permission: slot_permission,
    };

    if (values.duration_type == 0) {
        const dateToRepeat = new Date(
            values.date.getFullYear(),
            values.date.getMonth(),
            values.date.getDate(),
            values.dateTo_hour.getHours(),
            values.dateTo_hour.getMinutes(),
        );
        for (
            let i = 0;
            i < Math.floor((dateToRepeat.getTime() - dateFrom.getTime()) / 1000 / 60 / values.duration);
            i++
        ) {
            const data: SlotData = {
                healthcare_service: values.healthcare_service,
                available_for_reasons: values.appointment_reasons,
                status: values.status,
                date_from: new Date(
                    values.date.getFullYear(),
                    values.date.getMonth(),
                    values.date.getDate(),
                    values.date_hour.getHours(),
                    values.date_hour.getMinutes() + values.duration * i,
                    0,
                ),
                date_to: new Date(
                    values.date.getFullYear(),
                    values.date.getMonth(),
                    values.date.getDate(),
                    values.date_hour.getHours(),
                    values.date_hour.getMinutes() + values.duration * (i + 1),
                    0,
                ),
                comment: values.comment,
                schedule_rule: serializeRecurringRule(values),
                mode: values.mode,
                criteria_values,
                slot_permission: slot_permission,
            };
            props.handleSubmit(data, props.activeSchedule.id, values.scope);
        }
    } else {
        props.handleSubmit(data, props.activeSchedule.id, values.scope);
    }
};

// Maybe will be used in the future
// const checkSlotColliding = (formProps: SchedulePopoverProps, dateFrom: Date, dateTo: Date) => {
//     let isCollidingFlag = false;
//
//     for (const schedule of formProps.schedules.filter(
//         (schedule: ISchedule) => schedule.id !== formProps.activeSchedule?.id.toString(),
//     )) {
//         const start1 = dateFrom.getTime();
//         const end1 = dateTo.getTime();
//         const start2 = new Date(schedule.start as string).getTime();
//         const end2 = new Date(schedule.end as string).getTime();
//         isCollidingFlag = start1 < end2 && start2 < end1;
//         if (isCollidingFlag) {
//             break;
//         }
//     }
//
//     return isCollidingFlag;
// };
