import { useEffect, useState } from 'react';

const useObjectFetch = <Key extends unknown, Obj extends unknown>(
    key: Key | undefined,
    fetch: (key: Key) => Promise<Obj>,
) => {
    const [object, setObject] = useState<Obj | null>(null);

    useEffect(() => {
        if (key != null) {
            fetch(key).then(setObject);
        } else {
            setObject(null);
        }
        // eslint-disable-next-line
    }, [key]);

    return object;
};

export default useObjectFetch;
