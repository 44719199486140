import { useCallback, useRef, useState } from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { AppointmentRequestParams, Slot, SlotBookingData, SlotMode, SortTypes } from 'medrefer-web-sdk/api/models';
import { api } from 'medrefer-web-sdk/api';
import { useModalState, useTableOrdering } from 'medrefer-web-sdk/web-kit';
import { errorTimeout } from 'utils/constants';
import {
    AppointmentRequestsModel,
    AppointmentRequestStatus,
    AppointmentRequestTime,
    RequestCountData,
} from '../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';
import { toNullableDate } from '../../../utils/dates';
export interface RequestFilters {
    sort: SortTypes;
    statuses: AppointmentRequestStatus[];
    search: string;
}
export const useManageAppointmentRequests = (filters: RequestFilters) => {
    const { ordering, sortHandler } = useTableOrdering(['-booking_status', 'date_from'], true);
    const [activeItem, setActiveItem] = useState<AppointmentRequestsModel | null>(null);
    const [tableParams, setTableParams] = useState<{ pageIndex: number; pageSize: number; searchPhrase: string }>({
        pageIndex: 0,
        pageSize: 0,
        searchPhrase: '',
    });

    const [data, setData] = useState<AppointmentRequestsModel[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const requestLoadAppointments = useApiRequest();
    const request = useApiRequest({ errorTimeout });
    const editModalState = useModalState({ actionOnClose: () => setActiveItem(null) });
    const [checkedCount, setCheckedCount] = useState(0);
    const [dataChecked, setDataChecked] = useState<AppointmentRequestsModel[]>([]);
    const fetchIdRef = useRef(0);
    const autofillFinishedModalState = useModalState();
    const [autoFillLoading, setAutoFillLoading] = useState(false);
    const [changedStatusCnt, setChangedStatusCnt] = useState(0);
    const [noChangedStatusCnt, setNoChangedStatusCnt] = useState(0);
    const [countData, setCountData] = useState<RequestCountData>({ Apr: 0, Rej: 0, Pnd: 0, Can: 0, Rsp: 0 });
    const unselectAll = () => {
        setDataChecked((prevState) => {
            prevState.forEach((appointment_requests) => {
                appointment_requests.isChecked = false;
            });
            return [...prevState];
        });
        countChecked();
    };
    const selectAll = () => {
        setDataChecked((prevState) => {
            prevState.forEach((appointment_requests) => {
                if (appointment_requests.status == AppointmentRequestStatus.PENDING)
                    appointment_requests.isChecked = true;
            });
            return [...prevState];
        });
        countChecked();
    };
    const checkSchedule = (id: number) => {
        setDataChecked((prevState) => {
            const toCheck = prevState.findIndex((appointment_requests) => {
                return appointment_requests.id == id;
            });
            if (toCheck != -1) {
                prevState[toCheck].isChecked = !prevState[toCheck].isChecked;
                return [...prevState];
            } else {
                return prevState;
            }
        });
        countChecked();
    };
    const countChecked = useCallback(() => {
        setCheckedCount(
            data.filter((appointment_requests: AppointmentRequestsModel) => {
                return appointment_requests.isChecked;
            }).length,
        );
    }, [data]);
    const fetchData = useCallback(
        (pageIndex, pageSize, searchPhrase) => {
            const fetchId = ++fetchIdRef.current;
            setTableParams({ pageIndex, pageSize, searchPhrase });
            if (fetchId === fetchIdRef.current) {
                const params: AppointmentRequestParams = {
                    offset: pageSize * pageIndex,
                    limit: pageSize,
                    search: searchPhrase,
                    ordering: ordering.join(','),
                    statuses: filters.statuses,
                };

                requestLoadAppointments.dispatch(api.getAppointmentRequests(params)).then((res) => {
                    setPageCount(Math.ceil(res.totalCount / pageSize));
                    setData(res.appointment_requests);
                    setDataChecked(res.appointment_requests);
                    setCountData(res.metaData);
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ordering, filters.statuses],
    );
    const filterSlots = (activeItem: AppointmentRequestsModel, slots: Slot[]) => {
        const toReturn: Slot[] = [];
        slots.map((slot) => {
            const day = new Date(slot.date_from).getDay() - 1 <= -1 ? 6 : new Date(slot.date_from).getDay() - 1;
            switch (activeItem.available_time[day]) {
                case AppointmentRequestTime.AFTERNOON:
                    if (new Date(slot.date_from).getHours() >= 12) toReturn.push(slot);
                    break;
                case AppointmentRequestTime.MORNING:
                    if (new Date(slot.date_from).getHours() < 12) toReturn.push(slot);
                    break;
                case AppointmentRequestTime.ALL_DAY:
                    toReturn.push(slot);
                    break;
            }
        });
        return toReturn;
    };
    const fetchSlots = async (activeItem: AppointmentRequestsModel) => {
        const dateFrom = new Date();
        const dateTo = new Date(dateFrom.getFullYear() + 1, dateFrom.getMonth(), dateFrom.getDate());
        const cv: number[] = [];
        activeItem.criteria.map((critera) => {
            critera.values.map((cval) => {
                cv.push(cval.id);
            });
        });
        let res: Slot[] = [];
        if (activeItem.healthcare_service) {
            res = await api.getOrganizationSlotsForRequest(dateFrom, dateTo, activeItem.healthcare_service.id, cv);
        }
        if (activeItem.healthcare_service_group) {
            for (let i = 0; i < activeItem.healthcare_service_group.services.length; i++) {
                const tmp = await api.getOrganizationSlotsForRequest(
                    dateFrom,
                    dateTo,
                    activeItem.healthcare_service_group.services[i].id,
                    cv,
                );
                res = [...res, ...tmp];
            }
        }
        res = filterSlots(activeItem, res);
        return res;
    };
    const slotClickHandle = (slotKey: string, activeItem: AppointmentRequestsModel, res: Slot[]) => {
        const slot = res.find((slot) => slot.key === slotKey);
        if (!slot) return;
        if (!activeItem.patient) return;
        if (!activeItem.patient.patient) return;
        const slotBookingData: SlotBookingData = {
            street: activeItem.patient.patient.street,
            city: activeItem.patient.patient.city,
            slot: slot,
            patient: activeItem.patient.id,
            personal_title: activeItem.patient.personal_title,
            first_name: activeItem.patient.first_name,
            last_name: activeItem.patient.last_name,
            phone: activeItem.patient.phone,
            email: activeItem.patient.email,
            insurance_number: activeItem.patient.patient.insurance_number,
            insurance_status: activeItem.patient.patient.insurance_status,
            date_of_birth: toNullableDate(activeItem.patient.patient.date_of_birth),
            comment: activeItem.comment,
            sms_agreement: activeItem.patient.patient.sms_agreement,
            email_agreement: activeItem.patient.patient.email_agreement,
            terms_agreement: true,
            mode: SlotMode.ONSITE,
            reason: slot.available_for_reasons[0].id.toString(),
        };
        return slotBookingData;
    };
    const onConfirmBooking = async (bookingData: SlotBookingData, activeItem: AppointmentRequestsModel) => {
        if (bookingData) {
            const res = await api.bookSlot(bookingData);
            await api.changeAppointmentRequestStatus(activeItem.id, AppointmentRequestStatus.APPROVED, res.id);
        }
    };
    const autoFillHandler = async () => {
        let changedStatus = 0;
        let nonChangedStatus = 0;
        setAutoFillLoading(true);
        autofillFinishedModalState.toggleModal();
        for (const item of data) {
            if (item.isChecked) {
                const slots = await fetchSlots(item);
                if (slots.length > 0) {
                    const dataToBook = slotClickHandle(slots[0].key, item, slots);
                    if (dataToBook) {
                        await onConfirmBooking(dataToBook, item);
                        changedStatus++;
                    }
                } else {
                    nonChangedStatus++;
                }
            }
        }
        setChangedStatusCnt(changedStatus);
        setNoChangedStatusCnt(nonChangedStatus);
        setAutoFillLoading(false);
        reloadAppointments();
    };
    /**
     * Location actions.
     */
    const reloadAppointments = () => {
        editModalState.toggleModal();
        fetchData(tableParams.pageIndex, tableParams.pageSize, tableParams.searchPhrase);
    };
    const fetchActiveItem = (item: AppointmentRequestsModel) => {
        if (activeItem && item.id === activeItem.id) {
            return;
        }
        if (activeItem && item.id !== activeItem.id) {
            setActiveItem(null);
        }
        request.dispatch(api.getAppointmentRequest(item.id)).then((appointment) => {
            if (appointment.healthcare_service_group) {
                request
                    .dispatch(api.getHealthcareServiceGroup(appointment.healthcare_service_group.id))
                    .then((value) => {
                        appointment.healthcare_service_group.services = value.services;
                        setActiveItem(appointment);
                    });
            } else {
                setActiveItem(appointment);
            }
        });
    };

    const onConfirmAutoFillModal = () => {
        setChangedStatusCnt(0);
        setNoChangedStatusCnt(0);
        autofillFinishedModalState.toggleModal();
    };
    return {
        isLoading: requestLoadAppointments.isLoading,
        request,
        data,
        activeItem,
        pageCount,
        fetchData,
        editModalState,
        fetchActiveItem,
        reloadAppointments,
        ordering,
        sortHandler,
        unselectAll,
        selectAll,
        checkedCount,
        checkSchedule,
        dataChecked,
        countData,
        autoFillHandler,
        autofillFinishedModalState,
        changedStatusCnt,
        noChangedStatusCnt,
        onConfirmAutoFillModal,
        autoFillLoading,
    };
};
