export interface LoginOtpRequestData {
    email: string;
    password: string;
    organization_id?: number;
}

export interface LoginData extends LoginOtpRequestData {
    token: string;
}

export interface LoginCredentials extends LoginData {
    step: LoginStep;
}

export enum LoginStep {
    email,
    password,
    otpCode,
}
