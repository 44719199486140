import React, { ReactElement } from 'react';
import {
    ConfirmModalBody,
    DropDownMenuEdit,
    IconButton,
    Modal,
    ModalState,
    useModalState,
} from 'medrefer-web-sdk/web-kit';
import EditIcon from 'assets/EditIcon.svg';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'react-i18next';
import usePopoverMenu from 'hooks/usePopoverMenu';
import {
    AppointmentRequestsModel,
    AppointmentRequestStatus,
} from '../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';
import { api } from '../../../medrefer-web-sdk/api';

interface Props {
    activeListItem: AppointmentRequestsModel;
    state: ModalState;
    reloadRequests: () => void;
}

export const EditMenuRequest = (props: Props): ReactElement => {
    const { activeListItem } = props;
    const { t } = useTranslation();
    const cancelModal = useModalState();
    const respondModal = useModalState();
    const { isOpen, handleToggle, menuRef } = usePopoverMenu();

    if (activeListItem.status !== AppointmentRequestStatus.PENDING) {
        return <div />;
    }
    const handleClickAcceptResponded = async () => {
        await api.changeAppointmentRequestStatus(activeListItem.id, AppointmentRequestStatus.RESPONDED);
        props.reloadRequests();
        respondModal.toggleModal();
        props.state.toggleModal();
    };
    const handleClickAcceptCanceled = async () => {
        await api.changeAppointmentRequestStatus(activeListItem.id, AppointmentRequestStatus.CANCELED);
        props.reloadRequests();
        cancelModal.toggleModal();
        props.state.toggleModal();
    };
    return (
        <div ref={menuRef} className="d-flex align-items-center" style={{ position: 'relative' }}>
            <IconButton onClick={handleToggle}>
                <ReactSVG src={EditIcon} />
            </IconButton>
            <DropDownMenuEdit isOpen={isOpen}>
                <Modal
                    state={respondModal}
                    button={<span>{t('waitingRoom:mark_as_responded')}</span>}
                    body={
                        <ConfirmModalBody
                            title={t('waitingRoom:title_respond')}
                            message={t('waitingRoom:message_respond')}
                            confirmButton={t('waitingRoom:accept_btn_message')}
                            declineButton={t('waitingRoom:decline_btn_message')}
                            onConfirm={handleClickAcceptResponded}
                            onDecline={respondModal.toggleModal}
                        />
                    }
                />
                <Modal
                    state={cancelModal}
                    button={<span>{t('waitingRoom:cancel_request')}</span>}
                    body={
                        <ConfirmModalBody
                            title={t('waitingRoom:title_cancel')}
                            message={t('waitingRoom:message_cancel')}
                            confirmButton={t('waitingRoom:accept_btn_message')}
                            declineButton={t('waitingRoom:decline_btn_message')}
                            onConfirm={handleClickAcceptCanceled}
                            onDecline={cancelModal.toggleModal}
                        />
                    }
                />
            </DropDownMenuEdit>
        </div>
    );
};

export default EditMenuRequest;
