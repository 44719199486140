import styled from 'styled-components';

export const SettingsContainter = styled.div`
    margin: 0 80px;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        margin: 0;
        padding: 0;
        background-color: red;
    }
`;

export const SettingsContentContainer = styled.div`
    background: #fff;
    margin-top: 80px;
    padding: 45px 100px;
    min-width: 900px;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        position: fixed;
        top: 45px;
        margin: 0;
        min-width: 100vw;
        height: calc(var(--vh, 1vh) * 100 - ${({ theme }) => theme.navbarHeight.mobile});
        padding: 0;
        .row {
            margin: 0 !important;
            padding: 0 !important;
        }
        p {
            margin-left: 15px;
        }
    }
`;
