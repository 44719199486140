import * as Yup from 'yup';
import { OrganizationProfileFormProps, OrganizationProfileFormValues } from './OrganizationProfileForm.types';
import { theme } from 'medrefer-web-sdk/web-kit';
import { FormikBag } from 'formik';
import { mapPropsValuesForNoSlots } from '../../shared/NoSlotsBehaviourSubForm/NoSlotsBehaviourSubForm.schema';
import { validationSchemaForNoSlots } from '../../shared/NoSlotsBehaviourSubForm/NoSlotsBehaviourSubForm.schema';
import { parseFormToNoSlotsBehaviour } from 'components/shared/NoSlotsBehaviourSubForm/NoSlotsBehaviourSubForm.helpers';

export const mapPropsToValues = ({ activeItem }: OrganizationProfileFormProps): OrganizationProfileFormValues => {
    const no_slots_behaviour = mapPropsValuesForNoSlots({ activeItem });
    if (activeItem) {
        return {
            name: activeItem.name,
            description: activeItem.description,
            branding: {
                logo: activeItem.branding.logo?.id,
                accent_color: activeItem.branding.accent_color,
            },
            no_slots_behaviour,
        };
    }

    return {
        name: '',
        description: '',
        branding: {
            logo: undefined,
            accent_color: theme.accent.toUpperCase(),
        },
        no_slots_behaviour,
    };
};

export const validationSchema = ({ t, ...rest }: OrganizationProfileFormProps) =>
    Yup.object().shape({
        name: Yup.string()
            .matchesName(t('organizationProfileForm:warning_organization_name'))
            .required(t('organizationProfileForm:warning_organization_name')),
        description: Yup.string().max(500),
        branding: Yup.object().shape({
            logo: Yup.number(),
            accent_color: Yup.string().matchesHexColor(t('organizationProfileForm:warning_accent_color')),
        }),
        no_slots_behaviour: validationSchemaForNoSlots({ t, ...rest }),
    });

export const handleSubmit = (
    values: OrganizationProfileFormValues,
    { props }: FormikBag<OrganizationProfileFormProps, OrganizationProfileFormValues>,
) => {
    let logo;
    if (values.branding.logo !== props.activeItem?.branding?.logo?.id) {
        // Only include logo if it was changed, otherwise, if it was created by another user, attachment ownership error triggers
        logo = values.branding.logo;
    } else if (props.activeItem == null) {
        logo = null;
    }

    props.onFormSubmit({
        name: values.name,
        description: values.description,
        branding: {
            logo: logo,
            accent_color: values.branding.accent_color,
        },
        no_slots_behaviour: parseFormToNoSlotsBehaviour(values.no_slots_behaviour),
    });
};
