import { getAuthUser } from 'features/auth/selectors';
import { UserRole } from 'medrefer-web-sdk/api/models';
import Types from 'MyTypes';

export enum RouteRole {
    guest = 'GUEST',
}

export type UserRouteRole = UserRole | RouteRole;

export const routeRoleSelector = (state: Types.RootState) => {
    const authUser = getAuthUser(state);
    return authUser ? authUser.role : RouteRole.guest;
};
