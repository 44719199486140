import styled from 'styled-components';

export const VideoControls = styled.div`
    padding: 20px;
    position: absolute;
    left: 15px;
    bottom: 0;

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        left: 0;
        right: 0;
        margin: auto;
        width: fit-content;
    }
`;

export const VideoContainer = styled.div`
    background: rgb(27, 38, 56);
    width: 100%;
    display: flex;
    align-items: center;
    min-height: calc(100vh - ${({ theme }) => theme.navbarHeight.desktop});
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        min-height: calc(var(--maxvh) - ${({ theme }) => theme.navbarHeight.mobile});
    }
`;

export const JitsiContainer = styled.div<{ visible: boolean }>`
    ${({ visible }) => !visible && 'display: none;'}
    height: calc(100vh - ${({ theme }) => theme.navbarHeight.desktop});
    background: rgb(27, 38, 56);
    width: 100%;

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        min-height: calc(var(--maxvh) - ${({ theme }) => theme.navbarHeight.mobile});
    }

    position: relative;

    div[id^='jitsiMeeting-'] {
        height: 100%;
    }
`;

export const InfoScreen = styled.div`
    color: white;
    width: 100%;

    h1 {
        text-align: center;
        margin-bottom: 50px;
        font-weight: 600;
    }

    h2 {
        text-align: center;
        color: white;
    }
`;

export const VideoCallAppointmentDetails = styled.div`
    max-width: 600px;
    margin: auto;

    h1 {
        color: white;
    }

    span {
        color: white;
    }
`;
