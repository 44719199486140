import { AttachmentInput } from 'components/shared/AttachmentInput';
import { FormikProps, Field } from 'formik';
import {
    Button,
    ErrorMessage,
    FormErrorMessage,
    FormGroup,
    FormSelect,
    InformationContainer,
    Input,
    Label,
    UnderlineH2 as Title,
    TextGrey,
} from 'medrefer-web-sdk/web-kit';
import React from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AdminOrganizationFormProps, AdminOrganizationFormValues } from './AdminOrganizationForm.types';
import { AccentSquare } from 'components/shared/AccentSquare';
import { translateFeature } from 'utils/i18n';
import { Feature } from 'medrefer-web-sdk/api/models';
import { NoSlotsBehaviourSubForm } from 'components/shared/NoSlotsBehaviourSubForm';
import { EmailTextFooterModule } from './Submodules/EmailTextFooterModule';

export const AdminOrganizationData = (props: AdminOrganizationFormProps & FormikProps<AdminOrganizationFormValues>) => {
    const { t } = useTranslation();

    const formTitle = props.activeItem
        ? t('organizationProfileForm:organization_form_update_label')
        : t('organizationProfileForm:organization_form_create_label');

    const featureOptions = Object.values(Feature).map((feature) => ({
        value: feature,
        label: translateFeature(feature),
    }));
    const supportedLanguagesOptions = [
        { value: 'en', label: 'English' },
        { value: 'de', label: 'Deutsch' },
        { value: 'pl', label: 'Polski' },
    ];
    const operatingCountriesOptions = [
        { value: 'DE', label: 'Germany' },
        { value: 'PL', label: 'Poland' },
        { value: 'AT', label: 'Austria' },
    ];

    const getFieldOptions = (mandatory: boolean) => {
        let correctFieldOptions = props.fieldOptions;
        const listToCheck = mandatory ? props.values.fields : props.values.mandatory_fields;
        listToCheck.forEach((v) => {
            correctFieldOptions = correctFieldOptions.filter((fo) => fo.value !== v);
        });
        return correctFieldOptions;
    };

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <Title>{formTitle}</Title>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:organization_name_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <Input
                            isError={!!(props.touched.name && props.errors.name)}
                            id="name"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.name}
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.name && props.errors.name && (
                    <ErrorMessage className="text-right mt-1">{props.errors.name}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:organization_slug_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <Input
                            isError={!!(props.touched.slug && props.errors.slug)}
                            id="slug"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.slug}
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.slug && props.errors.slug && (
                    <ErrorMessage className="text-right mt-1">{props.errors.slug}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:organization_description_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <Input
                            isError={!!(props.touched.description && props.errors.description)}
                            id="description"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.description}
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.description && props.errors.description && (
                    <ErrorMessage className="text-right mt-1">{props.errors.description}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:organization_image_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <AttachmentInput
                            setFieldValue={props.setFieldValue}
                            value={props.values.branding.logo}
                            name={'branding.logo'}
                        />
                    </Col>
                </Row>
                {props.touched.branding?.logo && props.errors.branding?.logo && (
                    <ErrorMessage className="text-right mt-1">{props.errors.branding?.logo}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:organization_accent_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-right">
                        <Row className="primary">
                            <Col xs={1} className="text-right mr-3">
                                <AccentSquare color={props.values.branding.accent_color} />
                            </Col>
                            <Col xs={10} className="text-right">
                                <Input
                                    isError={
                                        !!(props.touched.branding?.accent_color && props.errors.branding?.accent_color)
                                    }
                                    id="branding.accent_color"
                                    type="text"
                                    onChange={(e) => {
                                        props.handleChange(e);
                                    }}
                                    value={props.values.branding.accent_color}
                                    onBlur={props.handleBlur}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {props.touched.branding?.accent_color && props.errors.branding?.accent_color && (
                    <ErrorMessage className="text-right mt-1">{props.errors.branding?.accent_color}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:appointment_emails_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Input
                            isError={
                                !!(
                                    props.touched.appointment_email_subscriptions &&
                                    props.errors.appointment_email_subscriptions
                                )
                            }
                            id="appointment_email_subscriptions"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.appointment_email_subscriptions}
                            onBlur={props.handleBlur}
                        />
                        <TextGrey>{t('organizationProfileDisplay:appointment_emails_help_text')}</TextGrey>
                    </Col>
                </Row>
                {props.touched.appointment_email_subscriptions && props.errors.appointment_email_subscriptions && (
                    <ErrorMessage className="text-right mt-1">
                        {props.errors.appointment_email_subscriptions}
                    </ErrorMessage>
                )}
            </FormGroup>
            {!!props.values.supported_languages.length && <EmailTextFooterModule props={props} />}
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:features_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Field
                            name="features"
                            options={featureOptions}
                            component={FormSelect}
                            isError={props.touched.features && !!props.errors.features}
                            isMulti
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.features && props.errors.features && (
                    <ErrorMessage className="text-right mt-1">{props.errors.features}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:supported_languages_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Field
                            name="supported_languages"
                            options={supportedLanguagesOptions}
                            component={FormSelect}
                            isError={props.touched.supported_languages && !!props.errors.supported_languages}
                            isMulti
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.supported_languages && props.errors.supported_languages && (
                    <ErrorMessage className="text-right mt-1">{props.errors.supported_languages}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:operating_countries_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Field
                            name="operating_countries"
                            options={operatingCountriesOptions}
                            component={FormSelect}
                            isError={props.touched.operating_countries && !!props.errors.operating_countries}
                            isMulti
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.operating_countries && props.errors.operating_countries && (
                    <ErrorMessage className="text-right mt-1">{props.errors.operating_countries}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:appointment_mandatory_fields_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Field
                            name="mandatory_fields"
                            options={getFieldOptions(true)}
                            component={FormSelect}
                            isError={props.touched.mandatory_fields && !!props.errors.mandatory_fields}
                            isMulti
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.features && props.errors.features && (
                    <ErrorMessage className="text-right mt-1">{props.errors.mandatory_fields}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('organizationProfileDisplay:appointment_non_mandatory_fields_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Field
                            name="fields"
                            options={getFieldOptions(false)}
                            component={FormSelect}
                            isError={props.touched.fields && !!props.errors.fields}
                            isMulti
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.features && props.errors.features && (
                    <ErrorMessage className="text-right mt-1">{props.errors.fields}</ErrorMessage>
                )}
            </FormGroup>
            <NoSlotsBehaviourSubForm
                namespace={'no_slots_behaviour'}
                values={props.values.no_slots_behaviour}
                errors={props.errors.no_slots_behaviour}
                touched={props.touched.no_slots_behaviour}
                handleChange={props.handleChange}
            />
            <InformationContainer>
                <FormErrorMessage apiErrors={props.request.errors} skipFormikErrors />
            </InformationContainer>
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" xs={12} md={6}>
                        <Button isLoading={props.request.isLoading}>{t('editProfileForm:submit_button_label')}</Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
};

export default AdminOrganizationData;
