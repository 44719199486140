import { FormikProps } from 'formik';
import React from 'react';
import { CustomRepeatFormProps, FormValues } from '../CustomRepeat.types';
import { DaySelectorContainer, DayCircle } from './DaySelector.styled';
import { Weekday } from 'medrefer-web-sdk/api/models';
import { useTranslation } from 'react-i18next';

export const DaySelector = (props: CustomRepeatFormProps & FormikProps<FormValues>) => {
    const { days } = props.values;
    const { t } = useTranslation();

    const isActive = (day: Weekday) => {
        return days.includes(day);
    };

    const toggleRepeatDay = (day: Weekday) => {
        props.setFieldValue('days', isActive(day) ? days.filter((d) => d !== day) : days.concat(day));
    };

    const dayOptions = [
        { label: t('global:daynamesOne.monday'), value: Weekday.MO },
        { label: t('global:daynamesOne.tuesday'), value: Weekday.TU },
        { label: t('global:daynamesOne.wednesday'), value: Weekday.WE },
        { label: t('global:daynamesOne.thursday'), value: Weekday.TH },
        { label: t('global:daynamesOne.friday'), value: Weekday.FR },
        { label: t('global:daynamesOne.saturday'), value: Weekday.SA },
        { label: t('global:daynamesOne.sunday'), value: Weekday.SU },
    ];

    return (
        <DaySelectorContainer>
            {dayOptions.map((option, key) => (
                <DayCircle key={key} onClick={() => toggleRepeatDay(option.value)} isActive={isActive(option.value)}>
                    {option.label}
                </DayCircle>
            ))}
        </DaySelectorContainer>
    );
};
