import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';

interface Props {
    cell: Cell<Record<string, never>, boolean>;
}

export const BooleanCell = ({ cell }: Props) => {
    const { t } = useTranslation();

    return cell.value ? t('global:yes') : t('global:no');
};
