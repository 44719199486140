import React, { FC } from 'react';
import { Select } from './Select';
import { GroupBase, PropsValue } from 'react-select';
import { getSelectValue, getFormFieldValue } from './utils';
import { FormSelectProps, Option } from './Select.types';

export const FormSelect: FC<FormSelectProps<Option, boolean, GroupBase<Option>>> = ({
    field,
    form,
    afterChange,
    isMulti,
    options,
    ...rest
}) => {
    const onChange = (option: PropsValue<Option>) => {
        form.setFieldValue(field.name, getFormFieldValue(option));

        if (afterChange != null) {
            afterChange(option);
        }
    };

    return (
        <Select
            name={field.name}
            value={getSelectValue(field.value, options, isMulti)}
            onChange={onChange}
            isMulti={isMulti}
            options={options}
            {...rest}
        />
    );
};
