import { loadStripe } from '@stripe/stripe-js';
import { stripePublicKey } from 'utils/settings';

export const getStripeClient = async () => {
    const instance = await loadStripe(stripePublicKey);
    if (!instance) {
        throw Error();
    }
    return instance;
};
