import React from 'react';
import { Col } from 'react-bootstrap';

import { Container, Content } from './styled';
import useCookiesConsent from './useCookiesConsent';
import { ExternalLink, PrimaryButton } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { getPath } from 'router-paths';

export const CookieConsent = () => {
    const { t } = useTranslation();
    const { isAccepted, acceptCookies } = useCookiesConsent();

    if (isAccepted) {
        return null;
    }

    return (
        <Container>
            <Content>
                <Col xs={12} md={10}>
                    {t('cookies:body')}{' '}
                    <ExternalLink href={getPath('privacyPolicy')}>{t('cookies:privacy_link')}</ExternalLink>.
                </Col>
                <Col xs={12} md={2}>
                    <PrimaryButton onClick={acceptCookies}>{t('cookies:accept')}</PrimaryButton>
                </Col>
            </Content>
        </Container>
    );
};
