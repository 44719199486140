import React, { useState } from 'react';
import { CriteriaContainer } from './CriteriaSearch.styled';
import { CriteriaSelect } from './CriteriaSelect';
import { Button, InformationBubble, InformationBubbleTypes } from 'medrefer-web-sdk/web-kit';
import { SlotCriteria } from 'medrefer-web-sdk/api/models';
import { useTranslation, withTranslation } from 'react-i18next';
import { Form, FormikProps, withFormik } from 'formik';
import {
    FormProps,
    FormValues,
    handleSubmit,
    mapPropsToValues,
    validate,
    isCriteriaVisible,
} from './SlotCriteriaForm.scheme';

export const InnerForm = ({
    values,
    slotsCriteria,
    setFieldValue,
    handleSubmit,
    errors,
    context,
}: FormProps & FormikProps<FormValues>) => {
    const { t } = useTranslation();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const visibleCriteria = slotsCriteria.filter((item) => {
        return isCriteriaVisible(item, {
            selectedValues: values.selectedValues,
            ...context,
        });
    });
    const isError = isSubmitting && errors?.selectedValues;

    const onCriteriaChange = (criteria: SlotCriteria, value: string[]) => {
        const next = { ...values.selectedValues };
        next[criteria.id] = value.map((item) => parseInt(item));

        // Clear values of the criteria that will be hidden after update
        slotsCriteria.map((item) => {
            if (
                !isCriteriaVisible(item, {
                    selectedValues: next,
                    ...context,
                })
            ) {
                next[item.id] = [];
            }
        });

        setFieldValue('selectedValues', next);
    };

    return (
        <CriteriaContainer>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    setIsSubmitting(true);
                    handleSubmit();
                }}
                className="w-100"
            >
                {visibleCriteria.map((item) => (
                    <CriteriaSelect
                        key={item.id}
                        criteria={item}
                        onCriteriaChange={onCriteriaChange}
                        isError={isSubmitting && !!errors.selectedValues?.[item.id]}
                    />
                ))}
                <div className="ml-auto mr-auto mt-4" style={{ maxWidth: 200, width: '100%' }}>
                    <Button>{t('publicSearch:criteria_search_button')}</Button>
                </div>
                {isError && (
                    <InformationBubble type={InformationBubbleTypes.warning}>
                        {t('publicSearch:criteria_empty_warning')}
                    </InformationBubble>
                )}
            </Form>
        </CriteriaContainer>
    );
};

const SlotCriteriaForm = withFormik<FormProps, FormValues>({
    mapPropsToValues,
    validate,
    handleSubmit,
})(InnerForm);

export default withTranslation()(SlotCriteriaForm);
