import React from 'react';
import SlotCriteriaForm from '../CriteriaSearch/SlotCriteriaForm';
import { SlotCriteria } from 'medrefer-web-sdk/api/models';
import { useTranslation } from 'react-i18next';
import { SearchTitle, SearchSubtitle } from './LandingSearch.styled';
import { SearchContainer } from './LandingSearch.styled';
import useSlotCriteriaForm from 'components/PublicSearch/CriteriaSearch/hooks/useSlotCriteriaForm';
import { useCriteriaSearchContext } from 'components/PublicSearch/CriteriaSearch/hooks/useCriteriaSearchContext';
import { SlotBookingProgress } from 'components/PublicSearch/SlotBookingProgress';

type Props = {
    slotsCriteria: SlotCriteria[];
};

export const LandingCriteria = ({ slotsCriteria }: Props) => {
    const { t } = useTranslation();
    const { onValuesSubmit } = useSlotCriteriaForm();
    const context = useCriteriaSearchContext();

    return (
        <>
            <SlotBookingProgress activeStep="criteria" />
            <SearchContainer>
                <SearchTitle>{t('publicSearch:landing_search_brand_title')}</SearchTitle>
                <SearchSubtitle className="mr-2 ml-2 text-center">
                    {t('publicSearch:criteria_search_description')}
                </SearchSubtitle>
                <SlotCriteriaForm slotsCriteria={slotsCriteria} onSubmit={onValuesSubmit} context={context} />
            </SearchContainer>
        </>
    );
};
