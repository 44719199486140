import { useCallback, useRef, useState } from 'react';
import useApiRequest from 'hooks/useApiRequest';
import { AppointmentModel, AppointmentParams } from 'medrefer-web-sdk/api/models';
import { api } from 'medrefer-web-sdk/api';
import { useModalState, useTableOrdering } from 'medrefer-web-sdk/web-kit';
import { errorTimeout } from 'utils/constants';

export const useManageAppointments = () => {
    const { ordering, sortHandler } = useTableOrdering(['-booking_status', 'date_from'], true);
    const [activeItem, setActiveItem] = useState<AppointmentModel | null>(null);
    const [tableParams, setTableParams] = useState<{ pageIndex: number; pageSize: number; searchPhrase: string }>({
        pageIndex: 0,
        pageSize: 0,
        searchPhrase: '',
    });

    const [data, setData] = useState<AppointmentModel[]>([]);
    const [pageCount, setPageCount] = useState(0);
    const requestLoadAppointments = useApiRequest();
    const request = useApiRequest({ errorTimeout });
    const editModalState = useModalState({ actionOnClose: () => setActiveItem(null) });

    const fetchIdRef = useRef(0);

    const fetchData = useCallback(
        (pageIndex, pageSize, searchPhrase) => {
            const fetchId = ++fetchIdRef.current;
            setTableParams({ pageIndex, pageSize, searchPhrase });
            if (fetchId === fetchIdRef.current) {
                const params: AppointmentParams = {
                    offset: pageSize * pageIndex,
                    limit: pageSize,
                    search: searchPhrase,
                    ordering: ordering.join(','),
                };

                requestLoadAppointments.dispatch(api.getAppointments(params)).then((res) => {
                    setPageCount(Math.ceil(res.totalCount / pageSize));
                    setData(res.appointments);
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [ordering],
    );

    /**
     * Location actions.
     */
    const reloadAppointments = () => {
        editModalState.toggleModal();
        fetchData(tableParams.pageIndex, tableParams.pageSize, tableParams.searchPhrase);
    };
    const fetchActiveItem = (item: AppointmentModel) => {
        if (activeItem && item.id === activeItem.id) {
            return;
        }
        if (activeItem && item.id !== activeItem.id) {
            setActiveItem(null);
        }
        request.dispatch(api.getAppointment(item.id)).then((appointment) => {
            setActiveItem(appointment);
        });
    };

    return {
        isLoading: requestLoadAppointments.isLoading,
        request,
        data,
        activeItem,
        pageCount,
        fetchData,
        editModalState,
        fetchActiveItem,
        reloadAppointments,
        ordering,
        sortHandler,
    };
};
