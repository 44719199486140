import { SlotCriteria, SlotCriteriaTranslation } from 'medrefer-web-sdk/api/models';
import { useEffect, useState } from 'react';
import { api } from 'medrefer-web-sdk/api';

export const useConditionCriteria = (activeItem?: SlotCriteriaTranslation) => {
    const [organizationCriteria, setOrganizationCriteria] = useState<SlotCriteria[]>([]);

    useEffect(() => {
        api.getSlotsCriteria().then((results) => {
            if (activeItem) {
                setOrganizationCriteria(results.filter((organization) => organization.id !== activeItem.id));
            } else {
                setOrganizationCriteria(results);
            }
        });
    }, [activeItem]);

    return organizationCriteria;
};
