import React from 'react';
import styled, { keyframes, css } from 'styled-components';

interface Props {
    size?: number;
    background?: string;
    absoluteCenter?: boolean;
}

export const ActivityIndicator = ({ size = 80, background = '#e0e1fd', absoluteCenter = false }: Props) => {
    return (
        <Container size={size} absoluteCenter={absoluteCenter}>
            <Circle size={size} background={background} />
            <Circle size={size} background={background} animationDelay={'-0.9s'} />
        </Container>
    );
};

const breatheAnimation = keyframes`
  0% {
    transform: scale(0, 0);
    opacity: 0.8;
  }
  100% {
    transform: scale(1, 1);
    opacity: 0;
  }
`;

const cssAbsoluteCenter = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

interface ContainerProps {
    size: number;
    absoluteCenter: boolean;
}

const Container = styled.div<ContainerProps>`
    position: relative;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    display: inline-block;
    padding: 0;
    text-align: left;

    ${(props) => props.absoluteCenter && cssAbsoluteCenter}
`;

interface CircleProps {
    size: number;
    background: string;
    animationDelay?: string;
}

const Circle = styled.div<CircleProps>`
    position: absolute;
    display: inline-block;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border-radius: 100%;
    background: ${(props) => props.background};
    animation: ${breatheAnimation} 1.5s linear infinite;
    animation-delay: ${(props) => props.animationDelay};
`;
