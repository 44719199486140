import styled, { css } from 'styled-components';
import { CustomTimePicker } from './TimePicker';

interface TimePickerProps {
    isLeft: boolean;
    isActive: boolean;
}

export const TimePicker = styled(CustomTimePicker)<TimePickerProps>`
    & .rc-time-picker-panel-select-option-selected {
        background-color: ${({ theme }) => theme.purple60};
        font-weight: normal;
    }

    & .rc-time-picker-clear,
    & .rc-time-picker-clear-icon:after {
        font-size: 15px;
    }

    & .rc-time-picker-panel-select,
    & .rc-time-picker-input,
    & .rc-time-picker-panel-input {
        font-family: sans-serif;
        font-size: 16px;
        cursor: pointer;

        ::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    ${(props) =>
        props.isLeft
            ? css`
                  border-top-right-radius: 0 !important;
                  border-bottom-right-radius: 0 !important;
                  border-right: 0px !important;
                  .rc-time-picker-panel-inner {
                      position: absolute;
                      left: 5px;
                      top: 90px;
                  }
                  .rc-time-picker-input {
                      padding-left: 18px;
                      width: 65px;
                  }
              `
            : css`
                  border-top-left-radius: 0 !important;
                  border-bottom-left-radius: 0 !important;
                  border-left: 0px !important;
                  padding-left: 0px !important;
                  .rc-time-picker-panel-inner {
                      position: absolute;
                      left: 57px;
                      top: 90px;
                  }
                  .rc-time-picker-input {
                  }
              `}

    ${(props) =>
        props.isActive &&
        css`
            .rc-time-picker-panel-inner {
                border: 1px solid ${({ theme }) => theme.accent} !important;
            }
        `}
    .rc-time-picker-input {
        background: transparent;
        border: none !important;
        font-size: 14px;
        line-height: 16px;
        height: 37.5px;

        letter-spacing: 0.175px;
        color: ${({ theme }) => theme.grey150};
    }

    .rc-time-picker-clear-icon:after {
        display: hidden;
    }
    .rc-time-picker-panel-inner {
        box-shadow: none;
    }

    .rc-time-picker-panel-input {
        text-align: center;
    }
    & .rc-time-picker-icon {
        display: none;
    }
`;

interface TimeRangeWrapperProps {
    isActive: boolean;
}

export const TimeRangeWrapper = styled.div<TimeRangeWrapperProps>`
    color: #2a2c2d;
    font-size: 14px;
    font-family: sans-serif;
    background: ${({ theme }) => theme.semiWhite};
    border-radius: 8px;
    height: 40px !important ;
    width: 150px;
    box-sizing: border-box;

    position: relative;
    background-image: none !important;
    border: 1px solid ${({ theme }) => theme.purple100};
    ${(props) =>
        props.isActive &&
        css`
            border: 1px solid ${({ theme }) => theme.accent} !important;
            input {
                border-radius: 8px;
                background: ${({ theme }) => theme.grey50} !important;
            }
        `}
    display: flex;
    position: relative;

    box-sizing: border-box;
    border-radius: 8px;
    &:after {
        color: ${({ theme }) => theme.grey150};
        content: '-';
        top: 8px;
        left: 60px;
        font-size: 14px;
        position: absolute;
    }
`;

interface IconProps {
    isVisible: boolean;
}

export const Icon = styled.img<IconProps>`
    display: ${(props) => (props.isVisible ? 'block' : 'none')};
    width: 13px;
    height: 13px;
    position: absolute;
    right: 12px;
    top: 8px;
    cursor: pointer;
`;

interface PopupBackgroundProps {
    isOpen: boolean;
}

export const PopupBackground = styled.div<PopupBackgroundProps>`
    span {
        display: none;
    }
    ${(props) =>
        props.isOpen &&
        css`
            display: flex;
            justify-content: space-around;

            span {
                display: block;
                margin-top: 10px;
                color: #000;
                font-weight: bold;
                font-size: 0.944rem;
            }
            z-index: 90;
            top: 50px;
            border: 1px solid ${({ theme }) => theme.accent};
            position: absolute;
            border-radius: 8px;
            width: 240px;
            height: 233.3px;
            background-color: ${({ theme }) => theme.grey50};
            &:after {
                position: absolute;
                content: '';
                left: 48px;
                top: -11px;
                width: 12px;
                height: 6px;
                border-bottom: solid 14px ${({ theme }) => theme.grey50};
                border-left: solid 14px transparent;
                border-right: solid 14px transparent;
            }
            &:before {
                position: absolute;
                content: '';
                left: 50px;
                top: -12px;
                width: 12px;
                height: 6px;
                border-bottom: solid 12px ${({ theme }) => theme.accent};
                border-left: solid 12px transparent;
                border-right: solid 12px transparent;
            }
        `}
`;
