import styled, { css } from 'styled-components';

interface TimerStyledProps {
    red?: boolean;
}

const cssRed = css`
    background: ${({ theme }) => theme.red200} !important;
    color: #fff !important;
`;

export const TimerStyled = styled.div<TimerStyledProps>`
    font-size: 12px;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    margin: auto;
    width: fit-content;
    margin-bottom: 10px;
    position: absolute;
    top: 20px;
    right: 0;
    left: 0;
    background: black;

    ${({ red }) => red && cssRed}
`;
