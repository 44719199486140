import * as Yup from 'yup';
import {
    SlotCriteriaCondition,
    SlotCriteriaManagementData,
    SlotCriteriaOperator,
    SlotCriteriaTranslation,
    SlotCriteriaType,
} from 'medrefer-web-sdk/api/models';
import { SlotCriteriaConditionType, SlotCriteriaFormValues, SlotsCriteriaFormProps } from './SlotsCriteriaForm.types';
import { FormikBag } from 'formik';
import {
    extractFieldTranslations,
    getDefaultFieldTranslations,
    getTranslationValidationForField,
} from 'utils/translation';

export const mapPropsToValues = ({ activeItem }: SlotsCriteriaFormProps): SlotCriteriaFormValues => {
    return {
        ...extractFieldTranslations(activeItem, 'name', ''),
        type: activeItem?.type,
        multiple_selection: activeItem ? activeItem.multiple_selection : false,
        required: activeItem ? activeItem.required : false,
        enable_condition: !!activeItem?.enable_when,
        condition_type: mapConditionTypeToValue(activeItem),
        enable_when: mapEnableWhenToValues(activeItem?.enable_when),
        values: activeItem
            ? activeItem.values.map((value) => ({
                  ...value,
                  ...extractFieldTranslations(value, 'name', ''),
              }))
            : [getDefaultFieldTranslations('name', '')],
    };
};

const mapConditionTypeToValue = (activeItem?: SlotCriteriaTranslation) => {
    if (activeItem?.enable_when) {
        if (activeItem.enable_when.criteria_value) {
            return SlotCriteriaConditionType.anotherCriteria;
        } else {
            return SlotCriteriaConditionType.healthcareService;
        }
    }
    return null;
};

const mapEnableWhenToValues = (condition: SlotCriteriaCondition | null | undefined) => {
    if (condition) {
        return {
            id: condition.id,
            criteria: condition.criteria ? condition.criteria.id.toString() : undefined,
            criteria_value: condition.criteria_value ? condition.criteria_value.id.toString() : undefined,
            healthcare_service: condition.healthcare_service ? condition.healthcare_service.id : undefined,
            operator: condition.operator,
        };
    }
    return {
        id: undefined,
        criteria: undefined,
        criteria_value: undefined,
        healthcare_service: undefined,
        operator: SlotCriteriaOperator.EQUALS,
    };
};

export const validationSchema = ({ t }: SlotsCriteriaFormProps) =>
    Yup.object().shape({
        ...getTranslationValidationForField('name', Yup.string()),
        type: Yup.string().required(t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.type_required')),
        multiple_selection: Yup.boolean(),
        enable_when: Yup.object().when('enable_condition', {
            is: true,
            then: Yup.object()
                .when('condition_type', {
                    is: SlotCriteriaConditionType.anotherCriteria,
                    then: Yup.object().shape({
                        id: Yup.number(),
                        criteria: Yup.string().required(),
                        criteria_value: Yup.string().required(),
                    }),
                })
                .when('condition_type', {
                    is: SlotCriteriaConditionType.healthcareService,
                    then: Yup.object().shape({
                        id: Yup.number(),
                        healthcare_service: Yup.string().required(),
                    }),
                }),
        }),
        condition_type: Yup.string()
            .nullable()
            .when('enable_condition', {
                is: true,
                then: Yup.string().required(t('form:error.required')),
            }),
        values: Yup.array().when('type', {
            is: SlotCriteriaType.TEXT,
            then: Yup.array()
                .min(1, t('manageSlotsCriteria:slotsCriteriaForm.values_warning'))
                .of(
                    Yup.object().shape({
                        id: Yup.number(),
                        ...getTranslationValidationForField('name', Yup.string()),
                    }),
                ),
        }),
    });

export const handleSubmit = (
    values: SlotCriteriaFormValues,
    { props }: FormikBag<SlotsCriteriaFormProps, SlotCriteriaFormValues>,
) => {
    const data: SlotCriteriaManagementData = {
        ...extractFieldTranslations(values, 'name', ''),
        type: values.type,
        multiple_selection: values.multiple_selection,
        required: values.required,
        enable_when: values.enable_condition
            ? {
                  id: values.enable_when.id,
                  operator: values.enable_when.operator,
                  criteria_value:
                      values.condition_type === SlotCriteriaConditionType.anotherCriteria
                          ? values.enable_when.criteria_value
                          : undefined,
                  healthcare_service:
                      values.condition_type === SlotCriteriaConditionType.healthcareService
                          ? values.enable_when.healthcare_service
                          : undefined,
              }
            : null,
        values: values.type === SlotCriteriaType.TEXT ? values.values : undefined,
    };
    props.handleSubmit(data);
};
