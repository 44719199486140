import React from 'react';
import { ActivityIndicator, Button, ModalBody, ModalMessage, ModalTitle } from '../../../medrefer-web-sdk/web-kit';
import { Container } from 'react-bootstrap';

type Props = {
    isLoading: boolean;
    title: string;
    messageChanged?: string;
    messageNonChanged?: string;
    confirmButton?: string;
    countChanged: number;
    countNonChanged: number;
    onConfirmHandler: () => void;
};

export const AutoFillInfoModal = ({
    isLoading,
    title,
    messageChanged,
    messageNonChanged,
    confirmButton,
    countChanged,
    countNonChanged,
    onConfirmHandler,
}: Props) => {
    const messageChangedWithCnt = messageChanged?.replace('{}', countChanged.toString());
    const messageNonChangedWithCnt = messageNonChanged?.replace('{}', countNonChanged.toString());
    return (
        <ModalBody>
            {isLoading && (
                <Container style={{ paddingLeft: '45%' }}>
                    <ActivityIndicator background={'#000'} />
                </Container>
            )}
            {!isLoading && (
                <>
                    <ModalTitle>{title}</ModalTitle>
                    {countChanged > 0 && <ModalMessage>{messageChangedWithCnt}</ModalMessage>}
                    {countNonChanged > 0 && <ModalMessage>{messageNonChangedWithCnt}</ModalMessage>}
                    <div style={{ maxWidth: 215 }}>
                        <div className="mb-2">
                            <Button style={{ minWidth: '150px' }} onClick={onConfirmHandler}>
                                {confirmButton}
                            </Button>
                        </div>
                    </div>
                </>
            )}
        </ModalBody>
    );
};
