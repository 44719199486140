import * as Yup from 'yup';
import { ServiceGroupFormProps } from './ServiceGroupForm.types';
import { ServiceGroupManagementData } from 'medrefer-web-sdk/api/models';

export const mapPropsToValues = ({ activeItem }: ServiceGroupFormProps): ServiceGroupManagementData => {
    return {
        name: activeItem ? activeItem.name : '',
        comment: activeItem ? activeItem.comment : '',
        services: activeItem ? activeItem.services.map((service) => service.id.toString()) : [],
    };
};

export const validationSchema = ({ t }: ServiceGroupFormProps) =>
    Yup.object().shape({
        name: Yup.string()
            .min(5, t('adminPanel:manageServiceGroups.warning_min_name'))
            .required(t('adminPanel:manageServiceGroups.warning_name_required')),
        comment: Yup.string().required(t('form:error.required')),
        services: Yup.array().min(2, t('adminPanel:manageServiceGroups.warning_min_array')),
    });
