import React from 'react';
import { CancelAppointment } from 'components/shared/CancelAppointment';
import { useMyAppointmentsData } from './hooks';
import { ActivityIndicator, ContextAwareToggle, H1 } from 'medrefer-web-sdk/web-kit';
import { Accordion, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AppointmentsList } from './AppointmentsList';
import { RescheduleAppointment } from 'components/shared/RescheduleAppointment';
import { useMyAppointmentsActions } from 'hooks/useMyAppointmentsActions';
import styled from 'styled-components';
import { WaitingRoomAppointmentsList } from '../WaitingRoomAppointments/WaitingRoomAppointmentsList';

export const MyAppointments = () => {
    const { t } = useTranslation();
    const {
        onGoingAppointments,
        upcomingAppointments,
        pastAppointments,
        cancelledAppointments,
        waitingRoomAppointments,
        loadMoreUpcomingAppointments,
        loadMorePastAppointments,
        loadMoreCancelledAppointments,
        loadMoreWaitingRoomAppointments,
        reloadAppointments,
    } = useMyAppointmentsData();

    const {
        appointmentId,
        cancelAppointmentModalState,
        rescheduleAppointmentModalState,
        cancelAppointment,
        openCancelAppointment,
        openRescheduleAppointment,
        rescheduleAppointment,
        isAppointmentRescheduleLoading,
        clearParams,
    } = useMyAppointmentsActions(reloadAppointments);

    return (
        <Container>
            <H1 className="mt-5">{t('patientPortal:myAppointments.title')}</H1>
            {(upcomingAppointments.isLoading && !upcomingAppointments.totalCount) ||
            (pastAppointments.isLoading && !pastAppointments.totalCount) ||
            (cancelledAppointments.isLoading && !cancelledAppointments.totalCount) ? (
                <ActivityIndicatorContainer>
                    <ActivityIndicator />
                </ActivityIndicatorContainer>
            ) : (
                <>
                    {onGoingAppointments.totalCount > 0 && (
                        <Accordion defaultActiveKey="0" className="mt-5 mb-5">
                            <Row>
                                <ContextAwareToggle eventKey="0">
                                    {t('patientPortal:myAppointments.ongoing_appointments_title')}
                                </ContextAwareToggle>
                            </Row>
                            <Accordion.Collapse eventKey="0">
                                <AppointmentsList appointmentsData={onGoingAppointments} showItemControls />
                            </Accordion.Collapse>
                        </Accordion>
                    )}
                    <Accordion defaultActiveKey={'1'} className="mt-5 mb-5">
                        <Row>
                            <ContextAwareToggle eventKey="1">
                                {t('patientPortal:myAppointments.upcomming_appointments_title')} (
                                {upcomingAppointments.totalCount})
                            </ContextAwareToggle>
                        </Row>
                        <Accordion.Collapse eventKey="1">
                            <AppointmentsList
                                appointmentsData={upcomingAppointments}
                                loadMore={loadMoreUpcomingAppointments}
                                noItemsMessage={t('patientPortal:myAppointments.no_upcomming_appointments')}
                                showItemControls
                                cancelAppointment={openCancelAppointment}
                                rescheduleAppointment={openRescheduleAppointment}
                                reloadAppointments={reloadAppointments}
                            />
                        </Accordion.Collapse>
                    </Accordion>
                    {waitingRoomAppointments.totalCount > 0 && (
                        <Accordion className="mt-5 mb-5">
                            <Row>
                                <ContextAwareToggle eventKey="2">
                                    {t('patientPortal:myAppointments:waiting_room_appointments_title')} (
                                    {waitingRoomAppointments.totalCount})
                                </ContextAwareToggle>
                            </Row>
                            <Accordion.Collapse eventKey="2">
                                <WaitingRoomAppointmentsList
                                    reloadAppointments={reloadAppointments}
                                    noItemsMessage={t('patientPortal:myAppointments.no_upcomming_appointments')}
                                    waitingRoomAppointmentsData={waitingRoomAppointments}
                                    loadMore={loadMoreWaitingRoomAppointments}
                                />
                            </Accordion.Collapse>
                        </Accordion>
                    )}
                    {pastAppointments.totalCount > 0 && (
                        <Accordion className="mt-5 mb-5">
                            <Row>
                                <ContextAwareToggle eventKey="3">
                                    {t('patientPortal:myAppointments.past_appointments_title')} (
                                    {pastAppointments.totalCount})
                                </ContextAwareToggle>
                            </Row>
                            <Accordion.Collapse eventKey="3">
                                <AppointmentsList
                                    appointmentsData={pastAppointments}
                                    loadMore={loadMorePastAppointments}
                                    noItemsMessage={t('patientPortal:myAppointments.no_past_appointments')}
                                />
                            </Accordion.Collapse>
                        </Accordion>
                    )}
                    {cancelledAppointments.totalCount > 0 && (
                        <Accordion className="mt-5 mb-5">
                            <Row>
                                <ContextAwareToggle eventKey="4">
                                    {t('patientPortal:myAppointments.cancelled_appointments_title')} (
                                    {cancelledAppointments.totalCount})
                                </ContextAwareToggle>
                            </Row>
                            <Accordion.Collapse eventKey="4">
                                <AppointmentsList
                                    appointmentsData={cancelledAppointments}
                                    loadMore={loadMoreCancelledAppointments}
                                    noItemsMessage={t('patientPortal:myAppointments.no_cancelled_appointments')}
                                />
                            </Accordion.Collapse>
                        </Accordion>
                    )}
                    {appointmentId && (
                        <>
                            <CancelAppointment
                                modalState={cancelAppointmentModalState}
                                cancelAppointment={() => {
                                    cancelAppointment(appointmentId);
                                }}
                                isLoading={isAppointmentRescheduleLoading}
                                onBeforeClose={clearParams}
                            />
                            <RescheduleAppointment
                                appointmentId={appointmentId}
                                modalState={rescheduleAppointmentModalState}
                                onBeforeClose={clearParams}
                                rescheduleAppointment={rescheduleAppointment}
                                isReschedulingAppointment={isAppointmentRescheduleLoading}
                            />
                        </>
                    )}
                </>
            )}
        </Container>
    );
};

const ActivityIndicatorContainer = styled.div`
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
