import React from 'react';
import { withFormik, FormikProps, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Col, Form } from 'react-bootstrap';
import { withTranslation, WithTranslation } from 'react-i18next';
import { Button, FormAsyncSelect, Option, Select } from 'medrefer-web-sdk/web-kit';
import { FormContentContainer } from './ServiceSearchForm.styled';
import { GeneralSearchItemType } from 'medrefer-web-sdk/api/models';
import useGeneralSearchOptions from 'hooks/options/useGeneralSearchOptions';
import useOrganizationLocationOptions from 'hooks/options/useOrganizationLocationOptions';
import useBrand from 'hooks/useBrand';
import useSlotModeOptions from 'hooks/options/useSlotModeOptions';

export interface GeneralSearchOption extends Option {
    id?: number;
    type: GeneralSearchItemType;
}

export interface ServiceSearchValues {
    healthcareServiceIds?: number[];
    location?: string;
    generalSearch?: string;
    generalSearchOption?: GeneralSearchOption;
    criteriaValues?: string;
    mode?: string;
}

export interface FormProps extends WithTranslation {
    onSubmit: (values: ServiceSearchValues) => void;
    mode?: string;
    location?: string;
    generalSearch?: string;
    generalSearchOption?: GeneralSearchOption;
    criteriaValues?: string;
}

const InnerForm = (props: FormProps & FormikProps<ServiceSearchValues>) => {
    const { handleSubmit, handleBlur, setFieldValue } = props;
    const { t } = useTranslation();
    const { loadGeneralSearchOptions } = useGeneralSearchOptions();
    const { loadOrganizationLocationOptions } = useOrganizationLocationOptions();
    const { organization } = useBrand();
    // const { setSelectedHS } = useOrganizationCriteriaOptions();
    const isMultipleLocations = organization?.locations_count && organization?.locations_count > 1;
    const handleGeneralSearchChange = async (option: GeneralSearchOption) => {
        setFieldValue('generalSearchOption', option);
    };
    const { modeParamOption, modeOptions, handleModeChange } = useSlotModeOptions(setFieldValue);
    const { featureEnabled } = useBrand();
    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
            className="w-100"
        >
            <FormContentContainer
                className={`${isMultipleLocations ? '' : 'd-flex justify-content-center align-items-center'}`}
            >
                <Col xs={12} md={isMultipleLocations ? 4 : 6}>
                    <Field
                        name="generalSearch"
                        loadOptions={loadGeneralSearchOptions}
                        initialOptions={props.generalSearchOption && [props.generalSearchOption]}
                        component={FormAsyncSelect}
                        placeholder={t('publicSearch:landing_category_placeholder')}
                        debounceTimeout={400}
                        isClearable
                        isError={props.touched.generalSearch && !!props.errors.generalSearch}
                        onBlur={handleBlur}
                        afterChange={handleGeneralSearchChange}
                    />
                </Col>
                {!!isMultipleLocations && (
                    <Col xs={12} md={4}>
                        <Field
                            name="location"
                            loadOptions={loadOrganizationLocationOptions}
                            component={FormAsyncSelect}
                            initialOptions={props.location && [{ label: props.location, value: props.location }]}
                            debounceTimeout={400}
                            isClearable
                            placeholder={t('publicSearch:landing_location_placeholder')}
                            isMulti={false}
                            isError={props.touched.location && !!props.errors.location}
                            onBlur={handleBlur}
                        />
                    </Col>
                )}
                {featureEnabled('videoCalls') && (
                    <Col xs={12} md={3}>
                        <Field
                            name="mode"
                            component={Select}
                            options={modeOptions}
                            defaultValue={modeParamOption ? modeParamOption : modeOptions[0]}
                            debounceTimeout={400}
                            placeholder={t('publicSearch:landing_mode_placeholder')}
                            isMulti={false}
                            isError={props.touched.mode && !!props.errors.mode}
                            onBlur={handleBlur}
                            onChange={handleModeChange}
                        />
                    </Col>
                )}
                <Col xs={12} md={2} className="d-flex justify-content-center align-items-center">
                    <div style={{ width: 100 }}>
                        <Button>{t('publicSearch:landing_search_button')}</Button>
                    </div>
                </Col>
            </FormContentContainer>
        </Form>
    );
};

const ServiceSearchForm = withFormik<FormProps, ServiceSearchValues>({
    mapPropsToValues: (props) => {
        return {
            mode: props.mode,
            location: props.location,
            generalSearch: props.generalSearch,
            generalSearchOption: props.generalSearchOption,
            criteriaValues: props.criteriaValues,
        };
    },
    handleSubmit: (values, { props }) => {
        props.onSubmit(values);
    },
})(InnerForm);

export default withTranslation()(ServiceSearchForm);
