import { Moment } from 'moment';

export const constants = {
    pagination: 20,
    dateISOFormat: 'YYYY-MM-DD',
    timeFormat: 'HH:mm',
    dateISOFormatWithTimezone: 'YYYY-MM-DDTHH:mm:ssZ',
};

export const formatISODate = (moment: Moment): string => {
    return moment.format(constants.dateISOFormat);
};

export const formatISODateTime = (moment: Moment): string => {
    return `${moment.format(constants.dateISOFormatWithTimezone)}`;
};

export const getFullName = (
    data: { personal_title: string; first_name: string; last_name: string },
    skipTitle?: boolean,
) => {
    const personalTitle = skipTitle ? '' : data.personal_title;
    return `${personalTitle || ''} ${data.first_name} ${data.last_name}`.trim();
};

/**
 * @param dateStart
 * @param dateEnd
 * @returns Median date between two dates
 */
export const getMedianDate = (dateStart: Date, dateEnd: Date) =>
    new Date((dateStart.getTime() + dateEnd.getTime()) / 2);

/**
 * @param o: Object to extract property from
 * @param propertyName: string key of object
 * @returns Value from object selected by string
 */
export function getProperty<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName]; // o[propertyName] is of type T[K]
}
