import React from 'react';
import { ShimmerPlaceholder } from './AppointmentCardPlaceholder.styled';

export const AppointmentCardPlaceholder = () => {
    return (
        <ShimmerPlaceholder>
            <article className="background">
                <div className="a" />
                <div className="b" />
                <div className="c" />
                <div className="d" />
                <div className="e" />
                <div className="f" />
                <div className="g" />
                <div className="h" />
                <div className="i" />
                <div className="j" />
                <div className="k" />
                <div className="l" />
            </article>
        </ShimmerPlaceholder>
    );
};
