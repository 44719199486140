import React from 'react';
import { useSlotsSearch } from './hooks/useSlotsSearch';
import { PageActivityIndicator } from 'components/shared/PageActivityIndicator';
import { useOrganizationCriteriaOptions } from 'hooks/options/useOrganizationCriteriaOptions';
import { LandingSearchForm } from './LandingSearchForm';
import { LandingService } from './LandingService';
import { LandingCriteria } from './LandingCriteria';
import { ServiceSearchValues } from 'components/PublicSearch/SlotBooking/ServiceSearchForm';

export const LandingSearch = () => {
    const { slotsCriteria } = useOrganizationCriteriaOptions();
    const { onSubmit, defaultValues, servicesLoaded, isSingleServiceOrganization, filterParams } = useSlotsSearch();
    const submitValues = (values: ServiceSearchValues) => {
        if (slotsCriteria && slotsCriteria.length > 0) {
            onSubmit(values, 'searchCriteria');
        } else {
            onSubmit(values, 'searchServiceSlots');
        }
    };

    if (!servicesLoaded || !slotsCriteria) {
        return <PageActivityIndicator />;
    }

    if (isSingleServiceOrganization && slotsCriteria.length > 0) {
        return <LandingCriteria slotsCriteria={slotsCriteria} />;
    }

    if (isSingleServiceOrganization) {
        return <LandingService filterParams={filterParams} />;
    }

    return <LandingSearchForm defaultValues={defaultValues} onSubmit={submitValues} />;
};
