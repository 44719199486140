import styled, { css } from 'styled-components';
import AsteriksIcon from '../../assets/icons/AsteriksIcon.png';

interface LabelProps {
    isRequired?: boolean;
    labelTop?: boolean;
}

export const Label = styled.label<LabelProps>`
    display: block;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.1875px;
    color: ${({ theme }) => theme.grey400};
    height: auto;
    padding: 0;
    width: 100%;
    position: relative;
    margin-bottom: 10px;

    ${(props) =>
        props.isRequired &&
        css`
            &:after {
                margin: 2px;
                bottom: 9px;
                position: absolute;
                content: '';
                background-image: url(${AsteriksIcon});
                width: 6px;
                height: 6px;
                background-size: 6px 6px;
            }
        `}
`;

export const LabelSubtitle = styled.span`
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 0.175px;
    color: ${({ theme }) => theme.purple200};
    display: block;
    width: 100%;
`;
