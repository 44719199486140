import { useNonInitialEffect, useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import { parseEnumValue, parseToEnumPropertyList } from 'medrefer-web-sdk/web-kit/utils/stringHelpers';
import { SortTypes } from 'medrefer-web-sdk/api/models';
import { BookingStatus } from 'components/Dashboard/StatusBadge/StatusBadge';
import { useMemo, useState } from 'react';

const defaultFilters = {
    search: '',
    sort: SortTypes.dateAsc,
    statuses: [BookingStatus.upcoming],
};

export const useDashboardFilters = () => {
    const queryParams = useQueryParams();
    const search = queryParams.get('search') || defaultFilters.search;
    const sort = parseEnumValue<SortTypes>(SortTypes, queryParams.get('sort')?.toUpperCase()) || defaultFilters.sort;
    const statusParams = parseToEnumPropertyList<BookingStatus>(BookingStatus, queryParams.getAll('status'));
    const [statuses, setStatuses] = useState(statusParams.length > 0 ? statusParams : defaultFilters.statuses);

    useNonInitialEffect(() => {
        setStatuses(statusParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(statusParams)]);

    return useMemo(() => {
        return {
            search,
            sort,
            statuses,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, sort, JSON.stringify(statuses)]);
};
