import styled from 'styled-components';

export const ValueTypography = styled.span`
    font-size: 15px;
    line-height: 20px;
    color: #24252f;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    word-break: break-word;
    &:last-of-type {
        margin-bottom: 20px;
    }
`;
