import React from 'react';
import { PatientInvitationForm } from './PatientInvitationForm';
import { UnderlineH2, Modal, ModalState } from 'medrefer-web-sdk/web-kit';
import useApiRequest from 'hooks/useApiRequest';
import { errorTimeout } from 'utils/constants';
import { PatientInvitationData, User } from 'medrefer-web-sdk/api/models';
import { useTranslation } from 'react-i18next';
import { api } from 'medrefer-web-sdk/api';

type Props = {
    state: ModalState;
    onPatientInvited: (patient: User) => void;
};

export const PatientInvitationModal = ({ state, onPatientInvited }: Props) => {
    const { t } = useTranslation();
    const request = useApiRequest({ errorTimeout });

    const handleSubmit = (values: PatientInvitationData) => {
        request.dispatch(api.invitePatient(values)).then(onPatientInvited);
    };

    return (
        <Modal
            state={state}
            body={
                <>
                    <UnderlineH2>{t('patientInvitation:form_title')}</UnderlineH2>
                    <PatientInvitationForm handleSubmit={handleSubmit} request={request} />
                </>
            }
        />
    );
};
