import { LoadMoreList } from '../../../medrefer-web-sdk/web-kit';
import React from 'react';
import { WaitingRoomAppointmentListItem } from './WaitingRoomAppointmentListItem';
import { MyWaitingRoomAppointmentsData } from '../MyAppointments/MyAppointments.types';
import { useTranslation } from 'react-i18next';
import { AppointmentRequestsModel } from '../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';

interface Props {
    waitingRoomAppointmentsData: MyWaitingRoomAppointmentsData;
    noItemsMessage: string;
    loadMore?: (prev: AppointmentRequestsModel[]) => void;
    reloadAppointments: () => void;
}
export const WaitingRoomAppointmentsList = ({
    waitingRoomAppointmentsData,
    loadMore,
    noItemsMessage,
    reloadAppointments,
}: Props) => {
    const { t } = useTranslation();
    return (
        <div>
            <LoadMoreList
                ListEmptyComponent={<p>{noItemsMessage}</p>}
                isLoading={waitingRoomAppointmentsData.isLoading}
                hasMore={!!waitingRoomAppointmentsData.next}
                loadMore={loadMore}
                loadMoreText={t('pagination:load_more')}
                data={waitingRoomAppointmentsData.waitingRoomAppointments.sort((a, b) => {
                    return b.id - a.id;
                })}
                renderItem={(item) => {
                    return <WaitingRoomAppointmentListItem item={item.item} reloadAppointments={reloadAppointments} />;
                }}
            />
        </div>
    );
};
