import React from 'react';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { SchedulePopoverTitle } from 'components/Calendar/SchedulePopover/SchedulePopover.styled';
import { useTranslation } from 'react-i18next';
import { Slot } from 'medrefer-web-sdk/api/models';
import { getFullName } from 'medrefer-web-sdk/api/utils';
import { BookSlotForPatientModal } from 'components/shared/BookSlotForPatient';
import { PrimaryButton, useModalState } from 'medrefer-web-sdk/web-kit';

interface SlotViewPopoverProps {
    activeSlot: Slot;
    closePopoverAndReloadAppointments: () => void;
}

export const SlotViewPopover = ({ activeSlot, closePopoverAndReloadAppointments }: SlotViewPopoverProps) => {
    const { t } = useTranslation();
    const appointmentDateFrom = moment(activeSlot.date_from);
    const appointmentDateTo = moment(activeSlot.date_to);
    const bookSlotModalState = useModalState();

    const onSlotBooked = () => {
        bookSlotModalState.toggleModal();
        closePopoverAndReloadAppointments();
    };

    return (
        <>
            <SchedulePopoverTitle>{t('editSlotForm:form_title_view')}</SchedulePopoverTitle>
            <div style={{ width: '100%' }}>
                <Row className="mb-1">
                    <Col xs={12} className="d-flex align-items-center">
                        <p>
                            <b>{activeSlot.managing_user && getFullName(activeSlot.managing_user)}</b>
                        </p>
                    </Col>
                </Row>
                <Row className="mb-1">
                    <Col xs={12} className="d-flex align-items-center">
                        {activeSlot.healthcare_service.name}
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col xs={12} className="d-flex flex-column">
                        <span>{appointmentDateFrom.format('DD MMMM YYYY')}</span>
                        <span>
                            {appointmentDateFrom.format('HH:mm')} -{appointmentDateTo.format('HH:mm')}
                        </span>
                        <span>{appointmentDateFrom.format('dddd')}</span>
                    </Col>
                </Row>
                <Row className="mb-4">
                    <BookSlotForPatientModal
                        state={bookSlotModalState}
                        button={
                            <PrimaryButton type="button">{t('editAppointmentForm:book_button_text')}</PrimaryButton>
                        }
                        slot={activeSlot}
                        onSlotBooked={onSlotBooked}
                    />
                </Row>
            </div>
        </>
    );
};
