import React, { useState } from 'react';

import { SidebarList } from 'components/Dashboard/Sidebar/SidebarList';
import { Appointment } from './Appointment';

const Dashboard = () => {
    const [showAppointmentDetails, setShowAppointmentDetails] = useState(false);
    const toggleShowAppointmentDetails = () => setShowAppointmentDetails((prev) => !prev);

    return (
        <div className="d-flex">
            <SidebarList toggleShowAppointmentDetails={toggleShowAppointmentDetails} />
            <Appointment showAppointmentDetails={showAppointmentDetails} />
        </div>
    );
};

export default Dashboard;
