import React from 'react';
import { TextGrey, H2 } from 'medrefer-web-sdk/web-kit';
import { TextCollapse } from 'components/shared/TextCollapse';
import { getFullName } from 'medrefer-web-sdk/api/utils';
import { UserPractitioner } from 'medrefer-web-sdk/api/models';
import { Col, Row } from 'react-bootstrap';
import { UserAvatar } from 'components/shared/UserAvatar';

type Props = {
    user: UserPractitioner | null;
    alternativeName?: string;
};
export const ManagingUser = ({ user, alternativeName }: Props) => {
    const practitionerDescription = user?.practitioner && user.practitioner.description;
    return (
        <>
            <Row className={'mb-2'}>
                <Col className={'d-flex'}>
                    <UserAvatar user={user} size={90} />
                    <Col>
                        <H2>{user ? getFullName(user) : alternativeName ? alternativeName : ''}</H2>
                        {practitionerDescription && (
                            <TextGrey>
                                <TextCollapse text={practitionerDescription} />
                            </TextGrey>
                        )}
                    </Col>
                </Col>
            </Row>
        </>
    );
};
