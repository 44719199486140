import React from 'react';
import { SchedulePopoverTitle } from 'components/Calendar/SchedulePopover/SchedulePopover.styled';
import { useTranslation } from 'react-i18next';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import { AppointmentView } from 'components/Calendar/AppointmentPopover/AppointmentView';
import { useMyAppointmentsActions } from 'hooks/useMyAppointmentsActions';
import { RescheduleAppointment } from 'components/shared/RescheduleAppointment';
import { CancelAppointment } from 'components/shared/CancelAppointment';
import { PopoverState } from 'medrefer-web-sdk/web-kit/components/Popover/hooks/usePopoverState';
import { Modal, PrimaryButton } from 'medrefer-web-sdk/web-kit';
import { AttachQuestionnaire } from 'components/Dashboard/Appointment/AppointmentHeader/ActionMenu/AttachQuestionnaire';

interface AppointmentPopoverProps {
    activeAppointment: AppointmentModel;
    reloadAppointments: () => Promise<void>;
    schedulePopoverState: PopoverState;
}

export const AppointmentPopover = ({
    activeAppointment,
    reloadAppointments,
    schedulePopoverState,
}: AppointmentPopoverProps) => {
    const { t } = useTranslation();
    const {
        cancelAppointmentModalState,
        rescheduleAppointmentModalState,
        attachQuestionnaireModalState,
        cancelAppointment,
        openCancelAppointment,
        openRescheduleAppointment,
        rescheduleAppointment,
        isAppointmentRescheduleLoading,
    } = useMyAppointmentsActions(reloadAppointments, schedulePopoverState);

    return (
        <>
            <SchedulePopoverTitle>{t('editAppointmentForm:form_title')}</SchedulePopoverTitle>
            <AppointmentView
                activeAppointment={activeAppointment}
                openCancelAppointment={openCancelAppointment}
                openRescheduleAppointment={openRescheduleAppointment}
            />
            <Modal
                state={attachQuestionnaireModalState}
                button={<PrimaryButton className="mb-3">{t('editMenu:attachQuestionnaire')}</PrimaryButton>}
                body={
                    <AttachQuestionnaire
                        appointment={activeAppointment}
                        toggleModal={attachQuestionnaireModalState.toggleModal}
                    />
                }
            />
            <RescheduleAppointment
                appointmentId={activeAppointment.id}
                modalState={rescheduleAppointmentModalState}
                rescheduleAppointment={rescheduleAppointment}
                isReschedulingAppointment={isAppointmentRescheduleLoading}
            />
            <CancelAppointment
                modalState={cancelAppointmentModalState}
                cancelAppointment={() => {
                    cancelAppointment(activeAppointment.id);
                }}
                isLoading={isAppointmentRescheduleLoading}
            />
        </>
    );
};
