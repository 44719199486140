import {
    AppointmentModel,
    AppointmentParams,
    OrganizationProfile,
    QuestionnaireResponseData,
} from 'medrefer-web-sdk/api/models';
import { AppThunk } from 'MyTypes';
import { store } from 'store';
import { api } from 'medrefer-web-sdk/api';
import {
    CLEAR_APPOINTMENTS,
    GET_APPOINTMENTS,
    UPDATE_APPOINTMENT,
    CHANGE_ACTIVE,
    UPDATE_REQUEST_APPOINTMENT,
} from './constants';
import { CountData } from './reducer';
import { action } from 'typesafe-actions';
import { SET_BRAND } from './constants';
import { AppointmentRequestsModel } from '../../medrefer-web-sdk/api/models/AppointmentRequestsModel';

export const getAppointments =
    (params: AppointmentParams): AppThunk =>
    async (dispatch) => {
        const state = store.getState();
        const appointments = state.appointment.appointments;
        const result = await api.getAppointments(params);
        const countData: CountData = {
            countByStatus: result.metadata.count_by_booking_status,
        };
        dispatch({
            type: GET_APPOINTMENTS,
            appointments: appointments.concat(result.appointments),
            countTotal: result.totalCount,
            countData,
        });
    };

export const getSingleAppointment =
    (id: number): AppThunk =>
    async (dispatch) => {
        const appointment = await api.getAppointment(id);
        dispatch(changeActiveListItem(appointment));
    };

export const uploadAttachments =
    (appointment: AppointmentModel, attachments: File[]): AppThunk =>
    async (dispatch) => {
        const updatedAppointment = await api.uploadAppointmentAttachments(appointment, attachments);
        dispatch(action(UPDATE_APPOINTMENT, updatedAppointment));
    };

export const uploadRequestAttachments =
    (requestAppointment: AppointmentRequestsModel, attachments: File[]): AppThunk =>
    async (dispatch) => {
        const updatedRequestAppointment = await api.uploadRequestAppointmentAttachments(
            requestAppointment,
            attachments,
        );
        dispatch(action(UPDATE_REQUEST_APPOINTMENT, updatedRequestAppointment));
    };

export const attachQuestionnaire =
    (appointment: AppointmentModel, dto: QuestionnaireResponseData): AppThunk =>
    async (dispatch) => {
        const questionnaireResponse = await api.createQuestionnaireResponse(appointment.id, dto);
        const updatedAppointment = appointment;
        updatedAppointment.questionnaire_responses = [
            ...updatedAppointment.questionnaire_responses,
            questionnaireResponse,
        ];
        dispatch(action(UPDATE_APPOINTMENT, updatedAppointment));
    };

export const clearAppointments = (): AppThunk => async (dispatch) => {
    dispatch(action(CLEAR_APPOINTMENTS));
};

export const cancelAppointment =
    (id: number): AppThunk =>
    async (dispatch) => {
        await api.cancelAppointment(id);
        await dispatch(clearAppointments());
    };

export const setBrand =
    (organizationSlug: string): AppThunk =>
    async (dispatch) => {
        try {
            const organization = await api.getOrganization(organizationSlug);
            dispatch(action(SET_BRAND, organization));
        } catch (e) {
            dispatch(action(SET_BRAND, null));
        }
    };

export const clearBrand = (): AppThunk => async (dispatch) => {
    dispatch(action(SET_BRAND, null));
};

export const setBrandData =
    (organization: OrganizationProfile): AppThunk =>
    async (dispatch) => {
        dispatch(action(SET_BRAND, organization));
    };

export const changeActiveListItem = (appointment: AppointmentModel | null) => {
    return action(CHANGE_ACTIVE, appointment);
};
