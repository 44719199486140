import styled from 'styled-components';
import { GreyOutlineButton } from 'medrefer-web-sdk/web-kit';
import { Col } from 'react-bootstrap';

export const AddAttachmentButton = styled(GreyOutlineButton)`
    transform: translateY(-40px);
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        transform: translateY(0);
    }

    &:active {
        transform: translateY(-40px);
        @media (max-width: ${({ theme }) => theme.devices.desktop}) {
            transform: translateY(0);
        }
    }
`;

export const ClearAttachmentButton = styled.div`
    position: absolute;
    left: 230px;
    top: 24px;
    cursor: pointer;
`;

export const CustomCol = styled(Col)`
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        text-align: center;
        button {
            margin: auto;
        }
    }
`;
