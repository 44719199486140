import { api } from 'medrefer-web-sdk/api';
import { getStorageItem, removeStorageItem, setStorageItem } from 'services/local-storage-service';
import jwt_decode from 'jwt-decode';
import { TokenPair } from 'medrefer-web-sdk/api/models';

export const isTokenValid = (token: string) => {
    try {
        const decodedAccess = jwt_decode<{ exp: number }>(token);
        return decodedAccess.exp * 1000 >= Date.now();
    } catch {
        return false;
    }
};

const tokenStorageKey = 'auth/access-token';
const refreshStorageKey = 'auth/refresh-token';

export const getAuthTokens = () => {
    const token = getStorageItem(tokenStorageKey);
    const refresh = getStorageItem(refreshStorageKey);

    return { token, refresh };
};

export const setAuthTokens = (pair: TokenPair) => {
    setStorageItem(tokenStorageKey, pair.token);
    setStorageItem(refreshStorageKey, pair.refresh_token);
    api.setAuthToken(pair.token);
};

export const clearAuthTokens = () => {
    removeStorageItem(tokenStorageKey);
    removeStorageItem(refreshStorageKey);
    api.clearAuthToken();
};
