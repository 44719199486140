import { Field, Form, FormikProps, withFormik } from 'formik';
import { Button, CheckBox, CheckBoxLabel, FormAsyncSelect, Label, LabelSubtitle } from 'medrefer-web-sdk/web-kit';
import React from 'react';
import { useQuestionnaireOptions } from 'hooks/options/useQuestionnaireOptions';
import { CustomRow } from 'components/Dashboard/Appointment/AppointmentDetails/AppointmentDetails.styled';
import { CustomCol } from './AttachQuestionnaire.styled';
import { useTranslation } from 'react-i18next';
import { Questionnaire } from 'medrefer-web-sdk/api/models';

interface FormProps {
    initialQuestionnaire?: Questionnaire;
    handleSubmit: (values: FormValues) => void;
}

export interface FormValues {
    questionnaire?: string;
    notifySource: boolean;
}

const InnerForm = (props: FormProps & FormikProps<FormValues>) => {
    const { t } = useTranslation();
    const { loadQuestionnaireOptions, toQuestionnaireOption } = useQuestionnaireOptions();

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <CustomRow style={{ margin: '30px 0' }}>
                <CustomCol xs={12}>
                    <Label>{t('attachQuestionnaireModalBody:title')}</Label>
                    <LabelSubtitle>{t('attachQuestionnaireModalBody:subtitle')}</LabelSubtitle>
                </CustomCol>
                <CustomCol xs={12} style={{ marginTop: '10px' }}>
                    <Field
                        name="questionnaire"
                        initialOptions={
                            props.initialQuestionnaire && [toQuestionnaireOption(props.initialQuestionnaire)]
                        }
                        loadOptions={loadQuestionnaireOptions}
                        component={FormAsyncSelect}
                        onBlur={props.handleBlur}
                    />
                </CustomCol>
                <CustomCol xs={12} style={{ marginTop: '20px' }}>
                    <CheckBox
                        id="notifySource"
                        checked={props.values.notifySource}
                        onChange={props.handleChange}
                        className="mr-4"
                        style={{ transform: 'translateY(-15px)' }}
                    />
                    <CheckBoxLabel>{t('attachQuestionnaireModalBody:send_email_checkbox')}</CheckBoxLabel>
                </CustomCol>
            </CustomRow>
            <div style={{ margin: '0 auto', maxWidth: 215 }}>
                <Button>{t('attachQuestionnaireModalBody:submit_button')}</Button>
            </div>
        </Form>
    );
};

const AttachQuestionnaireForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: () => ({
        questionnaire: undefined,
        notifySource: true,
    }),
    handleSubmit: (values, { props }) => {
        props.handleSubmit(values);
    },
})(InnerForm);

export default AttachQuestionnaireForm;
