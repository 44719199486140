import { AppointmentParams, SortTypes } from 'medrefer-web-sdk/api/models';

export const basicParams = (offset: number): AppointmentParams => {
    return {
        offset,
        sort: SortTypes.dateAsc,
        limit: 3,
    };
};

export const basicAppointmentDataObject = {
    next: '',
    appointments: [],
    totalCount: 0,
    isLoading: false,
};

export const basicWaitingRoomDataObject = {
    next: '',
    waitingRoomAppointments: [],
    totalCount: 0,
    isLoading: false,
};
