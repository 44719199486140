import { QuestionnaireResponseExtended } from './Questionnaires/QuestionnaireResponse';
import { Slot, SlotMode } from './Slot';
import {
    AppointmentStatusesCount,
    Attachment,
    Organization,
    Proposal,
    HealthcareService,
    PatientMetadata,
    AppointmentReason,
    UserPractitioner,
    UserPatientProfile,
    Price,
} from './index';
import { AppointmentRequestsModel } from './AppointmentRequestsModel';

export enum AppointmentStatus {
    BOOKED = 'BOOKED',
    CANCELLED = 'CANCELLED',
}

export interface VideoCallDetails {
    jwt: string;
    room: string;
    app_id: string;
    host: string;
    script_src: string;
}

export interface AppointmentModel {
    id: number;
    proposal?: Proposal;
    patient?: UserPatientProfile;
    managing_user?: UserPractitioner;
    patient_metadata: PatientMetadata;
    healthcare_service: HealthcareService;
    organization: Organization;
    reason: AppointmentReason;
    description: string;
    comment: string;
    date_from: string;
    date_to: string;
    status: AppointmentStatus;
    slot?: string;
    attachments: Attachment[];
    video_call_available: boolean;
    created_at: string;
    updated_at: string;
    mode?: SlotMode;
    price: Price | null;
    questionnaire_responses: QuestionnaireResponseExtended[];
}
export interface AppointmentModelWithSlot {
    id: number;
    proposal?: Proposal;
    patient?: UserPatientProfile;
    managing_user?: UserPractitioner;
    patient_metadata: PatientMetadata;
    healthcare_service: HealthcareService;
    organization: Organization;
    reason: AppointmentReason;
    description: string;
    comment: string;
    date_from: string;
    date_to: string;
    status: AppointmentStatus;
    slot?: Slot;
    attachments: Attachment[];
    video_call_available: boolean;
    created_at: string;
    updated_at: string;
    mode?: SlotMode;
    price: Price | null;
    questionnaire_responses: QuestionnaireResponseExtended[];
}

export interface AppointmentsMetadata {
    count_by_booking_status: AppointmentStatusesCount;
}

export interface AppointmentsDataPage {
    next: string;
    appointments: AppointmentModel[];
    totalCount: number;
    metadata: AppointmentsMetadata;
}
export interface WaitingRoomAppointmentsDataPage {
    next: string;
    waitingRoomAppointments: AppointmentRequestsModel[];
    totalCount: number;
    metadata: AppointmentsMetadata;
}
export interface AppointmentsWithSlotDataPage {
    next: string;
    appointments: AppointmentModelWithSlot[];
    totalCount: number;
    metadata: AppointmentsMetadata;
}
