import { useState, useEffect } from 'react';
import { useModalState } from 'medrefer-web-sdk/web-kit';
import useApiRequest from 'hooks/useApiRequest';
import { api } from 'medrefer-web-sdk/api';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import { PopoverState } from 'medrefer-web-sdk/web-kit/components/Popover/hooks/usePopoverState';

export enum ActionType {
    cancel = 'cancel',
    reschedule = 'reschedule',
}

export enum QueryParam {
    action = 'action',
    appointmentId = 'appointment_id',
}

export const useMyAppointmentsActions = (reloadAppointments?: () => void, schedulePopoverState?: PopoverState) => {
    const queryParams = useQueryParams();
    const [appointmentId, setAppointmentId] = useState<number | null>(null);
    const cancelAppointmentModalState = useModalState();
    const rescheduleAppointmentModalState = useModalState();
    const attachQuestionnaireModalState = useModalState();
    const request = useApiRequest();

    const openCancelAppointment = (appointmentId?: number) => {
        if (appointmentId) {
            queryParams.set(QueryParam.action, ActionType.cancel);
            queryParams.set(QueryParam.appointmentId, `${appointmentId}`);
            setAppointmentId(appointmentId);
        }

        cancelAppointmentModalState.toggleModal();
    };

    const cancelAppointment = (appointmentId?: number) => {
        let appointmentIdParam = appointmentId;
        if (typeof appointmentIdParam !== 'number') {
            appointmentIdParam = undefined;
        }

        if (!appointmentIdParam) {
            const appointmentIdQuery = queryParams.get(QueryParam.appointmentId);
            if (!appointmentIdQuery) return;
            appointmentIdParam = parseInt(appointmentIdQuery);
        }

        request
            .dispatch(api.cancelAppointment(appointmentIdParam))
            .then(reloadAppointments)
            .then(() => {
                cancelAppointmentModalState.toggleModal();
                schedulePopoverState && schedulePopoverState.togglePopover();
                clearParams();
            });
    };

    const openRescheduleAppointment = (appointmentId?: number) => {
        if (appointmentId) {
            queryParams.set(QueryParam.action, ActionType.reschedule);
            queryParams.set(QueryParam.appointmentId, `${appointmentId}`);
            setAppointmentId(appointmentId);
        }

        rescheduleAppointmentModalState.toggleModal();
    };

    const rescheduleAppointment = (appointmentId: number, slotKey: string) => {
        request
            .dispatch(api.rescheduleAppointment(appointmentId, slotKey))
            .then(reloadAppointments)
            .then(() => {
                rescheduleAppointmentModalState.toggleModal();
                schedulePopoverState && schedulePopoverState.togglePopover();
                clearParams();
            });
    };

    const clearParams = () => {
        queryParams.remove(QueryParam.action);
        queryParams.remove(QueryParam.appointmentId);
        setAppointmentId(null);
    };

    useEffect(() => {
        const action = queryParams.get('action');
        const appointmentIdParam = queryParams.get('appointment_id');

        if (appointmentIdParam) setAppointmentId(parseInt(appointmentIdParam));

        if (action === ActionType.cancel && appointmentIdParam) {
            cancelAppointmentModalState.toggleModal();
        }

        if (action === ActionType.reschedule && appointmentIdParam) {
            rescheduleAppointmentModalState.toggleModal();
        }
        // eslint-disable-next-line
    }, []);

    return {
        appointmentId,
        cancelAppointmentModalState,
        rescheduleAppointmentModalState,
        attachQuestionnaireModalState,
        cancelAppointment,
        openCancelAppointment,
        openRescheduleAppointment,
        rescheduleAppointment,
        clearParams,
        isAppointmentRescheduleLoading: request.isLoading,
    };
};
