import styled from 'styled-components';
import { Separator } from 'medrefer-web-sdk/web-kit';
import { Container, Row, Col } from 'react-bootstrap';

export const ListItemSeparator = styled(Separator)`
    width: auto;
`;

export const ButtonsContainer = styled(Row)`
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        gap: 10px;
        .cancel-button {
            margin-top: 20px;
            text-align: center;
        }
    }
    .cancel-button {
        cursor: pointer;
    }
`;

export const ListItemContainer = styled(Container)`
    background: white;
    border: 1px solid #e3e3e3;
    padding: 20px !important;
    border-radius: 5px;
`;

export const AppointmentTime = styled(Col)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;
    }
`;

export const AppointmentMode = styled.div`
    span {
        display: inline-block;
    }

    .icon {
        display: inline-block;
        margin-right: 5px;
        transform: translateY(-2px);
    }
`;
