import { ButtonsContainer } from '../../Dashboard/AppointmentsTable/EditAppointment/EditAppointment.styled';
import { PrimaryButton, RedButton } from '../../../medrefer-web-sdk/web-kit';
import { RescheduleAppointment } from '../../shared/RescheduleAppointment';
import React, { useState } from 'react';
import { AppointmentModel, AppointmentStatus } from '../../../medrefer-web-sdk/api/models';
import { useMyAppointmentsActions } from '../../../hooks/useMyAppointmentsActions';
import { useTranslation } from 'react-i18next';
import { CancelAppointmentModalBody } from '../../Dashboard/Appointment/AppointmentHeader/ActionMenu/CancelAppointmentModalBody';
import { AttachDocuments } from '../../Dashboard/Appointment/AppointmentHeader/ActionMenu/AttachDocuments';
import { AttachQuestionnaire } from '../../Dashboard/Appointment/AppointmentHeader/ActionMenu/AttachQuestionnaire';
type Props = {
    appointment: AppointmentModel;
    reloadAppointmentsRequests: () => void;
    toggleModal: () => void;
};
const AppointmentButtons = ({ appointment, reloadAppointmentsRequests, toggleModal }: Props) => {
    const [showCancelAppointment, setShowCancelAppointment] = useState(false);
    const [showAttachDocuments, setShowAttachDocuments] = useState(false);
    const [showAttachQuestionnaire] = useState(false);
    const { t } = useTranslation();
    const isBooked = appointment.status === AppointmentStatus.BOOKED;
    const isAppointmentBookedInFuture = isBooked && new Date(appointment.date_from) > new Date();
    const { rescheduleAppointmentModalState, rescheduleAppointment, isAppointmentRescheduleLoading } =
        useMyAppointmentsActions(reloadAppointmentsRequests);
    return (
        <>
            <ButtonsContainer>
                <PrimaryButton onClick={() => setShowAttachDocuments(true)} inverseColors>
                    {t('editMenu:attachDocuments')}
                </PrimaryButton>
                {isAppointmentBookedInFuture && (
                    <RescheduleAppointment
                        appointmentId={appointment.id}
                        modalState={rescheduleAppointmentModalState}
                        rescheduleAppointment={rescheduleAppointment}
                        isReschedulingAppointment={isAppointmentRescheduleLoading}
                        button={<PrimaryButton>{t('editMenu:reschedule')}</PrimaryButton>}
                    />
                )}
                {isBooked && (
                    <RedButton onClick={() => setShowCancelAppointment(true)}>{t('editMenu:cancel')}</RedButton>
                )}
            </ButtonsContainer>
            {showCancelAppointment && (
                <CancelAppointmentModalBody activeListItem={appointment} toggleModal={toggleModal} />
            )}
            {showAttachDocuments && <AttachDocuments toggleModal={toggleModal} appointment={appointment} />}
            {showAttachQuestionnaire && <AttachQuestionnaire appointment={appointment} toggleModal={toggleModal} />}
        </>
    );
};

export default AppointmentButtons;
