import styled from 'styled-components';
import ReactSelect, { GroupBase, StylesConfig } from 'react-select';
import { SelectProps, Option } from './Select.types';
import ExclamationCircleIcon from '../../../assets/icons/ExclamationCircleIcon.png';
import { theme } from '../../../theme';

export const stylesReactSelect: StylesConfig<Option> = {
    option: (styles, { isDisabled, isFocused, isSelected }) => ({
        ...styles,
        backgroundColor: isDisabled ? '' : isSelected ? theme.purple75 : isFocused ? theme.purple50 : '',
        color: '#000',
        ':active': {
            backgroundColor: !isDisabled ? '#f4f4ff' : undefined,
        },
    }),
};

export const ReactSelectComponent = styled(ReactSelect)<SelectProps<Option, boolean, GroupBase<Option>>>`
    .Select__control {
        border: 1px solid ${({ isError, theme }) => (isError ? theme.red300 : theme.purple100)};
        border-radius: 8px;
        width: 100%;
        min-height: 42px;
        background-color: ${({ isError }) => (isError ? 'rgba(255, 238, 243, 0.5)' : '')};
        background-image: ${({ isError }) => (isError ? `url(${ExclamationCircleIcon})` : 'none')};
        background-repeat: no-repeat;
        background-position: 98% 50%;

        &:hover {
            border-color: ${({ theme }) => theme.purple50};
        }

        padding-left: 3px;
    }

    .Select__placeholder {
        color: ${({ isError, theme }) => (isError ? theme.red300 : theme.purple200)};
    }

    .Select__dropdown-indicator {
        display: ${({ isError }) => (isError ? 'none' : 'block')};
        color: ${({ theme }) => theme.accent};
        margin: 0 2px;

        &:hover {
            color: ${({ theme }) => theme.accent};
        }
    }

    .Select__indicator-separator {
        height: ${({ isError }) => (isError ? '0' : '100%')};
        margin: 0;
        background-color: ${({ theme }) => theme.purple100};
    }

    .Select__multi-value {
        background-color: ${({ theme }) => theme.white};
        border: 1px solid ${({ theme }) => theme.black};
        border-radius: 8px;
    }

    .Select__multi-value__remove {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .Select__menu {
        z-index: 9999;
    }
`;
