import { CompleteUserInfoProps, CompleteUserInfoValues } from './CompleteUserInfo.types';
import * as Yup from 'yup';
import { FormikBag } from 'formik';
import { InsuranceStatus, UserRole, UserUpdateData } from '../../../medrefer-web-sdk/api/models';
import { findEnumValueByStringKey } from '../../shared/ServiceSlots/utils';

export const mapPropsToValues = (props: CompleteUserInfoProps): CompleteUserInfoValues => {
    return {
        address_valid: !!(props.user?.patient?.street && props.user?.patient?.city),
        insurance_number_valid: !!props.user?.patient?.insurance_number,
        insurance_status_valid: !!props.user?.patient?.insurance_status,
        phone_valid: !!props.user?.phone,
        city: props.user?.patient?.city || '',
        fields: props.fields,
        insurance_number: props.user?.patient?.insurance_number || '',
        insurance_status: props.user?.patient?.insurance_status || '',
        mandatory_fields: props.mandatory_fields,
        phone_number: props.user?.phone || '',
        street: props.user?.patient?.street || '',
        post_code: props.user?.patient?.post_code || '',
        terms_agreement: false,
        date_of_birth: props.user?.patient?.date_of_birth || '',
        user_fields: {
            terms_agreement: false,
            address_valid: props.user?.patient?.street !== undefined && props.user?.patient?.city !== undefined,
            insurance_number_valid: props.user?.patient?.insurance_number !== undefined,
            insurance_status_valid: props.user?.patient?.insurance_status !== undefined,
            phone_valid: props.user?.phone !== undefined,
            city: props.user?.patient?.city || '',
            fields: props.fields,
            post_code: props.user?.patient?.post_code || '',
            insurance_number: props.user?.patient?.insurance_number || '',
            insurance_status: props.user?.patient?.insurance_status || '',
            mandatory_fields: props.mandatory_fields,
            phone_number: props.user?.phone || '',
            street: props.user?.patient?.street || '',
            date_of_birth: props.user?.patient?.date_of_birth || '',
        },
    };
};

export const validationSchema = ({ t }: CompleteUserInfoProps) =>
    Yup.object().shape({
        phone_number: Yup.string().when(['mandatory_fields', 'fields'], {
            is: (mandatory_fields, fields) => mandatory_fields.includes('phone') || fields.includes('phone'),
            then: Yup.string()
                .matchesPhone(t('newAppointmentForm:warning_phone_matches'))
                .when(['mandatory_fields'], {
                    is: (mandatory_fields) => mandatory_fields.includes('phone'),
                    then: Yup.string().required(t('newAppointmentForm:warning_phone_required')),
                }),
        }),
        insurance_status: Yup.string().when('mandatory_fields', {
            is: (mandatory_fields) => mandatory_fields.includes('insurance_status'),
            then: Yup.string().required(t('newAppointmentForm:warning_insurance_status_required')),
        }),
        insurance_number: Yup.string().when('mandatory_fields', {
            is: (mandatory_fields) => mandatory_fields.includes('insurance_number'),
            then: Yup.string().required(t('newAppointmentForm:warning_insurance_number_required')),
        }),
        street: Yup.string().when('mandatory_fields', {
            is: (mandatory_fields) => mandatory_fields.includes('address'),
            then: Yup.string().required(t('newAppointmentForm:warning_address_required')),
        }),
        date_of_birth: Yup.date()
            .typeError(t('newAppointmentForm:warning_date_of_birth_type'))
            .required(t('newAppointmentForm:warning_date_of_birth_required')),
        terms_agreement: Yup.boolean().oneOf([true], t('newAppointmentForm:warning_terms_agreement')),
    });

export const handleSubmit = (
    values: CompleteUserInfoValues,
    { props }: FormikBag<CompleteUserInfoProps, CompleteUserInfoValues>,
) => {
    const updatedUser: UserUpdateData = {
        city: values.city,
        street: values.street,
        postCode: values.post_code,
        insuranceNumber: values.insurance_number,
        insuranceStatus: findEnumValueByStringKey(InsuranceStatus, values.insurance_status),
        phone: values.phone_number,
        role: UserRole.patient,
        dateOfBirth: new Date(values.date_of_birth),
    };

    props.updateProfile(updatedUser);

    props.onFormSubmit(updatedUser);
};
