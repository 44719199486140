import React from 'react';
import ReactDOM from 'react-dom';
import { Message, MessageBox, NotFoundContainer } from './NotFoundPage.styled';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';
import WarningIcon from 'medrefer-web-sdk/web-kit/assets/icons/svg/WarningIcon.svg';
import { Button } from 'medrefer-web-sdk/web-kit';
import { Link } from 'react-router-dom';
import useBrand from '../../hooks/useBrand';
import { getPath } from 'router-paths';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'features/auth/selectors';

export const NotFoundPage = () => {
    const { t } = useTranslation();
    const { getPathBranded, redirectToBrand } = useBrand();

    const homePageLink = getPathBranded('landing');
    const canGoToHomePage = homePageLink !== getPath('notFound');

    const user = useSelector(getAuthUser);
    const homeBrand = user?.organization?.slug;
    const canRedirectToHomePage = homeBrand != null;

    const redirectToHomePage = () => {
        if (homeBrand) {
            redirectToBrand(homeBrand);
        }
    };

    return ReactDOM.createPortal(
        <NotFoundContainer>
            <Message>
                <MessageBox>
                    <h1>404</h1>
                    <h2>
                        <ReactSVG src={WarningIcon} className={'icon'} />
                        {t('pageNotFound:title')}
                    </h2>
                    <span>{t('pageNotFound:description')}</span>
                    {canGoToHomePage ? (
                        <Link to={homePageLink} style={{ textDecoration: 'none' }}>
                            <Button>{t('pageNotFound:button_label')}</Button>
                        </Link>
                    ) : (
                        canRedirectToHomePage && (
                            <Button onClick={redirectToHomePage}>{t('pageNotFound:button_label')}</Button>
                        )
                    )}
                </MessageBox>
            </Message>
        </NotFoundContainer>,
        document.body,
    );
};
