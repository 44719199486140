import { HealthcareServiceGroup } from './models/HealthcareService';
import moment from 'moment';
import {
    AcceptInvitationData,
    AdminOrganization,
    AppointmentModel,
    AppointmentParams,
    AppointmentReason,
    AppointmentRequestParams,
    AppointmentsDataPage,
    AppointmentStep,
    AppointmentUpdateData,
    Attachment,
    BaseHealthcareService,
    CalendarConfig,
    EditLocationData,
    EditMemberProfileData,
    GeneralSearch,
    GeneralSearchItemType,
    GeneralSearchParams,
    HealthcareService,
    HealthcareServiceSlots,
    HealthcareServiceSlotsParams,
    HealthcareServicesParams,
    InsuranceCompany,
    Language,
    LocationAutocomplete,
    LocationData,
    LocationManagementData,
    LocationParams,
    LoginData,
    LoginOtpRequestData,
    MemberInvitationData,
    MembersManagementData,
    NewPasswordData,
    OrganizationLocation,
    OrganizationLocationsParams,
    OrganizationMember,
    OrganizationProfile,
    OrganizationProfileFormData,
    PaginatedDataParams,
    PatientInvitationData,
    PatientRegistrationData,
    Questionnaire,
    QuestionnaireData,
    QuestionnaireResponse,
    QuestionnaireResponseData,
    QuestionnaireResponseExtended,
    ServiceCategoriesParams,
    ServiceCategory,
    ServiceGroupManagementData,
    ServiceManagementData,
    Slot,
    SlotBookingData,
    SlotCriteria,
    SlotCriteriaManagementData,
    SlotCriteriaTranslation,
    SlotData,
    SlotEditScope,
    SlotStatus,
    SortTypes,
    TokenPair,
    User,
    UserPatientProfile,
    UserProfile,
    UserProfileOrganization,
    UserRole,
    UserUpdateData,
    VideoCallDetails,
} from './models';

import { ApiRoutes } from './ApiRoutes';
import { MedReferAPI, PaginatedData, RecursivePartial } from './ApiTypes';
import { HttpClient } from './HttpClient';
import { formatISODate, formatISODateTime } from './utils';
import { appointmentFactory, appointmentRequestsFactory } from './factories';
import { getCurrentBrandSlug } from 'hooks/useBrandSlug';
import { HealthcareServicesCriteriaParams } from './models/forms/HealthcareServiceCriteriaFinderParams';
import {
    AppointmentRequestsDataPage,
    AppointmentRequestsModel,
    AppointmentRequestStatus,
} from './models/AppointmentRequestsModel';
import { AppointmentRequestData } from './models/forms/AppointmentRequestData';

// API Implementation

export class Api implements MedReferAPI {
    client: HttpClient;

    constructor() {
        this.client = new HttpClient(process.env.REACT_APP_API_URL || '');
        this.setBrandSlugHeader(getCurrentBrandSlug());
    }

    setAuthToken(token: string): void {
        this.client.setCommonHeader('Authorization', `Bearer ${token}`);
    }

    setBrandSlugHeader(slug: string) {
        this.client.setCommonHeader('X-Medrefer-Brand', slug);
    }

    clearAuthToken(): void {
        this.client.deleteCommonHeader('Authorization');
    }

    setLanguage(language: string): void {
        this.client.setCommonHeader('Accept-Language', language);
    }

    async getAppointment(id: number): Promise<AppointmentModel> {
        const res = await this.client.get(ApiRoutes.appointmentIdRoute(id));
        return appointmentFactory(res.data);
    }

    async getAppointmentSteps(id: number): Promise<AppointmentStep[]> {
        const res = await this.client.get(ApiRoutes.appointmentStepsRoute(id));
        return res.data;
    }

    async getAppointmentVideoCall(id: number): Promise<VideoCallDetails> {
        const res = await this.client.get(ApiRoutes.appointmentVideoCallRoute(id));
        return res.data;
    }

    async getAppointmentVideoCallPatientAccess(id: number, token: string): Promise<VideoCallDetails> {
        const res = await this.client.get(ApiRoutes.appointmentVideoCallPatientAccessRoute(id), {
            params: {
                access_token: token,
            },
        });
        return res.data;
    }

    async getAppointmentPatientAccess(id: number, token: string): Promise<AppointmentModel> {
        const res = await this.client.get(ApiRoutes.appointmentPatientAccessRoute(id), {
            params: {
                access_token: token,
            },
        });
        return appointmentFactory(res.data);
    }

    async getAppointments(params: AppointmentParams): Promise<AppointmentsDataPage> {
        const orderingValue = {
            [SortTypes.dateAsc]: 'date_from',
            [SortTypes.dateDesc]: '-date_from',
            [SortTypes.nameAsc]: 'full_name',
            [SortTypes.nameDesc]: '-full_name',
        };

        const apiParams = {
            date_from__gte: params.dateFrom ? formatISODateTime(moment(params.dateFrom)) : undefined,
            date_from__lte: params.dateTo ? formatISODateTime(moment(params.dateTo)) : undefined,
            search: params.search,
            ordering: params.ordering || (params.sort && orderingValue[params.sort]),
            booking_status__in: params.statuses && params.statuses.join(','),
            limit: params.limit,
            offset: params.offset,
        };

        const res = await this.client.get(ApiRoutes.appointmentsRoute, { params: apiParams });
        return {
            next: res.data.next,
            appointments: res.data.results.map(appointmentFactory),
            totalCount: res.data.count,
            metadata: res.data.metadata,
        };
    }

    async cancelAppointment(id: number): Promise<AppointmentModel> {
        const res = await this.client.post(ApiRoutes.cancelAppointmentRoute(id));
        return appointmentFactory(res.data);
    }

    async rescheduleAppointment(id: number, slotKey: string): Promise<AppointmentModel> {
        const res = await this.client.post(ApiRoutes.rescheduleAppointmentRoute(id), { slot: slotKey });
        return appointmentFactory(res.data);
    }

    async getAppointmentReasons(): Promise<AppointmentReason[]> {
        const res = await this.client.get(ApiRoutes.appointmentReasonsRoute);
        return res.data;
    }

    async getAppointmentReason(id: number): Promise<AppointmentReason> {
        const res = await this.client.get(ApiRoutes.appointmentReasonsIdRoute(id));
        return res.data;
    }

    async getOrganizationMember(id: number): Promise<OrganizationMember> {
        const res = await this.client.get(ApiRoutes.organizationMembersIdRoute(id));
        return res.data;
    }
    async editOrganizationMember(id: number, values: MembersManagementData): Promise<OrganizationMember> {
        const res = await this.client.patch(ApiRoutes.organizationMembersIdRoute(id), values);
        return res.data;
    }

    async getOrganizationMembers(params: PaginatedDataParams): Promise<PaginatedData<OrganizationMember[]>> {
        const res = await this.client.get(ApiRoutes.organizationMembersRoute, { params });
        return res.data;
    }

    async getHealthcareService(serviceId: number): Promise<HealthcareService> {
        const res = await this.client.get(ApiRoutes.manageHealthcareServicesIdRoute(serviceId));
        return res.data;
    }

    async getHealthcareServices(params: HealthcareServicesParams): Promise<PaginatedData<HealthcareService[]>> {
        const res = await this.client.get(ApiRoutes.manageHealthcareServicesRoute, { params });
        return res.data;
    }

    async getAssignedHealthcareServices(): Promise<HealthcareService[]> {
        const res = await this.client.get(ApiRoutes.assignedHealthcareServicesRoute);
        return res.data;
    }

    async createHealthcareService(data: ServiceManagementData): Promise<HealthcareService> {
        const res = await this.client.post(ApiRoutes.manageHealthcareServicesRoute, data);
        return res.data;
    }

    async updateHealthcareService(serviceId: number, data: ServiceManagementData): Promise<HealthcareService> {
        const res = await this.client.patch(ApiRoutes.manageHealthcareServicesIdRoute(serviceId), data);
        return res.data;
    }

    async getOrganizationPatients(params: PaginatedDataParams): Promise<PaginatedData<User[]>> {
        const res = await this.client.get(ApiRoutes.getOrganizationPatientsRoute, { params });
        return res.data;
    }

    async getOrganizationPatient(userId: number): Promise<UserPatientProfile> {
        const res = await this.client.get(ApiRoutes.getOrganizationPatientRoute(userId));
        return res.data;
    }

    async getHealthcareServiceGroup(serviceGroupId: number): Promise<HealthcareServiceGroup> {
        const res = await this.client.get(ApiRoutes.manageHealthcareServiceGroupsIdRoute(serviceGroupId));
        return res.data;
    }

    async createHealthcareServiceGroup(data: ServiceGroupManagementData): Promise<HealthcareServiceGroup> {
        const res = await this.client.post(ApiRoutes.manageHealthcareServiceGroupsRoute, data);
        return res.data;
    }

    async updateHealthcareServiceGroup(
        serviceGroupId: number,
        data: ServiceGroupManagementData,
    ): Promise<HealthcareServiceGroup> {
        const res = await this.client.patch(ApiRoutes.manageHealthcareServiceGroupsIdRoute(serviceGroupId), data);
        return res.data;
    }

    async deleteHealthcareServiceGroup(serviceGroupId: number): Promise<void> {
        await this.client.delete(ApiRoutes.manageHealthcareServiceGroupsIdRoute(serviceGroupId));
    }

    async getHealthcareServiceGroups(
        params: HealthcareServicesParams,
    ): Promise<PaginatedData<HealthcareServiceGroup[]>> {
        const res = await this.client.get(ApiRoutes.manageHealthcareServiceGroupsRoute, { params });
        return res.data;
    }

    async getLocation(locationId: number): Promise<LocationData> {
        const res = await this.client.get(ApiRoutes.manageLocationsIdRoute(locationId));
        return res.data;
    }

    async getLocations(params: LocationParams): Promise<LocationAutocomplete[]> {
        const res = await this.client.get(ApiRoutes.locationsAutocompleteRoute, { params });
        return res.data;
    }

    async getLocationPlace(placeId: string): Promise<LocationData> {
        const res = await this.client.get(ApiRoutes.locationPlacesIdRoute(placeId));
        return res.data;
    }

    async getOrganizationLocations(
        params: OrganizationLocationsParams,
    ): Promise<PaginatedData<OrganizationLocation[]>> {
        const res = await this.client.get(ApiRoutes.getOrganizationLocationsRoute, { params });
        return res.data;
    }

    async getServiceLocations(params: LocationParams): Promise<PaginatedData<LocationData[]>> {
        const res = await this.client.get(ApiRoutes.manageLocationsRoute, { params });
        return res.data;
    }

    async createLocation(data: LocationManagementData): Promise<LocationData> {
        const res = await this.client.post(ApiRoutes.manageLocationsRoute, data);
        return res.data;
    }

    async updateLocation(locationId: number, data: EditLocationData): Promise<LocationData> {
        const res = await this.client.put(ApiRoutes.manageLocationsIdRoute(locationId), data);
        return res.data;
    }
    async deleteLocation(locationId: number): Promise<LocationData> {
        const res = await this.client.delete(ApiRoutes.manageLocationsIdRoute(locationId));
        return res.data;
    }

    async login(data: LoginData): Promise<TokenPair> {
        const res = await this.client.post(ApiRoutes.loginRoute, data);
        return res.data;
    }

    async requestLoginOtp(data: LoginOtpRequestData): Promise<void> {
        await this.client.post(ApiRoutes.requestLoginOtpRoute, data);
    }

    async loginWithCode(code: string, ephemeralToken: string): Promise<TokenPair> {
        const res = await this.client.post(ApiRoutes.loginCodeRoute, {
            ephemeral_token: ephemeralToken,
            code: code,
        });
        return res.data;
    }

    async registerPatient(form: PatientRegistrationData): Promise<TokenPair> {
        const data = {
            email: form.email,
            password: form.password,
            first_name: form.firstName,
            last_name: form.lastName,
            phone: form.phone,
            token: form.token,
            patient: form.patient,
            personal_title: form.personalTitle,
        };
        const res = await this.client.post(ApiRoutes.patientRegistrationRoute, data);
        return res.data;
    }

    async phoneValidation(phone: string): Promise<void> {
        await this.client.post(ApiRoutes.phoneValidation, {
            phone: phone,
        });
    }

    async checkEmailTaken(email: string): Promise<boolean> {
        const res = await this.client.post(ApiRoutes.checkFieldTakenRoute, {
            field: 'email',
            value: email,
        });
        return res.data.taken;
    }

    async refreshToken(refresh: string): Promise<TokenPair> {
        const res = await this.client.post(ApiRoutes.refreshTokenRoute, { refresh });
        return res.data;
    }

    async requestOtp(email: string, organization_id?: number): Promise<void> {
        await this.client.post(ApiRoutes.otpRequestsRoute, { email, organization_id });
    }

    async getOrganization(organizationSlug: string): Promise<OrganizationProfile> {
        const res = await this.client.get(ApiRoutes.organizationsSlugRoute(organizationSlug));
        return res.data;
    }

    async getOrganizationHealthcareServices(): Promise<BaseHealthcareService[]> {
        const res = await this.client.get(ApiRoutes.healthcareServicesRoute);
        return res.data;
    }

    async getCalendarOrganizationLocations(): Promise<OrganizationLocation[]> {
        const res = await this.client.get(ApiRoutes.getCalendarOrganizationLocationsRoute);
        return res.data;
    }

    async getCalendarConfig(): Promise<CalendarConfig> {
        const res = await this.client.get(ApiRoutes.getCalendarConfigRoute);
        return res.data;
    }

    async getInsuranceCompanies(): Promise<InsuranceCompany[]> {
        const res = await this.client.get(ApiRoutes.insuranceRoute);
        return res.data;
    }

    async getServiceCategories(params: ServiceCategoriesParams): Promise<PaginatedData<ServiceCategory[]>> {
        const res = await this.client.get(ApiRoutes.serviceCategoriesRoute, { params });
        return res.data;
    }

    async getOrganizationSlots(dateFrom?: Date, dateTo?: Date): Promise<Slot[]> {
        const params = {
            status: SlotStatus.FREE,
            slots_after: dateFrom ? formatISODateTime(moment(dateFrom)) : undefined,
            slots_before: dateTo ? formatISODateTime(moment(dateTo)) : undefined,
        };
        const res = await this.client.get(ApiRoutes.slotsOrganizationsRoute, { params });
        return res.data.results;
    }
    async getOrganizationSlotsForRequest(
        dateFrom: Date,
        dateTo: Date,
        healthcareServiceId: number,
        cv: number[] = [],
    ): Promise<Slot[]> {
        const params = {
            status: SlotStatus.FREE,
            slots_after: dateFrom ? formatISODateTime(moment(dateFrom)) : undefined,
            slots_before: dateTo
                ? formatISODateTime(
                      moment(new Date(dateFrom.getFullYear() + 1, dateFrom.getMonth(), dateFrom.getDate())),
                  )
                : undefined,
            hs: healthcareServiceId,
            pageSize: 100,
            pageOffset: 0,
            cv: cv.join(','),
        };
        const res = await this.client.get(ApiRoutes.slotsOrganizationsRequestsRoute, { params });
        return res.data.results;
    }
    async getOrganizationSlotsWithCriteria(
        dateFrom?: Date,
        dateTo?: Date,
        ordering?: string[],
        search?: string,
        pageSize?: number,
        pageOffset?: number,
    ): Promise<Slot[]> {
        const params = {
            status: SlotStatus.FREE,
            slots_after: dateFrom ? formatISODateTime(moment(dateFrom)) : undefined,
            slots_before: dateTo ? formatISODateTime(moment(dateTo)) : undefined,
            ordering: ordering?.join(','),
            search: search,
            pageSize: pageSize,
            pageOffset: pageOffset,
        };
        const res = await this.client.get(ApiRoutes.slotsOrganizationsCriteriaRoute, { params });
        return res.data.results;
    }
    async getSlot(slotKey: string): Promise<Slot> {
        const res = await this.client.get(ApiRoutes.slotPatientsRoute(slotKey));
        return res.data;
    }

    async getOrganizationSlot(slotKey: string): Promise<Slot> {
        const res = await this.client.get(ApiRoutes.slotOrganizationsRoute(slotKey));
        return res.data;
    }

    async createSlot(slot: SlotData): Promise<Slot> {
        const res = await this.client.post(ApiRoutes.slotsOrganizationsRoute, slot);
        return res.data;
    }

    async updateSlot(slot: SlotData, slotKey: string, scope: SlotEditScope): Promise<Slot> {
        const res = await this.client.put(ApiRoutes.slotOrganizationsRoute(slotKey), slot, { params: { scope } });
        return res.data;
    }

    async deleteSlot(slotKey: string, scope: SlotEditScope): Promise<void> {
        await this.client.delete(ApiRoutes.slotOrganizationsRoute(slotKey), { params: { scope } });
    }

    async bookSlot(data: SlotBookingData): Promise<AppointmentModel> {
        const body = {
            slot: data.slot.key,
            patient: data.patient,
            reason: data.reason,
            comment: data.comment,
            proposal: {
                personal_title: data.personal_title,
                phone: data.phone,
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                insurance_number: data.insurance_number,
                insurance_status: data.insurance_status,
                sms_agreement: data.sms_agreement,
                email_agreement: data.email_agreement,
                date_of_birth: data.date_of_birth && formatISODate(moment(data.date_of_birth)),
            },
            mode: data.mode,
            criteria_values: data.criteria_values,
        };
        const res = await this.client.post(ApiRoutes.slotBookingRoute, body);
        return appointmentFactory(res.data);
    }

    async getHealthcareServiceSlots(
        params: HealthcareServiceSlotsParams,
    ): Promise<PaginatedData<HealthcareServiceSlots[]>> {
        const apiParams: { [key: string]: string | number | undefined } = {
            location: params.location,
            offset: params.offset,
            id__in: params.healthcareServiceIds?.join(',') || undefined,
            key: params.healthcareServiceKey,
            slots_after: formatISODateTime(moment(params.slotsAfter)),
            slots_before: formatISODateTime(moment(params.slotsBefore)),
            available_for_reasons: params.availableForReasons ? params.availableForReasons.join(',') : undefined,
            criteria_values: params.criteriaValues,
            mode: params.mode,
            rescheduling_appointment: params.reschedulingAppointment,
        };

        if (params.generalSearch && params.generalSearchType) {
            const generalSearchParam = {
                [GeneralSearchItemType.healthcare_service_group]: 'keys',
                [GeneralSearchItemType.healthcare_service]: 'keys',
                [GeneralSearchItemType.practitioner]: 'managing_user_id__in',
                [GeneralSearchItemType.appointment_reason]: 'available_for_reasons',
            }[params.generalSearchType];
            apiParams[generalSearchParam] = params.generalSearch;
        }
        const res = await this.client.get(ApiRoutes.healthcareServiceSlotsRoute, {
            params: apiParams,
        });
        return res.data;
    }

    async generalSearch(params: GeneralSearchParams): Promise<GeneralSearch> {
        const res = await this.client.get(ApiRoutes.generalSearch, {
            params: params,
        });
        return res.data;
    }

    // Slots criteria
    async getSlotsCriteria(): Promise<SlotCriteria[]> {
        const res = await this.client.get(ApiRoutes.slotsCriteriaRoute);
        return res.data;
    }

    async getSlotsHSCriteria(params: HealthcareServicesCriteriaParams): Promise<SlotCriteria[]> {
        const res = await this.client.get(ApiRoutes.slotsCriteriaHSRoute, { params });
        return res.data;
    }

    async getSingleSlotsCriteria(id: number): Promise<SlotCriteria> {
        const res = await this.client.get(ApiRoutes.slotsCriteriaIdRoute(id));
        return res.data;
    }

    async getOrganizationSlotsCriteria(params: PaginatedDataParams): Promise<PaginatedData<SlotCriteriaTranslation[]>> {
        const res = await this.client.get(ApiRoutes.slotsCriteriaManagementRoute, { params });
        return res.data;
    }

    async getSingleOrganizationSlotsCriteria(id: number): Promise<SlotCriteriaTranslation> {
        const res = await this.client.get(ApiRoutes.slotsCriteriaManagementIdRoute(id));
        return res.data;
    }

    async createSlotCriteria(slotCriteriaData: SlotCriteriaManagementData): Promise<SlotCriteriaTranslation> {
        const res = await this.client.post(ApiRoutes.slotsCriteriaManagementRoute, slotCriteriaData);
        return res.data;
    }

    async updateSlotCriteria(
        id: number,
        slotCriteriaData: SlotCriteriaManagementData,
    ): Promise<SlotCriteriaTranslation> {
        const res = await this.client.put(ApiRoutes.slotsCriteriaManagementIdRoute(id), slotCriteriaData);
        return res.data;
    }

    async deleteSlotCriteria(id: number): Promise<void> {
        await this.client.delete(ApiRoutes.slotsCriteriaManagementIdRoute(id));
    }

    async uploadAppointmentAttachments(appointment: AppointmentModel, attachments: File[]): Promise<AppointmentModel> {
        const formData = new FormData();
        for (let i = 0; i < attachments.length; i++) {
            const attachment = attachments[i];
            const numberOfAttachments = appointment.attachments?.length || 0;
            const fileName = this.createAttachmentName(
                appointment.patient,
                numberOfAttachments + i + 1,
                attachment.name,
            );

            fileName && formData.append('files', attachment, fileName);
        }
        const res = await this.client.post(ApiRoutes.uploadAppointmentAttachmentRoute(appointment.id), formData);
        return appointmentFactory(res.data);
    }

    async uploadRequestAppointmentAttachments(
        requestAppointment: AppointmentRequestsModel,
        attachments: File[],
    ): Promise<AppointmentRequestsModel> {
        const formData = new FormData();
        for (let i = 0; i < attachments.length; i++) {
            const attachment = attachments[i];
            const numberOfAttachments = requestAppointment.attachments?.length || 0;
            const fileName = this.createAttachmentName(
                requestAppointment.patient,
                numberOfAttachments + i + 1,
                attachment.name,
            );

            fileName && formData.append('files', attachment, fileName);
        }
        const res = await this.client.post(
            ApiRoutes.uploadAppointmentRequestAttachmentRoute(requestAppointment.id),
            formData,
        );
        return appointmentRequestsFactory(res.data);
    }

    async getAttachmentLink(id: number): Promise<string> {
        const attachment = await this.client.get(ApiRoutes.appointmentAttachmentIdRoute(id));
        return attachment.data.url;
    }

    async getRequestAttachmentLink(id: number): Promise<string> {
        const rAttachment = await this.client.get(ApiRoutes.appointmentRequestAttachmentIdRoute(id));
        return rAttachment.data.url;
    }

    async updateAppointment(appointment: AppointmentUpdateData): Promise<AppointmentModel> {
        const { id } = appointment;
        const res = await this.client.patch(ApiRoutes.appointmentIdRoute(id), appointment);
        return appointmentFactory(res.data);
    }

    async updateRequestAppointment(requestAppointment: AppointmentRequestsModel): Promise<AppointmentRequestsModel> {
        const { id } = requestAppointment;
        const res = await this.client.patch(ApiRoutes.appointmentRequestRoute(id), requestAppointment);
        return appointmentRequestsFactory(res.data);
    }

    async uploadAttachment(file: File): Promise<Attachment> {
        const formData = new FormData();
        formData.append('file', file);
        const res = await this.client.post(ApiRoutes.uploadAttachmentRoute, formData);
        return res.data;
    }

    async updateOrganizationProfile(data: OrganizationProfileFormData): Promise<OrganizationProfile> {
        const res = await this.client.patch(ApiRoutes.manageOrganizationProfileRoute, data);
        return res.data;
    }

    async updateMemberProfile(data: EditMemberProfileData): Promise<UserProfile> {
        const values: Partial<UserUpdateData> = {
            practitioner: data,
        };
        const res = await this.client.patch(ApiRoutes.userProfileRoute, values);
        return res.data;
    }

    async updateUserProfile(userData: UserUpdateData): Promise<UserProfile> {
        const data: RecursivePartial<UserProfile> = {
            first_name: userData.firstName,
            last_name: userData.lastName,
            phone: userData.phone,
            personal_title: userData.personalTitle,
        };
        if (userData.role === UserRole.patient) {
            data.patient = {
                date_of_birth: userData.dateOfBirth && formatISODate(moment(userData.dateOfBirth)),
                post_code: userData.postCode,
                city: userData.city,
                street: userData.street,
                insurance_number: userData.insuranceNumber,
                insurance_company: userData.insuranceCompany,
                insurance_status: userData.insuranceStatus,
                sms_agreement: userData.smsAgreement,
                email_agreement: userData.emailAgreement,
            };
        }
        const res = await this.client.patch(ApiRoutes.userProfileRoute, data);
        return res.data;
    }

    async updateUserLanguage(language: Language): Promise<UserProfile> {
        const res = await this.client.patch(ApiRoutes.userProfileRoute, { language });
        return res.data;
    }

    async getUserProfile(): Promise<UserProfile> {
        const res = await this.client.get(ApiRoutes.userProfileRoute);
        return res.data;
    }

    async getUserProfiles(params: PaginatedDataParams): Promise<PaginatedData<UserProfileOrganization[]>> {
        const res = await this.client.get(ApiRoutes.userProfilesRoute, { params });
        return res.data;
    }

    async setUserPassword(password: NewPasswordData): Promise<void> {
        await this.client.post(ApiRoutes.passwordResetSetNewPassword, {
            password: password.password,
            token: password.token,
        });
    }

    async checkRecoveryToken(token: string): Promise<void> {
        await this.client.post(ApiRoutes.passwordResetCheckRecoveryToken, { token: token });
    }

    async requestPasswordReset(email: string): Promise<void> {
        await this.client.post(ApiRoutes.requestPasswordResetRoute, { email });
    }

    async inviteOrganizationMember(data: MemberInvitationData): Promise<User> {
        const res = await this.client.post(ApiRoutes.inviteOrganizationMemberRoute, data);
        return res.data;
    }

    async resendOrganizationMemberInvitation(data: MemberInvitationData): Promise<User> {
        const res = await this.client.post(ApiRoutes.resendOrganizationMemberInvitationRoute, data);
        return res.data;
    }

    async invitePatient(data: PatientInvitationData): Promise<User> {
        const body = {
            email: data.email,
            first_name: data.firstName,
            last_name: data.lastName,
            personal_title: data.personalTitle,
            phone: data.phone,
            patient: {
                date_of_birth: formatISODate(moment(data.patient.date_of_birth)),
            },
        };
        const res = await this.client.post(ApiRoutes.invitePatientRoute, body);
        return res.data;
    }

    async validateInvitation(invitationToken: string): Promise<User> {
        const res = await this.client.post(ApiRoutes.validateInvitationRoute, {
            invitation_token: invitationToken,
        });
        return res.data;
    }

    async acceptInvitation(data: AcceptInvitationData): Promise<TokenPair> {
        const res = await this.client.post(ApiRoutes.acceptInvitationRoute, {
            invitation_token: data.invitationToken,
            password: data.password,
            personal_title: data.personalTitle,
            first_name: data.firstName,
            last_name: data.lastName,
        });
        return res.data;
    }

    private createAttachmentName(user: UserPatientProfile | undefined, index: number, oldFileName: string): string {
        if (user === undefined) return '';

        const fileExtension =
            oldFileName.substring(oldFileName.lastIndexOf('.') + 1, oldFileName.length) || oldFileName;

        return user.first_name + '_' + user.last_name + '_' + index + '.' + fileExtension;
    }

    async adminListOrganizations(params: PaginatedDataParams): Promise<PaginatedData<AdminOrganization[]>> {
        const res = await this.client.get(ApiRoutes.organizationAdminRoot, { params });
        return res.data;
    }

    async adminEditOrganization(organizationId: number, data: OrganizationProfileFormData): Promise<AdminOrganization> {
        const res = await this.client.patch(ApiRoutes.organizationAdminEdit(organizationId), data);
        return res.data;
    }

    async adminCreateOrganization(data: OrganizationProfileFormData): Promise<AdminOrganization> {
        const res = await this.client.post(ApiRoutes.organizationAdminRoot, data);
        return res.data;
    }

    //questionnaires
    async getQuestionnaires(params: PaginatedDataParams): Promise<PaginatedData<Questionnaire[]>> {
        const res = await this.client.get(ApiRoutes.questionnaires, { params });
        return res.data;
    }

    async getQuestionnaire(id: number): Promise<Questionnaire> {
        const res = await this.client.get(ApiRoutes.questionnairesId(id));
        return res.data;
    }

    async createQuestionnaire(data: QuestionnaireData): Promise<Questionnaire> {
        const res = await this.client.post(ApiRoutes.questionnaires, data);
        return res.data;
    }

    async updateQuestionnaire(id: number, data: QuestionnaireData): Promise<Questionnaire> {
        const res = await this.client.patch(ApiRoutes.questionnairesId(id), data);
        return res.data;
    }

    async deleteQuestionnaire(id: number): Promise<void> {
        await this.client.delete(ApiRoutes.questionnairesId(id));
    }

    // QuestionnaireResponses

    async getQuestionnaireResponses(params: PaginatedDataParams): Promise<PaginatedData<QuestionnaireResponse[]>> {
        const res = await this.client.get(ApiRoutes.questionnaires, { params });
        return res.data;
    }

    async getQuestionnaireResponse(
        appointmentId: number,
        id: number,
        accessToken?: string,
    ): Promise<QuestionnaireResponseExtended> {
        const res = await this.client.get(ApiRoutes.questionnaireResponsesId(appointmentId, id), {
            params: { access_token: accessToken },
        });
        return res.data;
    }

    async createQuestionnaireResponse(
        appointmentId: number,
        data: QuestionnaireResponseData,
    ): Promise<QuestionnaireResponseExtended> {
        const res = await this.client.post(ApiRoutes.questionnaireResponses(appointmentId), data);
        return res.data;
    }

    async updateQuestionnaireResponse(
        id: number,
        appointmentId: number,
        data: QuestionnaireResponseData,
        accessToken?: string,
    ): Promise<QuestionnaireResponse> {
        const res = await this.client.patch(ApiRoutes.questionnaireResponsesId(appointmentId, id), data, {
            params: { access_token: accessToken },
        });
        return res.data;
    }

    async deleteQuestionnaireResponse(id: number, appointmentId: number): Promise<void> {
        await this.client.delete(ApiRoutes.questionnaireResponsesId(appointmentId, id));
    }

    async getAppointmentRequests(params: AppointmentRequestParams): Promise<AppointmentRequestsDataPage> {
        let statuses: AppointmentRequestStatus[] = [];
        if (params.statuses) {
            if (params.statuses?.length == 0) {
                statuses = [
                    AppointmentRequestStatus.PENDING,
                    AppointmentRequestStatus.CANCELED,
                    AppointmentRequestStatus.RESPONDED,
                    AppointmentRequestStatus.APPROVED,
                    AppointmentRequestStatus.REJECTED,
                ];
            } else {
                statuses = params.statuses;
            }
        }
        const apiParams = {
            search: params.search,
            ordering: params.ordering,
            status__in: statuses.join(','),
            limit: params.limit,
            offset: params.offset,
        };

        const res = await this.client.get(ApiRoutes.appointmentRequestsRoute, { params: apiParams });
        res.data.results.map(async (app: AppointmentRequestsModel) => {
            app.isChecked = false;
            if (app.healthcare_service_group) {
                const services = await this.getHealthcareServiceGroup(app.healthcare_service_group.id);
                app.healthcare_service_group.services = services.services;
            }
        });
        return {
            next: res.data.next,
            appointment_requests: res.data.results.map(appointmentRequestsFactory),
            totalCount: res.data.count,
            metaData: res.data.metadata.count_by_booking_status,
        };
    }
    async createAppointmentRequest(appointmentRequest: AppointmentRequestData): Promise<AppointmentRequestsModel> {
        const res = await this.client.post(ApiRoutes.appointmentRequestsRoute, appointmentRequest);
        return res.data;
    }
    async getAppointmentRequest(id: number): Promise<AppointmentRequestsModel> {
        const res = await this.client.get(ApiRoutes.appointmentRequestRoute(id));
        return appointmentRequestsFactory(res.data);
    }
    async changeAppointmentRequestStatus(
        id: number,
        status: AppointmentRequestStatus,
        idAppointment: number | null = null,
    ) {
        if (status == AppointmentRequestStatus.APPROVED && idAppointment) {
            const res = await this.client.get(ApiRoutes.appointmentRequestMarkApprovedRoute(id, idAppointment));
            return res.data;
        }
        if (status == AppointmentRequestStatus.RESPONDED) {
            const res = await this.client.get(ApiRoutes.appointmentRequestMarkRespondedRoute(id));
            return res.data;
        }
        if (status == AppointmentRequestStatus.PENDING) {
            const res = await this.client.get(ApiRoutes.appointmentRequestMarkPendingRoute(id));
            return res.data;
        }
        if (status == AppointmentRequestStatus.CANCELED) {
            const res = await this.client.get(ApiRoutes.appointmentRequestMarkCanceledRoute(id));
            return res.data;
        }
    }
}
