import React, { FC } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';

import { Route, Switch } from 'react-router';
import { PersistGate } from 'redux-persist/integration/react';

import { history, persistor, store } from 'store';

import './bootstrap.css';
import './App.css';

import './i18n/i18n';
import './services/yup-config';
import { CookieConsent } from 'components/PrivacyPolicy/CookiesConsent';
import { AuthGate } from 'components/Auth/AuthGate';
import { BrandedRouter, SuperAdminRouter } from 'components/Routers';
import { NotFoundPage } from 'components/NotFoundPage';
import { useUpdateWindowDimensions } from 'medrefer-web-sdk/web-kit/hooks';
import { ThemeProviderWrapper } from 'providers/ThemeProviderWrapper';
import { getPath } from 'router-paths';
import UpdateListener from 'utils/UpdateListener';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const App: FC = () => {
    useUpdateWindowDimensions();

    return (
        <Provider store={store}>
            <QueryClientProvider client={queryClient}>
                <ThemeProviderWrapper>
                    <CookieConsent />
                    <PersistGate loading={null} persistor={persistor}>
                        <ConnectedRouter history={history}>
                            <AuthGate>
                                <Switch>
                                    <Route exact path={getPath('notFound')} component={NotFoundPage} />
                                    <Route path={getPath('admin')}>
                                        <SuperAdminRouter />
                                    </Route>
                                    <BrandedRouter />
                                    <Route component={NotFoundPage} />
                                </Switch>
                            </AuthGate>
                            <UpdateListener />
                        </ConnectedRouter>
                    </PersistGate>
                </ThemeProviderWrapper>
            </QueryClientProvider>
        </Provider>
    );
};

export default App;
