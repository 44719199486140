import React, { useEffect, useMemo, useState } from 'react';
import { ArrowButton, ModalState, Option, Select, SingleValue } from 'medrefer-web-sdk/web-kit';
import { ButtonsContainer, GreyOutlineButton } from './CalendarTableMenu.styled';
import { useTranslation } from 'react-i18next';
import { getActiveLanguage } from '../../../../utils/i18n';
import { useQueryParams } from '../../../../medrefer-web-sdk/web-kit/hooks';

interface Props {
    handleToday: () => void;
    handlePrev: () => void;
    handleNext: () => void;
    dateFormatted: string;
    handlePageSizeChange: (newValue: number) => void;
    handleUnselectAll: () => void;
    handleSelectAll: () => void;
    editModalSlotState: ModalState;
    handleDeleteAllSelected: () => void;
    tableParams: { pageIndex: number; pageSize: number; searchPhrase: string };
    checkedCount: number;
}

export const CalendarTableMenu = (props: Props) => {
    const { t } = useTranslation();
    const pageSizeOptions: Option[] = useMemo(() => {
        return [
            { value: '10', label: t('adminPanel:table.show_x_items', { x: 10 }) },
            { value: '20', label: t('adminPanel:table.show_x_items', { x: 20 }) },
            { value: '30', label: t('adminPanel:table.show_x_items', { x: 30 }) },
            { value: '40', label: t('adminPanel:table.show_x_items', { x: 40 }) },
            { value: '50', label: t('adminPanel:table.show_x_items', { x: 50 }) },
        ];
        // eslint-disable-next-line
    }, [getActiveLanguage()]);

    const [pageSizeOption, setPageSizeOption] = useState<Option | null>(null);
    const queryParams = useQueryParams();
    useEffect(() => {
        const pageSize = props.tableParams.pageSize.toString();
        if (pageSize) {
            const index = pageSizeOptions.findIndex((option) => option.value == pageSize);
            setPageSizeOption(pageSizeOptions[index]);
        } else {
            setPageSizeOption(pageSizeOptions[0]);
        }
    }, [pageSizeOptions, queryParams, props.tableParams.pageSize]);

    const onOptionChange = (option: SingleValue<Option>) => {
        if (option) {
            setPageSizeOption(option);
            props.handlePageSizeChange(Number.parseInt(option.value));
        }
    };

    return (
        <ButtonsContainer>
            <GreyOutlineButton onClick={props.handleToday}> {t('calendar:today')}</GreyOutlineButton>
            <div style={{ width: 'fit-content' }}>
                <ArrowButton direction="left" onClick={props.handlePrev} />
                <ArrowButton direction="right" onClick={props.handleNext} />
            </div>
            <span style={{ minWidth: '150px' }}>{props.dateFormatted}</span>
            <GreyOutlineButton style={{ width: 'fit-content' }} onClick={props.handleSelectAll}>
                {t('calendar:selectAllButton')}
            </GreyOutlineButton>
            <GreyOutlineButton style={{ width: 'fit-content' }} onClick={props.handleUnselectAll}>
                {t('calendar:unselectAllButton')}
            </GreyOutlineButton>
            <GreyOutlineButton
                disabled={props.checkedCount == 0}
                style={{ width: 'fit-content' }}
                onClick={() => {
                    props.editModalSlotState.toggleModal();
                }}
            >
                {t('calendar:calendarTable:changeAccess')}
            </GreyOutlineButton>
            <GreyOutlineButton
                disabled={props.checkedCount == 0}
                style={{ width: 'fit-content' }}
                onClick={props.handleDeleteAllSelected}
            >
                {t('calendar:deleteButton')}
            </GreyOutlineButton>
            <div style={{ marginLeft: 'auto', marginRight: '15px' }}>
                <Select
                    name={'type'}
                    value={pageSizeOption}
                    onChange={onOptionChange}
                    placeholder={t('calendar:type')}
                    options={pageSizeOptions}
                    isSearchable={false}
                />
            </div>
        </ButtonsContainer>
    );
};
