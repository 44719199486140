import React, { FC } from 'react';
import { DataTable } from 'components/shared/DataTable';
import { AdminPanelContainer, FormActivityIndicatorContainer } from 'components/AdminPanel/AdminPanel.styled';
import { useManageServices } from './hooks/useManageServices';
import { columns } from './ManageServices.consts';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';
import { ServicesForm } from './ServicesForm';
import { ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { RouteBreadcrumbs } from 'components/shared/RouteBreadcrumbs';

export const ManageServices: FC = () => {
    const { t } = useTranslation();
    const { createHealthcareService, updateHealthcareService, request, activeItem, fetchActiveItem, ...rest } =
        useManageServices();

    return (
        <>
            <AdminPanelContainer>
                <DataTable
                    title={t('adminPanel:manageServices.title')}
                    actionColumnTitle={t('adminPanel:table.columns.actions')}
                    noItemsMessage={t('adminPanel:table.no_items_message')}
                    columns={columns(t)}
                    addItemForm={
                        <ServicesForm type={ModalType.add} request={request} handleSubmit={createHealthcareService} />
                    }
                    editItemForm={
                        activeItem ? (
                            <ServicesForm
                                activeItem={activeItem}
                                type={ModalType.edit}
                                request={request}
                                handleSubmit={updateHealthcareService}
                            />
                        ) : (
                            <FormActivityIndicatorContainer>
                                <ActivityIndicator />
                            </FormActivityIndicatorContainer>
                        )
                    }
                    setActiveItem={fetchActiveItem}
                    addButtonText={t('adminPanel:table.controls.addButtonText')}
                    previousPageText={t('adminPanel:table.controls.previousPageText')}
                    nextPageText={t('adminPanel:table.controls.nextPageText')}
                    breadcrumbs={<RouteBreadcrumbs />}
                    {...rest}
                />
            </AdminPanelContainer>
        </>
    );
};
