import { useNonInitialEffect } from 'medrefer-web-sdk/web-kit/hooks';
import { useState, useEffect } from 'react';
import { getStorageItem, setStorageItem } from 'services/local-storage-service';

const useCookiesConsent = () => {
    const cookieName = 'cookiesConsent';
    const [isAccepted, setIsAccepted] = useState<boolean>(false);

    useEffect(() => {
        setIsAccepted(getStorageItem(cookieName) === 'true');
    }, []);

    useNonInitialEffect(() => {
        setStorageItem(cookieName, isAccepted.toString());
    }, [isAccepted]);

    const acceptCookies = () => {
        setIsAccepted(true);
    };

    return { isAccepted, acceptCookies };
};

export default useCookiesConsent;
