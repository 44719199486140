import { useState } from 'react';
import { sortHandlerToggle } from './DataTable.helpers';

export const useTableOrdering = (defaultOrdering: string[] = [], allowMultiple = false) => {
    const [ordering, setOrdering] = useState<string[]>(defaultOrdering);

    const sortHandler = (sortParam: string) => {
        setOrdering(sortHandlerToggle(ordering, sortParam, allowMultiple));
    };

    return {
        ordering,
        sortHandler,
    };
};
