import React from 'react';
import { Switch, Route } from 'react-router';
import { SuperAdminRoute } from 'components/shared/routes';
import { Profile } from 'components/Profile';
import { Settings } from 'components/Settings';
import { NavigationProvider } from 'providers/NavigationProvider';
import { getPath, getPathAdmin } from 'router-paths';
import { ManageOrganizations } from 'components/SuperAdminPanel/ManageOrganizations';
import { Login } from 'components/Auth/Login';

export const SuperAdminRouter = () => {
    return (
        <NavigationProvider>
            <Switch>
                <Route exact path={getPathAdmin('login')} component={Login} />
                <SuperAdminRoute exact path={getPath('admin')} component={ManageOrganizations} />
                <SuperAdminRoute exact path={getPathAdmin('settings')} component={Settings} />
                <SuperAdminRoute exact path={getPathAdmin('profile')} component={Profile} />
            </Switch>
        </NavigationProvider>
    );
};
