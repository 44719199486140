const settingsProd = {
    settingsEnabled: true,
    i18n: {
        fallbackLng: 'en',
        supportedLngs: ['de', 'en', 'pl'],
    },
};

const settingsDev = {
    settingsEnabled: true,
    i18n: {
        fallbackLng: 'en',
        supportedLngs: ['de', 'en', 'pl'],
    },
};

export const currentEnv = process.env.REACT_APP_ENV;
export const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';

export const settings = currentEnv === 'production' ? settingsProd : settingsDev;
