import React from 'react';
import FilterIcon from 'assets/FilterIcon.svg';
import { ReactSVG } from 'react-svg';
import { IconButtonAlt2, DropDownMenuFilter } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import usePopoverMenu from 'hooks/usePopoverMenu';
import { SortTypes } from '../../medrefer-web-sdk/api/models';
import { AppointmentRequestStatus, RequestCountData } from '../../medrefer-web-sdk/api/models/AppointmentRequestsModel';
import { RequestFilterForm } from './RequestFilterForm';

export interface RequestsFilters {
    sort: SortTypes;
    statuses: AppointmentRequestStatus[];
    search: string;
    setStatuses: (status: AppointmentRequestStatus[]) => void;
}
interface Props {
    filters: RequestsFilters;
    countData: RequestCountData;
}

export const RequestListFilter = ({ filters, countData }: Props) => {
    const queryParams = useQueryParams();
    const { t } = useTranslation();
    const { isOpen, handleToggle, menuRef } = usePopoverMenu();

    const setStatuses = (items: AppointmentRequestStatus[]) => {
        queryParams.set(
            'status',
            items.map((item) => item),
        );
    };

    const filtersCount = filters.statuses.length;

    return (
        <div className="mr-2" ref={menuRef}>
            <IconButtonAlt2 onClick={handleToggle} style={{ width: '100%' }}>
                <ReactSVG src={FilterIcon} />
                {filtersCount > 0 ? (
                    <div className="pl-1 filter-labels">
                        +{filtersCount}
                        <span>filter(s)</span>
                    </div>
                ) : (
                    <div className="pl-1 filter-labels">{t('filterMenu:all_requests')}</div>
                )}
            </IconButtonAlt2>
            <DropDownMenuFilter isOpen={isOpen}>
                <RequestFilterForm countData={countData} filters={filters} setStatuses={setStatuses} />
            </DropDownMenuFilter>
        </div>
    );
};
