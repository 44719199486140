import React, { FC } from 'react';
import CancelUploadFile from '../../assets/icons/CancelUploadFile.png';

interface Props {
    click: () => void;
    name: string;
    listIndex?: number;
}

export const Thumb: FC<Props> = ({ name, click, listIndex }: Props) => {
    return (
        <div className="mb-2 d-flex text-break" style={{ cursor: 'pointer' }} onClick={() => click()}>
            <span className="mr-3" style={{ wordBreak: 'break-word' }}>{`${
                listIndex ? listIndex + '.' : ''
            } ${name}`}</span>
            <img
                src={CancelUploadFile}
                style={{ height: '15px', transform: 'translateY(5px)' }}
                alt="Cancel upload icon"
                className="mr-2"
            />
        </div>
    );
};
