import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const Message = styled.div`
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    font-weight: 600;
    margin-top: 15px;
`;

export const MobileNotAvailableContainer = styled(Container)`
    height: calc(var(--maxvh) - ${({ theme }) => theme.navbarHeight.desktop});

    @media (min-width: ${({ theme }) => theme.devices.desktop}) {
        display: none;
    }
`;
