import styled from 'styled-components';
import { Col } from 'react-bootstrap';

interface MenuSettingsProps {
    isOpen?: boolean;
}

export const MenuSettings = styled.div<MenuSettingsProps>`
    display: ${(props) => (props.isOpen ? 'flex' : 'none')};
    position: absolute;
    width: 350px;
    left: 88px;
    background: #ffffff;
    box-shadow: 0 1px 5px rgba(44, 51, 71, 0.12);
    border-radius: 8px;
    padding: 30px 0;
    z-index: 990;

    @media (min-width: ${({ theme }) => theme.devices.tablet}) {
        bottom: auto;
        left: auto;
        top: 75px;
        right: 20px;
    }

    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        width: auto;
        top: ${({ theme }) => theme.navbarHeight.mobile};
        left: 0;
        right: 0;
        position: fixed;
    }
`;

export const RightCol = styled(Col)`
    max-width: 350px;
    margin: 0 auto;
`;

export const UserEmail = styled.span`
    display: block;
    font-size: 15px;
    line-height: 25px;
    color: #191c21;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const UserDetails = styled.span`
    display: block;
    font-style: normal;
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 1.25px;
    color: #9d9eb2;
    max-width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
`;

export const DecorationLine = styled.div`
    width: 100%;
    height: 1px;
    background: #e6e8eb;
    margin-top: 10px;
    margin-bottom: 10px;
`;
