import {
    CalendarType,
    ClickScheduleEvent,
    CreateScheduleEvent,
    DateRange,
    UpdateScheduleEvent,
} from 'components/Calendar/CalendarView/CalendarView.types';
import { api } from 'medrefer-web-sdk/api';
import { SchedulePopoverType, ScheduleType } from 'components/Calendar/Calendar.types';
import { useState } from 'react';
import { ActiveSchedule } from 'components/Calendar/SchedulePopover/SchedulePopover.types';
import { usePopoverState } from 'medrefer-web-sdk/web-kit/components/Popover';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'features/auth/selectors';
import { canManageSlots } from 'features/auth/rules';

interface ScheduleData {
    id: string;
    type: ScheduleType;
}

export const useCalendarCallbacks = () => {
    const [schedulePopoverType, setSchedulePopoverType] = useState<SchedulePopoverType>(SchedulePopoverType.create);
    const [activeSchedule, setActiveSchedule] = useState<ActiveSchedule | null>(null);
    const authUser = useSelector(getAuthUser);
    let popoverHeight;
    if (activeSchedule?.type === ScheduleType.slot) {
        popoverHeight = authUser && canManageSlots(authUser) ? 800 : 325;
    }
    const schedulePopoverState = usePopoverState({
        height: popoverHeight,
        width: 600,
        actionOnClose: () => setActiveSchedule(null),
    });

    const setMonthActiveSchedule = (data: ScheduleData, onChangeDate?: (date: Date) => void) => {
        api.getOrganizationSlot(data.id).then((slot) => {
            const newStart = new Date(slot.date_from);
            const duration = (new Date(slot.date_to).getTime() - newStart.getTime()) / (60 * 1000);
            const newEnd = new Date(new Date(slot.date_from).getTime() + duration * (60 * 1000));
            onChangeDate && onChangeDate(newStart);
            setActiveSchedule({
                id: data.id,
                dateFrom: newStart,
                dateTo: newEnd,
                duration: duration,
                type: stringToScheduleType(data.type),
            });
        });
    };

    const getRangeDuration = (range: DateRange) => {
        return (range.dateTo.getTime() - range.dateFrom.getTime()) / (60 * 1000);
    };

    const stringToScheduleType = (raw: string) => {
        return raw === ScheduleType.slot ? ScheduleType.slot : ScheduleType.appointment;
    };

    const onClickSchedule = (e: ClickScheduleEvent) => {
        if (e.calendarType === CalendarType.month && e.data.type === ScheduleType.slot) {
            setMonthActiveSchedule(e.data);
        } else {
            setActiveSchedule({
                id: e.data.id,
                dateFrom: e.dateRange.dateFrom,
                dateTo: e.dateRange.dateTo,
                duration: getRangeDuration(e.dateRange),
                type: stringToScheduleType(e.data.type),
            });
        }

        setSchedulePopoverType(SchedulePopoverType.edit);
        schedulePopoverState.setTarget(e.domRect);
        schedulePopoverState.togglePopover();
    };

    const onCreateSchedule = (e: CreateScheduleEvent) => {
        setActiveSchedule({
            id: '',
            dateFrom: e.dateRange.dateFrom,
            dateTo: e.dateRange.dateTo,
            duration: Math.min(360, getRangeDuration(e.dateRange)),
            type: ScheduleType.slot,
        });
        setSchedulePopoverType(SchedulePopoverType.create);

        schedulePopoverState.setTarget(e.calendarType === CalendarType.day ? undefined : e.domRect);
        schedulePopoverState.togglePopover();
    };

    const onUpdateSchedule = (e: UpdateScheduleEvent) => {
        if (e.calendarType == CalendarType.month) {
            setMonthActiveSchedule(e.data, (newStart: Date) => {
                newStart.setMonth(e.dateRange.dateFrom.getMonth(), e.dateRange.dateFrom.getDate());
            });
        } else {
            setActiveSchedule({
                id: e.data.id,
                dateFrom: e.dateRange.dateFrom,
                dateTo: e.dateRange.dateTo,
                duration: getRangeDuration(e.dateRange),
                type: stringToScheduleType(e.data.type),
            });
        }

        schedulePopoverState.setTarget(undefined);
        schedulePopoverState.togglePopover();
    };

    return {
        schedulePopoverType,
        schedulePopoverState,
        activeSchedule,
        setActiveSchedule,
        onClickSchedule,
        onCreateSchedule,
        onUpdateSchedule,
    };
};
