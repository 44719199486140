import { QuestionnaireResponseFormData } from '../QuestionnaireResponseForm.types';
import { Answer, FhirQuestionnaireResponseStatus, FhirQuestionnaireResponse } from 'medrefer-web-sdk/api/models';

export const questionnaireResponseMapper = (data: QuestionnaireResponseFormData): FhirQuestionnaireResponse => {
    return {
        status: FhirQuestionnaireResponseStatus.COMPLETED,
        item: data.items.map((item) => ({
            linkId: item.linkId,
            text: item.text,
            answer: [mapValueToAnswer(item.value)],
        })),
    };
};

// TODO: verify it this mapping is enough when API ready
// Answer object has many types that look similar: Date, Time, DateTime | Text, String | etc
const mapValueToAnswer = (value: string | number | boolean | Date): Answer => {
    if (typeof value === 'string') {
        return {
            valueString: value,
        };
    }
    if (typeof value === 'number') {
        return {
            valueDecimal: value,
        };
    }
    if (typeof value === 'boolean') {
        return {
            valueBoolean: value,
        };
    }
    if (value instanceof Date) {
        return {
            valueDateTime: value,
        };
    }

    return {};
};
