import React from 'react';
import { MembersFormProps } from './MembersForm.types';
import { Form, FormikProps, withFormik } from 'formik';
import { useTranslation, withTranslation } from 'react-i18next';
import { mapPropsToValues, validationSchema } from './MembersForm.schema';
import {
    FormErrorMessage,
    Button,
    TextRed,
    UnderlineH2 as Title,
    InformationContainer,
} from 'medrefer-web-sdk/web-kit';
import { Col, Container, Row } from 'react-bootstrap';
import { MembersData } from './MembersData';
import { MembersManagementData } from 'medrefer-web-sdk/api/models';

export const InnerForm = (props: MembersFormProps & FormikProps<MembersManagementData>) => {
    const { t } = useTranslation();

    return (
        <>
            <Title>{t('adminPanel:manageMembers.editForm.title')}</Title>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                }}
            >
                <MembersData baseProps={props} />

                <InformationContainer>
                    <FormErrorMessage apiErrors={props.request.errors} />
                </InformationContainer>
                <Container>
                    <Row>
                        <Col className="m-auto" xs={12} md={12}>
                            <Button isLoading={props.request.isLoading}>
                                {t('adminPanel:manageMembers.editForm.edit_button')}
                            </Button>
                            {!props.isValid && !props.isValidating && (
                                <TextRed className="text-right mr-3 mt-4">
                                    {t('newAppointmentForm:warning_please_fill_all')}
                                </TextRed>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Form>
        </>
    );
};

const LocationsForm = withFormik<MembersFormProps, MembersManagementData>({
    mapPropsToValues: mapPropsToValues,
    validationSchema: validationSchema,
    handleSubmit: (values, { props }) => {
        props.handleSubmit(values);
    },
})(InnerForm);

export default withTranslation()(LocationsForm);
