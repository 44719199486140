import React from 'react';
import { SlotCriteriaFormValues, SlotsCriteriaFormProps } from './SlotsCriteriaForm.types';
import { useTranslation, withTranslation } from 'react-i18next';
import { Button, FormErrorMessage, InformationContainer, TextRed } from 'medrefer-web-sdk/web-kit';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';
import { Form, FormikProps, withFormik } from 'formik';
import { Col, Container, Row } from 'react-bootstrap';
import { mapPropsToValues, validationSchema, handleSubmit } from './SlotsCriteriaForm.schema';
import { SlotsCriteriaBaseData } from './SlotsCriteriaBaseData';
import { SlotsCriteriaConditionalAppearanceData } from 'components/AdminPanel/ManageSlotsCriteria/SlotsCriteriaForm/SlotsCriteriaConditionalAppearanceData';
import { SlotsCriteriaValuesData } from 'components/AdminPanel/ManageSlotsCriteria/SlotsCriteriaForm/SlotsCriteriaValuesData';

export interface FormikBaseProps {
    baseProps: SlotsCriteriaFormProps & FormikProps<SlotCriteriaFormValues>;
}

const InnerForm = (props: SlotsCriteriaFormProps & FormikProps<SlotCriteriaFormValues>) => {
    const { t } = useTranslation();
    return (
        <>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                }}
            >
                <SlotsCriteriaBaseData baseProps={props} />
                <SlotsCriteriaConditionalAppearanceData baseProps={props} />
                <SlotsCriteriaValuesData baseProps={props} />

                <InformationContainer>
                    <FormErrorMessage apiErrors={props.request.errors} />
                </InformationContainer>
                <Container>
                    <Row>
                        <Col className="m-auto" xs={12} md={12}>
                            <Button isLoading={props.request.isLoading}>
                                {props.type === ModalType.add
                                    ? t('adminPanel:manageServices.servicesForm.button_add')
                                    : t('adminPanel:manageServices.servicesForm.button_edit')}
                            </Button>
                            {!props.isValid && !props.isValidating && (
                                <TextRed className="text-right mr-3 mt-4">
                                    {t('newAppointmentForm:warning_please_fill_all')}
                                </TextRed>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Form>
        </>
    );
};

const SlotsCriteriaForm = withFormik<SlotsCriteriaFormProps, SlotCriteriaFormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(InnerForm);

export default withTranslation()(SlotsCriteriaForm);
