import React from 'react';

import { SidebarMenu, FiltersContainer } from './SidebarListMenu.styled';

import { SidebarListMenuSearch } from '../SidebarListMenuSearch';
import { SidebarListMenuSort } from '../SidebarListMenuSort';
import { SidebarListMenuFilter } from '../SidebarListMenuFilter';
import { DashboardFilters } from 'components/Dashboard/Dashboard.types';
import { CountData } from 'features/appointment/reducer';

interface Props {
    filters: DashboardFilters;
    countData: CountData;
}

export const SidebarListMenu = ({ filters, countData }: Props) => {
    return (
        <SidebarMenu>
            <SidebarListMenuSearch initialSearch={filters.search} />
            <FiltersContainer>
                <SidebarListMenuFilter filters={filters} countData={countData} />
                <SidebarListMenuSort sort={filters.sort} />
            </FiltersContainer>
        </SidebarMenu>
    );
};
