import * as Yup from 'yup';
import { AdminOrganizationFormProps, AdminOrganizationFormValues } from './AdminOrganizationForm.types';
import { theme } from 'medrefer-web-sdk/web-kit';
import { FormikBag } from 'formik';
import { mapPropsValuesForNoSlots } from 'components/shared/NoSlotsBehaviourSubForm/NoSlotsBehaviourSubForm.schema';
import { parseFormToNoSlotsBehaviour } from 'components/shared/NoSlotsBehaviourSubForm/NoSlotsBehaviourSubForm.helpers';
import { AdminOrganizationFormData } from '../../../../medrefer-web-sdk/api/models';
import { Language } from '../useEmailText';

export const mapPropsToValues = ({
    activeItem,
    mandatory_fields,
    fields,
    getEmailText,
}: AdminOrganizationFormProps): AdminOrganizationFormValues => {
    const branding = activeItem?.branding;
    return {
        name: activeItem?.name || '',
        slug: activeItem?.slug || '',
        description: activeItem?.description || '',
        features: activeItem?.features || [],
        appointment_email_subscriptions: activeItem?.appointment_email_subscriptions.join(',') || '',
        branding: {
            logo: branding?.logo?.id,
            accent_color: branding?.accent_color || theme.accent.toUpperCase(),
        },
        no_slots_behaviour: mapPropsValuesForNoSlots({ activeItem }),
        supported_languages: activeItem?.supported_languages || ['en'],
        operating_countries: activeItem?.operating_countries || [],
        fields: fields,
        mandatory_fields: mandatory_fields,
        address_field_mandatory: activeItem?.address_field_mandatory || false,
        address_field_visibility: activeItem?.address_field_visibility || false,
        insurance_number_field_mandatory: activeItem?.insurance_number_field_mandatory || false,
        insurance_number_field_visibility: activeItem?.insurance_number_field_visibility || false,
        insurance_type_field_mandatory: activeItem?.insurance_type_field_mandatory || false,
        insurance_type_field_visibility: activeItem?.insurance_type_field_visibility || false,
        phone_number_field_mandatory: activeItem?.phone_number_field_mandatory || false,
        phone_number_field_visibility: activeItem?.phone_number_field_visibility || false,
        email_text_pl: activeItem?.email_text?.find((et) => et.language === Language.pl)?.content || '',
        email_text_en: activeItem?.email_text?.find((et) => et.language === Language.en)?.content || '',
        email_text_de: activeItem?.email_text?.find((et) => et.language === Language.de)?.content || '',
        email_text: [],
        getEmailText: getEmailText,
    };
};

export const validationSchema = ({ t }: AdminOrganizationFormProps) =>
    Yup.object().shape({
        name: Yup.string()
            .matchesName(t('organizationProfileForm:warning_organization_name'))
            .required(t('organizationProfileForm:warning_organization_name')),
        slug: Yup.string()
            .matchesSlug(t('organizationProfileForm:warning_organization_slug'))
            .required(t('organizationProfileForm:warning_organization_slug')),
        description: Yup.string().max(500),
        branding: Yup.object().shape({
            logo: Yup.number(),
            accent_color: Yup.string().matchesHexColor(t('organizationProfileForm:warning_accent_color')),
        }),
        supported_languages: Yup.string().min(1, t('form:error.at_least_one_option')),
        operating_countries: Yup.string().min(1, t('form:error.at_least_one_option')),
    });

export const handleSubmit = (
    values: AdminOrganizationFormValues,
    { props }: FormikBag<AdminOrganizationFormProps, AdminOrganizationFormValues>,
) => {
    let logo;
    if (values.branding.logo !== props.activeItem?.branding?.logo?.id) {
        // Only include logo if it was changed, otherwise, if it was created by another user, attachment ownership error triggers
        logo = values.branding.logo;
    } else if (props.activeItem == null) {
        logo = null;
    }

    const requestData: AdminOrganizationFormData = {
        name: values.name,
        slug: values.slug,
        description: values.description,
        features: values.features,
        appointment_email_subscriptions: values.appointment_email_subscriptions.split(',').filter(Boolean),
        branding: {
            logo: logo,
            accent_color: values.branding.accent_color,
        },
        no_slots_behaviour: parseFormToNoSlotsBehaviour(values.no_slots_behaviour),
        supported_languages: values.supported_languages,
        operating_countries: values.operating_countries,
        address_field_mandatory: values.mandatory_fields.includes('address'),
        address_field_visibility: values.fields.includes('address') || values.mandatory_fields.includes('address'),
        insurance_number_field_mandatory: values.mandatory_fields.includes('insurance_number'),
        insurance_number_field_visibility:
            values.fields.includes('insurance_number') || values.mandatory_fields.includes('insurance_number'),
        insurance_type_field_mandatory: values.mandatory_fields.includes('insurance_status'),
        insurance_type_field_visibility:
            values.fields.includes('insurance_status') || values.mandatory_fields.includes('insurance_status'),
        phone_number_field_mandatory: values.mandatory_fields.includes('phone'),
        phone_number_field_visibility: values.fields.includes('phone') || values.mandatory_fields.includes('phone'),
        email_text: values.getEmailText(values),
    };
    props.onFormSubmit(requestData);
};
