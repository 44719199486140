import React, { useMemo } from 'react';
import { Breadcrumbs } from 'medrefer-web-sdk/web-kit';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import useBrand from 'hooks/useBrand';
import { useTranslation } from 'react-i18next';

export const RouteBreadcrumbs = () => {
    const { getPathBranded } = useBrand();
    const { t } = useTranslation();

    const routeConfig = useMemo(
        () => [
            {
                path: getPathBranded('landing'),
                breadcrumb: t('routeNames:dashboard'),
            },
            {
                path: getPathBranded('manageMembers'),
                breadcrumb: t('routeNames:manageMembers'),
            },
            {
                path: getPathBranded('manageServices'),
                breadcrumb: t('routeNames:manageServices'),
            },
            {
                path: getPathBranded('manageLocations'),
                breadcrumb: t('routeNames:manageLocations'),
            },
            {
                path: getPathBranded('manageSlotsCriteria'),
                breadcrumb: t('routeNames:manageSlotsCriteria'),
            },
            {
                path: getPathBranded('manageServiceGroups'),
                breadcrumb: t('routeNames:manageGroupServices'),
            },
            {
                path: getPathBranded('appointmentsTable'),
                breadcrumb: t('routeNames:appointmentsTable'),
            },
            {
                path: getPathBranded('manageQuestionnaires'),
                breadcrumb: t('routeNames:manageQuestionnaires'),
            },
        ],
        [getPathBranded, t],
    );
    const breadcrumbs = useBreadcrumbs(routeConfig);

    const routeBreadcrumbs = useMemo(() => {
        const breadcrumbsWithoutBrandRoutes = breadcrumbs.slice(2);
        return breadcrumbsWithoutBrandRoutes.map(({ match, breadcrumb }, index) => {
            return {
                label: breadcrumb,
                path: match.url,
                disabled: index === breadcrumbsWithoutBrandRoutes.length - 1,
            };
        });
    }, [breadcrumbs]);

    return <Breadcrumbs breadcrumbs={routeBreadcrumbs} />;
};
