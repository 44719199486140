import React, { FC } from 'react';
import { CheckBox, CheckBoxLabel, ErrorMessage, FormGroup } from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { SlotBookingFormData } from 'components/PublicSearch/SlotBooking/SlotBooking.types';
import { PrivacyPolicyNote } from 'components/PrivacyPolicy';
import { useSelector } from 'react-redux';
import { getBrand } from '../../../../features/auth/selectors';
import {
    EOptionalFields,
    useOptionalFields,
} from '../../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';

interface AgreementsProps {
    baseProps: FormikProps<SlotBookingFormData>;
    requestConfirmation: (data: SlotBookingFormData) => Promise<void>;
}

export const Agreements: FC<AgreementsProps> = ({ baseProps, requestConfirmation }: AgreementsProps) => {
    const { t } = useTranslation();
    const organization = useSelector(getBrand);
    const { isVisible } = useOptionalFields(organization);
    return (
        <>
            <FormGroup>
                {/*<OptionalField
                    fieldName={'phone'}
                    fields={baseProps.values.fields}
                    mandatory_fields={baseProps.values.mandatory_fields}
                    fieldLabel={t('newAppointmentForm:checkbox_text1')}
                    errorMessage={baseProps.errors.sms_agreement}
                    isError={!!(baseProps.errors.sms_agreement && baseProps.touched.sms_agreement)}
                    colClassName={'d-flex align-items-start'}
                    rowClassName={''}
                    isCheckMark={true}
                >
                    <CheckBox
                        id="sms_agreement"
                        checked={baseProps.values.sms_agreement}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            baseProps.handleChange(e);
                            requestConfirmation({
                                ...baseProps.values,
                                sms_agreement: !baseProps.values.sms_agreement,
                            });
                        }}
                        className="mr-4"
                    />
                </OptionalField>*/}
                {isVisible(EOptionalFields.phone) && (
                    <Row>
                        <Col className="d-flex align-items-start" xs={12}>
                            <CheckBox
                                id="sms_agreement"
                                checked={baseProps.values.sms_agreement}
                                onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                    baseProps.handleChange(e);
                                    requestConfirmation({
                                        ...baseProps.values,
                                        sms_agreement: !baseProps.values.sms_agreement,
                                    });
                                }}
                                className="mr-4"
                            />
                            <CheckBoxLabel htmlFor="sms_agreement" className={'ml-2'}>
                                {t('newAppointmentForm:checkbox_text1')}
                            </CheckBoxLabel>
                        </Col>
                    </Row>
                )}
                <Row>
                    <Col className="d-flex align-items-start" xs={12}>
                        <CheckBox
                            id="email_agreement"
                            checked={baseProps.values.email_agreement}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                baseProps.handleChange(e);
                                requestConfirmation({
                                    ...baseProps.values,
                                    email_agreement: !baseProps.values.email_agreement,
                                });
                            }}
                            className="mr-4"
                        />
                        <CheckBoxLabel htmlFor="email_agreement" className={'ml-2'}>
                            {t('newAppointmentForm:checkbox_text2')}
                        </CheckBoxLabel>
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex align-items-start" xs={12}>
                        <CheckBox
                            isError={baseProps.touched.terms_agreement && baseProps.errors.terms_agreement}
                            id="terms_agreement"
                            disable
                            checked={baseProps.values.terms_agreement}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                baseProps.handleChange(e);
                                requestConfirmation({
                                    ...baseProps.values,
                                    terms_agreement: !baseProps.values.terms_agreement,
                                });
                            }}
                            className="mr-4"
                        />
                        <CheckBoxLabel htmlFor="terms_agreement" isRequired className={'ml-2'}>
                            <PrivacyPolicyNote />
                        </CheckBoxLabel>
                    </Col>
                </Row>
                {baseProps.errors.terms_agreement && (
                    <ErrorMessage className="text-left mr-5 pr-5 mt-1">{baseProps.errors.terms_agreement}</ErrorMessage>
                )}
            </FormGroup>
        </>
    );
};
