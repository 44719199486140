import React, { ReactElement, useContext, useState, useMemo } from 'react';
import useBrand from 'hooks/useBrand';
import { Navigation } from 'components/Navigation';
import { useLocation } from 'react-router-dom';
import styled, { ThemeContext } from 'styled-components';
import { useSelector } from 'react-redux';
import { useWindowDimensions } from 'medrefer-web-sdk/web-kit/hooks/useWindowDimensions';
import { getAuthUser } from 'features/auth/selectors';
import { matchPath } from 'react-router';
import { getPath, getPathAdmin } from 'router-paths';
import { UserRole } from 'medrefer-web-sdk/api/models';
import { Footer } from 'components/Footer';
import { inIframe } from 'utils/inIframe';

export interface Props {
    children: ReactElement;
}

export const NavigationProvider = ({ children }: Props) => {
    const authUser = useSelector(getAuthUser);
    const { width } = useWindowDimensions();
    const theme = useContext(ThemeContext);
    const defaultSideMenuState = theme.parsePx(theme.devices.desktop) < document.body.clientWidth;
    const { getPathBranded } = useBrand();
    const location = useLocation();
    const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(defaultSideMenuState);

    const pathsWithoutNavigation = useMemo(() => {
        return [
            getPathBranded('login'),
            getPathBranded('register'),
            getPathBranded('resetPassword'),
            getPathBranded('forgotPassword'),
            getPathBranded('notFound'),
            getPathBranded('acceptInvitation'),
            getPathAdmin('login'),
        ];
    }, [getPathBranded]);

    const pathsWithoutSideMenu = useMemo(() => {
        return [
            getPathBranded('landing'),
            getPathBranded('searchCriteria'),
            getPathBranded('slotBooking', ':slotKey'),
            getPathBranded('searchServiceSlots'),
            getPathBranded('slotBookingSuccess', ':appointmentId'),
            getPathBranded('completeAppointmentSteps', ':appointmentId'),
            getPathBranded('questionnaire', ':appointmentId', ':questionnaireId'),
            getPath('admin'),
            getPathAdmin('settings'),
            getPathAdmin('profile'),
            getPathBranded('appointmentVideoCall', ':appointmentId'),
        ];
    }, [getPathBranded]);

    const pathsWithoutFooter = useMemo(() => {
        return [getPathBranded('appointmentVideoCall', ':appointmentId')];
    }, [getPathBranded]);

    const hideNavigation = pathsWithoutNavigation.find((path) =>
        matchPath(location.pathname, {
            path: path,
            exact: true,
            strict: false,
        }),
    );

    const isSideMenuVisible = !!authUser
        ? !pathsWithoutSideMenu.find((path) =>
              matchPath(location.pathname, {
                  path: path,
                  exact: true,
                  strict: false,
              }),
          ) || width < theme.parsePx(theme.devices.tablet)
        : false;

    const isFooterVisible =
        (!!!authUser || authUser.role === UserRole.patient) &&
        !pathsWithoutFooter.find((path) =>
            matchPath(location.pathname, {
                path: path,
                exact: true,
                strict: false,
            }),
        );

    if (hideNavigation || inIframe()) {
        return children;
    }

    return (
        <>
            <Navigation
                isSideMenuOpen={isSideMenuOpen}
                setIsSideMenuOpen={setIsSideMenuOpen}
                showSideMenu={isSideMenuVisible}
            />
            <ContentWrapper isSideMenuOpen={isSideMenuOpen} isSideMenuVisible={isSideMenuVisible}>
                {children}
            </ContentWrapper>
            {isFooterVisible && <Footer isSideMenuOpen={isSideMenuOpen} isSideMenuVisible={isSideMenuVisible} />}
        </>
    );
};

export const ContentWrapper = styled.div<{ isSideMenuOpen: boolean; isSideMenuVisible: boolean }>`
    transition: 0.2s ease-in-out;
    margin-left: ${({ isSideMenuOpen, isSideMenuVisible }) =>
        !isSideMenuVisible ? 0 : isSideMenuOpen ? '240px' : '80px'};
    padding-top: ${({ theme }) => theme.navbarHeight.desktop};
    min-height: calc(100vh - ${({ theme }) => theme.footerHeight});

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        margin-left: 0;
        padding-top: ${({ theme }) => theme.navbarHeight.mobile};
    }
`;
