import { useCallback } from 'react';
import { useCallbackRef } from 'use-callback-ref';
import { addDays, floorDate } from 'utils/dates';
import Calendar from './TUICalendar';
import { CalendarType, ClickScheduleEvent, CreateScheduleEvent, UpdateScheduleEvent } from './CalendarView.types';
import { CalendarState } from './useCalendarState';

interface CalendarControlsProps {
    state: CalendarState;
    onClickSchedule: (e: ClickScheduleEvent) => void;
    onCreateSchedule: (e: CreateScheduleEvent) => void;
    onUpdateSchedule: (e: UpdateScheduleEvent) => void;
}

export const useCalendarControls = ({
    state,
    onClickSchedule,
    onCreateSchedule,
    onUpdateSchedule,
}: CalendarControlsProps) => {
    const syncStateWithTuiCalendar = useCallback(() => {
        const instance = ref.current?.getInstance();
        if (!instance) return;

        const date = instance.getDate().toDate();
        const dateFrom = instance.getDateRangeStart().toDate();
        const dateTo = new Date(addDays(floorDate(instance.getDateRangeEnd().toDate()), 1).getTime() - 1);

        state.syncDate(date);
        state.syncDateRange({ dateFrom, dateTo });
        state.syncCalendarType(instance.getViewName() as CalendarType);
        // eslint-disable-next-line
    }, []);

    const calendarSetup = useCallback(() => {
        const instance = ref.current?.getInstance();
        if (!instance) return;

        instance.changeView(state.calendarType, true);
        instance.setDate(state.date);
        syncStateWithTuiCalendar();
        // eslint-disable-next-line
    }, []);

    // Fired when calendar is created
    const ref = useCallbackRef<Calendar | null>(null, calendarSetup);

    const handleClickSchedule = useCallback(({ schedule, event }) => {
        const calendar = ref.current?.getInstance();
        if (!calendar) return;

        onClickSchedule({
            domRect: event.target.getBoundingClientRect(),
            calendarType: calendar.getViewName() as CalendarType,
            dateRange: {
                dateFrom: schedule.start.toDate(),
                dateTo: schedule.end.toDate(),
            },
            data: schedule.raw,
        });
        // eslint-disable-next-line
    }, []);

    const handleCreateSchedule = useCallback((scheduleData) => {
        const calendar = ref.current?.getInstance();
        if (!calendar) return;

        onCreateSchedule({
            domRect: scheduleData.guide.guideElement?.getBoundingClientRect(),
            calendarType: calendar.getViewName() as CalendarType,
            dateRange: {
                dateFrom: scheduleData.start.toDate(),
                dateTo: scheduleData.end.toDate(),
            },
        });
        // eslint-disable-next-line
    }, []);

    const handleUpdateSchedule = useCallback(({ schedule, changes }) => {
        const calendar = ref.current?.getInstance();
        if (!calendar) return;

        onUpdateSchedule({
            calendarType: calendar.getViewName() as CalendarType,
            dateRange: {
                dateFrom: (changes.start || schedule.start).toDate(),
                dateTo: (changes.end || schedule.end).toDate(),
            },
            data: schedule.raw,
        });
        // eslint-disable-next-line
    }, []);

    const handleDeleteSchedule = useCallback(
        (res) => {
            const { id, calendarId } = res.schedule;
            const calendar = ref.current?.getInstance();
            return calendar?.deleteSchedule(id, calendarId);
        },
        [ref],
    );

    const handleToday = useCallback(() => {
        const calendar = ref.current?.getInstance();
        if (!calendar) return;

        calendar.today();
        syncStateWithTuiCalendar();
    }, [ref, syncStateWithTuiCalendar]);

    const handlePrev = useCallback(() => {
        const calendar = ref.current?.getInstance();
        if (!calendar) return;

        calendar.prev();
        syncStateWithTuiCalendar();
    }, [ref, syncStateWithTuiCalendar]);

    const handleNext = useCallback(() => {
        const calendar = ref.current?.getInstance();
        if (!calendar) return;

        calendar.next();
        syncStateWithTuiCalendar();
    }, [ref, syncStateWithTuiCalendar]);

    const handleDateChange = (date: Date) => {
        const calendar = ref.current?.getInstance();
        if (!calendar) return;

        calendar.setDate(date);
        syncStateWithTuiCalendar();
    };

    const handleCalendarTypeChange = (type: CalendarType) => {
        const calendar = ref.current?.getInstance();
        if (!calendar) return;

        calendar.changeView(type, true);
        syncStateWithTuiCalendar();
    };

    return {
        ref,
        handleClickSchedule,
        handleCreateSchedule,
        handleUpdateSchedule,
        handleDeleteSchedule,
        handleToday,
        handlePrev,
        handleNext,
        handleDateChange,
        handleCalendarTypeChange,
    };
};
