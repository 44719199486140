import React from 'react';
import { Col, Row } from 'react-bootstrap';
import {
    Button,
    FormErrorMessage,
    InformationBubble,
    InformationContainer,
    InformationBubbleTypes,
} from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { SlotBookingFormData, SlotBookingStep } from 'components/PublicSearch/SlotBooking/SlotBooking.types';
import { FormProps } from './BookingForm';
// import ReactMarkdown from 'react-markdown';
import { useDispatch } from 'react-redux';
import { routerActions } from 'connected-react-router';

interface Props {
    baseProps: FormProps & FormikProps<SlotBookingFormData>;
}

export const SubmitAppointmentForm = ({ baseProps }: Props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const onCLickBack = () => {
        dispatch(routerActions.goBack());
    };

    return (
        <Row>
            <Col className="ml-auto mr-auto mb-4" xs={12} md={10}>
                <InformationContainer>
                    {baseProps.otpCodeSent ? (
                        <InformationBubble type={InformationBubbleTypes.success} style={{ marginTop: '40px' }}>
                            {/*<ReactMarkdown*/}
                            {/*    renderers={{*/}
                            {/*        link: (props) => (*/}
                            {/*            <a href={props.href} rel="nofollow noreferrer noopener" target="_blank">*/}
                            {/*                {props.children}*/}
                            {/*            </a>*/}
                            {/*        ),*/}
                            {/*    }}*/}
                            {/*>*/}
                            {t('otp:code_sent') as string}
                            {/*</ReactMarkdown>*/}
                        </InformationBubble>
                    ) : (
                        baseProps.values.step !== SlotBookingStep.confirmation && (
                            <FormErrorMessage apiErrors={baseProps.request.errors} />
                        )
                    )}
                </InformationContainer>
                <div className="d-flex ml-auto mr-auto" style={{ maxWidth: 400 }}>
                    <div className="w-100 mr-3">
                        <Button inverseColors type="button" onClick={onCLickBack}>
                            {t('newAppointmentForm:button_cancel')}
                        </Button>
                    </div>
                    <div className="w-100">
                        <Button isLoading={baseProps.request.isLoading}>
                            {[SlotBookingStep.loginOtpCode, SlotBookingStep.registerOtpCode].includes(
                                baseProps.values.step,
                            ) || baseProps.user
                                ? t('newAppointmentForm:button_message')
                                : t('newAppointmentForm:button_next')}
                        </Button>
                    </div>
                </div>
            </Col>
        </Row>
    );
};
