import React, { FC } from 'react';
import ProtectedRoute, { ProtectedRouteInheritedProps } from 'components/shared/routes/ProtectedRoute';
import { UserRole } from 'medrefer-web-sdk/api/models';
import { RouteRole } from 'components/shared/routes/RouteRole';
import { getPathAdmin } from 'router-paths';

export const GuestRoute: FC<ProtectedRouteInheritedProps> = (props) => {
    return <ProtectedRoute allowRoles={[RouteRole.guest]} {...props} />;
};

export const AuthenticatedRoute: FC<ProtectedRouteInheritedProps> = (props) => {
    return <ProtectedRoute denyRoles={[RouteRole.guest]} {...props} />;
};

export const PatientRoute: FC<ProtectedRouteInheritedProps> = (props) => {
    return <ProtectedRoute allowRoles={[UserRole.patient]} {...props} />;
};

export const PatientGuestRoute: FC<ProtectedRouteInheritedProps> = (props) => {
    return <ProtectedRoute allowRoles={[UserRole.patient, RouteRole.guest]} {...props} />;
};

export const MemberRoute: FC<ProtectedRouteInheritedProps> = (props) => {
    return <ProtectedRoute allowRoles={[UserRole.organizationMember]} {...props} />;
};

export const DashboardRoute: FC<ProtectedRouteInheritedProps> = (props) => {
    return (
        <ProtectedRoute
            allowRoles={[UserRole.organization, UserRole.admin, UserRole.organizationMember, UserRole.cooperator]}
            {...props}
        />
    );
};

export const AdminRoute: FC<ProtectedRouteInheritedProps> = (props) => {
    return <ProtectedRoute allowRoles={[UserRole.organization, UserRole.admin]} {...props} />;
};

export const SuperAdminRoute: FC<ProtectedRouteInheritedProps> = (props) => {
    return <ProtectedRoute allowRoles={[UserRole.admin]} {...props} redirectTo={getPathAdmin('login')} />;
};
