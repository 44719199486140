import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { pageStyles } from '../Auth.styled';
import { AuthPage } from 'components/Auth/AuthPage';
import { InformationBubble, InformationBubbleTypes } from 'medrefer-web-sdk/web-kit';
import { AcceptInvitationForm } from './AcceptInvitationForm';
import useBrand from 'hooks/useBrand';
import { useAcceptInvitation } from 'components/Auth/AcceptInvitation/useAcceptInvitation';
import { useOptionalFields } from '../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';
import { useSelector } from 'react-redux';
import { getBrand } from '../../../features/auth/selectors';

export const AcceptInvitation: FC = () => {
    const { t } = useTranslation();
    const { getPathBranded } = useBrand();
    const organization = useSelector(getBrand);
    const { getFields, getMandatoryFields } = useOptionalFields(organization);
    const { invitee, invitationToken, isTokenValid, request, onSubmit } = useAcceptInvitation();

    return (
        <AuthPage pageTitle={t('acceptInvitationPage:h1')}>
            {invitee && (
                <AcceptInvitationForm
                    fields={getFields()}
                    mandatory_fields={getMandatoryFields()}
                    invitee={invitee}
                    invitationToken={invitationToken}
                    onSubmit={onSubmit}
                    request={request}
                />
            )}
            {!isTokenValid && (
                <InformationBubble type={InformationBubbleTypes.warning}>
                    {t('acceptInvitationPage:link_invalid')}
                </InformationBubble>
            )}
            <div className="mt-5">
                <span style={pageStyles.message}>{t('forgotPasswordPage:message')}</span>
                <Link to={getPathBranded('login')} style={pageStyles.message_cta}>
                    {t('forgotPasswordPage:message_cta')}
                </Link>
            </div>
        </AuthPage>
    );
};
