import { Column } from 'react-table';
import { TableLocationData } from './ManageLocations.hook';

export const columns: (t: (key: string) => string) => Column<TableLocationData>[] = (t) => [
    {
        Header: t('adminPanel:table.columns.id'),
        accessor: 'id',
        sortParam: 'id',
        width: 80,
    },
    {
        Header: t('adminPanel:table.columns.location_name'),
        accessor: 'name',
        sortParam: 'name',
    },
    {
        Header: t('adminPanel:table.columns.location_descritpion'),
        accessor: 'description',
        sortParam: 'description',
    },
    {
        Header: t('adminPanel:table.columns.address'),
        accessor: 'address',
        sortParam: 'address__text',
    },
];
