import React, { useState } from 'react';
import { UnderlineH2 as Title, RedButton, PrimaryButton, ConfirmModalBody } from '../../../../medrefer-web-sdk/web-kit';
import { Modal, ModalState } from '../../../../medrefer-web-sdk/web-kit';
import { ButtonsContainer } from '../EditAppointment/EditAppointment.styled';
import { useTranslation } from 'react-i18next';

type Props = {
    state: ModalState;
    title?: string;
    message?: string;
    makePublicButton: string;
    onMakePublic: () => void;
    makePrivateButton: string;
    onMakePrivate: () => void;
    makeInternalButton: string;
    onMakeInternal: () => void;
    isConfirmLoading?: boolean;
    confirmButtonTestId?: string;
    editModalAppointmentState: ModalState;
};

type ModalPropKeys = 'state';

export const EditSlotModal = ({ state, ...rest }: Props) => {
    return <Modal state={state} isCentered={true} body={<EditSlotModalBody {...rest} />} />;
};

export const EditSlotModalBody = ({
    title,
    makePublicButton,
    onMakePublic,
    makePrivateButton,
    onMakePrivate,
    makeInternalButton,
    onMakeInternal,
    confirmButtonTestId,
    editModalAppointmentState,
}: Omit<Props, ModalPropKeys>) => {
    const { t } = useTranslation();
    const [showConfirmMakePublic, setShowConfirmMakePublic] = useState<boolean>(false);
    const [showConfirmMakePrivate, setShowConfirmMakePrivate] = useState<boolean>(false);
    const [showConfirmMakeInternal, setShowConfirmMakeInternal] = useState<boolean>(false);
    return (
        <>
            {!showConfirmMakePublic && !showConfirmMakeInternal && !showConfirmMakePrivate && (
                <div>
                    <Title>{title}</Title>
                    <ButtonsContainer>
                        <RedButton
                            onClick={() => {
                                setShowConfirmMakePublic(true);
                            }}
                            data-testid={confirmButtonTestId}
                            style={{ minWidth: '150px' }}
                        >
                            {makePublicButton}
                        </RedButton>
                        <PrimaryButton
                            onClick={() => {
                                setShowConfirmMakeInternal(true);
                            }}
                            data-testid={confirmButtonTestId}
                            style={{ minWidth: '150px' }}
                            inverseColors
                        >
                            {makeInternalButton}
                        </PrimaryButton>
                        <PrimaryButton
                            onClick={() => {
                                setShowConfirmMakePrivate(true);
                            }}
                            data-testid={confirmButtonTestId}
                            style={{ minWidth: '150px' }}
                        >
                            {makePrivateButton}
                        </PrimaryButton>
                    </ButtonsContainer>
                </div>
            )}
            {showConfirmMakePublic && (
                <ConfirmModalBody
                    title={t('calendar:calendarTable:changeAccessConfirmModalTitle')}
                    message={t('calendar:calendarTable:confirmMakePublicMessage')}
                    confirmButton={t('calendar:calendarTable:confirmChangeAccessButton')}
                    declineButton={t('cancelAppointmentModalBody:decline_btn_message')}
                    onConfirm={() => {
                        setShowConfirmMakePublic(false);
                        editModalAppointmentState.toggleModal();
                        onMakePublic();
                    }}
                    onDecline={editModalAppointmentState.toggleModal}
                />
            )}
            {showConfirmMakePrivate && (
                <ConfirmModalBody
                    title={t('calendar:calendarTable:changeAccessConfirmModalTitle')}
                    message={t('calendar:calendarTable:confirmMakePrivateMessage')}
                    confirmButton={t('calendar:calendarTable:confirmChangeAccessButton')}
                    declineButton={t('cancelAppointmentModalBody:decline_btn_message')}
                    onConfirm={() => {
                        setShowConfirmMakePrivate(false);
                        editModalAppointmentState.toggleModal();
                        onMakePrivate();
                    }}
                    onDecline={editModalAppointmentState.toggleModal}
                />
            )}
            {showConfirmMakeInternal && (
                <ConfirmModalBody
                    title={t('calendar:calendarTable:changeAccessConfirmModalTitle')}
                    message={t('calendar:calendarTable:confirmMakeInternalMessage')}
                    confirmButton={t('calendar:calendarTable:confirmChangeAccessButton')}
                    declineButton={t('cancelAppointmentModalBody:decline_btn_message')}
                    onConfirm={() => {
                        setShowConfirmMakeInternal(false);
                        editModalAppointmentState.toggleModal();
                        onMakeInternal();
                    }}
                    onDecline={editModalAppointmentState.toggleModal}
                />
            )}
        </>
    );
};
