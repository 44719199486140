import styled from 'styled-components';

export const H1 = styled.h1`
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 36px;
    color: ${({ theme }) => theme.black};
`;

export const H2 = styled.h2`
    font-weight: 800;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1.75px;
    color: ${({ theme }) => theme.black};
`;
