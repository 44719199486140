import * as Yup from 'yup';
import { EditMemberProfileData, UserProfile } from 'medrefer-web-sdk/api/models';
import { WithTranslation } from 'react-i18next';
import { ApiRequest } from 'hooks/useApiRequest';

export interface FormProps extends WithTranslation {
    authUser: UserProfile;
    updateMemberProfile: (data: EditMemberProfileData) => void;
    request: ApiRequest;
}

export const mapPropsToValues = ({ authUser }: FormProps): EditMemberProfileData => {
    return {
        description: authUser.practitioner?.description || '',
        image: undefined,
    };
};

export const validationSchema = () =>
    Yup.object().shape({
        description: Yup.string().max(500),
    });
