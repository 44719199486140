import React, { ReactElement } from 'react';
import { AppointmentDetailsContainer } from './AppointmentDetails.styled';

import AppointmentFiles from './AppointmentFiles';
import AppointmentOverview from './AppointmentOverview';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import AppointmentQuestionnaireResponses from './AppointmentQuestionnaireResponses';
import { AppointmentTab } from 'components/Dashboard/Appointment/AppointmentTabs';
import { AppointmentVideoCall } from './AppointmentVideoCall';

interface Props {
    activeTab: AppointmentTab;
    activeListItem: AppointmentModel;
}

export const AppointmentDetails = ({ activeTab, activeListItem }: Props): ReactElement => {
    return (
        <AppointmentDetailsContainer style={activeTab === AppointmentTab.Questionnaires ? { padding: 0 } : {}}>
            {activeTab === AppointmentTab.Overview && <AppointmentOverview activeListItem={activeListItem} />}
            {activeTab === AppointmentTab.Attachments && <AppointmentFiles attachments={activeListItem.attachments} />}
            {activeTab === AppointmentTab.VideoCall && <AppointmentVideoCall activeListItem={activeListItem} />}
            {activeTab === AppointmentTab.Questionnaires && (
                <AppointmentQuestionnaireResponses appointment={activeListItem} />
            )}
        </AppointmentDetailsContainer>
    );
};
