import React from 'react';
import { FormikProps, Field } from 'formik';
import { ServiceGroupManagementData } from 'medrefer-web-sdk/api/models';
import { FormGroup, Input, Label, FormAsyncSelect, TextArea } from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ServiceGroupFormProps } from './ServiceGroupForm.types';
import useOrganizationServiceOptions from 'hooks/options/useOrganizationServiceOptions';

interface ServicesDataProps {
    baseProps: ServiceGroupFormProps & FormikProps<ServiceGroupManagementData>;
}

export const ServiceGroupData = ({ baseProps }: ServicesDataProps) => {
    const { t } = useTranslation();

    const { activeItem, values, handleChange, handleBlur, touched, errors } = baseProps;
    const { loadOrganizationServiceOptions, toOrganizationServiceOption } = useOrganizationServiceOptions();

    return (
        <>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label isRequired>
                            {t('adminPanel:manageServiceGroups.serviceGroupForm.service_group_name_label')}
                        </Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Input
                            isError={touched.name && !!errors.name}
                            id="name"
                            name="name"
                            value={values.name}
                            placeholder={t('adminPanel:manageServiceGroups.serviceGroupForm.service_group_name_label')}
                            type="text"
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label isRequired>{t('adminPanel:manageServices.servicesForm.comment_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <TextArea
                            isError={false}
                            id="comment"
                            name="comment"
                            value={baseProps.values.comment}
                            placeholder="Comment"
                            onChange={baseProps.handleChange}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label isRequired>{t('adminPanel:manageServiceGroups.serviceGroupForm.services_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Field
                            name="services"
                            className="services-multiselect"
                            loadOptions={loadOrganizationServiceOptions}
                            initialOptions={activeItem && activeItem.services.map(toOrganizationServiceOption)}
                            component={FormAsyncSelect}
                            placeholder={t('adminPanel:manageServiceGroups.serviceGroupForm.services_placeholder')}
                            isMulti={true}
                            isError={touched.services && !!errors.services}
                            onBlur={handleBlur}
                        />
                    </Col>
                </Row>
            </FormGroup>
        </>
    );
};
