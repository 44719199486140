import React, { FC } from 'react';
import Types from 'MyTypes';
import { Route, RouteProps } from 'react-router-dom';
import { UserRouteRole, routeRoleSelector } from './RouteRole';
import RedirectHome from './RedirectHome';
import { connect } from 'react-redux';

export interface ProtectedRouteProps extends RouteProps {
    allowRoles?: UserRouteRole[];
    denyRoles?: UserRouteRole[];
    redirectTo?: string;
    routeRole: UserRouteRole;
}

export type ProtectedRouteInheritedProps = Omit<ProtectedRouteProps, 'allowRoles' | 'denyRoles' | 'routeRole'>;

const ProtectedRoute: FC<ProtectedRouteProps> = ({ routeRole, allowRoles, denyRoles, redirectTo, ...rest }) => {
    const isDenied = denyRoles ? denyRoles.includes(routeRole) : false; // don't deny if deny roles are not provided
    const isAllowed = allowRoles ? allowRoles.includes(routeRole) : true; // allow if allow roles are not provided

    if (isDenied || !isAllowed) {
        return <RedirectHome redirectTo={redirectTo} />;
    }

    return <Route {...rest} />;
};

// routeRole must only be evaluated with `connect` and not useSelector
// because useSelector delays value change and user can access route
// that they never have access to.
const mapStateToProps = (state: Types.RootState) => ({
    routeRole: routeRoleSelector(state),
});

export default connect(mapStateToProps)(ProtectedRoute);
