import React from 'react';
import { Separator, UnderlineH2 as Title } from 'medrefer-web-sdk/web-kit';
import { PriceLabel, ServiceComment } from 'components/shared/ServiceSlots/ServiceSlots.styled';
import { Col, Row } from 'react-bootstrap';
import { AppointmentTime } from 'components/PatientPanel/MyAppointments/AppointmentsList/AppointmentList.styled';
import { FormValue } from 'components/shared/FormValue';
import { ManagingUser } from 'components/shared/ServiceSlots/ManagingUser';
import { ServiceLocation } from 'components/shared/ServiceSlots/ServiceLocation';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useBrand from 'hooks/useBrand';
import { AppointmentModel, HealthcareServiceSlots } from 'medrefer-web-sdk/api/models';

interface Props {
    healthcareService: HealthcareServiceSlots;
    appointment?: AppointmentModel;
}

export const ServiceSlotsHeader = ({ healthcareService, appointment }: Props) => {
    const { location, managing_user: managingUser } = healthcareService;
    const dateMoment = moment(appointment?.date_from);
    const { t } = useTranslation();
    const { featureEnabled } = useBrand();

    return (
        <>
            <Title className="w-100">
                <div className="w-100 d-flex justify-content-between align-items-center">
                    {healthcareService.name}
                    {featureEnabled('appointmentPricing') && healthcareService.price && (
                        <PriceLabel>{healthcareService.price.amount_formatted}</PriceLabel>
                    )}
                </div>
                {healthcareService.comment && (
                    <div className="mt-2">
                        <ServiceComment className="px-2 py-3">{healthcareService.comment}</ServiceComment>
                    </div>
                )}
            </Title>
            {managingUser && (
                <>
                    <Row>
                        <Col md={8} className="d-flex flex-column position-relative">
                            <ManagingUser user={managingUser} />
                        </Col>
                        {appointment && (
                            <AppointmentTime md={4}>
                                <span>{dateMoment.format('DD MMMM YYYY')}</span>
                                <span>{dateMoment.format('HH:mm')}</span>
                                <span>{dateMoment.format('dddd')}</span>
                            </AppointmentTime>
                        )}
                    </Row>
                    <Separator />
                </>
            )}
            {appointment && (
                <>
                    <Row className="mb-0 mr-0 ml-0">
                        <Col>
                            <FormValue
                                title={t('newAppointmentForm:title_reason_for_appointment')}
                                value={appointment.reason.name}
                            />
                            <FormValue title={t('newAppointmentForm:title_message')} value={appointment.comment} />
                        </Col>
                        <Col>
                            {!!appointment.attachments.length && (
                                <>
                                    <FormValue
                                        title={t('newAppointmentForm:title_attachments')}
                                        value={appointment.attachments.map((file) => file.filename)}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>
                    <Separator />
                </>
            )}
            {location && <ServiceLocation location={location} />}
        </>
    );
};
