import styled, { keyframes } from 'styled-components';

const spin360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }`;

export enum SpinnerColorVariant {
    red = 'red300',
    accent = 'accent',
}

interface ButtonSpinnerProps {
    spinnerColorVariant: SpinnerColorVariant;
}

export const ButtonSpinner = styled.div<ButtonSpinnerProps>`
    margin: 0 auto;
    text-indent: -9999em;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: ${({ theme }) => theme.white};
    background: linear-gradient(to right, ${({ theme }) => theme.white} 10%, rgba(255, 255, 255, 0) 42%);
    position: relative;
    transform: translateZ(0);
    &:before {
        width: 50%;
        height: 50%;
        background: ${({ theme }) => theme.white};
        border-radius: 100% 0 0 0;
        position: absolute;
        top: 0;
        left: 0;
        content: '';
    }
    &:after {
        background: ${({ theme, spinnerColorVariant }) => theme[spinnerColorVariant]};
        width: 75%;
        height: 75%;
        border-radius: 50%;
        content: '';
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
    animation: 1.5s linear infinite ${spin360};
`;
