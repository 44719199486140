import React, { FC, ReactNode } from 'react';
import {
    TableDisplayContainer,
    LoaderContainer,
    Table,
    TableBody,
    TableHead,
    ActionButtonsContainer,
    SortArrows,
    NoItemsIndicator,
} from './TableDisplay.styled';
import { ActivityIndicator } from 'medrefer-web-sdk/web-kit/components/ActivityIndicator';
import { HeaderGroup, Row, TableBodyProps, TableProps } from 'react-table';
import { IconButtonAlt, Modal } from 'medrefer-web-sdk/web-kit';
import { ReactSVG } from 'react-svg';
import EditIcon from 'assets/EditIcon.svg';
import TrashIcon from 'medrefer-web-sdk/web-kit/assets/icons/TrashIcon.png';
import { ModalState } from '../../Modal/Modal';
import { getSortOrder } from '../DataTable.helpers';

interface TableDisplayProps {
    actionColumnTitle: string;
    columnCount: number;
    getTableProps: () => TableProps;
    getTableBodyProps: () => TableBodyProps;
    // eslint-disable-next-line @typescript-eslint/ban-types
    headerGroups: HeaderGroup<object>[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    rows: Row<object>[];
    // eslint-disable-next-line @typescript-eslint/ban-types
    prepareRow: (row: Row<object>) => void;
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    setActiveItem: (item: object) => void;
    noItemsMessage: string;
    editItemForm?: ReactNode;
    editModalState?: ModalState;
    deleteItemForm?: ReactNode;
    deleteModalState?: ModalState;
    ordering?: string[];
    sortHandler?: (sortParam: string) => void;
    isResizable?: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    getExtraActions?: (row: Row<object>) => undefined | Array<ReactNode>;
}

export const TableDisplay: FC<TableDisplayProps> = ({
    actionColumnTitle,
    columnCount,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    isLoading,
    setActiveItem,
    editItemForm,
    editModalState,
    deleteItemForm,
    deleteModalState,
    ordering,
    sortHandler,
    isResizable,
    getExtraActions,
    noItemsMessage,
}: TableDisplayProps) => {
    return (
        <TableDisplayContainer>
            <Table {...getTableProps()}>
                <TableHead columnCount={columnCount + 1}>
                    {
                        // Loop over the header rows
                        headerGroups.map((headerGroup) => (
                            // Apply the header row props
                            // eslint-disable-next-line react/jsx-key
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {
                                    // Loop over the headers in each row
                                    headerGroup.headers.map((column) => (
                                        // Apply the header cell props
                                        // eslint-disable-next-line react/jsx-key
                                        <th
                                            {...column.getHeaderProps()}
                                            onClick={() => {
                                                if (!column.sortParam || !sortHandler) return;
                                                sortHandler(column.sortParam);
                                            }}
                                            className={column.sortParam ? 'sortable' : ''}
                                        >
                                            {
                                                // Render the header
                                                column.render('Header')
                                            }
                                            {column.sortParam && (
                                                <SortArrows
                                                    className="sort-arrows"
                                                    sortOrder={getSortOrder(ordering || [], column.sortParam)}
                                                />
                                            )}
                                            {column.canResize && isResizable && (
                                                <div
                                                    {...column.getResizerProps()}
                                                    className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                                                />
                                            )}
                                        </th>
                                    ))
                                }
                                <th>{actionColumnTitle}</th>
                            </tr>
                        ))
                    }
                </TableHead>

                <TableBody columnCount={columnCount + 1} {...getTableBodyProps()}>
                    {isLoading && (
                        <LoaderContainer>
                            <ActivityIndicator />
                        </LoaderContainer>
                    )}
                    {!isLoading && rows.length === 0 && <NoItemsIndicator>{noItemsMessage}</NoItemsIndicator>}
                    {!isLoading &&
                        rows.length > 0 &&
                        // Loop over the table rows
                        rows.map((row) => {
                            // Prepare the row for display
                            prepareRow(row);
                            return (
                                // Apply the row props
                                // eslint-disable-next-line react/jsx-key
                                <tr {...row.getRowProps()}>
                                    {
                                        // Loop over the rows cells
                                        row.cells.map((cell) => {
                                            // Apply the cell props
                                            return (
                                                // eslint-disable-next-line react/jsx-key
                                                <td {...cell.getCellProps()}>
                                                    {
                                                        // Render the cell contents
                                                        cell.render('Cell')
                                                    }
                                                </td>
                                            );
                                        })
                                    }
                                    <td>
                                        <ActionButtonsContainer>
                                            {getExtraActions && getExtraActions(row)}
                                            {editItemForm && editModalState && (
                                                <IconButtonAlt
                                                    onClick={() => {
                                                        setActiveItem(row.original);
                                                        editModalState.toggleModal();
                                                    }}
                                                >
                                                    <ReactSVG src={EditIcon} />
                                                </IconButtonAlt>
                                            )}
                                            {deleteItemForm && deleteModalState && (
                                                <IconButtonAlt
                                                    onClick={() => {
                                                        setActiveItem(row.original);
                                                        deleteModalState.toggleModal();
                                                    }}
                                                >
                                                    <img src={TrashIcon} alt="Button delete icon" />
                                                </IconButtonAlt>
                                            )}
                                        </ActionButtonsContainer>
                                    </td>
                                </tr>
                            );
                        })}
                </TableBody>
            </Table>
            {editItemForm && editModalState && <Modal isCentered={false} state={editModalState} body={editItemForm} />}
            {deleteItemForm && deleteModalState && (
                <Modal isCentered={false} state={deleteModalState} body={deleteItemForm} />
            )}
        </TableDisplayContainer>
    );
};
