import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { IconButton } from 'medrefer-web-sdk/web-kit';
import { ReactSVG } from 'react-svg';
import EditIcon from 'assets/EditIcon.svg';

const EditFieldWrapper = styled.div`
    position: relative;
`;

const FieldIconButton = styled(IconButton)`
    background: ${({ theme }) => theme.white};
    position: absolute;
    bottom: 0;
    right: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`;

type Props = {
    onClick?: () => void;
    isVisible: boolean;
    children: ReactNode;
};

export const EditFieldButton = ({ onClick, isVisible, children }: Props) => {
    return (
        <EditFieldWrapper>
            {children}
            {isVisible && (
                <FieldIconButton onClick={onClick} type="button">
                    <ReactSVG src={EditIcon} />
                </FieldIconButton>
            )}
        </EditFieldWrapper>
    );
};
