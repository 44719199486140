import React from 'react';
import { Label, Option, Select, SingleValue, MultiValue } from 'medrefer-web-sdk/web-kit';
import { SlotCriteria } from 'medrefer-web-sdk/api/models';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

type Props = {
    criteria: SlotCriteria;
    onCriteriaChange: (criteria: SlotCriteria, value: string[]) => void;
    isError: boolean;
};

export const CriteriaSelect = ({ criteria, onCriteriaChange, isError }: Props) => {
    const { t } = useTranslation();
    const options = criteria.values.map((value) => ({
        value: value.id?.toString() || '',
        label: value.name || '',
    }));

    const onChange = (option: SingleValue<Option> | MultiValue<Option>) => {
        if (option == null) {
            onCriteriaChange(criteria, []);
        } else if (Array.isArray(option)) {
            onCriteriaChange(
                criteria,
                option.map((o) => o.value),
            );
        } else {
            onCriteriaChange(criteria, [(option as Option).value]);
        }
    };

    return (
        <Row className="mt-4">
            <Col>
                <Label labelTop isRequired={criteria.required}>
                    {criteria.name}
                </Label>
                <Select
                    placeholder={t('publicSearch:criteria_any')}
                    options={options}
                    onChange={onChange}
                    isMulti={criteria.multiple_selection}
                    isClearable
                    isError={isError}
                />
            </Col>
        </Row>
    );
};
