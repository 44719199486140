import React from 'react';
import { AdminPanelContainer, FormActivityIndicatorContainer } from 'components/AdminPanel/AdminPanel.styled';
import { RouteBreadcrumbs } from 'components/shared/RouteBreadcrumbs';
import { ActivityIndicator, ConfirmModalBody } from 'medrefer-web-sdk/web-kit';
import { DataTable } from 'components/shared/DataTable';
import { useTranslation } from 'react-i18next';
import { columns } from './ManageSlotsCriteria.consts';
import { useManageSlotsCriteria } from './hooks/useManageSlotsCriteria';
import { SlotsCriteriaForm } from './SlotsCriteriaForm';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';

export const ManageSlotsCriteria = () => {
    const { t } = useTranslation();
    const {
        createSlotsCriteria,
        updateSlotsCriteria,
        deleteSlotsCriteria,
        request,
        activeItem,
        fetchActiveItem,
        deleteModalState,
        ...rest
    } = useManageSlotsCriteria();

    return (
        <AdminPanelContainer>
            <DataTable
                title={t('adminPanel:manageSlotsCriteria.title')}
                actionColumnTitle={t('adminPanel:table.columns.actions')}
                noItemsMessage={t('adminPanel:table.no_items_message')}
                columns={columns(t)}
                addItemForm={
                    <SlotsCriteriaForm type={ModalType.add} request={request} handleSubmit={createSlotsCriteria} />
                }
                editItemForm={
                    activeItem ? (
                        <SlotsCriteriaForm
                            activeItem={activeItem}
                            type={ModalType.edit}
                            request={request}
                            handleSubmit={updateSlotsCriteria}
                        />
                    ) : (
                        <FormActivityIndicatorContainer>
                            <ActivityIndicator />
                        </FormActivityIndicatorContainer>
                    )
                }
                deleteModalState={deleteModalState}
                deleteItemForm={
                    activeItem ? (
                        <ConfirmModalBody
                            title={t('adminPanel:manageSlotsCriteria.deleteForm.title')}
                            message={t('adminPanel:manageSlotsCriteria.deleteForm.message')}
                            confirmButton={t('adminPanel:manageSlotsCriteria.deleteForm.accept_button')}
                            declineButton={t('adminPanel:manageSlotsCriteria.deleteForm.decline_button')}
                            onConfirm={deleteSlotsCriteria}
                            onDecline={deleteModalState.toggleModal}
                        />
                    ) : (
                        <ActivityIndicator />
                    )
                }
                setActiveItem={fetchActiveItem}
                addButtonText={t('adminPanel:table.controls.addButtonText')}
                previousPageText={t('adminPanel:table.controls.previousPageText')}
                nextPageText={t('adminPanel:table.controls.nextPageText')}
                breadcrumbs={<RouteBreadcrumbs />}
                {...rest}
            />
        </AdminPanelContainer>
    );
};
