import { AgreementsWRProps, AgreementsWRValues } from './AgreementsWRForm.types';
import * as Yup from 'yup';
import { FormikBag } from 'formik';
import { UserUpdateData } from '../../../../../medrefer-web-sdk/api/models';

export const mapPropsToValues = (props: AgreementsWRProps): AgreementsWRValues => {
    return {
        emailAgreement: false,
        requestWaitingRoomFields: props.requestWaitingRoomFields,
        smsAgreement: false,
        termsAgreement: false,
        user: undefined,
        healthService: props.healthService,
        fields: props.fields,
        mandatory_fields: props.mandatory_fields,
        form_fields: {
            emailAgreement: false,
            requestWaitingRoomFields: props.requestWaitingRoomFields,
            smsAgreement: false,
            termsAgreement: false,
            user: undefined,
            healthService: props.healthService,
            fields: props.fields,
            mandatory_fields: props.mandatory_fields,
        },
    };
};

export const validationSchema = ({ t }: AgreementsWRProps) =>
    Yup.object().shape({
        termsAgreement: Yup.bool().oneOf([true], t('newAppointmentForm:warning_terms_agreement')),
        /*smsAgreement: Yup.bool().when(['phone', 'mandatory_fields', 'fields'], {
            is: (phone, mandatory_fields, fields) =>
                ['', undefined].includes(phone) && (mandatory_fields.includes('phone') || fields.includes('phone')),
            then: Yup.bool().equals([false], t('newAppointmentForm:warning_phone_required')),
            otherwise: Yup.bool().when('mandatory_fields', {
                is: (mandatory_fields) => mandatory_fields.includes('phone'),
                then: Yup.bool().oneOf([true], t('newAppointmentForm:warning_sms_agreement')),
            }),
        }),*/
    });

export const handleSubmit = (
    values: AgreementsWRValues,
    { props }: FormikBag<AgreementsWRProps, AgreementsWRValues>,
) => {
    const phoneNumber = props.requestWaitingRoomFields?.phone;
    if (phoneNumber === undefined || values.user === undefined || values.user.patient === undefined) return;

    const updatedUser: UserUpdateData = {
        role: values.user.role,
        phone: phoneNumber,
        smsAgreement: values.smsAgreement,
        emailAgreement: values.emailAgreement,
        dateOfBirth: new Date(values.user.patient.date_of_birth || ''),
        insuranceStatus: values.user.patient.insurance_status,
        insuranceNumber: values.user.patient.insurance_number,
        city: values.user.patient.city,
        street: values.user.patient.street,
        postCode: values.user.patient.post_code,
    };

    props.updateProfile(updatedUser);
    props.onFormSubmit(values);
};
