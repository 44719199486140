import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { truncateString } from 'medrefer-web-sdk/web-kit/utils/stringHelpers';

type Props = {
    text: string;
    maxLength?: number;
    initIsCollapsed?: boolean;
};

export const TextCollapse = ({ text, maxLength = 150, initIsCollapsed = true }: Props) => {
    const [isCollapsed, setIsCollapsed] = useState(initIsCollapsed);
    const { t } = useTranslation();

    const onToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    return (
        <>
            {truncateString(text, isCollapsed ? maxLength : text.length)}
            {` `}
            {text.length > maxLength && (
                <CollapseButton onClick={onToggle}>
                    {isCollapsed ? t('textCollapse:more') : t('textCollapse:less')}
                </CollapseButton>
            )}
        </>
    );
};

const CollapseButton = styled.span`
    text-decoration: underline;
    cursor: pointer;
`;
