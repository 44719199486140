import React from 'react';
import { LinkItem, Modal, useModalState } from 'medrefer-web-sdk/web-kit';
import * as Styled from 'components/Navigation/SettingsMenu/SettingsMenu.styled';

import ConfirmSignOut from 'components/Navigation/SettingsMenu/ConfirmSignout';

import { useTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { UserRole } from 'medrefer-web-sdk/api/models';
import useBrand from 'hooks/useBrand';
import usePopoverMenu from 'hooks/usePopoverMenu';
import { settings } from 'utils/settings';
import { UserAvatar } from 'components/shared/UserAvatar';
import { getPathAdmin } from 'router-paths';
import { translateUserRole } from 'utils/i18n';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser } from 'features/auth/selectors';
import { routerActions } from 'connected-react-router';
import UserProfileSelect from './UserProfileSelect';

export const SettingsMenu = () => {
    const authUser = useSelector(getAuthUser);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const modal = useModalState();
    const { getPathBranded } = useBrand();
    const { isOpen, handleToggle, menuRef } = usePopoverMenu();

    const goTo = (path: string) => {
        dispatch(routerActions.push(path));
        handleToggle();
    };

    if (!authUser) return null;

    return (
        <div ref={menuRef}>
            <div onClick={handleToggle} style={{ cursor: 'pointer' }}>
                <UserAvatar user={authUser} size={40} round />
            </div>

            <Styled.MenuSettings isOpen={isOpen}>
                <Styled.RightCol>
                    <Row>
                        <Col xs={3}>
                            <UserAvatar user={authUser} size={60} borderRadius={8} />
                        </Col>
                        <Col xs={9}>
                            <Styled.UserEmail className="mb-1">{authUser.email}</Styled.UserEmail>
                            <Styled.UserDetails>{translateUserRole(authUser.role)}</Styled.UserDetails>
                        </Col>
                    </Row>
                    {authUser.profiles_count > 1 && (
                        <>
                            <Styled.DecorationLine className="mb-3 mt-3" />
                            <Row>
                                <Col>
                                    <UserProfileSelect user={authUser} />
                                </Col>
                            </Row>
                        </>
                    )}
                    <Styled.DecorationLine className="mb-3 mt-3" />
                    <Row className="w-100">
                        <Col className="ml-auto d-flex flex-column" xs={9}>
                            <LinkItem
                                onClick={() =>
                                    goTo(
                                        authUser.role === UserRole.admin
                                            ? getPathAdmin('profile')
                                            : getPathBranded('profile'),
                                    )
                                }
                            >
                                {t('settingsMenu:profile')}
                            </LinkItem>
                            {authUser.role === UserRole.organizationMember && (
                                <LinkItem onClick={() => goTo(getPathBranded('memberProfile'))}>
                                    {t('settingsMenu:memberProfile')}
                                </LinkItem>
                            )}
                            {settings.settingsEnabled && (
                                <LinkItem
                                    onClick={() =>
                                        goTo(
                                            authUser.role === UserRole.admin
                                                ? getPathAdmin('settings')
                                                : getPathBranded('settings'),
                                        )
                                    }
                                >
                                    {t('settingsMenu:settings')}
                                </LinkItem>
                            )}
                            <div className="mr-auto">
                                <Modal
                                    state={modal}
                                    button={
                                        <LinkItem data-testid="logout-modal-opener">
                                            {t('settingsMenu:logout')}{' '}
                                        </LinkItem>
                                    }
                                    body={<ConfirmSignOut toggleModal={modal.toggleModal} />}
                                />
                            </div>
                        </Col>
                    </Row>
                </Styled.RightCol>
            </Styled.MenuSettings>
        </div>
    );
};
