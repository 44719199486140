import React, { useState } from 'react';
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next';
import { Field, Form, FormikProps, withFormik } from 'formik';
import * as Yup from 'yup';
import useOrganizationPatientsOptions from 'hooks/options/useOrganizationPatientsOptions';
import { Col, Row } from 'react-bootstrap';
import {
    Button,
    ErrorMessage,
    FormAsyncSelect,
    FormErrorMessage,
    FormGroup,
    FormSelect,
    IconButtonAlt,
    idNameToOption,
    InformationBubble,
    InformationBubbleTypes,
    InformationContainer,
    Label,
    TextArea,
    useModalState,
} from 'medrefer-web-sdk/web-kit';
import { ReactSVG } from 'react-svg';
import PlusIcon from 'assets/PlusIcon.svg';
import { PatientInvitationModal } from 'components/shared/PatientInvitationModal';
import { defaultSlotMode, Slot, SlotMode, User } from 'medrefer-web-sdk/api/models';
import { ApiRequest } from 'hooks/useApiRequest';
import useSlotModeOptions from 'hooks/options/useSlotModeOptions';

interface FormProps extends WithTranslation {
    request: ApiRequest;
    slot: Slot;
    handleSubmit: (values: FormValues) => void;
}

export interface FormValues {
    patient?: string;
    reason?: string;
    comment?: string;
    mode: SlotMode;
}

export const InnerForm: React.FC<FormProps & FormikProps<FormValues>> = (props) => {
    const { t } = useTranslation();
    const { loadOrganizationPatientsOptions, toOrganizationPatientOption } = useOrganizationPatientsOptions();
    const [invitedPatient, setInvitedPatient] = useState<User | null>(null);
    const patientInvitationModal = useModalState();

    const { modeOptions } = useSlotModeOptions(props.setFieldValue);
    const allowedModeOptions = modeOptions.filter(
        (option) => option.value === props.slot.mode || props.slot.mode == null,
    );

    const onPatientInvited = (patient: User) => {
        patientInvitationModal.toggleModal();
        props.setFieldValue('patient', patient.id.toString());
        setInvitedPatient(patient);
    };

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.handleSubmit();
            }}
        >
            <FormGroup>
                <Row>
                    <Col xs={12}>
                        <Label isRequired>{t('slotBooking:label_patient')}</Label>
                        <div className="d-flex flex-row">
                            <div style={{ width: '100%' }}>
                                <Field
                                    key={invitedPatient?.id}
                                    name="patient"
                                    loadOptions={loadOrganizationPatientsOptions}
                                    component={FormAsyncSelect}
                                    initialOptions={invitedPatient && [toOrganizationPatientOption(invitedPatient)]}
                                    isError={props.touched.patient && !!props.errors.patient}
                                    onBlur={props.handleBlur}
                                />
                            </div>
                            <IconButtonAlt className="ml-3" type="button" onClick={patientInvitationModal.toggleModal}>
                                <ReactSVG src={PlusIcon} />
                            </IconButtonAlt>
                        </div>
                        {invitedPatient && (
                            <InformationBubble type={InformationBubbleTypes.info}>
                                {t('slotBooking:patient_invited_info')}
                            </InformationBubble>
                        )}
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12}>
                        <Label isRequired labelTop>
                            {t('newAppointmentForm:title_reason_for_appointment')}
                        </Label>
                        <Field
                            name="reason"
                            options={props.slot.available_for_reasons.map(idNameToOption)}
                            component={FormSelect}
                            placeholder={t('newAppointmentForm:placeholder_reason_for_appointment')}
                            noOptionsMessage={() => t('newAppointmentForm:no_options_reason_for_appointment')}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12}>
                        <Label isRequired labelTop>
                            {t('calendar:mode.title')}
                        </Label>
                        <Field name="mode" options={allowedModeOptions} component={FormSelect} />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12}>
                        <Label labelTop>{t('newAppointmentForm:title_message')}</Label>
                        <TextArea
                            isError={!!(props.touched.comment && props.errors.comment)}
                            id="comment"
                            placeholder={t('newAppointmentForm:placeholder_message')}
                            onChange={props.handleChange}
                            value={props.values.comment}
                            onBlur={props.handleBlur}
                        />
                        {props.touched.comment && props.errors.comment && (
                            <ErrorMessage className="text-left mt-1">{props.errors.comment}</ErrorMessage>
                        )}
                    </Col>
                </Row>
            </FormGroup>
            <InformationContainer>
                <FormErrorMessage apiErrors={props.request.errors} />
            </InformationContainer>
            <div style={{ maxWidth: 200, margin: 'auto' }}>
                <Button isLoading={props.request.isLoading}>{t('newAppointmentForm:button_message')}</Button>
            </div>
            <PatientInvitationModal state={patientInvitationModal} onPatientInvited={onPatientInvited} />
        </Form>
    );
};

const BookSlotForPatientForm = withFormik<FormProps, FormValues>({
    mapPropsToValues: ({ slot }) => {
        const reasonId =
            slot.available_for_reasons.length === 1 ? slot.available_for_reasons[0].id.toString() : undefined;
        return {
            patient: undefined,
            reason: reasonId,
            comment: undefined,
            mode: slot.mode || defaultSlotMode,
        };
    },
    validationSchema: ({ t }: FormProps) =>
        Yup.object().shape({
            patient: Yup.number().required(t('form:error.required')),
            reason: Yup.string().required(t('newAppointmentForm:warning_reason_for_appointment_required')),
            comment: Yup.string()
                .min(5, t('newAppointmentForm:warning_message_min'))
                .max(500, t('newAppointmentForm:warning_message_max')),
        }),
    handleSubmit: (values, { props }) => {
        props.handleSubmit(values);
    },
})(InnerForm);

export default withTranslation()(BookSlotForPatientForm);
