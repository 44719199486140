import { getTimeFormatted } from 'components/Calendar/helpers';
import { getMappingData } from 'components/Calendar/helpers/utils';
import { BaseHealthcareService, OrganizationLocation, Slot } from 'medrefer-web-sdk/api/models';
import { ICalendarInfo, ISchedule } from 'tui-calendar';
import { CalendarType } from 'components/Calendar/CalendarView/CalendarView.types';

export const mapSlotsToSchedules = (
    slots: Slot[],
    activeServices: BaseHealthcareService[],
    calendarType: string | undefined,
    calendars: ICalendarInfo[],
    organizationLocations: OrganizationLocation[],
): ISchedule[] => {
    return slots.map((slot) => {
        const { calendarId, scheduleWidth, healthCareService, scheduleLeft } = getMappingData(
            slot,
            activeServices,
            calendars,
            organizationLocations,
        );

        return {
            calendarId: calendarId,
            category: 'time',
            isVisible: true,
            title: `${calendarType === CalendarType.month ? getTimeFormatted(new Date(slot.date_from)) + ' ' : ''}${
                slot.healthcare_service.name
            }`.trim(),
            id: slot.key,
            start: new Date(slot.date_from),
            end: new Date(slot.date_to),
            isReadOnly: true,
            state: slot.status,
            customStyle: `opacity: 0.3; ${
                calendarType === CalendarType.day && healthCareService
                    ? `width: ${scheduleWidth}%; position: absolute; left: ${scheduleLeft}% !important`
                    : ''
            }`,
            raw: {
                type: 'slot',
                id: slot.key,
                service_id: slot.healthcare_service.id,
                slot_permission: slot.slot_permission,
            },
            //this is done because we want to use AllDay styles on monthly calendar schedules
            isAllDay: calendarType === CalendarType.month,
        };
    });
};
