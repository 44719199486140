import styled, { css } from 'styled-components';
import ExclamationCircleIcon from '../../assets/icons/ExclamationCircleIcon.png';

interface TextAreaProps {
    isError?: boolean;
}

export const TextArea = styled.textarea<TextAreaProps>`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid ${({ theme }) => theme.purple100};
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    min-height: 84px;
    padding: 11px 35px 11px 14px;
    resize: none;
    ${(props) =>
        props.isError &&
        css`
            background: ${({ theme }) => theme.red100};
            border: 1px solid ${({ theme }) => theme.red300};
            color: ${({ theme }) => theme.red300};
            background-image: url(${ExclamationCircleIcon});
            background-repeat: no-repeat;
            background-position: 96% 50%;
        `}
`;
