import { PopoverState } from 'medrefer-web-sdk/web-kit/components/Popover/hooks/usePopoverState';

export interface PopoverPosition {
    height: number;
    width: number;
    topOffset: number;
    leftOffset: number;
}

const defaultPopoverHeight = 400;
const defaultPopoverWidth = 400;

/**
 * Calculates position of calendar popover based on clicked item
 * @param popoverState state object of popover that should be positioned
 * @param scheduleType type of schedule clicked
 * @returns top and left offset relative to the whole window
 */
export const usePopoverPosition = (popoverState: PopoverState): PopoverPosition => {
    const { target } = popoverState;

    const clientHeight = document.body.clientHeight;
    const clientWidth = document.body.clientWidth;

    const popoverHeight = Math.min(popoverState.height ? popoverState.height : defaultPopoverHeight, clientHeight - 40);
    const popoverWidth = popoverState.width ? popoverState.width : defaultPopoverWidth;

    if (!target) {
        return {
            height: popoverHeight,
            width: popoverWidth,
            topOffset: clientHeight / 2 - popoverHeight / 2,
            leftOffset: clientWidth / 2 - popoverWidth / 2,
        };
    }

    // Position of clicked element
    const top = target.top;
    const left = target.left + target.width;

    // Popover dimensions

    const leftOffset = left + popoverWidth > clientWidth ? left - popoverWidth - target.width : left;

    let topOffset = top;

    if (top + popoverHeight > clientHeight) {
        const overflowValue = popoverHeight + top - clientHeight;
        topOffset = top - overflowValue - 20;
    }

    if (clientHeight <= popoverHeight + 40) {
        topOffset = 20;
    }

    return { height: popoverHeight, width: popoverWidth, topOffset, leftOffset };
};
