import styled from 'styled-components';

export const SidebarListWrap = styled.aside`
    flex: 1;
    height: calc(100vh - ${({ theme }) => theme.navbarHeight.desktop});
    max-width: 400px;
    background: #fafafc;
    box-shadow: 0 1px 5px rgba(44, 51, 71, 0.12);
    z-index: 1;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        width: 100vw;
        height: calc(var(--maxvh)- 120px);
        box-shadow: none;
    }
`;

export const ScrollContainer = styled.div`
    height: calc(100vh - 225px);
    overflow: scroll;
    margin: 0 auto;

    box-sizing: border-box;
    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: transparent;
    }
    &:hover {
        ::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.2);
        }
    }
    ::-webkit-scrollbar {
        width: 2px;
        position: fixed;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        height: calc(var(--vh, 1vh) * 100 - 120px);
        > div {
            width: 100vw;
        }
        margin-top: 75px;
        width: 100vw;
    }
`;
