import React from 'react';
import { Cell, Column } from 'react-table';
import { ManageMembersTableData } from 'components/AdminPanel/ManageMembers/hooks/useManageMembers';
import { DisplayArrayData } from 'medrefer-web-sdk/web-kit';
import { translateUserRole } from 'utils/i18n';

export const columns: (t: (key: string) => string) => Column<ManageMembersTableData>[] = (t) => [
    {
        Header: t('adminPanel:table.columns.id'),
        accessor: 'id',
        sortParam: 'id',
        width: 80,
    },
    {
        Header: t('adminPanel:table.columns.email'),
        accessor: 'email',
        sortParam: 'email',
        width: 200,
    },
    {
        Header: t('adminPanel:table.columns.name'),
        accessor: 'nameDisplay',
        sortParam: 'last_name',
    },
    {
        Header: t('adminPanel:table.columns.role'),
        accessor: 'role',
        sortParam: 'role',
        Cell: (cell: Cell) => <span>{translateUserRole(cell.value)}</span>,
    },
    {
        Header: t('adminPanel:table.columns.available_services'),
        accessor: 'assigned_healthcare_services',
        minWidth: 200,
        Cell: DisplayArrayData,
    },
    {
        Header: t('adminPanel:table.columns.status'),
        accessor: 'is_active',
        sortParam: 'is_active',
        // eslint-disable-next-line react/display-name
        Cell: ({ cell: { value } }) => {
            return <span>{value ? t('adminPanel:table.status_active') : t('adminPanel:table.status_inactive')}</span>;
        },
        maxWidth: 80,
    },
];
