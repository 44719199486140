import { useEffect, useMemo, useState } from 'react';
import { SlotSimplified, SlotStatus } from 'medrefer-web-sdk/api/models';
import {
    addDays,
    getDaysDiff,
    maxDate,
    maxNullableDate,
    minDate,
    minNullableDate,
    floorDate,
    subtractDays,
    toNullableDate,
    ceilDate,
} from 'utils/dates';
import { LoadMoreData } from './SlotsCalendar';
import { NodeCallback, useElementSize } from 'medrefer-web-sdk/web-kit/hooks';

export interface CalendarHook {
    /**
     * Slots aggregated for each day
     */
    daysSlots: SlotSimplified[][];
    /**
     * The date that calendar displays columns from
     */
    dateFrom: Date;
    /**
     * The date that calendar displays columns to
     */
    dateTo: Date;
    /**
     * The date from which the slots have been already fetched
     */
    fetchedDateFrom: Date;
    /**
     * The date to which the slots have been already fetched
     */
    fetchedDateTo: Date;
    /**
     * The nearest date of the slot further down the calendar
     */
    nearestSlotDate: Date | null;
    /**
     * Go to next calendar page
     */
    goNext: () => void;
    /**
     * Go to previous calendar page
     */
    goPrev: () => void;
    /**
     * Go to the nearest slot further down the calendar
     */
    goNearest: () => void;
    hasNext: boolean;
    hasPrev: boolean;
    noSlotsInFuture: boolean;
}

export const useSlotsCalendar = (
    initSlots: SlotSimplified[],
    initDateFrom: Date,
    initFetchedDateFrom: Date,
    initFetchedDateTo: Date,
    initNearestDateFrom: Date | null,
    daysRangeSize: number,
    dateRangeMin: Date | null,
    dateRangeMax: Date | null,
    loadMore: (dateFrom: Date, dateTo: Date) => Promise<LoadMoreData>,
): CalendarHook => {
    const [fetchData, setFetchData] = useState({
        slots: initSlots,
        fetchedDateFrom: initFetchedDateFrom,
        fetchedDateTo: initFetchedDateTo,
        // The nearest date of the slot in the future that hasn't been fetched yet
        nearestDateFrom: initNearestDateFrom,
    });
    const { slots, fetchedDateFrom, fetchedDateTo, nearestDateFrom } = fetchData;

    const [dateFrom, setDateFrom] = useState(initDateFrom);
    const dateTo = addDays(dateFrom, daysRangeSize);
    const [isLoading, setIsLoading] = useState(false);
    const nearestNextSlot = slots.find((s) => new Date(s.date_from) > dateTo && s.status === SlotStatus.FREE);
    const nearestSlotDate = minNullableDate(toNullableDate(nearestNextSlot?.date_from), nearestDateFrom);

    const hasPrev =
        dateFrom.getTime() > fetchedDateFrom.getTime() &&
        dateRangeMin != null &&
        dateFrom.getTime() > dateRangeMin.getTime();

    const hasNext =
        dateTo.getTime() < fetchedDateTo.getTime() && dateRangeMax != null && dateTo.getTime() < dateRangeMax.getTime();

    useEffect(() => {
        if (shouldFetchNext()) {
            fetchNext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateTo, fetchedDateTo, dateRangeMax]);

    useEffect(() => {
        if (shouldFetchPrev()) {
            fetchPrev();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFrom, fetchedDateFrom, dateRangeMin]);

    const shouldFetchNext = () => {
        if (dateRangeMax == null) {
            return false;
        }
        const isEndReached = dateTo.getTime() >= fetchedDateTo.getTime();
        const fetchHasNext = fetchedDateTo.getTime() < dateRangeMax.getTime();
        return isEndReached && fetchHasNext && !isLoading;
    };

    const shouldFetchPrev = () => {
        if (dateRangeMin == null) {
            return false;
        }
        const isStartReached = dateFrom.getTime() <= fetchedDateFrom.getTime();
        const fetchHasPrev = fetchedDateFrom.getTime() > dateRangeMin.getTime();
        return isStartReached && fetchHasPrev && !isLoading;
    };

    const fetchNext = () => {
        fetchSlots(fetchedDateTo, minDate(addDays(maxDate(fetchedDateTo, dateTo), daysRangeSize), dateRangeMax!));
    };

    const fetchPrev = () => {
        fetchSlots(maxDate(subtractDays(fetchedDateFrom, daysRangeSize), dateRangeMin!), fetchedDateFrom);
    };

    const fetchSlots = (filterDateFrom: Date, filterDateTo: Date) => {
        setIsLoading(true);

        loadMore(filterDateFrom, filterDateTo)
            .then((data) => {
                setFetchData((current) => ({
                    slots: current.slots.concat(data.items),
                    fetchedDateFrom: minDate(filterDateFrom, current.fetchedDateFrom),
                    fetchedDateTo: maxDate(filterDateTo, current.fetchedDateTo),
                    nearestDateFrom: maxNullableDate(current.nearestDateFrom, data.nearestDateFrom),
                }));
                setIsLoading(false);
            })
            .catch(() => {
                setIsLoading(false);
            });
    };

    const goNext = () => {
        if (!hasNext) {
            return;
        }

        const nextStart = minDate(dateTo, subtractDays(ceilDate(fetchedDateTo), daysRangeSize));
        setDateFrom(nextStart);
    };

    const goPrev = () => {
        if (!hasPrev) {
            return;
        }

        const nextStart = maxDate(floorDate(fetchedDateFrom), subtractDays(dateFrom, daysRangeSize));
        setDateFrom(nextStart);
    };

    const goNearest = () => {
        if (!nearestSlotDate || !dateRangeMax) {
            return;
        }

        const nextStart = minDate(floorDate(nearestSlotDate), subtractDays(ceilDate(dateRangeMax), daysRangeSize));
        setDateFrom(nextStart);
    };

    const daysSlots = useMemo(() => {
        const daysSlots: SlotSimplified[][] = [];

        Array(daysRangeSize)
            .fill(0)
            .forEach(() => {
                daysSlots.push([]);
            });

        slots
            .filter((slot) => new Date(slot.date_from) >= dateFrom && new Date(slot.date_from) < dateTo)
            .forEach((slot) => {
                const columnIdx = getDaysDiff(dateFrom, floorDate(new Date(slot.date_from)));
                if (daysSlots[columnIdx]) {
                    daysSlots[columnIdx].push(slot);
                }
            });
        return daysSlots;
    }, [daysRangeSize, slots, dateFrom, dateTo]);

    const noSlotsInFuture = useMemo(() => {
        return (
            !slots.find((slot) => slot.status === SlotStatus.FREE && Date.parse(slot.date_from) > Date.now()) &&
            !nearestSlotDate
        );
    }, [slots, nearestSlotDate]);

    return {
        daysSlots,
        dateFrom,
        dateTo,
        fetchedDateFrom,
        fetchedDateTo,
        nearestSlotDate,
        goNext,
        goPrev,
        goNearest,
        hasNext,
        hasPrev,
        noSlotsInFuture,
    };
};

export const useCalendarSize = (maxDaysRangeSize: number): [NodeCallback, number] => {
    const [ref, calendarSize] = useElementSize({ initialTimeout: 300 });
    const [daysRangeSize, setDaysRangeSize] = useState(maxDaysRangeSize);

    useEffect(() => {
        if (calendarSize) {
            const minColumnWidth = 100;
            const columnsFit = Math.ceil(calendarSize.width / minColumnWidth);
            setDaysRangeSize(Math.min(columnsFit, maxDaysRangeSize));
        }
    }, [calendarSize, maxDaysRangeSize]);

    return [ref, daysRangeSize];
};
