import { withFormik } from 'formik';
import { withTranslation } from 'react-i18next';
import { mapPropsToValues, validationSchema, handleSubmit } from './AdminOrganizationForm.schema';
import { AdminOrganizationFormProps, AdminOrganizationFormValues } from './AdminOrganizationForm.types';
import AdminOrganizationData from './AdminOrganizationData';

const AdminOrganizationForm = withFormik<AdminOrganizationFormProps, AdminOrganizationFormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(AdminOrganizationData);

export default withTranslation()(AdminOrganizationForm);
