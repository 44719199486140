import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ReactSVG } from 'react-svg';

import EditIcon from 'assets/EditIcon.svg';

import {
    UnderlineH2 as TitleUnderline,
    Modal,
    useModalState,
    DisplayLabelsArray,
    ExternalLink,
} from 'medrefer-web-sdk/web-kit';
import { ProfileDisplayContainer, CustomIconButton } from 'components/Profile/Profile.styled';
import { useDispatch, useSelector } from 'react-redux';
import { userSelectors } from 'features/auth';
import useApiRequest from 'hooks/useApiRequest';
import ProfileAttribute from 'components/shared/ProfileAttribute';
import { api } from 'medrefer-web-sdk/api';
import { getUserData } from 'features/auth/actions';
import { EditMemberProfileData, HealthcareService } from 'medrefer-web-sdk/api/models';
import { EditMemberProfileForm } from 'components/MemberProfile/EditMemberProfileForm';
import { errorTimeout } from 'utils/constants';
import { canManageAnyService } from 'features/auth/rules';
import { getPath } from 'router-paths';

const ModalButton = function (): JSX.Element {
    return (
        <CustomIconButton>
            <ReactSVG src={EditIcon} />
        </CustomIconButton>
    );
};

const useAssignedHealthcareServices = () => {
    const [items, setItems] = useState<HealthcareService[]>([]);
    const authUser = useSelector(userSelectors.getAuthUser);

    useEffect(() => {
        if (authUser && !canManageAnyService(authUser)) {
            api.getAssignedHealthcareServices().then(setItems);
        }
    }, [authUser]);

    return items;
};

export const MemberProfile: React.FC = () => {
    const authUser = useSelector(userSelectors.getAuthUser);
    const assignedHealthcareServices = useAssignedHealthcareServices();
    const updateProfileRequest = useApiRequest({ errorTimeout });
    const { t } = useTranslation();
    const modal = useModalState();
    const dispatch = useDispatch();
    const member = authUser?.practitioner;

    const updateMemberProfile = (data: EditMemberProfileData) => {
        updateProfileRequest.dispatch(api.updateMemberProfile(data)).then(() => {
            dispatch(getUserData());
            modal.toggleModal();
        });
    };

    if (!member) {
        return null;
    }

    return (
        <div className="pl-md-5 pr-md-5 pb-md-5 pt-md-3 pt-0">
            <div className="w-full d-flex justify-content-center mt-md-5">
                <ProfileDisplayContainer>
                    <Modal
                        state={modal}
                        isCentered={false}
                        button={<ModalButton />}
                        className="position-absolute"
                        body={
                            <EditMemberProfileForm
                                updateMemberProfile={updateMemberProfile}
                                authUser={authUser}
                                request={updateProfileRequest}
                            />
                        }
                    />
                    <TitleUnderline style={{ padding: '20px 0' }}>
                        {t('memberProfileDisplay:member_profile_label')}
                    </TitleUnderline>
                    <Row className="mr-0 ml-0">
                        <Col xs={12} md={6}>
                            <ProfileAttribute
                                label={t('memberProfileDisplay:member_description_label')}
                                value={member.description}
                            />
                            <ProfileAttribute
                                label={t('memberProfileDisplay:member_image_label')}
                                value={member.image ? ' ' : ' - '}
                            />
                            {member.image && (
                                <Row>
                                    <img
                                        className="mb-4"
                                        alt={'Member profile'}
                                        style={{ maxHeight: '100px' }}
                                        src={member.image.file}
                                    />
                                </Row>
                            )}
                            {assignedHealthcareServices.length > 0 && (
                                <>
                                    <ProfileAttribute
                                        label={t('memberProfileDisplay:member_services_label')}
                                        value={' '}
                                    />
                                    <Row className="mb-4">
                                        <DisplayLabelsArray labels={assignedHealthcareServices.map((s) => s.name)} />
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={3} className="mr-auto">
                            <div>
                                <ExternalLink href={getPath('privacyPolicy')}>
                                    {t('memberProfileDisplay:privacy_policy_link')}
                                </ExternalLink>
                            </div>
                        </Col>
                    </Row>
                </ProfileDisplayContainer>
            </div>
        </div>
    );
};
