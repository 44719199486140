import styled, { css } from 'styled-components';
import { BookingStatus } from './StatusBadge';

export interface StyledBadgeProps {
    type?: BookingStatus;
}

const cssGreen = css`
    background: #eaf9ec;
    color: #4cc13a;
    &:after {
        background: #4cc13a;
    }
`;

const cssRed = css`
    background: #ffeef3;
    color: #f92763;

    &:after {
        background: #f92763;
    }
`;

const cssGrey = css`
    background: #eeeef2;
    color: #747478;

    &:after {
        background: #747478;
    }
`;

export const StyledBadge = styled.span<StyledBadgeProps>`
    display: block;
    position: relative;
    border-radius: 13px;
    font-weight: 600;
    font-size: 10px;
    color: black;
    padding: 6px 11px 7px 22px;
    height: 27px;
    box-sizing: border-box;
    width: fit-content;

    &:after {
        position: absolute;
        top: 11px;
        left: 11px;
        content: '';
        width: 6px;
        height: 6px;
        border-radius: 50%;
    }
    ${(props) => props.type === BookingStatus.upcoming && cssGreen}
    ${(props) => props.type === BookingStatus.past && cssGrey}
    ${(props) => props.type === BookingStatus.cancelled && cssRed}
`;
