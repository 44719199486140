import { useEffect, useState, useCallback, Dispatch, SetStateAction } from 'react';
import { AppointmentModel, AppointmentParams, AppointmentRequestParams, SortTypes } from 'medrefer-web-sdk/api/models';
import { api } from 'medrefer-web-sdk/api';
import {
    MyAppointmentsData,
    MyWaitingRoomAppointmentsData,
} from 'components/PatientPanel/MyAppointments/MyAppointments.types';
import { basicAppointmentDataObject, basicParams, basicWaitingRoomDataObject } from '../MyAppointments.consts';
import moment from 'moment';
import { BookingStatus } from 'components/Dashboard/StatusBadge/StatusBadge';
import {
    AppointmentRequestsModel,
    AppointmentRequestStatus,
} from '../../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';

export const useMyAppointmentsData = () => {
    const [upcomingAppointments, setUpcomingAppointments] = useState<MyAppointmentsData>(basicAppointmentDataObject);
    const [pastAppointments, setPastAppointments] = useState<MyAppointmentsData>(basicAppointmentDataObject);
    const [cancelledAppointments, setCancelledAppointments] = useState<MyAppointmentsData>(basicAppointmentDataObject);
    const [onGoingAppointments, setOnGoingAppointments] = useState<MyAppointmentsData>(basicAppointmentDataObject);
    const [waitingRoomAppointments, setWaitingRoomAppointments] =
        useState<MyWaitingRoomAppointmentsData>(basicWaitingRoomDataObject);

    useEffect(() => {
        const now = moment();
        const onGoing = pastAppointments.appointments.filter(
            (appt) => now.isAfter(moment(appt.date_from)) && now.isBefore(moment(appt.date_to)),
        );
        const newAppointments = [...onGoing];
        setOnGoingAppointments({
            ...onGoingAppointments,
            totalCount: newAppointments.length,
            appointments: newAppointments,
        });
        //eslint-disable-next-line
    }, [pastAppointments]);

    const loadMore = useCallback(
        async (params: AppointmentParams, callbackSetState: Dispatch<SetStateAction<MyAppointmentsData>>) => {
            callbackSetState((prev) => {
                return { ...prev, isLoading: true };
            });

            const res = await api.getAppointments(params);

            callbackSetState((prev) => {
                if (!prev) {
                    return { ...res, isLoading: false };
                }
                return {
                    ...res,
                    appointments: prev.appointments.concat(res.appointments),
                    isLoading: false,
                };
            });
        },
        [],
    );

    const loadMore_waitingRoom = useCallback(
        async (
            params: AppointmentRequestParams,
            callbackSetState: Dispatch<SetStateAction<MyWaitingRoomAppointmentsData>>,
        ) => {
            callbackSetState((prev) => {
                return { ...prev, isLoading: true };
            });

            const res = await api.getAppointmentRequests(params);

            callbackSetState((prev) => {
                if (!prev) {
                    return {
                        next: res.next,
                        waitingRoomAppointments: res.appointment_requests,
                        totalCount: res.totalCount,
                        isLoading: false,
                    };
                }
                return {
                    waitingRoomAppointments: prev.waitingRoomAppointments.concat(res.appointment_requests),
                    next: res.next,
                    totalCount: res.totalCount,
                    isLoading: false,
                };
            });
        },
        [],
    );

    const loadMoreWaitingRoomAppointments = useCallback(
        (prev: AppointmentRequestsModel[]) => {
            const params: AppointmentRequestParams = {
                offset: prev.length,
                limit: 3,
                search: '',
                ordering: '',
                statuses: [AppointmentRequestStatus.PENDING],
            };
            loadMore_waitingRoom(params, setWaitingRoomAppointments);
        },
        [loadMore_waitingRoom],
    );

    const loadMoreUpcomingAppointments = useCallback(
        (prev: AppointmentModel[]) => {
            const params = basicParams(prev.length);
            params.dateFrom = new Date();
            params.statuses = [BookingStatus.upcoming];
            loadMore(params, setUpcomingAppointments);
        },
        [loadMore],
    );

    const loadMorePastAppointments = useCallback(
        (prev: AppointmentModel[]) => {
            const params = basicParams(prev.length);
            params.dateTo = new Date();
            params.statuses = [BookingStatus.past];
            params.sort = SortTypes.dateDesc;

            loadMore(params, setPastAppointments);
        },
        [loadMore],
    );

    const loadMoreCancelledAppointments = useCallback(
        (prev: AppointmentModel[]) => {
            const params = basicParams(prev.length);
            params.statuses = [BookingStatus.cancelled];
            params.sort = SortTypes.dateDesc;

            loadMore(params, setCancelledAppointments);
        },
        [loadMore],
    );

    const reloadAppointments = useCallback(() => {
        setUpcomingAppointments(basicAppointmentDataObject);
        setPastAppointments(basicAppointmentDataObject);
        setCancelledAppointments(basicAppointmentDataObject);
        setWaitingRoomAppointments(basicWaitingRoomDataObject);
        loadMoreWaitingRoomAppointments([]);
        loadMoreUpcomingAppointments([]);
        loadMorePastAppointments([]);
        loadMoreCancelledAppointments([]);
    }, [
        loadMoreUpcomingAppointments,
        loadMorePastAppointments,
        loadMoreCancelledAppointments,
        loadMoreWaitingRoomAppointments,
    ]);

    useEffect(() => {
        reloadAppointments();
    }, [
        loadMoreUpcomingAppointments,
        loadMorePastAppointments,
        loadMoreCancelledAppointments,
        reloadAppointments,
        loadMoreWaitingRoomAppointments,
    ]);

    return {
        onGoingAppointments,
        upcomingAppointments,
        pastAppointments,
        cancelledAppointments,
        waitingRoomAppointments,
        loadMoreUpcomingAppointments,
        loadMorePastAppointments,
        loadMoreCancelledAppointments,
        loadMoreWaitingRoomAppointments,
        reloadAppointments,
    };
};
