import styled from 'styled-components';

interface IconButtonProps {
    tooltipContent?: string;
}
export const IconButton = styled.button<IconButtonProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 42px;
    height: 42px;
    border: 2px solid ${({ theme }) => theme.accent};
    box-sizing: border-box;
    border-radius: 8px;
    background: transparent;
    &:hover {
        &:before {
            visibility: ${({ tooltipContent }) => (tooltipContent === undefined ? 'hidden' : 'visible')};
            opacity: 1;
        }
    }
    &:before {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.2s ease-in;
        opacity: 0;
        content: '${({ tooltipContent }) => tooltipContent}';
        position: absolute;
        right: auto;
        left: auto;
        margin: auto;
        bottom: -40px;
        background: #39393a;
        padding: 2px;
        border-radius: 10px;
        color: white;
        font-size: 14px;
        font-weight: 400;
        padding: 9px;
        white-space: nowrap;
    }
    img,
    svg {
        height: 20px;
        width: auto;
        path {
            fill: ${({ theme }) => theme.accent} !important;
        }
    }
    &.icon-button {
        height: 54px;
        width: 54px;
        margin-left: auto;
        @media (max-width: ${({ theme }) => theme.devices.desktop}) {
            width: calc(100% - 15px);
            margin-left: 15px;
        }
    }
    ${({ tooltipContent }) =>
        tooltipContent &&
        styled.button`
            &:hover {
                &:before {
                    visibility: visible;
                    opacity: 1;
                }
            }
            &:before {
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s, opacity 0.2s ease-in;
                opacity: 0;
                content: '${tooltipContent}';
                position: absolute;
                right: auto;
                left: auto;
                margin: auto;
                bottom: -40px;
                background: #39393a;
                padding: 2px;
                border-radius: 10px;
                color: white;
                font-size: 14px;
                font-weight: 400;
                padding: 7px;
            }
        `}
`;

export const IconButtonAlt = styled.button`
    width: 42px;
    height: 42px;
    border: transparent;
    box-sizing: border-box;
    border-radius: 8px;
    background: ${({ theme }) => theme.accent}0f;
    &:hover {
        background-color: ${({ theme }) => theme.semiWhite};
    }
    img,
    svg {
        height: 20px;
        width: auto;
        path {
            fill: ${({ theme }) => theme.accent} !important;
        }
    }
`;

export const IconButtonAlt2 = styled.button`
    width: 168px;
    height: 36px;
    border: 1px solid ${({ theme }) => theme.purple75};
    box-sizing: border-box;
    display: flex;
    align-items: center;
    border-radius: 8px;
    position: relative;
    padding-left: 12px;
    background: transparent;
    &:after {
        position: absolute;
        content: '';
        top: calc(50% - 2.5px);
        right: 10px;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid ${({ theme }) => theme.accent};
    }
    &:hover {
        background-color: ${({ theme }) => theme.semiWhite};
    }
    svg {
        max-width: 14px;
        max-height: 14px;

        path {
            fill: ${({ theme }) => theme.accent} !important;
        }
    }
    div {
        font-size: 13px;
        line-height: 25px;
        color: ${({ theme }) => theme.black};
        font-weight: normal;
        span {
            padding-left: 5px;
            cursor: default;
            font-size: 13px;
            line-height: 25px;
            color: ${({ theme }) => theme.grey200};
        }
    }

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        width: 42px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0;
        &:after {
            display: none;
        }
        &:hover {
            background-color: ${({ theme }) => theme.semiWhite};
        }
        svg {
            max-width: 14px;
            max-height: 14px;

            path {
                fill: ${({ theme }) => theme.accent} !important;
            }
        }
        .filter-labels {
            display: none;
        }
    }
`;
