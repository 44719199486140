import { LocationAutocomplete } from 'medrefer-web-sdk/api/models';
import { LoadedOptionsPage, Option } from 'medrefer-web-sdk/web-kit';
import { api } from 'medrefer-web-sdk/api';

const useLocationAutocompleteOptions = () => {
    const toOption = (location: LocationAutocomplete) => ({
        label: location.name,
        value: location.place_id,
    });

    const loadLocationAutocompleteOptions = async (
        search: string,
        loadedOptions: Option[],
    ): Promise<LoadedOptionsPage> => {
        search = search.replace('/', ' ');
        const res = await api.getLocations({
            limit: 10,
            offset: loadedOptions.length,
            search,
        });

        return {
            options: res.map(toOption),
            hasMore: false,
        };
    };

    const setAddressProps = (
        propsSetter: (propName: string, propValue: string) => void,
        option: Option,
        cityPropName = 'city',
        streetPropName = 'street',
        postalCodePropName = 'post_code',
    ) => {
        option.label.replace('/', ', ');
        api.getLocationPlace(option.value.toString()).then((place) => {
            propsSetter(cityPropName, place.address.city);
            propsSetter(streetPropName, place.address.text);
            propsSetter(postalCodePropName, place.address.postal_code);
        });
    };

    return {
        setAddressProps,
        loadLocationAutocompleteOptions,
    };
};

export default useLocationAutocompleteOptions;
