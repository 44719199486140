import { useState, useCallback, useLayoutEffect } from 'react';

export type NodeCallback = (node: HTMLElement | null) => void;

type ElementSizeHook = [NodeCallback, DOMRect | null];

interface UseDimensionsArgs {
    liveMeasure?: boolean;
    initialTimeout?: number;
}

export const useElementSize = ({ liveMeasure = true, initialTimeout = 0 }: UseDimensionsArgs = {}): ElementSizeHook => {
    const [dimensions, setDimensions] = useState<DOMRect | null>(null);
    const [node, setNode] = useState<HTMLElement | null>(null);
    const ref = useCallback((node: HTMLElement | null) => {
        setNode(node);
    }, []);

    useLayoutEffect(() => {
        if (node) {
            const measure = () => {
                window.requestAnimationFrame(() => setDimensions(node.getBoundingClientRect()));
            };
            setTimeout(measure, initialTimeout);

            if (liveMeasure) {
                window.addEventListener('resize', measure);

                return () => {
                    window.removeEventListener('resize', measure);
                };
            }
        }
    }, [node, liveMeasure, initialTimeout]);

    return [ref, dimensions];
};
