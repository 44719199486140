import { getAuthUser } from 'features/auth/selectors';
import { UserRole } from 'medrefer-web-sdk/api/models';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IJitsiMeetExternalApi } from '@jitsi/react-sdk/lib/types';

type VideoCallStatus = 'jitsiLoading' | 'waitingRoom' | 'infoScreen' | 'ongoing' | 'callEnded';

export const useVideoCall = () => {
    const user = useSelector(getAuthUser);
    const [jitsiApi, setJitsiApi] = useState<IJitsiMeetExternalApi | null>(null);
    const [videoCallStatus, setVideoCallStatus] = useState<VideoCallStatus>('jitsiLoading');
    useEffect(() => {
        if (jitsiApi) {
            jitsiApi.executeCommand('toggleTileView');

            jitsiApi.addListener(`tileViewChanged`, ({ enabled }: { enabled: boolean }) => {
                if (!enabled) {
                    jitsiApi.executeCommand('toggleTileView');
                }
            });

            jitsiApi.addListener('errorOccurred', () => {
                setVideoCallStatus('infoScreen');
            });

            jitsiApi.addListener(`videoConferenceJoined`, () => {
                const participants = jitsiApi.getParticipantsInfo();
                if (participants.length == 1 && user === undefined) {
                    setVideoCallStatus('waitingRoom');
                } else {
                    if (participants.length == 1 && user?.role === UserRole.patient) {
                        setVideoCallStatus('waitingRoom');
                    } else {
                        setVideoCallStatus('ongoing');
                    }
                }
            });

            jitsiApi.addListener(`participantJoined`, () => {
                const participants = jitsiApi.getParticipantsInfo();
                if (participants.length == 0 && user?.role === UserRole.patient) {
                    setVideoCallStatus('waitingRoom');
                } else {
                    setVideoCallStatus('ongoing');
                }
            });

            jitsiApi.addListener('readyToClose', () => {
                setVideoCallStatus('callEnded');
            });
        }
    }, [jitsiApi, user]);

    const jitsiConfig = {
        disableProfile: false,
        hideEmailInSettings: true,
        prejoinConfig: { enabled: false },
        toolbarButtons: ['settings', 'hangup', 'microphone', 'camera', 'fullscreen', 'chat'],
        connectionIndicators: { disableDetails: true },
        hideConferenceSubject: true,
        hideConferenceTimer: true,
        startWithVideoMuted: true,
        readOnlyName: true,
        disablePolls: true,
        deeplinking: {
            disabled: true,
        },
        disableSelfViewSettings: true,
        remoteVideoMenu: {
            disableKick: true,
            disableGrantModerator: true,
            disableRemoteMute: true,
        },
    };

    const jitsiInterfaceConfig = {
        LANG_DETECTION: true,
        VIDEO_QUALITY_LABEL_DISABLED: true,
        MAXIMUM_ZOOMING_COEFFICIENT: 1,
        SETTINGS_SECTIONS: ['devices'],
        TOOLBAR_ALWAYS_VISIBLE: true,
    };

    return { jitsiConfig, jitsiInterfaceConfig, videoCallStatus, setJitsiApi };
};
