import styled from 'styled-components';
import { Col } from 'react-bootstrap';
import { theme } from 'medrefer-web-sdk/web-kit';

export const LeftCol = styled(Col)`
    background-color: ${({ theme }) => theme.purple50};
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        display: none;
    }
`;

export const RightCol = styled(Col)`
    background-color: ${({ theme }) => theme.white};
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: column;
    padding-top: 10vh;
`;

export const FormBox = styled.div`
    width: 400px;

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        max-width: 100%;
        margin: 10px;
    }
`;

export const PageTitle = styled.h1`
    font-weight: 800;
    font-size: 25px;
    line-height: 36px;
    color: #000000;
    padding-bottom: 20px;
    max-width: 400px;
`;

export const pageStyles = {
    container: {
        height: '100vh',
        width: '100vw',
    },
    label: { paddingBottom: 11 },
    btn: {
        background: theme.accent,
        borderRadius: '8px',
        width: 200,
        height: 48,
        marginBottom: 23,
        marginTop: 32,
    },
    message: {
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.175px',
        color: '#767785',
        marginRight: 7,
    },
    message_cta: {
        fontSize: '14px',
        lineHeight: '19px',
        letterSpacing: '0.175px',
        color: '#24252F',
    },
    formBox: {
        maxWidth: 336,
        margin: '20%',
    },
};
