import styled from 'styled-components';

export const ServiceColumnsStyled = styled.div<{
    isDailyView: boolean;
    columnsNumber: number;
}>`
    display: ${({ isDailyView }) => (isDailyView ? 'inline-flex' : 'none')};
    background: white;
    border-bottom: 1px solid rgb(229, 229, 229);
    margin-left: 71px;
    width: calc(100% - 71px);
    .service-column {
        display: inline-block;
        width: ${({ columnsNumber }) => 100 / columnsNumber}%;
        min-width: 200px;
        text-align: center;
        border-right: 1px solid rgb(229, 229, 229);
        padding: 10px;
        font-weight: 600;
        position: relative;
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        &:nth-child(1) {
            border-left: 1px solid rgb(229, 229, 229);
        }

        span {
            position: absolute;
            display: block;
        }
    }

    .column-line {
        position: absolute;
        top: 0;
        width: 1px;
        height: calc(100% + 68px);
        background: rgb(229, 229, 229);
        z-index: 10;
    }
`;
