export interface EmailText {
    language: string;
    param_name: string;
    content: string;
}

export interface EmailTextProps {
    supported_languages: string[];
    email_text_pl: string;
    email_text_en: string;
    email_text_de: string;
}

export enum Language {
    en = 'en',
    de = 'de',
    pl = 'pl',
}

export enum EmailTemplateParamName {
    footer_content = 'footer_content',
}

export const useEmailText = () => {
    const GetEmailTextArray = (values: EmailTextProps): EmailText[] => {
        const email_text: EmailText[] = [];

        if (values.supported_languages.includes('pl')) {
            email_text.push({
                language: Language.pl,
                param_name: EmailTemplateParamName.footer_content,
                content: values.email_text_pl,
            });
        }

        if (values.supported_languages.includes('en')) {
            email_text.push({
                language: Language.en,
                param_name: EmailTemplateParamName.footer_content,
                content: values.email_text_en,
            });
        }

        if (values.supported_languages.includes('de')) {
            email_text.push({
                language: Language.de,
                param_name: EmailTemplateParamName.footer_content,
                content: values.email_text_de,
            });
        }

        return email_text;
    };

    return {
        GetEmailTextArray,
    };
};
