import { CheckBoxLabel, ErrorMessage, FormGroup, Label } from '../../../../../medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import React, { ReactNode } from 'react';

interface Props {
    children: ReactNode;
    isError: boolean;
    errorMessage: string | undefined;
    fieldName: string;
    fieldLabel: string;
    fields: string[];
    mandatory_fields: string[];
    isCheckMark?: boolean;
    colClassName?: string;
    rowClassName?: string;
    placeLabelNextToField?: boolean;
    isBasicField?: boolean;
}
export const OptionalField = ({
    children,
    isError,
    fieldName,
    mandatory_fields,
    errorMessage,
    fieldLabel,
    fields,
    isCheckMark = false,
    colClassName = 'ml-auto mr-auto',
    rowClassName = 'primary',
    isBasicField = false,
    placeLabelNextToField = false,
}: Props) => {
    if (isBasicField) {
        return (
            <>
                {(fields.includes(fieldName) || mandatory_fields.includes(fieldName)) && (
                    <>
                        {placeLabelNextToField ? (
                            <Col xs={12} md={4}>
                                <Label isRequired={mandatory_fields.includes(fieldName)}>{fieldLabel}</Label>
                            </Col>
                        ) : (
                            <Label isRequired={mandatory_fields.includes(fieldName)}>{fieldLabel}</Label>
                        )}
                        {children}
                        {isError && <ErrorMessage className="text-left mt-1">{errorMessage}</ErrorMessage>}
                    </>
                )}
            </>
        );
    }

    if (isCheckMark) {
        return (
            <>
                {(fields.includes(fieldName) || mandatory_fields.includes(fieldName)) && (
                    <>
                        <Row className={rowClassName}>
                            <Col xs={12} md={12} className={colClassName}>
                                {children}
                                <CheckBoxLabel isRequired={mandatory_fields.includes(fieldName)}>
                                    {fieldLabel}
                                </CheckBoxLabel>
                            </Col>
                        </Row>
                        {isError && <ErrorMessage className="text-left mt-1">{errorMessage}</ErrorMessage>}
                    </>
                )}
            </>
        );
    }

    return (
        <>
            {(fields.includes(fieldName) || mandatory_fields.includes(fieldName)) && (
                <>
                    {placeLabelNextToField ? (
                        <Col xs={12} md={4}>
                            <Label isRequired={mandatory_fields.includes(fieldName)}>{fieldLabel}</Label>
                        </Col>
                    ) : (
                        <Label isRequired={mandatory_fields.includes(fieldName)}>{fieldLabel}</Label>
                    )}
                    <FormGroup>
                        <Row className={rowClassName}>
                            <Col xs={12} md={12} className={colClassName}>
                                {children}
                            </Col>
                        </Row>
                        {isError && <ErrorMessage className="text-left mt-1">{errorMessage}</ErrorMessage>}
                    </FormGroup>
                </>
            )}
        </>
    );
};
