import React from 'react';
import { Message, MobileNotAvailableContainer } from './MobileNotAvailable.styled';
import { useTranslation } from 'react-i18next';

export const MobileNotAvailable = () => {
    const { t } = useTranslation();

    return (
        <MobileNotAvailableContainer fluid>
            <Message>
                <span>{t('global:mobileNotAvailable')}</span>
            </Message>
        </MobileNotAvailableContainer>
    );
};
