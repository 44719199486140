import { ErrorMessage, FormGroup, Label, Option, RadioTile } from '../../../../../medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import Select from '../../../../../medrefer-web-sdk/web-kit/components/formComponents/Select/Select';
import { ActionMeta, SingleValue } from 'react-select';
import React, { useState } from 'react';
import { RequestWaitingRoomProps, RequestWaitingRoomValues } from '../RequestWaitingRoomForm.types';
import { FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { Weekday } from '../../../../../medrefer-web-sdk/api/models';
import { EDayOptionsType, useDayOptions } from '../useDayOptions';

interface Props {
    props: RequestWaitingRoomProps & FormikProps<RequestWaitingRoomValues>;
    canSubmit: boolean;
}
export const DayTimeList = ({ props, canSubmit }: Props) => {
    const { t } = useTranslation();
    const [reload, SetReload] = useState(false);
    const { dayTimeNoneOption, dayTimeOptions, dayOptionsLong, GetDayIndex } = useDayOptions();
    const PickDay = (day: Weekday) => {
        props.setFieldTouched('pickedDays', true);
        const pickedDays = props.values.pickedDays;
        props.values.pickedDays = pickedDays.includes(day)
            ? pickedDays.filter((item) => item != day)
            : [...props.values.pickedDays, day];
        SetReload(!reload);
    };
    const IsPicked = (day: Weekday): boolean => {
        return props.values.pickedDays.includes(day);
    };

    const GetDayOption = (day: Weekday): Option => {
        let option: Option = dayTimeOptions[0];
        dayTimeOptions.forEach((o: Option<string>): void => {
            const index = GetDayIndex(day, EDayOptionsType.Long);
            if (index === -1) return;

            if (o.value === props.values.pickedDayTimes[index]) {
                option = o;
                return;
            }
        });
        return option;
    };

    const SetDayOption = (
        newValue: SingleValue<Option<string>>,
        actionMeta: ActionMeta<Option<string>>,
        day: Weekday,
    ) => {
        const index = GetDayIndex(day, EDayOptionsType.Long);
        if (index === -1 || newValue === null) return;

        props.values.pickedDayTimes[index] = newValue.value;
        SetReload(!reload);
    };

    return (
        <>
            <Label isRequired>{t('requestWaitingRoom:appointment_day_label')}</Label>
            <FormGroup>
                {dayOptionsLong.map((option, key) => (
                    <Row key={key}>
                        <Col xs={6} md={6} className="mt-2">
                            <RadioTile
                                style={{ borderRadius: 1 }}
                                id={option.label}
                                disabled={canSubmit}
                                value={option.label}
                                checked={IsPicked(option.value)}
                                unselectable={'on'}
                                readOnly={false}
                                onChange={() => {
                                    return null;
                                }}
                                onClick={() => PickDay(option.value)}
                                title={option.label}
                            />
                        </Col>
                        <Col xs={6} md={6} className="mt-3">
                            {IsPicked(option.value) && (
                                <Select
                                    name={'type'}
                                    isDisabled={canSubmit}
                                    value={GetDayOption(option.value)}
                                    onChange={(
                                        newValue: SingleValue<Option<string>>,
                                        actionMeta: ActionMeta<Option<string>>,
                                    ) => SetDayOption(newValue, actionMeta, option.value)}
                                    placeholder={dayTimeNoneOption.label}
                                    options={dayTimeOptions}
                                    isSearchable={false}
                                />
                            )}
                        </Col>
                    </Row>
                ))}
                {props.touched.pickedDays && props.values.pickedDays.length === 0 && (
                    <ErrorMessage className="text-left mt-1">
                        {t('requestWaitingRoom:required_picked_days')}
                    </ErrorMessage>
                )}
            </FormGroup>
        </>
    );
};
