import { unified } from 'unified';
import { PhrasingContent } from 'mdast';
import remarkParse from 'remark-parse';

import { NoSlotsBehaviour } from 'medrefer-web-sdk/api/models';
import { NoSlotsAction, NoSlotsDisplay, NoSlotsSubFormValues } from './NoSlotsBehaviourSubForm.types';
import { toMarkdown } from 'mdast-util-to-markdown';

export const parseNoSlotsBehaviourToForm = (values?: NoSlotsBehaviour | null): NoSlotsSubFormValues => {
    if (values == null) {
        return {
            displayCalendar: NoSlotsDisplay.default,
            message: '',
            actionType: NoSlotsAction.none,
            actionLink: '',
            actionLinkText: '',
            actionEmail: '',
            actionEmailText: '',
            actionPhone: '',
            actionPhoneText: '',
        };
    }
    if (values.waiting_room) {
        return {
            displayCalendar: NoSlotsDisplay.waiting,
            message: '',
            actionType: NoSlotsAction.none,
            actionLink: '',
            actionLinkText: '',
            actionEmail: '',
            actionEmailText: '',
            actionPhone: '',
            actionPhoneText: '',
        };
    }
    return {
        displayCalendar: values.display_calendar ? NoSlotsDisplay.display : NoSlotsDisplay.hidden,
        ...parseNoSlotsMessageToValues(values.message),
    };
};

const parseNoSlotsMessageToValues = (noSlotsMessage: string) => {
    const result = {
        message: noSlotsMessage,
        actionType: NoSlotsAction.none,
        actionLink: '',
        actionLinkText: '',
        actionEmail: '',
        actionEmailText: '',
        actionPhone: '',
        actionPhoneText: '',
    };

    try {
        const processor = unified().use(remarkParse);
        const parsed = processor.parse(noSlotsMessage);
        const content = parsed.children[0];

        if (content && content.type === 'paragraph' && content.children.length >= 1 && content.children.length <= 2) {
            const textContent = content.children[0];
            const linkContent = content.children[1];

            if (textContent?.type === 'text') {
                result.message = textContent.value;
            }

            if (linkContent?.type === 'link') {
                const linkTextContent = linkContent.children[0];
                let actionText = '';
                if (linkTextContent?.type === 'text') {
                    actionText = linkTextContent.value;
                }
                if (linkContent.url.startsWith('mailto:')) {
                    result.actionType = NoSlotsAction.email;
                    result.actionEmail = linkContent.url.substring(7);
                    result.actionEmailText = actionText;
                } else if (linkContent.url.startsWith('tel:')) {
                    result.actionType = NoSlotsAction.phone;
                    result.actionPhone = linkContent.url.substring(4);
                    result.actionPhoneText = actionText;
                } else {
                    result.actionType = NoSlotsAction.link;
                    result.actionLink = linkContent.url;
                    result.actionLinkText = actionText;
                }
            }
        }
    } catch (err) {}

    return result;
};

export const parseFormToNoSlotsBehaviour = (values: NoSlotsSubFormValues): NoSlotsBehaviour | null => {
    if (values.displayCalendar === NoSlotsDisplay.default) {
        return null;
    }
    if (values.displayCalendar === NoSlotsDisplay.display) {
        return {
            waiting_room: false,
            display_calendar: true,
            message: serializeNoSlotsMessage(values),
        };
    }
    if (values.displayCalendar === NoSlotsDisplay.waiting) {
        return {
            display_calendar: true,
            waiting_room: true,
            message: serializeNoSlotsMessage(values),
        };
    }
    return {
        waiting_room: false,
        display_calendar: false,
        message: '',
    };
};

const serializeNoSlotsMessage = (values: NoSlotsSubFormValues) => {
    let message = values.message.trim() + ' ';
    let actionText = '';
    let actionUrl = null;

    switch (values.actionType) {
        case NoSlotsAction.link:
            actionText = values.actionLinkText || values.actionLink;
            actionUrl = values.actionLink;
            break;
        case NoSlotsAction.email:
            actionText = values.actionEmailText || values.actionEmail;
            actionUrl = 'mailto:' + values.actionEmail;
            break;
        case NoSlotsAction.phone:
            actionText = values.actionPhoneText || values.actionPhone;
            actionUrl = 'tel:' + values.actionPhone;
            break;
        default:
            message = message.trim();
    }

    const contents: PhrasingContent[] = [
        {
            type: 'text',
            value: message,
        },
    ];

    if (actionUrl) {
        contents.push({
            type: 'link',
            url: actionUrl,
            children: [{ type: 'text', value: actionText }],
        });
    }

    return toMarkdown({
        type: 'root',
        children: [
            {
                type: 'paragraph',
                children: contents,
            },
        ],
    });
};
