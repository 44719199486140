import axios from 'axios';

const UNAUTHORIZED = 401;

export const setupUnauthorizedHook = (handler: () => void) => {
    axios.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response) {
                const { status } = error.response;
                if (status === UNAUTHORIZED) {
                    handler();
                }
            }
            return Promise.reject(error);
        },
    );
};
