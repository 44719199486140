import { useNonInitialEffect, useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import { parseEnumValue } from 'medrefer-web-sdk/web-kit/utils/stringHelpers';
import { SortTypes } from 'medrefer-web-sdk/api/models';
import { useMemo, useState } from 'react';
import { AppointmentRequestStatus } from '../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';

const defaultFilters = {
    search: '',
    sort: SortTypes.dateAsc,
    statuses: [AppointmentRequestStatus.PENDING],
};

export const useRequestListFilters = () => {
    const queryParams = useQueryParams();
    const search = queryParams.get('search') || defaultFilters.search;
    const sort = parseEnumValue<SortTypes>(SortTypes, queryParams.get('sort')?.toUpperCase()) || defaultFilters.sort;
    const statusParams = queryParams.getAll('status').map((value) => {
        return (
            parseEnumValue<AppointmentRequestStatus>(AppointmentRequestStatus, value) ||
            AppointmentRequestStatus.PENDING
        );
    });
    const [statuses, setStatuses] = useState<AppointmentRequestStatus[]>(
        statusParams.length > 0 ? statusParams : defaultFilters.statuses,
    );
    useNonInitialEffect(() => {
        setStatuses(statusParams);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(statusParams)]);

    return useMemo(() => {
        return {
            search,
            sort,
            statuses,
            setStatuses,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [statuses]);
};
