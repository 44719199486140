import React from 'react';
import { AdminPanelContainer, FormActivityIndicatorContainer } from 'components/AdminPanel/AdminPanel.styled';
import { useManageLocations } from 'components/AdminPanel/ManageLocations/ManageLocations.hook';
import { columns } from './ManageLocations.consts';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';

import { ConfirmModalBody } from 'medrefer-web-sdk/web-kit/';
import { DataTable } from 'components/shared/DataTable';
import { LocationsForm } from './LocationsForm';
import { ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { RouteBreadcrumbs } from 'components/shared/RouteBreadcrumbs';

export const ManageLocations = () => {
    const { t } = useTranslation();
    const {
        deleteModalState,
        createLocation,
        updateLocation,
        deleteLocation,
        request,
        activeItem,
        fetchActiveItem,
        ...rest
    } = useManageLocations();

    return (
        <>
            <AdminPanelContainer>
                <DataTable
                    title={t('adminPanel:manageLocations.title')}
                    actionColumnTitle={t('adminPanel:table.columns.actions')}
                    noItemsMessage={t('adminPanel:table.no_items_message')}
                    columns={columns(t)}
                    addItemForm={<LocationsForm type={ModalType.add} request={request} handleSubmit={createLocation} />}
                    editItemForm={
                        activeItem ? (
                            <LocationsForm
                                activeItem={activeItem}
                                type={ModalType.edit}
                                request={request}
                                handleSubmit={updateLocation}
                            />
                        ) : (
                            <FormActivityIndicatorContainer>
                                <ActivityIndicator />
                            </FormActivityIndicatorContainer>
                        )
                    }
                    deleteModalState={deleteModalState}
                    deleteItemForm={
                        activeItem ? (
                            <ConfirmModalBody
                                title={t('adminPanel:manageLocations.deleteForm.title')}
                                message={t('adminPanel:manageLocations.deleteForm.message')}
                                confirmButton={t('adminPanel:manageLocations.deleteForm.accept_button')}
                                declineButton={t('adminPanel:manageLocations.deleteForm.decline_button')}
                                onConfirm={deleteLocation}
                                onDecline={deleteModalState.toggleModal}
                            />
                        ) : (
                            <ActivityIndicator />
                        )
                    }
                    setActiveItem={fetchActiveItem}
                    addButtonText={t('adminPanel:table.controls.addButtonText')}
                    previousPageText={t('adminPanel:table.controls.previousPageText')}
                    nextPageText={t('adminPanel:table.controls.nextPageText')}
                    breadcrumbs={<RouteBreadcrumbs />}
                    {...rest}
                />
            </AdminPanelContainer>
        </>
    );
};
