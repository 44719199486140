import React from 'react';
import { ActivityIndicator, Timer } from 'medrefer-web-sdk/web-kit';
import { JitsiContainer, InfoScreen, VideoContainer, VideoCallAppointmentDetails } from './VideoCall.styled';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { JitsiMeeting } from '@jitsi/react-sdk';
import { getFullName } from 'medrefer-web-sdk/api/utils';
import { AppointmentDetails } from 'components/shared/AppointmentDetailsModal/AppointmentDetailsModal';
import { useVideoCall } from './hooks/useVideoCall';
import { useAppointmentData } from './hooks/useAppointmentData';

export const VideoCall = () => {
    const { t } = useTranslation();
    const { appointment, appointmentIncorrect, videoCall } = useAppointmentData();
    const { jitsiConfig, jitsiInterfaceConfig, videoCallStatus, setJitsiApi } = useVideoCall();
    const initTimer = Math.floor(Math.abs(moment().diff(appointment?.date_to) / 1000));

    return (
        <VideoContainer>
            {appointment && (
                <JitsiContainer visible={videoCallStatus === 'ongoing'}>
                    <Timer timeLeft={initTimer} />
                    {videoCall && (
                        <JitsiMeeting
                            domain={videoCall.host}
                            // appId={videoCall.app_id}
                            roomName={videoCall.room}
                            jwt={videoCall.jwt}
                            configOverwrite={jitsiConfig}
                            interfaceConfigOverwrite={jitsiInterfaceConfig}
                            onApiReady={setJitsiApi}
                        />
                    )}
                </JitsiContainer>
            )}
            {videoCallStatus === 'waitingRoom' && (
                <InfoScreen>
                    <h1>{t('telemedicine:waitingroom.title')}</h1>
                    {appointment && appointment.managing_user && (
                        <h2>
                            {getFullName(appointment.managing_user)} {t('telemedicine:waitingroom.subtitle')}
                        </h2>
                    )}
                </InfoScreen>
            )}
            {videoCallStatus === 'infoScreen' && appointment && (
                <InfoScreen>
                    <VideoCallAppointmentDetails>
                        <AppointmentDetails appointment={appointment} />
                    </VideoCallAppointmentDetails>
                </InfoScreen>
            )}
            {videoCallStatus === 'callEnded' && (
                <InfoScreen>
                    <h2>Call ended</h2>
                </InfoScreen>
            )}
            {appointmentIncorrect ? (
                <InfoScreen>
                    <h2>{t('telemedicine:no_configuration')}</h2>
                </InfoScreen>
            ) : (
                videoCallStatus === 'jitsiLoading' && <ActivityIndicator absoluteCenter />
            )}
        </VideoContainer>
    );
};
