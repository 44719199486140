import { OrganizationLocation } from 'medrefer-web-sdk/api/models';
import { LoadedOptionsPage, Option } from 'medrefer-web-sdk/web-kit';
import { api } from 'medrefer-web-sdk/api';

const useOrganizationLocationOptions = () => {
    const toOption = (location: OrganizationLocation) => ({
        label: location.name,
        value: location.name,
    });

    const loadOrganizationLocationOptions = async (
        search: string,
        loadedOptions: Option[],
    ): Promise<LoadedOptionsPage> => {
        const res = await api.getOrganizationLocations({
            search,
            limit: 10,
            offset: loadedOptions.length,
            has_healthcare_services: true,
        });

        return {
            options: res.results.map(toOption),
            hasMore: false,
        };
    };

    const recoverOrganizationLocationOptions = async (missingKeys: string[]) => {
        const res = await api.getOrganizationLocations({
            id__in: missingKeys.join(','),
        });
        return res.results.map(toOption);
    };

    return {
        loadOrganizationLocationOptions,
        recoverOrganizationLocationOptions,
    };
};

export default useOrganizationLocationOptions;
