import React from 'react';
import { FormGroup, Input, Label, UnderlineH2 as Title, SwitchInput, TextGrey } from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getTranslationConfigForField } from 'utils/translation';
import useBrand from 'hooks/useBrand';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';
import { FormikBaseProps } from './SlotsCriteriaForm';

export const SlotsCriteriaBaseData = ({ baseProps }: FormikBaseProps) => {
    const { t } = useTranslation();
    const { organization } = useBrand();

    return (
        <>
            <Title>
                {baseProps.type === ModalType.add
                    ? t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.title_add')
                    : t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.title_edit')}
            </Title>
            <FormGroup>
                <Row>
                    {getTranslationConfigForField(
                        'name',
                        t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.criteria_name_label'),
                        organization,
                    ).map(({ label, fieldName }) => (
                        <Col key={fieldName} xs={12} md={6} className="mb-3">
                            <Label isRequired>{label}</Label>
                            <Input
                                isError={false}
                                id={fieldName}
                                name={fieldName}
                                value={baseProps.values[fieldName]}
                                type="text"
                                onChange={baseProps.handleChange}
                                onBlur={baseProps.handleBlur}
                            />
                        </Col>
                    ))}
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={6} md={4}>
                        <Label style={{ marginTop: 0 }}>
                            {t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.multiple_selection_label')}
                        </Label>
                    </Col>

                    <Col xs={6} md={8}>
                        <SwitchInput
                            id="multiple_selection"
                            isChecked={baseProps.values.multiple_selection}
                            handleChange={baseProps.handleChange}
                        />
                    </Col>
                    <Col>
                        <TextGrey>
                            {t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.multiple_selection_help_text')}
                        </TextGrey>
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={6} md={4}>
                        <Label style={{ marginTop: 0 }}>
                            {t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.required_label')}
                        </Label>
                    </Col>

                    <Col xs={6} md={8}>
                        <SwitchInput
                            id="required"
                            isChecked={baseProps.values.required}
                            handleChange={baseProps.handleChange}
                        />
                    </Col>
                    <Col>
                        <TextGrey>{t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.required_help_text')}</TextGrey>
                    </Col>
                </Row>
            </FormGroup>
        </>
    );
};
