import { AgreementsWRProps, AgreementsWRValues } from './AgreementsWRForm.types';
import { FormikProps } from 'formik';
import React, { useEffect } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { Button, CheckBox, CheckBoxLabel, ErrorMessage, H1 } from '../../../../../medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAuthUser, getBrand } from '../../../../../features/auth/selectors';
import { PrivacyPolicyNote } from '../../../../PrivacyPolicy';
import { UserPatient } from '../../../../../medrefer-web-sdk/api/models';
import { useDayOptions } from '../useDayOptions';
import { AgreementsData } from '../../../AgreementsInfo/AgreementsData';
import { EOptionalFields, useOptionalFields } from '../useOptionalFields';

export const AgreementsWRData = (props: AgreementsWRProps & FormikProps<AgreementsWRValues>) => {
    const { t } = useTranslation();
    const authUser = useSelector(getAuthUser);
    const organization = useSelector(getBrand);
    const { isVisible } = useOptionalFields(organization);
    const { GetDayTime, dayOptionsLong } = useDayOptions();

    useEffect(() => {
        props.values.user = authUser;
        if (props.values.requestWaitingRoomFields !== undefined && authUser !== undefined) {
            props.values.requestWaitingRoomFields.phone = authUser.phone;
            props.setFieldValue('phone', authUser.phone);
            if (
                props.touched.smsAgreement === undefined &&
                props.touched.emailAgreement === undefined &&
                authUser.patient !== undefined
            ) {
                const userPatient: UserPatient = authUser.patient;
                props.setFieldValue('smsAgreement', userPatient.sms_agreement);
                props.setFieldValue('emailAgreement', userPatient.email_agreement);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser]);

    const getDayAndTimeArray = (): string[] => {
        const dayTimeArray: string[] = [];
        const days = props.values.requestWaitingRoomFields?.pickedDays;
        if (days === undefined) return dayTimeArray;
        for (let i = 0; i < days.length; i++) {
            if (days[i] === 'None') continue;

            const dayTime = GetDayTime(days[i]);
            dayTimeArray.push(dayOptionsLong[i].label + ': ' + dayTime);
        }
        return dayTimeArray;
    };

    return (
        <Form
            onSubmit={(e) => {
                props.values.user = authUser;
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <H1 className="mb-4">{t('slotBooking:confirmation')}</H1>
            <AgreementsData
                managing_user={props.healthService?.managing_user}
                health_service={props.healthService}
                agreements_data_picked_day_time_array={getDayAndTimeArray}
                message={props.requestWaitingRoomFields?.message}
                attachments={props.requestWaitingRoomFields?.attachDocumentHandler?.attachments}
            />
            {/*<OptionalField
                isError={!!(props.errors.smsAgreement && props.touched.smsAgreement)}
                errorMessage={'add key here'}
                fieldName={'phone'}
                fieldLabel={t('newAppointmentForm:checkbox_text1')}
                fields={props.fields}
                mandatory_fields={props.mandatory_fields}
                colClassName={'d-flex align-items-start'}
                isCheckMark={true}
            >
                <CheckBox
                    id="smsAgreement"
                    isError={props.touched.smsAgreement && props.errors.smsAgreement}
                    checked={props.values.smsAgreement}
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        props.handleChange(e);
                    }}
                    className="mr-4"
                />
            </OptionalField>*/}
            {isVisible(EOptionalFields.phone) && (
                <Row>
                    <Col className="d-flex align-items-start" xs={12}>
                        <CheckBox
                            id="smsAgreement"
                            isError={props.touched.smsAgreement && props.errors.smsAgreement}
                            checked={props.values.smsAgreement}
                            onChange={(e: React.FormEvent<HTMLInputElement>) => {
                                props.handleChange(e);
                            }}
                            className="mr-4"
                        />
                        <CheckBoxLabel htmlFor="smsAgreement" className={'ml-2'}>
                            {t('newAppointmentForm:checkbox_text1')}
                        </CheckBoxLabel>
                    </Col>
                </Row>
            )}
            <Row>
                <Col className="d-flex align-items-start" xs={12}>
                    <CheckBox
                        id="emailAgreement"
                        checked={props.values.emailAgreement}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            props.handleChange(e);
                        }}
                        className="mr-4"
                    />
                    <CheckBoxLabel htmlFor="emailAgreement" className={'ml-2'}>
                        {t('newAppointmentForm:checkbox_text2')}
                    </CheckBoxLabel>
                </Col>
            </Row>
            <Row>
                <Col className="d-flex align-items-start" xs={12}>
                    <CheckBox
                        isError={props.touched.termsAgreement && props.errors.termsAgreement}
                        id="termsAgreement"
                        disable
                        checked={props.values.termsAgreement}
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                            props.handleChange(e);
                            //props.setFieldTouched('termsAgreement', true);
                        }}
                        className="mr-4"
                    />
                    <CheckBoxLabel htmlFor="termsAgreement" isRequired className={'ml-2'}>
                        <PrivacyPolicyNote />
                    </CheckBoxLabel>
                </Col>
            </Row>
            {props.errors.termsAgreement && (
                <ErrorMessage className="text-left">{props.errors.termsAgreement}</ErrorMessage>
            )}
            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" style={{ marginTop: '20px' }} xs={12} md={6}>
                        <Button
                            isLoading={props.isLoading}
                            disabled={props.errors.termsAgreement !== undefined || !props.values.termsAgreement}
                            style={
                                props.errors.termsAgreement === undefined && props.values.termsAgreement
                                    ? {}
                                    : {
                                          backgroundColor: 'lightgray',
                                          borderColor: 'lightgray',
                                          boxShadow: '0px 20px 25px lightgray',
                                      }
                            }
                            type="submit"
                        >
                            {t('newAppointmentForm:button_confirm_message')}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
};
