import { Option } from 'medrefer-web-sdk/web-kit';
import { api } from 'medrefer-web-sdk/api';
import { User } from 'medrefer-web-sdk/api/models';
import { getFullName } from 'medrefer-web-sdk/api/utils';

const useOrganizationPatientsOptions = () => {
    const toOrganizationPatientOption = (patient: User) => ({
        value: patient.id.toString(),
        label: `${getFullName(patient)} (${patient.email})`,
    });

    const loadOrganizationPatientsOptions = async (search: string, loadedOptions: Option[]) => {
        const res = await api.getOrganizationPatients({
            limit: 10,
            offset: loadedOptions.length,
            search,
        });

        return {
            options: res.results.map(toOrganizationPatientOption),
            hasMore: res.next != null,
        };
    };

    return {
        toOrganizationPatientOption,
        loadOrganizationPatientsOptions,
    };
};

export default useOrganizationPatientsOptions;
