import { FormProps } from './RegisterForm.types';
import * as Yup from 'yup';
import { RegistrationStep } from 'medrefer-web-sdk/api/models';
import { FormikBag } from 'formik';
import { RegisterFormValues } from './RegisterForm.types';

export const mapPropsToValues = (props: FormProps): RegisterFormValues => ({
    firstName: '',
    lastName: '',
    personalTitle: '',
    email: '',
    password: '',
    phone: '',
    token: '',
    step: RegistrationStep.base,
    confirm_password: '',
    fields: props.fields,
    mandatory_fields: props.mandatory_fields,
    date_of_birth: '',
    insurance_number: '',
    insurance_status: '',
    sms_agreement: false,
    email_agreement: false,
    post_code: '',
    city: '',
    street: '',
    terms_agreement: false,
    patient: {
        date_of_birth: '',
    },
});

export const validationSchema = ({ t }: FormProps) =>
    Yup.object().shape({
        email: Yup.string()
            .max(50, t('loginForm:warning_login_max'))
            .email(t('loginForm:warning_login_matches'))
            .required(t('loginForm:warning_email_required')),
        password: Yup.string()
            .matchesPassword(
                t('registerForm:warning_password_min', { min: 8 }),
                t('registerForm:warning_password_too_simple'),
            )
            .required(t('loginForm:warning_password_required')),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('password'), ''], t('newAppointmentForm:warning_password_confirmation'))
            .required(t('newAppointmentForm:warning_password_confirmation')),
        firstName: Yup.string()
            .matchesName(t('newAppointmentForm:warning_first_name_matches'))
            .required(t('newAppointmentForm:warning_first_name_required')),
        lastName: Yup.string()
            .matchesName(t('newAppointmentForm:warning_last_name_matches'))
            .required(t('newAppointmentForm:warning_last_name_required')),
        phone: Yup.string().when(['mandatory_fields', 'fields'], {
            is: (mandatory_fields, fields) => mandatory_fields.includes('phone') || fields.includes('phone'),
            then: Yup.string()
                .matchesPhone(t('newAppointmentForm:warning_phone_matches'))
                .when('mandatory_fields', {
                    is: (mandatory_fields) => mandatory_fields.includes('phone'),
                    then: Yup.string().required(t('newAppointmentForm:warning_phone_required')),
                }),
        }),
        date_of_birth: Yup.date()
            .typeError(t('newAppointmentForm:warning_date_of_birth_type'))
            .required(t('newAppointmentForm:warning_date_of_birth_required')),
        street: Yup.string().when(['mandatory_fields'], {
            is: (mandatory_fields) => mandatory_fields.includes('insurance_status'),
            then: Yup.string().required(t('newAppointmentForm:warning_address_required')),
        }),
        insurance_status: Yup.string().when(['mandatory_fields'], {
            is: (mandatory_fields) => mandatory_fields.includes('insurance_status'),
            then: Yup.string().required(t('newAppointmentForm:warning_insurance_status_required')),
        }),
        insurance_number: Yup.string().when(['mandatory_fields'], {
            is: (mandatory_fields) => mandatory_fields.includes('insurance_number'),
            then: Yup.string().required(t('newAppointmentForm:warning_insurance_number_required')),
        }),
        token: Yup.string().when('step', {
            is: RegistrationStep.otpCode,
            then: Yup.string().required(t('otp:warning_code_required')),
        }),
        terms_agreement: Yup.bool().oneOf([true], t('newAppointmentForm:warning_terms_agreement')),
    });

export const handleSubmit = (
    values: RegisterFormValues,
    { props, setSubmitting, setTouched, setFieldValue }: FormikBag<FormProps, RegisterFormValues>,
) => {
    const goNextStep = () => {
        setSubmitting(false);
        setTouched({});
        setFieldValue('step', values.step + 1);
    };

    // YUP object validation is written badly, that's why we need this code
    values.patient.date_of_birth = values.date_of_birth;
    values.patient.street = values.street;
    values.patient.city = values.city;
    values.patient.post_code = values.post_code;
    values.patient.sms_agreement = values.sms_agreement;
    values.patient.email_agreement = values.email_agreement;
    values.patient.insurance_number = values.insurance_number;
    values.patient.insurance_status = values.insurance_status;

    switch (values.step) {
        case RegistrationStep.base:
            props.submitCredentials(values).then((exists: boolean) => {
                if (!exists) {
                    goNextStep();
                }
            });
            break;
        case RegistrationStep.otpCode:
            props.submitOtp(values);
            break;
    }
};
