import { SlotMode } from 'medrefer-web-sdk/api/models';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import { useTranslation } from 'react-i18next';

interface ModeOption {
    label: string;
    value: string;
}

const useSlotModeOptions = (
    setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void,
) => {
    const { t } = useTranslation();
    const queryParams = useQueryParams();

    const modeParam = queryParams.get('mode');

    const handleModeChange = (option: ModeOption) => {
        setFieldValue('mode', option.value);
    };

    const modeOptions = [
        {
            label: t('calendar:mode.onsite'),
            value: SlotMode.ONSITE,
        },
        {
            label: t('calendar:mode.online'),
            value: SlotMode.ONLINE,
        },
    ];

    const modeParamOption = modeOptions.find((option) => option.value === modeParam);

    return {
        modeOptions,
        handleModeChange,
        modeParamOption,
    };
};

export default useSlotModeOptions;
