import React from 'react';
import DatePicker from 'react-datepicker';
import { useDatePicker } from './DatePicker.hook';
import { FullWidthWrapper } from './DatePicker.styled';

// TODO: Add component interface
//eslint-disable-next-line @typescript-eslint/no-explicit-any
export const DatePickerField = (props: any) => {
    const { inputRef, isError, setFieldValue, field, fullWidthClass, handleKeyDown } = useDatePicker(props);
    return (
        <FullWidthWrapper timeGrid={props.timeGrid} showDropdownArrow={props.showDropdownArrow}>
            <DatePicker
                id={props.name}
                className={isError ? `error ${fullWidthClass}` : `${fullWidthClass}`}
                minDate={new Date(1900, 0, 1)}
                dateFormat={['dd.MM.yyyy', 'd.M.yy', 'dMyy', 'ddMMyy']}
                {...field}
                {...props}
                selected={field.value}
                onChange={(val) => {
                    setFieldValue(field.name, val);
                }}
                locale={props.i18n.language.substring(0, 2)}
                ref={inputRef}
                onKeyDown={(e) => handleKeyDown(e)}
            />
            {props.showDropdownArrow && (
                <div className="Select__indicators css-1hb7zxy-IndicatorsContainer datePicker-dropdown-indicator">
                    <span className="Select__indicator-separator css-1okebmr-indicatorSeparator"></span>
                    <div
                        className="Select__indicator Select__dropdown-indicator css-tlfecz-indicatorContainer"
                        aria-hidden="true"
                    >
                        <svg
                            height="20"
                            width="20"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                            className="css-tj5bde-Svg"
                        >
                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                        </svg>
                    </div>
                </div>
            )}
        </FullWidthWrapper>
    );
};
