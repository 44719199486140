import EditIcon from 'assets/EditIcon.svg';
import useApiRequest from 'hooks/useApiRequest';
import useBrand from 'hooks/useBrand';
import { CustomIconButton, ProfileDisplayContainer } from 'components/Profile/Profile.styled';
import ProfileAttribute from 'components/shared/ProfileAttribute';
import { setBrand } from 'features/appointment/actions';
import { userSelectors } from 'features/auth';
import { api } from 'medrefer-web-sdk/api';
import { OrganizationProfileFormData } from 'medrefer-web-sdk/api/models';
import { ExternalLink, Modal, UnderlineH2 as TitleUnderline, useModalState } from 'medrefer-web-sdk/web-kit';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import { errorTimeout } from 'utils/constants';
import { OrganizationProfileForm } from './OrganizationProfileForm';
import { getPath } from 'router-paths';

const ModalButton = function (): JSX.Element {
    return (
        <CustomIconButton>
            <ReactSVG src={EditIcon} />
        </CustomIconButton>
    );
};

export const OrganizationProfile: React.FC = () => {
    const authUser = useSelector(userSelectors.getAuthUser);
    const { organization } = useBrand();
    const updateProfileRequest = useApiRequest({ errorTimeout });
    const { t } = useTranslation();
    const modal = useModalState();
    const dispatch = useDispatch();
    if (!authUser) {
        return null;
    }

    const updateOrganizationProfile = (data: OrganizationProfileFormData) => {
        updateProfileRequest.dispatch(api.updateOrganizationProfile(data)).then(() => {
            organization && dispatch(setBrand(organization?.slug));
            modal.toggleModal();
        });
    };

    return (
        <div className="pl-md-5 pr-md-5 pb-md-5 pt-md-3 pt-0">
            <div className="w-full d-flex justify-content-center mt-md-5">
                <ProfileDisplayContainer>
                    <Modal
                        state={modal}
                        isCentered={false}
                        button={<ModalButton />}
                        className="position-absolute"
                        body={
                            organization && (
                                <OrganizationProfileForm
                                    onFormSubmit={updateOrganizationProfile}
                                    activeItem={organization}
                                    request={updateProfileRequest}
                                />
                            )
                        }
                    />
                    <TitleUnderline style={{ padding: '20px 0' }}>
                        {t('organizationProfileDisplay:organization_profile_label')}
                    </TitleUnderline>
                    <Row className="mb-5 mr-0 ml-0">
                        <Col xs={12} md={6}>
                            <ProfileAttribute
                                label={t('organizationProfileDisplay:organization_name_label')}
                                value={organization?.name}
                            />
                            <ProfileAttribute
                                label={t('organizationProfileDisplay:organization_description_label')}
                                value={organization?.description}
                            />
                            <ProfileAttribute
                                label={t('organizationProfileDisplay:organization_image_label')}
                                value={organization?.branding?.logo ? ' ' : ' - '}
                            />
                            {organization?.branding?.logo && (
                                <img
                                    alt={'organization profile'}
                                    style={{ maxHeight: '100px' }}
                                    src={organization?.branding.logo.file}
                                />
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="mr-auto">
                            <div>
                                <ExternalLink href={getPath('privacyPolicy')}>
                                    {t('organizationProfileDisplay:privacy_policy_link')}
                                </ExternalLink>
                            </div>
                        </Col>
                    </Row>
                </ProfileDisplayContainer>
            </div>
        </div>
    );
};
