import React, { useState } from 'react';
import { AppointmentModel, AppointmentStatus } from 'medrefer-web-sdk/api/models';
import { RedButton, UnderlineH2 as Title, PrimaryButton, ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { ButtonsContainer, SpinnerContainer } from './EditAppointment.styled';
import { CancelAppointmentModalBody } from 'components/Dashboard/Appointment/AppointmentHeader/ActionMenu/CancelAppointmentModalBody';
import { AttachDocuments } from 'components/Dashboard/Appointment/AppointmentHeader/ActionMenu/AttachDocuments';
import { RescheduleAppointment } from 'components/shared/RescheduleAppointment';
import { useMyAppointmentsActions } from 'hooks/useMyAppointmentsActions';
import { AttachQuestionnaire } from 'components/Dashboard/Appointment/AppointmentHeader/ActionMenu/AttachQuestionnaire';
import useBrand from 'hooks/useBrand';

interface Props {
    activeItem: AppointmentModel | null;
    toggleModal: () => void;
    reloadAppointments: () => void;
}

export const EditAppointment = ({ activeItem, toggleModal, reloadAppointments }: Props) => {
    const [showCancelAppointment, setShowCancelAppointment] = useState(false);
    const [showAttachDocuments, setShowAttachDocuments] = useState(false);
    const [showAttachQuestionnaire, setShowAttachQuestionnaire] = useState(false);
    const { t } = useTranslation();
    const { featureEnabled } = useBrand();

    const { rescheduleAppointmentModalState, rescheduleAppointment, isAppointmentRescheduleLoading } =
        useMyAppointmentsActions(reloadAppointments);

    const isBooked = activeItem && activeItem.status === AppointmentStatus.BOOKED;
    const isAppointmentBookedInFuture = isBooked && new Date(activeItem.date_from) > new Date();

    if (!activeItem) {
        return (
            <SpinnerContainer>
                <ActivityIndicator />
            </SpinnerContainer>
        );
    }
    return (
        <>
            {!showCancelAppointment && !showAttachDocuments && !showAttachQuestionnaire && (
                <div>
                    <Title>{t('editMenu:title')}</Title>
                    <ButtonsContainer>
                        <PrimaryButton onClick={() => setShowAttachDocuments(true)} inverseColors>
                            {t('editMenu:attachDocuments')}
                        </PrimaryButton>
                        {featureEnabled('questionnaires') && (
                            <PrimaryButton onClick={() => setShowAttachQuestionnaire(true)} inverseColors>
                                {t('editMenu:attachQuestionnaire')}
                            </PrimaryButton>
                        )}
                        {isAppointmentBookedInFuture && (
                            <RescheduleAppointment
                                appointmentId={activeItem.id}
                                modalState={rescheduleAppointmentModalState}
                                rescheduleAppointment={rescheduleAppointment}
                                isReschedulingAppointment={isAppointmentRescheduleLoading}
                                button={<PrimaryButton>{t('editMenu:reschedule')}</PrimaryButton>}
                            />
                        )}
                        {isBooked && (
                            <RedButton onClick={() => setShowCancelAppointment(true)}>{t('editMenu:cancel')}</RedButton>
                        )}
                    </ButtonsContainer>
                </div>
            )}
            {showCancelAppointment && (
                <CancelAppointmentModalBody activeListItem={activeItem} toggleModal={toggleModal} />
            )}
            {showAttachDocuments && <AttachDocuments toggleModal={toggleModal} appointment={activeItem} />}
            {showAttachQuestionnaire && <AttachQuestionnaire appointment={activeItem} toggleModal={toggleModal} />}
        </>
    );
};
