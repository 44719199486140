import styled from 'styled-components';
import { CheckBox as StyledCheckBox, CheckBoxLabel } from 'medrefer-web-sdk/web-kit';
import CheckBoxIcon from 'assets/CheckBoxIcon.svg';

export const Title = styled.div`
    font-weight: 800;
    font-size: 10px;
    line-height: 14px;
    color: ${({ theme }) => theme.black};
    border-bottom: #eaeaf4 1px solid;
    width: 100%;
    padding-bottom: 5px;
    margin-bottom: 14px;
`;

export const Amount = styled.div`
    font-size: 13px;
    line-height: 25px;
    color: #9d9eb2;
    text-align: right;
    margin: 3px 0;
`;

export const CheckBox = styled(StyledCheckBox)`
    box-sizing: border-box;
    color: red;
    visibility: hidden !important;
    position: relative;
    cursor: pointer;
    margin-top: -2px;

    &:before {
        transition: 0.1s cubic-bezier(0.01, 1.06, 0.85, 0.51);
        border-radius: 4px;
        box-sizing: border-box;
        content: '';
        visibility: visible;
        position: absolute;
        top: 0;
        left: 0;
        width: 22px;
        height: 22px;
        border: 1px solid #dbdcee;
    }

    &:after {
        transition: 0.1s cubic-bezier(0.01, 1.06, 0.85, 0.51);
        position: absolute;
        top: 0;
        left: 0;
        width: 22px;
        height: 22px;
        visibility: visible;
        opacity: 0;
        content: '';
        background-size: 50%;
        background-image: url(${CheckBoxIcon});
        filter: brightness(0);
        background-repeat: no-repeat;
        background-position: 50% 0%;
    }

    &:checked {
        &:before {
            border: 1px solid ${({ theme }) => theme.accent};
        }

        &:after {
            opacity: 1;
            background-position: center;
        }
    }

    &[disabled] {
        cursor: default;

        :before {
            background-color: #eaeaea;
        }
    }
`;

export const Label = styled(CheckBoxLabel)`
    color: ${({ theme }) => theme.black} !important;
    display: flex;
    font-size: 13px !important;
    line-height: 25px;
    margin: 6px 0;
    cursor: pointer;
    min-width: 180px;
`;
