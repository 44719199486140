import styled from 'styled-components';

export const ButtonsContainer = styled.div`
    display: flex;
    gap: 30px;
    margin: 15px 0;
    position: relative;
    align-items: center;
    z-index: 10;
    button {
        width: 100px;
    }
    > div {
        width: 200px;
    }
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        gap: 0;
    }
    span {
        font-size: 20px;
        font-weight: 600;
        padding-top: 4px;
    }
`;
interface GraeyOutlineButtonProps {
    isLarge?: boolean;
}

export const GreyOutlineButton = styled.button<GraeyOutlineButtonProps>`
    border: 1px solid ${({ theme }) => theme.purple100};
    box-sizing: border-box;
    border-radius: 8px;
    background: transparent;
    width: ${(props) => (props.isLarge ? '215px' : '200px')};
    height: ${(props) => (props.isLarge ? '48px' : '40px')};
    display: block;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.black};
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }
    &[disabled] {
        border: 1px solid #999999;
        background-color: #cccccc;
        color: #666666;
        cursor: no-drop;
    }
`;
