import styled from 'styled-components';

export const FormContentContainer = styled.div`
    width: 750px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }
    div {
        min-height: 0 !important;
    }
`;
