import React from 'react';

import { Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { SearchInput } from 'medrefer-web-sdk/web-kit';
import { useDelayedState, useNonInitialEffect, useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';

interface Props {
    initialSearch: string;
}

export const SidebarListMenuSearch = ({ initialSearch }: Props) => {
    const queryParams = useQueryParams();
    const { t } = useTranslation();
    const [search, setSearch, delayedSearch] = useDelayedState(initialSearch);

    useNonInitialEffect(() => {
        queryParams.set('search', delayedSearch);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delayedSearch]);

    return (
        <div className="w-100">
            <Form onSubmit={(e) => e.preventDefault()}>
                <Form.Group>
                    <SearchInput
                        id="search"
                        placeholder={t('sidebar:search_placeholder')}
                        type="text"
                        onChange={(e: { target: HTMLInputElement }) => setSearch(e.target.value)}
                        value={search}
                    />
                </Form.Group>
            </Form>
        </div>
    );
};
