import React from 'react';
import { Cell } from 'react-table';
import { DisplayLabelsArray } from './DisplayLabelsArray';

interface Props {
    cell: Cell;
}

export const DisplayArrayData = ({ cell }: Props) => {
    if (!cell.value) {
        return null;
    }
    const array = Array.isArray(cell.value) ? cell.value : [cell.value];

    return <DisplayLabelsArray labels={array.map((a) => a.name)} />;
};
