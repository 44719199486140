import styled from 'styled-components';
import { SearchInput } from '../../formComponents';

export const TableControlsContainer = styled.div`
    display: flex;
    margin: 20px 0 0 0;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 3;
`;

export const TableFilters = styled.div`
    display: flex;
    .Select__control {
        min-width: 150px;
    }
`;

export const TableSearchInput = styled(SearchInput)`
    margin: 0;
    min-width: 400px;
    border: 1px solid hsl(0, 0%, 80%);
`;

export const Pagination = styled.div`
    display: flex;
    gap: 20px;
`;

export const PaginationNavigation = styled.div`
    display: flex;

    align-items: center;
`;

export const PaginationSettings = styled.div`
    width: 250px;
`;

export const PaginationButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid hsl(0, 0%, 80%);
    background: transparent;
    outline: none;
    cursor: pointer;
    height: 38px;
    transition: all 0.3s ease-out;
    color: ${({ theme }) => theme.accent};
    font-weight: bold;

    &:disabled {
        color: ${({ theme }) => theme.grey200};
        cursor: default;
    }

    &:not(:disabled):hover {
        background-color: ${({ theme }) => theme.purple60};
        color: ${({ theme }) => theme.accent};
    }

    &.left {
        border-radius: 4px 0 0 4px;
    }

    &.right {
        border-radius: 0 4px 4px 0;
        border-left: none;
    }

    &.page {
        border-radius: 0;
        width: 38px;
        border-left: none;
    }

    &.active {
        color: ${({ theme }) => theme.white};
        background-color: ${({ theme }) => theme.accent};
    }
`;
