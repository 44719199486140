import useApiRequest from 'hooks/useApiRequest';
import { PatientRegistrationData } from 'medrefer-web-sdk/api/models';
import { registerPatientUser } from 'features/auth/actions';
import { useDispatch, useSelector } from 'react-redux';
import { errorTimeout, messageTimeout } from 'utils/constants';
import { api } from 'medrefer-web-sdk/api';
import { useTemporaryState } from 'medrefer-web-sdk/web-kit/hooks';
import { formatISODate } from 'medrefer-web-sdk/api/utils';
import moment from 'moment';
import { getBrand } from '../../features/auth/selectors';

const useRegisterFlow = () => {
    const [emailTaken, setEmailTaken] = useTemporaryState(false, errorTimeout);
    const [otpCodeSent, setOtpCodeSent] = useTemporaryState(false, messageTimeout);
    const request = useApiRequest({ errorTimeout });
    const dispatch = useDispatch();
    const organization = useSelector(getBrand);

    const submitCredentials = async (data: PatientRegistrationData) => {
        const exists = await request.dispatch(api.checkEmailTaken(data.email));
        if (exists) {
            setEmailTaken(true);
        } else {
            await request.dispatch(api.requestOtp(data.email, organization?.id));
        }
        return exists;
    };

    const submitOtp = async (data: PatientRegistrationData) => {
        await request.dispatch(
            dispatchRegisterPatientUser({
                email: data.email,
                password: data.password,
                firstName: data.firstName,
                lastName: data.lastName,
                phone: data.phone,
                token: data.token,
                personalTitle: data.personalTitle,
                patient: {
                    date_of_birth: formatISODate(moment(data.patient.date_of_birth)),
                    insurance_number: data.patient.insurance_number,
                    insurance_status: data.patient.insurance_status,
                    city: data.patient.city,
                    street: data.patient.street,
                    post_code: data.patient.post_code,
                },
            }),
        );
        setOtpCodeSent(true);
    };

    const dispatchRegisterPatientUser = async (data: PatientRegistrationData) => {
        await dispatch(registerPatientUser(data));
    };

    return {
        request,
        emailTaken,
        otpCodeSent,
        submitCredentials,
        submitOtp,
    };
};

export default useRegisterFlow;
