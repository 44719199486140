import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { AppointmentModel, Slot } from 'medrefer-web-sdk/api/models';
import { useCalendarActions } from 'components/Calendar/hooks';
import { SchedulePopoverType, ScheduleType } from 'components/Calendar/Calendar.types';
import { api } from 'medrefer-web-sdk/api';
import { canManageSlots } from 'features/auth/rules';
import { Popover } from 'medrefer-web-sdk/web-kit/components/Popover';
import { SchedulePopover } from 'components/Calendar/SchedulePopover';
import { SlotViewPopover } from 'components/Calendar/SlotViewPopover';
import { AppointmentPopover } from 'components/Calendar/AppointmentPopover';
import { ActiveSchedule } from 'components/Calendar/SchedulePopover/SchedulePopover.types';
import { useSelector } from 'react-redux';
import { getAuthUser } from 'features/auth/selectors';
import { PopoverState } from 'medrefer-web-sdk/web-kit/components/Popover/hooks/usePopoverState';
import { HourRange } from 'components/Calendar/CalendarView/CalendarView.types';

interface Props {
    activeSchedule: ActiveSchedule | null;
    setActiveSchedule: Dispatch<SetStateAction<ActiveSchedule | null>>;
    schedulePopoverType: SchedulePopoverType;
    schedulePopoverState: PopoverState;
    hourRange: HourRange;
    reloadSchedules: () => Promise<void>;
}

export const CalendarActiveSchedule = ({
    activeSchedule,
    setActiveSchedule,
    schedulePopoverType,
    schedulePopoverState,
    reloadSchedules,
    hourRange,
}: Props) => {
    const authUser = useSelector(getAuthUser);
    const [activeSlot, setActiveSlot] = useState<Slot | null>(null);
    const [activeAppointment, setActiveAppointment] = useState<AppointmentModel | null>(null);

    const { request, handleAddSlot, handleDeleteSlot, handleEditSlot } = useCalendarActions(
        activeSchedule,
        reloadSchedules,
        schedulePopoverState,
    );

    const onPopoverCloseAction = () => {
        request.clearErrors();
        setActiveSchedule(null);
    };

    useEffect(() => {
        if (!activeSchedule) {
            setActiveSlot(null);
            setActiveAppointment(null);
            return;
        }
        if (activeSchedule.type === ScheduleType.appointment && !isNaN(parseInt(activeSchedule.id))) {
            api.getAppointment(parseInt(activeSchedule.id)).then(setActiveAppointment);
        } else if (activeSchedule.type === ScheduleType.slot && activeSchedule.id) {
            api.getOrganizationSlot(activeSchedule.id).then(setActiveSlot);
        }
    }, [activeSchedule]);

    const closePopoverAndReloadAppointments = () => {
        schedulePopoverState.togglePopover();
        reloadSchedules();
    };

    if (!authUser) return null;

    return (
        <>
            {activeSchedule?.type === ScheduleType.slot && canManageSlots(authUser) && (
                <Popover
                    isLoading={schedulePopoverType === SchedulePopoverType.edit && !activeSlot}
                    state={schedulePopoverState}
                    body={
                        <SchedulePopover
                            type={schedulePopoverType}
                            request={request}
                            activeSchedule={activeSchedule}
                            activeSlot={activeSlot}
                            handleSubmit={
                                schedulePopoverType !== SchedulePopoverType.create ? handleEditSlot : handleAddSlot
                            }
                            deleteSlot={handleDeleteSlot}
                            hourRange={hourRange}
                            closePopoverAndReloadAppointments={closePopoverAndReloadAppointments}
                        />
                    }
                    onCloseAction={onPopoverCloseAction}
                />
            )}
            {activeSchedule?.type === ScheduleType.slot && !canManageSlots(authUser) && (
                <Popover
                    isLoading={!activeSlot}
                    state={schedulePopoverState}
                    body={
                        activeSlot && (
                            <SlotViewPopover
                                activeSlot={activeSlot}
                                closePopoverAndReloadAppointments={closePopoverAndReloadAppointments}
                            />
                        )
                    }
                    onCloseAction={onPopoverCloseAction}
                />
            )}
            {activeSchedule?.type === ScheduleType.appointment && (
                <Popover
                    isLoading={!activeAppointment}
                    state={schedulePopoverState}
                    body={
                        activeAppointment && (
                            <AppointmentPopover
                                activeAppointment={activeAppointment}
                                reloadAppointments={reloadSchedules}
                                schedulePopoverState={schedulePopoverState}
                            />
                        )
                    }
                    onCloseAction={onPopoverCloseAction}
                />
            )}
        </>
    );
};
