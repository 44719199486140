import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Button, InformationBubble, InformationBubbleTypes } from 'medrefer-web-sdk/web-kit';
import { AppointmentModel, AppointmentStep, AppointmentStepType } from 'medrefer-web-sdk/api/models';
import { useTranslation } from 'react-i18next';
import { usePaymentCheckout } from 'components/plugins/StripePayments/usePaymentCheckout';

interface Props {
    appointment: AppointmentModel;
    step: AppointmentStep<AppointmentStepType.stripePayment>;
}

export const PaymentMethodForm = ({ appointment, step }: Props) => {
    const { t } = useTranslation('plugins');
    const { checkoutError, isCheckoutLoading, previousAttemptFailed, redirectToCheckout } = usePaymentCheckout(
        step.step_details,
    );

    return (
        <>
            <Row>
                <Col md={6} xs={12} className="mx-auto" style={{ maxWidth: 400 }}>
                    {previousAttemptFailed && (
                        <InformationBubble type={InformationBubbleTypes.warning}>
                            {t('stripePayment.previous_attempt_failed')}
                        </InformationBubble>
                    )}
                </Col>
            </Row>
            {checkoutError && (
                <Row>
                    <Col md={4} xs={12} className="mx-auto">
                        <InformationBubble type={InformationBubbleTypes.warning}>{checkoutError}</InformationBubble>
                    </Col>
                </Row>
            )}
            <Row>
                <Col xl={2} md={3} sm={4} xs={12} className="mt-3 mb-4 mx-auto">
                    <Button onClick={redirectToCheckout} isLoading={isCheckoutLoading}>
                        {t('stripePayment.button_label', { amount: appointment.price?.amount_formatted })}
                    </Button>
                </Col>
            </Row>
        </>
    );
};
