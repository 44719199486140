import * as Yup from 'yup';
import { FormikBag } from 'formik';
import {
    RequestWaitingRoomProps,
    RequestWaitingRoomValues,
    SubmitWaitingRoomProps,
} from './RequestWaitingRoomForm.types';
import { LoginStep, PatientRegistrationData, UserPatientData, Weekday } from '../../../../medrefer-web-sdk/api/models';
import { numberToWeekday } from '../../../Calendar/SchedulePopover/RecurringOptionField/helpers/rrule';
import { SlotBookingStep } from '../../../PublicSearch/SlotBooking/SlotBooking.types';
import { AppointmentRequestTime } from '../../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';

export const mapPropsToValues = (props: RequestWaitingRoomProps): RequestWaitingRoomValues => {
    return {
        confirmPassword: '',
        dateOfBirth: null,
        firstName: '',
        insuranceNumber: '',
        insuranceStatus: '',
        lastName: '',
        personalTitle: '',
        otpCodeError: false,
        email: props.user !== undefined ? props.user.email : '',
        phone: props.user !== undefined ? props.user.phone : '',
        message: '',
        pickedDays: [],
        pickedDayTimes: ['Mrn', 'Mrn', 'Mrn', 'Mrn', 'Mrn'],
        files: [],
        password: '',
        step: SlotBookingStep.base,
        token: '',
        passwordError: false,
        isLoading: false,
        isAuthorized: props.user !== undefined,
        attachDocumentHandler: undefined,
        fields: props.fields,
        mandatory_fields: props.mandatory_fields,
        street: '',
        city: '',
        form_fields: {
            email: props.user !== undefined ? props.user.email : '',
            message: '',
            phone: props.user !== undefined ? props.user.phone : '',
            pickedDays: [],
            pickedDayTimes: ['Mrn', 'Mrn', 'Mrn', 'Mrn', 'Mrn'],
            password: '',
            files: [],
            step: SlotBookingStep.base,
            token: '',
            passwordError: false,
            otpCodeError: false,
            isLoading: false,
            confirmPassword: '',
            firstName: '',
            dateOfBirth: null,
            lastName: '',
            insuranceStatus: '',
            insuranceNumber: '',
            personalTitle: '',
            isAuthorized: props.user !== undefined,
            attachDocumentHandler: undefined,
            fields: props.fields,
            mandatory_fields: props.mandatory_fields,
            street: '',
            city: '',
        },
    };
};

export const validationSchema = ({ t }: RequestWaitingRoomProps) =>
    Yup.object().shape({
        email: Yup.string()
            .email(t('newAppointmentForm:warning_email_matches'))
            .required(t('newAppointmentForm:warning_email_required')),
        message: Yup.string()
            .min(5, t('newAppointmentForm:warning_message_min'))
            .max(500, t('newAppointmentForm:warning_message_max')),
        phone: Yup.string().when(['step', 'fields', 'mandatory_fields'], {
            is: (step) => [SlotBookingStep.register].includes(step),
            then: Yup.string().when(['mandatory_fields', 'fields'], {
                is: (mandatory_fields, fields) => mandatory_fields.includes('phone') || fields.includes('phone'),
                then: Yup.string()
                    .matchesPhone(t('newAppointmentForm:warning_phone_matches'))
                    .when('mandatory_fields', {
                        is: (mandatory_fields) => mandatory_fields.includes('phone'),
                        then: Yup.string().required(t('newAppointmentForm:warning_phone_required')),
                    }),
            }),
        }),
        insuranceStatus: Yup.string().when(['step', 'fields', 'mandatory_fields'], {
            is: (step) => [SlotBookingStep.register].includes(step),
            then: Yup.string().when('mandatory_fields', {
                is: (mandatory_fields) => mandatory_fields.includes('insurance_status'),
                then: Yup.string().required(t('newAppointmentForm:warning_insurance_status_required')),
            }),
        }),
        insuranceNumber: Yup.string().when(['step', 'fields', 'mandatory_fields'], {
            is: (step) => [SlotBookingStep.register].includes(step),
            then: Yup.string().when('mandatory_fields', {
                is: (mandatory_fields) => mandatory_fields.includes('insurance_number'),
                then: Yup.string().required(t('newAppointmentForm:warning_insurance_number_required')),
            }),
        }),
        city: Yup.string().when(['step', 'fields', 'mandatory_fields'], {
            is: (step) => [SlotBookingStep.register].includes(step),
            then: Yup.string().when('mandatory_fields', {
                is: (mandatory_fields) => mandatory_fields.includes('address'),
                then: Yup.string().required(t('newAppointmentForm:warning_address_required')),
            }),
        }),
        pickedDays: Yup.array().min(1, t('requestWaitingRoom:required_picked_days')),
        password: Yup.string()
            .when('step', {
                is: (step) => [SlotBookingStep.register].includes(step),
                then: Yup.string().matchesPassword(
                    t('registerForm:warning_password_min', { min: 8 }),
                    t('registerForm:warning_password_too_simple'),
                ),
            })
            .when('step', {
                is: (step) => [SlotBookingStep.login, SlotBookingStep.register].includes(step),
                then: Yup.string().required(t('newAppointmentForm:warning_password_required')),
            }),
        confirmPassword: Yup.string().when('step', {
            is: (step) => [SlotBookingStep.register].includes(step),
            then: Yup.string()
                .oneOf([Yup.ref('password'), ''], t('newAppointmentForm:warning_password_confirmation'))
                .required(t('newAppointmentForm:warning_password_confirmation')),
        }),
        firstName: Yup.string()
            .matchesName(t('newAppointmentForm:warning_first_name_matches'))
            .when('step', {
                is: SlotBookingStep.register,
                then: Yup.string().required(t('newAppointmentForm:warning_first_name_required')),
            }),
        lastName: Yup.string()
            .matchesName(t('newAppointmentForm:warning_last_name_matches'))
            .when('step', {
                is: SlotBookingStep.register,
                then: Yup.string().required(t('newAppointmentForm:warning_last_name_required')),
            }),
        dateOfBirth: Yup.date()
            .nullable()
            .when('step', {
                is: SlotBookingStep.register,
                then: Yup.date()
                    .typeError(t('newAppointmentForm:warning_date_of_birth_type'))
                    .required(t('newAppointmentForm:warning_date_of_birth_required')),
            }),
        token: Yup.string().when('step', {
            is: (step) => [SlotBookingStep.loginOtpCode, SlotBookingStep.registerOtpCode].includes(step),
            then: Yup.string().required(t('otp:warning_code_required')),
        }),
    });

const getPatientRegistrationData = (values: RequestWaitingRoomValues): PatientRegistrationData => {
    let birthDate = values.dateOfBirth?.toDateString();
    if (birthDate === undefined) birthDate = '';

    //console.log(values);

    const userPatientData: UserPatientData = {
        date_of_birth: birthDate,
        insurance_number: values.insuranceNumber,
        insurance_status: values.insuranceStatus,
        street: values.street,
        city: values.city,
    };
    return {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        password: values.password,
        patient: userPatientData,
        personalTitle: values.personalTitle,
        phone: values.phone,
        token: values.token,
    };
};

export const handleSubmit = (
    values: RequestWaitingRoomValues,
    { props, setTouched, setFieldValue }: FormikBag<RequestWaitingRoomProps, RequestWaitingRoomValues>,
) => {
    switch (values.step) {
        case SlotBookingStep.login:
            setFieldValue('isLoading', true);
            props
                .submitCredentials({ email: values.email, password: values.password, token: '', step: LoginStep.email })
                .then(() => {
                    setFieldValue('step', SlotBookingStep.loginOtpCode);
                    setFieldValue('passwordError', false);
                    setTouched({});
                })
                .catch(() => {
                    setFieldValue('passwordError', true);
                    setTouched({});
                })
                .finally(() => setFieldValue('isLoading', false));
            return;
        case SlotBookingStep.loginOtpCode:
            setFieldValue('isLoading', true);
            props
                .submitOtp({
                    email: values.email,
                    password: values.password,
                    token: values.token,
                    step: LoginStep.otpCode,
                })
                .then(() => {
                    setFieldValue('step', SlotBookingStep.confirmation);
                    setTouched({});
                    submit(values, props.onFormSubmit);
                })
                .catch(() => {
                    setFieldValue('otpCodeError', true);
                    setTouched({});
                })
                .finally(() => setFieldValue('isLoading', false));
            return;
        case SlotBookingStep.register:
            setFieldValue('isLoading', true);
            props
                .submitCredentials_register(getPatientRegistrationData(values))
                .then(() => {
                    setFieldValue('step', SlotBookingStep.registerOtpCode);
                    setTouched({});
                })
                .catch(() => {
                    setTouched({});
                })
                .finally(() => setFieldValue('isLoading', false));
            return;
        case SlotBookingStep.registerOtpCode:
            setFieldValue('isLoading', true);
            props
                .submitOtp_register(getPatientRegistrationData(values))
                .then(() => {
                    setFieldValue('step', SlotBookingStep.confirmation);
                    setTouched({});
                    submit(values, props.onFormSubmit);
                })
                .catch(() => {
                    setFieldValue('otpCodeError', true);
                    setTouched({});
                })
                .finally(() => setFieldValue('isLoading', false));
            return;
    }

    submit(values, props.onFormSubmit);
};
const submit = (values: RequestWaitingRoomValues, onFormSubmit: (obj: SubmitWaitingRoomProps) => void) => {
    const pickedDays: AppointmentRequestTime[] = [];
    for (let i = 0; i < 5; i++) {
        const weekday: Weekday = numberToWeekday(i);
        const appointmentRequestTime = values.pickedDayTimes[i] as unknown as AppointmentRequestTime; //.toUpperCase()
        const pickedDayTime: AppointmentRequestTime = values.pickedDays.includes(weekday)
            ? appointmentRequestTime
            : AppointmentRequestTime['NONE'];
        pickedDays.push(pickedDayTime);
    }
    pickedDays.push(AppointmentRequestTime['NONE']);
    pickedDays.push(AppointmentRequestTime['NONE']);

    const submitObject: SubmitWaitingRoomProps = {
        email: values.email,
        message: values.message,
        phone: values.phone,
        pickedDays: pickedDays,
        attachDocumentHandler: values.attachDocumentHandler,
    };

    onFormSubmit(submitObject);
};
