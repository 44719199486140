import styled, { css } from 'styled-components';
import { AppointmentStatus } from '../../../api/models';

interface Props {
    type: AppointmentStatus;
}

const cssGreen = css`
    background: #eaf9ec;
    color: #4cc13a;
`;

const cssRed = css`
    background: #ffeef3;
    color: #f92763;
    min-width: 175px;
`;

export const InformationConfirmed = styled.div<Props>`
    display: flex;
    align-items: center;
    border-radius: 8px;
    font-weight: 300;
    height: 42px;
    font-size: 14px;
    line-height: 19px;
    padding: 0 11px;
    span {
        margin-right: 4px;
    }
    > div {
        font-weight: bold;
    }
    svg {
        margin-right: 9.5px;
    }
    ${(props) => props.type === AppointmentStatus.BOOKED && cssGreen}
    ${(props) => props.type === AppointmentStatus.CANCELLED && cssRed}
`;
