import { getAuthUser } from 'features/auth/selectors';
import { api } from 'medrefer-web-sdk/api';
import { AppointmentModel, VideoCallDetails } from 'medrefer-web-sdk/api/models';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

const useAppointmentId = () => {
    const { appointmentId } = useParams<{ appointmentId?: string }>();
    return Number(appointmentId);
};

export const useAppointmentData = () => {
    const queryParams = useQueryParams();
    const token = queryParams.get('token');
    const appointmentId = useAppointmentId();
    const user = useSelector(getAuthUser);
    const [appointment, setAppointment] = useState<AppointmentModel>();
    const [videoCall, setVideoCall] = useState<VideoCallDetails>();
    const [appointmentIncorrect, setAppointmentIncorrect] = useState(false);

    useEffect(() => {
        loadObjects();
        // eslint-disable-next-line
    }, []);

    const loadObjects = async () => {
        try {
            const [appointment, videoCall] = await Promise.all([loadAppointment(), loadVideoCall()]);
            setAppointment(appointment);
            setVideoCall(videoCall);
        } catch (err) {
            setAppointmentIncorrect(true);
        }
    };

    const loadAppointment = () => {
        if (user) {
            return api.getAppointment(appointmentId);
        } else if (token) {
            return api.getAppointmentPatientAccess(appointmentId, token);
        } else {
            throw new Error();
        }
    };

    const loadVideoCall = () => {
        if (user) {
            return api.getAppointmentVideoCall(appointmentId);
        } else if (token) {
            return api.getAppointmentVideoCallPatientAccess(appointmentId, token);
        } else {
            throw new Error();
        }
    };

    return {
        appointment,
        appointmentIncorrect,
        videoCall,
    };
};
