import React from 'react';
import {
    FormGroup,
    FormSelect,
    UnderlineH2 as Title,
    IconButtonAlt,
    Input,
    Label,
    TextGrey,
} from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { FormikBaseProps } from './SlotsCriteriaForm';
import { Field, FieldArray } from 'formik';
import { Col, Row } from 'react-bootstrap';
import { SlotCriteriaType } from 'medrefer-web-sdk/api/models';
import TrashIcon from 'medrefer-web-sdk/web-kit/assets/icons/TrashIcon.png';
import PlusIcon from 'assets/PlusIcon.svg';
import { getDefaultFieldTranslations, getTranslationConfigForField } from 'utils/translation';
import useBrand from 'hooks/useBrand';

export const SlotsCriteriaValuesData = ({ baseProps }: FormikBaseProps) => {
    const { t } = useTranslation();
    const { organization } = useBrand();
    const criteriaTypeOptions = [
        { value: SlotCriteriaType.TEXT, label: t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.type_text') },
        {
            value: SlotCriteriaType.PRACTITIONER,
            label: t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.type_practitioner'),
        },
    ];

    return (
        <>
            <Title className="mb-3 mt-4">
                {t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.criteria_values_title')}
            </Title>
            <TextGrey>{t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.criteria_values_help_text')}</TextGrey>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label isRequired>{t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.type_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Field
                            name="type"
                            component={FormSelect}
                            options={criteriaTypeOptions}
                            isError={baseProps.touched.type && !!baseProps.errors.type}
                            isDisabled={baseProps.activeItem != null}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
            </FormGroup>
            {baseProps.values.type === SlotCriteriaType.PRACTITIONER && (
                <div>{t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.type_practitioner_help_text')}</div>
            )}
            {baseProps.values.type === SlotCriteriaType.TEXT && (
                <FormGroup>
                    <FieldArray
                        name="values"
                        render={(arrayHelpers) => (
                            <div>
                                {baseProps.values.values.map((value, index) => (
                                    <FormGroup key={index}>
                                        <Row>
                                            {getTranslationConfigForField(
                                                'name',
                                                t(
                                                    'adminPanel:manageSlotsCriteria.slotsCriteriaForm.criteria_values_name',
                                                ),
                                                organization,
                                            ).map(({ label, fieldName }) => (
                                                <Col key={fieldName} sm={5} className="mb-3">
                                                    <Label isRequired>{label}</Label>
                                                    <Input
                                                        isError={false}
                                                        id={`values[${index}].${fieldName}`}
                                                        name={`values[${index}].${fieldName}`}
                                                        value={baseProps.values.values[index][fieldName]}
                                                        type="text"
                                                        onChange={baseProps.handleChange}
                                                        onBlur={baseProps.handleBlur}
                                                    />
                                                </Col>
                                            ))}
                                            <Col sm={2} className="d-flex align-items-end mb-3">
                                                <IconButtonAlt type="button" onClick={() => arrayHelpers.remove(index)}>
                                                    <img src={TrashIcon} alt="Delete" />
                                                </IconButtonAlt>
                                            </Col>
                                        </Row>
                                    </FormGroup>
                                ))}
                                <div className="d-flex justify-content-center">
                                    <IconButtonAlt
                                        type="button"
                                        onClick={() => arrayHelpers.push(getDefaultFieldTranslations('name', ''))}
                                    >
                                        <img src={PlusIcon} alt="Add" />
                                    </IconButtonAlt>
                                </div>
                            </div>
                        )}
                    />
                </FormGroup>
            )}
        </>
    );
};
