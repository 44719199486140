import { useQueryParams, useTemporaryState } from 'medrefer-web-sdk/web-kit/hooks';
import { errorTimeout } from 'utils/constants';
import { useState } from 'react';
import { getStripeClient } from 'components/plugins/StripePayments/utils';
import { StripePaymentStepDetails } from 'medrefer-web-sdk/api/models';

export const usePaymentCheckout = (stepDetails: StripePaymentStepDetails) => {
    const [checkoutError, setError] = useTemporaryState<string | undefined>(undefined, errorTimeout);
    const [isCheckoutLoading, setIsLoading] = useState(false);
    const queryParams = useQueryParams();

    const redirectToCheckout = async () => {
        setIsLoading(true);
        const stripe = await getStripeClient();
        stripe
            .redirectToCheckout({
                sessionId: stepDetails.session_id,
            })
            .then((result) => {
                setIsLoading(false);
                setError(result.error?.message);
            });
    };

    return {
        checkoutError,
        isCheckoutLoading,
        redirectToCheckout,
        previousAttemptFailed: queryParams.get('redirect_status') === 'failed',
    };
};
