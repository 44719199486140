import React, { useEffect } from 'react';
import {
    HealthcareServiceSlots,
    AppointmentModel,
    HealthcareServiceSlotsParams,
    SlotSimplified,
    NoSlotsBehaviour,
} from 'medrefer-web-sdk/api/models';
import { Container } from 'components/shared/ServiceSlots/ServiceSlots.styled';
import { SlotsCalendar } from 'components/shared/ServiceSlots/SlotsCalendar';
import { ServiceSlotsHeader } from 'components/shared/ServiceSlots/ServiceSlotsHeader';
import { useCalendarSize, useSlotsCalendar } from 'components/shared/ServiceSlots/SlotsCalendar/SlotsCalendar.hooks';
import { api } from 'medrefer-web-sdk/api';
import { floorDate, maxNullableDate, toNullableDate } from 'utils/dates';

interface Props {
    healthcareService: HealthcareServiceSlots;
    handleSlotClick: (slotKey: string) => void;
    appointment?: AppointmentModel;
    initDateFrom: Date;
    initFetchedDateFrom: Date;
    initFetchedDateTo: Date;
    maxDaysRangeSize: number;
    startFromNearest?: boolean;
    /*
    Parameters that filter slots within the healthcare service and should be
    passed when loading next pagination pages to preserve filtering.
     */
    slotFilterParams: Partial<HealthcareServiceSlotsParams>;
    noSlotsBehaviour: NoSlotsBehaviour;
}

export interface LoadMoreData {
    items: SlotSimplified[];
    nearestDateFrom: Date | null;
}

export const ServiceSlots = ({
    healthcareService,
    handleSlotClick,
    appointment,
    initDateFrom,
    initFetchedDateFrom,
    initFetchedDateTo,
    maxDaysRangeSize,
    startFromNearest,
    slotFilterParams,
    noSlotsBehaviour,
}: Props) => {
    const [calendarRef, daysRangeSize] = useCalendarSize(maxDaysRangeSize);
    const loadMore = async (slotsAfter: Date, slotsBefore: Date): Promise<LoadMoreData> => {
        const page = await api.getHealthcareServiceSlots({
            healthcareServiceKey: healthcareService.key,
            slotsAfter: slotsAfter,
            slotsBefore: slotsBefore,
            ...slotFilterParams,
        });
        if (page.results.length === 1) {
            const item = page.results[0];
            return {
                items: item.slots,
                nearestDateFrom: toNullableDate(item.nearest_slot_date_from),
            };
        }
        return {
            items: [],
            nearestDateFrom: null,
        };
    };

    const { min_slot_date_from, max_slot_date_from, nearest_slot_date_from } = healthcareService;
    const today = floorDate(new Date());
    const dateRangeMin = maxNullableDate(toNullableDate(min_slot_date_from), today);
    const dateRangeMax = toNullableDate(max_slot_date_from);
    const nearestDateFrom = toNullableDate(nearest_slot_date_from);
    const calendar = useSlotsCalendar(
        healthcareService.slots,
        initDateFrom,
        initFetchedDateFrom,
        initFetchedDateTo,
        nearestDateFrom,
        daysRangeSize,
        dateRangeMin,
        dateRangeMax,
        loadMore,
    );

    useEffect(() => {
        if (startFromNearest && !calendar.daysSlots.length) {
            calendar.goNearest();
        }
        // eslint-disable-next-line
    }, []);

    if (calendar.noSlotsInFuture && !noSlotsBehaviour.display_calendar) {
        return null;
    }

    return (
        <Container>
            <ServiceSlotsHeader healthcareService={healthcareService} appointment={appointment} />
            <SlotsCalendar
                calendar={calendar}
                healthcareService={healthcareService}
                calendarRef={calendarRef}
                daysRangeSize={daysRangeSize}
                handleSlotClick={handleSlotClick}
                noSlotsBehaviour={noSlotsBehaviour}
            />
        </Container>
    );
};
