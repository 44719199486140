import React from 'react';
import { ServicesFormProps } from '.';
import { Field, FormikProps } from 'formik';
import { Currency } from 'medrefer-web-sdk/api/models';
import { FormAsyncSelect, FormSelect, Option } from 'medrefer-web-sdk/web-kit';
import { FormGroup, Input, Label, SwitchInput, TextArea, TextGrey } from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useServiceCategoryOptions from 'hooks/options/useServiceCategoryOptions';
import useServiceLocationOptions from 'hooks/options/useServiceLocationOptions';
import { ServiceFormValues } from './ServicesForm.types';
import useBrand from 'hooks/useBrand';

interface ServicesDataProps {
    baseProps: ServicesFormProps & FormikProps<ServiceFormValues>;
}

export const ServicesData = ({ baseProps }: ServicesDataProps) => {
    const { t } = useTranslation();
    const { featureEnabled } = useBrand();
    const { activeItem } = baseProps;
    const { loadServiceCategoryOptions, toServiceCategoryOption } = useServiceCategoryOptions();
    const { loadServiceLocationOptions, toServiceLocationOption } = useServiceLocationOptions();
    const currencyOptions = [
        { value: Currency.EUR, label: Currency.EUR },
        { value: Currency.USD, label: Currency.USD },
        { value: Currency.PLN, label: Currency.PLN },
    ];

    return (
        <>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label isRequired>{t('adminPanel:manageServices.servicesForm.service_name_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <Input
                            isError={baseProps.touched.name && !!baseProps.errors.name}
                            id="name"
                            name="name"
                            value={baseProps.values.name}
                            type="text"
                            onChange={baseProps.handleChange}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={6} md={4}>
                        <Label isRequired> {t('adminPanel:manageServices.servicesForm.categories_label')}</Label>
                    </Col>
                    <Col xs={6} md={8}>
                        <Field
                            name="categories"
                            loadOptions={loadServiceCategoryOptions}
                            initialOptions={activeItem && activeItem.categories.map(toServiceCategoryOption)}
                            afterChange={(option: Option) => baseProps.setFieldValue('categories', [option.value])}
                            component={FormAsyncSelect}
                            placeholder={t('adminPanel:manageServices.servicesForm.categories_placeholder')}
                            isMulti={false}
                            isError={baseProps.touched.categories && !!baseProps.errors.categories}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={6} md={4}>
                        <Label isRequired>{t('adminPanel:manageServices.servicesForm.locations_label')}</Label>
                    </Col>
                    <Col xs={6} md={8}>
                        <Field
                            name="location"
                            loadOptions={loadServiceLocationOptions}
                            initialOptions={activeItem?.location && [toServiceLocationOption(activeItem.location)]}
                            component={FormAsyncSelect}
                            placeholder={t('adminPanel:manageServices.servicesForm.locations_label')}
                            isMulti={false}
                            isError={baseProps.touched.location && !!baseProps.errors.location}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} md={4}>
                        <Label>{t('adminPanel:manageServices.servicesForm.comment_label')}</Label>
                    </Col>
                    <Col xs={12} md={8}>
                        <TextArea
                            isError={baseProps.touched.comment && !!baseProps.errors.comment}
                            id="comment"
                            name="comment"
                            value={baseProps.values.comment}
                            placeholder="Comment"
                            onChange={baseProps.handleChange}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
            </FormGroup>

            {featureEnabled('appointmentPricing') && (
                <>
                    <FormGroup>
                        <Row>
                            <Col xs={6} md={4}>
                                <Label style={{ marginTop: 0 }}>
                                    {t('adminPanel:manageServices.servicesForm.charge_applies_label')}
                                </Label>
                            </Col>
                            <Col xs={6} md={8}>
                                <SwitchInput
                                    id="charge_applies"
                                    isChecked={baseProps.values.charge_applies}
                                    handleChange={baseProps.handleChange}
                                />
                            </Col>
                        </Row>
                    </FormGroup>

                    {baseProps.values.charge_applies && (
                        <FormGroup>
                            <Row>
                                <Col xs={6} md={4}>
                                    <Label isRequired>{t('adminPanel:manageServices.servicesForm.amount_label')}</Label>
                                </Col>
                                <Col xs={6} md={5}>
                                    <Input
                                        isError={false}
                                        id="amount"
                                        name="amount"
                                        value={baseProps.values.amount}
                                        type="text"
                                        onChange={baseProps.handleChange}
                                        onBlur={baseProps.handleBlur}
                                    />
                                </Col>
                                <Col xs={6} md={3}>
                                    <Field
                                        name="amount_currency"
                                        component={FormSelect}
                                        options={currencyOptions}
                                        isError={false}
                                        onBlur={baseProps.handleBlur}
                                    />
                                </Col>
                            </Row>
                        </FormGroup>
                    )}
                </>
            )}

            <FormGroup>
                <Row>
                    <Col xs={6} md={4}>
                        <Label style={{ marginTop: 0 }}>
                            {t('adminPanel:manageServices.servicesForm.is_active_label')}
                        </Label>
                    </Col>
                    <Col xs={6} md={8}>
                        <SwitchInput
                            id="is_active"
                            isChecked={baseProps.values.is_active}
                            handleChange={baseProps.handleChange}
                        />
                    </Col>
                    <Col>
                        <TextGrey>{t('adminPanel:manageServices.servicesForm.is_active_description')}</TextGrey>
                    </Col>
                </Row>
            </FormGroup>

            <FormGroup>
                <Row>
                    <Col xs={6} md={4}>
                        <Label style={{ marginTop: 0 }}>
                            {t('adminPanel:manageServices.servicesForm.requires_assignment_to_access_label')}
                        </Label>
                    </Col>
                    <Col xs={6} md={8}>
                        <SwitchInput
                            id="requires_assignment_to_access"
                            isChecked={baseProps.values.requires_assignment_to_access}
                            handleChange={baseProps.handleChange}
                        />
                    </Col>
                    <Col>
                        <TextGrey>
                            {t('adminPanel:manageServices.servicesForm.requires_assignment_to_access_description')}
                        </TextGrey>
                    </Col>
                </Row>
            </FormGroup>

            <FormGroup>
                <Row>
                    <Col xs={6} md={4}>
                        <Label style={{ marginTop: 0 }}>
                            {t('adminPanel:manageServices.servicesForm.is_video_call_restricted_label')}
                        </Label>
                    </Col>
                    <Col xs={6} md={8}>
                        <SwitchInput
                            id="is_video_call_restricted"
                            isChecked={baseProps.values.is_video_call_restricted}
                            handleChange={baseProps.handleChange}
                        />
                    </Col>
                    <Col>
                        <TextGrey>
                            {t('adminPanel:manageServices.servicesForm.is_video_call_restricted_description')}
                        </TextGrey>
                    </Col>
                </Row>
            </FormGroup>
        </>
    );
};
