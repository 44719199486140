import * as Yup from 'yup';
import { LocationFormValues, LocationsFormProps } from './LocationsForm.types';

export const mapPropsToValues = ({ activeItem }: LocationsFormProps): LocationFormValues => {
    return {
        name: activeItem ? activeItem.name : '',
        description: activeItem ? activeItem.description : '',
        position: {
            latitude: activeItem ? activeItem.position.latitude : 0,
            longitude: activeItem ? activeItem.position.longitude : 0,
        },
        address: {
            text: activeItem ? activeItem.address.text : '',
            city: activeItem ? activeItem.address.city : '',
            district: activeItem ? activeItem.address.district : '',
            state: activeItem ? activeItem.address.state : '',
            postal_code: activeItem ? activeItem.address.postal_code : '',
        },
        addressSelect: activeItem && 'default',
    };
};

export const validationSchema = ({ t }: LocationsFormProps) =>
    Yup.object().shape({
        name: Yup.string().required(t('form:error.required')),
        location: Yup.object().shape({
            latitude: Yup.number(),
            longitude: Yup.string(),
        }),
        addressSelect: Yup.string().required(t('form:error.required')),
    });
