import { HealthcareServiceGroup } from 'medrefer-web-sdk/api/models';
import { Column } from 'react-table';
import { DisplayArrayData } from 'medrefer-web-sdk/web-kit';

export const columns: (t: (key: string) => string) => Column<HealthcareServiceGroup>[] = (t) => [
    {
        Header: t('adminPanel:table.columns.id'),
        accessor: 'id',
        sortParam: 'id',
        width: 80,
    },
    {
        Header: t('adminPanel:table.columns.name'),
        accessor: 'name',
        sortParam: 'name',
    },
    {
        Header: t('adminPanel:table.columns.services'),
        accessor: 'services',
        minWidth: 200,
        Cell: DisplayArrayData,
    },
    {
        Header: t('adminPanel:table.columns.comment'),
        sortParam: 'comment',
        accessor: 'comment',
    },
];
