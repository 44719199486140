import React, { ReactNode, useCallback, useState } from 'react';
import { AdminPanelContainer } from 'components/AdminPanel/AdminPanel.styled';
import { DataTable } from '../../shared/DataTable';
import { columns } from './CalendarTable.consts';
import { useTranslation } from 'react-i18next';
import { useSlotAndAppointments } from './CalendarTable.hook';
import {
    ActivityIndicator,
    ConfirmModalBody,
    IconButtonAlt,
    Modal,
    useModalState,
} from '../../../medrefer-web-sdk/web-kit';
import { CalendarTableMenu } from './CalendarTableMenu/CalendarTableMenu';
import { Schedule } from './CalendarTable.types';
import { SlotPermission } from '../../../medrefer-web-sdk/api/models';
import { EditSlotModalBody } from './EditSlot/EditSlotModal';
import { Row } from 'react-table';
import TrashIcon from '../../../assets/TrashIcon.svg';
import { CalendarTableInfoModal } from './InfoModal/CalendarTableInfoModal';

export const CalendarTable = () => {
    const { t } = useTranslation();
    const {
        unselectAll,
        selectAll,
        checkSchedule,
        makeSelectedPrivate,
        makeSelectedPublic,
        makeSelectedInternal,
        deleteSlot,
        deleteSlots,
        editSlot,
        editModalSlotState,
        completedRequestModalState,
        completedRequestCount,
        requestErrorModalState,
        requestLoadAppointments,
        handlePageSizeChange,
        handleNext,
        handlePrev,
        handleToday,
        formattedDate,
        checkedCount,
        ...rest
    } = useSlotAndAppointments();
    const [activeItem, setActiveItem_] = useState<Schedule | null>(null);
    const deleteModalState = useModalState({ actionOnClose: () => setActiveItem(null) });
    const deleteManyModalState = useModalState({ actionOnClose: () => setActiveItem(null) });
    const editModalAppointmentState = useModalState({ actionOnClose: () => setActiveItem(null) });
    const handleDeleteSlot = () => {
        if (activeItem?.key) {
            deleteSlot(activeItem.key);
        }
        deleteModalState.toggleModal();
    };
    const handleDeleteManySlots = () => {
        deleteSlots();
        deleteManyModalState.toggleModal();
    };
    const setActiveItem = useCallback((item: Schedule | null) => {
        if (item) {
            setActiveItem_(item);
        }
    }, []);
    const handleDeleteMany = () => {
        deleteManyModalState.toggleModal();
    };
    const handleMakePublicOneSlot = async () => {
        if (activeItem && activeItem.key) editSlot(activeItem.key, SlotPermission.PUBLIC);
        editModalAppointmentState.toggleModal();
    };
    const handleMakePrivateOneSlot = () => {
        if (activeItem && activeItem.key) editSlot(activeItem.key, SlotPermission.PRIVATE);
        editModalAppointmentState.toggleModal();
    };
    const handleMakeInternalOneSlot = () => {
        if (activeItem && activeItem.key) editSlot(activeItem.key, SlotPermission.INTERNAL);
        editModalAppointmentState.toggleModal();
    };
    const getExtraActions = useCallback(
        // eslint-disable-next-line @typescript-eslint/ban-types
        (row: Row<object>): undefined | Array<ReactNode> => {
            const rowObject = row.original as Schedule;
            return [
                <IconButtonAlt
                    key={rowObject.id}
                    onClick={() => {
                        deleteModalState.toggleModal();
                        setActiveItem(rowObject);
                    }}
                >
                    <img src={TrashIcon} alt="Button delete icon" />
                </IconButtonAlt>,
            ];
        },
        [setActiveItem, deleteModalState],
    );

    return (
        <>
            <AdminPanelContainer>
                <DataTable
                    title={t('sidebar:tooltip_calendar_table')}
                    actionColumnTitle={t('adminPanel:table.columns.actions')}
                    noItemsMessage={t('adminPanel:table.no_items_message')}
                    columns={columns(t, checkSchedule)}
                    setActiveItem={setActiveItem}
                    previousPageText={t('adminPanel:table.controls.previousPageText')}
                    nextPageText={t('adminPanel:table.controls.nextPageText')}
                    breadcrumbs={
                        <>
                            <CalendarTableMenu
                                handleToday={handleToday}
                                handlePrev={handlePrev}
                                handleNext={handleNext}
                                dateFormatted={formattedDate}
                                handlePageSizeChange={handlePageSizeChange}
                                handleSelectAll={selectAll}
                                handleUnselectAll={unselectAll}
                                editModalSlotState={editModalSlotState}
                                handleDeleteAllSelected={handleDeleteMany}
                                tableParams={rest.tableParams}
                                checkedCount={checkedCount}
                            />
                        </>
                    }
                    getExtraActions={getExtraActions}
                    editModalState={editModalAppointmentState}
                    editItemForm={
                        activeItem ? (
                            <EditSlotModalBody
                                title={t('calendar:editSlotModalTitle')}
                                message={t('calendar:editSlotModalMessage')}
                                makePublicButton={t('calendar:makePublicButton')}
                                onMakePublic={handleMakePublicOneSlot}
                                makePrivateButton={t('calendar:makePrivateButton')}
                                onMakePrivate={handleMakePrivateOneSlot}
                                makeInternalButton={t('calendar:makeInternalButton')}
                                onMakeInternal={handleMakeInternalOneSlot}
                                editModalAppointmentState={editModalAppointmentState}
                            />
                        ) : (
                            <ActivityIndicator />
                        )
                    }
                    {...rest}
                />
            </AdminPanelContainer>
            <Modal
                state={deleteManyModalState}
                body={
                    <ConfirmModalBody
                        title={t('adminPanel:manageSlotsCriteria.deleteForm.title')}
                        message={t('adminPanel:manageSlotsCriteria.deleteForm.message')}
                        confirmButton={t('adminPanel:manageSlotsCriteria.deleteForm.accept_button')}
                        declineButton={t('adminPanel:manageSlotsCriteria.deleteForm.decline_button')}
                        onConfirm={handleDeleteManySlots}
                        onDecline={deleteManyModalState.toggleModal}
                    />
                }
            />
            <Modal
                state={deleteModalState}
                body={
                    activeItem ? (
                        <ConfirmModalBody
                            title={t('adminPanel:manageSlotsCriteria.deleteForm.title')}
                            message={t('adminPanel:manageSlotsCriteria.deleteForm.message')}
                            confirmButton={t('adminPanel:manageSlotsCriteria.deleteForm.accept_button')}
                            declineButton={t('adminPanel:manageSlotsCriteria.deleteForm.decline_button')}
                            onConfirm={handleDeleteSlot}
                            onDecline={deleteModalState.toggleModal}
                        />
                    ) : (
                        <ActivityIndicator />
                    )
                }
            />
            <Modal
                state={editModalSlotState}
                body={
                    <EditSlotModalBody
                        title={t('calendar:editSlotModalTitle')}
                        message={t('calendar:editSlotModalMessage')}
                        makePublicButton={t('calendar:makePublicButton')}
                        onMakePublic={makeSelectedPublic}
                        makePrivateButton={t('calendar:makePrivateButton')}
                        onMakePrivate={makeSelectedPrivate}
                        makeInternalButton={t('calendar:makeInternalButton')}
                        onMakeInternal={makeSelectedInternal}
                        editModalAppointmentState={editModalSlotState}
                    />
                }
            />
            <Modal
                state={completedRequestModalState}
                body={
                    <CalendarTableInfoModal
                        state={completedRequestModalState}
                        count={completedRequestCount}
                        title={t('calendar:calendarTable:finishedInfoModalTitle')}
                        message={t('calendar:calendarTable:finishedInfoModalMessage')}
                        confirmButton={t('calendar:calendarTable:finishedInfoModalButton')}
                    />
                }
            />
            <Modal
                state={requestErrorModalState}
                body={
                    <CalendarTableInfoModal
                        state={requestErrorModalState}
                        count={completedRequestCount}
                        title={t('calendar:calendarTable:finishedInfoModalTitle')}
                        confirmButton={t('calendar:calendarTable:finishedInfoModalButton')}
                        errors={requestLoadAppointments}
                    />
                }
            />
        </>
    );
};
