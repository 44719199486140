import React from 'react';
import { ServiceGroupFormProps } from './ServiceGroupForm.types';
import { Form, FormikProps, withFormik } from 'formik';
import { useTranslation, withTranslation } from 'react-i18next';
import { mapPropsToValues, validationSchema } from './ServiceGroupForm.schema';
import { FormErrorMessage, Button, UnderlineH2 as Title, InformationContainer } from 'medrefer-web-sdk/web-kit';
import { Col, Container, Row } from 'react-bootstrap';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';
import { ServiceGroupManagementData } from 'medrefer-web-sdk/api/models';
import { ServiceGroupData } from './ServiceGroupData';

export const InnerForm = (props: ServiceGroupFormProps & FormikProps<ServiceGroupManagementData>) => {
    const { t } = useTranslation();
    return (
        <>
            <Title>
                {props.type === ModalType.add
                    ? t('adminPanel:manageServiceGroups.serviceGroupForm.title_add')
                    : t('adminPanel:manageServiceGroups.serviceGroupForm.title_edit')}
            </Title>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                }}
            >
                <ServiceGroupData baseProps={props} />
                <Container>
                    <Row>
                        <Col className="m-auto" xs={12} md={12}>
                            <Button isLoading={props.request.isLoading}>
                                {props.type === ModalType.add
                                    ? t('adminPanel:manageServiceGroups.serviceGroupForm.button_add')
                                    : t('adminPanel:manageServiceGroups.serviceGroupForm.button_edit')}
                            </Button>
                            <InformationContainer>
                                <FormErrorMessage apiErrors={props.request.errors} onlyTouched={false} />
                            </InformationContainer>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </>
    );
};

const LocationsForm = withFormik<ServiceGroupFormProps, ServiceGroupManagementData>({
    mapPropsToValues: mapPropsToValues,
    validationSchema: validationSchema,
    handleSubmit: (values, { props }) => {
        props.handleSubmit(values);
    },
})(InnerForm);

export default withTranslation()(LocationsForm);
