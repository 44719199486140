import React from 'react';
import { QuestionnaireItems } from './components';
import {
    BaseQuestionnaireResponseFormProps,
    QuestionnaireResponseFormData,
    QuestionnaireResponseFormProps,
} from './QuestionnaireResponseForm.types';
import { Form, withFormik } from 'formik';
import { useTranslation, withTranslation } from 'react-i18next';
import { handleSubmit, mapPropsToValues } from './QuestionnaireResponseForm.schema';
import { Container, Row, Col } from 'react-bootstrap';
import { Button } from 'medrefer-web-sdk/web-kit';
import { FhirQuestionnaire } from 'medrefer-web-sdk/api/models';

export const InnerForm = (props: BaseQuestionnaireResponseFormProps) => {
    const { t } = useTranslation();
    const fhirQuestionnaireConfig = JSON.parse(props.config) as FhirQuestionnaire;
    return (
        <div>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                }}
            >
                <QuestionnaireItems items={fhirQuestionnaireConfig.item} baseProps={props} readOnly={props.readOnly} />
                <Container style={{ marginTop: 50 }}>
                    <Row>
                        <Col className="m-auto" xs={12} md={12}>
                            <Button>
                                {props.readOnly ? t('questionnaire:close_button') : t('questionnaire:submit_button')}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Form>
        </div>
    );
};

const QuestionnaireResponseForm = withFormik<QuestionnaireResponseFormProps, QuestionnaireResponseFormData>({
    mapPropsToValues,
    handleSubmit,
})(InnerForm);

export default withTranslation()(QuestionnaireResponseForm);
