import styled from 'styled-components';

export const BrandLogo = styled.div`
    a {
        color: #333;
        font-size: 20px;
        font-weight: 600;

        svg {
            transform: translateY(-2px);
        }

        &:hover {
            text-decoration: none;
        }
    }
`;
