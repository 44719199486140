import React, { FC, useMemo } from 'react';
import { StyledBadge } from './StatusBadge.styled';
import { useTranslation } from 'react-i18next';
import {
    AppointmentRequestsModel,
    AppointmentRequestStatus,
} from '../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';

export interface Props {
    appointment: AppointmentRequestsModel;
}

export const StatusBadge: FC<Props> = ({ appointment }: Props) => {
    const { t } = useTranslation();

    const state = useMemo(() => {
        return appointment.status;
    }, [appointment]);

    let badgeText = 'Old value in database';

    switch (state) {
        case AppointmentRequestStatus.PENDING:
            badgeText = t('statusBadge:pending');
            break;
        case AppointmentRequestStatus.RESPONDED:
            badgeText = t('statusBadge:responded');
            break;
        case AppointmentRequestStatus.APPROVED:
            badgeText = t('statusBadge:approved');
            break;
        case AppointmentRequestStatus.CANCELED:
            badgeText = t('statusBadge:cancelled');
            break;
    }

    return <StyledBadge type={state}>{badgeText}</StyledBadge>;
};
