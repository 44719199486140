import { useState } from 'react';

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const useDelayedState = <T = any>(initialValue: T, inputDelay = 400): [T, (nextValue: T) => void, T] => {
    const [value, setValue] = useState<T>(initialValue);
    const [delayedValue, setDelayedValue] = useState<T>(initialValue);
    const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout | null>(null);

    const setValueWithDelay = (nextValue: T) => {
        if (searchTimeout != null) {
            clearTimeout(searchTimeout!);
        }
        setValue(nextValue);
        setSearchTimeout(setTimeout(() => setDelayedValue(nextValue), inputDelay));
    };

    return [value, setValueWithDelay, delayedValue];
};
