import styled, { css } from 'styled-components';

interface Props {
    isReadOnly?: boolean;
}

export const Switch = styled.div<Props>`
    *,
    *:before,
    *:after {
        box-sizing: border-box;
    }
    cursor: pointer;
    &:hover &:before {
        background: white;
        box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
    }
    display: flex;

    input {
        ${(props) =>
            props.isReadOnly &&
            css`
                cursor: default !important;
            `}
    }

    .toggle-switch {
        display: inline-block;
        width: 60px;
        height: 27px;
        position: relative;
        vertical-align: middle;
        border: 1px solid #dbdcee;
        box-sizing: border-box;
        border-radius: 21px;
        margin-right: 10px;
        &:before,
        &:after {
            content: '';
        }
        &:before {
            display: block;
            background: linear-gradient(to bottom, #fff 0%, #eee 100%);
            border-radius: 50%;
            width: 17px;
            height: 17px;
            position: absolute;
            top: 4px;
            left: 4px;
            transition: ease 0.25s;
            background: #c5c5d2;
        }
    }
    .toggle-checkbox:checked + .toggle-switch {
        &:before {
            background: ${({ theme }) => theme.accent};
            left: 36px;
        }
    }
    .toggle-label {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.175px;
        color: #c5c5d2 !important;
        @media (max-width: ${({ theme }) => theme.devices.desktop}) {
            max-width: 70%;
        }
    }

    .toggle-checkbox:checked + .toggle-switch + .toggle-label {
        color: #24252f !important;
    }

    .toggle-checkbox {
        position: absolute;
        left: 0;
        z-index: 10;
        opacity: 0;
        cursor: pointer;
        width: 90px;
    }
`;
