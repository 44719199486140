import React, { FC } from 'react';
import { ServiceColumnsStyled } from './ServiceColumns.styled';
import { CalendarType } from '../CalendarView.types';
import { ICalendarInfo } from 'tui-calendar';

interface CalendarFiltersProps {
    activeCalendars: ICalendarInfo[];
    calendarType: string | undefined;
}

export const ServiceColumns: FC<CalendarFiltersProps> = ({ activeCalendars, calendarType }: CalendarFiltersProps) => {
    return (
        <ServiceColumnsStyled
            id={'service-columns'}
            columnsNumber={activeCalendars.length}
            isDailyView={calendarType === CalendarType.day}
        >
            {activeCalendars.map((service, index) => {
                return (
                    <div key={index + 'activeServices'} className={`service-column ${service.name}-column`}>
                        {service.name}
                    </div>
                );
            })}
            {activeCalendars.map((service, index) =>
                index > 0 ? (
                    <div
                        key={index}
                        className="column-line"
                        style={{ left: `calc(((100% - 71px)/${activeCalendars.length})*${index} + 70px)` }}
                    />
                ) : (
                    ''
                ),
            )}
        </ServiceColumnsStyled>
    );
};
