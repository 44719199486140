import { TFunction } from 'i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { Button, FormGroup, InformationBubble, InformationBubbleTypes } from '../../../../../medrefer-web-sdk/web-kit';
import React, { useState } from 'react';
import { RequestWaitingRoomProps, RequestWaitingRoomValues } from '../RequestWaitingRoomForm.types';
import { FormikProps } from 'formik';
import { SlotBookingStep } from '../../../../PublicSearch/SlotBooking/SlotBooking.types';
import { LoginCredentials, LoginStep } from '../../../../../medrefer-web-sdk/api/models';

interface Props {
    t: TFunction;
    SetCanSubmit: (submit: boolean) => void;
    props: RequestWaitingRoomProps & FormikProps<RequestWaitingRoomValues>;
    onCancel: () => void;
    emailExists: (data: LoginCredentials) => Promise<boolean>;
    setLoginOrRegisterStage: (set: boolean) => void;
}

export default function RequestWaitingRoomUserSubModule({
    t,
    SetCanSubmit,
    props,
    onCancel,
    emailExists,
    setLoginOrRegisterStage,
}: Props) {
    const [proceeded, SetProceeded] = useState(false);
    const [isLoading, SetIsLoading] = useState(false);
    const isTouched = (): boolean => {
        return (
            ((props.touched.email !== undefined || props.values.email !== '') &&
                (props.touched.phone !== undefined || props.values.phone != '')) ||
            props.touched.pickedDays !== undefined
        );
    };
    const isError = (): boolean => {
        return (
            (props.errors.email !== undefined && !props.values.isAuthorized) ||
            (props.errors.phone !== undefined && !props.values.isAuthorized) ||
            props.errors.message !== undefined ||
            props.values.pickedDays.length === 0
        );
    };

    const handleProceed = () => {
        if (props.values.isAuthorized) {
            props.values.step = SlotBookingStep.confirmation;
            props.handleSubmit();
            return;
        }

        SetIsLoading(true);
        emailExists({
            step: LoginStep.email,
            token: '',
            email: props.values.email,
            password: props.values.password,
        })
            .then((res) => {
                SetCanSubmit(true);
                SetProceeded(true);
                if (res) {
                    setLoginOrRegisterStage(true);
                    props.values.step = SlotBookingStep.login;
                }
            })
            .finally(() => {
                SetIsLoading(false);
            });
    };

    return (
        <Container>
            {!proceeded && (
                <>
                    {isTouched() && isError() && (
                        <FormGroup>
                            <Row className="primary">
                                <Col className="ml-auto mr-auto" xs={12} md={12}>
                                    <InformationBubble
                                        type={InformationBubbleTypes.warning}
                                        style={{ marginBottom: '-22px' }}
                                    >
                                        {t('requestWaitingRoom:error_finish_form')}
                                    </InformationBubble>
                                </Col>
                            </Row>
                        </FormGroup>
                    )}
                    <FormGroup>
                        <Row className="primary">
                            <Col className="ml-auto mr-auto" xs={12} md={6}>
                                <Button type="button" inverseColors={true} onClick={onCancel}>
                                    {t('newAppointmentForm:button_cancel')}
                                </Button>
                            </Col>
                            <Col className="ml-auto mr-auto" xs={12} md={6}>
                                <Button
                                    type={'button'}
                                    isLoading={isLoading}
                                    onClick={handleProceed}
                                    disabled={isError() || !isTouched()}
                                    style={
                                        !isError() && isTouched()
                                            ? {}
                                            : {
                                                  backgroundColor: 'lightgray',
                                                  borderColor: 'lightgray',
                                                  boxShadow: '0px 20px 25px lightgray',
                                              }
                                    }
                                >
                                    {t('newAppointmentForm:button_next')}
                                </Button>
                            </Col>
                        </Row>
                    </FormGroup>
                </>
            )}
        </Container>
    );
}
