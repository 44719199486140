import React, { SyntheticEvent } from 'react';
import {
    FormSelect,
    DatePicker,
    ErrorMessage,
    FormGroup,
    Label,
    TextArea,
    SwitchInput,
    H1,
} from 'medrefer-web-sdk/web-kit';
import { FormValues, SchedulePopoverProps } from 'components/Calendar/SchedulePopover/SchedulePopover.types';

import { Field, FormikProps } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSchedulePopover } from 'components/Calendar/SchedulePopover/SchedulePopover.hook';
import { Col, Row } from 'react-bootstrap';
import { FormColumn, SwitchInputContainer } from 'components/Calendar/SchedulePopover/SchedulePopover.styled';
import { RecurringOptionField } from '../RecurringOptionField';
import { CriteriaPicker } from '.';
import { SlotMode } from 'medrefer-web-sdk/api/models';
import useBrand from 'hooks/useBrand';

export interface ScheduleFormBaseProps {
    baseProps: SchedulePopoverProps & FormikProps<FormValues>;
}

const durationOptions = () => {
    const options = [];

    for (let i = 5; i <= 360; i += 5) {
        options.push({ label: i.toString(), value: i });
    }

    return options;
};

export const ScheduleFormData = ({ baseProps }: ScheduleFormBaseProps) => {
    const { t, i18n } = useTranslation();
    const { activeSlot } = baseProps;
    const { healthcareServiceOptions, appointmentReasonsOptions } = useSchedulePopover();
    const date = new Date();
    const { featureEnabled } = useBrand();

    const handleModeChange = (e: SyntheticEvent<Element, Event>) => {
        const newValue = (e.target as HTMLInputElement).id;
        const value = baseProps.values.mode;
        const { ONLINE, ONSITE } = SlotMode;

        const isModeOnsite = newValue === ONLINE && (value === null || value === ONLINE);

        const isModeOnline = newValue === ONSITE && (value === null || value === ONSITE);

        const isModeNull = (newValue === ONLINE || newValue === ONSITE) && (value === ONLINE || value === ONSITE);

        if (isModeOnsite) {
            baseProps.setFieldValue('mode', ONSITE);
        } else if (isModeOnline) {
            baseProps.setFieldValue('mode', ONLINE);
        } else if (isModeNull) {
            baseProps.setFieldValue('mode', null);
        }
    };
    const slotsType = [
        { value: 0, label: t('newSlotForm:slot_private') },
        { value: 1, label: t('newSlotForm:slot_public') },
        { value: 2, label: t('newSlotForm:slot_internal') },
    ];
    const durationTypes = [
        { value: 0, label: t('newSlotForm:slot_with_duration') },
        { value: 1, label: t('newSlotForm:slot_single') },
    ];
    return (
        <>
            <FormGroup>
                <Row>
                    <Col xs={12} md={6}>
                        <Label isRequired labelTop>
                            {t('newAppointmentForm:title_healthcare_service')}
                        </Label>
                        <Field
                            name="healthcare_service"
                            options={healthcareServiceOptions}
                            disabled={healthcareServiceOptions && healthcareServiceOptions.length <= 1}
                            component={FormSelect}
                            placeholder={t('newAppointmentForm:placeholder_insurance_company')}
                            noOptionsMessage={() => t('newAppointmentForm:no_options_healthcare_service')}
                            isMulti={false}
                            isError={baseProps.touched.healthcare_service && baseProps.errors.healthcare_service}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                    <Col xs={12} md={6}>
                        <Label isRequired labelTop>
                            {t('newSlotForm:slot_visibility')}
                        </Label>
                        <Field
                            name="slot_visibility"
                            options={slotsType}
                            component={FormSelect}
                            isMulti={false}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <FormColumn xs={12} md={12}>
                        <Label isRequired labelTop>
                            {t('newSlotForm:duration_type')}
                        </Label>
                        <Field
                            name="duration_type"
                            options={durationTypes}
                            disabled={healthcareServiceOptions && healthcareServiceOptions.length <= 1}
                            component={FormSelect}
                            placeholder={t('newAppointmentForm:placeholder_insurance_company')}
                            noOptionsMessage={() => t('newAppointmentForm:no_options_healthcare_service')}
                            isMulti={false}
                            onBlur={baseProps.handleBlur}
                        />
                    </FormColumn>
                </Row>
            </FormGroup>
            {baseProps.values.duration_type == 0 && (
                <>
                    <Row>
                        <Col xs={12} md={4}>
                            <Label isRequired labelTop>
                                {t('calendar:date')}
                            </Label>
                            <DatePicker
                                isError={!!(baseProps.touched.date && baseProps.errors.date)}
                                name="date"
                                value={baseProps.activeSchedule?.dateFrom}
                                fullWidth
                                autoComplete="off"
                                showYearDropdown
                                scrollableYearDropdown
                                dropdownMode="select"
                                i18n={i18n}
                                dateFormat="yyyy/MM/dd"
                                timeFormat="HH:mm"
                                minDate={date}
                                maxDate={new Date(date.getFullYear() + 1, date.getMonth(), date.getDate())}
                                minTime={new Date(new Date().setHours(baseProps.hourRange.min, 0, 0, 0))}
                                maxTime={new Date(new Date().setHours(baseProps.hourRange.max, 0, 0, 0))}
                            />
                            {baseProps.touched.date && baseProps.errors.date && (
                                <ErrorMessage className="text-left mt-1 mr-3">{baseProps.errors.date}</ErrorMessage>
                            )}
                        </Col>
                        <Col xs={12} md={4}>
                            <Label isRequired labelTop>
                                {t('calendar:date_hours_start')}
                            </Label>
                            <DatePicker
                                isError={!!(baseProps.touched.date_hour && baseProps.errors.date_hour)}
                                name="date_hour"
                                maxlength="5"
                                value={baseProps.activeSchedule?.dateFrom}
                                fullWidth
                                autoComplete="off"
                                dropdownMode="select"
                                i18n={i18n}
                                dateFormat="HH:mm"
                                minTime={new Date(new Date().setHours(baseProps.hourRange.min, 0, 0, 0))}
                                maxTime={new Date(new Date().setHours(baseProps.hourRange.max, 0, 0, 0))}
                                showTimeSelect
                                showTimeSelectOnly
                                // timeGrid
                                timeFormat="HH:mm"
                                timeIntervals={5}
                                timeCaption={t('calendar:date_hours')}
                                showDropdownArrow
                            />
                            {baseProps.touched.date_hour && baseProps.errors.date_hour && (
                                <ErrorMessage className="text-left mt-1 mr-3">
                                    {baseProps.errors.date_hour}
                                </ErrorMessage>
                            )}
                        </Col>
                        <Col xs={12} md={4}>
                            <Label isRequired labelTop>
                                {t('calendar:date_hours_end')}
                            </Label>
                            <DatePicker
                                isError={!!(baseProps.touched.date_hour && baseProps.errors.date_hour)}
                                name="dateTo_hour"
                                maxlength="5"
                                value={baseProps.activeSchedule?.dateTo}
                                fullWidth
                                autoComplete="off"
                                dropdownMode="select"
                                i18n={i18n}
                                dateFormat="HH:mm"
                                minTime={baseProps.values.date_hour} //{new Date(new Date().setHours(baseProps.hourRange.min, 0, 0, 0))}
                                maxTime={new Date(new Date().setHours(baseProps.hourRange.max, 0, 0, 0))}
                                showTimeSelect
                                showTimeSelectOnly
                                // timeGrid
                                timeFormat="HH:mm"
                                timeIntervals={baseProps.values.duration}
                                timeCaption={t('calendar:date_hours')}
                                showDropdownArrow
                            />
                            {baseProps.touched.date_hour && baseProps.errors.date_hour && (
                                <ErrorMessage className="text-left mt-1 mr-3">
                                    {baseProps.errors.date_hour}
                                </ErrorMessage>
                            )}
                        </Col>
                    </Row>
                    <FormGroup>
                        <Row>
                            <Col xs={12} md={12}>
                                <Label isRequired labelTop>
                                    {t('calendar:duration')}
                                </Label>
                                <Field
                                    name="duration"
                                    options={durationOptions()}
                                    component={FormSelect}
                                    placeholder={t('calendar:duration')}
                                    isMulti={false}
                                    isError={false}
                                    onBlur={baseProps.handleBlur}
                                />
                                {baseProps.touched.duration && baseProps.errors.duration && (
                                    <ErrorMessage className="text-left mt-1 mr-3">
                                        {baseProps.errors.duration}
                                    </ErrorMessage>
                                )}
                            </Col>
                        </Row>
                    </FormGroup>
                    {(!activeSlot || activeSlot?.is_recurring) && (
                        <FormGroup>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Label labelTop>{t('calendar:recurringOption.repeat')}</Label>
                                    <RecurringOptionField props={baseProps} />
                                </Col>
                            </Row>
                        </FormGroup>
                    )}
                </>
            )}
            {baseProps.values.duration_type == 1 && (
                <>
                    <FormGroup>
                        <Row>
                            <Col xs={12} md={3}>
                                <Label isRequired labelTop>
                                    {t('calendar:date')}
                                </Label>
                                <DatePicker
                                    isError={!!(baseProps.touched.date && baseProps.errors.date)}
                                    name="date"
                                    value={baseProps.activeSchedule?.dateFrom}
                                    fullWidth
                                    autoComplete="off"
                                    showYearDropdown
                                    scrollableYearDropdown
                                    dropdownMode="select"
                                    i18n={i18n}
                                    dateFormat="yyyy/MM/dd"
                                    timeFormat="HH:mm"
                                    minDate={date}
                                    maxDate={new Date(date.getFullYear() + 1, date.getMonth(), date.getDate())}
                                    minTime={new Date(new Date().setHours(baseProps.hourRange.min, 0, 0, 0))}
                                    maxTime={new Date(new Date().setHours(baseProps.hourRange.max, 0, 0, 0))}
                                    // showDropdownArrow
                                />
                                {baseProps.touched.date && baseProps.errors.date && (
                                    <ErrorMessage className="text-left mt-1 mr-3">{baseProps.errors.date}</ErrorMessage>
                                )}
                            </Col>
                            <Col xs={12} md={3}>
                                <Label isRequired labelTop>
                                    {t('calendar:date_hours')}
                                </Label>
                                <DatePicker
                                    isError={!!(baseProps.touched.date_hour && baseProps.errors.date_hour)}
                                    name="date_hour"
                                    maxlength="5"
                                    value={baseProps.activeSchedule?.dateFrom}
                                    fullWidth
                                    autoComplete="off"
                                    dropdownMode="select"
                                    i18n={i18n}
                                    dateFormat="HH:mm"
                                    minTime={new Date(new Date().setHours(baseProps.hourRange.min, 0, 0, 0))}
                                    maxTime={new Date(new Date().setHours(baseProps.hourRange.max, 0, 0, 0))}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    // timeGrid
                                    timeFormat="HH:mm"
                                    timeIntervals={5}
                                    timeCaption={t('calendar:date_hours')}
                                    showDropdownArrow
                                />
                                {baseProps.touched.date_hour && baseProps.errors.date_hour && (
                                    <ErrorMessage className="text-left mt-1 mr-3">
                                        {baseProps.errors.date_hour}
                                    </ErrorMessage>
                                )}
                            </Col>
                            <Col xs={12} md={6}>
                                <Label isRequired labelTop>
                                    {t('calendar:duration')}
                                </Label>
                                <Field
                                    name="duration"
                                    options={durationOptions()}
                                    component={FormSelect}
                                    placeholder={t('calendar:duration')}
                                    isMulti={false}
                                    isError={false}
                                    onBlur={baseProps.handleBlur}
                                />
                                {baseProps.touched.duration && baseProps.errors.duration && (
                                    <ErrorMessage className="text-left mt-1 mr-3">
                                        {baseProps.errors.duration}
                                    </ErrorMessage>
                                )}
                            </Col>
                        </Row>
                    </FormGroup>
                    {(!activeSlot || activeSlot?.is_recurring) && (
                        <FormGroup>
                            <Row>
                                <Col xs={12} md={12}>
                                    <Label labelTop>{t('calendar:recurringOption.repeat')}</Label>
                                    <RecurringOptionField props={baseProps} />
                                </Col>
                            </Row>
                        </FormGroup>
                    )}
                </>
            )}
            <FormGroup>
                <Row>
                    <Col xs={12} md={12}>
                        <Label isRequired labelTop>
                            {t('newAppointmentForm:title_reasons_for_appointment')}
                        </Label>
                        <Field
                            name="appointment_reasons"
                            options={appointmentReasonsOptions}
                            disabled={appointmentReasonsOptions.length <= 1}
                            component={FormSelect}
                            placeholder={t('newAppointmentForm:placeholder_reasons_for_appointment')}
                            noOptionsMessage={() => t('newAppointmentForm:no_options_reason_for_appointment')}
                            isMulti
                            isError={baseProps.touched.appointment_reasons && baseProps.errors.appointment_reasons}
                            onBlur={baseProps.handleBlur}
                        />
                        {baseProps.touched.appointment_reasons && baseProps.errors.appointment_reasons && (
                            <ErrorMessage className="text-left mt-1">
                                {baseProps.errors.appointment_reasons}
                            </ErrorMessage>
                        )}
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} md={12}>
                        <Label labelTop>{t('calendar:comment')}</Label>
                        <TextArea
                            isError={!!(baseProps.touched.comment && baseProps.errors.comment)}
                            id="comment"
                            value={baseProps.values.comment}
                            onChange={baseProps.handleChange}
                            onBlur={baseProps.handleBlur}
                        />
                    </Col>
                </Row>
                {baseProps.touched.comment && baseProps.errors.comment && (
                    <ErrorMessage className="text-left mt-1">{baseProps.errors.comment}</ErrorMessage>
                )}
            </FormGroup>
            {featureEnabled('videoCalls') && (
                <FormGroup>
                    <Row>
                        <Col xs={12}>
                            <H1 className="mb-3">{t('calendar:mode.title')}</H1>
                        </Col>
                        <Col xs={12}>
                            <SwitchInputContainer>
                                <Label labelTop>{t('calendar:mode.onsite')}</Label>
                                <SwitchInput
                                    id={SlotMode.ONSITE}
                                    isChecked={
                                        baseProps.values.mode === null || baseProps.values.mode === SlotMode.ONSITE
                                    }
                                    handleChange={handleModeChange}
                                />
                            </SwitchInputContainer>
                            <SwitchInputContainer style={{ marginLeft: '20px' }}>
                                <Label labelTop>{t('calendar:mode.online')}</Label>
                                <SwitchInput
                                    id={SlotMode.ONLINE}
                                    isChecked={
                                        baseProps.values.mode === null || baseProps.values.mode === SlotMode.ONLINE
                                    }
                                    handleChange={handleModeChange}
                                />
                            </SwitchInputContainer>
                        </Col>
                    </Row>
                </FormGroup>
            )}
            <FormGroup>
                <CriteriaPicker baseProps={baseProps} />
            </FormGroup>
        </>
    );
};
