import React from 'react';
import { FormProps } from './BookingForm';
import { Field, FormikProps } from 'formik';
import { SlotBookingFormData, SlotBookingStep } from 'components/PublicSearch/SlotBooking/SlotBooking.types';
import {
    H1,
    Input,
    Label,
    ErrorMessage,
    FormGroup,
    TextGrey,
    UnderlineH2,
    FormSeparator,
    DatePicker,
    FormSelect,
    FormAsyncSelect,
    Option,
} from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FormColumn, UserFormContainer } from './BookingForm.styled';
import i18n from 'i18next';
import { useInsuranceStatusOptions } from 'hooks/options/useInsuranceStatusOptions';
// import ReactMarkdown from 'react-markdown';
import { PhoneInput } from 'components/shared/PhoneInput';
import { OptionalField } from '../../../shared/ServiceSlots/RequestWaitingRoomModal/RequestWaitingRoomDataComponents/OptionalField';
import useLocationAutocompleteOptions from '../../../../hooks/options/useLocationAutocompleteOptions';

type Props = {
    props: FormProps & FormikProps<SlotBookingFormData>;
};

export const UserRegisterForm = ({ props }: Props) => {
    const { t } = useTranslation();
    const insuranceStatusOptions = useInsuranceStatusOptions();
    const { loadLocationAutocompleteOptions, setAddressProps } = useLocationAutocompleteOptions();

    return (
        <UserFormContainer>
            <H1 className="mb-3">{t('slotBooking:register_title')}</H1>
            <Row>
                <Col>
                    <UnderlineH2>{t('slotBooking:register_description')}</UnderlineH2>
                </Col>
            </Row>
            <FormGroup>
                <Row>
                    <Col xs={12} md={6}>
                        <Label labelTop>{t('newAppointmentForm:title_personal_title')}</Label>
                        <Input
                            isError={!!(props.touched.personal_title && props.errors.personal_title)}
                            id="personal_title"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.personal_title}
                            disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                props.values.step,
                            )}
                            onBlur={props.handleBlur}
                        />
                        {props.touched.personal_title && props.errors.personal_title && (
                            <ErrorMessage className="text-left mt-1 mr-3">{props.errors.personal_title}</ErrorMessage>
                        )}
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col xs={12} md={6}>
                        <Label isRequired labelTop>
                            {t('newAppointmentForm:title_first_name')}
                        </Label>
                        <Input
                            isError={!!(props.touched.first_name && props.errors.first_name)}
                            id="first_name"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.first_name}
                            onBlur={props.handleBlur}
                            disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                props.values.step,
                            )}
                        />
                        {props.touched.first_name && props.errors.first_name && (
                            <ErrorMessage className="text-left mt-1 mr-3">{props.errors.first_name}</ErrorMessage>
                        )}
                    </Col>
                    <FormColumn xs={12} md={6}>
                        <Label isRequired labelTop>
                            {t('newAppointmentForm:title_last_name')}
                        </Label>
                        <Input
                            isError={!!(props.touched.last_name && props.errors.last_name)}
                            id="last_name"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.last_name}
                            onBlur={props.handleBlur}
                            disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                props.values.step,
                            )}
                        />
                        {props.touched.last_name && props.errors.last_name && (
                            <ErrorMessage className="text-left mt-1 mr-3">{props.errors.last_name}</ErrorMessage>
                        )}
                    </FormColumn>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row style={{ rowGap: '36px' }}>
                    <Col xs={12} md={6}>
                        <Label isRequired labelTop>
                            {t('newAppointmentForm:title_date_of_birth')}
                        </Label>
                        <DatePicker
                            isError={props.touched.date_of_birth && props.errors.date_of_birth}
                            name="date_of_birth"
                            value={props.values.date_of_birth}
                            fullWidth
                            autoComplete="off"
                            showYearDropdown
                            scrollableYearDropdown
                            maxDate={new Date()}
                            dropdownMode="select"
                            i18n={i18n}
                            onChange={props.handleChange}
                            disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                props.values.step,
                            )}
                        />
                        {props.touched.date_of_birth && props.errors.date_of_birth && (
                            <ErrorMessage className="text-left mt-1 mr-3">{props.errors.date_of_birth}</ErrorMessage>
                        )}
                    </Col>
                    <Col xs={12} md={6}>
                        <OptionalField
                            isError={!!(props.errors.phone && props.touched.phone)}
                            errorMessage={props.errors.phone}
                            fieldName={'phone'}
                            fieldLabel={t('newAppointmentForm:title_phone_number')}
                            fields={props.fields}
                            mandatory_fields={props.mandatory_fields}
                            isBasicField={true}
                        >
                            <PhoneInput
                                isError={!!(props.errors.phone && props.touched.phone)}
                                id="phone"
                                type="text"
                                onChange={props.handleChange}
                                value={props.values.phone}
                                onBlur={props.handleBlur}
                                disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                    props.values.step,
                                )}
                            />
                        </OptionalField>
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row style={{ rowGap: '36px' }}>
                    <Col xs={12} md={6}>
                        <OptionalField
                            isError={!!(props.errors.insurance_number && props.touched.insurance_number)}
                            errorMessage={props.errors.insurance_number}
                            fieldName={'insurance_number'}
                            fieldLabel={t('newAppointmentForm:title_insurance_number')}
                            fields={props.fields}
                            mandatory_fields={props.mandatory_fields}
                            isBasicField={true}
                        >
                            <Input
                                isError={!!(props.touched.insurance_number && props.errors.insurance_number)}
                                id="insurance_number"
                                type="text"
                                onChange={props.handleChange}
                                value={props.values.insurance_number}
                                onBlur={props.handleBlur}
                                disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                    props.values.step,
                                )}
                            />
                        </OptionalField>
                    </Col>
                    <Col xs={12} md={6}>
                        <OptionalField
                            isError={!!(props.errors.insurance_status && props.touched.insurance_status)}
                            errorMessage={props.errors.insurance_status}
                            fieldName={'insurance_status'}
                            fieldLabel={t('newAppointmentForm:title_insurance_status')}
                            fields={props.fields}
                            mandatory_fields={props.mandatory_fields}
                            isBasicField={true}
                        >
                            <Field
                                name="insurance_status"
                                options={insuranceStatusOptions}
                                component={FormSelect}
                                placeholder={t('newAppointmentForm:placeholder_insurance_status')}
                                isMulti={false}
                                isError={props.touched.insurance_status && props.errors.insurance_status}
                                onBlur={props.handleBlur}
                                isDisabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                    props.values.step,
                                )}
                            />
                        </OptionalField>
                    </Col>
                    <Col xs={12} md={6}>
                        <OptionalField
                            isError={!!(props.errors.street && props.touched.street)}
                            errorMessage={props.errors.street}
                            fieldName={'address'}
                            fieldLabel={t('newAppointmentForm:title_address')}
                            fields={props.fields}
                            mandatory_fields={props.mandatory_fields}
                        >
                            <Field
                                name="address"
                                isError={!!(props.errors.street && props.touched.street)}
                                loadOptions={loadLocationAutocompleteOptions}
                                component={FormAsyncSelect}
                                placeholder={t('adminPanel:manageLocations.locationsForm.address_placeholder')}
                                debounceTimeout={400}
                                minSearchLength={3}
                                onBlur={props.handleBlur}
                                isDisabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                    props.values.step,
                                )}
                                afterChange={(option: Option) => setAddressProps(props.setFieldValue, option)}
                            />
                        </OptionalField>
                    </Col>
                </Row>
            </FormGroup>
            <FormSeparator className="mt-0" />
            <FormGroup>
                <Row>
                    <FormColumn xs={12} md={6} className={'mt-0'}>
                        <Label isRequired labelTop>
                            {t('newAppointmentForm:title_password')}
                        </Label>
                        <Input
                            isError={!!(props.touched.password && props.errors.password)}
                            id="password"
                            placeholder={t('newAppointmentForm:placeholder_password')}
                            type="password"
                            onChange={props.handleChange}
                            value={props.values.password}
                            onBlur={props.handleBlur}
                            disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                props.values.step,
                            )}
                        />
                        {props.touched.password && props.errors.password && (
                            <ErrorMessage className="text-left mt-1 mr-3">{props.errors.password}</ErrorMessage>
                        )}
                    </FormColumn>
                    <FormColumn xs={12} md={6}>
                        <Label isRequired labelTop>
                            {t('newAppointmentForm:title_confirm_password')}
                        </Label>
                        <Input
                            isError={!!(props.touched.confirm_password && props.errors.confirm_password)}
                            id="confirm_password"
                            placeholder={t('newAppointmentForm:placeholder_confirm_password')}
                            type="password"
                            onChange={props.handleChange}
                            value={props.values.confirm_password}
                            onBlur={props.handleBlur}
                            disabled={[SlotBookingStep.registerOtpCode, SlotBookingStep.confirmation].includes(
                                props.values.step,
                            )}
                        />
                        {props.touched.confirm_password && props.errors.confirm_password && (
                            <ErrorMessage className="text-left mt-1 mr-3">{props.errors.confirm_password}</ErrorMessage>
                        )}
                    </FormColumn>
                </Row>
            </FormGroup>
            {[SlotBookingStep.registerOtpCode, SlotBookingStep.registerConfirmation].includes(props.values.step) && (
                <>
                    <FormSeparator className="mb-4 mt-0" />
                    <FormGroup>
                        <Row className="mb-4">
                            <Col>
                                <TextGrey>
                                    {/*<ReactMarkdown*/}
                                    {/*    renderers={{*/}
                                    {/*        link: (props) => (*/}
                                    {/*            <a href={props.href} rel="nofollow noreferrer noopener" target="_blank">*/}
                                    {/*                {props.children}*/}
                                    {/*            </a>*/}
                                    {/*        ),*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {t('otp:code_sent') as string}
                                    {/*</ReactMarkdown>*/}
                                </TextGrey>
                            </Col>
                        </Row>
                        <Row>
                            <FormColumn xs={12} md={6}>
                                <Label isRequired labelTop>
                                    {t('loginForm:otp_code')}
                                </Label>
                                <Input
                                    isError={!!(props.touched.token && props.errors.token)}
                                    id="token"
                                    placeholder={t('loginForm:otp_code')}
                                    type="text"
                                    onChange={props.handleChange}
                                    value={props.values.token}
                                    onBlur={props.handleBlur}
                                    autoFocus
                                />
                                {props.touched.token && props.errors.token && (
                                    <ErrorMessage className="text-left mt-1 mr-3">{props.errors.token}</ErrorMessage>
                                )}
                            </FormColumn>
                        </Row>
                    </FormGroup>
                </>
            )}
        </UserFormContainer>
    );
};
