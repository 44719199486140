import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ShowMoreArrow,
    ShowMoreContainer,
} from 'components/shared/ServiceSlots/CalendarTimeline/CalendarTimeline.styled';
import { PrimaryButton, Separator } from 'medrefer-web-sdk/web-kit';
import BackArrow from 'medrefer-web-sdk/web-kit/assets/icons/svg/BackArrow.svg';

export const ShowMoreHours = ({ isActive, toggle }: { isActive: boolean; toggle: () => void }) => {
    const { t } = useTranslation();

    return (
        <ShowMoreContainer>
            <Separator />
            <PrimaryButton noBorder inverseColors onClick={toggle}>
                {!isActive ? t('publicSearch:show_more') : t('publicSearch:show_less')}
                <ShowMoreArrow isReversed={isActive} src={BackArrow} />
            </PrimaryButton>
        </ShowMoreContainer>
    );
};
