import React from 'react';
import { ServicesFormProps } from './ServicesForm.types';
import { Form, FormikProps, withFormik } from 'formik';
import { useTranslation, withTranslation } from 'react-i18next';
import { mapPropsToValues, validationSchema } from './ServicesForm.schema';
import {
    FormErrorMessage,
    Button,
    TextRed,
    UnderlineH2 as Title,
    InformationContainer,
} from 'medrefer-web-sdk/web-kit';
import { Col, Container, Row } from 'react-bootstrap';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';
import { ServicesData } from 'components/AdminPanel/ManageServices/ServicesForm/ServicesData';
import { ServiceFormValues } from './ServicesForm.types';

export const InnerForm = (props: ServicesFormProps & FormikProps<ServiceFormValues>) => {
    const { t } = useTranslation();
    return (
        <>
            <Title>
                {props.type === ModalType.add
                    ? t('adminPanel:manageServices.servicesForm.title_add')
                    : t('adminPanel:manageServices.servicesForm.title_edit')}
            </Title>
            <Form
                onSubmit={(e) => {
                    e.preventDefault();
                    props.handleSubmit();
                }}
            >
                <ServicesData baseProps={props} />

                <InformationContainer>
                    <FormErrorMessage apiErrors={props.request.errors} />
                </InformationContainer>
                <Container>
                    <Row>
                        <Col className="m-auto" xs={12} md={12}>
                            <Button isLoading={props.request.isLoading}>
                                {props.type === ModalType.add
                                    ? t('adminPanel:manageServices.servicesForm.button_add')
                                    : t('adminPanel:manageServices.servicesForm.button_edit')}
                            </Button>
                            {!props.isValid && !props.isValidating && (
                                <TextRed className="text-right mr-3 mt-4">
                                    {t('newAppointmentForm:warning_please_fill_all')}
                                </TextRed>
                            )}
                        </Col>
                    </Row>
                </Container>
            </Form>
        </>
    );
};

const LocationsForm = withFormik<ServicesFormProps, ServiceFormValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit: (values, { props }) => {
        const { amount, amount_currency, charge_applies, payment_methods, ...data } = values; // eslint-disable-line @typescript-eslint/no-unused-vars
        let price = null;
        if (charge_applies) {
            price = { amount, amount_currency, payment_methods };
        }
        props.handleSubmit({ price, ...data });
    },
})(InnerForm);

export default withTranslation()(LocationsForm);
