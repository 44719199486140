import styled from 'styled-components';

export const Separator = styled.hr`
    width: 100%;
    border-top: 1px solid #eeeef2;
    max-width: 100vw !important;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        margin-left: 0;
        width: 100%;
    }
`;
