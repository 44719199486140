import { withFormik } from 'formik';
import { CompleteUserInfoProps, CompleteUserInfoValues } from './CompleteUserInfo.types';
import { withTranslation } from 'react-i18next';
import { handleSubmit, mapPropsToValues, validationSchema } from './CompleteUserInfo.schema';
import { CompleteUserInfoData } from './CompleteUserInfoData';

const CompleteUserInfoForm = withFormik<CompleteUserInfoProps, CompleteUserInfoValues>({
    mapPropsToValues,
    validationSchema,
    handleSubmit,
})(CompleteUserInfoData);
export default withTranslation()(CompleteUserInfoForm);
