import React, { useEffect, useState } from 'react';
import { Modal, useModalState } from '../../../medrefer-web-sdk/web-kit';
import CompleteUserInfoForm from './CompleteUserInfoForm';
import { useSelector } from 'react-redux';
import { getAuthUser, getBrand } from '../../../features/auth/selectors';
import { useOptionalFields } from '../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';
import useLoginFlow from '../useLoginFlow';

interface Props {
    onFormSubmit?: () => void;
    onDiscard?: (() => void) | undefined;
}
export const CompleteUserInfoModal = ({
    onFormSubmit = () => {
        return;
    },
    onDiscard = undefined,
}: Props) => {
    const authUser = useSelector(getAuthUser);
    const organization = useSelector(getBrand);
    const { getFields, getMandatoryFields } = useOptionalFields(organization);
    const { updateProfile } = useLoginFlow();
    const modal = useModalState();

    const [modalInitialized, InitializeModal] = useState(false);

    const onDiscardModal = onDiscard
        ? () => {
              modal.toggleModal();
              onDiscard();
          }
        : undefined;

    useEffect(() => {
        if (modalInitialized) return;

        modal.toggleModal();
        InitializeModal(true);
        // eslint-disable-next-line
    }, [modalInitialized]);

    return (
        <Modal
            isCentered={false}
            state={{
                isOpen: modal.isOpen,
                toggleModal: onDiscardModal || modal.toggleModal,
            }}
            body={
                <CompleteUserInfoForm
                    user={authUser}
                    onFormSubmit={onFormSubmit}
                    fields={getFields()}
                    mandatory_fields={getMandatoryFields()}
                    updateProfile={updateProfile}
                />
            }
        />
    );
};
