import styled, { css } from 'styled-components';
import ExclamationCircleIcon from '../../assets/icons/ExclamationCircleIcon.png';
interface StyledInputProps {
    isError?: boolean;
    isReadOnly?: boolean;
}

export const Input = styled.input<StyledInputProps>`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: ${({ theme }) => theme.white};
    border: 1px solid ${({ theme }) => theme.purple100};
    box-sizing: border-box;
    border-radius: 8px;
    min-height: 42px;
    width: 100%;
    padding: 0 14px;
    position: relative;
    ${(props) =>
        props.isError &&
        css`
            background: ${({ theme }) => theme.red100};
            border: 1px solid ${({ theme }) => theme.red300};
            color: ${({ theme }) => theme.red300};
            background-image: url(${ExclamationCircleIcon});
            background-repeat: no-repeat;
            background-position: 98% 50%;
        `}
    &:focus {
        border: 1px solid ${({ theme }) => theme.accent};
        border-radius: 8px;
        box-shadow: none;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:focus {
        transition: background-color 600000s 0s, color 600000s 0s;
    }
`;
