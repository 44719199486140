import React from 'react';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import { Column } from 'react-table';
import { formatDateTime, getDateTimeIntervalString } from 'utils/dates';
import moment from 'moment';
import { getFullName } from 'medrefer-web-sdk/api/utils';
import { StatusBadge } from 'components/Dashboard/StatusBadge';

export const columns: (t: (key: string) => string) => Column<AppointmentModel>[] = (t) => [
    {
        Header: t('adminPanel:table.columns.id'),
        accessor: 'id',
        sortParam: 'id',
        width: 50,
    },
    {
        Header: t('adminPanel:table.columns.full_name'),
        accessor: (appointment) => <span>{getFullName(appointment.patient_metadata)}</span>,
        sortParam: 'full_name',
        width: 100,
    },
    {
        Header: t('adminPanel:table.columns.time'),
        accessor: (appointment) => {
            const startMoment = moment(new Date(appointment.date_from));
            const endMoment = moment(new Date(appointment.date_to));

            return <span>{getDateTimeIntervalString(startMoment, endMoment)}</span>;
        },
        sortParam: 'date_from',
    },
    {
        Header: t('adminPanel:table.columns.service_name'),
        accessor: (appointment) => <span>{appointment.healthcare_service.name}</span>,
        sortParam: 'healthcare_service__name',
    },
    {
        Header: t('adminPanel:table.columns.reason_for_appointment'),
        accessor: (appointment) => <span>{appointment.reason.name}</span>,
        sortParam: 'reason__name',
    },
    {
        Header: t('adminPanel:table.columns.status'),
        accessor: (appointment) => <StatusBadge appointment={appointment} />,
        width: 70,
        sortParam: 'booking_status',
    },
    {
        Header: t('adminPanel:table.columns.updatedAt'),
        accessor: (appointment) => <span>{formatDateTime(moment(new Date(appointment.updated_at)))}</span>,
        width: 70,
        sortParam: 'updated_at',
    },
];
