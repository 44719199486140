import { FormValues, RecurringSlotOption } from '../../SchedulePopover.types';
import { Slot } from 'medrefer-web-sdk/api/models';
import { rruleToScheduleRule, scheduleRuleToRRule } from './rrule';

export const getInitialRecurringOption = (slot: Slot | null) => {
    return slot?.schedule_rule ? RecurringSlotOption.custom : RecurringSlotOption.dontRepeat;
};

export const getInitialRecurringRule = (slot: Slot | null) => {
    return slot?.schedule_rule ? scheduleRuleToRRule(slot.schedule_rule) : undefined;
};

export const serializeRecurringRule = (values: FormValues) => {
    return values.recurring_rule ? rruleToScheduleRule(values.recurring_rule) : null;
};
