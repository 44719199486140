import React from 'react';
import { AdminPanelContainer, FormActivityIndicatorContainer } from 'components/AdminPanel/AdminPanel.styled';
import { columns } from './ManageQuestionnaires.consts';
import { ModalType } from 'components/AdminPanel/AdminPanel.types';

import { ConfirmModalBody } from 'medrefer-web-sdk/web-kit/';
import { DataTable } from 'components/shared/DataTable';
import { QuestionnaireForm } from './QuestionnaireForm';
import { ActivityIndicator } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';
import { RouteBreadcrumbs } from 'components/shared/RouteBreadcrumbs';
import { useManageQuestionnaires } from './ManageQuestionnaires.hook';

export const ManageQuestionnaires = () => {
    const { t } = useTranslation();
    const {
        deleteModalState,
        createQuestionnaire,
        updateQuestionnaire,
        deleteQuestionnaire,
        activeItem,
        fetchActiveItem,
        request,
        ...rest
    } = useManageQuestionnaires();

    return (
        <AdminPanelContainer>
            <DataTable
                title={t('adminPanel:manageQuestionnaires.title')}
                actionColumnTitle={t('adminPanel:table.columns.actions')}
                noItemsMessage={t('adminPanel:table.no_items_message')}
                columns={columns(t)}
                addItemForm={
                    <QuestionnaireForm type={ModalType.add} request={request} handleSubmit={createQuestionnaire} />
                }
                editItemForm={
                    activeItem ? (
                        <QuestionnaireForm
                            activeItem={activeItem}
                            type={ModalType.edit}
                            request={request}
                            handleSubmit={updateQuestionnaire}
                        />
                    ) : (
                        <FormActivityIndicatorContainer>
                            <ActivityIndicator />
                        </FormActivityIndicatorContainer>
                    )
                }
                deleteModalState={deleteModalState}
                deleteItemForm={
                    activeItem ? (
                        <ConfirmModalBody
                            title={t('adminPanel:manageLocations.deleteForm.title')}
                            message={t('adminPanel:manageLocations.deleteForm.message')}
                            confirmButton={t('adminPanel:manageLocations.deleteForm.accept_button')}
                            declineButton={t('adminPanel:manageLocations.deleteForm.decline_button')}
                            onConfirm={deleteQuestionnaire}
                            onDecline={deleteModalState.toggleModal}
                        />
                    ) : (
                        <ActivityIndicator />
                    )
                }
                setActiveItem={fetchActiveItem}
                addButtonText={t('adminPanel:table.controls.addButtonText')}
                previousPageText={t('adminPanel:table.controls.previousPageText')}
                nextPageText={t('adminPanel:table.controls.nextPageText')}
                breadcrumbs={<RouteBreadcrumbs />}
                {...rest}
            />
        </AdminPanelContainer>
    );
};
