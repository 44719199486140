import { useEffect, useRef } from 'react';
import { useField, useFormikContext } from 'formik';

export const useDatePicker = (props) => {
    const inputRef = useRef(null);
    const { isError } = props;
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const fullWidthClass = props.fullWidth ? 'react-datepicker-wrapper-full' : '';

    function setInputFilter(textbox, inputFilter) {
        ['input'].forEach(function (event) {
            textbox.addEventListener(event, function () {
                if (this.name === 'date_hour' || this.name === 'dateTo_hour') {
                    if (this.value.length === 2 && this.oldValue.length !== 3) {
                        if (this.value.slice(-1) === ':' || this.oldValue.slice(-1) === ':') {
                            return;
                        }
                        this.value += ':';
                    }
                    if (this.value.length < 3 && /^\d{0,2}$/.test(this.value)) {
                        this.oldValue = this.value;
                    } else if (this.value.length >= 3 && /^\d{0,2}:?\d{0,2}$/.test(this.value)) {
                        this.oldValue = this.value;
                    } else if (this.hasOwnProperty('oldValue')) {
                        this.value = this.oldValue;
                    } else {
                        this.value = '';
                    }
                } else {
                    if (
                        (this.value.length === 2 && this.oldValue.length !== 3) ||
                        (this.value.length === 5 && this.oldValue.length !== 6)
                    ) {
                        if (this.value.slice(-1) === '/' || this.oldValue.slice(-1) === '/') {
                            return;
                        }
                        this.value += '/';
                    }
                    if (inputFilter(this.value)) {
                        this.oldValue = this.value;
                        this.oldSelectionStart = this.selectionStart;
                        this.oldSelectionEnd = this.selectionEnd;
                        // eslint-disable-next-line no-prototype-builtins
                    } else if (this.hasOwnProperty('oldValue')) {
                        this.value = this.oldValue;
                        this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
                    } else {
                        this.value = '';
                    }
                }
            });
        });
    }

    const handleKeyDown = (e) => {
        if (e.which === 9) inputRef.current.setOpen(false, true);
    };

    useEffect(() => {
        setInputFilter(document.getElementById(props.name), function (value) {
            return /^\d{0,2}\/?\d{0,2}\/?\d{0,4}$/.test(value); // Allow digits and '.' only, using a RegExp
        });
    }, [props.name]);

    return {
        isError,
        setFieldValue,
        field,
        fullWidthClass,
        handleKeyDown,
        inputRef,
    };
};
