import { useContext, useEffect, useMemo, useState } from 'react';
import { ICalendarInfo, ISchedule } from 'tui-calendar';
import { ThemeContext } from 'styled-components';
import { mapAppointmentsToSchedules, mapSlotsToSchedules } from 'components/Calendar/helpers';
import { BaseHealthcareService } from 'medrefer-web-sdk/api/models';
import { ScheduleType } from '../Calendar.types';
import { CalendarState } from 'components/Calendar/CalendarView/useCalendarState';
import { useOrganizationLocations } from 'components/Calendar/hooks/useOrganizationLocations';
import { useCalendarSlotsAndAppointments } from 'components/Calendar/hooks/useCalendarSlotsAndAppointments';

export const useCalendarData = (state: CalendarState) => {
    const [activeScheduleTypes, setActiveScheduleTypes] = useState<string[]>(Object.keys(ScheduleType));
    const theme = useContext(ThemeContext);
    const organizationLocations = useOrganizationLocations();

    const services = useMemo(() => {
        return organizationLocations.map((x) => x.service_set).flat(1);
    }, [organizationLocations]);

    const [activeServices, setActiveServices] = useState<BaseHealthcareService[]>(services);
    const { appointments, slots, isLoading, reloadSchedules } = useCalendarSlotsAndAppointments(state.dateRange);

    useEffect(() => {
        setActiveServices(services);
    }, [services]);

    const calendars: ICalendarInfo[] = useMemo(() => {
        const colors: string[] = [
            theme.saddlebrown,
            theme.darkslategray,
            theme.darkgreen,
            theme.darkkhaki,
            theme.indigo,
            theme.red,
            theme.darkturquoise,
            theme.lime,
            theme.mediumspringgreen,
            theme.blue,
            theme.fuchsia,
            theme.cornflower,
            theme.deeppink,
            theme.yellow,
            theme.orange,
            theme.lightpink,
        ];

        return services.map((service, index) => {
            return {
                id: service.id.toString(),
                name: service.name,
                color: theme.white,
                bgColor: colors[index],
                dragBgColor: colors[index],
                borderColor: colors[index],
            };
        });
    }, [theme, services]);

    const schedules = useMemo(() => {
        if (!calendars) return;
        const schedulesData: ISchedule[] = [];

        if (appointments.length !== 0) {
            const appointmentsData: ISchedule[] = mapAppointmentsToSchedules(
                appointments,
                activeServices,
                state.calendarType,
                calendars,
                organizationLocations,
            );
            schedulesData.push(...appointmentsData);
        }

        if (slots.length !== 0) {
            const slotsData: ISchedule[] = mapSlotsToSchedules(
                slots,
                activeServices,
                state.calendarType,
                calendars,
                organizationLocations,
            );
            schedulesData.push(...slotsData);
        }

        const activeServiceIds = activeServices.map((x) => x.id);
        return schedulesData.filter((schedule) => {
            return (
                activeServiceIds.includes(schedule.raw?.service_id as number) &&
                activeScheduleTypes.includes(schedule.raw?.type as string)
            );
        });
    }, [
        appointments,
        slots,
        calendars,
        activeServices,
        activeScheduleTypes,
        state.calendarType,
        organizationLocations,
    ]);

    const activeCalendars = useMemo(() => {
        const activeServiceIds = activeServices.map((s) => s.id.toString());
        return calendars.filter((calendar) => activeServiceIds.includes(calendar.id));
    }, [activeServices, calendars]);

    return {
        schedules,
        calendars,
        activeCalendars,
        activeServices,
        setActiveServices,
        setActiveScheduleTypes,
        activeScheduleTypes,
        isLoading,
        reloadSchedules,
    };
};
