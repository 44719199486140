import React from 'react';
import PhoneInput2, { PhoneInputProps } from 'react-phone-input-2';
import ExclamationCircleIcon from 'medrefer-web-sdk/web-kit/assets/icons/ExclamationCircleIcon.png';
import 'react-phone-input-2/lib/style.css';
import styled, { css } from 'styled-components';

interface BasePhoneInputProps extends PhoneInputProps {
    isError?: boolean;
}

export const BasePhoneInput = ({ isError, ...rest }: BasePhoneInputProps) => {
    return (
        <PhoneInputStyles isError={isError}>
            <PhoneInput2 {...rest} />
        </PhoneInputStyles>
    );
};

const PhoneInputStyles = styled.div<{ isError?: boolean }>`
    width: 100%;

    .react-tel-input .form-control {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        color: #000;
        background: ${({ theme }) => theme.white};
        border: 1px solid ${({ theme }) => theme.purple100};
        box-sizing: border-box;
        border-radius: 8px;
        min-height: 42px;
        width: 100%;
        padding: 0 14px 0 48px;
        position: relative;
        font-size: 16px;

        ${(props) =>
            props.isError &&
            css`
                background: ${({ theme }) => theme.red100};
                border: 1px solid ${({ theme }) => theme.red300};
                color: ${({ theme }) => theme.red300};
                background-image: url(${ExclamationCircleIcon});
                background-repeat: no-repeat;
                background-position: 98% 50%;
            `}

        &:focus {
            border: 1px solid ${({ theme }) => theme.accent};
            border-radius: 8px;
            box-shadow: none;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:focus {
            transition: background-color 600000s 0s, color 600000s 0s;
        }
    }

    .react-tel-input .flag-dropdown {
        ${(props) =>
            props.isError &&
            css`
                background: ${({ theme }) => theme.red100};
                border: 1px solid ${({ theme }) => theme.red300};
            `}
    }
`;
