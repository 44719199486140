import styled from 'styled-components';

export const DaySelectorContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 5px 0;
    gap: 8px;
`;

export const DayCircle = styled.span<{ isActive: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    background-color: ${({ theme, isActive }) => (isActive ? theme.accent : theme.grey100)};
    color: ${({ theme, isActive }) => (isActive ? theme.white : theme.black)};
    border-radius: 50%;
    transition: all 0.1s ease;
`;
