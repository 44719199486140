import { FormikProps, withFormik } from 'formik';
import React, { useRef } from 'react';
import * as Yup from 'yup';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';

import {
    Button,
    FormErrorMessage,
    FormGroup,
    InformationBubble,
    InformationContainer,
    InformationBubbleTypes,
    Input,
} from 'medrefer-web-sdk/web-kit';
import { ApiRequest } from 'hooks/useApiRequest';
import { LoginCredentials, LoginStep } from 'medrefer-web-sdk/api/models';
import { EditFieldButton } from 'components/shared/EditFieldButton';
// import ReactMarkdown from 'react-markdown';

interface FormProps extends WithTranslation {
    request: ApiRequest;
    emailNotFound: boolean;
    otpCodeSent: boolean;
    passwordReset: boolean;
    ensureEmailExists: (data: LoginCredentials) => Promise<boolean>;
    submitCredentials: (data: LoginCredentials) => Promise<void>;
    submitOtp: (data: LoginCredentials) => Promise<void>;
}

const InnerForm = (props: FormProps & FormikProps<LoginCredentials>) => {
    const { t } = useTranslation();
    const emailInput = useRef<HTMLInputElement>(null);
    const passwordInput = useRef<HTMLInputElement>(null);
    const { step } = props.values;

    const setStep = (newStep: LoginStep) => {
        props.setFieldValue('step', newStep);
    };

    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <FormGroup className="mb-0">
                <Form.Label>{t('loginForm:email')}</Form.Label>
                <EditFieldButton
                    onClick={() => {
                        setStep(LoginStep.email);
                        setImmediate(() => emailInput.current!.focus());
                    }}
                    isVisible={step > LoginStep.email}
                >
                    <Input
                        ref={emailInput}
                        data-testid="email"
                        id="email"
                        name="email"
                        type="email"
                        onChange={props.handleChange}
                        value={props.values.email}
                        disabled={step > LoginStep.email}
                        autoFocus
                    />
                </EditFieldButton>
            </FormGroup>
            {step >= LoginStep.password && (
                <FormGroup>
                    <Form.Label>{t('loginForm:password')}</Form.Label>
                    <EditFieldButton
                        onClick={() => {
                            setStep(LoginStep.password);
                            setImmediate(() => passwordInput.current!.focus());
                        }}
                        isVisible={step > LoginStep.password}
                    >
                        <Input
                            ref={passwordInput}
                            data-testid="password"
                            id="password"
                            name="password"
                            type="password"
                            onChange={props.handleChange}
                            value={props.values.password}
                            disabled={step > LoginStep.password}
                            autoFocus
                        />
                    </EditFieldButton>
                </FormGroup>
            )}
            {step >= LoginStep.otpCode && (
                <FormGroup>
                    <Form.Label>{t('loginForm:otp_code')}</Form.Label>
                    <Input
                        data-testid="token"
                        id="token"
                        name="token"
                        type="text"
                        onChange={props.handleChange}
                        value={props.values.token}
                        autoFocus
                    />
                </FormGroup>
            )}
            <InformationContainer>
                {props.passwordReset ? (
                    <InformationBubble type={InformationBubbleTypes.success}>
                        {t('loginForm:after_password_reset')}
                    </InformationBubble>
                ) : props.otpCodeSent ? (
                    <InformationBubble type={InformationBubbleTypes.success}>
                        {/*<ReactMarkdown*/}
                        {/*    renderers={{*/}
                        {/*        link: (props) => (*/}
                        {/*            <a href={props.href} rel="nofollow noreferrer noopener" target="_blank">*/}
                        {/*                {props.children}*/}
                        {/*            </a>*/}
                        {/*        ),*/}
                        {/*    }}*/}
                        {/*>*/}
                        {t('otp:code_sent') as string}
                        {/*</ReactMarkdown>*/}
                    </InformationBubble>
                ) : props.emailNotFound ? (
                    <InformationBubble type={InformationBubbleTypes.warning}>
                        {t('loginForm:warning_account_not_found')}
                    </InformationBubble>
                ) : (
                    <FormErrorMessage apiErrors={props.request.errors} />
                )}
            </InformationContainer>
            <div style={{ maxWidth: 200, margin: 'auto' }}>
                <Button isLoading={props.request.isLoading}>
                    {step < LoginStep.otpCode ? t('loginForm:button_next') : t('loginForm:button')}
                </Button>
            </div>
        </Form>
    );
};

const LoginForm = withFormik<FormProps, LoginCredentials>({
    mapPropsToValues: () => ({ email: '', password: '', token: '', step: LoginStep.email }),
    validationSchema: ({ t }: FormProps) =>
        Yup.object().shape({
            email: Yup.string()
                .max(50, t('loginForm:warning_login_max'))
                .email(t('loginForm:warning_login_matches'))
                .required(t('loginForm:warning_email_required')),
            password: Yup.string()
                .max(50, t('loginForm:warning_password_max'))
                .when('step', {
                    is: LoginStep.password,
                    then: Yup.string().required(t('loginForm:warning_password_required')),
                }),
            token: Yup.string().when('step', {
                is: LoginStep.otpCode,
                then: Yup.string().required(t('otp:warning_code_required')),
            }),
        }),
    handleSubmit: (values, { props, setSubmitting, setFieldValue, setTouched }) => {
        const goNextStep = () => {
            setSubmitting(false);
            setTouched({});
            setFieldValue('step', values.step + 1);
        };

        switch (values.step) {
            case LoginStep.email:
                props.ensureEmailExists(values).then((exists) => {
                    if (exists) {
                        goNextStep();
                    }
                });
                break;
            case LoginStep.password:
                props.submitCredentials(values).then(goNextStep);
                break;
            case LoginStep.otpCode:
                props.submitOtp(values);
                break;
        }
    },
})(InnerForm);

export default withTranslation()(LoginForm);
