import React, { ReactElement, ReactNode } from 'react';
import { theme } from 'medrefer-web-sdk/web-kit';
import { ThemeProvider } from 'styled-components';
import useBrand from 'hooks/useBrand';
interface Props {
    children?: ReactNode;
}
export function ThemeProviderWrapper({ children }: Props): ReactElement {
    const { organization } = useBrand();
    return (
        <ThemeProvider
            theme={{
                ...theme,
                accent: organization?.branding?.accent_color || theme.accent,
            }}
        >
            {children}
        </ThemeProvider>
    );
}
