import React, { useState } from 'react';
import { SideMenuWrapper, SideMenuContainer, SideMenuInner, SideMenuList } from './SideMenu.styled';
import CalendarIcon from 'medrefer-web-sdk/web-kit/assets/icons/svg/Calendar.svg';
import GroupsIcon from 'medrefer-web-sdk/web-kit/assets/icons/svg/Groups.svg';
import HomeIcon from 'medrefer-web-sdk/web-kit/assets/icons/svg/Home.svg';
import BookAppointment from 'medrefer-web-sdk/web-kit/assets/icons/svg/BookAppointment.svg';
import TableIcon from 'assets/TableIcon.svg';
import CalendarQuestionIcon from 'assets/CalendarQuestionOutline.svg';
import { SideMenuLink } from './SideMenuLink';
import { useTranslation } from 'react-i18next';
import { ExpandableMenu, ExpandableMenuLink } from './ExpandableMenu';
import { getAuthUser, getBrand } from 'features/auth/selectors';
import { useSelector } from 'react-redux';
import { UserRole } from 'medrefer-web-sdk/api/models';
import { ReactSVG } from 'react-svg';
import { theme } from 'medrefer-web-sdk/web-kit';
import useBrand from 'hooks/useBrand';
import { Link } from '../../shared/Link';

interface SideMenuProps {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SideMenu = ({ isOpen, setIsOpen }: SideMenuProps) => {
    const { t } = useTranslation();
    const authUser = useSelector(getAuthUser);
    const isMobile = document && document.body.clientWidth <= theme.parsePx(theme.devices.desktop);
    const { featureEnabled } = useBrand();

    const [mouseActionOpen, setMouseActionOpen] = useState(false);

    const handleOnMouseEnter = () => {
        !isMobile && setMouseActionOpen(true);
    };

    const handleOnMouseLeave = () => {
        !isMobile && setMouseActionOpen(false);
    };

    const isOrganization = authUser?.role === UserRole.organization;
    const isOrganizationMember = authUser?.role === UserRole.organizationMember;
    const isPatient = authUser?.role === UserRole.patient;
    const isCooperator = authUser?.role === UserRole.cooperator;
    const isSideMenuOpen = isOpen || mouseActionOpen;
    const onClickLink = () => {
        if (isMobile) {
            setIsOpen(false);
        }
    };

    const links: ExpandableMenuLink[] = [
        { path: 'manageMembers', title: t('sidebar:tooltip_user') },
        { path: 'manageServices', title: t('sidebar:tooltip_services') },
        { path: 'manageServiceGroups', title: t('sidebar:tooltip_service_groups') },
        { path: 'manageLocations', title: t('sidebar:tooltip_locations') },
        { path: 'manageSlotsCriteria', title: t('sidebar:tooltip_slots_criteria') },
    ];

    if (featureEnabled('questionnaires')) {
        links.push({
            path: 'manageQuestionnaires',
            title: t('sidebar:tooltip_questionnaires'),
        });
    }

    links.push({ path: 'organizationProfile', title: t('sidebar:tooltip_profile') });

    const organization = useSelector(getBrand);

    return (
        <SideMenuWrapper isOpen={isSideMenuOpen} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
            <SideMenuContainer>
                <SideMenuInner>
                    <SideMenuList>
                        <li className={`menu-title ${!isSideMenuOpen ? 'hidden' : ''}`}>
                            {t('sidebar:tooltip_main_label')}
                        </li>
                        {isPatient && (
                            <>
                                {organization?.slug == 'agata' ||
                                (organization?.slug == 'sec' && organization.id == 14) ||
                                (organization?.slug == 'zweitmeinung' && organization.id == 13) ? (
                                    <li>
                                        <Link target={'_self'} href={'https://www.zweitmeinung-arzt.online/'}>
                                            <ReactSVG
                                                src={BookAppointment}
                                                className={'nav-icon'}
                                                alt="Patient panel icon"
                                            />
                                            <span>{t('sidebar:tooltip_find_appointment')}</span>
                                        </Link>
                                    </li>
                                ) : (
                                    <SideMenuLink
                                        title={t('sidebar:tooltip_find_appointment')}
                                        link="landing"
                                        icon={
                                            <ReactSVG
                                                src={BookAppointment}
                                                className={'nav-icon'}
                                                alt="Patient panel icon"
                                            />
                                        }
                                        onClick={onClickLink}
                                    />
                                )}
                                <SideMenuLink
                                    title={t('sidebar:tooltip_appointments')}
                                    link="patientPanel"
                                    icon={<ReactSVG src={GroupsIcon} className="nav-icon" alt="Patient panel icon" />}
                                    onClick={onClickLink}
                                />
                            </>
                        )}
                        {(isOrganization || isOrganizationMember || isCooperator) && (
                            <>
                                <SideMenuLink
                                    title={t('sidebar:tooltip_appointments')}
                                    link="appointments"
                                    icon={<ReactSVG src={GroupsIcon} className={'nav-icon'} alt="Appointment icon" />}
                                    notExact
                                    onClick={onClickLink}
                                />
                                <SideMenuLink
                                    title={t('sidebar:tooltip_appointments_table')}
                                    link="appointmentsTable"
                                    icon={<ReactSVG src={TableIcon} className={'nav-icon'} alt="Appointment icon" />}
                                    notExact
                                    onClick={onClickLink}
                                />
                                <SideMenuLink
                                    title={t('sidebar:tooltip_request_list')}
                                    link="requestList"
                                    icon={
                                        <ReactSVG
                                            src={CalendarQuestionIcon}
                                            className={'nav-icon'}
                                            alt="AppointmentRequests icon"
                                        />
                                    }
                                    onClick={onClickLink}
                                />
                                <SideMenuLink
                                    title={t('sidebar:tooltip_calendar_table')}
                                    link="calendarTable"
                                    icon={<ReactSVG src={CalendarIcon} className={'nav-icon'} alt="Calendar icon" />}
                                    onClick={onClickLink}
                                />
                                <SideMenuLink
                                    title={t('sidebar:tooltip_calendar')}
                                    link="calendar"
                                    icon={<ReactSVG src={CalendarIcon} className={'nav-icon'} alt="Calendar icon" />}
                                    onClick={onClickLink}
                                />
                            </>
                        )}
                        {isOrganization && (
                            <>
                                <li className={`menu-title ${!isSideMenuOpen ? 'hidden' : ''}`}>
                                    {t('sidebar:tooltip_admin_label')}
                                </li>
                                <ExpandableMenu
                                    title={t('sidebar:tooltip_organization')}
                                    icon={<ReactSVG src={HomeIcon} className="nav-icon" alt="Location icon" />}
                                    isSideMenuOpen={isSideMenuOpen}
                                    setIsSideMenuOpen={setIsOpen}
                                    onClick={onClickLink}
                                    links={links}
                                />
                            </>
                        )}
                    </SideMenuList>
                </SideMenuInner>
            </SideMenuContainer>
        </SideMenuWrapper>
    );
};
