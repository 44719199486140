import { useLocation } from 'react-router-dom';
import { Search } from 'history';
import { routerActions } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useMemo } from 'react';

type Params = { [key: string]: string[] };

type QueryParams = {
    params: Params;
    get: (key: string) => string | undefined;
    copy: () => Query;
    getAll: (key: string) => string[];
    set: (key: string, value: string | string[]) => void;
    remove: (key: string) => void;
    toString: () => string;
    clear: () => void;
};

export const useQueryParams = (): QueryParams => {
    const location = useLocation();
    const dispatch = useDispatch();
    const query = useMemo(() => {
        return new Query(searchToParams(location.search));
    }, [location.search]);

    const set = (key: string, value: string | string[]) => {
        query.set(key, value);
        dispatch(routerActions.replace(query.toString()));
    };

    const remove = (key: string) => {
        query.remove(key);
        dispatch(routerActions.replace(query.toString()));
    };

    const clear = () => {
        query.clear();
        dispatch(routerActions.replace(query.toString()));
    };

    return {
        params: query.params,
        get: query.get,
        getAll: query.getAll,
        copy: query.copy,
        toString: query.toString,
        set,
        remove,
        clear,
    };
};

const searchToParams = (search: Search): Params => {
    const searchParams = new URLSearchParams(search);
    const params: Params = {};

    searchParams.forEach((value: string, key: string) => {
        if (!params.hasOwnProperty(key)) {
            params[key] = [];
        }
        params[key].push(value);
    });

    return params;
};

class Query {
    params: Params;

    constructor(params: Params) {
        this.params = params;
    }

    get = (key: string): string | undefined => {
        return this.params[key]?.[0];
    };

    getAll = (key: string): string[] => {
        if (this.params.hasOwnProperty(key)) {
            return this.params[key];
        }
        return [];
    };

    set = (key: string, value: string | string[] | undefined) => {
        if (value == null) {
            this.remove(key);
            return;
        }
        if (typeof value === 'string') {
            value = [value];
        }
        this.params[key] = value;
    };

    remove = (key: string) => {
        delete this.params[key];
    };

    clear = () => {
        Object.keys(this.params).forEach(this.remove);
    };

    copy = () => {
        return new Query(Object.assign({}, this.params));
    };

    toString = () => {
        const stringParams: string[] = [];

        Object.entries(this.params).forEach(([key, values]) => {
            values.forEach((value) => {
                if (value) {
                    stringParams.push(`${key}=${value}`);
                }
            });
        });

        return '?' + stringParams.join('&');
    };
}
