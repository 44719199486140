import styled from 'styled-components';

export const TableDisplayContainer = styled.div`
    background-color: ${({ theme }) => theme.white};
    border-collapse: separate !important;
    margin-top: 20px;
    overflow-x: auto;
    width: 100%;
    position: relative;
`;

export const Table = styled.table`
    display: block;
    min-width: 100% !important;
    table-layout: fixed;
    width: 100%;
    display: inline-table;

    .resizer {
        right: 0;
        background: rgba(0, 0, 0, 0.2);
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        touch-action: none;

        &.isResizing {
            background: ${({ theme }) => theme.purple100};
        }
    }
`;

export const LoaderContainer = styled.div`
    height: 458px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const TableHead = styled.thead<{ columnCount: number }>`
    overflow-y: auto;
    overflow-x: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    background-color: ${({ theme }) => theme.background};

    .sortable {
        cursor: pointer;
    }

    th {
        position: relative;
        padding: 20px;
        position: relative;
    }
    th:last-of-type {
        padding-right: 0;
        width: 115px;
    }
`;

export const TableBody = styled.tbody<{ columnCount: number }>`
    overflow-y: scroll;
    overflow-x: hidden;

    tr {
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
    tr:last-of-type {
        border-bottom: none;
    }
    td {
        position: relative;
        padding: 10px 20px;
        vertical-align: top;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    td:last-of-type {
        padding: 0;
    }
`;

export const ActionButtonsContainer = styled.div`
    display: flex;
    gap: 10px;
    padding: 10px;
    justify-content: center;
    width: 114px;
`;

export const SortArrows = styled.div<{ sortOrder: string | null }>`
    display: flex;
    flex-direction: column;
    right: 10px;
    top: 21px;

    &:before {
        opacity: ${({ sortOrder }) => (sortOrder === 'asc' ? '1' : '.2')};
        content: '\\2191';
        position: absolute;
        right: 10px;
        top: 21px;
        height: 10px;
        width: 10px;
    }

    &:after {
        opacity: ${({ sortOrder }) => (sortOrder === 'desc' ? '1' : '.2')};
        content: '\\2193';
        position: absolute;
        right: 0px;
        top: 21px;
    }
`;

export const NoItemsIndicator = styled.p`
    display: flex;
    justify-content: center;
    margin: 20px;
`;
