import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModalBody } from 'medrefer-web-sdk/web-kit';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import { useDispatch } from 'react-redux';
import { cancelAppointment } from 'features/appointment/actions';

interface Props {
    activeListItem: AppointmentModel;
    toggleModal: () => void;
}

export default function CancelAppointmentModalBody(props: Props): ReactElement {
    const { toggleModal, activeListItem } = props;
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const handleClickAccept = () => {
        dispatch(cancelAppointment(activeListItem.id));
        toggleModal();
    };

    const handleClickReject = () => {
        toggleModal();
    };

    return (
        <ConfirmModalBody
            title={t('cancelAppointmentModalBody:title')}
            message={t('cancelAppointmentModalBody:message')}
            confirmButton={t('cancelAppointmentModalBody:accept_btn_message')}
            declineButton={t('cancelAppointmentModalBody:decline_btn_message')}
            onConfirm={handleClickAccept}
            onDecline={handleClickReject}
        />
    );
}
