import React, { useCallback, useMemo, useState } from 'react';
import {
    AppointmentModel,
    AppointmentStatus,
    AppointmentStep,
    AppointmentStepStatus,
    AppointmentStepType,
} from 'medrefer-web-sdk/api/models';
import { AppointmentPaymentStep } from 'components/plugins/StripePayments/AppointmentPaymentStep';
import { api } from 'medrefer-web-sdk/api';
import { useInterval, useNonInitialEffect } from 'medrefer-web-sdk/web-kit/hooks';
import { Redirect } from 'react-router-dom';
import useBrand from 'hooks/useBrand';

interface Props {
    appointment: AppointmentModel;
    initialAppointmentSteps: AppointmentStep[];
}

export const AppointmentStepsManager = ({ appointment, initialAppointmentSteps }: Props) => {
    const { getPathBranded } = useBrand();
    const [appointmentSteps, setAppointmentSteps] = useState(initialAppointmentSteps);
    const [shouldReloadSteps, setShouldReloadSteps] = useState(false);

    const reloadAppointmentSteps = useCallback(() => {
        api.getAppointmentSteps(appointment.id).then(setAppointmentSteps);
    }, [appointment.id]);

    const reloadStepsIfNeeded = useCallback(() => {
        if (shouldReloadSteps) {
            reloadAppointmentSteps();
        }
    }, [reloadAppointmentSteps, shouldReloadSteps]);

    useInterval(reloadStepsIfNeeded, 5 * 1000);
    useInterval(reloadAppointmentSteps, 60 * 1000);

    const activeStep = useMemo(() => {
        return appointmentSteps.find((step) => step.status === AppointmentStepStatus.pending);
    }, [appointmentSteps]);

    const allStepsCompleted = useMemo(() => {
        return !appointmentSteps.find((step) => step.status !== AppointmentStepStatus.completed);
    }, [appointmentSteps]);

    useNonInitialEffect(() => {
        setShouldReloadSteps(false);
    }, [activeStep?.id]);

    const startReloadingSteps = () => {
        // Must be called by the step handler to indicate that the step is or about to be completed.
        setShouldReloadSteps(true);
    };

    if (allStepsCompleted) {
        return <Redirect to={getPathBranded('slotBookingSuccess', appointment.id.toString())} />;
    }

    if (appointment.status == AppointmentStatus.CANCELLED) {
        return <div>Your appointment has been cancelled.</div>; // TODO: Show information that appointment is expired and has been cancelled.
    }

    if (!activeStep) {
        return <div>Error occurred!</div>; // TODO: Some of the step entered an error state
    }

    const stepHandlers = {
        [AppointmentStepType.stripePayment]: AppointmentPaymentStep,
    };
    const Component = stepHandlers[activeStep.type];
    return <Component appointment={appointment} step={activeStep} startReloadingSteps={startReloadingSteps} />;
};
