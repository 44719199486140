import React, { useEffect, useState } from 'react';
import { checkAuthentication } from 'features/auth/actions';
import { setupUnauthorizedHook } from 'medrefer-web-sdk/api/Interceptor';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser } from 'features/auth/selectors';
import { updateUserLanguage } from 'features/auth/actions';
import { Language } from 'medrefer-web-sdk/api/models';
import { PageActivityIndicator } from 'components/shared/PageActivityIndicator';
import { useUserLogout } from 'hooks/useUserLogout';
import { getActiveLanguage } from 'utils/i18n';

type Props = {
    children: JSX.Element;
};

const AuthGate = ({ children }: Props) => {
    const [isAuthPending, setIsAuthPending] = useState(true);
    const dispatch = useDispatch();
    const { logoutUser } = useUserLogout();
    useUserLanguageUpdater();

    useEffect(() => {
        setupUnauthorizedHook(logoutUser);
        doAuthentication();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const doAuthentication = async () => {
        try {
            await dispatch(checkAuthentication());
        } catch (err) {}
        setIsAuthPending(false);
    };

    if (isAuthPending) {
        return <PageActivityIndicator />;
    }

    return children;
};

const useUserLanguageUpdater = () => {
    const authUser = useSelector(getAuthUser);
    const dispatch = useDispatch();
    const currentLanguage = getActiveLanguage() as Language;

    useEffect(() => {
        if (authUser && currentLanguage !== authUser.language) {
            dispatch(updateUserLanguage(currentLanguage));
        }
        // eslint-disable-next-line
    }, [authUser, currentLanguage, dispatch]);
};

export default React.memo(AuthGate);
