import { useEffect } from 'react';

export const useUpdateWindowDimensions = () => {
    useEffect(() => {
        const updateWindowDimensions = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            // --maxvh represents value of 100vh adjusted to controls on safari iOS
            document.documentElement.style.setProperty('--maxvh', `calc(var(--vh, 1vh) * 100)`);
        };

        updateWindowDimensions();
        window.addEventListener('resize', updateWindowDimensions);

        return () => window.removeEventListener('resize', updateWindowDimensions);
    }, []);
};
