import React from 'react';
import { Row } from 'react-bootstrap';
import { GreyTitle as Title, ValueTypography as Value } from 'medrefer-web-sdk/web-kit';

type Props = {
    label: string;
    value?: string | number | null;
};

const ProfileAttribute = ({ label, value }: Props) => {
    return (
        <>
            <Row>
                <Title>{label}</Title>
            </Row>
            <Row>
                <Value>{value || '-'}</Value>
            </Row>
        </>
    );
};

export default ProfileAttribute;
