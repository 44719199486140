import React from 'react';
import { getInitials } from './utils';
import styled from 'styled-components';

export type AvatarProps = {
    src?: string;
    name: string;
    size: number | string;
    borderRadius?: number | string;
    /**
     * Whether an image should be round, overrides borderRadius property
     */
    round?: boolean;
};

export const Avatar = ({ src, name, size, borderRadius = 4, round = false }: AvatarProps) => {
    const initials = getInitials(name, 2);
    borderRadius = round ? '50%' : borderRadius;

    return (
        <AvatarContainer style={{ width: size, height: size, borderRadius }}>
            {src ? <AvatarImg src={src} alt={initials} /> : <AvatarInitials>{initials}</AvatarInitials>}
        </AvatarContainer>
    );
};

const AvatarContainer = styled.div`
    background: ${({ theme }) => theme.purple50};
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const AvatarImg = styled.img`
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

const AvatarInitials = styled.div`
    color: ${({ theme }) => theme.accent};
    font-weight: bold;
    font-size: 19px;
`;
