import { useState, useEffect, useCallback } from 'react';
import { api } from 'medrefer-web-sdk/api';
import { idNameToOption, Option } from 'medrefer-web-sdk/web-kit';
import { healthcareServiceFactory } from 'features/appointment/factories';
import { useOrganizationLocations } from 'components/Calendar/hooks/useOrganizationLocations';

export const useSchedulePopover = () => {
    const organizationLocations = useOrganizationLocations();
    const [appointmentReasonsOptions, setAppointmentReasonsOptions] = useState<Option[]>([]);
    const healthcareServices = organizationLocations.map((x) => x.service_set).flat(1);
    const healthcareServiceOptions = healthcareServices.map(healthcareServiceFactory);

    const getAppointmentReasonsOptions = useCallback(async () => {
        const res = await api.getAppointmentReasons();
        const options: Option[] = res.map(idNameToOption);

        setAppointmentReasonsOptions(options);
    }, []);

    useEffect(() => {
        getAppointmentReasonsOptions();
    }, [getAppointmentReasonsOptions]);

    return { healthcareServiceOptions, appointmentReasonsOptions };
};
