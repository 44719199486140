import { Weekday } from '../../../../medrefer-web-sdk/api/models';
import { Option } from '../../../../medrefer-web-sdk/web-kit';
import { AppointmentRequestTime } from '../../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';
import { useTranslation } from 'react-i18next';

export const useDayOptions = () => {
    const { t } = useTranslation();

    const dayOptionsLong = [
        { label: t('global:daynamesLong.1'), value: Weekday.MO },
        { label: t('global:daynamesLong.2'), value: Weekday.TU },
        { label: t('global:daynamesLong.3'), value: Weekday.WE },
        { label: t('global:daynamesLong.4'), value: Weekday.TH },
        { label: t('global:daynamesLong.5'), value: Weekday.FR },
    ];
    const dayOptionsShort = [
        { label: t('global:daynamesShort.1'), value: Weekday.MO },
        { label: t('global:daynamesShort.2'), value: Weekday.TU },
        { label: t('global:daynamesShort.3'), value: Weekday.WE },
        { label: t('global:daynamesShort.4'), value: Weekday.TH },
        { label: t('global:daynamesShort.5'), value: Weekday.FR },
    ];
    const dayTimeOptions: Option[] = [
        { value: AppointmentRequestTime.MORNING, label: t('requestWaitingRoom:selection_morning') },
        { value: AppointmentRequestTime.AFTERNOON, label: t('requestWaitingRoom:selection_afternoon') },
        { value: AppointmentRequestTime.ALL_DAY, label: t('requestWaitingRoom:selection_all_day') },
    ];
    const dayTimeNoneOption: Option = {
        value: AppointmentRequestTime.NONE,
        label: t('requestWaitingRoom:selection_none'),
    };

    const GetDayIndex = (day: Weekday, optionType: EDayOptionsType): number => {
        if (optionType === EDayOptionsType.Long) {
            for (let i = 0; i < dayOptionsLong.length; i++) {
                if (dayOptionsLong[i].value === day) return i;
            }
            return -1;
        }

        for (let i = 0; i < dayOptionsShort.length; i++) {
            if (dayOptionsShort[i].value === day) return i;
        }
        return -1;
    };

    const GetDayTime = (time: AppointmentRequestTime): string => {
        let label = '';
        dayTimeOptions.forEach((dayTime) => {
            if (dayTime.value === time) {
                label = dayTime.label;
                return;
            }
        });
        return label;
    };

    return {
        dayOptionsLong,
        dayTimeOptions,
        dayTimeNoneOption,
        GetDayIndex,
        GetDayTime,
    };
};

export enum EDayOptionsType {
    Long = 0,
    Short = 1,
}
