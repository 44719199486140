import React, { ReactNode, useCallback } from 'react';
import { AdminPanelContainer } from 'components/AdminPanel/AdminPanel.styled';
import { useManageAppointments } from './AppointmentsTable.hook';

import { IconButtonAlt, Modal } from 'medrefer-web-sdk/web-kit/';
import { DataTable } from 'components/shared/DataTable';
import EditIcon from 'assets/EditIcon.svg';
import { useTranslation } from 'react-i18next';
import { RouteBreadcrumbs } from 'components/shared/RouteBreadcrumbs';
import { columns } from 'components/Dashboard/AppointmentsTable/AppointmentsTable.consts';
import { EditAppointment } from 'components/Dashboard/AppointmentsTable/EditAppointment/EditAppointment';
import { AppointmentModel } from 'medrefer-web-sdk/api/models';
import { ReactSVG } from 'react-svg';
import { Row } from 'react-table';
export const AppointmentsTable = () => {
    const { t } = useTranslation();
    const { editModalState, activeItem, fetchActiveItem, reloadAppointments, ...rest } = useManageAppointments();

    const setActiveItem = useCallback(
        (item: AppointmentModel) => {
            fetchActiveItem(item);
            editModalState.toggleModal();
        },
        [editModalState, fetchActiveItem],
    );

    const getExtraActions = useCallback(
        // eslint-disable-next-line @typescript-eslint/ban-types
        (row: Row<object>): undefined | Array<ReactNode> => {
            const rowObject = row.original as AppointmentModel;

            return [
                <IconButtonAlt key={rowObject.id} onClick={() => setActiveItem(rowObject)}>
                    <ReactSVG src={EditIcon} />
                </IconButtonAlt>,
            ];
        },
        [setActiveItem],
    );

    return (
        <>
            <AdminPanelContainer>
                <DataTable
                    title={t('sidebar:tooltip_appointments_table')}
                    actionColumnTitle={t('adminPanel:table.columns.actions')}
                    noItemsMessage={t('adminPanel:table.no_items_message')}
                    columns={columns(t)}
                    setActiveItem={fetchActiveItem}
                    previousPageText={t('adminPanel:table.controls.previousPageText')}
                    nextPageText={t('adminPanel:table.controls.nextPageText')}
                    breadcrumbs={<RouteBreadcrumbs />}
                    getExtraActions={getExtraActions}
                    {...rest}
                />
            </AdminPanelContainer>
            <Modal
                isCentered={true}
                body={
                    <EditAppointment
                        activeItem={activeItem}
                        toggleModal={editModalState.toggleModal}
                        reloadAppointments={reloadAppointments}
                    />
                }
                state={editModalState}
            />
        </>
    );
};
