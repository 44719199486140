import styled, { css } from 'styled-components';
import { CalendarType } from './CalendarView.types';

export const CalendarContainer = styled.div`
    padding: 0 0 0 1px;
    overflow: hidden;
    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        padding-top: ${({ theme }) => theme.navbarHeight.mobile};
    }
`;

export const VerticalScrollWrapper = styled.div<{ calendarType: string | undefined }>`
    max-height: 100%;
    overflow: ${({ calendarType }) => (calendarType === CalendarType.month ? 'hidden' : 'auto')};
`;

export const HorizontalScrollWrapper = styled.div`
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
`;

export const CalendarWrapper = styled.div<{
    height: number;
    calendarType: string | undefined;
    hourRangeCount: number;
    pastRowsLength: number;
    isPast: boolean;
    isCurrent: boolean;
    videoCallIcon: string;
}>`
    height: ${({ height }) => height}px;
    position: relative;
    width: fit-content;
    min-width: 100%;

    ${({ pastRowsLength, isPast, isCurrent, theme }) =>
        isPast
            ? css`
                  .tui-full-calendar-month-week-item {
                      .tui-full-calendar-weekday-grid-line {
                          background-color: ${theme.grey50} !important;
                      }
                  }

                  .tui-full-calendar-month-week-item {
                      .tui-full-calendar-weekday-grid-line:nth-child(-n + ${({}) => new Date().getDay() - 1}) {
                          background-color: ${({ theme }) => theme.grey50} !important;
                      }
                  }

                  .tui-full-calendar-time-date {
                      background-color: rgba(169, 169, 169, 0.1) !important;
                  }
              `
            : isCurrent &&
              css`
                  .tui-full-calendar-month-week-item:nth-child(-n + ${pastRowsLength}) {
                      .tui-full-calendar-weekday-grid-line {
                          background-color: ${({ theme }) => theme.grey50} !important;
                      }
                  }

                  .tui-full-calendar-month-week-item:nth-child(${pastRowsLength + 1}) {
                      .tui-full-calendar-weekday-grid-line:nth-child(-n + ${({}) => new Date().getDay() - 1}) {
                          background-color: ${({ theme }) => theme.grey50} !important;
                      }
                  }

                  .tui-full-calendar-time-date:nth-child(-n + ${new Date().getDay() - 1}) {
                      background-color: rgba(169, 169, 169, 0.1) !important;
                  }
              `}

    .tui-full-calendar-weekday-schedule-block {
        width: 14% !important;
    }

    .tui-full-calendar-time-date,
    .tui-full-calendar-time-date-schedule-block-wrap,
    .tui-full-calendar-timegrid-schedules,
    .tui-full-calendar-timegrid-h-grid,
    .tui-full-calendar-timegrid-right,
    .tui-full-calendar-timegrid-hourmarker-time {
        height: 100%;
    }

    .tui-full-calendar-time-date-schedule-block-wrap {
        margin: 0 !important;
    }

    .tui-full-calendar-timegrid-timezone,
    .tui-full-calendar-timegrid-left {
        height: 100% !important;
    }

    .tui-full-calendar-timegrid-hour,
    .tui-full-calendar-timegrid-gridline {
        height: calc(100% * ${({ hourRangeCount }) => 1 / hourRangeCount}) !important;
    }

    .tui-full-calendar-dayname-container {
        overflow: auto;
    }

    .tui-full-calendar-vlayout-area {
        div:nth-child(1) {
            overflow: hidden;
        }
    }

    .tui-full-calendar-dayname-layout {
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 1;
        background: white;
    }

    .tui-full-calendar-time-schedule {
        strong {
            display: none;
        }
    }

    .tui-full-calendar-time-schedule-content {
        text-overflow: ellipsis;

        .video-call-icon {
            background-image: url(${({ videoCallIcon }) => videoCallIcon});
            display: inline-block;
            width: 18px;
            height: 15px;
            background-size: cover;
            filter: invert(1);
            transform: translateY(1px);
        }
    }

    ${({ calendarType }) =>
        calendarType === CalendarType.day
            ? css`
                  .tui-full-calendar-time-date-schedule-block {
                      width: 100% !important;
                      left: 0 !important;
                  }
              `
            : ''}
`;

export const CalendarLoading = styled.div<{ isLoading?: boolean }>`
    display: ${({ isLoading }) => (isLoading ? 'block' : 'none')};
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.1;
    z-index: 1;

    div {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

export const CalendarViewStyled = styled.div`
    display: grid;
    grid-template-columns: 1fr 4fr;
    input {
        margin-right: 10px;
    }
    .add-slot-icon {
        border-radius: 24px;
        height: 48px;
        margin: 10px;
        width: 160px;
        text-align: left;
        padding-left: 60px;
        position: relative;
        border: none;
        box-shadow: 0 2px 3px 0 rgb(60 64 67 / 25%), 0 6px 10px 4px rgb(60 64 67 / 15%);
        transition: 0.2s;
        &:hover {
            box-shadow: 0 2px 5px 0 rgb(60 64 67 / 80%), 0 6px 10px 4px rgb(60 64 67 / 15%);
            background: #eaeaea;
        }
        img {
            width: 24px;
            height: 24px;
            position: absolute;
            left: 20px;
            top: 11px;
        }
    }
    .services {
        margin-top: 20px;
        position: relative;

        button {
            font-size: 14px;
            font-weight: 600;
            letter-spacing: 0.2px;
            line-height: 20px;
            color: #3c4043;
            width: 100%;
            text-align: left;
            padding-right: 0;
            background: none;
            border: none;
            border-radius: 0;
            outline: none !important;
            position: relative;
            &.location {
                padding-left: 35px;
                padding-right: 35px;
            }
            img {
                width: 14px;
                height: 14px;
                fill: grey;
                position: absolute;
                right: 10px;
                top: 9px;
            }

            &:focus {
                background: none;
                outline: none !important;
                border: none;
                box-shadow: none;
            }
            &:active {
                box-shadow: none !important;
                color: #3c4043 !important;
                background-color: transparent !important;
                border-color: transparent !important;
            }
            &:hover {
                background: #e4e5e7;
            }
        }
        .service {
            position: relative;
            cursor: pointer;

            &.location {
                padding-left: 20px;
            }

            &:hover {
                background: #e4e5e7;
            }

            input {
                transform: translateY(4px);
            }

            label {
                display: inline;
                font-weight: 200 !important;
                font-size: 14px;
                transform: translateY(3px);
                cursor: pointer;
                user-select: none;
            }
        }
    }

    input {
        display: inline-block;
        height: 18px;
        width: 18px;
        appearance: none;
        border: 1px solid #34495e;
        border-radius: 4px;
        outline: none;
        transition-duration: 0.3s;
        background-color: white;
        cursor: pointer;
        text-align: center;
        transform: translateY(6px);
        margin-left: 10px;

        &.location-checkbox {
            position: absolute;
            z-index: 10;
            left: 0;
            top: 0;
        }

        &:checked {
            &:before {
                content: '\\2714';
                color: white;
                position: absolute;
                left: 2px;
                top: -3px;
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        display: none;
    }
`;
