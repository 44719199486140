import React, { useState } from 'react';
import {
    Button,
    FormSeparator,
    InformationBubble,
    InformationBubbleTypes,
    ModalTitle,
    TextArea,
} from '../../../../../medrefer-web-sdk/web-kit';
import { Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const MailPlaceholderTextModal = () => {
    const { t } = useTranslation();
    const [copied, SetCopy] = useState(false);

    const copyDefaultEmail = () => {
        const copyGfGText = document.getElementById('emailDefaultText') as HTMLInputElement;
        copyGfGText.select();
        navigator.clipboard.writeText(copyGfGText.value);
        SetCopy(true);
    };

    return (
        <>
            <ModalTitle>{t('organizationProfileDisplay:email_template_modal_title')}</ModalTitle>
            <FormSeparator />
            <TextArea style={{ height: 580 }} disabled={true} id={'emailDefaultText'}>
                {'<p>\n' +
                    '    Data protection is of a particularly high priority for the MedRefer GmbH.\n' +
                    '    You can find the privacy policy <a href="https://www.medrefer.de/en/privacy-policy" target="_blank">here</a>.\n' +
                    '    Should you have any questions on data protection of your personal data,\n' +
                    '    don’t hesitate to reach out to <a href="mailto:info@medrefer.de">info@medrefer.de</a>\n' +
                    '    or via our <a href="https://www.medrefer.de/en#contact" target="_blank">contact form</a>.\n' +
                    '</p>\n' +
                    '<hr>\n' +
                    '<a href="https://app.medrefer.de/">https://app.medrefer.de/</a>\n' +
                    '<p>\n' +
                    '    MedRefer GmbH, Glashütter Str. 45a, 01309, Dresden, Deutschland\n' +
                    '</p>'}
            </TextArea>
            {copied && (
                <InformationBubble style={{ marginTop: 5 }} type={InformationBubbleTypes.success}>
                    {t('organizationProfileDisplay:copy_message')}
                </InformationBubble>
            )}
            <Col xs={12} md={6} className="ml-auto mr-auto" style={{ marginTop: 10, marginBottom: -20 }}>
                <Button type={'button'} onClick={copyDefaultEmail}>
                    {t('organizationProfileDisplay:copy_button')}
                </Button>
            </Col>
        </>
    );
};
