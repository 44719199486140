import React from 'react';
import moment from 'moment';
import { formatDate, formatDateTime } from 'utils/dates';
import { ListItem } from './AppointmentCard.styled';
import { AppointmentModel, SlotMode } from 'medrefer-web-sdk/api/models';
import { ReactSVG } from 'react-svg';
import VideoCamIcon from 'assets/VideocamIcon.svg';
import { StatusBadge } from 'components/Dashboard/StatusBadge';
import { DateDistanceToNow } from 'medrefer-web-sdk/web-kit';
import { useTranslation } from 'react-i18next';

interface Props {
    appointment: AppointmentModel;
    isActive: boolean;
    onClick: () => void;
}

export const AppointmentCard = ({ appointment, isActive, onClick }: Props) => {
    const { t } = useTranslation();
    const { healthcare_service, date_from, patient_metadata } = appointment;
    const { full_name, date_of_birth } = patient_metadata;

    const dateFrom = moment(date_from);
    const timeValue = formatDateTime(dateFrom);

    return (
        <ListItem active={isActive} onClick={onClick}>
            <div>
                <span className={'full-name'}>{full_name}</span>
                <span className={'date-of-birth'}>{date_of_birth ? formatDate(moment(date_of_birth)) : ''}</span>
                <div>
                    <span>{healthcare_service.name}</span>
                </div>
                <span className="d-inline-flex date-from" style={{ maxWidth: 260 }}>
                    {timeValue}
                </span>
            </div>
            <div className="d-flex flex-column">
                <div className="ml-auto d-flex status-badge-container">
                    {appointment.mode === SlotMode.ONLINE && (
                        <div className="d-flex video-call">
                            <ReactSVG src={VideoCamIcon} className="icon" />
                            <span>{t('calendar:mode.online')}</span>
                        </div>
                    )}
                    <StatusBadge appointment={appointment} />
                </div>
                {date_from !== null && <DateDistanceToNow date={moment(date_from).toDate()} />}
            </div>
        </ListItem>
    );
};
