import { WithTranslation } from 'react-i18next';
import { Frequency, Weekday } from 'medrefer-web-sdk/api/models';
import RRule from 'rrule';

/**
 *  Defines possible options for recurring slot ending conditions
 */
export enum EndsOnOption {
    never = 'never',
    onDay = 'onDay',
    afterX = 'afterX',
}

export interface FormValues {
    interval: number;
    freq: Frequency;
    days: Weekday[];
    endsOn: EndsOnOption;
    until?: Date;
    count?: number;
}

export interface CustomRepeatFormProps extends WithTranslation {
    recurringSchema?: RRule;
    onSubmit: (pattern: RRule) => void;
}
