import React from 'react';
import { H1, InformationBubble, InformationBubbleTypes } from 'medrefer-web-sdk/web-kit';

import { Col, Container } from 'react-bootstrap';
import { QuestionnaireResponseForm } from './components/QuestionnaireResponseForm';
import { Redirect } from 'react-router';
import RedirectHome from 'components/shared/routes/RedirectHome';
import useBrand from 'hooks/useBrand';
import { useQuestionnaireResponseData } from './hooks/useQuestionnaireResponseData';
import { PageActivityIndicator } from 'components/shared/PageActivityIndicator';
import { useTranslation } from 'react-i18next';

export const PatientQuestionnaire = () => {
    const { t } = useTranslation();
    const { getPathBranded, featureEnabled } = useBrand();
    const { questionnaireResponse, isLoading, isError, readOnly, submitSuccess, onSubmit } =
        useQuestionnaireResponseData();

    if (!featureEnabled('questionnaires')) {
        return <RedirectHome />;
    }

    if (isLoading) {
        return <PageActivityIndicator />;
    }

    if (submitSuccess) {
        return <Redirect to={getPathBranded('patientPanel')} />;
    }

    return (
        <Container fluid>
            {questionnaireResponse && (
                <Col xs={12} md={10} className="mt-5 mx-auto p-5" style={{ maxWidth: 800, backgroundColor: 'white' }}>
                    <H1 className="mb-4">{questionnaireResponse.questionnaire.title}</H1>
                    <QuestionnaireResponseForm
                        config={questionnaireResponse.questionnaire.content}
                        content={questionnaireResponse.content}
                        onSubmit={onSubmit}
                        readOnly={readOnly}
                    />
                </Col>
            )}
            {isError && (
                <Col xs={12} md={10} className="mt-5 mx-auto" style={{ maxWidth: 600 }}>
                    <InformationBubble type={InformationBubbleTypes.warning}>
                        {t('questionnaire:link_invalid')}
                    </InformationBubble>
                </Col>
            )}
        </Container>
    );
};
