import React from 'react';
import { FormAsyncSelect, Option } from 'medrefer-web-sdk/web-kit';
import { api } from 'medrefer-web-sdk/api';
import { UserProfile, UserProfileOrganization } from 'medrefer-web-sdk/api/models';
import { Field, FormikProps, withFormik } from 'formik';
import useBrand from 'hooks/useBrand';

interface FormProps {
    user: UserProfile;
}

interface FormValues {
    profile?: string;
}

const InnerForm = (props: FormProps & FormikProps<FormValues>) => {
    const { redirectToBrand } = useBrand();

    const toUserProfileOption = (user: UserProfileOrganization) => {
        return {
            value: user.organization?.slug,
            label: user.organization?.name,
        };
    };

    const loadUserProfileOptions = async (search: string, loadedOptions: Option[]) => {
        const res = await api.getUserProfiles({
            limit: 20,
            offset: loadedOptions.length,
            search,
        });

        return {
            options: res.results.map(toUserProfileOption),
            hasMore: res.next != null,
        };
    };

    const handleProfileChange = (option: Option) => {
        redirectToBrand(option.value);
    };

    return (
        <Field
            name="profile"
            loadOptions={loadUserProfileOptions}
            initialOptions={[toUserProfileOption(props.user)]}
            component={FormAsyncSelect}
            onBlur={props.handleBlur}
            afterChange={handleProfileChange}
        />
    );
};

const UserProfileSelect = withFormik<FormProps, FormValues>({
    mapPropsToValues: (props) => ({
        profile: props.user.organization?.slug,
    }),
    handleSubmit: () => null,
})(InnerForm);

export default UserProfileSelect;
