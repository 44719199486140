import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    ClickableText,
    EmptyPlaceholder,
} from 'components/shared/ServiceSlots/CalendarTimeline/CalendarTimeline.styled';
import moment from 'moment';

export const NearestDateNavigator = ({ date, onClick }: { date: Date; onClick: () => void }) => {
    const { t } = useTranslation();
    return (
        <EmptyPlaceholder>
            <div>
                {t('publicSearch:calendar_range_empty')}
                <span> | </span>
                <ClickableText onClick={onClick}>
                    {t('publicSearch:nearest_slot_on')} {moment(date).format('MMMM D, YYYY')}
                </ClickableText>
            </div>
        </EmptyPlaceholder>
    );
};
