import { FormProps } from './PatientInvitationForm.types';
import * as Yup from 'yup';

export const mapPropsToValues = () => ({
    email: '',
    firstName: '',
    lastName: '',
    personalTitle: '',
    phone: '',
    patient: {
        date_of_birth: '',
    },
});

export const validationSchema = ({ t }: FormProps) =>
    Yup.object().shape({
        email: Yup.string()
            .max(50, t('loginForm:warning_login_max'))
            .email(t('loginForm:warning_login_matches'))
            .required(t('loginForm:warning_email_required')),
        firstName: Yup.string()
            .matchesName(t('newAppointmentForm:warning_first_name_matches'))
            .required(t('newAppointmentForm:warning_first_name_required')),
        lastName: Yup.string()
            .matchesName(t('newAppointmentForm:warning_last_name_matches'))
            .required(t('newAppointmentForm:warning_last_name_required')),
        phone: Yup.string().matchesPhone(t('newAppointmentForm:warning_phone_matches')),
        patient: Yup.object().shape({
            date_of_birth: Yup.date()
                .typeError(t('newAppointmentForm:warning_date_of_birth_type'))
                .required(t('newAppointmentForm:warning_date_of_birth_required')),
        }),
    });
