import React from 'react';
import { Slot, SlotSimplified, SlotStatus } from 'medrefer-web-sdk/api/models';
import { BusySlot, EmptySlot, FreeSlot } from 'components/shared/ServiceSlots/CalendarTimeline/CalendarTimeline.styled';
import { formatTime } from 'utils/dates';
import moment from 'moment';

export const SlotTime = ({
    slot,
    handleSlotClick,
}: {
    slot: SlotSimplified | Slot | undefined;
    handleSlotClick: (slotKey: string) => void;
}) => {
    if (slot == null) {
        return <EmptySlot>-</EmptySlot>;
    }

    const timeString = formatTime(moment(slot.date_from));

    if (slot.status === SlotStatus.BUSY) {
        return <BusySlot>{timeString}</BusySlot>;
    }

    if (new Date(slot.date_from) < new Date()) {
        return <FreeSlot disabled>{timeString}</FreeSlot>;
    }

    return <FreeSlot onClick={() => handleSlotClick(slot.key)}>{timeString}</FreeSlot>;
};
