export interface AppointmentStep<T extends AppointmentStepType = AppointmentStepType> {
    id: number;
    type: T;
    status: AppointmentStepStatus;
    step_details: AppointmentStepDetailsMap[T];
}

export enum AppointmentStepStatus {
    pending = 'PENDING',
    completed = 'COMPLETED',
    enteredInError = 'ENTERED_IN_ERROR',
}

export enum AppointmentStepType {
    stripePayment = 'stripe_payment',
}

type AppointmentStepDetailsMap = {
    [AppointmentStepType.stripePayment]: StripePaymentStepDetails;
};

export interface StripePaymentStepDetails {
    session_id: string;
    intent_client_secret: string;
}
