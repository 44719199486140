import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

interface Breadcrumb {
    label: ReactNode;
    path: string;
    disabled?: boolean;
}

interface BreadcrumbsProps {
    breadcrumbs: Breadcrumb[];
}

export const Breadcrumbs = ({ breadcrumbs }: BreadcrumbsProps) => {
    return (
        <BreadcrumbsContainer>
            {breadcrumbs.map(({ label, path, disabled }, index) => (
                <React.Fragment key={index}>
                    <BreadcrumbItem key={path} to={path} disabled={disabled}>
                        {label}
                    </BreadcrumbItem>
                    {index !== breadcrumbs.length - 1 && <BreadcrumbDivider>/</BreadcrumbDivider>}
                </React.Fragment>
            ))}
        </BreadcrumbsContainer>
    );
};

const BreadcrumbsContainer = styled.div`
    width: 100%;
    padding: 20px 0;
    display: flex;
    gap: 10px;
`;
const BreadcrumbItem = styled(Link)<{ disabled?: boolean }>`
    color: ${({ theme }) => theme.accent};
    text-decoration: none;

    &:hover {
        color: ${({ theme }) => theme.accent};
        text-decoration: none;
    }

    ${({ disabled }) =>
        disabled &&
        css`
            color: grey;
            cursor: default;
            pointer-events: none;
        `};
`;

const BreadcrumbDivider = styled.span``;
