import { useState } from 'react';
import { api } from 'medrefer-web-sdk/api';
import { useAttachDocuments } from 'hooks/useAttachDocuments';
import { LoginData, PatientRegistrationData, UserUpdateData } from 'medrefer-web-sdk/api/models';
import { SlotBookingFormData, SlotBookingStep } from 'components/PublicSearch/SlotBooking/SlotBooking.types';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser, getBrand } from 'features/auth/selectors';
import { useQueryParams, useTemporaryState } from 'medrefer-web-sdk/web-kit/hooks';
import { loginUser, registerPatientUser } from 'features/auth/actions';
import useApiRequest from 'hooks/useApiRequest';
import { errorTimeout, messageTimeout } from 'utils/constants';
import { formatISODate } from 'medrefer-web-sdk/api/utils';
import moment from 'moment';
import { store } from 'store';
import { authActions } from 'features/auth';
import { toNullableDate } from 'utils/dates';

const useSlotBooking = () => {
    const authUser = useSelector(getAuthUser);
    const [submittedValues, setSubmittedValues] = useState<SlotBookingFormData | null>(null);
    const [bookingCompleted, setBookingCompleted] = useState<boolean>(false);
    const [newAppointmentId, setNewAppointmentId] = useState<number>(0);
    const [otpCodeSent, setOtpCodeSent] = useTemporaryState(false, messageTimeout);
    const attachDocumentsHandler = useAttachDocuments();
    const request = useApiRequest({ errorTimeout });
    const dispatch = useDispatch();
    const queryParams = useQueryParams();
    const organization = useSelector(getBrand);

    const checkEmailTaken = async (data: SlotBookingFormData) => {
        return request.dispatch(api.checkEmailTaken(data.email));
    };

    const submitLoginData = async (data: SlotBookingFormData) => {
        await request.dispatch(
            api.requestLoginOtp({
                email: data.email,
                password: data.password,
                organization_id: organization?.id,
            }),
        );
        setOtpCodeSent(true);
    };

    const submitRegisterData = async (data: SlotBookingFormData) => {
        await request.dispatch(api.requestOtp(data.email, organization?.id));
        setOtpCodeSent(true);
    };

    const submitLoginOtpCode = async (data: SlotBookingFormData) => {
        setOtpCodeSent(false);
        await performLogin(data);
        await requestConfirmation(data);
    };

    const requestConfirmation = async (data: SlotBookingFormData) => {
        const user = store.getState().auth.user;
        const dts: SlotBookingFormData = {
            ...data,
            mode: data.mode,
            reason: data.reason,
            comment: data.comment,
            step: user ? SlotBookingStep.confirmation : SlotBookingStep.registerConfirmation,
            first_name: user?.first_name || data.first_name,
            last_name: user?.last_name || data.last_name,
            city: user?.patient?.city || data.city,
            street: user?.patient?.street || data.street,
            phone: user?.phone || data.phone,
            insurance_number: user?.patient?.insurance_number || data.insurance_number,
            insurance_status: user?.patient?.insurance_status || data.insurance_status,
            date_of_birth: toNullableDate(user?.patient?.date_of_birth) || data.date_of_birth,
            personal_title: user?.personal_title || data.personal_title,
        };
        setSubmittedValues(dts);
    };

    const discardConfirmation = () => {
        setSubmittedValues(null);
    };

    const submitConfirmation = async () => {
        if (!submittedValues) {
            return;
        }
        setSubmittedValues(null);
        if (authUser) {
            if (authUser.phone == '' && submittedValues.phone !== '') {
                const patient = authUser.patient;
                await updateProfile({
                    firstName: authUser.first_name,
                    lastName: authUser.last_name,
                    phone: submittedValues.phone,
                    personalTitle: authUser.personal_title,
                    role: authUser.role,
                    dateOfBirth: patient?.date_of_birth ? new Date(patient?.date_of_birth) : null,
                    postCode: patient ? patient.post_code : '',
                    city: patient ? patient.city : '',
                    street: patient ? patient.street : '',
                    insuranceNumber: patient ? patient.insurance_number : '',
                    insuranceCompany: patient ? patient.insurance_company : undefined,
                    insuranceStatus: patient ? patient.insurance_status : undefined,
                    smsAgreement: patient ? patient.sms_agreement : false,
                    emailAgreement: patient ? patient.email_agreement : false,
                });
            }
            await performSlotBooking(submittedValues);
        } else if (
            [SlotBookingStep.registerOtpCode, SlotBookingStep.registerConfirmation].includes(submittedValues.step)
        ) {
            await performRegistration(submittedValues);
            await performSlotBooking(submittedValues);
        }
    };

    const updateProfile = async (data: UserUpdateData) => {
        await dispatch(authActions.updateUserData(data));
    };

    const performLogin = async (data: SlotBookingFormData) => {
        await request.dispatch(
            dispatchLogin({
                email: data.email,
                password: data.password,
                token: data.token,
            }),
        );
    };

    const dispatchLogin = async (data: LoginData) => {
        await dispatch(loginUser(data));
    };

    const performRegistration = async (data: SlotBookingFormData) => {
        await request.dispatch(
            dispatchRegister({
                email: data.email,
                password: data.password,
                firstName: data.first_name,
                lastName: data.last_name,
                phone: data.phone || '',
                token: data.token,
                personalTitle: data.personal_title ? data.personal_title : '',
                patient: {
                    date_of_birth: formatISODate(moment(data.date_of_birth)),
                    insurance_number: data.insurance_number,
                    insurance_status: data.insurance_status,
                    sms_agreement: data.sms_agreement,
                    email_agreement: data.email_agreement,
                    street: data.street,
                    city: data.city,
                },
            }),
        );
    };

    const dispatchRegister = async (data: PatientRegistrationData) => {
        await dispatch(registerPatientUser(data));
    };

    const performSlotBooking = async (data: SlotBookingFormData) => {
        const criteriaValues = queryParams.get('criteria') || '';
        data.criteria_values = criteriaValues.split(',').filter(Boolean);
        const appointment = await request.dispatch(api.bookSlot(data));
        if (attachDocumentsHandler.attachments.length) {
            await attachDocumentsHandler.uploadAttachments(appointment);
        }
        setNewAppointmentId(appointment.id);
        setBookingCompleted(true);
    };

    return {
        submittedValues,
        request,
        attachDocumentsHandler,
        bookingCompleted,
        newAppointmentId,
        otpCodeSent,
        checkEmailTaken,
        submitLoginData,
        submitRegisterData,
        submitLoginOtpCode,
        requestConfirmation,
        discardConfirmation,
        submitConfirmation,
    };
};

export default useSlotBooking;
