import React, { useEffect, useMemo, useState } from 'react';
import { ArrowButton, GreyOutlineButton, Option, Select, SingleValue } from 'medrefer-web-sdk/web-kit';
import { ButtonsContainer } from './CalendarMenu.styled';
import { useTranslation } from 'react-i18next';
import { CalendarType } from '../CalendarView.types';

interface Props {
    handleToday: () => void;
    handlePrev: () => void;
    handleNext: () => void;
    calendarType: CalendarType;
    dateFormatted: string;
    handleCalendarTypeChange: (newValue: CalendarType) => void;
}

export const CalendarMenu = (props: Props) => {
    const { t } = useTranslation();
    const calendarOptions = useMemo(() => {
        return [
            { value: 'day', label: t('calendar:calendar_type_day') },
            { value: 'week', label: t('calendar:calendar_type_week') },
            { value: 'month', label: t('calendar:calendar_type_month') },
        ];
    }, [t]);

    const [calendarOption, setCalendarOption] = useState<Option | null>(null);

    useEffect(() => {
        const newOption = calendarOptions.find((option) => option.value === props.calendarType);
        setCalendarOption(newOption || calendarOptions[1]);
    }, [calendarOptions, props.calendarType]);

    const onOptionChange = (option: SingleValue<Option>) => {
        if (option) {
            props.handleCalendarTypeChange(option.value.toString() as CalendarType);
            setCalendarOption(option);
        }
    };

    return (
        <ButtonsContainer>
            <GreyOutlineButton onClick={props.handleToday}> {t('calendar:today')}</GreyOutlineButton>
            <div style={{ width: 'fit-content' }}>
                <ArrowButton direction="left" onClick={props.handlePrev} />
                <ArrowButton direction="right" onClick={props.handleNext} />
            </div>
            <span>{props.dateFormatted}</span>
            <div style={{ marginLeft: 'auto', marginRight: '15px' }}>
                <Select
                    name={'type'}
                    value={calendarOption}
                    onChange={onOptionChange}
                    placeholder={t('calendar:type')}
                    options={calendarOptions}
                    isSearchable={false}
                />
            </div>
        </ButtonsContainer>
    );
};
