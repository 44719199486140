import styled from 'styled-components';

interface Props {
    active?: boolean;
}

export const ListItem = styled.div<Props>`
    width: 100%;
    min-height: 106px;
    display: flex;
    justify-content: space-between;
    padding: 16px 28px 18px 32px;
    box-shadow: 0px 1px 5px rgba(44, 51, 71, 0.12);
    background-color: ${(props) => (props.active ? props.theme.accent : '#fafafc')};

    .status-badge-container {
        gap: 10px;

        .video-call {
            gap: 3px;
            font-size: 13px;
            align-items: center;
            transform: translateY(-1px);
            color: ${(props) => (props.active ? '#FFFFFF' : '#000000')};
            svg {
                transform: translateY(-1px);
                width: 20px;
                fill: ${(props) => (props.active ? '#FFFFFF' : '#000000')};
            }
        }
    }

    &:nth-of-type(2n + 1) {
        background-color: ${(props) => (props.active ? props.theme.accent : '#FFFF')};
    }
    > div {
        display: flex;
        flex-direction: column;
        &:first-of-type {
            > span {
                &.full-name {
                    font-weight: bold;
                    font-size: 13px;
                    line-height: 18px;
                    color: ${(props) => (props.active ? '#FFFFFF' : '#000000')};
                }
                &.date-of-birth {
                    display: block;
                    height: 18px;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 18px;
                    color: ${(props) => (props.active ? '#FFFFFF' : '#252122')};
                }
                &.date-from {
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 14px;
                    color: ${(props) => (props.active ? '#FFFFFF' : '#9d9eb2')};
                }
            }
            > div {
                span {
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 14px;
                    color: ${(props) => (props.active ? '#FFFFFF' : '#9d9eb2')};
                    padding: 0 10px;
                    &:first-of-type {
                        padding-left: 0;
                    }
                }
            }
        }
        &:last-of-type {
            justify-content: space-between;
            > span {
                &:last-of-type {
                    font-weight: 600;
                    font-size: 10px;
                    line-height: 14px;
                    color: ${(props) => (props.active ? '#FFFFFF' : '#9d9eb2')};
                    text-align: end;
                }
            }
        }
    }
    @media (min-width: ${({ theme }) => theme.devices.desktop + 1}) {
        &:hover {
            background: ${(props) => (props.active ? props.theme.accent : '#F4F4FF')};
            cursor: pointer;
        }
    }

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        background-color: #fafafc;
        &:nth-of-type(2n + 1) {
            background-color: #ffff;
        }
        &:active {
            background: #dbdcee;
        }

        > div {
            &:first-of-type {
                > span {
                    &:nth-of-type(1) {
                        color: #000000;
                    }
                    &:nth-of-type(2) {
                        color: #252122;
                    }
                    &:nth-of-type(3) {
                        color: #9d9eb2;
                    }
                }
                > div {
                    span {
                        color: #9d9eb2;
                    }
                }
            }
            &:last-of-type {
                > span {
                    &:last-of-type {
                        color: #9d9eb2;
                    }
                }
            }
        }
    }
`;
