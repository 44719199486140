import { useDefaultSearchValues } from 'components/PublicSearch/LandingSearch/hooks/useDefaultSearchValues';
import { GeneralSearchItemType } from 'medrefer-web-sdk/api/models';

export interface CriteriaSearchContext {
    healthcareServiceKey?: string;
}

export const useCriteriaSearchContext = (): CriteriaSearchContext => {
    const defaultValues = useDefaultSearchValues();

    let healthcareServiceKey;

    if (defaultValues?.generalSearchOption?.type === GeneralSearchItemType.healthcare_service) {
        healthcareServiceKey = defaultValues.generalSearchOption.value;
    }

    return {
        healthcareServiceKey,
    };
};
