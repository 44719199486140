import styled from 'styled-components';

interface StepsProps {
    stepCount: number;
    activeStep?: number;
    isActive?: boolean;
}

export const ProgressStepsContainer = styled.div`
    position: relative;
    padding-top: 20px;
    padding-bottom: 40px;
    text-align: center;
    font-size: 14px;
`;

export const ProgressLine = styled.span`
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0;
    top: 41px;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.grey200};
`;

export const ProgressLineFilled = styled(ProgressLine)<StepsProps>`
    width: ${({ stepCount, activeStep }) =>
        activeStep === stepCount
            ? '100%'
            : activeStep
            ? `${18 + (64 / stepCount) * activeStep - 64 / (stepCount * 2)}%`
            : '0%'};
    background-color: ${({ theme }) => theme.accent};

    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        width: ${({ stepCount, activeStep }) =>
            activeStep === stepCount
                ? '100%'
                : activeStep
                ? `${5 + (90 / stepCount) * activeStep - 64 / (stepCount * 2)}%`
                : '0%'};
    }
`;

export const StepsList = styled.ul`
    list-style-type: none;
    position: relative;
    display: inline-block;
    width: 64%;
    margin: 0 auto;
    padding: 0;

    @media (max-width: ${({ theme }) => theme.devices.tablet}) {
        width: 90%;
    }
`;

export const Step = styled.li<StepsProps>`
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: ${({ stepCount }) => `${100 / stepCount}%`};
    height: 60px;
    padding-top: 40px;
    text-align: center;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const StepDot = styled.span<StepsProps>`
    position: absolute;
    display: block;
    width: 30px;
    height: 30px;
    left: 50%;
    top: 34%;
    margin: 0;
    transform: translate(-50%, -50%);
    background-color: ${({ isActive, theme }) => (isActive ? theme.accent : theme.white)};
    border: 2px solid ${({ isActive, theme }) => (isActive ? theme.accent : theme.grey200)};
    border-radius: 30px;
    color: #fff;
    padding: 3px;
`;

export const StepText = styled.p``;
