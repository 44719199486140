import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { pageStyles } from '../Auth.styled';
import PasswordRecoveryForm from './PasswordRecoveryForm';
import { AuthPage } from 'components/Auth/AuthPage';
import useApiRequest from 'hooks/useApiRequest';
import { api } from 'medrefer-web-sdk/api';
import useBrand from 'hooks/useBrand';
import { errorTimeout } from 'utils/constants';

export const ForgotPassword: FC = () => {
    const { t } = useTranslation();
    const request = useApiRequest({ errorTimeout });
    const { organization, getPathBranded } = useBrand();

    const onSubmit = (email: string) => {
        organization?.id && request.dispatch(api.requestPasswordReset(email));
    };

    return (
        <AuthPage pageTitle={t('forgotPasswordPage:h1')}>
            <PasswordRecoveryForm onSubmit={onSubmit} request={request} />
            <div className="mt-5">
                <span style={pageStyles.message}>{t('forgotPasswordPage:message')}</span>
                <Link to={getPathBranded('login')} style={pageStyles.message_cta}>
                    {t('forgotPasswordPage:message_cta')}
                </Link>
            </div>
        </AuthPage>
    );
};
