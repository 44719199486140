import { Attachment } from './Attachment';
import { HealthcareService, HealthcareServiceGroup } from './HealthcareService';
import { AppointmentReason } from './AppointmentReason';
import { Organization } from './Organization';
import { UserPatientProfile } from './User';
import { PatientMetadata } from './PatientMetadata';
import { Proposal } from './Proposal';
import { SlotCriteria } from './SlotsCriteria';
import { AppointmentModel } from './AppointmentModel';
import { UserPractitioner } from './Practitioner';

export enum AppointmentRequestStatus {
    PENDING = 'Pnd',
    RESPONDED = 'Rsp',
    APPROVED = 'Apr',
    CANCELED = 'Can',
    REJECTED = 'Rej',
}
export enum AppointmentRequestTime {
    MORNING = 'Mrn',
    AFTERNOON = 'Aft',
    ALL_DAY = 'Ad',
    NONE = 'None',
}
export interface AppointmentRequestsModel {
    id: number;
    available_time: AppointmentRequestTime[];
    proposal?: Proposal;
    patient?: UserPatientProfile;
    patient_metadata: PatientMetadata;
    healthcare_service: HealthcareService;
    healthcare_service_group: HealthcareServiceGroup;
    managing_user?: UserPractitioner;
    organization: Organization;
    reason: AppointmentReason;
    comment: string;
    attachments: Attachment[];
    status: AppointmentRequestStatus;
    criteria: SlotCriteria[];
    isChecked?: boolean;
    appointment?: AppointmentModel;
}

export interface RequestCountData {
    Pnd: number;
    Can: number;
    Rsp: number;
    Apr: number;
    Rej: number;
}
export interface AppointmentRequestsDataPage {
    next: string;
    appointment_requests: AppointmentRequestsModel[];
    totalCount: number;
    metaData: RequestCountData;
}
