/* eslint-disable no-unused-vars */
import React, { ReactElement } from 'react';
import { IconButton, IconButtonAlt } from '../buttons';
import TrashIcon from '../../assets/icons/TrashIcon.png';
import PlusClircleIcon from '../../assets/icons/PlusCircleIcon.png';
import styled from 'styled-components';

export enum DateFieldActionTypes {
    add = 'add',
    delete = 'delete',
}

interface Props {
    click?: () => void;
    actionType: DateFieldActionTypes;
}

const DateFieldContainer = styled.div`
    margin: 10px 0;
`;

export const DateFieldButton = ({ click, actionType }: Props): ReactElement => {
    const handleClick = (e: React.SyntheticEvent) => {
        e.preventDefault();
        click && click();
    };

    return (
        <DateFieldContainer>
            {actionType === DateFieldActionTypes.add ? (
                <IconButton onClick={(e: React.SyntheticEvent) => handleClick(e)}>
                    <img src={PlusClircleIcon} alt="Button icon" />
                </IconButton>
            ) : (
                <IconButtonAlt onClick={(e: React.SyntheticEvent) => handleClick(e)}>
                    <img src={TrashIcon} alt="Button icon" />
                </IconButtonAlt>
            )}
        </DateFieldContainer>
    );
};
