const version = process.env.APP_VERSION;
const STORAGE_KEY = `__SERIALIZED_STATE_TREE_v${version}__`;

// eslint-disable-next-line @typescript-eslint/ban-types
export function saveState<T = object>(storeState: T): boolean {
    if (!localStorage) {
        return false;
    }

    try {
        const serializedState = JSON.stringify(storeState);
        setStorageItem(STORAGE_KEY, serializedState);
        return true;
    } catch (error) {
        throw new Error('store serialization failed');
    }
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function loadState<T = object>(): T | undefined {
    if (!localStorage) {
        return;
    }

    try {
        const serializedState = getStorageItem(STORAGE_KEY);
        if (serializedState == null) {
            return;
        }
        return JSON.parse(serializedState);
    } catch (error) {
        throw new Error('store deserialization failed');
    }
}

export const setStorageItem = (key: string, value: string): void => {
    localStorage.setItem(key, value);
};

export const getStorageItem = (key: string): string | null => {
    return localStorage.getItem(key);
};

export const removeStorageItem = (key: string): void => {
    localStorage.removeItem(key);
};
