import React from 'react';
import { RouteComponentProps } from 'react-router';
import { BookingContainer } from '../LandingSearch/LandingSearch.styled';
import { Col, Container, Row } from 'react-bootstrap';
import BookingForm from './BookingForm/BookingForm';
import { BookingSlotWarning } from 'components/PublicSearch/SlotBooking/BookingSlotWarning';
import useSlot from './useSlot';
import useSlotBooking from './useSlotBooking';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthUser, getBrand } from 'features/auth/selectors';
import { SlotCriteriaType, SlotStatus } from 'medrefer-web-sdk/api/models';
import { useTranslation } from 'react-i18next';
import { PageActivityIndicator } from 'components/shared/PageActivityIndicator';
import useBrand from 'hooks/useBrand';
import { H1 } from 'medrefer-web-sdk/web-kit';
import { SlotBookingProgress } from 'components/PublicSearch/SlotBookingProgress';
import { getFullName } from 'medrefer-web-sdk/api/utils';
import { formatDateTime } from 'utils/dates';
import moment from 'moment';
import { useOptionalFields } from '../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';

interface MatchParams {
    slotKey: string;
}

export const SlotBooking = ({ match }: RouteComponentProps<MatchParams>) => {
    const { t } = useTranslation();
    const slot = useSlot(match.params.slotKey);
    const {
        submittedValues,
        request,
        attachDocumentsHandler,
        bookingCompleted,
        newAppointmentId,
        otpCodeSent,
        checkEmailTaken,
        submitLoginData,
        submitRegisterData,
        submitLoginOtpCode,
        requestConfirmation,
        discardConfirmation,
        submitConfirmation,
    } = useSlotBooking();
    const organization = useSelector(getBrand);
    const { getFields, getMandatoryFields } = useOptionalFields(organization);
    const { getPathBranded, featureEnabled } = useBrand();
    const authUser = useSelector(getAuthUser);

    if (!slot) {
        return <PageActivityIndicator />;
    }

    if (bookingCompleted && newAppointmentId) {
        return <Redirect to={getPathBranded('completeAppointmentSteps', newAppointmentId.toString())} />;
    }

    const isSlotFree = slot.status === SlotStatus.FREE;

    const criteriaPractitionerName = slot.criteria.find(
        (slotCriteria) => slotCriteria.type === SlotCriteriaType.PRACTITIONER,
    )?.values[0].name;

    return (
        <>
            <SlotBookingProgress
                activeStep="appointmentForm"
                includePaymentStep={featureEnabled('stripePayments') && slot.price != null}
            />
            <Container className="justify-content-center">
                <Col className="ml-auto mr-auto mt-5">
                    <Row>
                        <H1 className="mb-4" style={{ paddingLeft: '15px' }}>
                            {}
                            {criteriaPractitionerName
                                ? t('slotBooking:appointment_with') + ' ' + criteriaPractitionerName + ' '
                                : slot.managing_user && slot.managing_user.first_name
                                ? t('slotBooking:appointment_with') + ' ' + getFullName(slot.managing_user) + ' '
                                : t('slotBooking:appointment') + ' '}
                            {t('slotBooking:on') + ' '}
                            {formatDateTime(moment(slot.date_from))}
                        </H1>
                    </Row>
                    <BookingContainer>
                        <Col>
                            {isSlotFree ? (
                                <BookingForm
                                    user={authUser}
                                    request={request}
                                    slot={slot}
                                    otpCodeSent={otpCodeSent}
                                    checkEmailTaken={checkEmailTaken}
                                    submitLoginData={submitLoginData}
                                    submitRegisterData={submitRegisterData}
                                    submitLoginOtpCode={submitLoginOtpCode}
                                    requestConfirmation={requestConfirmation}
                                    attachDocumentsHandler={attachDocumentsHandler}
                                    submittedValues={submittedValues}
                                    onDiscard={discardConfirmation}
                                    onConfirm={submitConfirmation}
                                    fields={getFields()}
                                    mandatory_fields={getMandatoryFields()}
                                />
                            ) : (
                                <BookingSlotWarning text={t('slotBooking:warning_slot_not_available')} />
                            )}
                        </Col>
                    </BookingContainer>
                </Col>
            </Container>
        </>
    );
};
