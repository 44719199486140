import { Col } from 'react-bootstrap';
import { FormValue } from '../FormValue';
import { formatISODate, getFullName } from '../../../medrefer-web-sdk/api/utils';
import { FormSeparator } from '../../../medrefer-web-sdk/web-kit';
import moment from 'moment';
import { EOptionalFields, useOptionalFields } from '../ServiceSlots/RequestWaitingRoomModal/useOptionalFields';
import { CustomRow } from '../ConfirmBookingModal/ConfirmBookingModal.styled';
import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AdminOrganization,
    AppointmentModel,
    HealthcareService,
    HealthcareServiceSlots,
    UserPractitioner,
    UserProfile,
} from '../../../medrefer-web-sdk/api/models';
import { useSelector } from 'react-redux';
import { getAuthUser, getBrand } from '../../../features/auth/selectors';
import { formatDate, getTimeIntervalString } from '../../../utils/dates';
import { mapInsuranceToTranslation } from '../../../utils/i18n';
import AppointmentFiles from '../../Dashboard/Appointment/AppointmentDetails/AppointmentFiles';
import {
    TabAppointment,
    TabAppointmentBadge,
    TabContainer,
} from '../../Dashboard/Appointment/AppointmentTabs/AppointmentTabs.styled';

interface Props {
    managing_user: UserPractitioner | undefined;
    health_service: HealthcareService | HealthcareServiceSlots | undefined;
    agreements_data_picked_day_time_array?: () => string[] | undefined;
    attachments?: File[] | undefined;
    appointment?: AppointmentModel | undefined;
    admin_organization?: AdminOrganization | undefined;
    message?: string | undefined;
    duration?: [string, string] | undefined;
    appointment_reason?: string;
    date_from?: string | undefined;
    show_form_separator?: boolean;
    user?: UserProfile | undefined;
}
export const AgreementsData = ({
    managing_user,
    health_service,
    agreements_data_picked_day_time_array = undefined,
    attachments = undefined,
    appointment = undefined,
    admin_organization = undefined,
    message = undefined,
    duration = undefined,
    date_from = undefined,
    appointment_reason = undefined,
    show_form_separator = true,
    user = undefined,
}: Props) => {
    const { t } = useTranslation();
    const authUser = useSelector(getAuthUser);
    const organization = useSelector(getBrand);
    const { isVisible } = useOptionalFields(admin_organization !== undefined ? admin_organization : organization);
    const [attachmentsOpen, setAttachmentsOpen] = useState(false);

    const getAddress = (): undefined | string => {
        if (user) {
            if (user?.patient?.city === '' || user?.patient?.street === '') return undefined;

            return user.patient?.city + ', ' + user.patient?.street;
        }

        if (authUser === undefined) return undefined;

        if (authUser?.patient?.city === '' || authUser?.patient?.street === '') return undefined;

        return authUser?.patient?.city + ', ' + authUser?.patient?.street;
    };

    const getAttachmentsLength = (): number => {
        if (attachments !== undefined) return attachments.length;
        if (appointment !== undefined) return appointment.attachments.length;

        return 0;
    };

    return (
        <>
            <CustomRow className="mb-0 mr-0 ml-0">
                {getAttachmentsLength() !== 0 && (
                    <TabContainer className="w-100 mb-3">
                        <TabAppointment
                            className="mr-3"
                            inverseColors={attachmentsOpen}
                            onClick={() => setAttachmentsOpen(false)}
                        >
                            {t('appointmentTabs:tab_btn_1')}
                        </TabAppointment>
                        <TabAppointmentBadge
                            className="mr-3"
                            inverseColors={!attachmentsOpen}
                            onClick={() => setAttachmentsOpen(true)}
                            data-badge={getAttachmentsLength()}
                        >
                            {t('appointmentTabs:tab_btn_3')}
                        </TabAppointmentBadge>
                    </TabContainer>
                )}
                {attachmentsOpen ? (
                    attachmentsOpen && <AppointmentFiles attachments={appointment?.attachments} files={attachments} />
                ) : (
                    <Fragment>
                        <Col>
                            <FormValue
                                title={t('slotOverview:practitioner')}
                                value={managing_user && managing_user.first_name ? getFullName(managing_user) : '-'}
                            />
                            {date_from && (
                                <FormValue title={t('slotOverview:date_from')} value={formatDate(moment(date_from))} />
                            )}
                            {appointment_reason && (
                                <FormValue
                                    title={t('newAppointmentForm:title_reason_for_appointment')}
                                    value={appointment_reason}
                                />
                            )}
                            {agreements_data_picked_day_time_array && (
                                <FormValue
                                    title={t('requestWaitingRoom:picked_days_confirmation_label')}
                                    value={agreements_data_picked_day_time_array()}
                                />
                            )}
                        </Col>
                        <Col>
                            <FormValue title={t('slotOverview:healthcare_service')} value={health_service?.name} />
                            {duration && (
                                <FormValue
                                    title={t('slotOverview:duration')}
                                    value={getTimeIntervalString(moment(duration[0]), moment(duration[1]))}
                                />
                            )}
                        </Col>
                        <FormSeparator />
                        <Col>
                            <FormValue
                                title={t('newAppointmentForm:title_personal_title')}
                                value={user?.personal_title || authUser?.personal_title}
                            />
                            <FormValue
                                title={t('newAppointmentForm:title_first_name')}
                                value={user?.first_name || authUser?.first_name}
                            />
                            <FormValue
                                title={t('newAppointmentForm:title_last_name')}
                                value={user?.last_name || authUser?.last_name}
                            />
                            <FormValue
                                title={t('newAppointmentForm:title_date_of_birth')}
                                value={
                                    (user?.patient?.date_of_birth &&
                                        formatISODate(moment(user.patient.date_of_birth))) ||
                                    (authUser?.patient?.date_of_birth &&
                                        formatISODate(moment(authUser?.patient.date_of_birth)))
                                }
                            />
                            <FormValue
                                title={t('newAppointmentForm:title_email')}
                                value={user?.email || authUser?.email}
                            />
                        </Col>
                        <Col>
                            {isVisible(EOptionalFields.phone) && (
                                <FormValue
                                    title={t('newAppointmentForm:title_phone_number')}
                                    value={user?.phone || authUser?.phone}
                                />
                            )}
                            {isVisible(EOptionalFields.insurance_status) && (
                                <FormValue
                                    title={t('newAppointmentForm:title_insurance_status')}
                                    value={
                                        (user?.patient?.insurance_status &&
                                            mapInsuranceToTranslation(user.patient.insurance_status)) ||
                                        (authUser?.patient?.insurance_status &&
                                            mapInsuranceToTranslation(authUser?.patient?.insurance_status))
                                    }
                                />
                            )}
                            {isVisible(EOptionalFields.insurance_number) && (
                                <FormValue
                                    title={t('newAppointmentForm:title_insurance_number')}
                                    value={user?.patient?.insurance_number || authUser?.patient?.insurance_number}
                                />
                            )}
                            {isVisible(EOptionalFields.address) && (
                                <FormValue title={t('newAppointmentForm:title_address')} value={getAddress()} />
                            )}
                            {message && (
                                <FormValue title={t('newAppointmentForm:title_confirmation_message')} value={message} />
                            )}
                        </Col>
                    </Fragment>
                )}
                {show_form_separator && <FormSeparator />}
            </CustomRow>
        </>
    );
};
