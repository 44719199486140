import styled from 'styled-components';
import { PopoverPosition } from './hooks/usePopoverPosition';

export const PopoverWrapper = styled.div<{
    isOpen: boolean;
}>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99;
`;

export const PopoverContentWrapper = styled.div<{
    position: PopoverPosition;
}>`
    position: fixed;
    top: ${({ position }) => position.topOffset}px;
    left: ${({ position }) => position.leftOffset}px;
    height: auto;
    max-height: ${({ position }) => `${position.height}px`} !important;
    width: ${({ position }) => position.width}px;
    background: white;
    border: 1px solid gainsboro;
    border-radius: 10px;
    z-index: 30;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12),
        0px 11px 15px -7px rgba(0, 0, 0, 0.2);
`;

export const PopoverOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
`;

export const CloseButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    outline: none;
    border: none;
    padding: 15px;
    z-index: 9999;
`;

export const PopoverLoaderContainer = styled.div<{ position: PopoverPosition }>`
    display: flex;
    height: ${({ position }) => `${position.height}px`};
    justify-content: center;
    align-items: center;
`;
export const PopoverContent = styled.div`
    max-height: 100%;
    overflow: auto;
    width: 100%;
    padding: 0 30px;
`;
