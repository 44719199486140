import { useEffect, useMemo, useState } from 'react';
import { Slot, SlotBookingData, SlotMode, SlotStatus } from 'medrefer-web-sdk/api/models';
import {
    addDays,
    ceilDate,
    floorDate,
    getDaysDiff,
    maxDate,
    minDate,
    minNullableDate,
    subtractDays,
    toNullableDate,
} from 'utils/dates';
import { api } from '../../../medrefer-web-sdk/api';
import useApiRequest from '../../../hooks/useApiRequest';
import { errorTimeout } from '../../../utils/constants';
import {
    AppointmentRequestsModel,
    AppointmentRequestStatus,
    AppointmentRequestTime,
} from '../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';
import { ModalState } from '../../../medrefer-web-sdk/web-kit';

export interface CalendarHookRequest {
    /**
     * Slots aggregated for each day
     */
    daysSlots: Slot[][];
    /**
     * The date that calendar displays columns from
     */
    dateFrom: Date;
    /**
     * The date that calendar displays columns to
     */
    dateTo: Date;
    /**
     * The date from which the slots have been already fetched
     */
    fetchedDateFrom: Date;
    /**
     * The date to which the slots have been already fetched
     */
    fetchedDateTo: Date;
    /**
     * The nearest date of the slot further down the calendar
     */
    nearestSlotDate: Date | null;
    /**
     * Go to next calendar page
     */
    goNext: () => void;
    /**
     * Go to previous calendar page
     */
    goPrev: () => void;
    /**
     * Go to the nearest slot further down the calendar
     */
    goNearest: () => void;
    hasNext: boolean;
    hasPrev: boolean;
    noSlotsInFuture: boolean;
    isLoading: boolean;
    hasSlots: boolean;
    slotClickHandle: (slotKey: string) => void;
    bookingData: SlotBookingData | null;
    onConfirmBooking: () => void;
    onDiscard: () => void;
}

export const useSlotsFinderCalendar = (
    initSlots: Slot[],
    initDateFrom: Date,
    initNearestDateFrom: Date | null,
    daysRangeSize: number,
    dateRangeMin: Date | null,
    dateRangeMax: Date | null,
    healthcareServiceId: number,
    activeItem: AppointmentRequestsModel,
    // loadMore: (dateFrom: Date, dateTo: Date) => void,
    state: ModalState,
    reloadAppointmentsRequests: () => void,
): CalendarHookRequest => {
    const [fetchData, setFetchData] = useState({
        slots: initSlots,
        fetchedDateFrom: initDateFrom,
        fetchedDateTo: addDays(initDateFrom, daysRangeSize),
        // The nearest date of the slot in the future that hasn't been fetched yet
        nearestDateFrom: initNearestDateFrom,
    });
    const { slots, fetchedDateFrom, fetchedDateTo, nearestDateFrom } = fetchData;
    const request = useApiRequest({ errorTimeout });
    const [dateFrom, setDateFrom] = useState(initDateFrom);
    const dateTo = addDays(dateFrom, daysRangeSize);
    const [firstLoad, setFirstLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const nearestNextSlot = slots.find((s) => new Date(s.date_from) > dateTo && s.status === SlotStatus.FREE);
    const nearestSlotDate = minNullableDate(toNullableDate(nearestNextSlot?.date_from), nearestDateFrom);
    const [hasSlots, setHasSlots] = useState(false);
    const [bookingData, setBookingData] = useState<SlotBookingData | null>(null);
    const [minDateFrom, setMinDateFrom] = useState<Date>(new Date());
    const hasPrev =
        dateFrom.getTime() > fetchedDateFrom.getTime() &&
        dateRangeMin != null &&
        minDateFrom.getTime() < dateFrom.getTime() &&
        dateFrom.getTime() > dateRangeMin.getTime();

    const hasNext =
        dateTo.getTime() < fetchedDateTo.getTime() && dateRangeMax != null && dateTo.getTime() < dateRangeMax.getTime();
    useEffect(() => {
        if (shouldFetchNext()) {
            fetchNext();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFrom]); // [dateTo, fetchedDateTo, dateRangeMax]);
    //
    useEffect(() => {
        if (shouldFetchPrev()) {
            fetchPrev();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateFrom]);

    const shouldFetchNext = () => {
        if (dateRangeMax == null) {
            return false;
        }
        const isEndReached = dateTo.getTime() >= fetchedDateTo.getTime();
        return isEndReached && !isLoading; //&& fetchHasNext
    };

    const shouldFetchPrev = () => {
        if (dateFrom.getTime() > fetchData.fetchedDateFrom.getTime()) return true;
        if (minDateFrom.getTime() < dateFrom.getTime()) return false;
        return true; //fetchHasPrev
    };

    const fetchNext = () => {
        fetchSlots(fetchedDateTo, minDate(addDays(maxDate(fetchedDateTo, dateTo), daysRangeSize)));
    };

    const fetchPrev = () => {
        fetchSlots(maxDate(subtractDays(fetchedDateFrom, daysRangeSize), dateRangeMin!), fetchedDateFrom);
    };

    const filterSlots = (slots: Slot[]) => {
        const toReturn: Slot[] = [];
        let hasSlots = false;
        slots.map((slot) => {
            const day = new Date(slot.date_from).getDay() - 1 <= -1 ? 6 : new Date(slot.date_from).getDay() - 1;
            switch (activeItem.available_time[day]) {
                case AppointmentRequestTime.AFTERNOON:
                    if (new Date(slot.date_from).getHours() >= 12) toReturn.push(slot);
                    hasSlots = true;
                    break;
                case AppointmentRequestTime.MORNING:
                    if (new Date(slot.date_from).getHours() < 12) toReturn.push(slot);
                    hasSlots = true;
                    break;
                case AppointmentRequestTime.ALL_DAY:
                    toReturn.push(slot);
                    hasSlots = true;
                    break;
            }
            if (toReturn.length == 0) hasSlots = false;
            setHasSlots(hasSlots);
        });
        if (hasSlots && firstLoad) {
            setFirstLoad(false);
            setDateFrom(new Date(toReturn[0].date_from));
            setMinDateFrom(new Date(toReturn[0].date_from));
        }
        return toReturn;
    };
    const fetchSlots = (filterDateFrom: Date, filterDateTo: Date) => {
        setIsLoading(true);
        const cv: number[] = [];
        activeItem.criteria.map((critera) => {
            critera.values.map((cval) => {
                cv.push(cval.id);
            });
        });

        request.dispatch(
            api.getOrganizationSlotsForRequest(dateFrom, dateTo, healthcareServiceId, cv).then((res) => {
                res = filterSlots(res);
                setFetchData((current) => ({
                    slots: res,
                    fetchedDateFrom: minDate(filterDateFrom, current.fetchedDateFrom),
                    fetchedDateTo: maxDate(filterDateTo, current.fetchedDateTo),
                    nearestDateFrom: null,
                }));
                setIsLoading(false);
            }),
        );
    };

    const goNext = () => {
        if (!hasNext) {
            return;
        }

        const nextStart = addDays(dateFrom, daysRangeSize);
        setDateFrom(nextStart);
    };

    const goPrev = () => {
        if (!hasPrev) {
            return;
        }

        const nextStart = maxDate(floorDate(fetchedDateFrom), subtractDays(dateFrom, daysRangeSize));
        setDateFrom(nextStart);
    };

    const goNearest = () => {
        if (!nearestSlotDate || !dateRangeMax) {
            return;
        }

        const nextStart = minDate(floorDate(nearestSlotDate), subtractDays(ceilDate(dateRangeMax), daysRangeSize));
        setDateFrom(nextStart);
    };

    const daysSlots = useMemo(() => {
        const daysSlots: Slot[][] = [];
        Array(daysRangeSize)
            .fill(0)
            .forEach(() => {
                daysSlots.push([]);
            });
        slots
            .filter((slot) => new Date(slot.date_from) >= dateFrom && new Date(slot.date_from) < dateTo)
            .forEach((slot) => {
                const columnIdx = getDaysDiff(floorDate(dateFrom), floorDate(new Date(slot.date_from)));
                if (daysSlots[columnIdx]) {
                    daysSlots[columnIdx].push(slot);
                }
            });
        return daysSlots;
    }, [daysRangeSize, slots, dateFrom, dateTo]);

    const noSlotsInFuture = useMemo(() => {
        return !slots.find((slot) => slot.status === SlotStatus.FREE) && !nearestSlotDate;
    }, [slots, nearestSlotDate]);

    const slotClickHandle = (slotKey: string) => {
        const slot = fetchData.slots.find((slot) => slot.key === slotKey);
        if (!slot) return;
        if (!activeItem.patient) return;
        if (!activeItem.patient.patient) return;
        const slotBookingData: SlotBookingData = {
            street: activeItem.patient.patient.street,
            city: activeItem.patient.patient.city,
            slot: slot,
            patient: activeItem.patient.id,
            personal_title: activeItem.patient.personal_title,
            first_name: activeItem.patient.first_name,
            last_name: activeItem.patient.last_name,
            phone: activeItem.patient.phone,
            email: activeItem.patient.email,
            insurance_number: activeItem.patient.patient.insurance_number,
            insurance_status: activeItem.patient.patient.insurance_status,
            date_of_birth: toNullableDate(activeItem.patient.patient.date_of_birth),
            comment: activeItem.comment,
            sms_agreement: activeItem.patient.patient.sms_agreement,
            email_agreement: activeItem.patient.patient.email_agreement,
            terms_agreement: true,
            mode: SlotMode.ONSITE,
            reason: slot.available_for_reasons[0].id.toString(),
        };
        setBookingData(slotBookingData);
    };
    const onConfirmBooking = () => {
        if (bookingData) {
            request.dispatch(api.bookSlot(bookingData)).then((res) => {
                request
                    .dispatch(
                        api.changeAppointmentRequestStatus(activeItem.id, AppointmentRequestStatus.APPROVED, res.id),
                    )
                    .then(() => {
                        reloadAppointmentsRequests();
                        setBookingData(null);
                        state.toggleModal();
                    });
            });
        }
    };
    const onDiscard = () => {
        setBookingData(null);
    };
    return {
        daysSlots,
        dateFrom,
        dateTo,
        fetchedDateFrom,
        fetchedDateTo,
        nearestSlotDate,
        goNext,
        goPrev,
        goNearest,
        hasNext,
        hasPrev,
        noSlotsInFuture,
        isLoading: request.isLoading,
        hasSlots,
        slotClickHandle,
        bookingData,
        onConfirmBooking,
        onDiscard,
    };
};
