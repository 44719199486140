import { useTranslation } from 'react-i18next';
import { AdminOrganization, OrganizationProfile, UserProfile, UserRole } from '../../../../medrefer-web-sdk/api/models';

export interface KeyLabelPair {
    value: string;
    label: string;
}
export const useOptionalFields = (organization: OrganizationProfile | undefined | AdminOrganization) => {
    const { t } = useTranslation();

    const fieldsOptions: KeyLabelPair[] = [
        { value: 'insurance_number', label: t('profileDisplay:insurance_number') },
        { value: 'insurance_status', label: t('profileDisplay:insurance_status') },
        { value: 'phone', label: t('profileDisplay:phone_label') },
        { value: 'address', label: t('adminPanel:table.columns.address') },
    ];
    const getMandatoryFields = (): string[] => {
        if (organization === undefined) return [];

        const mandatoryFields: string[] = [];
        if (organization.address_field_mandatory) mandatoryFields.push('address');
        if (organization.phone_number_field_mandatory) mandatoryFields.push('phone');
        if (organization.insurance_type_field_mandatory) mandatoryFields.push('insurance_status');
        if (organization.insurance_number_field_mandatory) mandatoryFields.push('insurance_number');
        return mandatoryFields;
    };

    const isVisible = (fieldName: EOptionalFields): boolean => {
        return getMandatoryFields().includes(fieldName) || getFields().includes(fieldName);
    };

    const isMandatory = (fieldName: EOptionalFields): boolean => {
        return getMandatoryFields().includes(fieldName);
    };

    const getFields = (): string[] => {
        if (organization === undefined) return [];

        const fields: string[] = [];
        if (organization.address_field_visibility && !organization.address_field_mandatory) fields.push('address');
        if (organization.phone_number_field_visibility && !organization.phone_number_field_mandatory)
            fields.push('phone');
        if (organization.insurance_type_field_visibility && !organization.insurance_type_field_mandatory)
            fields.push('insurance_status');
        if (organization.insurance_number_field_visibility && !organization.insurance_number_field_mandatory)
            fields.push('insurance_number');
        return fields;
    };

    const hasMissingInfo = (user: UserProfile | undefined): boolean => {
        if (user === undefined) return true;

        if (user.role !== UserRole.patient) return false;

        const date_of_birth = user.role === UserRole.patient && !user.patient?.date_of_birth;
        const phone = isMandatory(EOptionalFields.phone) && !user.phone;
        const address = isMandatory(EOptionalFields.address) && (!user.patient?.street || !user.patient?.city);
        const insurance_number = isMandatory(EOptionalFields.insurance_number) && !user.patient?.insurance_number;
        const insurance_status = isMandatory(EOptionalFields.insurance_status) && !user.patient?.insurance_status;

        return phone || address || insurance_number || insurance_status || date_of_birth;
    };

    return {
        fieldsOptions,
        getFields,
        getMandatoryFields,
        isMandatory,
        isVisible,
        hasMissingInfo,
    };
};

export enum EOptionalFields {
    phone = 'phone',
    address = 'address',
    insurance_status = 'insurance_status',
    insurance_number = 'insurance_number',
}
