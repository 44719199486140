import { useQuery } from 'react-query';
import { api } from 'medrefer-web-sdk/api';

export const useOrganizationLocations = () => {
    const query = useQuery('calendarOrganizationLocations', () => api.getCalendarOrganizationLocations(), {
        staleTime: Infinity,
        refetchOnMount: 'always',
    });
    return query.data || [];
};
