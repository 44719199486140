import React from 'react';
import { GeoPosition, LocationData } from 'medrefer-web-sdk/api/models';
import { Separator, TextGrey } from 'medrefer-web-sdk/web-kit';
import LocationIcon from 'medrefer-web-sdk/web-kit/assets/icons/LocationIcon.png';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

type Props = {
    location: LocationData | null;
};

export const ServiceLocation = ({ location }: Props) => {
    return (
        location && (
            <>
                <div>
                    <Icon src={LocationIcon} alt="Icon" />
                    <AddressText>
                        {location.address.text}, {location.address.city},{location.name}
                    </AddressText>
                    {location?.position && (
                        <>
                            <AddressText> • </AddressText>
                            {<GoogleMapLink position={location.position} />}
                        </>
                    )}
                </div>
                <Separator />
            </>
        )
    );
};

const GoogleMapLink = ({ position }: { position: GeoPosition }) => {
    const { t } = useTranslation();
    const url = `https://www.google.com/maps/search/?api=1&query=${position.latitude},${position.longitude}`;
    return (
        <MapLink href={url} target="_blank">
            {t('publicSearch:location_show_map')}
        </MapLink>
    );
};

const Icon = styled.img`
    margin-right: 6px;
`;

const AddressText = styled(TextGrey)`
    font-size: 11px;
`;

const MapLink = styled.a`
    color: ${({ theme }) => theme.grey400};
    font-size: 13px;
`;
