import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { CheckBox, CheckBoxLabel } from 'medrefer-web-sdk/web-kit';
import { PrivacyPolicyNote } from 'components/PrivacyPolicy';
import { useTranslation } from 'react-i18next';
import { SlotBookingData } from 'medrefer-web-sdk/api/models';

interface Props {
    values: SlotBookingData;
}

export const DisplayAgreements = ({ values }: Props) => {
    const { t } = useTranslation();
    return (
        <Col xs={12} md={8}>
            <Row>
                <div className="d-flex align-items-start mb-1" style={{ gap: '20px' }}>
                    <CheckBox readOnly disabled checked={values.sms_agreement} className="mr-2" />
                    <CheckBoxLabel className={'ml-2'}>{t('appointmentOverview:checkbox_text1')}</CheckBoxLabel>
                </div>
            </Row>
            <Row>
                <div className="d-flex align-items-start mb-1" style={{ gap: '20px' }}>
                    <CheckBox readOnly disabled checked={values.email_agreement} className="mr-2" />
                    <CheckBoxLabel className={'ml-2'}>{t('appointmentOverview:checkbox_text2')}</CheckBoxLabel>
                </div>
            </Row>
            <Row>
                <div className="d-flex align-items-start mb-1" style={{ gap: '20px' }}>
                    <CheckBox readOnly disabled checked={values.terms_agreement} className="mr-2" />
                    <CheckBoxLabel className={'ml-2'}>
                        <PrivacyPolicyNote />
                    </CheckBoxLabel>
                </div>
            </Row>
        </Col>
    );
};
