import styled from 'styled-components';

interface GraeyOutlineButtonProps {
    isLarge?: boolean;
}

export const GreyOutlineButton = styled.button<GraeyOutlineButtonProps>`
    border: 1px solid ${({ theme }) => theme.purple100};
    box-sizing: border-box;
    border-radius: 8px;
    background: transparent;
    width: ${(props) => (props.isLarge ? '215px' : '200px')};
    height: ${(props) => (props.isLarge ? '48px' : '40px')};
    display: block;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 19px;
    color: ${({ theme }) => theme.black};
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }
    &[disabled] {
        cursor: default;
    }
`;
