import React from 'react';
import { UserPatient } from 'medrefer-web-sdk/api/models';
import { Col } from 'react-bootstrap';
import ProfileAttribute from 'components/shared/ProfileAttribute';
import { useTranslation } from 'react-i18next';
import { EOptionalFields } from '../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';
import { mapInsuranceToTranslation } from '../../utils/i18n';

type Props = {
    patient: UserPatient;
    isVisible: (fieldType: EOptionalFields) => boolean;
};

const PatientAttributes = ({ patient, isVisible }: Props) => {
    const { t } = useTranslation();

    const getAddress = (): undefined | string => {
        if (patient.city === '' || patient.street === '') return undefined;

        return patient.city + ', ' + patient.street;
    };

    return (
        <>
            <Col xs={12} md={6}>
                <ProfileAttribute label={t('profileDisplay:date_of_birth')} value={patient.date_of_birth} />
                {isVisible(EOptionalFields.insurance_number) && (
                    <ProfileAttribute label={t('profileDisplay:insurance_number')} value={patient.insurance_number} />
                )}
                {isVisible(EOptionalFields.insurance_status) && (
                    <ProfileAttribute
                        label={t('profileDisplay:insurance_status')}
                        value={patient.insurance_status && mapInsuranceToTranslation(patient.insurance_status)}
                    />
                )}
            </Col>
            <Col xs={12} md={6}>
                {/*<ProfileAttribute label={t('profileDisplay:post_code')} value={patient.post_code} />*/}
                {isVisible(EOptionalFields.address) && (
                    <ProfileAttribute label={t('newAppointmentForm:title_address')} value={getAddress()} />
                )}
            </Col>
        </>
    );
};

export default PatientAttributes;
