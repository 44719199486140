import styled from 'styled-components';

export const SideMenuWrapper = styled.div<{ isOpen: boolean }>`
    background-color: #fff;
    bottom: 0;
    left: 0;
    margin-top: 0;
    position: fixed;
    top: ${({ theme }) => theme.navbarHeight.desktop};
    width: ${({ isOpen }) => (isOpen ? '240px' : '80px')};
    z-index: 20;
    box-shadow: rgb(44 51 71 / 12%) 0 1px 5px;
    transition: 0.2s ease-in-out;

    span,
    .arrow-icon {
        display: ${({ isOpen }) => (isOpen ? 'inline' : 'none')};
    }

    ul {
        li {
            a {
                span {
                    display: ${({ isOpen }) => (isOpen ? 'inline' : 'none')};
                }
            }
        }
    }

    @media (max-width: ${({ theme }) => theme.devices.desktop}) {
        left: ${({ isOpen }) => (isOpen ? '0' : '-80px')};
        top: ${({ theme }) => theme.navbarHeight.mobile};
    }
`;

export const SideMenuContainer = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
`;

export const SideMenuInner = styled.div`
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 100%;
`;

export const SideMenuList = styled.ul`
    padding: 10px 15px;
    font-size: 16px;
    list-style-type: none;
    margin: 0;
    position: relative;
    li {
        &.menu-title {
            color: #455a64;
            display: flex;
            font-size: 14px;
            opacity: 1;
            padding: 2px 15px;
            white-space: nowrap;
            transition: 0.2s;

            &.hidden {
                opacity: 0;
            }
        }

        svg {
            width: 24px;
            height: 24px;
            transition: 0.2s;
            transform: translateY(-2px);
        }

        .subdrop-title {
            padding: 8px 15px;
            cursor: pointer;
            position: relative;
            transition: 0.2s;

            span {
                margin-left: 10px;
            }

            img {
                &.arrow-icon {
                    width: 6px;
                    position: absolute;
                    right: 0;
                    top: 15px;
                    transition: 0.2s;

                    &.open {
                        transform: rotate(90deg);
                    }
                }
            }

            &.active {
                color: ${({ theme }) => theme.accent};

                svg {
                    fill: ${({ theme }) => theme.accent};
                }
            }

            &:hover {
                color: ${({ theme }) => theme.accent};

                svg {
                    fill: ${({ theme }) => theme.accent};
                }
            }
        }
    }
`;

export const SideMenuSubDrop = styled.ul<{ isOpen: boolean }>`
    display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
    padding-left: 25px;
    margin-left: 0;
    list-style: none;

    a {
        font-size: 15px;
    }
`;
