import React from 'react';
import { useTranslation } from 'react-i18next';
import { ProgressStep, ProgressSteps } from 'components/shared/ProgressSteps/ProgressSteps';

interface Props {
    activeStep: string;
    includePaymentStep?: boolean;
}

export const SlotBookingProgress = ({ activeStep, includePaymentStep = false }: Props) => {
    const { t } = useTranslation();
    const extraSteps = [];

    if (includePaymentStep) {
        extraSteps.push({
            name: 'payment',
            title: t('progressIndicator:steps.payment'),
        });
    }

    const steps: ProgressStep[] = [
        {
            name: 'criteria',
            title: t('progressIndicator:steps.criteria'),
        },
        {
            name: 'pickSlot',
            title: t('progressIndicator:steps.pickSlot'),
        },
        {
            name: 'appointmentForm',
            title: t('progressIndicator:steps.appointmentForm'),
        },
        ...extraSteps,
        {
            name: 'done',
            title: t('progressIndicator:steps.done'),
        },
    ];

    return <ProgressSteps activeStep={activeStep} steps={steps} />;
};
