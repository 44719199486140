import { SlotMode } from 'medrefer-web-sdk/api/models';
import { ServiceSearchValues } from 'components/PublicSearch/SlotBooking/ServiceSearchForm/ServiceSearchForm';
import { routerActions } from 'connected-react-router';
import { useHealthcareServiceOptions } from 'features/appointment/hooks';
import useBrand from 'hooks/useBrand';
import { useQueryParams } from 'medrefer-web-sdk/web-kit/hooks';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RouteName } from 'router-paths';
import { useDefaultSearchValues } from './useDefaultSearchValues';

export const useSlotsSearch = () => {
    const dispatch = useDispatch();
    const defaultValues = useDefaultSearchValues();
    const [filterParams, setFilterParams] = useState(defaultValues);
    const [servicesLoaded, setServicesLoaded] = useState(false);
    const { getPathBranded } = useBrand();
    const healthcareServices = useHealthcareServiceOptions();
    const isSingleServiceOrganization = healthcareServices?.length === 1;
    const queryParams = useQueryParams();

    const onSubmit = (values: ServiceSearchValues, redirectRoute: RouteName) => {
        setFilterParams(values);
        const path = getPathBranded(redirectRoute);
        const params = queryParams.copy();
        params.set('label', values.generalSearchOption?.label);
        params.set('search', values.generalSearchOption?.value);
        params.set('type', values.generalSearchOption?.type);
        params.set('location', values.location);
        params.set('id', values.generalSearchOption?.id?.toString());
        params.set('mode', values.mode ? values.mode : SlotMode.ONSITE);

        dispatch(routerActions.push(path + params.toString()));
    };

    useEffect(() => {
        if (!healthcareServices || servicesLoaded) {
            return;
        }
        setServicesLoaded(true);

        if (isSingleServiceOrganization) {
            const service = healthcareServices[0];
            setFilterParams({
                ...filterParams,
                healthcareServiceIds: [service.value],
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [healthcareServices, servicesLoaded, isSingleServiceOrganization]);

    return {
        onSubmit,
        defaultValues,
        filterParams,
        servicesLoaded,
        isSingleServiceOrganization,
    };
};
