import { createStore, applyMiddleware } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router';

import { composeEnhancers } from './utils';
import rootReducer from './root-reducer';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// browser history
export const history = createBrowserHistory();

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['router', 'appointment'],
};

const routerMiddleware = createRouterMiddleware(history);

// configure middlewares
const middlewares = [thunk, routerMiddleware];
// compose enhancers
const enhancer = composeEnhancers(applyMiddleware(...middlewares));

// rehydrate state on app start
const initialState = {};

// create store
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

export const store = createStore(persistedReducer, initialState, enhancer);

// export let store = createStore(persistedReducer);
export const persistor = persistStore(store);
