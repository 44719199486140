import React, { ReactNode, useContext } from 'react';
import { AccordionContext, useAccordionToggle } from 'react-bootstrap';
import { StyledUnderlineChevron } from './ContextAwareToggle.styled';

interface Props {
    children: ReactNode;
    eventKey: string;
    callback?: (arg0: string) => void;
}

export function ContextAwareToggle({ children, eventKey, callback }: Props) {
    const currentEventKey = useContext(AccordionContext);

    const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));

    const isCurrentEventKey = currentEventKey === eventKey;

    return (
        <button
            type="button"
            style={{ border: 'none', width: '100%', background: 'none', padding: 0 }}
            onClick={decoratedOnClick}
            className="ml-3 mr-3 mb-4"
        >
            <StyledUnderlineChevron isActive={isCurrentEventKey}>{children}</StyledUnderlineChevron>
        </button>
    );
}
