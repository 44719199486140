import React, { FC, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthPage } from 'components/Auth/AuthPage';
import LoginForm from './LoginForm';
import { pageStyles } from '../Auth.styled';
import { useSelector } from 'react-redux';
import useBrand from 'hooks/useBrand';
import useLoginFlow from '../useLoginFlow';
import { getAuthUser, getBrand } from 'features/auth/selectors';
import RedirectHome from 'components/shared/routes/RedirectHome';
import { useFirstRender } from 'medrefer-web-sdk/web-kit/hooks';
import { useOptionalFields } from '../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';
import { CompleteUserInfoModal } from '../CompleteUserInformation/CompleteUserInfoModal';

export const Login: FC = () => {
    const { t } = useTranslation();
    const authUser = useSelector(getAuthUser);
    const firstRender = useFirstRender();
    const location = useLocation<{ from?: string }>();
    const { request, emailNotFound, otpCodeSent, passwordReset, submitCredentials, ensureEmailExists, submitOtp } =
        useLoginFlow();
    const { getPathBranded } = useBrand();

    const organization = useSelector(getBrand);
    const { hasMissingInfo } = useOptionalFields(organization);

    const [passUser, SetPassUser] = useState(false);

    if (authUser && (!hasMissingInfo(authUser) || passUser)) {
        if (firstRender) return <RedirectHome />;

        return <RedirectHome redirectTo={location.state?.from} />;
    }

    return (
        <AuthPage pageTitle={t('loginPage:h1')}>
            {authUser && hasMissingInfo(authUser) && !passUser && (
                <CompleteUserInfoModal onDiscard={() => SetPassUser(true)} />
            )}
            <LoginForm
                request={request}
                emailNotFound={emailNotFound}
                otpCodeSent={otpCodeSent}
                passwordReset={passwordReset}
                ensureEmailExists={ensureEmailExists}
                submitCredentials={submitCredentials}
                submitOtp={submitOtp}
            />
            <div className="mt-5">
                <div>
                    <span style={pageStyles.message}>{t('loginPage:register_message')}</span>
                    <Link to={getPathBranded('register')} style={pageStyles.message_cta}>
                        {t('registerPage:h1')}
                    </Link>
                </div>
                <div>
                    <span style={pageStyles.message}>{t('loginPage:message')}</span>
                    <Link to={getPathBranded('forgotPassword')} style={pageStyles.message_cta}>
                        {t('loginPage:message_cta')}
                    </Link>
                </div>
            </div>
        </AuthPage>
    );
};
