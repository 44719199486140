import useApiRequest from '../../../../hooks/useApiRequest';
import { errorTimeout } from '../../../../utils/constants';
import { api } from '../../../../medrefer-web-sdk/api';
import { AppointmentRequestData } from '../../../../medrefer-web-sdk/api/models/forms/AppointmentRequestData';
import { AppointmentRequestTime } from '../../../../medrefer-web-sdk/api/models/AppointmentRequestsModel';
import { useQueryParams } from '../../../../medrefer-web-sdk/web-kit/hooks';

export const useWaitingRoomRequest = () => {
    const request = useApiRequest({ errorTimeout });
    const queryParams = useQueryParams();
    const addAppointmentRequest = async (
        healthcareServiceID: number,
        isGroup: boolean,
        userId: number,
        available_time: AppointmentRequestTime[],
        comment: string,
    ) => {
        const criteria =
            queryParams
                .get('criteria')
                ?.split(',')
                .map((num) => Number.parseInt(num)) || [];
        const data: AppointmentRequestData = {
            healthcare_service: isGroup ? undefined : healthcareServiceID,
            healthcare_service_group: isGroup ? healthcareServiceID : undefined,
            patient: userId,
            available_time: available_time,
            comment: comment,
            criteria_values: criteria,
        };
        return await dispatchRequest(data);
    };
    const dispatchRequest = async (data: AppointmentRequestData) => {
        return await request.dispatch(api.createAppointmentRequest(data));
    };
    return {
        isLoading: request.isLoading,
        addAppointmentRequest: addAppointmentRequest,
    };
};
