import React, { useEffect, useState } from 'react';
import { TimerStyled } from './Timer.styled';

interface Props {
    timeLeft: number;
}

export const Timer = ({ timeLeft }: Props) => {
    const [secondsLeft, setSecondsLeft] = useState<number>(timeLeft);
    useEffect(() => {
        const timer = setInterval(() => {
            setSecondsLeft((prev) => prev - 1);
        }, 1000);
        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line
    }, []);

    const getSeconds = (secondsLeft: number) => {
        const seconds = Math.abs(secondsLeft) % 60;
        return seconds < 10 ? `0${seconds}` : seconds;
    };

    const getMinutes = (secondsLeft: number) => {
        const minutes = secondsLeft < 60 ? 0 : Math.abs(Math.floor(secondsLeft / 60)) % 60;
        return minutes < 10 ? `0${minutes}` : minutes;
    };

    const getHours = (secondsLeft: number) => {
        const hours = Math.abs(Math.floor(secondsLeft / 60 / 60)) % 60;
        if (secondsLeft < 60 * 60) return '00';
        return hours < 10 ? `0${hours}` : hours;
    };

    const hours = getHours(secondsLeft);

    return (
        <TimerStyled red={secondsLeft < 0}>
            {`${secondsLeft < 0 ? '- ' : ''}${hours !== '00' ? `${hours}:` : ''}${getMinutes(secondsLeft)}:${getSeconds(
                secondsLeft,
            )}`}
        </TimerStyled>
    );
};
