import { SlotEditScope, SlotData } from 'medrefer-web-sdk/api/models';
import { ActiveSchedule } from '../SchedulePopover/SchedulePopover.types';
import useApiRequest from 'hooks/useApiRequest';
import { errorTimeout } from 'utils/constants';
import { PopoverState } from 'medrefer-web-sdk/web-kit/components/Popover/hooks/usePopoverState';
import { api } from 'medrefer-web-sdk/api';

export const useCalendarActions = (
    activeSchedule: ActiveSchedule | null,
    reloadSchedules: () => Promise<void>,
    schedulePopoverState: PopoverState,
) => {
    const request = useApiRequest({ errorTimeout });

    const handleAddSlot = (values: SlotData) => {
        request.dispatch(dispatchAddSlot(values));
    };

    const handleEditSlot = (values: SlotData, slotKey: string, scope: SlotEditScope) => {
        request.dispatch(dispatchEditSlot(values, slotKey, scope));
    };

    const handleDeleteSlot = (selectedScope?: SlotEditScope) => {
        if (!activeSchedule?.id) {
            return;
        }
        request.dispatch(dispatchDeleteSlot(activeSchedule.id, selectedScope));
    };

    const dispatchAddSlot = async (values: SlotData) => {
        await api.createSlot(values);
        await reloadSchedules();
        schedulePopoverState.togglePopover();
    };

    const dispatchEditSlot = async (values: SlotData, slotKey: string, scope: SlotEditScope) => {
        await api.updateSlot(values, slotKey, scope);
        await reloadSchedules();
        schedulePopoverState.togglePopover();
    };

    const dispatchDeleteSlot = async (slotKey: string, selectedScope: SlotEditScope = SlotEditScope.SINGLE) => {
        await api.deleteSlot(slotKey, selectedScope);
        await reloadSchedules();
        schedulePopoverState.togglePopover();
    };

    return {
        request,
        handleAddSlot,
        handleEditSlot,
        handleDeleteSlot,
    };
};
