import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';

interface Props {
    message: string;
    className?: string;
}

export const NoSlotsMessageMarkdown = ({ message, className }: Props) => {
    return (
        <ReactMarkdown className={className} renderers={renderers} allowedTypes={['paragraph', 'link', 'text']}>
            {message}
        </ReactMarkdown>
    );
};

export const NoSlotsMessage = styled(NoSlotsMessageMarkdown)`
    font-size: 15px;
    padding: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
`;

const renderers = {
    link: ({ href, children }: { href: string; children: ReactNode }) => (
        <MarkdownLink href={href} target="_blank" rel="noreferrer">
            {children}
        </MarkdownLink>
    ),
    paragraph: ({ children }: { children: ReactNode }) => <MarkdownParagraph>{children}</MarkdownParagraph>,
};

const MarkdownLink = styled.a`
    color: ${({ theme }) => theme.accent};
    font-weight: 600;

    &:hover {
        color: ${({ theme }) => theme.accent};
    }
`;

const MarkdownParagraph = styled.p`
    margin-bottom: 0;
`;
