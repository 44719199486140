import React from 'react';
import { SlotCriteriaConditionType } from 'components/AdminPanel/ManageSlotsCriteria/SlotsCriteriaForm/SlotsCriteriaForm.types';
import { Field } from 'formik';
import {
    ErrorMessage,
    FormGroup,
    FormSelect,
    Label,
    RadioTile,
    SwitchInput,
    TextGrey,
    UnderlineH2 as Title,
} from 'medrefer-web-sdk/web-kit';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useConditionOptions } from 'components/AdminPanel/ManageSlotsCriteria/hooks/useConditionOptions';
import { useNonInitialEffect } from 'medrefer-web-sdk/web-kit/hooks';
import { useHealthcareServiceOptions } from 'features/appointment/hooks';
import { FormikBaseProps } from './SlotsCriteriaForm';

export const SlotsCriteriaConditionalAppearanceData = ({ baseProps }: FormikBaseProps) => {
    const { t } = useTranslation();
    const { conditionCriteriaOptions, conditionValueOptions } = useConditionOptions(baseProps);
    const healthcareServiceOptions = useHealthcareServiceOptions();

    useNonInitialEffect(() => {
        baseProps.setFieldValue('enable_when.criteria_value', undefined);
    }, [baseProps.values.enable_when.criteria]);

    return (
        <>
            <Title className="mt-4">{t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.condition_title')}</Title>
            <TextGrey>{t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.condition_help_text')}</TextGrey>
            <FormGroup>
                <Row>
                    <Col xs={6} md={4}>
                        <Label style={{ marginTop: 0 }}>
                            {t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.enable_condition_label')}
                        </Label>
                    </Col>

                    <Col xs={6} md={8}>
                        <SwitchInput
                            id="enable_condition"
                            isChecked={baseProps.values.enable_condition}
                            handleChange={baseProps.handleChange}
                        />
                    </Col>
                </Row>
            </FormGroup>
            {baseProps.values.enable_condition && (
                <>
                    <FormGroup>
                        <Row>
                            <Col>
                                <Label isRequired>
                                    {t('adminPanel:manageSlotsCriteria.slotsCriteriaForm.condition_type_label')}
                                </Label>
                            </Col>
                        </Row>
                        {baseProps.touched.condition_type && baseProps.errors.condition_type && (
                            <ErrorMessage className="mt-1">{baseProps.errors.condition_type}</ErrorMessage>
                        )}
                        <Row>
                            <Col xs={12} md={6} className="mt-2">
                                <RadioTile
                                    name={'condition_type'}
                                    value={SlotCriteriaConditionType.anotherCriteria}
                                    title={t(
                                        'adminPanel:manageSlotsCriteria.slotsCriteriaForm.condition_type_another_criteria_label',
                                    )}
                                    description={t(
                                        'adminPanel:manageSlotsCriteria.slotsCriteriaForm.condition_type_another_criteria_help_text',
                                    )}
                                    checked={
                                        baseProps.values.condition_type === SlotCriteriaConditionType.anotherCriteria
                                    }
                                    onChange={baseProps.handleChange}
                                />
                            </Col>
                            <Col xs={12} md={6} className="mt-2">
                                <RadioTile
                                    name={'condition_type'}
                                    value={SlotCriteriaConditionType.healthcareService}
                                    title={t(
                                        'adminPanel:manageSlotsCriteria.slotsCriteriaForm.condition_type_healthcare_service_label',
                                    )}
                                    description={t(
                                        'adminPanel:manageSlotsCriteria.slotsCriteriaForm.condition_type_healthcare_service_help_text',
                                    )}
                                    checked={
                                        baseProps.values.condition_type === SlotCriteriaConditionType.healthcareService
                                    }
                                    onChange={baseProps.handleChange}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    {baseProps.values.condition_type === SlotCriteriaConditionType.anotherCriteria && (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <Label isRequired>
                                            {t(
                                                'adminPanel:manageSlotsCriteria.slotsCriteriaForm.condition_criteria_label',
                                            )}
                                        </Label>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <Field
                                            name="enable_when.criteria"
                                            options={conditionCriteriaOptions}
                                            component={FormSelect}
                                            isError={
                                                baseProps.touched.enable_when?.criteria &&
                                                !!baseProps.errors.enable_when?.criteria
                                            }
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <Label isRequired>
                                            {t(
                                                'adminPanel:manageSlotsCriteria.slotsCriteriaForm.condition_value_label',
                                            )}
                                        </Label>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <Field
                                            name="enable_when.criteria_value"
                                            component={FormSelect}
                                            options={conditionValueOptions}
                                            noOptionsMessage={() =>
                                                t(
                                                    'adminPanel:manageSlotsCriteria.slotsCriteriaForm.condition_value_empty',
                                                )
                                            }
                                            isError={
                                                baseProps.touched.enable_when?.criteria_value &&
                                                !!baseProps.errors.enable_when?.criteria_value
                                            }
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </>
                    )}
                    {baseProps.values.condition_type === SlotCriteriaConditionType.healthcareService && (
                        <>
                            <FormGroup>
                                <Row>
                                    <Col xs={12} md={4}>
                                        <Label isRequired>
                                            {t(
                                                'adminPanel:manageSlotsCriteria.slotsCriteriaForm.condition_healthcare_service_label',
                                            )}
                                        </Label>
                                    </Col>
                                    <Col xs={12} md={8}>
                                        <Field
                                            name="enable_when.healthcare_service"
                                            component={FormSelect}
                                            options={healthcareServiceOptions}
                                            isError={
                                                baseProps.touched.enable_when?.healthcare_service &&
                                                !!baseProps.errors.enable_when?.healthcare_service
                                            }
                                            onBlur={baseProps.handleBlur}
                                        />
                                    </Col>
                                </Row>
                            </FormGroup>
                        </>
                    )}
                </>
            )}
        </>
    );
};
