import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AppointmentTabs, AppointmentTab } from './AppointmentTabs';
import { AppointmentDetails } from './AppointmentDetails';
import { AppointmentHeader } from './AppointmentHeader';
import { AppointmentContainer } from './Appointment.styled';
import { useSelector } from 'react-redux';
import Types from 'MyTypes';
import { getActiveListItem } from 'features/appointment/selectors';

interface Props {
    showAppointmentDetails: boolean;
}

export const Appointment = ({ showAppointmentDetails }: Props) => {
    const activeListItem = useSelector((state: Types.RootState) => getActiveListItem(state.appointment));
    const [activeTab, setActiveTab] = useState(AppointmentTab.Overview);

    useEffect(() => {
        setActiveTab(AppointmentTab.Overview);
    }, [activeListItem]);

    if (!activeListItem) {
        return null;
    }

    return (
        <AppointmentContainer $isShow={showAppointmentDetails}>
            <Col>
                <Row className="mb-4">
                    <AppointmentHeader activeListItem={activeListItem} />
                </Row>
                <Row className="d-flex mb-4">
                    <AppointmentTabs
                        activeListItem={activeListItem}
                        activeTab={activeTab}
                        setActiveTab={setActiveTab}
                    />
                </Row>
                <Row className="d-flex">
                    <AppointmentDetails activeListItem={activeListItem} activeTab={activeTab} />
                </Row>
            </Col>
        </AppointmentContainer>
    );
};
