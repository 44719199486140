import React from 'react';
import { useParams } from 'react-router-dom';
import useApiRequest from 'hooks/useApiRequest';
import useObjectFetch from 'hooks/useObjectFetch';
import { api } from 'medrefer-web-sdk/api';
import { AppointmentStepsManager } from 'components/PublicSearch/CompleteAppointmentSteps/AppointmentStepsManager';
import { Col, Container, Row } from 'react-bootstrap';
import { PageActivityIndicator } from 'components/shared/PageActivityIndicator';
import { SlotBookingProgress } from 'components/PublicSearch/SlotBookingProgress';

export const CompleteAppointmentSteps = () => {
    const { appointmentId } = useParams<{ appointmentId?: string }>();
    const request = useApiRequest();
    const appointment = useObjectFetch(appointmentId, (appointmentId) =>
        request.dispatch(api.getAppointment(parseInt(appointmentId))),
    );
    const appointmentSteps = useObjectFetch(appointmentId, (appointmentId) =>
        request.dispatch(api.getAppointmentSteps(parseInt(appointmentId))),
    );

    if (request.isLoading || !appointment || !appointmentSteps) {
        return <PageActivityIndicator />;
    }

    return (
        <>
            <SlotBookingProgress activeStep="payment" includePaymentStep />
            <Container fluid>
                <Row>
                    <Col xs={12} md={10} className="ml-auto mr-auto mt-5 position-relative">
                        <AppointmentStepsManager appointment={appointment} initialAppointmentSteps={appointmentSteps} />
                    </Col>
                </Row>
            </Container>
        </>
    );
};
