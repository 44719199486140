import React, { FC } from 'react';
import ArrowLeftIcon from 'medrefer-web-sdk/web-kit/assets/icons/ArrowLeftIcon.png';
import ArrowRightIcon from 'medrefer-web-sdk/web-kit/assets/icons/ArrowRightIcon.png';
import { ArrowButtonContainer } from './ArrowButton.styled';
interface ArrowButtonProps {
    direction: 'right' | 'left';
    onClick: () => void;
}

export const ArrowButton: FC<ArrowButtonProps> = ({ direction, onClick }: ArrowButtonProps) => {
    const imgSrc = direction == 'right' ? ArrowRightIcon : ArrowLeftIcon;

    return (
        <ArrowButtonContainer onClick={onClick} direction={direction}>
            <img src={imgSrc} />
        </ArrowButtonContainer>
    );
};
