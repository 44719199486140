import {
    Button,
    ErrorMessage,
    FormGroup,
    Input,
    Label,
    TextArea,
    UnderlineH2 as Title,
} from '../../../../medrefer-web-sdk/web-kit';
import { Col, Container, Form, Row } from 'react-bootstrap';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormikProps } from 'formik';
import { RequestWaitingRoomProps, RequestWaitingRoomValues } from './RequestWaitingRoomForm.types';
import { LoginCredentials, LoginStep } from '../../../../medrefer-web-sdk/api/models';
import FileAttachment from './FileAttachment';
import RequestWaitingRoomUserSubModule from './SubModules/RequestWaitingRoomUserSubModule';
import useLoginFlow from '../../../Auth/useLoginFlow';
import RequestWaitingRoomRegisterSubModule from './SubModules/RequestWaitingRoomRegisterSubModule';
import RequestWaitingRoomLoginSubModule from './SubModules/RequestWaitingRoomLoginSubModule';
import { SlotBookingStep } from '../../../PublicSearch/SlotBooking/SlotBooking.types';
import { AttachDocumentsHanlerType } from '../../../../hooks/useAttachDocuments';
import { DayTimeList } from './RequestWaitingRoomDataComponents/DayTimeList';
// import { useSelector } from 'react-redux';
// import { getBrand } from '../../../../features/auth/selectors';

export const RequestWaitingRoomData = (props: RequestWaitingRoomProps & FormikProps<RequestWaitingRoomValues>) => {
    const { t } = useTranslation();
    const { ensureEmailExists } = useLoginFlow();
    const [canSubmit, SetCanSubmit] = useState(false);
    const [loginOrRegisterStage, SetLoginOrRegisterStage] = useState(false);
    const [emailExists, SetEmailExists] = useState<boolean | undefined | null>(undefined);
    // const organization = useSelector(getBrand);

    const HandleFileSubmit = (attachmentHandler: AttachDocumentsHanlerType) => {
        props.values.attachDocumentHandler = attachmentHandler;
    };

    const SetBaseStep = () => {
        SetLoginOrRegisterStage(false);
        SetCanSubmit(false);
        props.values.otpCodeError = false;
        props.values.passwordError = false;
        props.values.step = SlotBookingStep.base;
        SetEmailExists(undefined);
    };

    const EmailExists = (): boolean => {
        if (emailExists === null) return false;

        if (emailExists !== undefined) {
            return emailExists;
        }

        const loginObject: LoginCredentials = {
            password: '',
            step: LoginStep.email,
            token: '',
            email: props.values.email,
        };
        SetEmailExists(null);
        ensureEmailExists(loginObject).then((res) => SetEmailExists(res));
        return false;
    };

    const handleLoginOrRegisterStep = () => {
        SetLoginOrRegisterStage(true);
        props.values.step = emailExists ? SlotBookingStep.login : SlotBookingStep.register;
    };
    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <Title>{t('requestWaitingRoom:request_waiting_room_label')}</Title>
            {!loginOrRegisterStage ? (
                <>
                    <DayTimeList props={props} canSubmit={canSubmit} />
                    <Label>{t('newAppointmentForm:title_message')}</Label>
                    <FormGroup>
                        <Row>
                            <Col xs={12} md={12} className="text-left">
                                <TextArea
                                    isError={!!(props.touched.message && props.errors.message)}
                                    id="message"
                                    style={{ height: '130px' }}
                                    disabled={canSubmit}
                                    placeholder={t('newAppointmentForm:placeholder_message')}
                                    onChange={props.handleChange}
                                    value={props.values.message}
                                    onBlur={props.handleBlur}
                                />
                            </Col>
                        </Row>
                        {props.touched.message && props.errors.message && (
                            <ErrorMessage className="text-left mt-1">{props.errors.message}</ErrorMessage>
                        )}
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col xs={12} md={12} className="text-left">
                                <FileAttachment
                                    onFilePicked={HandleFileSubmit}
                                    disable={canSubmit}
                                    subtitle={t('newAppointmentForm:subtitle_attachment')}
                                    title={t('newAppointmentForm:title_attachments')}
                                    maxFiles={5}
                                    minFiles={0}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <Label isRequired>{t('newAppointmentForm:title_email')}</Label>
                    <FormGroup>
                        <Row className="primary">
                            <Col xs={12} md={12} className="text-left">
                                <Input
                                    isError={!!(props.touched.email && props.errors.email)}
                                    id="email"
                                    type="text"
                                    disabled={canSubmit || props.values.isAuthorized}
                                    onChange={props.handleChange}
                                    value={props.values.email}
                                    onBlur={props.handleBlur}
                                />
                            </Col>
                        </Row>
                        {props.touched.email && props.errors.email && (
                            <ErrorMessage className="text-left mt-1">{props.errors.email}</ErrorMessage>
                        )}
                    </FormGroup>
                    {/*Maybe useful in future*/}
                    {/*<Label isRequired>{t('newAppointmentForm:title_phone_number')}</Label>
                    <FormGroup>
                        <Row>
                            <Col xs={12} md={12} className="text-left">
                                <PhoneInput
                                    isError={!!(props.touched.phone && props.errors.phone)}
                                    id="phone"
                                    type="text"
                                    disabled={canSubmit}
                                    onChange={props.handleChange}
                                    value={props.values.phone}
                                    onBlur={props.handleBlur}
                                />
                            </Col>
                        </Row>
                        {props.touched.phone && props.errors.phone && (
                            <ErrorMessage className="text-left mt-1">{props.errors.phone}</ErrorMessage>
                        )}
                    </FormGroup>*/}
                    <RequestWaitingRoomUserSubModule
                        t={t}
                        props={props}
                        setLoginOrRegisterStage={SetLoginOrRegisterStage}
                        emailExists={ensureEmailExists}
                        SetCanSubmit={SetCanSubmit}
                        onCancel={props.closeModal}
                    />
                </>
            ) : emailExists ? (
                <RequestWaitingRoomLoginSubModule onCancel={SetBaseStep} t={t} mainProps={props} />
            ) : (
                <RequestWaitingRoomRegisterSubModule onCancel={SetBaseStep} t={t} props={props} />
            )}
            {canSubmit && !loginOrRegisterStage && (
                <Container>
                    <Row>
                        <Col className="ml-auto mr-auto" xs={12} md={6}>
                            <Button type="button" onClick={handleLoginOrRegisterStep} isLoading={emailExists === null}>
                                {EmailExists()
                                    ? t('requestWaitingRoom:login_button')
                                    : t('requestWaitingRoom:signup_button')}
                            </Button>
                        </Col>
                        {/*<Col className="ml-auto mr-auto" xs={12} md={6}>
                            <Button type="submit" isLoading={props.request.isLoading}>
                                {t('requestWaitingRoom:send_request_as_guest_button')}
                            </Button>
                        </Col>*/}
                    </Row>
                </Container>
            )}
        </Form>
    );
};
