import React from 'react';
import { FormikProps, withFormik } from 'formik';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { useTranslation, withTranslation } from 'react-i18next';
import { UserRole } from 'medrefer-web-sdk/api/models';
import {
    Button,
    ErrorMessage,
    FormErrorMessage,
    FormGroup,
    Input,
    Label,
    UnderlineH2 as Title,
} from 'medrefer-web-sdk/web-kit';
import { FormProps, mapPropsToValues, UserProfileEditFormData, validationSchema } from './EditProfileForm.schema';
import EditPatientForm from './EditPatientForm';
import {
    EOptionalFields,
    useOptionalFields,
} from '../../shared/ServiceSlots/RequestWaitingRoomModal/useOptionalFields';
import { useSelector } from 'react-redux';
import { getBrand } from '../../../features/auth/selectors';
import { PhoneInput } from '../../shared/PhoneInput';

const InnerForm = (props: FormProps & FormikProps<UserProfileEditFormData>) => {
    const { t } = useTranslation();
    const organization = useSelector(getBrand);
    const { isVisible } = useOptionalFields(organization);
    return (
        <Form
            onSubmit={(e) => {
                e.preventDefault();
                props.handleSubmit();
            }}
        >
            <Title>{t('editProfileForm:form_label')}</Title>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('editProfileForm:personal_title')}</Label>
                    </Col>
                    <Col md={8} className="text-left">
                        <Input
                            isError={!!(props.touched.personalTitle && props.errors.personalTitle)}
                            id="personalTitle"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.personalTitle}
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.personalTitle && props.errors.personalTitle && (
                    <ErrorMessage className="text-left mt-1">{props.errors.personalTitle}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('editProfileForm:first_name_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-left">
                        <Input
                            isError={!!(props.touched.firstName && props.errors.firstName)}
                            id="firstName"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.firstName}
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.firstName && props.errors.firstName && (
                    <ErrorMessage className="text-left mt-1">{props.errors.firstName}</ErrorMessage>
                )}
            </FormGroup>
            <FormGroup>
                <Row className="primary">
                    <Col xs={12} md={4}>
                        <Label>{t('editProfileForm:last_name_label')}</Label>
                    </Col>
                    <Col xs={12} md={8} className="text-left">
                        <Input
                            isError={!!(props.touched.lastName && props.errors.lastName)}
                            id="lastName"
                            type="text"
                            onChange={props.handleChange}
                            value={props.values.lastName}
                            onBlur={props.handleBlur}
                        />
                    </Col>
                </Row>
                {props.touched.lastName && props.errors.lastName && (
                    <ErrorMessage className="text-left mt-1">{props.errors.lastName}</ErrorMessage>
                )}
            </FormGroup>
            {isVisible(EOptionalFields.phone) && (
                <FormGroup>
                    <Row className="primary">
                        <Col xs={12} md={4}>
                            <Label isRequired={props.mandatory_fields.includes('phone')}>
                                {t('editProfileForm:phone_label')}
                            </Label>
                        </Col>
                        <Col xs={12} md={8} className="text-left">
                            <PhoneInput
                                isError={!!(props.touched.phone && props.errors.phone)}
                                id="phone"
                                type="text"
                                onChange={props.handleChange}
                                value={props.values.phone}
                                onBlur={props.handleBlur}
                            />
                        </Col>
                    </Row>
                    {props.touched.phone && props.errors.phone && (
                        <ErrorMessage className="text-left mt-1">{props.errors.phone}</ErrorMessage>
                    )}
                    <FormErrorMessage apiErrors={props.request.errors} skipFormikErrors />
                </FormGroup>
            )}

            {props.authUser.role === UserRole.patient && <EditPatientForm baseProps={props} />}

            <Container>
                <Row>
                    <Col className="ml-auto mr-auto" xs={12} md={6}>
                        <Button isLoading={props.request.isLoading}>{t('editProfileForm:submit_button_label')}</Button>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
};

const EditProfileForm = withFormik<FormProps, UserProfileEditFormData>({
    mapPropsToValues,
    validationSchema,
    handleSubmit: (values, { props }) => {
        props.updateUserData(values);
    },
})(InnerForm);

export default withTranslation()(EditProfileForm);
