import { api } from 'medrefer-web-sdk/api';
import * as Yup from 'yup';

const rName = /^[\w'\-,.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*(){}|~<>;:[\]]{2,}$/;
const rHexColor = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
const rPostalCode = /^[a-zA-Z0-9][a-zA-Z0-9\- ]{0,10}[a-zA-Z0-9]$/;
const rPassword = /^(?!\d+$)/;
const rSlug = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;

Yup.addMethod(Yup.string, 'matchesName', function (message) {
    return Yup.string().matches(rName, message);
});

const validatedPhones: { [key: string]: boolean } = {};

Yup.addMethod(Yup.string, 'matchesPhone', function (message) {
    return Yup.string().test('phone', message, async (phone?: string | null) => {
        if (!phone) {
            return true;
        }

        if (validatedPhones[phone] == null) {
            let valid = true;
            try {
                await api.phoneValidation(phone);
            } catch (e) {
                valid = false;
            }
            validatedPhones[phone] = valid;
        }

        return validatedPhones[phone];
    });
});

Yup.addMethod(Yup.string, 'matchesHexColor', function (message) {
    return Yup.string().matches(rHexColor, message);
});

Yup.addMethod(Yup.string, 'matchesPostalCode', function (message) {
    return Yup.string().matches(rPostalCode, message);
});

Yup.addMethod(Yup.string, 'matchesPassword', function (messageMin, messageTooSimple) {
    return Yup.string().min(8, messageMin).matches(rPassword, messageTooSimple);
});

Yup.addMethod(Yup.string, 'matchesSlug', function (message) {
    return Yup.string().matches(rSlug, message);
});
