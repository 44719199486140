import React from 'react';
import ArrowRightIcon from 'medrefer-web-sdk/web-kit/assets/icons/ArrowRightIcon.png';
import ArrowLeftIcon from 'medrefer-web-sdk/web-kit/assets/icons/ArrowLeftIcon.png';
import { useDateNavigator } from './useDateNavigator';
import { CalendarState } from 'components/Calendar/CalendarView/useCalendarState';
import { useCurrentMonth } from 'components/Calendar/DateNavigator/useCurrentMonth';
import { isSameDay, isToday } from 'utils/dates';
import { CalendarType } from 'components/Calendar/CalendarView/CalendarView.types';

import { DateNavigatorStyled } from './DateNavigator.styled';
import { getActiveLanguage } from 'utils/i18n';

interface Props {
    state: CalendarState;
    handleDateChange: (date: Date) => void;
    handleCalendarTypeChange: (calendarType: CalendarType) => void;
}

export const DateNavigator = ({ state, handleDateChange, handleCalendarTypeChange }: Props) => {
    const { currentMonth, handleNextMonth, handlePrevMonth } = useCurrentMonth(state);
    const { days, dayNames } = useDateNavigator(currentMonth);

    const handleCalendarDayClick = (date: Date) => {
        handleDateChange(date);
        if (state.date.getTime() === date.getTime()) {
            handleCalendarTypeChange(CalendarType.day);
        }
    };

    return (
        <DateNavigatorStyled>
            <div className="month-picker">
                <span>{`${currentMonth.toLocaleString(getActiveLanguage(), {
                    month: 'long',
                })} ${currentMonth.getFullYear()}`}</span>
                <div onClick={handleNextMonth}>
                    <img src={ArrowRightIcon} className="right" alt="arrow right icon" />
                </div>
                <div onClick={handlePrevMonth}>
                    <img src={ArrowLeftIcon} className="left" alt="arrow left icon" />
                </div>
            </div>
            <div className="days-picker">
                {dayNames.map((dayName, index) => (
                    <div className="day dayName" key={dayName + index}>
                        {dayName}
                    </div>
                ))}
                {days.map((day) => {
                    let className = 'day';
                    if (isToday(day)) {
                        className += ' today';
                    } else if (isSameDay(state.date, day)) {
                        className += ' active';
                    }
                    return (
                        <div key={day.toString()} className={className} onClick={() => handleCalendarDayClick(day)}>
                            {day.getDate()}
                        </div>
                    );
                })}
            </div>
        </DateNavigatorStyled>
    );
};
