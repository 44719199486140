import React, { ReactElement } from 'react';

import { Badge } from 'react-bootstrap';
import {
    QuestionnaireResponseExtended,
    FhirQuestionnaireResponseStatus,
    AppointmentModel,
} from 'medrefer-web-sdk/api/models';
import { useTranslation } from 'react-i18next';
import { QuestionnaireResponseContainer, QuestionnaireResponse } from './AppointmentQuestionnaireResponses.styled';
import { Link } from 'react-router-dom';
import useBrand from 'hooks/useBrand';

interface Props {
    appointment: AppointmentModel;
}

const AppointmentQuestionnaireResponses = ({ appointment }: Props): ReactElement => {
    const { t } = useTranslation();

    const { getPathBranded } = useBrand();

    return (
        <QuestionnaireResponseContainer>
            {appointment.questionnaire_responses.map((response: QuestionnaireResponseExtended) => (
                <Link
                    to={getPathBranded('questionnaire', appointment.id.toString(), response.id.toString())}
                    target="_blank"
                    key={response.questionnaire.title}
                >
                    <QuestionnaireResponse>
                        <span>{response.questionnaire.title}</span>
                        <Badge
                            variant={`${
                                response.status === FhirQuestionnaireResponseStatus.IN_PROGRESS ? 'primary' : ''
                            }`}
                            className="ml-2"
                        >
                            {t(`questionnaire:status.${response.status}`)}
                        </Badge>
                    </QuestionnaireResponse>
                </Link>
            ))}
        </QuestionnaireResponseContainer>
    );
};

export default AppointmentQuestionnaireResponses;
