import styled, { css } from 'styled-components';

export interface PrimaryButtonProps {
    maxWidth?: number;
    muted?: boolean;
    inverseColors?: boolean;
    noBorder?: boolean;
    badgeContent?: number;
    tooltipContent?: string;
}

const primaryButtonStyle = css<PrimaryButtonProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.accent};
    color: white;
    box-shadow: 0px 20px 25px ${({ theme }) => theme.accent}66;
    border-radius: 8px;
    width: 100%;
    font-weight: 800;
    font-size: 14px;
    height: 54px;
    text-align: center;
    box-sizing: border-box;
    border: ${({ theme }) => `2px solid ${theme.accent}`};
    max-width: ${({ maxWidth }) => (maxWidth ? maxWidth + 'px' : 'auto')};

    background: ${({ theme, muted }) => (muted ? theme.grey100 : undefined)};
    color: ${({ theme, muted }) => (muted ? theme.grey300 : undefined)};
    box-shadow: ${({ muted }) => (muted ? 'none' : undefined)};
    cursor: ${({ muted }) => (muted ? 'not-allowed' : undefined)};
    text-decoration: none !important;
    svg {
        fill: ${({ theme }) => theme.accent};
        display: block;
        margin-right: 5px;
    }

    &.disabled {
        background: #b0b0b0 !important;
        color: #6d6d6d !important;
        border: none;
        box-shadow: none;
    }

    ${({ inverseColors, theme }) =>
        inverseColors
            ? css`
                  background: white;
                  color: ${theme.accent};
              `
            : ''}
    ${({ noBorder }) =>
        noBorder &&
        css`
            border: none;
            box-shadow: none;
        `}
    
        ${({ badgeContent }) =>
            badgeContent &&
            css`
                &:after {
                    content: '${badgeContent}';
                    position: absolute;
                    right: -8px;
                    top: -8px;
                    background: red;
                    padding: 2px;
                    border-radius: 10px;
                    color: white;
                    font-size: 12px;
                    font-weight: 400;
                    height: 21px;
                    width: 20px;
                }
            `}

            ${({ tooltipContent }) =>
                tooltipContent &&
                css`
                    &:hover {
                        &:before {
                            visibility: visible;
                            opacity: 1;
                        }
                    }
                    &:before {
                        visibility: hidden;
                        opacity: 0;
                        transition: visibility 0s, opacity 0.2s ease-in;
                        opacity: 0;
                        content: '${tooltipContent}';
                        position: absolute;
                        right: auto;
                        left: auto;
                        margin: auto;
                        bottom: -40px;
                        background: #39393a;
                        padding: 2px;
                        border-radius: 10px;
                        color: white;
                        font-size: 14px;
                        font-weight: 400;
                        padding: 7px;
                    }
                `}

    }
`;

export const PrimaryButton = styled.button<PrimaryButtonProps>`
    ${primaryButtonStyle}
`;
